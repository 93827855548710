import { Fields, Loader, Menu } from "@/components/collection"
import { Prose } from "@/components/frontend/prose"
import { PlaceHeader } from "@/components/kulturrallye/PlaceHeader"
import { Stats } from "@/components/kulturrallye/Stats"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Button } from "@/components/ui/button"
import { CardAccordion } from "@/components/ui/card-accordion"
import { Link, linkCx } from "@/components/ui/link"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocale, useDictionary } from "@/dictionaries/hooks"
import { usePromise } from "@/hooks/usePromise"
import { SelectProvider, useSelect } from "@/hooks/useSelect"
import { usePlace } from "@/store/places/hooks"
import { Place } from "@/store/places/localizers"
import { getWorkshop } from "@/store/workshops/actions"
import { useReservationsByWorkshop, useWorkshop } from "@/store/workshops/hooks"
import { Workshop } from "@/store/workshops/localizers"
import {
  BarChart3,
  CalendarSearch,
  Clock,
  Eye,
  EyeOff,
  Globe,
  LineChart,
  MapPin,
  MoreVertical,
  Pen,
  Presentation,
  Trash,
  UsersIcon,
} from "lucide-react"
import { Redirect } from "wouter"
import { ContextProvider, usePageContext } from "../Context"
import { Collection } from "./Collection"
import { Context as EventsContext, ContextProvider as EventsContextProvider } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/index.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley")

/**
 * DashboardKultureralleyPlaceWorkshop
 */
type Props = { workshop: Workshop; place: Place }
const DashboardKultureralleyPlaceWorkshop: React.FC<Props> = props => {
  const { workshop, place } = props
  const { _ } = useDictionary(dictionary())
  const select = useSelect(EventsContext)

  return (
    <PageWrapper ref={select.ref}>
      <SelectProvider {...select.props} />
      <PageHeader
        breadcrumbs={[
          [_("index.breadcrumbs"), "/dashboard/kultureralley"],
          [place.name, `/dashboard/kultureralley/${place.id}`],
          [workshop.name, `/dashboard/kultureralley/${place.id}/${workshop.id}`],
        ]}
      />
      <CardAccordion defaultValue={"meta"} group={`workshop-${workshop.id}`}>
        <SectionMeta workshop={workshop} place={place} />
        <SectionEvents workshop={workshop} place={place} />
        <SectionReservations workshop={workshop} place={place} />
      </CardAccordion>
    </PageWrapper>
  )
}

type PageProps = { placeId: string; id: string }
export default ({ placeId, id }: PageProps) => {
  const { _ } = useDictionary(dictionary())
  const workshop = useWorkshop(id)
  const place = usePlace(placeId)
  const [result, inProgress] = usePromise(() => getWorkshop(id))
  if (inProgress)
    return (
      <Loader
        breadcrumbs={[
          [_("index.breadcrumbs"), "/dashboard/kultureralley"],
          [place?.name ?? _("places.breadcrumbs"), `/dashboard/kultureralley/${placeId}`],
        ]}
      />
    )
  if (!result || result.error || G.isNullable(workshop) || G.isNullable(place))
    return <Redirect to={`/dashboard/kultureralley/${placeId}`} />

  return (
    <ContextProvider place={place}>
      <EventsContextProvider place={place} workshop={workshop}>
        <DashboardKultureralleyPlaceWorkshop place={place} workshop={workshop} />
      </EventsContextProvider>
    </ContextProvider>
  )
}

/**
 * SectionMeta
 */

const SectionMeta: React.FC<Props> = props => {
  const { workshop, place } = props
  const { _ } = useDictionary(dictionary("workshops"))
  const locale = useDateFnsLocale()
  const ctx = usePageContext()
  const toggleState = () =>
    workshop.published ? ctx.unpublish(workshop.id) : ctx.publish(workshop.id)

  return (
    <Menu type="context-menu" menu={<ContextMenu {...props} />}>
      <CardAccordion.Item value="meta">
        <PlaceHeader place={place} />
        <CardAccordion.Header>
          <CardAccordion.Header.Title>
            <Presentation size={16} aria-hidden />
            {_("section-meta-title")}
          </CardAccordion.Header.Title>
          <CardAccordion.Header.Aside className="relative">
            <Button
              variant="secondary"
              icon
              size="xxs"
              onClick={() => ctx.openStats(workshop.trackingId)}
              aria-label={_("menu.open-stats")}
            >
              <BarChart3 />
            </Button>
            <Button
              variant={workshop.published ? "default" : "secondary"}
              icon
              size="xxs"
              aria-label={_("menu.state-toggle", {
                state: workshop.published ? "draft" : "published",
              })}
              onClick={toggleState}
            >
              {workshop.published ? (
                <Eye aria-label={_(`menu.state-published`)} />
              ) : (
                <EyeOff aria-label={_(`menu.state-draft`)} />
              )}
            </Button>
            <Menu menu={<ContextMenu {...props} />} type="dropdown-menu" align="start" side="left">
              <Button variant="ghost" size="xs" icon>
                <MoreVertical aria-hidden />
              </Button>
            </Menu>
          </CardAccordion.Header.Aside>
        </CardAccordion.Header>
        <CardAccordion.Content className="flex flex-col pt-4 px-8 pb-8">
          <h1 className="text-lg font-medium leading-none tracking-tight">{workshop.name}</h1>

          <Prose
            className="max-w-xl"
            dangerouslySetInnerHTML={{
              __html: workshop.description,
            }}
          />
          <Fields divider>
            <Fields.Item
              name={_("place")}
              icon={<MapPin aria-hidden />}
              value={
                <Link href={`/dashboard/kultureralley/${place.id}`} className={linkCx}>
                  {place.name}
                </Link>
              }
            />
            <Fields.Item
              name={_("languages")}
              icon={<Globe aria-hidden />}
              value={pipe(
                workshop.languages,
                A.map(language =>
                  _(`languages-${S.toLowerCase(language) as "en"}`, { defaultValue: language })
                ),
                A.join(", ")
              )}
            />
            <Fields.Item
              name={_("duration")}
              icon={<Clock aria-hidden />}
              value={T.formatDuration(workshop.duration, { locale })}
            />
            <Fields.Item
              name={_("max-attendees")}
              icon={<UsersIcon aria-hidden />}
              value={_("max-attendee-people", { count: workshop.maxAttendees })}
            />
          </Fields>
        </CardAccordion.Content>
      </CardAccordion.Item>
    </Menu>
  )
}

/**
 * SectionEvents
 */
const SectionEvents: React.FC<Props> = () => {
  const { _ } = useDictionary(dictionary("workshops"))
  return (
    <CardAccordion.Item value="events">
      <CardAccordion.Header>
        <CardAccordion.Header.Title>
          <CalendarSearch size={16} aria-hidden />
          {_("section-events-title")}
        </CardAccordion.Header.Title>
        <CardAccordion.Header.Aside />
      </CardAccordion.Header>
      <CardAccordion.Content className="pt-4 px-8 pb-8">
        <Collection />
      </CardAccordion.Content>
    </CardAccordion.Item>
  )
}

/**
 * SectionReservations
 */
const SectionReservations: React.FC<Props> = props => {
  const { _ } = useDictionary(dictionary("workshops"))
  const reservations = useReservationsByWorkshop(props.workshop.id)
  return (
    <CardAccordion.Item value="reservations">
      <CardAccordion.Header>
        <CardAccordion.Header.Title>
          <LineChart size={16} aria-hidden />
          {_("section-reservations-title")}
        </CardAccordion.Header.Title>
        <CardAccordion.Header.Aside />
      </CardAccordion.Header>
      <CardAccordion.Content className="flex flex-col gap-4 pt-4 px-8 pb-8">
        <Stats reservations={reservations} />
      </CardAccordion.Content>
    </CardAccordion.Item>
  )
}

/**
 * ContextMenu
 */
const ContextMenu: React.FC<Props> = ({ workshop }) => {
  const { _ } = useDictionary(dictionary("workshops", "menu"))
  const { confirmDelete, edit, publish, unpublish, openStats } = usePageContext()
  const { id } = workshop
  return (
    <>
      <Menu.Item onClick={() => edit(workshop)}>
        <Pen aria-hidden />
        {_("edit")}
      </Menu.Item>
      {workshop.published ? (
        <Menu.Item onClick={() => unpublish(id)}>
          <EyeOff aria-hidden />
          {_("unpublish")}
        </Menu.Item>
      ) : (
        <Menu.Item onClick={() => publish(id)}>
          <Eye aria-hidden />
          {_("publish")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => openStats(workshop.trackingId)}>
        <BarChart3 aria-hidden />
        {_("open-stats")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDelete(id)}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
    </>
  )
}
