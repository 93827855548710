import { Payload } from "@/services/contents/service"
import { ContentItemBase } from "@/store/contents/localizers"
import { Language } from "@/store/languages/localizers"
import { z } from "zod"

/**
 * unions exports
 */
import * as Articles from "./articles/schemas"
import * as Cards from "./cards/schemas"
import * as ContactDetails from "./contact-details/schemas"
import * as ContactForm from "./contact-form/schemas"
import * as Gallery from "./gallery/schemas"
import * as Heading from "./heading/schemas"
import * as KulturRallye from "./kultur-rallye/schemas"
import * as News from "./news/schemas"
import * as Newsletter from "./newsletter/schemas"
import * as Offers from "./offers/schemas"
import * as Picture from "./picture/schemas"
import * as Projects from "./projects/schemas"
import * as Richtext from "./richtext/schemas"
import * as Video from "./video/schemas"

export const apiContentItemAny = z.union([
  Articles.apiContentItem,
  Cards.apiContentItem,
  ContactDetails.apiContentItem,
  ContactForm.apiContentItem,
  Gallery.apiContentItem,
  Heading.apiContentItem,
  KulturRallye.apiContentItem,
  News.apiContentItem,
  Newsletter.apiContentItem,
  Offers.apiContentItem,
  Picture.apiContentItem,
  Projects.apiContentItem,
  Richtext.apiContentItem,
  Video.apiContentItem,
])
export type ContentItemMapping = {
  articles: Articles.ContentItem
  cards: Cards.ContentItem
  "contact-details": ContactDetails.ContentItem
  "contact-form": ContactForm.ContentItem
  gallery: Gallery.ContentItem
  heading: Heading.ContentItem
  "kultur-rallye": KulturRallye.ContentItem
  news: News.ContentItem
  newsletter: Newsletter.ContentItem
  offers: Offers.ContentItem
  picture: Picture.ContentItem
  projects: Projects.ContentItem
  richtext: Richtext.ContentItem
  video: Video.ContentItem
}

/**
 * default schemas and types exports
 */
export type ApiContentItemAny = z.infer<typeof apiContentItemAny>
export const apiContent = z.object({
  id: z.string().uuid(),
  items: z.array(apiContentItemAny),
})
export type ApiContent = z.infer<typeof apiContent>
export type ItemMappingExport<T extends keyof ContentItemMapping> = {
  ItemForm: React.FC<{ item: ContentItemBase & ContentItemMapping[T]; close: () => void }>
  ItemRender: React.FC<{ item: ContentItemBase & ContentItemMapping[T] }>
  create: (
    order: number,
    languages: Language[],
    id: string
  ) => Extend<Payload["items"]["create"], FormPayload<T>>
  ItemThumbnail: React.FC
  limitTo?: string
}
export type ItemMapping<T extends keyof ContentItemMapping> = {
  [K in T]: ItemMappingExport<K>
}
export type FormPayload<T extends keyof ContentItemMapping> = {
  props: ContentItemMapping[T]["props"]
  translations: {
    languageId: Language["id"]
    props: ContentItemMapping[T]["translations"][Language["id"]]["props"]
  }[]
  files: string[]
}
