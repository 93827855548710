import globalConfig, { acceptImageExtensions } from "@/config/global"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { humanFileSize } from "@/fns/human"
import {
  acceptToInputAccept,
  checkExtFromFile,
  formatExtList,
  getSizeFromFile,
  useDropZone,
} from "@/hooks/useDropZone"
import { saveAs } from "file-saver"
import { Download, ImageOff, X } from "lucide-react"
import selectFiles from "select-files-capture"
import {
  Form,
  FormFieldWrapper,
  FormFieldWrapperProps,
  FormFileType,
  extractInputProps,
  extractWrapperProps,
  isSynteticFile,
  normalizeFormFile,
  useFieldContext,
} from "."
import { AdaptHeight } from "../ui/adapt-height"
import { Button } from "../ui/button"
import { Image } from "../ui/image"
import { linkCx } from "../ui/link"
import { Popover } from "../ui/popover"
import { SrOnly } from "../ui/sr-only"

/**
 * dictionary src/dictionaries/en/components/form/form-files.json
 */
const dictionary = createContextMapper("components", "form", "form-files")

/**
 * FormImages
 */
type Props = FormInputImagesProps & FormFieldWrapperProps
export const FormImages = React.forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <FormFieldWrapper {...extractWrapperProps(props)}>
    <FormInputImages {...extractInputProps<FormFieldWrapperProps>(props)} ref={ref} />
  </FormFieldWrapper>
))

/**
 * FormInputFiles
 */
type FormInputImagesProps = React.ComponentProps<typeof Form.Input> & {
  accept?: string[]
  min?: number
  max?: number
  multiple?: boolean
}
const FormInputImages = React.forwardRef<HTMLButtonElement, FormInputImagesProps>(
  (
    {
      accept = acceptImageExtensions,
      min = 0,
      max = globalConfig.maxUploadFile,
      multiple = true,
      className,
    },
    ref
  ) => {
    const { _ } = useDictionary(dictionary())
    const { setFieldValue, value, id } = useFieldContext<FormFileType[]>()

    // manage file picker and drop zone
    const onDropFiles = (files: File[]) => {
      if (A.isNotEmpty(files)) multiple ? setFieldValue([...value, ...files]) : setFieldValue(files)
    }
    const onError = (code: "TOOLARGE" | "UNACCEPTED") => {
      toast.error(_(code))
    }
    const onClickDropZone = async () => {
      const fileList = await selectFiles({ accept: acceptToInputAccept(accept), multiple })
      const files = fileList ? Array.from(fileList) : []
      if (!A.some(files, file => min <= getSizeFromFile(file) && max >= getSizeFromFile(file)))
        return onError("TOOLARGE")
      if (!A.some(files, file => checkExtFromFile(file, accept))) return onError("UNACCEPTED")
      onDropFiles(files)
    }
    const { bindDropZone, dragOver } = useDropZone({
      accept,
      min,
      max,
      multiple,
      onDropFiles,
      onError,
    })

    // manage files
    const removeFile = (index: number) => {
      const current = A.getUnsafe(value, index)
      if (isSynteticFile(current)) {
        return setFieldValue(A.replaceAt(value, index, { ...current, delete: true }))
      }
      setFieldValue(A.removeAt(value, index))
    }

    return (
      <div className="flex flex-col gap-2">
        <div
          className={cx(
            "relative flex justify-center items-center w-full rounded-md",
            "border border-input border-dashed focus-within:border-orient transition-colors",
            dragOver ? "bg-primary/5 border-primary" : "bg-card",
            className
          )}
          {...bindDropZone}
        >
          <DropInner ref={ref} {...{ id, accept, min, max, onClickDropZone }} />
        </div>
        <ImageList files={value} removeFile={removeFile} />
      </div>
    )
  }
)

/**
 * DropInner
 */
type DropInnerProps = {
  id: string
  accept: string[]
  min?: number
  max: number
  multiple?: boolean
  onClickDropZone: React.MouseEventHandler<HTMLButtonElement>
}
const DropInner = React.forwardRef<HTMLButtonElement, DropInnerProps>(
  ({ id, onClickDropZone, max, accept }, ref) => {
    const { _ } = useDictionary(dictionary())
    const acceptedExtensions = React.useMemo(() => formatExtList(accept), [accept])
    return (
      <div className="flex flex-col justify-center items-center min-h-[10rem] p-4 gap-1">
        <p className="text-base max-sm:text-center max-sm:text-sm">
          {_("placeholder-before")}{" "}
          <button id={id} onClick={onClickDropZone} ref={ref} className={linkCx}>
            {_("placeholder-button")}
          </button>{" "}
          {_("placeholder-after")}
        </p>
        <p className="text-xs text-muted-foreground  max-sm:text-center">
          ({<span>{_("max-file-size", { size: humanFileSize(max) })}</span>},
          <Popover>
            <Popover.Trigger asChild>
              <button className={cx(linkCx)}>{_("accept")}</button>
            </Popover.Trigger>
            <Popover.Content>
              <p className="text-xs">
                <span className="inline-block pb-1 font-bold">{_("accept-extensions")}</span>
                <br />
                {A.join(acceptedExtensions, ", ")}
              </p>
            </Popover.Content>
          </Popover>
          )
        </p>
      </div>
    )
  }
)

/**
 * ImageList
 */
type ImageListProps = {
  files: FormFileType[]
  removeFile: (index: number) => void
}
const ImageList: React.FC<ImageListProps> = ({ files, removeFile }) => {
  return A.isNotEmpty(files) ? (
    <AdaptHeight overflowVisible>
      <div className="grid grid-cols-3 gap-2">
        {A.mapWithIndex(files, (index, formFile) => (
          <ImageItem formFile={formFile} remove={() => removeFile(index)} key={index} />
        ))}
      </div>
    </AdaptHeight>
  ) : null
}

/**
 * ImageItem
 */
type ImageItemProps = {
  formFile: FormFileType
  remove: React.MouseEventHandler<HTMLButtonElement>
}
const ImageItem: React.FC<ImageItemProps> = ({ formFile, remove }) => {
  const { _ } = useDictionary(dictionary())
  const file = normalizeFormFile(formFile)

  return file.delete === false ? (
    <div className="relative flex justify-between items-center h-32 gap-1 px-4 py-2 border border-input rounded-md overflow-hidden">
      <Image src={file.url} className="absolute inset-0 size-full rounded-md object-cover">
        <ImageOff size={64} strokeWidth={0.8} className="text-muted-foreground" />
      </Image>
      <p className="absolute bottom-1 left-1 flex items-center py-1 px-2 rounded-md bg-background/50 text-secondary-foreground text-xs">
        {humanFileSize(file.size)}
        {isSynteticFile(formFile) && (
          <Button variant="link" size="link" onClick={() => saveAs(file.url, file.name)}>
            <Download aria-hidden size={12} />
            <SrOnly>{_("download-file", { file: file.name })}</SrOnly>
          </Button>
        )}
      </p>
      <Button
        variant="transparent"
        size="xxs"
        icon
        onClick={remove}
        className="absolute top-1 right-1"
      >
        <X aria-hidden />
        <SrOnly>{_("remove-file", { file: file.name })}</SrOnly>
      </Button>
    </div>
  ) : null
}
