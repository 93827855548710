import {
  Form,
  FormAssertive,
  FormExtraFields,
  FormImage,
  FormInput,
  FormSelect,
  FormTextarea,
} from "@/components/form"
import { Button } from "@/components/frontend/button"
import { useDictionary } from "@/dictionaries/hooks"
import {
  useProjectCategoryOptions,
  useProjectLanguageOptions,
  useProjectOrientationOptions,
} from "@/store/projects/hooks"
import { dictionary } from "."
import { useInformationForm } from "./useInformationForm"
import { StepsProps } from "./useStepper"

/**
 * FormInformation
 */
export const FormInformation: React.FC<
  { form: ReturnType<typeof useInformationForm> } & StepsProps
> = ({ form, setStep }) => {
  const { _ } = useDictionary(dictionary("form"))
  const languageOptions = useProjectLanguageOptions()
  const categoryOptions = useProjectCategoryOptions()
  const orientationOptions = useProjectOrientationOptions()
  return (
    <Form
      form={form}
      className="flex flex-col gap-6"
      onSubmit={() => form.isValid && setStep("definition")}
    >
      <FormAssertive />

      <div className="grid grid-cols-2 gap-6">
        <FormInput
          label={_("name-label")}
          required
          name="name"
          maxLength={100}
          placeholder={_("name-placeholder")}
          labelPopover={_("name-popover")}
        />
        <FormSelect
          label={_("language-label")}
          required
          name="language"
          options={languageOptions}
          labelPopover={_("language-popover")}
        />
        <FormSelect
          label={_("category-label")}
          required
          name="category"
          options={categoryOptions}
          labelPopover={_("category-popover")}
        />
        <FormSelect
          required
          label={_("orientation-label")}
          name="orientation"
          options={orientationOptions}
          labelPopover={_("orientation-popover")}
        />
      </div>
      <FormImage
        required
        label={_("image-label")}
        name="image"
        ratio="aspect-[16/5]"
        labelPopover={_("image-popover")}
      />
      <FormExtraFields
        label={_("partner-label")}
        name="partners"
        translate={c => _(`partner-${c}`)}
        canAdd={false}
        canRemove={false}
        labelPopover={_("partner-popover")}
      />
      <FormExtraFields
        label={_("class-label")}
        name="classes"
        translate={c => _(`class-${c}`)}
        canAdd={false}
        canRemove={false}
        labelPopover={_("class-popover")}
      />
      <FormTextarea
        label={_("description-label")}
        name="description"
        maxLength={255}
        rows={6}
        required
        labelPopover={_("description-popover")}
      />
      <div className="flex justify-end">
        <Form.Submit asChild>
          <Button>{_("next")}</Button>
        </Form.Submit>
      </div>
    </Form>
  )
}
