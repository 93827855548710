import Selecto, { SelectoProps } from "react-selecto"
import { useMemoOnce } from "./useMemoOnce"

/**
 * SelectProvider
 */
export type SelectProviderProps = React.ComponentProps<typeof Selecto>
export const SelectProvider = React.forwardRef<Selecto, SelectProviderProps>((props, ref) => {
  const isTouchDevice = useMemoOnce(() => "ontouchstart" in window || navigator.maxTouchPoints > 0)
  return isTouchDevice ? null : <Selecto {...props} ref={ref} />
})

/**
 * useSelect
 */
export const useSelect = <C extends ContextType, T extends HTMLElement = HTMLDivElement>(
  Context: React.Context<C>,
  options: Partial<SelectoProps> = {}
) => {
  const ref = React.useRef<T | null>(null)
  const { selected, setSelected } = React.useContext(Context)

  /**
   * onSelect
   */
  const onSelect: SelectoProps["onSelect"] = React.useCallback(
    ({ added, removed }) => {
      let selection = A.reduce(added, selected, (list, { dataset }) => {
        return G.isNotNullable(dataset.selected) ? [...list, dataset.selected] : list
      })
      selection = A.reduce(removed, selection, (list, { dataset }) => {
        return G.isNotNullable(dataset.selected)
          ? A.reject(list, id => id === dataset.selected)
          : list
      })
      setSelected(selection)
    },
    [selected, setSelected]
  )

  /**
   * defaultOptions
   */
  const defaultOptions: Partial<SelectoProps> = {
    container: ref.current,
    dragContainer: ref.current,
    selectableTargets: ["[data-selected]"],
    selectByClick: true,
    selectFromInside: true,
    continueSelect: false,
    toggleContinueSelect: "shift",
    keyContainer: window,
    hitRate: 1,
    onSelect,
  }
  const props = D.merge(defaultOptions, options)
  return { ref, props }
}

/**
 * useSelectItem
 */
export const useSelectItem = <C extends ContextType>(
  Context: React.Context<C>,
  id: string,
  onNavigate?: () => void
) => {
  // selection
  const { selected, setSelected } = React.useContext(Context)
  const isSelected = React.useMemo(() => A.includes(selected, id), [id, selected])
  const hasSelection = React.useMemo(() => A.isNotEmpty(selected), [selected])
  const select = React.useCallback(
    () => setSelected([...selected, id]),
    [id, selected, setSelected]
  )
  const deselect = React.useCallback(
    () => setSelected(A.reject(selected, sid => sid === id)),
    [id, selected, setSelected]
  )
  const toggleSelection = React.useCallback(
    () => (isSelected ? deselect() : select()),
    [deselect, isSelected, select]
  )

  // bind
  const isTouchDevice = React.useMemo(
    () => "ontouchstart" in window || navigator.maxTouchPoints > 0,
    []
  )
  // const touchBind = useLongPress(
  //   (e) => {
  //     e.preventDefault()
  //     select()
  //   },
  //   { cancelOnMovement: true, detect: LongPressEventType.Touch }
  // )
  const onClick = () => {
    if (isTouchDevice) {
      hasSelection ? toggleSelection() : onNavigate && onNavigate()
    }
  }
  const onDoubleClick = () => !isTouchDevice && onNavigate && onNavigate()
  const onContextMenu = (e: React.MouseEvent) => isTouchDevice && e.preventDefault()

  // props
  const selectItemProps = {
    "data-selected": id,
    onClick,
    onDoubleClick,
    onContextMenu,
    // ...touchBind()
  }

  return { isSelected, selectItemProps, hasSelection, select, deselect, toggleSelection }
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
}
