import { containerVariants } from "@/components/layout/frontend/container"
import { zeroPad } from "@/fns/String"
import * as Tabs from "@radix-ui/react-tabs"
import { Fragment } from "react"
import { StepsProps } from "."

/**
 * Stepper
 */
type StepperProps = StepsProps & {
  "aria-label": string
  children: React.ReactNode
}
export const Stepper: React.FC<StepperProps> = ({ steps, setStep, step, children, ...props }) => {
  const valueIndex = React.useMemo(
    () => A.getIndexBy(steps, item => item.value === step) ?? 0,
    [steps, step]
  )
  return (
    <Tabs.Root
      className={cx(
        "flex flex-col gap-5 sm:gap-[30px] md:gap-[55px]",
        containerVariants({ x: "sm" })
      )}
      value={step}
      onValueChange={setStep}
    >
      <Tabs.List className="flex justify-stretch items-center w-full" {...props}>
        {A.mapWithIndex(steps, (index, { value, disabled, name }) => (
          <Fragment key={value}>
            <StepperTrigger
              index={index}
              value={value}
              isActive={index <= valueIndex}
              isCurrent={value === step}
              disabled={disabled}
            >
              {name}
            </StepperTrigger>
            {index < steps.length - 1 && <StepperTriggerDivider isActive={index < valueIndex} />}
          </Fragment>
        ))}
      </Tabs.List>
      {children}
    </Tabs.Root>
  )
}

/**
 * StepperTrigger
 */
type StepperTriggerProps = {
  index: number
  value: string
  isActive: boolean
  isCurrent: boolean
  disabled: boolean
  children?: React.ReactNode
}
const StepperTrigger: React.FC<StepperTriggerProps> = ({
  index,
  value,
  isActive,
  isCurrent,
  disabled,
  children,
}) => (
  <Tabs.Trigger
    className={cx(
      "flex items-center gap-5 sm:gap-[30px] pr-8 uppercase font-bold text-sm",
      "transition-colors duration-500",
      "disabled:pointer-events-none ",
      isActive ? "text-orient" : "text-emperor",
      isCurrent ? "flex" : "hidden lg:flex"
    )}
    value={value}
    disabled={disabled}
  >
    <span
      className={cx(
        "flex justify-center items-center w-10 h-10",
        "border",
        "transition-colors duration-500",
        isActive ? "bg-orient border-orient text-white" : "bg-white border-mercury text-emperor"
      )}
    >
      {zeroPad(index + 1)}
    </span>
    {children}
  </Tabs.Trigger>
)

/**
 * StepperTriggerDivider
 */
type StepperTriggerDividerProps = {
  isActive: boolean
}
const StepperTriggerDivider: React.FC<StepperTriggerDividerProps> = ({ isActive }) => (
  <span
    aria-hidden
    className={cx(
      "hidden lg:block h-0 grow ml-8 mr-16 border-b-2 transition-colors duration-500",
      isActive ? "border-orient" : "border-mercury"
    )}
  />
)

/**
 * StepperContent
 */
type StepperContentProps = {
  value: string
  title: string
  secondary?: string
  children: React.ReactNode
}
export const StepperContent: React.FC<StepperContentProps> = ({
  value,
  title,
  secondary,
  children,
}) => {
  return (
    <Tabs.Content
      className="hidden data-[state=active]:flex flex-col p-5 sm:p-[30px] border border-mercury"
      value={value}
    >
      <h2 className="text-xl font-bold mb-2">{title}</h2>
      {G.isNotNullable(secondary) && <p className="text-gray text-sm">{secondary}</p>}
      <div className="flex flex-col gap-6 mt-6">{children}</div>
    </Tabs.Content>
  )
}
