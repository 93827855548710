/**
 * proseStyle
 */
const proseStyle: ClassName = cx(
  "prose max-w-none hyphens-auto dark:prose-invert font-plus",
  "prose-headings:font-bold prose-headings:mt-4 prose-headings:mb-4",
  "prose-h1:text-3xl prose-h1:font-extrabold",
  "prose-h2:text-2xl prose-h2:font-extrabold",
  "prose-h3:text-xl prose-h3:font-extrabold",
  "prose-h4:text-lg prose-h4:font-extrabold",
  "prose-h5:text-base prose-h5:font-extrabold",
  "prose-h6:text-base prose-h6:font-medium",
  "prose-p:text-base prose-p:leading-relaxed prose-p:mt-4 prose-p:mb-4",
  "prose-blockquote:text-base prose-blockquote:font-normal prose-blockquote:italic",
  "prose-li:marker:text-base prose-li:marker:font-extrabold",
  "prose-ul:-ml-4",
  // "prose-ul:list-[square]",
  "prose-ul:list-image-[url(@/assets/icons/rectangle.svg)]",
  "prose-ol:-ml-4",
  "prose-a:underline"
)

/**
 * ProseWrapper
 */
const ProseWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => (
  <div className={cx(proseStyle, className)} {...props}>
    {children}
  </div>
)

/**
 * Export
 */
export const Prose = Object.assign(ProseWrapper, {
  className: proseStyle,
})
