import { CmsContextProvider } from "@/components/cms/Context"
import { ContentItems } from "@/components/cms/components/content"
import SeoItem from "@/components/cms/components/seo"
import { Loader, Menu } from "@/components/collection"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { StatsDialog } from "@/components/trackings/dialogs/stats"
import { Button } from "@/components/ui/button"
import { Confirm, confirmSafeDictionary } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { usePromise } from "@/hooks/usePromise"

import { Badge } from "@/components/ui/badge"
import { useIsSuperadmin } from "@/store/auth/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { deletePage, getPage, updatePage } from "@/store/pages/actions"
import { usePage } from "@/store/pages/hooks"
import { Page } from "@/store/pages/localizers"
import { BarChart3, Eye, EyeOff, Lock, LockOpen, Trash } from "lucide-react"
import { Redirect } from "wouter"

/**
 * dictionary src/dictionaries/en/pages/dashboard/pages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "pages")

/**
 * Page: PagesPage
 */
type PageProps = { id: string }
const PagesPage: React.FC<PageProps> = ({ id }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()

  const page = usePage(id)

  const { confirm: confirmDelete, ...deleteProps } = useConfirm<void>({
    onAsyncConfirm: () => deletePage(page.id),
    dictionary: confirmSafeDictionary(dictionary("delete-confirm")),
  })
  const { setItem: statsDialog, ...statsProps } = useDialog<string>()
  const openStats = () => statsDialog(page.trackingId)

  const [result, inProgress] = usePromise(() => getPage(id))
  if (inProgress) return <Loader breadcrumbs={[[_("breadcrumbs"), "/dashboard/pages"]]} />
  if (!result || result.error || G.isNullable(page)) return <Redirect to="/dashboard/pages" />

  const isDraft = page.state === "draft"
  return (
    <CmsContextProvider
      ressource={{ type: "pages", ...D.selectKeys(page, ["id", "seo", "content"]) }}
    >
      <PageWrapper>
        <PageHeader
          breadcrumbs={[
            [_("breadcrumbs"), "/dashboard/pages"],
            [
              S.isEmpty(S.trim(t(page.seo).title ?? "")) ? _("no-title") : t(page.seo).title,
              `/dashboard/pages/${id}`,
            ],
          ]}
        />
        <SeoItem
          menu={<ContextMenu {...{ page, confirmDelete, openStats }} />}
          action={
            <>
              <Button icon size="xxs" onClick={openStats} aria-label={_("menu.stats")}>
                <BarChart3 aria-hidden />
              </Button>
              <Badge
                variant={isDraft ? "secondary" : "success"}
                icon
                aria-label={_(`state-${page.state}`)}
              >
                {isDraft ? <EyeOff aria-hidden /> : <Eye aria-hidden />}
              </Badge>
            </>
          }
        />
        <ContentItems />
        <StatsDialog {...statsProps} />
        <Confirm {...deleteProps} />
      </PageWrapper>
    </CmsContextProvider>
  )
}

export default PagesPage

/**
 * ContextMenu
 */
type ContextMenuProps = {
  page: Page
  confirmDelete: () => void
  openStats: () => void
}
export const ContextMenu: React.FC<ContextMenuProps> = ({ page, confirmDelete, openStats }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const isSuperadmin = useIsSuperadmin()
  const isPublished = page.state === "published"

  const canManageState = isSuperadmin ? true : !page.lock
  const toggleState = () => updatePage(page.id, { state: isPublished ? "draft" : "published" })

  const canDelete = isSuperadmin ? true : !page.lock
  const toggleLock = () => updatePage(page.id, { lock: !page.lock })

  return (
    <>
      <Menu.Item onClick={openStats}>
        <BarChart3 aria-hidden />
        {_("stats")}
      </Menu.Item>
      {canManageState && (
        <Menu.Item onClick={toggleState}>
          {isPublished ? <Eye aria-hidden /> : <EyeOff aria-hidden />}
          {_(isPublished ? "unpublish" : "publish")}
        </Menu.Item>
      )}
      {isSuperadmin && (
        <Menu.Item onClick={toggleLock}>
          {page.lock ? <LockOpen aria-hidden /> : <Lock aria-hidden />}
          {_(page.lock ? "unlock" : "lock")}
        </Menu.Item>
      )}
      {canDelete && (
        <Menu.Item onClick={confirmDelete}>
          <Trash aria-hidden />
          {_("delete")}
        </Menu.Item>
      )}
    </>
  )
}
