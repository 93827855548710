import { api, AuthErrorCode, NoContent } from "@/services/api"
import { z } from "zod"
import { ApiPage, apiPage } from "./schemas"

/**
 * schemas
 */
const createPayload = z.object({
  lock: z.boolean().optional(),
  state: z.enum(["draft", "published"]).optional(),
})
const updatePayload = z.object({
  lock: z.boolean().optional(),
  state: z.enum(["draft", "published"]).optional(),
})

export type Payload = {
  create: z.infer<typeof createPayload>
  update: z.infer<typeof updatePayload>
}

/**
 * service
 */
export const service = {
  index: async () => {
    type RSuccess = { pages: ApiPage[] }
    type RError = AuthErrorCode
    const { success, data } = await api.get<RSuccess, RError>("pages")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { pages: A.map(data.pages, apiPage.parse) }, error: false } as const
  },
  create: async (payload: Payload["create"]) => {
    type RSuccess = { page: ApiPage }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.post<RSuccess, RError>("pages", {
      data: createPayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { page: apiPage.parse(data.page) }, error: false } as const
  },
  read: async (id: string) => {
    type RSuccess = { page: ApiPage }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`pages/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { page: apiPage.parse(data.page) }, error: false } as const
  },
  update: async (id: string, payload: Payload["update"]) => {
    type RSuccess = { page: ApiPage }
    type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
    const { success, data } = await api.put<RSuccess, RError>(`pages/${id}`, {
      data: updatePayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { page: apiPage.parse(data.page) }, error: false } as const
  },
  delete: async (id: string) => {
    type RSuccess = NoContent
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.delete<RSuccess, RError>(`pages/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: {}, error: false } as const
  },
}
