import { ApiContent, apiContent, ApiContentItemAny, apiContentItemAny } from "@/components/cms/items/schemas"
import { api, AuthErrorCode } from "@/services/api"
import { z } from "zod"

/**
 * schemas
 */
const updatePayload = z.object({
  translations: z.array(
    z.object({
      languageId: z.string().uuid(),
      title: z.string(),
      description: z.string(),
      keywords: z.array(z.string()),
      socials: z.array(
        z.object({
          name: z.string(),
          content: z.string(),
        })
      ),
      imageId: z.string().uuid().nullable(),
    })
  ),
})
const createItemPayload = z.object({
  type: z.string(),
  state: z.enum(["draft", "published"]).optional(),
  order: z.number(),
  props: z.record(z.any()),
  translations: z.array(
    z.object({
      languageId: z.string().uuid(),
      props: z.record(z.any()),
    })
  ),
  files: z.array(z.string().uuid()),
})
const updateItemPayload = z.object({
  type: z.string().optional(),
  state: z.enum(["draft", "published"]).optional(),
  props: z.record(z.any()).optional(),
  translations: z
    .array(
      z.object({
        languageId: z.string().uuid(),
        props: z.record(z.any()),
      })
    )
    .optional(),
  files: z.array(z.string().uuid()).optional(),
})
const reorderItemsPayload = z.object({
  items: z.array(z.string()),
})
const sortedIdSchema = z.array(z.string())
export type Payload = {
  update: z.infer<typeof updatePayload>
  items: {
    create: z.infer<typeof createItemPayload>
    update: z.infer<typeof updateItemPayload>
    reorder: z.infer<typeof reorderItemsPayload>
  }
}

/**
 * service
 */
export const service = {
  update: async (model: string, modelId: string, payload: Payload["update"]) => {
    type RSuccess = { content: ApiContent }
    type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
    const { success, data } = await api.put<RSuccess, RError>(`${model}/${modelId}/content`, {
      data: updatePayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { content: apiContent.parse(data.content) }, error: false } as const
  },
  items: {
    create: async (model: string, modelId: string, payload: Payload["items"]["create"]) => {
      type RSuccess = { item: ApiContentItemAny; sortedIds: string[] }
      type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
      const { success, data } = await api.post<RSuccess, RError>(`${model}/${modelId}/content/items`, {
        data: createItemPayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return {
        data: { item: apiContentItemAny.parse(data.item), sortedIds: sortedIdSchema.parse(data.sortedIds) },
        error: false,
      } as const
    },
    update: async (model: string, modelId: string, itemId: string, payload: Payload["items"]["update"]) => {
      type RSuccess = { item: ApiContentItemAny }
      type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
      const { success, data } = await api.put<RSuccess, RError>(`${model}/${modelId}/content/items/${itemId}`, {
        data: updateItemPayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { item: apiContentItemAny.parse(data.item) }, error: false } as const
    },
    delete: async (model: string, modelId: string, itemId: string) => {
      type RSuccess = { sortedIds: string[] }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.delete<RSuccess, RError>(`${model}/${modelId}/content/items/${itemId}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { sortedIds: sortedIdSchema.parse(data.sortedIds) }, error: false } as const
    },
    reorder: async (model: string, modelId: string, payload: Payload["items"]["reorder"]) => {
      type RSuccess = { sortedIds: string[] }
      type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
      const { success, data } = await api.put<RSuccess, RError>(`${model}/${modelId}/content/items`, {
        data: reorderItemsPayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { sortedIds: sortedIdSchema.parse(data.sortedIds) }, error: false } as const
    },
  },
}
