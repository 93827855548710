import { useEventListener } from "@/hooks/useEventListener"

type WithinProps = {
  children: React.ReactNode
  className?: CX
  onFocusOut?: Voided
  onFocusIn?: Voided
}

const timeout: { current: Option<ReturnType<typeof setTimeout>> } = { current: null }

export const Within: React.FC<WithinProps> = ({
  onFocusOut = F.ignore,
  onFocusIn = F.ignore,
  ...props
}) => {
  const focusRef = React.useRef<HTMLDivElement>(null)

  useEventListener(
    "focusin",
    () => {
      if (timeout.current) clearTimeout(timeout.current)
      onFocusIn()
    },
    focusRef
  )

  useEventListener(
    "focusout",
    () => {
      timeout.current = setTimeout(() => {
        onFocusOut()
      }, 100)
    },
    focusRef
  )

  return (
    <div ref={focusRef} className={props.className}>
      {props.children}
    </div>
  )
}
