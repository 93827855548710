import {
  Form,
  FormAssertive,
  FormInput,
  FormSubmit,
  FormTextarea,
  FormTranslationTabs,
  useForm,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { createArticleCategory } from "@/store/articles/actions"
import { useLanguagesById } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import type { UseDialogFormProps, UseDialogProps } from "../ui/hooks/useDialog"

/**
 * dictionary src/dictionaries/en/components/dialogs/article-category-create-dialog.json
 */
const dictionary = createContextMapper("components", "dialogs", "article-category-create-dialog")

/**
 * ArticleCategoryCreateDialog
 */
export const ArticleCategoryCreateDialog: React.FC<UseDialogProps<void>> = props => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="max-w-xl">
      <DialogForm {...props} />
    </Dialog>
  )
}
const DialogForm: React.FC<UseDialogFormProps<void>> = ({ onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      translations: D.map(languagesById, language => ({
        languageId: language.id,
        name: "",
        description: "",
      })),
    })),
    onSubmit: async ({ values }) => {
      const payload = { translations: D.values(values.translations) }
      return match(await createArticleCategory(payload))
        .with({ error: false }, () => {
          toast.success(_("success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormInput
              label={_form("category-name-label")}
              name="name"
              placeholder={_form("category-name-placeholder")}
            />
            <FormTextarea
              label={_form("category-description-label")}
              name="description"
              placeholder={_form("category-description-placeholder")}
            />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("create")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
