import { Image } from "@/components/ui/image"
import { useLightboxFiles } from "@/components/ui/lightbox"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { useTranslation } from "@/store/languages/hooks"
import { useMediasFiles } from "@/store/medias/hooks"
import PhotoAlbum, { RenderPhoto } from "react-photo-album"
import { match } from "ts-pattern"
import { container } from "../../frontend/container"
import { Header } from "../../frontend/header"
import { ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel, template } = item.props
  const { title, secondary } = t(item).props
  const files = useMediasFiles(item.props.images)
  const images = React.useMemo<PhotoAlbumLightboximage[]>(
    () =>
      A.filterMap(files, file =>
        file.metaData.height && file.metaData.width
          ? {
              ...D.selectKeys(file, ["id", "url", "size", "extension"]),
              name: t(file).alt,
              key: file.id,
              src: file.url,
              height: file.metaData.height,
              width: file.metaData.width,
              alt: t(file).alt,
            }
          : O.None
      ),
    [files, t]
  )
  const { renderLightbox, lightbox } = useLightboxFiles(images)
  const templateProps = {
    images,
    lightbox,
  }

  if (!oneIsNotEmpty(title, stripeHtml(secondary)) && A.isEmpty(images)) return null

  return (
    <div className={cx("relative flex flex-col py-8 gap-8 font-plus", container({ x: "sm" }))}>
      <Header {...{ title, secondary, titleLevel }} />
      {match(template)
        .with(0, () => <Template1 {...templateProps} />)
        .with(1, () => <Template2 {...templateProps} />)
        .with(2, () => <Template3 {...templateProps} />)
        .with(3, () => <Template4 {...templateProps} />)
        .otherwise(() => null)}
      {renderLightbox()}
    </div>
  )
}

/**
 * templates
 */
const Template1: React.FC<TemplateProps> = ({ images, lightbox }) => (
  <ul>
    <PhotoAlbum
      layout="masonry"
      columns={3}
      photos={images}
      renderPhoto={renderPhoto(lightbox)}
      spacing={16}
    />
  </ul>
)
const Template2: React.FC<TemplateProps> = ({ images, lightbox }) => (
  <ul>
    <PhotoAlbum
      layout="rows"
      rowConstraints={{
        maxPhotos: 4,
      }}
      photos={images}
      renderPhoto={renderPhoto(lightbox)}
      spacing={16}
    />
  </ul>
)
const Template3: React.FC<TemplateProps> = ({ images, lightbox }) => (
  <ul>
    <PhotoAlbum
      layout="columns"
      columns={3}
      photos={images}
      renderPhoto={renderPhoto(lightbox)}
      spacing={16}
    />
  </ul>
)
const Template4: React.FC<TemplateProps> = ({ images, lightbox }) => (
  <ul className="grid grid-cols-3 gap-4">
    {A.map(images, photo => (
      <li
        key={photo.id}
        className="aspect-square cursor-pointer rounded-md"
        onClick={() => lightbox(photo.id)}
      >
        <Image alt={photo.alt} src={photo.src} className="size-full object-cover rounded-md" />
      </li>
    ))}
  </ul>
)
const renderPhoto: (lightbox: (id: string) => void) => RenderPhoto<PhotoAlbumLightboximage> =
  lightbox =>
  ({ photo, wrapperStyle }) =>
    (
      <li
        className="cursor-pointer  rounded-md"
        style={wrapperStyle}
        onClick={() => lightbox(photo.id)}
      >
        <Image alt={photo.alt} src={photo.src} className="size-full object-cover  rounded-md" />
      </li>
    )

/**
 * types
 */
type TemplateProps = {
  images: PhotoAlbumLightboximage[]
  lightbox: (id: string) => void
}
type PhotoAlbumLightboximage = {
  id: string
  name: string
  key: string
  src: string
  height: number
  width: number
  alt: string
  size: number
  extension: string
  url: string
}
