import { LuxembourgMap } from "@/components/frontend/map"
import { Prose } from "@/components/frontend/prose"
import { HN } from "@/components/ui/hn"
import { Image } from "@/components/ui/image"
import { LinkExternal } from "@/components/ui/link"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { useTranslation } from "@/store/languages/hooks"
import { getPreview, getThumbnail } from "@/store/medias/helpers"
import { useMediasFile } from "@/store/medias/hooks"
import { Envelope } from "@phosphor-icons/react"
import { container } from "../../frontend/container"
import { ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel, image, imageSmall } = item.props
  const { title, secondary, mapTitle, mapSecondary, content, contactTitle } = t(item).props
  const imageFile = useMediasFile(image)
  const imageSmallFile = useMediasFile(imageSmall)
  return (
    <div className="flex flex-col font-plus">
      <div
        className={cx(
          "relative w-full flex justify-between overflow-hidden xl:rounded-[2px] pb-2 mb-10 min-h-[300px] text-white",
          container({ x: "head" })
        )}
      >
        <Image className="absolute inset-0 size-full object-cover" src={getPreview(imageFile)} />
        <div
          className="absolute inset-0 size-full bg-gradient-to-b from-[#002c41]/20 from-5% to-[#002c41]/80 to-80%"
          aria-hidden
        />
        <div className="relative w-full flex flex-col justify-end gap-x-[30px]">
          <Image
            className="absolute top-[30px] right-[30px] size-24 object-cover"
            src={getThumbnail(imageSmallFile)}
          />
          <div className="flex flex-row items-end justify-between w-full px-[30px] py-9 gap-x-[30px] gap-y-8">
            <HN level={titleLevel} className="max-w-xl text-3xl font-bold">
              {title}
            </HN>
            {oneIsNotEmpty(secondary) && (
              <div className="p-5 border border-white text-sm font-medium uppercase">
                {secondary}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={cx("flex flex-col pb-8 gap-8 ", container({ x: "md" }))}>
        <div className="w-full mt-20 border rounded-[2px] border-frontend-mercury py-8 md:py-[70px] flex flex-col lg:flex-row gap-14 justify-between items-end lg:items-center">
          <div className="ml-10 md:border-l-[4px] md:border-frontend-tomato md:pl-10 md:py-6 text-base flex flex-col gap-6 lg:max-w-md flex-1">
            <HN level={titleLevel} className="text-2xl font-bold">
              {mapTitle}
            </HN>
            {oneIsNotEmpty(stripeHtml(mapSecondary)) && (
              <Prose dangerouslySetInnerHTML={{ __html: mapSecondary }} className="-my-6" />
            )}
          </div>
          <div className="relative w-full lg:flex-1 max-w-[380px] lg:max-w-[520px] h-[200px]">
            <div>
              <LuxembourgMap
                each={cx(
                  "cursor-pointer transition-all",
                  "fill-neutral-50/80 hover:fill-neutral-50 stroke-neutral-300",
                  "dark:fill-neutral-900 dark:hover:fill-neutral-800 dark:stroke-neutral-400 ",
                  "hover:stroke-frontend-primary"
                )}
                className="w-full left-0 right-0 top-0 absolute z-20 lg:translate-y-[-40%] lg:top-[50%]"
              />
              <svg
                className="w-full left-0 right-0 top-0 lg:absolute z-10 lg:translate-y-[-40%] lg:top-[50%] fill-card"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 553 793"
              >
                <path d="M70 0h283l200 397-46 396H20L0 397 70 0Z" />
              </svg>
            </div>
          </div>
        </div>
        <Prose dangerouslySetInnerHTML={{ __html: content }} className="max-w-lg" />
        <HN level={titleLevel} className="text-xl font-bold text-frontend-shark max-w-lg">
          {contactTitle}
        </HN>
        <div className="flex gap-8">
          {A.filterMap(item.props.contacts, id => {
            const contact = D.get(t(item).props.contacts, id)
            if (!contact) return O.None
            return (
              <div
                key={id}
                className="flex flex-col gap-2 p-8 border border-frontend-mercury text-frontend-shark  text-sm"
              >
                <div>
                  <span className="text-frontend-orient uppercase font-bold">{contact.name}</span>{" "}
                  {oneIsNotEmpty(contact.company) && <span>({contact.company})</span>}
                </div>
                <div className="">{contact.region}</div>
                <div className="flex items-center gap-2">
                  <Envelope size={16} strokeWidth={1} />
                  <LinkExternal href={`mailto${contact.email}`} className="underline">
                    {contact.email}
                  </LinkExternal>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
