import { Video } from "@/components/ui/video"
import { YoutubeIFrame } from "@/components/ui/youtube"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { useTranslation } from "@/store/languages/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import { container } from "../../frontend/container"
import { Header } from "../../frontend/header"
import { ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel, youtube } = item.props
  const { title, secondary } = t(item).props
  const video = useMediasFile(item.props.video)

  if (!oneIsNotEmpty(title, stripeHtml(secondary)) && G.isNullable(youtube) && G.isNullable(video))
    return null
  return (
    <div className={cx("relative flex flex-col py-8 gap-8 font-plus", container({ x: "sm" }))}>
      <Header {...{ title, secondary, titleLevel }} />
      {G.isNotNullable(youtube) ? (
        <YoutubeIFrame
          videoId={youtube}
          title=""
          className="rounded-md bg-muted"
          options={{ autoplay: false }}
        />
      ) : G.isNotNullable(video) ? (
        <Video
          src={video.url}
          aria-label={t(video).alt}
          wrapperCx="w-full aspect-video rounded-md overflow-hidden bg-muted"
        />
      ) : null}
    </div>
  )
}

/**
 * templates
 */
// {
//   match(template)
//     .with(0, () => <Template1 {...{ item }} />)
//     .otherwise(() => null)
// }
// type TemplateProps = { item: ContentItemBase & ContentItemMapping[ItemType] }
// const Template1: React.FC<TemplateProps> = ({ item }) => (<></>)
