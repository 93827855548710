import { apiContentItemBase, apiContentItemTranslation } from "@/services/contents/schemas"
import { z } from "zod"

/**
 * schemas
 */
export const itemType = "cards"
export const itemProps = z.object({
  titleLevel: z.number().default(1),
  template: z.number().default(0),
  cards: z.string().array(),
  cardsProps: z.record(
    z.string(),
    z.object({
      image: z.string().nullable(),
    })
  ),
})
export const itemTranslation = apiContentItemTranslation.extend({
  props: z.object({
    title: z.string(),
    secondary: z.string(),
    cards: z.record(
      z.string(),
      z.object({
        title: z.string(),
        secondary: z.string(),
        content: z.string(),
        linkText: z.string(),
        linkUrl: z.string(),
      })
    ),
  }),
})

/**
 * exports
 */
export const apiContentItem = apiContentItemBase.extend({
  type: z.literal(itemType),
  props: itemProps,
  translations: itemTranslation.array(),
})
export type ApiContentItem = z.infer<typeof apiContentItem>
export type ItemType = typeof itemType
export type ItemProps = z.infer<typeof itemProps>
export type ItemTranslation = z.infer<typeof itemTranslation>
export type ContentItem = {
  type: ItemType
  props: ItemProps
  translations: ByLanguage<ItemTranslation>
}
