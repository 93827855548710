import { createGradient } from "@/fns/colors"
import { WorkshopEvent } from "@/store/workshops/localizers"

/**
 * useBookingValues
 */
export const useBookingValues = (events: WorkshopEvent[]) => {
  const [totalSlots, totalBooking] = React.useMemo(
    () =>
      A.reduce(events, [0, 0], ([slots, bookings], { reservationsSlot, reservationsDone }) => [
        slots + reservationsSlot,
        bookings + reservationsDone,
      ]),
    [events]
  )
  const bookingPercent = React.useMemo(
    () => (totalSlots > 0 ? N.clamp(Math.floor((100 / totalSlots) * totalBooking), 0, 100) : 100),
    [totalSlots, totalBooking]
  )
  const bookingPercentColor = React.useMemo(
    () => bookingGradient.ratio(bookingPercent / 100),
    [bookingPercent]
  )
  const bookingPercentMedian = React.useMemo(
    () =>
      Math.floor(
        events.length > 0
          ? A.reduce(
              events,
              0,
              (acc, { reservationsSlot, reservationsDone }) =>
                acc +
                (reservationsSlot > 0
                  ? N.clamp(Math.floor((100 / reservationsSlot) * reservationsDone), 0, 100)
                  : 100)
            ) / events.length
          : 100
      ),
    [events]
  )
  const bookingPercentMedianColor = React.useMemo(
    () => bookingGradient.ratio(bookingPercentMedian / 100),
    [bookingPercentMedian]
  )
  return {
    totalSlots,
    totalBooking,
    bookingPercent,
    bookingPercentColor,
    bookingPercentMedian,
    bookingPercentMedianColor,
  }
}

/**
 * helpers
 */
const bookingGradient = createGradient("#dc2626", "#d97706", "#16a34a")
