import { Globe, Info } from "lucide-react"
import { FormGroup, FormGroupError, FormGroupInfo, FormLabel } from "."
import { Button } from "../ui/button"
import { Popover } from "../ui/popover"
import { SrOnly } from "../ui/sr-only"

/**
 * dictionary src/dictionaries/fr/components/form/group.json
 */
const dictionary = createContextMapper("components", "form", "group")
/**
 * FormMediaWrapper
 */
export type FormFieldWrapperProps = {
  label?: string
  labelCx?: ClassName
  labelAside?: React.ReactNode
  labelPopover?: React.ReactNode
  required?: boolean
  name?: string
  info?: string | false
  translatable?: boolean
}
export const FormFieldWrapper: React.FC<React.PropsWithChildren<FormFieldWrapperProps>> = ({
  label,
  labelCx,
  labelAside,
  labelPopover,
  name,
  info,
  translatable = false,
  required,
  children,
}) => {
  const { _ } = useDictionary(dictionary())
  return (
    <FormGroup name={name}>
      <div className={cx("flex justify-between items-center min-h-6", labelCx)}>
        <FormLabel required={required}>{label}</FormLabel>
        {G.isNotNullable(labelAside) && labelAside}
        {G.isNotNullable(labelPopover) && <AsidePopover>{labelPopover}</AsidePopover>}
        {translatable && (
          <Button
            icon
            variant="ghost"
            size="xxs"
            aria-label={_("translatable")}
            className="text-muted-foreground"
          >
            <Globe aria-hidden />
          </Button>
        )}
      </div>
      {children}
      <FormGroupError />
      {info !== false && <FormGroupInfo>{info}</FormGroupInfo>}
    </FormGroup>
  )
}

const AsidePopover: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Popover>
      <Popover.Trigger asChild>
        <Button icon variant="ghost" size="xs" className="text-muted-foreground">
          <Info aria-hidden size={18} />
          <SrOnly>{_("info")}</SrOnly>
        </Button>
      </Popover.Trigger>
      <Popover.Content side="left" align="start" className="text-sm">
        {children}
      </Popover.Content>
    </Popover>
  )
}

type Props = FormFieldWrapperProps & { [key: string]: any }
export const extractWrapperProps = (props: Props): FormFieldWrapperProps => {
  const { label, labelCx, labelAside, labelPopover, name, required, info, translatable } = props
  return { label, labelCx, labelAside, labelPopover, name, required, info, translatable }
}
export const extractInputProps = <T extends Record<string, unknown>>(
  props: Omit<FormFieldWrapperProps, "required"> & T
): { [key: string]: any } => {
  const inputProps = D.deleteKeys(props, [
    "label",
    "labelCx",
    "labelAside",
    "name",
    "info",
    "translatable",
  ])
  return inputProps as T
}
