import { Button, buttonVariants } from "@/components/ui/button"
import { useSpotlight } from "@/components/ui/hooks/useSpotlight"
import { Image, ImageProps, ImageRef } from "@/components/ui/image"
import { Link } from "@/components/ui/link"
import { Spotlight } from "@/components/ui/spotlight"
import { SrOnly } from "@/components/ui/sr-only"
import { MoreVertical } from "lucide-react"
import { Menu } from "./"

/**
 * CardRoot
 */
type CardRootProps = {
  size?: "default" | "sm" | "xs"
  disabled?: boolean
  selected?: boolean
} & React.ComponentPropsWithRef<"div">
const CardRoot: React.FC<CardRootProps> = ({
  size = "default",
  disabled,
  selected,
  className,
  children,
  ...props
}) => {
  const { cardProps, cardSpotlightProps } = useSpotlight()
  return (
    <div
      className={cx(
        "relative flex flex-col rounded-lg bg-card text-card-foreground border border-muted shadow-sm overflow-hidden",
        isTouchDevice() && "select-none touch-callout-none",
        disabled && "opacity-50",
        size === "default" ? "" : "",
        className
      )}
      {...cardProps}
      {...props}
    >
      {children}
      {selected && (
        <div
          className="absolute inset-0 pointer-events-none border border-primary rounded-lg"
          aria-hidden
        />
      )}
      <Spotlight {...cardSpotlightProps} />
    </div>
  )
}

const CardNoSpotlight: React.FC<CardRootProps> = ({
  size = "default",
  disabled,
  selected,
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={cx(
        "relative flex flex-col rounded-lg bg-card text-card-foreground border border-muted shadow-sm overflow-hidden",
        isTouchDevice() && "select-none touch-callout-none",
        disabled && "opacity-50",
        size === "default" ? "" : "",
        className
      )}
      {...props}
    >
      {children}
      {selected && (
        <div
          className="absolute inset-0 pointer-events-none border border-primary rounded-lg"
          aria-hidden
        />
      )}
    </div>
  )
}

/**
 * CardImage
 */
const CardImage = React.forwardRef<ImageRef, ImageProps>(({ className, ...props }, ref) => (
  <Image
    className={cx(
      "w-full aspect-video object-cover rounded-t-md text-muted-foreground/10 pointer-events-none",
      className
    )}
    ref={ref}
    {...props}
  />
))

/**
 * CardHeader
 */
type CardHeaderProps = {
  size?: "default" | "sm" | "xs"
} & React.ComponentPropsWithRef<"div">
const CardHeader = React.forwardRef<HTMLDivElement, CardHeaderProps>(
  ({ size = "default", className, ...props }, ref) => (
    <div
      ref={ref}
      className={cx(
        "relative flex flex-col gap-y-2",
        size === "default" ? "p-4 pr-12" : "p-2 pr-8",
        className
      )}
      {...props}
    />
  )
)

/**
 * CardTitle
 */
type CardTitleProps = {
  size?: "default" | "sm" | "xs"
  href?: string
} & React.ComponentPropsWithRef<"h3">
const CardTitle = React.forwardRef<HTMLParagraphElement, CardTitleProps>(
  ({ size = "default", href, className, children, ...props }, ref) => (
    <h3
      ref={ref}
      className={cx(
        "text-lg font-medium min-h-[1rem] leading-none tracking-wide",
        size === "default" ? "" : "",
        className
      )}
      {...props}
    >
      {href ? (
        <Link
          href={href}
          className={cx(
            buttonVariants({
              variant: "link",
              size: "link",
              className: cx("font-normal text-inherit"),
            })
          )}
        >
          {children}
        </Link>
      ) : (
        children
      )}
    </h3>
  )
)

/**
 * CardDescription
 */
type CardDescriptionProps = {
  size?: "default" | "sm" | "xs"
} & React.ComponentPropsWithRef<"p">
const CardDescription = React.forwardRef<HTMLParagraphElement, CardDescriptionProps>(
  ({ size = "default", className, ...props }, ref) => (
    <p
      ref={ref}
      className={cx(
        "text-sm font-light text-muted-foreground [&_svg]:w-3 [&_svg]:h-3",
        size === "default" ? "" : "",
        className
      )}
      {...props}
    />
  )
)

/**
 * CardSecondary
 */
type CardSecondaryProps = {
  size?: "default" | "sm" | "xs"
} & React.ComponentPropsWithRef<"p">
const CardSecondary = React.forwardRef<HTMLParagraphElement, CardSecondaryProps>(
  ({ size = "default", className, ...props }, ref) => (
    <p
      ref={ref}
      className={cx(
        "text-sm font-light text-muted-foreground",
        size === "default" ? "" : "",
        className
      )}
      {...props}
    />
  )
)

/**
 * CardMenu
 */
const CardMenu: React.FC<React.ComponentPropsWithoutRef<"button"> & { menu: React.ReactNode }> = ({
  className,
  menu,
  ...props
}) => {
  const { _ } = useDictionary("components.layout.menu")
  return (
    <Menu menu={menu} type="dropdown-menu" align="start" side="left">
      <Button
        variant="ghost"
        size="xs"
        icon
        className={cx("absolute top-2 right-2 rounded-full", className)}
        {...props}
      >
        <MoreVertical aria-hidden />
        <SrOnly>{_("button")}</SrOnly>
      </Button>
    </Menu>
  )
}

/**
 * CardContent
 */
const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cx("p-4 pt-0", className)} {...props} />
  )
)

/**
 * CardFooter
 */
const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cx("flex items-center p-4 pt-0", className)} {...props} />
  )
)

/**
 * exports
 */
export const Card = Object.assign(CardRoot, {
  NoSpotlight: CardNoSpotlight,
  Image: CardImage,
  Header: CardHeader,
  Title: CardTitle,
  Description: CardDescription,
  Menu: CardMenu,
  Content: CardContent,
  Secondary: CardSecondary,
  Footer: CardFooter,
})

/**
 * helpers
 */
const isTouchDevice = (): boolean => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0
}
