import { useResponsive } from "@/hooks/useResponsive"
import { useToggle } from "@/hooks/useToggle"
import { IconContext } from "@phosphor-icons/react"

/**
 * Context for frontend layout
 */
export const LayoutContext = React.createContext<LayoutContextType>({} as LayoutContextType)
export const useLayoutContext = () => React.useContext(LayoutContext)
export const LayoutContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const mobileMenu = useToggle()
  const loading = useToggle()

  /**
   * hide scrollbar when mobile menu is open
   */
  React.useEffect(() => {
    mobileMenu.state
      ? document.body.classList.add("overflow-hidden")
      : document.body.classList.remove("overflow-hidden")
  }, [mobileMenu.state])

  const media = useResponsive()

  /**
   * close mobile menu when screen size is lg
   */
  React.useEffect(() => {
    if (media.min("lg")) mobileMenu.close()
  }, [media, mobileMenu])

  return (
    <IconContext.Provider value={{ weight: "regular" }}>
      <LayoutContext.Provider value={{ mobileMenu, loading }}>{children}</LayoutContext.Provider>
    </IconContext.Provider>
  )
}

type LayoutContextType = {
  mobileMenu: ReturnType<typeof useToggle>
  loading: ReturnType<typeof useToggle>
}
