import { Button } from "@/components/ui/button"
import { Link, linkCx } from "@/components/ui/link"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { useCookies } from ".."

/**
 * dictionary src/dictionaries/fr/components/cookies.json
 */
const dictionary = createContextMapper("components", "cookies")

/**
 * ConsentDialog
 */
export const ConsentDialog: React.FC = () => {
  const { _ } = useDictionary(dictionary("consent"))
  const { isConsented, setPreferences, acceptAll, rejectAll } = useCookies()
  return (
    <DialogPrimitive.Root open={!isConsented} modal={false}>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Content
          className={cx(
            "fixed left-4 bottom-4 z-50 max-w-lg w-full rounded-sm",
            "bg-background shadow-sm border",
            cx(
              "duration-200",
              "data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95",
              "data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95"
            )
          )}
        >
          <div className="flex flex-col p-4 gap-1 border-b">
            <DialogPrimitive.Title className="text-base font-medium">
              {_("title")}
            </DialogPrimitive.Title>
            <DialogPrimitive.Description className="text-sm text-muted-foreground leading-tight">
              {_("description")}
            </DialogPrimitive.Description>
          </div>
          <div className="flex px-4 py-2 gap-4 justify-between border-b">
            <div className="flex gap-4">
              <Button variant="outline" onClick={acceptAll}>
                {_("accept-all")}
              </Button>
              <Button variant="outline" onClick={rejectAll}>
                {_("reject-all")}
              </Button>
            </div>
            <Button onClick={() => setPreferences(true)}>{_("manage-cookies")}</Button>
          </div>
          <div className="flex px-4 py-2 gap-4">
            <Link href="/data-protection" className={cx(linkCx, "text-sm text-muted-foreground")}>
              {_("data-protection")}
            </Link>
            <Link href="/legal-notice" className={cx(linkCx, "text-sm text-muted-foreground")}>
              {_("legal-notice")}
            </Link>
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}
