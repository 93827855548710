import { byId } from "@/fns/byId"
import * as Articles from "@/services/articles/service"
import * as Contents from "@/services/contents/service"
import * as Seos from "@/services/seos/service"
import { match } from "ts-pattern"
import { articlesStore } from "."
import { extractFilesFromContent, extractFilesFromContentItem } from "../contents/helpers"
import { localizeContent, localizeContentItem } from "../contents/localizers"
import { mediasStore } from "../medias"
import { localizeMediaFile } from "../medias/localizers"
import { extractFilesFromSeo } from "../seos/helpers"
import { localizeSeo } from "../seos/localizers"
import { usersStore } from "../users"
import { localizeUser } from "../users/localizers"
import {
  extractCategoriesFromArticles,
  extractFilesFromArticle,
  extractFilesFromArticles,
  extractUsersFromArticles,
} from "./helpers"
import { localizeArticle, localizeArticleCategory } from "./localizers"

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * articles
 */

/**
 * getArticles
 */
export const getArticles = async () =>
  match(await Articles.service.index())
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromArticles(data.articles), localizeMediaFile)),
      })
      usersStore.evolve({
        users: D.merge(byId(extractUsersFromArticles(data.articles), localizeUser)),
      })
      articlesStore.evolve({
        articles: byId(data.articles, localizeArticle),
        categories: D.merge(
          byId(extractCategoriesFromArticles(data.articles), localizeArticleCategory)
        ),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createArticle
 */
export const createArticle = async (payload: Articles.Payload["create"]) =>
  match(await Articles.service.create(payload))
    .with({ error: false }, ({ data }) => {
      usersStore.evolve({
        users: D.merge(byId(extractUsersFromArticles([data.article]), localizeUser)),
      })
      articlesStore.evolve({
        articles: D.set(data.article.id, localizeArticle(data.article)),
        categories: D.merge(
          byId(extractCategoriesFromArticles([data.article]), localizeArticleCategory)
        ),
      })
      return { error: false, id: data.article.id } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getArticle
 */
export const getArticle = async (id: string) =>
  match(await Articles.service.read(id))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromArticle(data.article), localizeMediaFile)),
      })
      usersStore.evolve({
        users: D.merge(byId(extractUsersFromArticles([data.article]), localizeUser)),
      })
      articlesStore.evolve({
        articles: D.set(data.article.id, localizeArticle(data.article)),
        categories: D.merge(
          byId(extractCategoriesFromArticles([data.article]), localizeArticleCategory)
        ),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateArticle
 */
export const updateArticle = async (id: string, payload: Articles.Payload["update"]) =>
  match(await Articles.service.update(id, payload))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromArticle(data.article), localizeMediaFile)),
      })
      usersStore.evolve({
        users: D.merge(byId(extractUsersFromArticles([data.article]), localizeUser)),
      })
      articlesStore.evolve({
        articles: D.set(data.article.id, localizeArticle(data.article)),
        categories: D.merge(
          byId(extractCategoriesFromArticles([data.article]), localizeArticleCategory)
        ),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteArticle
 */
export const deleteArticle = async (id: string) =>
  match(await Articles.service.delete(id))
    .with({ error: false }, () => {
      articlesStore.evolve({ articles: D.deleteKey(id) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * seos
 */

/**
 * updateArticleSeo
 */
export const updateArticleSeo = async (id: string, payload: Seos.Payload["update"]) =>
  match(await Seos.service.update("articles", id, payload))
    .with({ error: false }, async ({ data }) => {
      if (G.isNullable(D.get(articlesStore.current.articles, id))) return await getArticle(id)
      mediasStore.evolve({ files: D.merge(byId(extractFilesFromSeo(data.seo), localizeMediaFile)) })
      articlesStore.evolve({
        articles: {
          [id]: {
            seo: localizeSeo(data.seo),
          },
        },
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * contents
 */

/**
 * updateArticleContent
 */
export const updateArticleContent = async (id: string, payload: Contents.Payload["update"]) =>
  match(await Contents.service.update("articles", id, payload))
    .with({ error: false }, async ({ data }) => {
      if (G.isNullable(D.get(articlesStore.current.articles, id))) return await getArticle(id)
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromContent(data.content), localizeMediaFile)),
      })
      articlesStore.evolve({
        articles: {
          [id]: {
            content: localizeContent(data.content),
          },
        },
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createArticleContentItem
 */
export const createArticleContentItem = async (
  id: string,
  payload: Contents.Payload["items"]["create"]
) =>
  match(await Contents.service.items.create("articles", id, payload))
    .with({ error: false }, async ({ data }) => {
      if (G.isNullable(D.get(articlesStore.current.articles, id))) return await getArticle(id)
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromContentItem(data.item), localizeMediaFile)),
      })
      articlesStore.evolve({
        articles: {
          [id]: {
            content: {
              items: flow(
                D.set(data.item.id, localizeContentItem(data.item)),
                D.map(item =>
                  D.set(item, "order", A.getIndexBy(data.sortedIds, id => id === item.id) ?? 0)
                )
              ),
            },
          },
        },
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateArticlesContentItem
 */
export const updateArticleContentItem = async (
  id: string,
  itemId: string,
  payload: Contents.Payload["items"]["update"]
) =>
  match(await Contents.service.items.update("articles", id, itemId, payload))
    .with({ error: false }, async ({ data }) => {
      if (G.isNullable(D.get(articlesStore.current.articles, id))) return await getArticle(id)
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromContentItem(data.item), localizeMediaFile)),
      })
      articlesStore.evolve({
        articles: {
          [id]: {
            content: {
              items: D.set(data.item.id, localizeContentItem(data.item)),
            },
          },
        },
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteArticleContentItem
 */
export const deleteArticleContentItem = async (id: string, itemId: string) =>
  match(await Contents.service.items.delete("articles", id, itemId))
    .with({ error: false }, async () => {
      if (G.isNullable(D.get(articlesStore.current.articles, id))) return await getArticle(id)
      articlesStore.evolve({
        articles: {
          [id]: {
            content: {
              items: D.deleteKey(itemId),
            },
          },
        },
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * reorderArticleContentItems
 */
export const reorderArticleContentItems = async (
  id: string,
  payload: Contents.Payload["items"]["reorder"]
) => {
  // get article from store if it's not there get it from api
  if (G.isNullable(D.get(articlesStore.current.articles, id))) {
    const response = await getArticle(id)
    if (response.error) return { error: true, code: response.code } as const
  }

  // optimist update with new order before sending request
  articlesStore.evolve({
    articles: {
      [id]: {
        content: {
          items: flow(
            D.map(item =>
              D.set(item, "order", A.getIndexBy(payload.items, id => id === item.id) ?? 0)
            )
          ),
        },
      },
    },
  })

  return match(await Contents.service.items.reorder("articles", id, payload))
    .with({ error: false }, () => ({ error: false } as const))
    .otherwise(({ error, code }) => ({ error, code } as const))
}

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * categories
 */

/**
 * getArticleCategories
 */
export const getArticleCategories = async () =>
  match(await Articles.service.categories.index())
    .with({ error: false }, ({ data }) => {
      articlesStore.evolve({ categories: byId(data.categories, localizeArticleCategory) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createArticleCategory
 */
export const createArticleCategory = async (payload: Articles.Payload["categories"]["create"]) =>
  match(await Articles.service.categories.create(payload))
    .with({ error: false }, ({ data }) => {
      articlesStore.evolve({
        categories: D.set(data.category.id, localizeArticleCategory(data.category)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getArticleCategory
 */
export const getArticleCategory = async (id: string) =>
  match(await Articles.service.categories.read(id))
    .with({ error: false }, ({ data }) => {
      articlesStore.evolve({
        categories: D.set(data.category.id, localizeArticleCategory(data.category)),
        articles: D.merge(byId(data.articles, localizeArticle)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateArticleCategory
 */
export const updateArticleCategory = async (
  id: string,
  payload: Articles.Payload["categories"]["update"]
) =>
  match(await Articles.service.categories.update(id, payload))
    .with({ error: false }, ({ data }) => {
      articlesStore.evolve({
        categories: D.set(data.category.id, localizeArticleCategory(data.category)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteArticleCategory
 */
export const deleteArticleCategory = async (id: string) =>
  match(await Articles.service.categories.delete(id))
    .with({ error: false }, () => {
      articlesStore.evolve({ categories: D.deleteKey(id) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * cmsActions
 */
export const cmsActions = {
  updateSeo: updateArticleSeo,
  updateContent: updateArticleContent,
  createContentItem: createArticleContentItem,
  updateContentItem: updateArticleContentItem,
  deleteContentItem: deleteArticleContentItem,
  reorderContentItems: reorderArticleContentItems,
}
