/**
 * useDialog
 */
export const useDialog = <T = true>(props: { afterClose?: () => void } = {}) => {
  const { afterClose } = props
  const [open, setOpen] = React.useState<T | false>(false)
  return {
    open: open !== false,
    onOpenChange: () => {
      setOpen(false)
      afterClose && afterClose()
    },
    item: open,
    setItem: (value: T) => setOpen(value),
  }
}
export type UseDialogProps<T = void> = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: T | false
}
export type UseDialogFormProps<T = void> = T extends void
  ? {
      onOpenChange: (state: boolean) => void
    }
  : {
      onOpenChange: (state: boolean) => void
      item: T
    }
