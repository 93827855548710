import { themeColors } from "@/components/layout/dashboard/theme/context"
import { useTheme } from "./useTheme"
import { Button } from "@/components/ui/button"
import { DropdownMenu } from "@/components/ui/dropdown-menu"

import { Moon, Sun, SunMoon, Palette } from "lucide-react"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { SrOnly } from "@/components/ui/sr-only"

/**
 * ThemeDropdown
 */
type ThemeDropdownProps = {
  beforeClick?: () => true
  align?: DropdownMenuPrimitive.MenuContentProps["align"]
  side?: DropdownMenuPrimitive.MenuContentProps["side"]
}
export const ThemeDropdown: React.FC<ThemeDropdownProps> = ({
  beforeClick = () => true,
  align = "end",
  side = "right",
}) => {
  const { _ } = useDictionary("components.theme-toggle")
  const { setTheme, color: currentColor, setColor } = useTheme()
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <Button variant="ghost" icon className="rounded-full">
          <Sun
            size={20}
            className="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0"
            aria-hidden
          />
          <Moon
            size={20}
            className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"
            aria-hidden
          />
          <SrOnly>{_("button")}</SrOnly>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align={align} side={side}>
        <DropdownMenu.Item onClick={() => beforeClick() && setTheme("light")}>
          <Sun aria-hidden />
          {_("light")}
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={() => beforeClick() && setTheme("dark")}>
          <Moon aria-hidden />
          {_("dark")}
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={() => beforeClick() && setTheme("system")}>
          <SunMoon aria-hidden />
          {_("system")}
        </DropdownMenu.Item>
        <DropdownMenu.Sub>
          <DropdownMenu.Sub.Trigger>
            <Palette aria-hidden />
            {_("colors")}
          </DropdownMenu.Sub.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Sub.Content>
              {A.map([...themeColors], color => (
                <DropdownMenu.CheckboxItem
                  key={color}
                  checked={color === currentColor}
                  onCheckedChange={() => beforeClick() && setColor(color)}
                >
                  <span
                    className="inline-flex w-4 h-4 mr-2"
                    style={{ background: `hsl(var(--primary-${color}))` }}
                  />
                  {_(`colors-${color}`)}
                </DropdownMenu.CheckboxItem>
              ))}
            </DropdownMenu.Sub.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Sub>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
