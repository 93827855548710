/// <reference types="vite-plugin-svgr/client" />
import { Grid } from "@/components/collection"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { LastestProjects } from "./LastestProjects"
import { LastestUsers } from "./LastestUsers"
import { ReservationsStats } from "./ReservationsStats"
import { Today } from "./Today"
import { VisitsStats } from "./VisitsStats"

/**
 * Page: dashboard/index
 */
const DashboardIndex: React.FC = () => {
  return (
    <PageWrapper>
      <PageHeader breadcrumbs={[]} />
      <PageContent>
        <Grid view="grid">
          <Today />
          <VisitsStats />
          <LastestProjects />
          <LastestUsers />
          <ReservationsStats />
        </Grid>
      </PageContent>
    </PageWrapper>
  )
}
export default DashboardIndex
