import { SrOnly } from "@/components/ui/sr-only"

/**
 * dictionary src/dictionaries/en/components/page-loader.json
 */
const dictionary = createContextMapper("components", "page-loader")

/**
 * LoadingBar
 */
export const LoadingBar: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  return (
    <div className="fixed top-0 inset-x-0 w-full" role="status">
      <div className="relative h-0.5 overflow-hidden bg-primary/25" aria-hidden>
        <div className="animate-loading-bar absolute h-full w-4/5 rounded-full bg-primary/50" />
      </div>
      <SrOnly>{_("message")}</SrOnly>
    </div>
  )
}
