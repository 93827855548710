/// <reference types="vite-plugin-svgr/client" />
import Dance from "@/assets/icons/dance.svg?react"
import { Card, Fields, Menu, Row } from "@/components/collection"
import { Badge } from "@/components/ui/badge"
import { Link, linkCx } from "@/components/ui/link"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { useSelectItem } from "@/hooks/useSelect"
import { Project } from "@/store/projects/localizers"
import { ClockCountdown, MaskHappy, MusicNotes } from "@phosphor-icons/react"
import { CircleCheck, CircleX, Globe, ImageOff, Signpost } from "lucide-react"
import { Context, ContextMenu, usePageContext } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/projects.json
 */
const dictionary = createContextMapper("pages", "dashboard", "projects")

/**
 * Item
 */
type Props = { project: Project }
const Item: React.FC<Props> = props => {
  const { project } = props
  const { selectItemProps } = useSelectItem(Context, project.id, () =>
    navigate(`/dashboard/projects/${project.id}`)
  )
  const { view } = usePageContext()

  return (
    <Menu type="context-menu" menu={<ContextMenu {...props} />} asChild>
      <div
        className="grid"
        {...selectItemProps}
        onDoubleClick={() => navigate(`/dashboard/projects/${project.id}`)}
      >
        {view === "grid" ? <ItemCard {...props} /> : <ItemRow {...props} />}
      </div>
    </Menu>
  )
}
export default Item

/**
 * ItemCard
 */
const ItemCard: React.FC<Props> = ({ project }) => {
  const { _ } = useDictionary(dictionary())
  const format = useDateFnsLocaleFormat()
  const { isSelected } = useSelectItem(Context, project.id)
  const { name, image, category, orientation, language } = project
  const description = React.useMemo(
    () => project.description.replace(/(<([^>]+)>)/gi, ""),
    [project.description]
  )
  return (
    <Card selected={isSelected}>
      <Card.Image src={image?.url}>
        <ImageOff size={64} strokeWidth={0.8} aria-hidden />
      </Card.Image>
      <Card.Header>
        <Card.Title>{name}</Card.Title>
        <Card.Description>
          {_("created")}{" "}
          {project.createdBy && (
            <>
              {_("created-by")}{" "}
              <Link href={`/dashboard/users/${project.createdBy.id}`} className={linkCx}>
                {`${project.createdBy.profile.firstname} ${project.createdBy.profile.lastname}`}
              </Link>
            </>
          )}{" "}
          {_("created-at")} {format(project.createdAt, "PPPPp")}
        </Card.Description>
        <Card.Description>{description}</Card.Description>
        <Card.Menu menu={<ContextMenu project={project} />} />
      </Card.Header>
      <Card.Content>
        <Fields divider>
          <Fields.Item
            name={_("category")}
            icon={
              <>
                {category === "dance" && <Dance aria-hidden />}
                {category === "theater" && <MaskHappy aria-hidden />}
                {category === "music" && <MusicNotes aria-hidden />}
              </>
            }
            value={_(`category-${category}`)}
            stretch
          />
          <Fields.Item
            name={_("orientation")}
            icon={<Signpost size={16} aria-hidden />}
            value={_(`orientation-${orientation}`)}
            stretch
          />
          <Fields.Item
            name={_("language")}
            icon={<Globe size={16} aria-hidden />}
            value={_(`language-${language as "en" | "fr" | "de"}`)}
            stretch
          />
        </Fields>
        <div className="flex justify-around mt-4">
          <Badge
            variant={
              project.status === "accepted"
                ? "success"
                : project.status === "pending"
                ? "secondary"
                : "destructive"
            }
          >
            {project.status === "accepted" && <CircleCheck aria-label={_(`status-accepted`)} />}
            {project.status === "refused" && <CircleX aria-label={_(`status-refused`)} />}
            {project.status === "pending" && <ClockCountdown aria-label={_(`status-pending`)} />}
            {_(`status-${project.status}`)}
          </Badge>
        </div>
      </Card.Content>
    </Card>
  )
}

/**
 * ItemRow
 */
const ItemRow: React.FC<Props> = ({ project }) => {
  const { _ } = useDictionary(dictionary())
  const format = useDateFnsLocaleFormat()
  const { isSelected } = useSelectItem(Context, project.id)
  const { name, language, category, orientation } = project
  const iconProps = {
    "aria-label": _(`category-${category}`),
    className: "w-4 h-4",
  }
  return (
    <Row selected={isSelected}>
      <Row.Image className="uppercase text-xs">
        {category === "dance" && <Dance {...iconProps} />}
        {category === "theater" && <MaskHappy {...iconProps} />}
        {category === "music" && <MusicNotes {...iconProps} />}
      </Row.Image>
      <Row.Header>
        <Row.Title>{name}</Row.Title>
        <Row.Description>
          {_("created")}{" "}
          {project.createdBy && (
            <>
              {_("created-by")}{" "}
              <Link href={`/dashboard/users/${project.createdBy.id}`} className={linkCx}>
                {`${project.createdBy.profile.firstname} ${project.createdBy.profile.lastname}`}
              </Link>
            </>
          )}{" "}
          {_("created-at")} {format(project.createdAt, "PPPPp")}
        </Row.Description>
      </Row.Header>
      <Row.Content className="flex flex-wrap gap-2">
        <Badge variant="secondary">
          <Signpost aria-label={_("orientation")} />
          {_(`orientation-${orientation}`)}
        </Badge>

        <Badge variant="secondary">
          <Globe aria-label={_("language")} />
          {_(`language-${language as "en" | "fr" | "de"}`)}
        </Badge>
        <Badge
          variant={
            project.status === "accepted"
              ? "success"
              : project.status === "pending"
              ? "secondary"
              : "destructive"
          }
        >
          {project.status === "accepted" && <CircleCheck aria-label={_(`status-accepted`)} />}
          {project.status === "refused" && <CircleX aria-label={_(`status-refused`)} />}
          {project.status === "pending" && <ClockCountdown aria-label={_(`status-pending`)} />}
          {_(`status-${project.status}`)}
        </Badge>
      </Row.Content>
      <Row.Menu menu={<ContextMenu project={project} />} />
    </Row>
  )
}
