import { decorateStore } from "@/fns/decorateStore"
import { create } from "zustand"
import { Project } from "./localizers"

/**
 * types
 */
export type ProjectsStoreState = {
  projects: ById<Project>
}

/**
 * initialState
 */
const initialState: ProjectsStoreState = {
  projects: {},
}

/**
 * Store
 */
export const projectsStore = decorateStore(initialState, create, {})
export const useProjectsStore = projectsStore.use
