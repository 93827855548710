import { i18nConfig, Locale } from "@/config/i18n"
import i18next from "i18next"
import languagedetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import de from "./de.json"
import fr from "./fr.json"

/**
 * merge dictionaries
 */
const dictionaries: Record<Locale, Dictionaries> = { fr, de }

/**
 * i18next configuration
 */
i18next.use(initReactI18next).use(languagedetector)
i18next.init({
  fallbackLng: i18nConfig.fallbackLocale,
  debug: i18nConfig.debug,
  defaultNS: i18nConfig.defaultNamespace,
  supportedLngs: [...D.values(i18nConfig.localeCodes), ...i18nConfig.locales],
  resources: A.reduce([...i18nConfig.locales], {}, (resources, locale) => ({
    ...resources,
    [locale]: {
      [i18nConfig.defaultNamespace]: dictionaries[locale] ?? {},
    },
  })),
})
