import { api, AuthErrorCode, NoContent } from "@/services/api"
import { ApiArticle, ApiArticleCategory, apiArticle, apiArticleCategory, apiArticleType } from "./schemas"
import { z } from "zod"

/**
 * schemas
 */
const createPayload = z.object({
  state: z.enum(["draft", "published"]).optional(),
  type: apiArticleType.optional().default("news"),
  categoryId: z.string().nullable().optional(),
  authorId: z.string().nullable().optional(),
  publishedAt: z.string().optional(),
  publishedFrom: z.string().optional().nullable(),
  publishedTo: z.string().optional().nullable(),
})
const updatePayload = z.object({
  state: z.enum(["draft", "published"]).optional(),
  type: apiArticleType.optional(),
  categoryId: z.string().nullable().optional(),
  authorId: z.string().nullable().optional(),
  publishedAt: z.string().optional(),
  publishedFrom: z.string().optional().nullable(),
  publishedTo: z.string().optional().nullable(),
})
const createCategoryPayload = z.object({
  order: z.number().optional(),
  translations: z.array(
    z.object({
      languageId: z.string().uuid(),
      name: z.string(),
      description: z.string(),
    })
  ),
})
const updateCategoryPayload = z.object({
  translations: z.array(
    z.object({
      languageId: z.string().uuid(),
      name: z.string(),
      description: z.string(),
    })
  ),
})
const reorderCategoriesPayload = z.object({
  categories: z.array(z.string().uuid()),
})
const sortedIdSchema = z.array(z.string())

export type Payload = {
  create: z.infer<typeof createPayload>
  update: z.infer<typeof updatePayload>
  categories: {
    create: z.infer<typeof createCategoryPayload>
    update: z.infer<typeof updateCategoryPayload>
    reorder: z.infer<typeof reorderCategoriesPayload>
  }
}

/**
 * service
 */
export const service = {
  index: async () => {
    type RSuccess = { articles: ApiArticle[] }
    type RError = AuthErrorCode
    const { success, data } = await api.get<RSuccess, RError>("articles")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { articles: A.map(data.articles, apiArticle.parse) }, error: false } as const
  },
  create: async (payload: Payload["create"]) => {
    type RSuccess = { article: ApiArticle }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.post<RSuccess, RError>("articles", { data: createPayload.parse(payload) })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { article: apiArticle.parse(data.article) }, error: false } as const
  },
  read: async (id: string) => {
    type RSuccess = { article: ApiArticle }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`articles/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { article: apiArticle.parse(data.article) }, error: false } as const
  },
  update: async (id: string, payload: Payload["update"]) => {
    type RSuccess = { article: ApiArticle }
    type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
    const { success, data } = await api.put<RSuccess, RError>(`articles/${id}`, { data: updatePayload.parse(payload) })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { article: apiArticle.parse(data.article) }, error: false } as const
  },
  delete: async (id: string) => {
    type RSuccess = { success: NoContent }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.delete<RSuccess, RError>(`articles/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: null, error: false } as const
  },
  categories: {
    index: async () => {
      type RSuccess = { categories: ApiArticleCategory[] }
      type RError = AuthErrorCode
      const { success, data } = await api.get<RSuccess, RError>("article-categories")
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { categories: A.map(data.categories, apiArticleCategory.parse) }, error: false } as const
    },
    create: async (payload: Payload["categories"]["create"]) => {
      type RSuccess = { category: ApiArticleCategory; sortedIds: string[] }
      type RError = AuthErrorCode<"VALIDATION_FAILURE">
      const { success, data } = await api.post<RSuccess, RError>("article-categories", {
        data: createCategoryPayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return {
        data: { category: apiArticleCategory.parse(data.category), sortedIds: sortedIdSchema.parse(data.sortedIds) },
        error: false,
      } as const
    },
    read: async (id: string) => {
      type RSuccess = { category: ApiArticleCategory; articles: ApiArticle[] }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`article-categories/${id}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return {
        data: {
          category: apiArticleCategory.parse(data.category),
          articles: A.map(data.articles, apiArticle.parse),
        },
        error: false,
      } as const
    },
    update: async (id: string, payload: Payload["categories"]["update"]) => {
      type RSuccess = { category: ApiArticleCategory }
      type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
      const { success, data } = await api.put<RSuccess, RError>(`article-categories/${id}`, {
        data: updateCategoryPayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { category: apiArticleCategory.parse(data.category) }, error: false } as const
    },
    delete: async (id: string) => {
      type RSuccess = { sortedIds: string[] }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.delete<RSuccess, RError>(`article-categories/${id}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { sortedIds: sortedIdSchema.parse(data.sortedIds) }, error: false } as const
    },
    reorder: async (payload: Payload["categories"]["reorder"]) => {
      type RSuccess = { sortedIds: string[] }
      type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
      const { success, data } = await api.put<RSuccess, RError>(`article-categories`, {
        data: reorderCategoriesPayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { sortedIds: sortedIdSchema.parse(data.sortedIds) }, error: false } as const
    },
  },
}
