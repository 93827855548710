import { Prose } from "@/components/frontend/prose"
import { HN } from "@/components/ui/hn"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { useTranslation } from "@/store/languages/hooks"
import { container } from "../../frontend/container"
import { ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel } = item.props
  const { title, secondary, cards } = t(item).props

  return (
    <div className={cx("flex flex-col py-8 gap-8 font-plus text-gray", container({ x: "sm" }))}>
      <div className={cx("flex flex-col gap-5")}>
        <div className="flex flex-col gap-4">
          {oneIsNotEmpty(title) && (
            <HN
              level={titleLevel}
              className="text-2xl font-bold text-black dark:text-white max-w-xl"
            >
              {title}
            </HN>
          )}
          {oneIsNotEmpty(stripeHtml(secondary)) && (
            <Prose
              className="max-w-xl prose-p:text-gray prose-p:text-sm"
              dangerouslySetInnerHTML={{
                __html: secondary,
              }}
            />
          )}
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 pt-5 gap-4 text-sm">
          {A.map(item.props.cards, id => (
            <Card key={id} card={D.get(cards, id)} titleLevel={titleLevel + 1} />
          ))}
        </div>
      </div>
    </div>
  )
}

const Card: React.FC<{
  card: Option<{
    content: string
    title: string
  }>
  titleLevel: number
}> = ({ card, titleLevel }) => {
  if (G.isNullable(card)) return null
  const { title, content } = card
  return (
    <div className="p-4 border border-aquahaze flex flex-col gap-2">
      {oneIsNotEmpty(title) && (
        <HN level={titleLevel} className="text-lg font-semibold text-black dark:text-white">
          {title}
        </HN>
      )}
      {oneIsNotEmpty(stripeHtml(content)) && (
        <Prose
          className="max-w-3xl prose-p:my-0 prose-p:text-gray prose-p:text-sm"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
  )
}
