import { useForm, validator } from "@/components/form"
import { StoreProps, dictionary } from "."

/**
 * useReservationForm
 */
export const useReservationForm = ({ workshops, events, eventId }: StoreProps) => {
  const { _, language: userLanguage } = useDictionary(dictionary("reservation", "reservation"))
  const { min } = validator

  const form = useForm({
    allowSubmitAttempt: true,
    values: React.useMemo(() => {
      const event = A.find(events, event => event.id === eventId)
      const workshop = A.find(workshops, workshop => workshop.id === event?.workshopId)
      const languages = workshop?.languages ?? []
      const language =
        A.find(languages, l => S.toLowerCase(l) === userLanguage) ?? A.head(languages) ?? ""
      return {
        place: event?.placeId ?? "",
        workshop: event?.workshopId ?? "",
        eventDate: event ? T.formatISO(event.datetime, { representation: "date" }) : "",
        event: event?.id ?? "",
        students: 1,
        message: "",
        language,
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId, events, workshops]),
    validate: validator({
      place: [min(1, _("place-required"))],
      workshop: [min(1, _("workshop-required"))],
      eventDate: [min(1, _("date-required"))],
      event: [min(1, _("time-required"))],
      students: [
        min(1, _("students-required")),
        (students, { workshop }) => {
          const maxAttendees = A.find(workshops, ({ id }) => id === workshop)?.maxAttendees
          if (maxAttendees && students > maxAttendees)
            return _("students-exceeded", { max: maxAttendees })
          return null
        },
      ],
      language: [min(1, _("language-required"))],
    }),
    onSubmit: F.ignore,
  })
  return form
}
