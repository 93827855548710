import { Prose } from "@/components/frontend/prose"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { ContentItemBase } from "@/store/contents/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { container } from "../../frontend/container"
import { Header } from "../../frontend/header"
import { ContentItemMapping, ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel, template } = item.props
  const { title, secondary, content } = t(item).props

  if (!oneIsNotEmpty(title, stripeHtml(secondary), stripeHtml(content))) return null
  return (
    <div className={cx("flex flex-col py-8 gap-8 font-plus", container({ x: "sm" }))}>
      <Header {...{ title, secondary, titleLevel }} />
      {match(template)
        .with(0, () => <Template1 {...{ item }} />)
        .otherwise(() => null)}
    </div>
  )
}

/**
 * templates
 */
type TemplateProps = { item: ContentItemBase & ContentItemMapping[ItemType] }
const Template1: React.FC<TemplateProps> = ({ item }) => {
  const t = useTranslation()
  const content = t(item).props.content
  return oneIsNotEmpty(stripeHtml(content)) ? (
    <Prose
      className="max-w-xl"
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  ) : null
}
