import { RouteAdmin } from "@/components/routes/admin"
import { RouteUnauthenticated } from "@/components/routes/unauthenticated"
import SignIn from "@/pages/sign-in"
import DashboardRoutes from "@/routes/dashboard"
import { Route, Switch } from "wouter"
import FrontendRoutes from "./frontend"

/**
 * Routes
 */
const Routes: React.FC = () => (
  <Switch>
    <RouteUnauthenticated path="/sign-in" redirect="/dashboard">
      <SignIn />
    </RouteUnauthenticated>
    <RouteAdmin path="/dashboard/:rest*" redirect="/">
      <DashboardRoutes />
    </RouteAdmin>
    <Route path="/:rest*">
      <FrontendRoutes />
    </Route>
  </Switch>
)
export default Routes
