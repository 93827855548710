import { usePersistedState } from "../components/cookies/hooks/usePersistedState"

/**
 * useSortable
 */
export const useSortable = <
  T extends Record<string, unknown>,
  F = Record<string, (item: T) => unknown>
>(
  storageKey: string,
  fields: F,
  initial: keyof F,
  direction: Direction = "ASC"
) => {
  /**
   * state
   */
  const [sort, setSort] = usePersistedState<{
    field: keyof F
    direction: "ASC" | "DESC"
  }>({ direction, field: initial }, `sort-${storageKey}`, "interface", sessionStorage)

  /**
   * toggleSort
   */
  const toggle = React.useCallback(
    (field: keyof F) =>
      setSort({
        direction: sort.field === field && sort.direction === "ASC" ? "DESC" : "ASC",
        field,
      }),
    [sort, setSort]
  )

  const reset = React.useCallback(
    () => setSort({ direction: "ASC", field: initial }),
    [setSort, initial]
  )

  const isDESC = React.useCallback(
    (field: keyof F) => sort.field === field && sort.direction === "DESC",
    [sort]
  )

  const isActive = React.useCallback((field: keyof F) => sort.field === field, [sort])

  const sortBy = React.useCallback(
    (items: T[]) => {
      const getField = fields[sort.field] as (item: T) => unknown
      return A.sort(items, (a, b) => sorting(getField(a), getField(b), sort.direction))
    },
    [fields, sort.direction, sort.field]
  )
  const list = React.useMemo(() => D.keys(fields as Record<string, unknown>), [fields])

  return [{ sort, setSort, toggle, reset, isDESC, isActive, list }, sortBy] as const
}

/**
 * sorting
 */
export const sorting = (
  a: FieldValue,
  b: FieldValue,
  direction: Direction = "ASC",
  fallbackStr = "\u{10FFFF}"
) => {
  const { itemA, itemB } = direction === "DESC" ? { itemA: b, itemB: a } : { itemA: a, itemB: b }
  if (G.isString(itemA) && G.isString(itemB))
    return (itemA || fallbackStr).localeCompare(itemB || fallbackStr)
  if (G.isDate(itemA) && G.isDate(itemB)) return T.isBefore(itemA, itemB) ? -1 : 1
  if (G.isNumber(itemA) && G.isNumber(itemB)) return itemA - itemB
  return 0
}

/**
 * types
 */
export type Sortable<T extends Record<string, unknown>> = ReturnType<typeof useSortable<T>>[0]
type FieldValue = string | Date | number | unknown
type Direction = "ASC" | "DESC"
