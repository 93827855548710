/// <reference types="vite-plugin-svgr/client" />
import { LinkExternal } from "@/components/ui/link"
import { Tooltip } from "@/components/ui/tooltip"
import { Program } from "@/store/programs/localizers"
import { useClickAndTrack } from "@/store/trackings/hooks"
import { Book, FileText, TreeStructure } from "@phosphor-icons/react"
import { dictionary } from "."

/**
 * Item
 */
export const Item: React.FC<{ program: Program; className?: ClassName }> = ({
  program,
  className,
}) => {
  const { _ } = useDictionary(dictionary())
  const iconProps = { size: 26 }

  return (
    <ul key={program.id} className={className}>
      <li className="flex flex-col md:flex-row md:flex-wrap gap-1 md:gap-x-4 pb-4 md:pb-0 text-sm">
        <span className="inline md:hidden text-sm font-bold uppercase tracking-wider">
          {_(`branch`)}
        </span>
        <span className="flex md:w-16 shrink-0 text-tomato font-bold uppercase tracking-wider">
          {program.code}
        </span>
        <span>{program.name}</span>
      </li>
      <LinkButton
        trackingId={program.programsTrackingId}
        type="programs"
        url={program.programs}
        icon={<FileText {...iconProps} />}
      />
      <LinkButton
        trackingId={program.booksTrackingId}
        type="books"
        url={program.books}
        icon={<Book {...iconProps} />}
      />
      <LinkButton
        trackingId={program.pearltreesTrackingId}
        type="pearltrees"
        url={program.pearltrees}
        icon={<TreeStructure {...iconProps} />}
      />
    </ul>
  )
}

const LinkButton: React.FC<{
  type: "programs" | "books" | "pearltrees"
  trackingId: string
  url: string
  icon: React.ReactNode
}> = ({ type, trackingId, url, icon }) => {
  const { _ } = useDictionary(dictionary())
  const { onClick } = useClickAndTrack(trackingId)
  return S.isNotEmpty(url) ? (
    <li className="flex md:justify-center items-center">
      <span className="md:hidden text-sm font-bold uppercase tracking-wider">{_(type)} :</span>
      <Tooltip content={`${_("open-url")} ${url}`}>
        <LinkExternal
          href={url}
          className="flex justify-center items-center size-6 text-orient"
          onClick={onClick}
        >
          {icon}
        </LinkExternal>
      </Tooltip>
    </li>
  ) : (
    <span aria-hidden />
  )
}
