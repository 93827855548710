/**
 * Checks if an object has a truthy value for a given key
 */
export const hasTruthyKey = <T extends Record<string, unknown>>(object: T, keys: (keyof T)[]): boolean =>
  keys.some((k) => k in object && !!object[k])

/**
 * Checks if an object has a non-empty string value for a given key
 */
export const hasNonEmptyProp = (object: Record<string, string>): boolean =>
  pipe(
    object,
    D.values,
    A.some((v) => G.isString(v) && S.isNotEmpty(v))
  )
