import { Button, buttonVariants } from "@/components/frontend/button"
import { MobilePopover } from "@/components/frontend/mobile-popover"
import { useResponsive } from "@/hooks/useResponsive"
import { sorting } from "@/hooks/useSortable"
import { Article, ArticleCategory } from "@/store/frontend/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { CaretDown } from "@phosphor-icons/react"
import { dictionary } from "."

/**
 * FilterArticlesByCategory
 */
export const FilterByCategory: React.FC<{
  articles: Article[]
  selectedCategory: string | null
  setSelectedCategory: (id: string | null) => void
}> = ({ articles, selectedCategory, setSelectedCategory }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const categories = React.useMemo(() => {
    const categories = extractsCategories(articles)

    return A.sort(categories, (a, b) => sorting(t(a).name, t(b).name))
  }, [t, articles])

  const [open, setOpen] = React.useState(false)
  const media = useResponsive()
  React.useEffect(() => {
    if (media.min("lg") && open) setOpen(false)
  }, [media, open])

  const current = React.useMemo(
    () => A.find(categories, ({ id }) => id === selectedCategory),
    [categories, selectedCategory]
  )
  return media.min("lg") ? (
    <div className="flex flex-wrap items-center gap-1 [&>button]:px-4 [&>button]:h-[40px] [&>button]:text-sm">
      <Button
        variant={G.isNullable(selectedCategory) ? "outline" : "tab"}
        onClick={() => setSelectedCategory(null)}
      >
        {_("all-offers")}
      </Button>
      {A.map(categories, category => (
        <Button
          key={category.id}
          variant={selectedCategory === category.id ? "outline" : "tab"}
          onClick={() => setSelectedCategory(category.id)}
        >
          {t(category).name}
        </Button>
      ))}
    </div>
  ) : (
    <>
      <MobilePopover open={open} onOpenChange={state => setOpen(state)} modal={false}>
        <MobilePopover.Trigger
          className={cx(buttonVariants({ variant: "dark" }), "relative w-full z-50")}
        >
          {G.isNullable(current) ? _("all-offers") : t(current).name}
          <CaretDown className={cx("h-5 transition-transform", open ? "rotate-180" : "rotate-0")} />
        </MobilePopover.Trigger>
        <MobilePopover.Content className="p-0">
          <ul className="flex flex-col">
            <ListItem
              onClick={() => {
                setSelectedCategory(null)
                setOpen(false)
              }}
              active={G.isNullable(selectedCategory)}
            >
              {_("all-offers")}
            </ListItem>
            {A.mapWithIndex(categories, (index, category) => (
              <ListItem
                key={index}
                onClick={() => {
                  setSelectedCategory(category.id)
                  setOpen(false)
                }}
                active={selectedCategory === category.id}
              >
                {t(category).name}
              </ListItem>
            ))}
          </ul>
        </MobilePopover.Content>
      </MobilePopover>
    </>
  )
}

/**
 * ListItem
 */
const ListItem: React.FC<
  {
    onClick: () => void
    on?: boolean
    active: boolean
  } & React.HTMLAttributes<HTMLElement>
> = ({ active, className, onClick, on = true, children, ...props }) => {
  return on ? (
    <li className={className} {...props}>
      <Button
        onClick={onClick}
        variant="secondary"
        className={cx(
          "w-full justify-start",
          active && "bg-bluewhale hover:bg-bluewhale text-white pointer-events-none"
        )}
      >
        {children}
      </Button>
    </li>
  ) : null
}

/**
 * helpers
 */
const extractsCategories = (articles: Article[]) =>
  A.reduce(articles, [] as ArticleCategory[], (categories, article) => {
    if (G.isNullable(article.category)) return categories
    return A.includes(categories, article.category)
      ? categories
      : A.append(categories, article.category)
  })
