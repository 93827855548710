import { Card, Fields, Menu, Row } from "@/components/collection"
import { Region } from "@/components/frontend/regions"
import { useBookingValues } from "@/components/kulturrallye/hooks"
import { Badge } from "@/components/ui/badge"
import { Image } from "@/components/ui/image"
import { LinkExternal, linkCx } from "@/components/ui/link"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { stripeHtml } from "@/fns/String"
import { useSelectItem } from "@/hooks/useSelect"
import { getThumbnail } from "@/store/medias/helpers"
import { useMediasFile } from "@/store/medias/hooks"
import { Place } from "@/store/places/localizers"
import { useEventsByPlace, useWorkshopsByPlace } from "@/store/workshops/hooks"
import {
  Eye,
  EyeOff,
  ImageOff,
  LinkIcon,
  Locate,
  Presentation,
  Ticket,
  TicketPercent,
} from "lucide-react"
import { Context, ContextMenu } from "./Context"

/**
 * dictionary src/dictionaries/fr/pages/dashboard/kultureralley/places.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "places")

/**
 * Item
 */
export const Item: React.FC<{ place: Place }> = props => {
  const { place } = props
  const { view } = React.useContext(Context)
  const { selectItemProps } = useSelectItem(Context, place.id, () =>
    navigate(`/dashboard/kultureralley/${place.id}`)
  )
  return (
    <Menu type="context-menu" menu={<ContextMenu {...props} />} asChild>
      <div className="grid" {...selectItemProps}>
        {view === "grid" ? <ItemCard {...props} /> : <ItemRow {...props} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCard
 */
const ItemCard: React.FC<{ place: Place }> = ({ place }) => {
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, place.id)
  const cover = useMediasFile(place.cover)
  const image = useMediasFile(place.image)
  const workshops = useWorkshopsByPlace(place.id)
  const { totalSlots, totalBooking, bookingPercent, bookingPercentColor } = useBookingValues(
    useEventsByPlace(place.id)
  )
  return (
    <Card selected={isSelected}>
      <div className="relative">
        <Card.Image src={getThumbnail(cover)}>
          <ImageOff size={64} strokeWidth={1} />
        </Card.Image>
        <div className="absolute bottom-1 right-1 shadow-2xl grid w-1/4 p-2 aspect-video opacity-95 bg-background border border-secondary-300 rounded-lg overflow-hidden">
          <Image
            src={getThumbnail(image)}
            wrapperCx="flex justify-center items-center"
            className="max-w-full max-h-full"
          />
        </div>
        <Region
          region={place.map as "center"}
          className={
            "absolute bottom-1 left-1 w-[12%] fill-primary/5 stroke-primary/75 stroke-[0.5px]"
          }
          pathClassName={"fill-primary-foreground/90 stroke-primary stroke-2"}
          dots={[place.coordinate]}
          dotClassName="bg-primary size-1 shadow-[0px_0px_5px_0px_hsl(var(--primary)/0.75)]"
        />
      </div>
      <Card.Header>
        <Card.Title className="line-clamp-1">{place.name}</Card.Title>
        <Card.Description className="line-clamp-3">
          {stripeHtml(place.description)}
        </Card.Description>
        <Card.Menu menu={<ContextMenu {...{ place }} />} />
      </Card.Header>
      <Card.Content>
        <Fields divider>
          <Fields.Item
            name={_("state")}
            icon={<Eye aria-hidden />}
            value={_(`state-${place.published ? "published" : "draft"}`)}
            stretch
          />
          <Fields.Item
            name={_("website")}
            icon={<LinkIcon aria-hidden />}
            value={
              <LinkExternal href={place.website} className={linkCx}>
                {place.website}
              </LinkExternal>
            }
            stretch
          />
        </Fields>
        <span className="flex justify-evenly flex-wrap mt-4 gap-4">
          <Badge variant="secondary" aria-label={_("workshops")}>
            <Presentation aria-hidden />
            {workshops.length}
          </Badge>
          <Badge variant="secondary" aria-label={_("booking")}>
            <Ticket aria-hidden />
            {`${totalBooking} / ${totalSlots}`}
          </Badge>
          {totalSlots > 0 && (
            <Badge
              variant="secondary"
              style={{ backgroundColor: bookingPercentColor, color: "white" }}
              aria-label={_("booking-percent")}
            >
              <TicketPercent aria-hidden />
              {`${bookingPercent}%`}
            </Badge>
          )}
        </span>
      </Card.Content>
    </Card>
  )
}

/**
 * ItemRow
 */
const ItemRow: React.FC<{ place: Place }> = ({ place }) => {
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, place.id)
  const image = useMediasFile(place.image)
  const workshops = useWorkshopsByPlace(place.id)
  const { totalSlots, totalBooking, bookingPercent, bookingPercentColor } = useBookingValues(
    useEventsByPlace(place.id)
  )
  return (
    <Row selected={isSelected}>
      <Row.Image
        src={getThumbnail(image)}
        className="bg-muted object-contain max-w-full max-h-full"
      >
        <ImageOff size={16} />
      </Row.Image>
      <Row.Header>
        <Row.Title>{place.name}</Row.Title>
        <Row.Description>
          {_("website")} :{" "}
          <LinkExternal href={place.website} className={linkCx}>
            {place.website}
          </LinkExternal>
        </Row.Description>
      </Row.Header>
      <Row.Content className="flex flex-wrap gap-2">
        <Badge variant="secondary">
          <Locate aria-label={_("form.map-label")} />
          {_(`form.map-${place.map}`)}
        </Badge>
        <Badge variant="secondary">
          <Presentation aria-label={_("workshops")} />
          {workshops.length}
        </Badge>
        <Badge variant="secondary">
          <Ticket aria-label={_("booking")} />
          {`${totalBooking} / ${totalSlots}`}
        </Badge>
        <Badge variant="secondary" style={{ backgroundColor: bookingPercentColor, color: "white" }}>
          <TicketPercent aria-label={_("booking-percent")} />
          {`${bookingPercent}%`}
        </Badge>
        <Row.Icon tooltip={_(`state-${place.published ? "published" : "draft"}`)}>
          {place.published ? <Eye aria-hidden /> : <EyeOff aria-hidden />}
        </Row.Icon>
      </Row.Content>
      <Row.Menu menu={<ContextMenu {...{ place }} />} />
    </Row>
  )
}
