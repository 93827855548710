import { storage } from "../fns/storage"

/**
 * useCookieState
 */
export function useCookieState<T>(
  defaultValue: T,
  key: string
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = React.useState(() => {
    const storeValue = storage.cookie.get(key)
    if (storeValue) {
      try {
        const storeState = JSON.parse(storeValue) as T
        return storeState
      } catch (e) {}
    }
    return defaultValue
  })
  React.useEffect(() => {
    storage.cookie.set(key, JSON.stringify(state))
  }, [key, state])
  return [state, setState]
}
