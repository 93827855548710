import { initialFiles, useForm } from "@/components/form"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { Project } from "@/store/frontend/localizers"

/**
 * useAnnexeForm
 */
export const useAnnexeForm = (project: Option<Project> = null) => {
  return useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() =>
      project
        ? {
            documents: initialFiles(D.values(project.documents)),
            images: initialFiles(D.values(project.images)),
          }
        : {
            documents: initialFiles([]),
            images: initialFiles([]),
          }
    ),
    onSubmit: F.ignore,
  })
}
