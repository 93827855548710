import { Fields, Loader, Menu } from "@/components/collection"
import { Prose } from "@/components/frontend/prose"
import { Region } from "@/components/frontend/regions"
import { PlaceHeader } from "@/components/kulturrallye/PlaceHeader"
import { Stats } from "@/components/kulturrallye/Stats"
import { generateGoogleMapsLink } from "@/components/kulturrallye/helpers"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Button } from "@/components/ui/button"
import { CardAccordion } from "@/components/ui/card-accordion"
import { LinkExternal, linkCx } from "@/components/ui/link"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { usePromise } from "@/hooks/usePromise"
import { SelectProvider, useSelect } from "@/hooks/useSelect"
import { getPlace } from "@/store/places/actions"
import { usePlace } from "@/store/places/hooks"
import { Place } from "@/store/places/localizers"
import { getWorkshops } from "@/store/workshops/actions"
import { useReservationsByPlace } from "@/store/workshops/hooks"
import {
  Eye,
  EyeOff,
  LineChart,
  LinkIcon,
  Mail,
  MapPinned,
  MoreVertical,
  Pen,
  Phone,
  PinIcon,
  Presentation,
  Trash,
} from "lucide-react"
import { Redirect } from "wouter"
import { ContextProvider, usePageContext } from "../Context"
import { Collection } from "./Collection"
import { Context as WorkshopsContext, ContextProvider as WorshopsContextProvider } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/index.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley")

/**
 * DashboardKultureralleyPlace
 */
type Props = { place: Place }
const DashboardKultureralleyPlace: React.FC<Props> = props => {
  const { place } = props
  const { _ } = useDictionary(dictionary())
  const select = useSelect(WorkshopsContext)

  return (
    <PageWrapper ref={select.ref}>
      <PageHeader
        breadcrumbs={[
          [_("index.breadcrumbs"), "/dashboard/kultureralley"],
          [place.name, `/dashboard/kultureralley/${place.id}`],
        ]}
      />
      <SelectProvider {...select.props} />
      <PageContent>
        <CardAccordion defaultValue={"meta"} group={`place-${place.id}`}>
          <SectionMeta {...props} />
          <SectionWorkshops {...props} />
          <SectionReservations {...props} />
        </CardAccordion>
      </PageContent>
    </PageWrapper>
  )
}

type PageProps = { id: string }
export default ({ id }: PageProps) => {
  const { _ } = useDictionary(dictionary())
  const place = usePlace(id)
  usePromise(getWorkshops)
  const [result, inProgress] = usePromise(() => getPlace(id))
  if (inProgress)
    return <Loader breadcrumbs={[[_("index.breadcrumbs"), "/dashboard/kultureralley"]]} />
  if (!result || result.error || G.isNullable(place))
    return <Redirect to="/dashboard/kultureralley" />
  return (
    <ContextProvider>
      <WorshopsContextProvider place={place}>
        <DashboardKultureralleyPlace place={place} />
      </WorshopsContextProvider>
    </ContextProvider>
  )
}

/**
 * SectionMeta
 */
const SectionMeta: React.FC<Props> = ({ place }) => {
  const { _ } = useDictionary(dictionary("places"))
  const ctx = usePageContext()
  const toggleState = () => (place.published ? ctx.unpublish(place.id) : ctx.publish(place.id))
  return (
    <Menu type="context-menu" menu={<ContextMenu place={place} />}>
      <CardAccordion.Item value="meta">
        <PlaceHeader place={place} />
        <CardAccordion.Header>
          <CardAccordion.Header.Title>
            <MapPinned size={16} aria-hidden />
            {_("section-meta-title")}
          </CardAccordion.Header.Title>
          <CardAccordion.Header.Aside className="relative">
            <Button
              variant={place.published ? "default" : "secondary"}
              icon
              size="xxs"
              aria-label={_("menu.state-toggle", {
                state: place.published ? "draft" : "published",
              })}
              onClick={toggleState}
            >
              {place.published ? (
                <Eye aria-label={_(`state-published`)} />
              ) : (
                <EyeOff aria-label={_(`state-draft`)} />
              )}
            </Button>
            <Menu
              menu={<ContextMenu place={place} />}
              type="dropdown-menu"
              align="start"
              side="left"
            >
              <Button variant="ghost" size="xs" icon>
                <MoreVertical aria-hidden />
              </Button>
            </Menu>
          </CardAccordion.Header.Aside>
        </CardAccordion.Header>
        <CardAccordion.Content className="flex flex-col pt-4 px-8 pb-8">
          <div className="grid grid-cols-4 gap-16">
            <div className="col-span-3">
              <h1 className="text-lg font-medium leading-none tracking-tight">{place.name}</h1>
              <Prose
                className="max-w-xl"
                dangerouslySetInnerHTML={{
                  __html: place.description,
                }}
              />
            </div>
            <div>
              <Region region={place.map as "center"} className={""} dots={[place.coordinate]} />
            </div>
          </div>

          <Fields divider>
            {S.isNotEmpty(S.trim(place.website)) && (
              <Fields.Item
                name={_("website")}
                icon={<LinkIcon aria-hidden />}
                value={
                  <LinkExternal href={place.website} className={linkCx}>
                    {place.website}
                  </LinkExternal>
                }
              />
            )}
            {S.isNotEmpty(S.trim(place.address)) && (
              <Fields.Item
                name={_("address")}
                icon={<PinIcon aria-hidden />}
                value={
                  <LinkExternal
                    href={generateGoogleMapsLink(place.address)}
                    className={cx(linkCx, "whitespace-pre")}
                  >
                    {place.address}
                  </LinkExternal>
                }
              />
            )}
            <Fields.ItemExtra
              icon={<Phone aria-hidden />}
              fields={place.phones}
              wrapper={value => (
                <a href={`tel:${value}`} className={linkCx}>
                  {value}
                </a>
              )}
            />
            <Fields.ItemExtra
              icon={<Mail aria-hidden />}
              fields={place.emails}
              wrapper={value => (
                <a href={`mailto:${value}`} className={linkCx}>
                  {value}
                </a>
              )}
            />
          </Fields>
        </CardAccordion.Content>
      </CardAccordion.Item>
    </Menu>
  )
}

/**
 * SectionWorkspaces
 */
const SectionWorkshops: React.FC<Props> = () => {
  const { _ } = useDictionary(dictionary("places"))
  return (
    <CardAccordion.Item value="workshops">
      <CardAccordion.Header>
        <CardAccordion.Header.Title>
          <Presentation size={16} aria-hidden />
          {_("section-workshops-title")}
        </CardAccordion.Header.Title>
        <CardAccordion.Header.Aside />
      </CardAccordion.Header>
      <CardAccordion.Content className="flex flex-col gap-4 pt-4 px-8 pb-8">
        <Collection />
      </CardAccordion.Content>
    </CardAccordion.Item>
  )
}

/**
 * SectionReservations
 */
const SectionReservations: React.FC<Props> = props => {
  const { _ } = useDictionary(dictionary("places"))
  const reservations = useReservationsByPlace(props.place.id)
  return (
    <CardAccordion.Item value="reservation">
      <CardAccordion.Header>
        <CardAccordion.Header.Title>
          <LineChart size={16} aria-hidden />
          {_("section-stats-title")}
        </CardAccordion.Header.Title>
        <CardAccordion.Header.Aside />
      </CardAccordion.Header>
      <CardAccordion.Content className="flex flex-col gap-4 pt-4 px-8 pb-8">
        <Stats reservations={reservations} />
      </CardAccordion.Content>
    </CardAccordion.Item>
  )
}

/**
 * ContextMenu
 */
const ContextMenu: React.FC<{
  place: Place
}> = ({ place }) => {
  const { _ } = useDictionary(dictionary("places", "menu"))
  const { confirmDelete, edit, publish, unpublish } = usePageContext()
  const { id } = place
  return (
    <>
      <Menu.Item onClick={() => edit(place)}>
        <Pen aria-hidden />
        {_("edit")}
      </Menu.Item>
      {place.published ? (
        <Menu.Item onClick={() => unpublish(id)}>
          <EyeOff aria-hidden />
          {_("unpublish")}
        </Menu.Item>
      ) : (
        <Menu.Item onClick={() => publish(id)}>
          <Eye aria-hidden />
          {_("publish")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => confirmDelete(id)}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
    </>
  )
}
