import newsletterBG from "@/assets/images/newsletter.png"
import { Button } from "@/components/frontend/button"
import { Checkbox } from "@/components/frontend/checkbox"
import { HN } from "@/components/ui/hn"
import { textPlaceholder } from "@/fns/String"
import { validator } from "@/fns/validator"
import { service } from "@/services/frontend/service"
import { useTranslation } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { ItemMappingExport } from "."
import { Container } from "../container"
import { Wrapper } from "../wrapper"

/**
 * dictionary src/dictionaries/en/components/cms.json
 * dictionary src/dictionaries/de/components/cms.json
 * dictionary src/dictionaries/fr/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")

type RenderFC = ItemMappingExport<"newsletter">["Render"]

export const Render: RenderFC = props => {
  const { item } = props
  const { _ } = useDictionary(dictionary("content", "items", "newsletter"))
  const t = useTranslation()
  const { titleLevel } = item.props
  const { title, secondary, label, button } = t(item).props

  // register the new email to the newsletter
  const [email, setEmail] = React.useState("")
  const [consent, setConsent] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<"EMAIL_ALREADY_EXISTS" | "VALIDATION_FAILURE" | null>(
    null
  )
  const [consentError, setConsentError] = React.useState(false)
  const emailIsValid = React.useMemo(
    () => G.isString(email) && validator.regex.mail.test(email),
    [email]
  )
  const [registrationDone, setRegistrationDone] = React.useState(false)

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!consent) return setConsentError(true)
    if (!G.isString(email) || !validator.regex.mail.test(email))
      return setError("VALIDATION_FAILURE")
    setLoading(true)
    const { error, code } = await service.newsletters.register({ email })
    setLoading(false)
    if (error) {
      match(code)
        .with("EMAIL_ALREADY_EXISTS", () => setError("EMAIL_ALREADY_EXISTS"))
        .with("VALIDATION_FAILURE", () => setError("VALIDATION_FAILURE"))
        .otherwise(() => toast.error(_("error")))
    }
    setRegistrationDone(true)
  }
  validator.regex
  return (
    <Wrapper margin="normal" noMarginBottom>
      <div
        style={{ backgroundImage: `url('${newsletterBG}')` }}
        className="bg-cover bg-center bg-no-repeat w-full max-w-screen-2xl mx-auto"
      >
        <Container x="sm" className={cx("flex flex-col gap-8 py-7 xl:py-24")}>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
            <div className="flex flex-col gap-5">
              <HN
                level={titleLevel}
                className="text-4xl font-extrabold text-frontend-tomato font-plus uppercase tracking-wide"
              >
                {textPlaceholder(title, _("title-placeholder"))}
              </HN>
              <p className="text-frontend-bluewhale text-xl font-bold font-plus">
                {textPlaceholder(secondary, _("secondary-placeholder"))}
              </p>
            </div>
            {registrationDone ? (
              <div className="h-[138px] text-frontend-bluewhale py-2 font-plus max-w-xl flex flex-col gap-2">
                <p className="font-bold">{_("registration-done-1")}</p>
                <p>{_("registration-done-2")}</p>
              </div>
            ) : (
              <form className="flex flex-col items-start xl:flex-row gap-5" onSubmit={onSubmit}>
                <div className="relative flex flex-col gap-5">
                  {G.isNotNullable(error) && (
                    <p
                      className={cx(
                        "absolute -top-6 left-0 text-red-500 text-sm",
                        error === "VALIDATION_FAILURE" && emailIsValid && "hidden"
                      )}
                    >
                      {_(error)}
                    </p>
                  )}
                  <input
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                    placeholder={textPlaceholder(label, _("label-placeholder"))}
                    className={cx(
                      "w-full block px-5 py-4 h-[50px] border-[1px] focus:outline-0 font-plus text-frontend-emperor disabled:opacity-50",
                      error && !emailIsValid
                        ? "border-red-500 bg-red-50"
                        : "border-frontend-cloud bg-white"
                    )}
                    disabled={loading}
                  />
                  <Checkbox id="newsletter-consent">
                    <div className="flex items-start gap-3">
                      <Checkbox.Trigger
                        onCheckedChange={(value: boolean) => setConsent(value)}
                        value={consent}
                        className="bg-white mt-[5px] sm:mt-[3px]"
                        disabled={loading}
                      />
                      <Checkbox.Label
                        className={cx(
                          "text-bluewhale/80",
                          !consent && consentError && "text-red-500"
                        )}
                      >
                        {_("consent-label")}
                      </Checkbox.Label>
                    </div>
                  </Checkbox>
                </div>
                <Button variant="dark" type="submit" className="w-auto" disabled={loading}>
                  {textPlaceholder(button, _("button-placeholder"))}
                </Button>
              </form>
            )}
          </div>
        </Container>
      </div>
    </Wrapper>
  )
}
