import kulturrallyeRegionBg from "@/assets/images/kulturrallye-region.jpeg"
import { Button, focusOutlineClasses } from "@/components/frontend/button"
import { Checkbox } from "@/components/frontend/checkbox"
import { isValidDateInterval } from "@/components/frontend/datepicker"
import { DateIntervalSelect } from "@/components/frontend/dateselect"
import { ImageHeader } from "@/components/frontend/image-header"
import { MobilePopover } from "@/components/frontend/mobile-popover"
import { Region } from "@/components/frontend/regions"
import { skeleton } from "@/components/frontend/skeleton"
import { containerVariants } from "@/components/layout/frontend/container"
import { Tooltip } from "@/components/ui/tooltip"
import globalConfig from "@/config/global"
import { byId } from "@/fns/byId"
import { RegionName } from "@/fns/maps"
import { Evolver, useEvolveState } from "@/hooks/useEvolveState"
import { useList } from "@/hooks/useList"
import { useData } from "@/hooks/usePromise"
import { useResponsive } from "@/hooks/useResponsive"
import { Note } from "@/pages/kulturrallye/Note"
import { PlacePreview } from "@/pages/kulturrallye/PlacePreview"
import { service } from "@/services/frontend/service"
import { Workshop, localizeWorkshop } from "@/store/frontend/localizers"
import { Place, localizePlace } from "@/store/places/localizers"
import { CaretDown, MaskSad } from "@phosphor-icons/react"
import * as Tabs from "@radix-ui/react-tabs"
import { add, endOfYear, startOfDay } from "date-fns"
import { useTitle } from "hoofd"
import { ExternalLink } from "lucide-react"
import { match } from "ts-pattern"
import { Link } from "wouter"
import { useKulturrallyeContext } from "../Context"
import { placeLink, regionLink } from "../Routes"
import { RegionBacklink } from "../places"

/**
 * dictionary src/dictionaries/en/pages/kulturrallye.json
 */
const dictionary = createContextMapper("pages", "kulturrallye")

/**
 * KulturrallyeRegion
 */

const KulturrallyeRegions: React.FC<{
  region: RegionName
  initialView: Option<string>
}> = props => {
  const { _ } = useDictionary(dictionary())
  const { _: _map } = useDictionary(contextMapper("components", "map"))

  useTitle(`${globalConfig.siteName} - ${_("name")}: ${_("region")} ${_map(props.region)}`)

  return (
    <div className="flex flex-col mb-44 gap-16 animate-appear-opacity">
      <div className={containerVariants({ x: "head" })}>
        <ImageHeader background={kulturrallyeRegionBg} backTo="/kulturrallye">
          <ImageHeader.Main>
            <ImageHeader.Content>
              <ImageHeader.Title>{_("name")}</ImageHeader.Title>
              <p>
                <RegionBacklink region={props.region} />
              </p>
            </ImageHeader.Content>
          </ImageHeader.Main>
        </ImageHeader>
      </div>

      <div className={containerVariants({ x: "sm" })}>
        <RegionWorkshops region={props.region} initialView={props.initialView} />
      </div>
    </div>
  )
}
export default KulturrallyeRegions

/**
 * RegionWorkshops
 */

const RegionWorkshops: React.FC<{ region: RegionName; initialView: Option<string> }> = props => {
  const { _ } = useDictionary(dictionary())

  const media = useResponsive()

  // filters context
  const { filters } = useKulturrallyeContext()

  const nowDate = React.useMemo(() => startOfDay(new Date()), [])
  const filtersInterval = React.useMemo(() => {
    // fallback to dates filtering all upcomming events if no user interval selected
    return isValidDateInterval(filters.interval)
      ? filters.interval
      : { start: nowDate, end: endOfYear(add(nowDate, { years: 1 })) }
  }, [filters.interval, nowDate])

  /**
   * Filters
   */

  // selected, persisted places
  const [{ selected }, setSelectedPlaces] = useEvolveState<{ selected: string[] }>(
    { selected: [] },
    `required-kulturrallye-${props.region}-places`,
    sessionStorage
  )

  /**
   * Loaders
   */

  const [places, loadingPlaces] = useData({}, async () =>
    match(await service.places.index())
      .with({ error: false }, ({ data }) => byId(data.places, localizePlace))
      .otherwise(() => ({}))
  )
  const [intervalWorkshops, loadingWorkshops] = useData(
    {},
    async () =>
      match(
        await service.workshops.index({
          from: T.formatISO(filtersInterval.start),
          to: T.formatISO(filtersInterval.end),
        })
      )
        .with({ error: false }, ({ data }) => byId(data.workshops, localizeWorkshop))
        .otherwise(() => ({})),
    [filtersInterval]
  )

  /**
   * Workshops
   */

  const intervalWorkshopsList = useList(intervalWorkshops)

  /**
   * Places
   */

  const placesList = useList(places)

  const regionPlaces = React.useMemo(
    () => placesList.filter(place => place.map === props.region),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [placesList]
  )

  // filter places by selection
  const filteredPlaces = React.useMemo(() => {
    if (!selected.length) return regionPlaces
    return A.filter(regionPlaces, place => selected.includes(place.id))
  }, [selected, regionPlaces])

  // filter places with active workshops
  const filteredPlacesWithWorkshops = React.useMemo(() => {
    return A.filterMap<Place, Place & { workshops: Workshop[] }>(filteredPlaces, place => {
      const activePlaceWorkshops = intervalWorkshopsList.filter(workshop => {
        return workshop.placeId === place.id && D.isNotEmpty(workshop.events)
      })
      if (activePlaceWorkshops.length) return O.Some({ ...place, workshops: activePlaceWorkshops })
      return O.None
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPlaces, intervalWorkshops])

  /**
   * Active states
   */
  const [popoverPlace, setPopoverPlace] = React.useState<Option<string>>(null)
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)

  const loading = loadingWorkshops

  return (
    <Tabs.Root
      defaultValue={props.initialView ?? "list"}
      onValueChange={view => navigate(`${regionLink(props.region)}/${view}`, { replace: true })}
      className="w-full flex flex-col lg:grid lg:grid-cols-[370px_1fr] gap-12"
    >
      <div className="flex flex-col gap-7">
        <Tabs.List className="flex gap-1" aria-label="Manage your account">
          <Tabs.Trigger asChild value="list">
            <Button variant="tab" case="uppercase" className="flex-1">
              {_("regionPage.filters.listView")}
            </Button>
          </Tabs.Trigger>
          <Tabs.Trigger asChild value="map">
            <Button variant="tab" case="uppercase" className="flex-1">
              {_("regionPage.filters.mapView")}
            </Button>
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="list" className="lg:hidden w-full">
          <MobilePopover open={mobileMenuOpen} onOpenChange={setMobileMenuOpen} modal={false}>
            <MobilePopover.Trigger asChild>
              <Button className="w-full" variant="dark">
                {_("regionPage.filters.filters")}
                <CaretDown size={20} className={cx(mobileMenuOpen ? "rotate-180" : "rotate-0")} />
              </Button>
            </MobilePopover.Trigger>
            <MobilePopover.Content>
              <RegionFilters
                loading={loadingPlaces}
                places={regionPlaces}
                selected={selected}
                setSelectedPlaces={setSelectedPlaces}
              />
            </MobilePopover.Content>
          </MobilePopover>
        </Tabs.Content>

        <hr className="border-mercury/80" />

        {media.min("lg") && (
          <Tabs.Content value="list">
            <RegionFilters
              loading={loadingPlaces}
              places={regionPlaces}
              selected={selected}
              setSelectedPlaces={setSelectedPlaces}
            />
          </Tabs.Content>
        )}

        <Tabs.Content value="map">
          <ul className="flex flex-col gap-3 text-sm animate-appear-opacity text-gray">
            {regionPlaces.map(place => (
              <li
                key={place.id}
                className="flex items-center underline gap-3"
                onMouseEnter={() => setPopoverPlace(place.id)}
                onMouseLeave={() => popoverPlace === place.id && setPopoverPlace(null)}
              >
                <ExternalLink className="w-5 text-orient" aria-hidden />
                <Link to={placeLink(place)}>{place.name}</Link>
              </li>
            ))}
          </ul>
        </Tabs.Content>
      </div>

      <div>
        <Tabs.Content value="list">
          {loading ? (
            <div className={cx(gridClasses)}>
              <PlacesPreviewListSkeleton />
            </div>
          ) : filteredPlacesWithWorkshops.length > 0 ? (
            <div className={cx(gridClasses)}>
              {filteredPlacesWithWorkshops.map(place => (
                <PlacePreview key={place.id} place={place} workshops={place.workshops} />
              ))}
            </div>
          ) : (
            <Note>
              <MaskSad />
              {_("empty-result")}
            </Note>
          )}
        </Tabs.Content>

        <Tabs.Content value="map">
          <RegionMap
            places={regionPlaces}
            region={props.region}
            loading={loadingPlaces}
            {...{ popoverPlace, setPopoverPlace }}
          />
        </Tabs.Content>
      </div>
    </Tabs.Root>
  )
}

const gridClasses = cx("grid grid-cols-1 xl:grid-cols-2 gap-5 items-start")

/**
 * RegionFilters
 */

const RegionFilters: React.FC<{
  loading: boolean
  places: Place[]
  selected: string[]
  setSelectedPlaces: Evolver<{
    selected: string[]
  }>
}> = props => {
  const { _ } = useDictionary(dictionary())

  const makeTogglePlace = (id: string) => (checked: boolean) =>
    props.setSelectedPlaces({ selected: checked ? A.append(id) : A.reject(F.equals(id)) })
  const { filters } = useKulturrallyeContext()
  return (
    <div className="flex flex-col gap-7">
      <div className="flex flex-col items-start gap-5">
        <h2 className="text-lg font-semibold">{_("date.period")}</h2>

        <DateIntervalSelect interval={filters.interval} onChange={filters.setInterval} />
      </div>

      <hr className="border-mercury/80" />

      <div className="flex flex-col items-start gap-5 w-full">
        <div className="flex items-center justify-between w-full">
          <h2 className="text-lg font-semibold">{_("name")}</h2>

          {props.selected.length > 0 && (
            <button
              className={cx(
                focusOutlineClasses,
                "font-medium text-xs text-gray/80 underline underline-offset-2"
              )}
              onClick={() => props.setSelectedPlaces({ selected: [] })}
            >
              {_("regionPage.filters.clearFilters")}
            </button>
          )}
        </div>

        {props.loading ? (
          <PlacesListSkeleton />
        ) : (
          <>
            {!props.places.length && (
              <span className="w-full text-xs text-center py-3 text-gray">{_("no-place")}</span>
            )}

            <ul className="flex flex-col gap-3 text-sm animate-appear-opacity text-gray">
              {props.places.map(place => {
                const checked = props.selected.includes(place.id)
                const togglePlace = makeTogglePlace(place.id)

                return (
                  <Checkbox key={place.id} id={place.id}>
                    <li className="flex items-center gap-3">
                      <Checkbox.Trigger onCheckedChange={togglePlace} value={checked} />
                      <Checkbox.Label className={cx(checked ? "text-bluewhale" : "")}>
                        {place.name}
                      </Checkbox.Label>
                    </li>
                  </Checkbox>
                )
              })}
            </ul>
          </>
        )}
      </div>
    </div>
  )
}

/**
 * RegionMap
 */

const RegionMap: React.FC<{
  popoverPlace: Option<string>
  setPopoverPlace: React.Dispatch<React.SetStateAction<Option<string>>>
  places: Place[]
  region: RegionName
  loading: boolean
}> = props => {
  const { popoverPlace, setPopoverPlace, places, region, loading } = props
  const currentPlace = React.useMemo(
    () => A.find(places, place => place.id === popoverPlace),
    [popoverPlace, places]
  )

  return (
    <div className="flex flex-col w-full">
      <div className="relative border-[1px] border-mercury flex items-center justify-center p-10 w-full pt-36">
        <Region
          region={region}
          pathClassName="transition-all fill-neutral-50/80 group-hover/region:fill-neutral-50 stroke-neutral-300 group-hover/region:stroke-frontend-primary"
          className={cx(
            "group/region w-full max-w-[70%] animate-appear-opacity",
            loading && "animate-throb"
          )}
        >
          {places.map(place => (
            <span
              key={place.id}
              className="absolute w-3.5"
              style={coordinateToStyle(place.coordinate)}
            >
              <Tooltip content={place.name}>
                <button
                  onClick={() => setPopoverPlace(place.id)}
                  className={cx(
                    "absolute w-full aspect-square rounded-full translate-x-[-50%] translate-y-[-50%] transition-all border-[0.5px] border-white",
                    place.id === popoverPlace
                      ? "bg-tomato shadow-[0px_0px_5px_0px_#FF633766]"
                      : "bg-bluewhale shadow-[0px_0px_5px_0px_#0070A566]"
                  )}
                />
              </Tooltip>
            </span>
          ))}
        </Region>

        {currentPlace && (
          <div className="absolute top-6 left-6 right-6 flex justify-start">
            <PlacePreview place={currentPlace} workshops={[]} />
          </div>
        )}
      </div>
    </div>
  )
}

const coordinateToStyle = (coordinate: [number, number]) => ({
  top: `${coordinate[1]}%`,
  left: `${coordinate[0]}%`,
})

/**
 * Skeletons
 */

const PlacesListSkeleton: React.FC = () => {
  return (
    <div className="w-full flex flex-col gap-3" aria-hidden>
      <span className={cx(skeleton({ h: "sm", widths: "uneven" }))} />
      <span className={cx(skeleton({ h: "sm", widths: "uneven" }))} />
      <span className={cx(skeleton({ h: "sm", widths: "uneven" }))} />
    </div>
  )
}

const PlacesPreviewListSkeleton: React.FC = () => {
  return (
    <>
      <span className={cx(skeleton({ h: "xl" }))} />
      <span className={cx(skeleton({ h: "xl" }))} />
      <span className={cx(skeleton({ h: "xl" }))} />
      <span className={cx(skeleton({ h: "xl" }))} />
      <span className={cx(skeleton({ h: "xl" }))} />
    </>
  )
}
