import { Fields, Section } from "@/components/collection"
import { generateGoogleMapsLink } from "@/components/kulturrallye/helpers"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import type { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { LinkExternal, linkCx } from "@/components/ui/link"
import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { usePlace } from "@/store/places/hooks"
import { useUser } from "@/store/users/hooks"
import { useEvent, useWorkshop } from "@/store/workshops/hooks"
import { WorkshopReservation } from "@/store/workshops/localizers"
import { Link } from "wouter"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/events.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "reservations")

/**
 * DetailsDialog
 */
export const DetailsDialog: React.FC<UseDialogProps<WorkshopReservation>> = ({
  item,
  ...props
}) => {
  const { _ } = useDictionary(dictionary("details-dialog"))

  return (
    <Dialog {...props} title={_("title")} description={_("description")} className="max-w-2xl">
      {item && <Details item={item} {...props} />}
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_("close")}</Button>
        </Dialog.Close>
      </Dialog.Footer>
    </Dialog>
  )
}

const Details: React.FC<UseDialogFormProps<WorkshopReservation>> = ({ item: reservation }) => {
  const { _ } = useDictionary(dictionary("details-dialog"))
  const format = useDateFnsLocaleFormat()

  const { contact } = reservation
  const workshop = useWorkshop(reservation.workshopId)
  const place = usePlace(workshop?.placeId)
  const event = useEvent(reservation.eventId)
  const user = useUser(reservation.userId)
  return (
    <div className="flex flex-col pb-4 gap-6">
      {user && (
        <div className="flex flex-col w-full gap-4">
          <Section.Header>
            <Section.HeaderTitle>{_("user-title")}</Section.HeaderTitle>
            <Section.HeaderDescription>{_("user-description")}</Section.HeaderDescription>
          </Section.Header>
          <Fields divider>
            <Fields.Item
              name={_("user-full-name")}
              value={
                <Link href={`/dashboard/users/${user.id}`} className={linkCx}>
                  {`${user.profile.firstname} ${user.profile.lastname}`}
                </Link>
              }
              stretch
            />
            <Fields.Item
              name={_("user-email")}
              value={
                <LinkExternal href={`mailto:${user.email}`} className={linkCx}>
                  {user.email}
                </LinkExternal>
              }
              stretch
            />
          </Fields>
        </div>
      )}

      <div className="flex flex-col w-full gap-4">
        <Section.Header>
          <Section.HeaderTitle>{_("reservation-title")}</Section.HeaderTitle>
          <Section.HeaderDescription>{_("reservation-description")}</Section.HeaderDescription>
        </Section.Header>
        <Fields divider>
          <Fields.Item
            name={_("reservation-state")}
            value={
              reservation.canceled
                ? _("reservation-state-canceled")
                : _("reservation-state-confirmed")
            }
            stretch
          />
          <Fields.Item
            name={_("reservation-created-at")}
            value={format(reservation.createdAt, "PPPPpp")}
            stretch
          />
          {S.isNotEmpty(S.trim(reservation.message)) && (
            <Fields.Item name={_("reservation-message")} value={reservation.message} stretch />
          )}
        </Fields>
      </div>

      <div className="flex flex-col w-full gap-4">
        <Section.Header>
          <Section.HeaderTitle>{_("workshop-title")}</Section.HeaderTitle>
          <Section.HeaderDescription>{_("workshop-description")}</Section.HeaderDescription>
        </Section.Header>
        <Fields divider>
          {G.isNotNullable(workshop) && (
            <Fields.Item
              name={_("workshop-name")}
              value={
                <Link
                  href={`/dashboard/kultureralley/${workshop.placeId}/${workshop.id}`}
                  className={linkCx}
                >
                  {workshop.name}
                </Link>
              }
              stretch
            />
          )}
          {G.isNotNullable(place) && (
            <Fields.Item
              name={_("workshop-place")}
              value={
                <Link href={`/dashboard/kultureralley/${place.id}`} className={linkCx}>
                  {place.name}
                </Link>
              }
              stretch
            />
          )}
          {G.isNotNullable(event) && (
            <Fields.Item
              name={_("workshop-date")}
              value={format(event.datetime, "PPPPpp")}
              stretch
            />
          )}
          <Fields.Item name={_("workshop-language")} value={reservation.language} stretch />
          <Fields.Item name={_("workshop-students")} value={reservation.students} stretch />
        </Fields>
      </div>

      {G.isNotNullable(contact) && (
        <div className="flex flex-col w-full gap-4">
          <Section.Header>
            <Section.HeaderTitle>{_("class-title")}</Section.HeaderTitle>
            <Section.HeaderDescription>{_("class-description")}</Section.HeaderDescription>
          </Section.Header>
          <Fields divider>
            <Fields.Item name={_("class-name")} value={contact.schoolClass} stretch />
            <Fields.Item name={_("class-school")} value={contact.schoolName} stretch />
            <Fields.Item
              name={_("class-address")}
              value={
                <LinkExternal
                  href={generateGoogleMapsLink(
                    `${contact.schoolStreet} ${contact.schoolZip} ${contact.schoolCity}`
                  )}
                  className={linkCx}
                >
                  {contact.schoolStreet} {contact.schoolZip} {contact.schoolCity}
                </LinkExternal>
              }
              stretch
            />
            <Fields.Item name={_("class-teacher")} value={contact.teacherName} stretch />
            <Fields.Item
              name={_("class-email")}
              value={
                <LinkExternal href={`mailto:${contact.teacherEmail}`} className={linkCx}>
                  {contact.teacherEmail}
                </LinkExternal>
              }
              stretch
            />
            <Fields.Item
              name={_("class-phone")}
              value={
                <LinkExternal href={`tel:${contact.teacherPhone}`} className={linkCx}>
                  {contact.teacherPhone}
                </LinkExternal>
              }
              stretch
            />
          </Fields>
        </div>
      )}
    </div>
  )
}
