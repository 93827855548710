import { cva } from "class-variance-authority"

export const skeleton = cva("w-full bg-primary-800/10 rounded-[1px] animate-throb", {
  variants: {
    h: {
      sm: "h-5",
      md: "h-10",
      lg: "h-20",
      xl: "h-44",
    },
    widths: {
      uneven: "even:max-w-[90%] last:max-w-[40%]",
      even: "w-full",
    },
  },
  defaultVariants: {
    widths: "even",
  },
})
