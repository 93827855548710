import { ApiArticle } from "@/services/articles/schemas"
import { ApiMediasFile } from "@/services/medias/schemas"
import { extractFilesFromContent } from "../contents/helpers"
import { extractFilesFromSeo } from "../seos/helpers"

export const extractFilesFromArticle = (article: ApiArticle): ApiMediasFile[] => [
  ...extractFilesFromSeo(article.seo),
  ...extractFilesFromContent(article.content),
]
export const extractFilesFromArticles = (articles: ApiArticle[]): ApiMediasFile[] =>
  A.reduce(articles, [] as ApiMediasFile[], (list, article) => [
    ...list,
    ...extractFilesFromArticle(article),
  ])
export const extractCategoriesFromArticles = (articles: ApiArticle[]) =>
  D.values(
    A.reduce(articles, {}, (categories, article) =>
      G.isNotNullable(article.category)
        ? D.set(categories, article.category.id, article.category)
        : categories
    )
  )
export const extractUsersFromArticles = (articles: ApiArticle[]) =>
  D.values(
    A.reduce(articles, {}, (users, article) =>
      G.isNotNullable(article.author) ? D.set(users, article.author.id, article.author) : users
    )
  )
