import { byId } from "@/fns/byId"
import * as Projects from "@/services/projects/service"
import { match } from "ts-pattern"
import { projectsStore } from "."
import { localizeProject } from "./localizers"

/**
 * getProjects
 */
export const getProjects = async () =>
  match(await Projects.service.index())
    .with({ error: false }, ({ data }) => {
      projectsStore.evolve({ projects: byId(data.projects, localizeProject) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getProject
 */
export const getProject = async (id: string) =>
  match(await Projects.service.read(id))
    .with({ error: false }, ({ data }) => {
      projectsStore.evolve({ projects: D.set(data.project.id, localizeProject(data.project)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateProject
 */
export const updateProject = async (id: string, payload: Projects.Payload["update"]) =>
  match(await Projects.service.update(id, payload))
    .with({ error: false }, ({ data }) => {
      projectsStore.evolve({ projects: D.set(data.project.id, localizeProject(data.project)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteProject
 */
export const deleteProject = async (id: string) =>
  match(await Projects.service.delete(id))
    .with({ error: false }, () => {
      projectsStore.evolve({ projects: D.deleteKey(id) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getLastestProjects
 */
export const getLastestProjects = async () =>
  match(await Projects.service.latest())
    .with({ error: false }, ({ data }) => {
      const projectsById = byId(data.projects, localizeProject)
      projectsStore.evolve({ projects: D.merge(projectsById) })
      return { error: false, projects: D.values(projectsById) } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))
