import { focusOutlineClasses } from "@/components/frontend/button"
import { ImageHeader } from "@/components/frontend/image-header"
import { LuxembourgMap, allRegions } from "@/components/frontend/map"
import { Prose } from "@/components/frontend/prose"
import { HN } from "@/components/ui/hn"
import { Image } from "@/components/ui/image"
import { Link, LinkExternal } from "@/components/ui/link"
import { Within } from "@/components/ui/within"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { regionLink } from "@/pages/kulturrallye/Routes"
import { useTranslation } from "@/store/languages/hooks"
import { getPreview, getThumbnail } from "@/store/medias/helpers"
import { Envelope } from "@phosphor-icons/react"
import { ItemMappingExport, getMediasFile } from "."
import { Container } from "../container"
import { Wrapper } from "../wrapper"

/**
 * dictionary src/dictionaries/en/pages/kulturrallye.json
 */
const dictionary = createContextMapper("components", "map")

type RenderFC = ItemMappingExport<"kultur-rallye">["Render"]

export const Render: RenderFC = ({ item, files }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const { titleLevel, image, imageSmall } = item.props
  const { title, secondary, mapTitle, mapSecondary, content, contactTitle } = t(item).props
  const imageFile = getMediasFile(files, image)
  const imageSmallFile = getMediasFile(files, imageSmall)

  const [isWithinAccessibleMap, setIsWithinAccessibleMap] = React.useState(false)

  return (
    <Wrapper margin="normal">
      <Container x="md">
        <ImageHeader background={getPreview(imageFile)}>
          <ImageHeader.Main>
            <Image
              wrapperCx="lg:absolute right-[55px] top-9"
              className="size-16 sm:size-24 object-cover"
              src={getThumbnail(imageSmallFile)}
            />
            <ImageHeader.Content>
              <ImageHeader.Title level={titleLevel}>{title}</ImageHeader.Title>
            </ImageHeader.Content>
            {oneIsNotEmpty(secondary) && (
              <div className="grow shrink-0 p-5 border border-white text-white text-sm font-medium uppercase">
                {secondary}
              </div>
            )}
          </ImageHeader.Main>
        </ImageHeader>
        <div className="flex flex-col py-[15px] lg:px-[15px] gap-8">
          <div
            className={cx(
              "flex flex-col lg:flex-row justify-between items-center gap-8 w-full mt-5 lg:mt-32 pt-8 md:pb-8 lg:py-[70px]",
              "border rounded-[2px] border-mercury"
            )}
          >
            <div className="flex flex-col lg:max-w-md flex-1 ml-[30px] md:ml-[51px] px-[30px] md:px-[51px] py-4 md:py-6 gap-3 md:gap-6 border-l-4 border-tomato text-base">
              <HN level={titleLevel + 1} className="text-2xl font-bold">
                {mapTitle}
              </HN>
              {oneIsNotEmpty(stripeHtml(mapSecondary)) && (
                <Prose dangerouslySetInnerHTML={{ __html: mapSecondary }} className="-my-6" />
              )}
            </div>
            <div className="relative w-full lg:flex-1 max-w-lg">
              <div>
                <LuxembourgMap
                  onClick={region => navigate(regionLink(region))}
                  each={cx(
                    "cursor-pointer transition-all",
                    "fill-neutral-50/80 hover:fill-neutral-50 stroke-neutral-300",
                    "dark:fill-neutral-900 dark:hover:fill-neutral-800 dark:stroke-neutral-400 ",
                    "hover:stroke-frontend-primary"
                  )}
                  className="absolute z-20 top-0 lg:top-[50%] inset-x-0 w-full lg:-translate-y-[40%]"
                />
                <svg
                  className="w-full left-0 right-0 top-0 lg:absolute z-10 lg:translate-y-[-40%] lg:top-[50%] fill-card"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 553 793"
                >
                  <path d="M70 0h283l200 397-46 396H20L0 397 70 0Z" />
                </svg>
              </div>
              <Within
                onFocusIn={() => setIsWithinAccessibleMap(true)}
                onFocusOut={() => setIsWithinAccessibleMap(false)}
              >
                <ul
                  className={cx(
                    "absolute right-5 text-sm top-0 z-30 flex flex-col items-end justify-end gap-x-4 gap-y-1 underline text-bluewhale font-semibold flex-wrap transition-all",
                    isWithinAccessibleMap ? "" : "sr-only"
                  )}
                >
                  {A.map(allRegions, region => (
                    <li key={region}>
                      <Link className={cx(focusOutlineClasses)} to={regionLink(region)}>
                        {_(region)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Within>
            </div>
          </div>
          <Prose
            dangerouslySetInnerHTML={{ __html: content }}
            className="md:max-w-lg xl:max-w-2xl"
          />
          <HN
            level={titleLevel + 1}
            className="text-xl font-bold text-shark md:max-w-lg xl:max-w-2xl"
          >
            {contactTitle}
          </HN>
          <div className="flex flex-wrap gap-8">
            {A.filterMap(item.props.contacts, id => (
              <Contact key={id} contact={D.get(t(item).props.contacts, id)} />
            ))}
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}

/**
 * Contact
 */
const Contact: React.FC<{
  contact: Option<{
    name: string
    company: string
    region: string
    email: string
  }>
}> = ({ contact }) => {
  if (G.isNullable(contact)) return null
  return (
    <div className="relative z-10 flex flex-col w-full max-w-sm gap-2 p-8 border border-mercury bg-white text-shark text-sm">
      <div>
        <span className="text-frontend-orient uppercase font-bold">{contact.name}</span>{" "}
        {oneIsNotEmpty(contact.company) && <span>({contact.company})</span>}
      </div>
      <div className="">{contact.region}</div>
      <div className="flex items-center gap-2">
        <Envelope size={16} strokeWidth={1} />
        <LinkExternal href={`mailto${contact.email}`} className="underline">
          {contact.email}
        </LinkExternal>
      </div>
    </div>
  )
}
