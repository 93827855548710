import serverErrorImage from "@/assets/images/serveur-error.png"
import { Button } from "@/components/frontend/button"
import { Container } from "@/components/layout/frontend/container"
import { Wrapper } from "@/components/layout/frontend/wrapper"
import { Image } from "@/components/ui/image"
import { useSearch } from "wouter/use-location"

/**
 * dictionary src/dictionaries/fr/pages/server-error.json
 */
const dictionary = createContextMapper("pages", "server-error")
/**
 * Page: ServerError
 */
const ServerError: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const error = useErrorsDictionary()
  const search = useSearch()
  const code = React.useMemo(() => {
    const code = new URLSearchParams(search).get("code")
    if (A.includes(errorCodes, code)) return code as ErrorCode
    return "SERVER_ERROR" as ErrorCode
  }, [search])

  const errorMessage = error(code)
  return (
    <Wrapper>
      <Container x="md">
        <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
          <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
            <div className="relative">
              <div className="absolute">
                <div className="">
                  <h1 className="my-2 text-gray-800 font-bold text-2xl">{_("title")}</h1>
                  <p className="my-2 text-gray-800">{errorMessage}</p>
                  <Button type="link" href="/" variant="dark" className="sm:w-full lg:w-auto">
                    {_("button")}
                  </Button>
                </div>
              </div>
              <div
                aria-hidden
                className="text-[150px]/[150px] sm:text-[200px]/[200px] xl:text-[300px]/[200px] font-extrabold text-tomato/10"
              >
                500
              </div>
            </div>
          </div>
          <div>
            <Image src={serverErrorImage} />
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}
export default ServerError

/**
 * errors
 */
const errorCodes: ErrorCode[] = [
  "FETCH_ERROR",
  "UNAUTHORIZED_ACCESS",
  "RESOURCE_NOT_ALLOWED",
  "RESOURCE_NOT_FOUND",
  "LIMIT_EXCEEDED",
  "SERVER_ERROR",
]
type ErrorCode =
  | "FETCH_ERROR"
  | "UNAUTHORIZED_ACCESS"
  | "RESOURCE_NOT_ALLOWED"
  | "RESOURCE_NOT_FOUND"
  | "LIMIT_EXCEEDED"
  | "SERVER_ERROR"
