import { Card, Fields, Menu, Row } from "@/components/collection"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { useSelectItem } from "@/hooks/useSelect"
import { Article } from "@/store/articles/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import { CalendarClock, CalendarPlus, Eye, EyeOff, ImageIcon } from "lucide-react"
import { Context, ContextMenu, usePageContext } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/articles.json
 */
const dictionary = createContextMapper("pages", "dashboard", "articles")

/**
 * Item
 */
type Props = { article: Article }
export const Item: React.FC<Props> = ({ article }) => {
  const { view } = usePageContext()
  const { selectItemProps } = useSelectItem(Context, article.id, () =>
    navigate(`/dashboard/offers/${article.id}`)
  )
  return (
    <Menu type="context-menu" menu={<ContextMenu article={article} />} asChild>
      <div className="grid" {...selectItemProps}>
        {view === "grid" ? <ItemCard {...{ article }} /> : <ItemRow {...{ article }} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCard
 */
const ItemCard: React.FC<Props> = ({ article }) => {
  const { _ } = useDictionary(dictionary())
  const format = useDateFnsLocaleFormat()
  const { isSelected } = useSelectItem(Context, article.id)
  const t = useTranslation()
  const { title, description } = t(article.seo)
  const image = useMediasFile(t(article.seo).image)

  return (
    <Card
      className={cx("overflow-hidden", status === "deleted" && "opacity-75")}
      selected={isSelected}
    >
      <Card.Image src={image?.previewUrl} className="aspect-video">
        <ImageIcon size={64} strokeWidth={1} />
      </Card.Image>
      <Card.Header>
        <Card.Title>{textPlaceholder(title, _("no-title"))}</Card.Title>
        <Card.Description>{textPlaceholder(description, _("no-description"))}</Card.Description>
        <Card.Menu menu={<ContextMenu article={article} />} />
      </Card.Header>
      <Card.Content>
        <Fields divider>
          <Fields.Item
            name={_("state")}
            icon={<Eye aria-hidden />}
            value={_(`state-${article.state}`)}
            stretch
          />
          <Fields.Item
            name={_("created-at")}
            icon={<CalendarPlus aria-hidden />}
            value={format(article.createdAt, "PPpp")}
            stretch
          />
          <Fields.Item
            name={_("updated-at")}
            icon={<CalendarClock aria-hidden />}
            value={format(article.updatedAt, "PPpp")}
            stretch
          />
        </Fields>
      </Card.Content>
    </Card>
  )
}

/**
 * ItemRow
 */
const ItemRow: React.FC<Props> = ({ article }) => {
  const { _ } = useDictionary(dictionary())

  const { isSelected } = useSelectItem(Context, article.id)
  const format = useDateFnsLocaleFormat()
  const t = useTranslation()
  const { title } = t(article.seo)
  const image = useMediasFile(t(article.seo).image)
  return (
    <Row selected={isSelected}>
      <Row.Image src={image?.previewUrl} alt={image ? t(image).alt : undefined}>
        <ImageIcon size={12} className="text-foreground/50" aria-hidden />
      </Row.Image>
      <Row.Header className="grow">
        <Row.Title>{textPlaceholder(title, _("no-title"))}</Row.Title>
        <Row.Description>
          {_("created-at")} {format(article.createdAt, "PP")}
        </Row.Description>
      </Row.Header>
      <Row.Content>
        <Row.Icon tooltip={_(`state-${article.state}`)}>
          {article.state === "draft" && <EyeOff aria-hidden />}
          {article.state === "published" && <Eye aria-hidden />}
        </Row.Icon>
      </Row.Content>
      <Row.Menu menu={<ContextMenu article={article} />} />
    </Row>
  )
}

/**
 * helpers
 */
const textPlaceholder = (value: string, placeholder: string) =>
  S.isEmpty(S.trim(value ?? "")) ? placeholder : value
