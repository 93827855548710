import { Menu, useMenu } from "@/components/collection"
import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { Confirm } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { useSelectItem } from "@/hooks/useSelect"
import { archiveWorkshopReservation, deleteWorkshopReservation } from "@/store/workshops/actions"
import { useOldReservations } from "@/store/workshops/hooks"
import { WorkshopReservation } from "@/store/workshops/localizers"
import { SelectionPlus, SelectionSlash } from "@phosphor-icons/react"
import { Archive, Ticket, Trash } from "lucide-react"
import { DetailsDialog } from "./Details"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/reservations.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "reservations")

/**
 * Context reservations
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    "list",
    "state-reservations-view",
    "interface",
    sessionStorage
  )

  // confirms
  const displayName = (id: string) => id
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    displayName,
    onAsyncConfirm: deleteWorkshopReservation,
    dictionary: Confirm.dictionary(dictionary("delete-confirm")),
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    displayName,
    list: selected,
    onAsyncConfirm: deleteWorkshopReservation,
    dictionary: Confirm.dictionary(dictionary("delete-selection-confirm")),
  })
  const { confirm: confirmArchive, ...archiveProps } = useConfirm<string>({
    displayName,
    onAsyncConfirm: archiveWorkshopReservation,
    dictionary: Confirm.dictionary(dictionary("archive-confirm")),
  })
  const { confirm: confirmArchiveSelection, ...archiveSelectionProps } = useConfirm<void, string>({
    displayName,
    list: selected,
    onAsyncConfirm: archiveWorkshopReservation,
    dictionary: Confirm.dictionary(dictionary("archive-selection-confirm")),
  })
  const oldReservations = useOldReservations()
  const { confirm: confirmArchiveOlder, ...archiveOlderProps } = useConfirm<void, string>({
    displayName,
    list: oldReservations,
    onAsyncConfirm: archiveWorkshopReservation,
    dictionary: Confirm.dictionary(dictionary("archive-older-confirm")),
  })

  // dialogs
  const { setItem: details, ...detailsProps } = useDialog<WorkshopReservation>()

  return (
    <Context.Provider
      value={{
        oldReservations,
        selected,
        setSelected,
        view,
        setView,
        details,
        confirmDelete,
        confirmDeleteSelection,
        confirmArchive,
        confirmArchiveSelection,
        confirmArchiveOlder,
      }}
    >
      {children}
      <Confirm {...deleteProps} />
      <Confirm {...deleteSelectionProps} />
      <Confirm {...archiveProps} />
      <Confirm {...archiveSelectionProps} />
      <Confirm {...archiveOlderProps} />
      <DetailsDialog {...detailsProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
export const ContextMenu: React.FC<{
  reservation: WorkshopReservation
}> = ({ reservation }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const {
    confirmDelete,
    confirmDeleteSelection,
    confirmArchive,
    confirmArchiveSelection,
    details,
  } = usePageContext()
  const { id } = reservation
  const { isSelected, toggleSelection } = useSelectItem(Context, id)
  const { type } = useMenu()
  const isContextMenu = type === "context-menu"

  return (
    <>
      <Menu.Item onClick={toggleSelection}>
        {isSelected ? <SelectionSlash aria-hidden /> : <SelectionPlus aria-hidden />}
        {_(isSelected ? "unselect" : "select")}
      </Menu.Item>
      <Menu.Item onClick={() => details(reservation)}>
        <Ticket aria-hidden />
        {_("display")}
      </Menu.Item>
      {!reservation.archived && (
        <Menu.Item onClick={() => confirmArchive(id)}>
          <Archive aria-hidden />
          {_("archive")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => confirmDelete(id)}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
      {isContextMenu && isSelected && (
        <>
          <Menu.Separator />
          {!reservation.archived && (
            <Menu.Item onClick={confirmArchiveSelection}>
              <Archive aria-hidden />
              {_("archive-selection")}
            </Menu.Item>
          )}
          <Menu.Item onClick={confirmDeleteSelection}>
            <Trash aria-hidden />
            {_("delete-selection")}
          </Menu.Item>
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  oldReservations: string[]
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  details: (value: WorkshopReservation) => void
  confirmDelete: (value: string) => void
  confirmDeleteSelection: () => void
  confirmArchive: (value: string) => void
  confirmArchiveSelection: () => void
  confirmArchiveOlder: () => void
}
