import { Image } from "@/components/ui/image"
import { useTranslation } from "@/store/languages/hooks"
import { getPreview, getThumbnail } from "@/store/medias/helpers"
import { useMediasFile } from "@/store/medias/hooks"
import { Place } from "@/store/places/localizers"

/**
 * components
 */

export const PlaceHeader: React.FC<{ place: Place }> = ({ place }) => {
  const cover = useMediasFile(place.cover)
  const image = useMediasFile(place.image)
  const t = useTranslation()
  return (
    <div className="relative w-full">
      <Image
        src={getPreview(cover)}
        alt={cover ? t(cover).alt : undefined}
        draggable="false"
        className="aspect-[36/9] object-cover w-full rounded-t-md"
      />
      <div className="absolute bottom-1 right-1 grid w-1/4 max-w-40 p-2 aspect-video opacity-95 bg-background border border-secondary-300 rounded-lg overflow-hidden">
        <Image
          src={getThumbnail(image)}
          alt={image ? t(image).alt : undefined}
          draggable="false"
          wrapperCx="flex justify-center items-center"
          className="max-w-full max-h-full"
        />
      </div>
    </div>
  )
}
