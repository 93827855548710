import { PlaySquare, Popcorn } from "lucide-react"
import {
  Form,
  FormFieldWrapper,
  FormFieldWrapperProps,
  extractInputProps,
  extractWrapperProps,
  inputVariants,
  useFieldContext,
} from "."
import { buttonFormField } from "../ui/button"
import { YoutubeIFrame } from "../ui/youtube"
/**
 * FormYoutube
 */
type Props = YoutubeInputProps & FormFieldWrapperProps
export const FormYoutube: React.FC<Props> = ({ ...props }) => (
  <FormFieldWrapper {...extractWrapperProps(props)}>
    <YoutubeInput {...extractInputProps(props)} />
  </FormFieldWrapper>
)
type YoutubeInputProps = {
  placeholder?: string
  className?: ClassName
  disabled?: boolean
}
const YoutubeInput: React.FC<YoutubeInputProps> = ({ className, placeholder, disabled }) => {
  const { value, disabled: ctxDisabled } = useFieldContext<string>()
  const isValid = React.useMemo(() => /^[a-zA-Z0-9_-]{11}$/.test(value), [value])

  return (
    <div className="flex flex-col gap-4">
      <Form.Input
        type="string"
        placeholder={placeholder}
        disabled={ctxDisabled || disabled}
        className={cx(inputVariants({ className }))}
      />
      {isValid ? (
        <YoutubeIFrame
          videoId={value}
          title=""
          className="border border-input rounded-md bg-muted"
          options={{ autoplay: false }}
        />
      ) : (
        <div className={cx(buttonFormField, "aspect-video")}>
          <span className="relative" aria-hidden>
            <PlaySquare size={64} strokeWidth={0.5} />
            <span className="absolute -top-1 -right-1 flex justify-center items-center size-6 bg-muted rounded-full">
              <Popcorn size={20} strokeWidth={1.4} />
            </span>
          </span>
        </div>
      )}
    </div>
  )
}
