import { ApiSeo, ApiSeoSocialMeta, ApiSeoTranslation } from "@/services/seos/schemas"
import { byLanguage } from "../languages/helpers"

/**
 * localizeSeo
 */
export const localizeSeo = (parsed: ApiSeo) => ({
  id: parsed.id,
  translations: byLanguage(parsed.translations, localizeSeoTranslation),
  files: A.map(parsed.files, D.prop("id")),
})
export type Seo = ReturnType<typeof localizeSeo>

/**
 * localizeSeoTranslation
 */
export const localizeSeoTranslation = (parsed: ApiSeoTranslation) => ({
  id: parsed.id,
  title: parsed.title,
  description: parsed.description,
  keywords: parsed.keywords,
  socials: A.map(parsed.socials, localizeSeoSocialMeta),
  image: parsed.imageId,
})
export type SeoTranslation = ReturnType<typeof localizeSeoTranslation>

/**
 * localizeSeoSocialMeta
 */
export const localizeSeoSocialMeta = (parsed: ApiSeoSocialMeta) => ({
  type: parsed.type,
  title: parsed.title,
  description: parsed.description,
  image: parsed.imageId,
})
export type SeoSocialMeta = ReturnType<typeof localizeSeoSocialMeta>
