import { Switch } from "@/components/ui/switch"
import { FormGroup, FormGroupError, FormGroupInfo, FormLabel, useFieldContext } from "."

/**
 * FormSwitch
 */
type Props = SelectInputProps & {
  label?: string
  name?: string
  info?: string | false
  className?: ClassName
}
export const FormSwitch: React.FC<Props> = ({
  label,
  name,
  info,
  disabled,
  className,
  ...props
}) => {
  return (
    <FormGroup name={name} className={className}>
      <div className="flex justify-between items-center">
        <FormLabel>{label}</FormLabel>
        <SwitchInput {...props} disabled={disabled} />
      </div>
      <FormGroupError />
      {info !== false && <FormGroupInfo>{info}</FormGroupInfo>}
    </FormGroup>
  )
}

type SelectInputProps = {
  size?: "default" | "sm"
  placeholder?: string
  className?: ClassName
  disabled?: boolean
}
const SwitchInput: React.FC<SelectInputProps> = ({ size, disabled }) => {
  const { id, name, value, setFieldValue, disabled: ctxDisabled } = useFieldContext<boolean>()
  return (
    <Switch
      id={id}
      name={name}
      checked={value}
      size={size}
      onCheckedChange={setFieldValue}
      disabled={disabled || ctxDisabled}
    />
  )
}
