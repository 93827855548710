import { useLayoutContext } from "./context"

/**
 * ProgressBar
 */
export const ProgressBar: React.FC = () => {
  const { loading } = useLayoutContext()

  return (
    <div
      className={cx(
        "fixed z-50 top-0 inset-x-0 w-full pointer-events-none transition-opacity duration-300 ease-in-out",
        loading.state ? "opacity-100" : "opacity-0"
      )}
      aria-hidden
    >
      <div className="h-1.5 w-full bg-solitude overflow-hidden bg-gradient-to-r from-solitude to-frontend-tomato">
        <div className="w-full h-full bg-gradient-to-r from-solitude to-frontend-tomato origin-left-right animate-progress-bar"></div>
      </div>
    </div>
  )
}
