import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { useTranslation } from "@/store/languages/hooks"
import { updateMediasFile, updateMediasFolder } from "@/store/medias/actions"
import { getChildrenFolderIds } from "@/store/medias/helpers"
import { useMediasFilesById, useMediasFoldersById } from "@/store/medias/hooks"
import { SelectFolder } from "../components/select-folder"
import { confirmSelection, useMediaContext } from "../context"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias", "move-selection-dialog")

/**
 * MoveSelectionDialog
 */
export const MoveSelectionDialog: React.FC<UseDialogProps<void>> = props => {
  const { selected, currentFolder } = useMediaContext()
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-xl">
      <DialogForm
        {...props}
        selection={{
          folders: [...selected.folders],
          files: [...selected.files],
        }}
        currentFolder={currentFolder}
      />
    </Dialog>
  )
}

/**
 * DialogForm
 */
type Props = {
  selection: { folders: string[]; files: string[] }
  currentFolder: string | null
}
const DialogForm: React.FC<UseDialogFormProps<void> & Props> = ({
  selection,
  currentFolder,
  onOpenChange,
}) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()

  const [selected, setSelected] = React.useState<string | null>(currentFolder)
  const { resetSelection } = useMediaContext()

  const translate = useTranslation()
  const filesById = useMediasFilesById()
  const foldersById = useMediasFoldersById()
  const displayFileName = (id: string) => {
    const file = D.get(filesById, id)
    return G.isNotNullable(file) ? translate(file).name : id
  }
  const displayFolderName = (id: string) => foldersById[id]?.name ?? id

  const onSubmit = async (nextId?: string) => {
    const moveId = nextId ?? selected
    onOpenChange(false)
    await confirmSelection(
      selection,
      (id: string) => updateMediasFolder(id, { parentId: moveId }),
      displayFolderName,
      (id: string) => updateMediasFile(id, { folderId: moveId }),
      displayFileName,
      `move-selection-confirm`,
      _,
      resetSelection
    )()
  }

  const disabledIds = React.useMemo(
    () => [
      ...A.reduce(selection.folders, [], (ids: string[], currentId) => [
        ...ids,
        ...getChildrenFolderIds(currentId, D.values(foldersById)),
        currentId,
      ]),
    ],
    [selection.folders, foldersById]
  )
  const submitDisabled = selected === currentFolder || disabledIds.includes(selected ?? "")

  return (
    <>
      <SelectFolder current={currentFolder} {...{ selected, setSelected, onSubmit, disabledIds }} />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <Button
          onClick={e => {
            e.stopPropagation()
            onSubmit()
          }}
          disabled={submitDisabled}
        >
          {_("submit")}
        </Button>
      </Dialog.Footer>
    </>
  )
}
