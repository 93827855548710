import { useIsAdmin } from "@/store/auth/hooks"
import { type DefaultParams, Redirect, Route, type RouteProps } from "wouter"

/**
 * RouteAdmin
 */
interface Props extends RouteProps {
  redirect?: string
}
export const RouteAdmin: React.FC<Props> = ({ redirect = "/sign-in", children, ...props }) => {
  const isAdmin = useIsAdmin()
  return (
    <>
      {isAdmin ? (
        <Route {...props}>{G.isFunction(children) ? (params: DefaultParams) => children(params) : children}</Route>
      ) : (
        <Route {...props}>
          <Redirect to={redirect} />
        </Route>
      )}
    </>
  )
}
