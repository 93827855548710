import logoUrl from "@/assets/images/logo.png"
import { ButtonIcon } from "@/components/frontend/button"
import { useActiveLocation } from "@/components/ui/hooks/useActiveLocation"
import { Link } from "@/components/ui/link"
import { SrOnly } from "@/components/ui/sr-only"
import { makePathToApi } from "@/fns/File"
import { useAuthStore } from "@/store/auth"
import { signOut } from "@/store/auth/actions"
import { useIsAdmin, useIsMember } from "@/store/auth/hooks"
import { List, Spinner, User, X } from "@phosphor-icons/react"
import { Container } from "./container"
import { useLayoutContext } from "./context"
import { LanguagesSelector } from "./languages"
import { MenuLink, menuHeader1, menuHeader2 } from "./menus"
import { Wrapper } from "./wrapper"

/**
 * dictionary src/dictionaries/en/components/frontend/layout.json
 */
const dictionary = createContextMapper("components", "frontend", "layout")

/**
 * Header
 */
export const Header: React.FC = () => {
  const { _ } = useDictionary(dictionary("header"))
  const { mobileMenu } = useLayoutContext()

  const isMember = useIsMember()
  const isAdmin = useIsAdmin()
  const displayLink = (link: MenuLink) => {
    const { restrict } = link
    if (restrict === "member") return isAdmin || isMember
    if (restrict === "admin") return isAdmin
    return true
  }

  return (
    <>
      <header
        className={cx(
          mobileMenu.state &&
            "overflow-y-scroll overscroll-none fixed w-full h-screen bg-white z-[9999]",
          "text-sm lg:text-xs xl:text-sm"
        )}
      >
        <Wrapper className={cx(mobileMenu.state && "h-screen")}>
          <Container
            x="sm"
            className={cx(
              "flex flex-col lg:flex-row lg:items-center py-5",
              mobileMenu.state && "grow"
            )}
          >
            <nav className="flex justify-between items-center w-full lg:w-auto">
              {/* logo */}
              <Link href="/">
                <span>
                  <img
                    className="h-10 lg:h-8 xl:h-10"
                    src={logoUrl}
                    alt={"VP: Voie de préparation"}
                  />
                </span>
              </Link>
              {/* mobile menu toggle */}
              <ButtonIcon
                className={cx(
                  "[&>svg]:w-5 [&>svg]:h-5",
                  mobileMenu.state ? "bg-orient" : "lg:hidden"
                )}
                variant={"dark"}
                size={"icon"}
                onClick={mobileMenu.toggle}
              >
                {mobileMenu.state ? <X /> : <List />}
                <SrOnly>{_("close-mobile-menu")}</SrOnly>
              </ButtonIcon>
            </nav>
            <div
              className={cx(
                "lg:flex w-full flex-col lg:flex-row lg:items-center gap-5 lg:gap-3 xl:gap-5",
                mobileMenu.state ? "flex grow" : "hidden"
              )}
            >
              {/* nav menu 1 */}
              <nav className="flex grow lg:items-center pt-8 lg:pt-0">
                <ul className="flex justify-start lg:grow lg:justify-end gap-5 lg:gap-x-3.5 xl:gap-x-[30px] lg:flex-row flex-col">
                  {A.filterMap(menuHeader1, link =>
                    displayLink(link) ? <Link1 link={link} key={link.id} /> : O.None
                  )}
                </ul>
              </nav>
              {/* nav menu 2 */}

              <nav className="flex flex-wrap items-center justify-center">
                <ul className="gap-x-[10px] lg:gap-x-1.5 xl:gap-x-[10px] grid grid-cols-2 lg:flex justify-end w-full lg:w-auto">
                  {A.filterMap(menuHeader2, link =>
                    displayLink(link) ? <Link2 link={link} key={link.id} /> : O.None
                  )}
                </ul>
              </nav>

              {/* nav auth & languages */}
              <nav className="flex items-center gap-5 lg:gap-3 xl:gap-3">
                <AuthLink />
                <Divider className="hidden lg:flex" />
                <LanguagesSelector />
              </nav>
            </div>
          </Container>
        </Wrapper>
      </header>
    </>
  )
}

/**
 * Link1
 */
const Link1: React.FC<{ link: MenuLink }> = ({ link }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { mobileMenu } = useLayoutContext()
  const { isActivePath, isActiveLocation } = useActiveLocation()
  const isActive = link.exact ? isActiveLocation(link.path) : isActivePath(link.path)
  return (
    <li>
      <Link
        href={link.path}
        className={cx(
          "group/link relative lg:flex flex-col inline-block lg:mb-0 text-emperor underline-offset-8 decoration-2",
          "transition-all outline-none focus-visible:font-medium",
          isActive
            ? "text-orient underline lg:no-underline"
            : "border-transparent hover:text-orient hover:underline lg:hover:no-underline focus-visible:underline lg:focus-visible:no-underline  focus-visible:text-orient"
        )}
        onClick={mobileMenu.close}
      >
        {_(link.name)}
        <span
          aria-hidden
          className={cx(
            "absolute top-6 hidden lg:block bg-orient h-0.5 w-full",
            isActive
              ? "scale-x-100"
              : "scale-x-0 group-hover/link:scale-x-100 transition-transform duration-300 group-focus-visible/link:scale-x-100"
          )}
        />
      </Link>
    </li>
  )
}

/**
 * Link2
 */
const Link2: React.FC<{ link: MenuLink }> = ({ link }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { mobileMenu } = useLayoutContext()
  const { isActivePath, isActiveLocation } = useActiveLocation()
  const isActive = link.exact ? isActivePath(link.path) : isActiveLocation(link.path)
  return (
    <li>
      <Link
        className={cx(
          "inline-flex justify-center items-center h-[46px] w-full lg:w-auto",
          "border-[1px] border-mercury text-shark hover:border-orient focus:bg-aquahaze active:bg-orient active:border-orient active:text-white px-4 lg:px-2 xl:px-4",
          "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary-500 focus-visible:ring-offset-2",
          "focus:shadow-[0_0px_4px_0px] focus:shadow-matisse focus:border-[1px] focus:border-orient",
          "disabled:pointer-events-none disabled:opacity-50 disabled:bg-cloud",
          "transition-colors",
          isActive ? "text-orient border-orient" : ""
        )}
        href={link.path}
        onClick={mobileMenu.close}
      >
        {_(link.name)}
      </Link>
    </li>
  )
}

/**
 * AuthLink
 */
const AuthLink: React.FC = () => {
  const { _ } = useDictionary(dictionary("header"))
  const { mobileMenu } = useLayoutContext()
  const { authenticated, initDone } = useAuthStore()
  const className =
    "flex items-center h-[46px] px-4 lg:px-2 xl:px-4 gap-x-2  text-shark font-medium animate-appear-opacity [&_svg]:w-4 [&_svg]:h-4 xl:[&_svg]:w-5 xl:[&_svg]:h-5"
  const icon = !initDone ? <Spinner className="animate-slow-rotate" /> : <User />
  return (
    <div className="grow lg:grow-0 flex justify-center">
      {authenticated ? (
        <Link className={className} href={"/sign-out"} onClick={signOut}>
          {icon}
          {_("sign-out")}
        </Link>
      ) : (
        <a className={className} href={makePathToApi("/saml/login")} onClick={mobileMenu.close}>
          {icon}
          {_("sign-in")}
        </a>
      )}
    </div>
  )
}

/**
 * Divider
 */
const Divider: React.FC<React.ComponentProps<"span">> = ({ className, ...props }) => {
  return (
    <span
      aria-hidden
      className={cx(
        "flex border-b-[1px] lg:border-l-[1px] w-full lg:w-0 border-mercury lg:h-[30px]",
        className
      )}
      {...props}
    />
  )
}
