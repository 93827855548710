import { ToolbarButton } from "./button"
import { ToolbarFilters } from "./filters"
import { ToolbarRoot } from "./root"
import { ToolbarSearch } from "./search"
import { ToolbarSort } from "./sort"
import { ToolbarView } from "./view"
export const Toolbar = Object.assign(ToolbarRoot, {
  Search: ToolbarSearch,
  Button: ToolbarButton,
  Sort: ToolbarSort,
  Filters: ToolbarFilters,
  View: ToolbarView,
})
