import { makePathToApi } from "@/fns/File"
import { ApiUser, ApiUserProfile } from "@/services/users/schemas"

/**
 * localizeUser
 */
export const localizeUser = (parsed: ApiUser) => ({
  ...D.selectKeys(parsed, ["id", "email", "role", "status"]),
  profile: localizeUserProfile(parsed.profile),
  lastLoginAt: G.isNotNullable(parsed.lastLoginAt) ? T.parseISO(parsed.lastLoginAt) : null,
  deletedAt: G.isNotNullable(parsed.deletedAt) ? T.parseISO(parsed.deletedAt) : null,
  createdAt: T.parseISO(parsed.createdAt),
  updatedAt: T.parseISO(parsed.updatedAt),
})
export type User = ReturnType<typeof localizeUser>

/**
 * localizeUserProfile
 */
export const localizeUserProfile = (parsed: ApiUserProfile) => ({
  ...D.selectKeys(parsed, ["firstname", "lastname", "position", "company", "email"]),
  image: S.isNotEmpty(parsed.image.url) ? makePath(parsed.image.url) : null,
  preview: S.isNotEmpty(parsed.image.previewUrl) ? makePath(parsed.image.previewUrl) : null,
  thumbnail: S.isNotEmpty(parsed.image.thumbnailUrl) ? makePath(parsed.image.thumbnailUrl) : null,
})
export type UserProfile = ReturnType<typeof localizeUserProfile>

/**
 * helpers
 */
const makePath = (path: string) => (S.isNotEmpty(path) ? makePathToApi(path) : null)
