import { Menu, useMenu } from "@/components/collection"
import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { Confirm } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { useSelectItem } from "@/hooks/useSelect"
import { deletePlace, updatePlace } from "@/store/places/actions"
import { usePlacesById } from "@/store/places/hooks"
import { Place } from "@/store/places/localizers"
import { SelectionPlus, SelectionSlash } from "@phosphor-icons/react"
import { Eye, EyeOff, Pen, PlusSquare, Rocket, Trash } from "lucide-react"
import { CreateDialog } from "./CreateDialog"
import { EditDialog } from "./EditDialog"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/places.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "places")

/**
 * Context places
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const placesById = usePlacesById()

  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    "grid",
    "state-places-view",
    "interface",
    sessionStorage
  )

  // actions
  const publish = (id: string) => updatePlace(id, { published: true })
  const unpublish = (id: string) => updatePlace(id, { published: false })

  // confirms
  const displayName = (id: string) => D.get(placesById, id)?.name ?? ""
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deletePlace,
    dictionary: Confirm.dictionary(dictionary("delete-confirm")),
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName,
    onAsyncConfirm: deletePlace,
    dictionary: Confirm.dictionary(dictionary("delete-selection-confirm")),
  })
  const { confirm: confirmPublishSelection, ...publishSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName,
    onAsyncConfirm: id => updatePlace(id, { published: true }),
    dictionary: Confirm.dictionary(dictionary("publish-selection-confirm")),
  })
  const { confirm: confirmUnpublishSelection, ...unpublishSelectionProps } = useConfirm<
    void,
    string
  >({
    list: selected,
    displayName,
    onAsyncConfirm: id => updatePlace(id, { published: false }),
    dictionary: Confirm.dictionary(dictionary("unpublish-selection-confirm")),
  })

  // dialogs
  const { setItem: create, ...createProps } = useDialog<void>()
  const { setItem: edit, ...editProps } = useDialog<Place>()

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        view,
        setView,
        create,
        edit,
        publish,
        unpublish,
        confirmDelete,
        confirmDeleteSelection,
        confirmPublishSelection,
        confirmUnpublishSelection,
      }}
    >
      {children}
      <Confirm {...deleteProps} />
      <Confirm {...deleteSelectionProps} />
      <Confirm {...publishSelectionProps} />
      <Confirm {...unpublishSelectionProps} />
      <CreateDialog {...createProps} />
      <EditDialog {...editProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
export const ContextMenu: React.FC<{
  place: Place
}> = ({ place }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const {
    confirmDelete,
    edit,
    create,
    publish,
    unpublish,
    confirmDeleteSelection,
    confirmPublishSelection,
    confirmUnpublishSelection,
  } = usePageContext()
  const { id } = place
  const { isSelected, toggleSelection } = useSelectItem(Context, place.id)
  const { type } = useMenu()
  const isContextMenu = type === "context-menu"
  return (
    <>
      <Menu.Item onClick={toggleSelection}>
        {isSelected ? <SelectionSlash aria-hidden /> : <SelectionPlus aria-hidden />}
        {_(isSelected ? "unselect" : "select")}
      </Menu.Item>
      <Menu.Item onClick={() => edit(place)}>
        <Pen aria-hidden />
        {_("edit")}
      </Menu.Item>
      <Menu.Item onClick={() => navigate(`/dashboard/kultureralley/${id}`)}>
        <Rocket aria-hidden />
        {_("display")}
      </Menu.Item>
      {place.published ? (
        <Menu.Item onClick={() => unpublish(id)}>
          <EyeOff aria-hidden />
          {_("unpublish")}
        </Menu.Item>
      ) : (
        <Menu.Item onClick={() => publish(id)}>
          <Eye aria-hidden />
          {_("publish")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => confirmDelete(id)}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
      {isContextMenu && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={create}>
            <PlusSquare aria-hidden />
            {_("create")}
          </Menu.Item>
          {isSelected && (
            <>
              <Menu.Separator />
              {place.published ? (
                <Menu.Item onClick={confirmUnpublishSelection}>
                  <EyeOff aria-hidden />
                  {_("unpublish-selection")}
                </Menu.Item>
              ) : (
                <Menu.Item onClick={confirmPublishSelection}>
                  <Eye aria-hidden />
                  {_("publish-selection")}
                </Menu.Item>
              )}
              <Menu.Item onClick={confirmDeleteSelection}>
                <Trash aria-hidden />
                {_("delete-selection")}
              </Menu.Item>
            </>
          )}
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  confirmDelete: (value: string) => void
  edit: (value: Place) => void
  publish: (id: string) => void
  unpublish: (id: string) => void
  create: () => void
  confirmDeleteSelection: () => void
  confirmPublishSelection: () => void
  confirmUnpublishSelection: () => void
}
