import { ApiMediasFile } from "@/services/medias/schemas"
import { ApiPage } from "@/services/pages/schemas"
import { extractFilesFromContent } from "../contents/helpers"
import { extractFilesFromSeo } from "../seos/helpers"

export const extractFilesFromPage = (page: ApiPage): ApiMediasFile[] => [
  ...extractFilesFromSeo(page.seo),
  ...extractFilesFromContent(page.content),
]
export const extractFilesFromPages = (pages: ApiPage[]): ApiMediasFile[] =>
  A.reduce(pages, [] as ApiMediasFile[], (list, page) => [...list, ...extractFilesFromPage(page)])
