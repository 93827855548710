import { Program } from "./localizers"

/**
 * getUniqueClasses
 */
export const getUniqueClasses = (programs: Program[]): string[] =>
  pipe(
    programs,
    A.reduce(new Set<string>(), (set, program) => set.add(program.class)),
    set => Array.from(set)
  )
