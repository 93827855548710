import { i18nConfig } from "@/config/i18n"

/**
 * dateToWeek:
 * get surrounding week days for any date
 */

export const dateToWeek = (date: Date | number, weekStartsOn: 0 | 1 = 1) =>
  T.eachDayOfInterval({
    start: T.startOfWeek(date, { weekStartsOn }),
    end: T.endOfWeek(date, { weekStartsOn }),
  })

/**
 * isValidInterval
 */
export const isValidInterval = (interval: Interval) => {
  if (!(T.isValid(interval.start) && T.isValid(interval.end))) return false
  if (T.isEqual(interval.start, interval.end)) return false
  return T.isBefore(interval.start, interval.end)
}

/**
 * Checks if two date values are of the same month and year
 * @param {unknown} date
 * @param {unknown} [basedate=(new Date())]
 * @returns {boolean}
 */
export const isSameMonth = (date: unknown, basedate: unknown = new Date()): boolean => {
  if (!(isDate(date) && isDate(basedate))) return false
  return basedate.getMonth() === date.getMonth() && basedate.getFullYear() === date.getFullYear()
}

/**
 * Checks if date is a valid Date
 * @param {unknown} date
 * @returns {boolean}
 */
export const isDate = (date: unknown): date is Date =>
  date instanceof Date && !isNaN(date.valueOf())

/**
 * Humanize dates
 */
export const humanTime = (date: Date, shorten?: boolean) => {
  if (T.getMinutes(date) === 0) return `${T.format(date, "H")}${shorten ? "" : "h"}`
  return `${T.format(date, "H")}h${T.format(date, "mm")}`
}

/**
 * humanDateInterval
 */
export const humanDateInterval = (
  { start, end }: { start: Date; end: Date },
  displayDate = true
) => {
  const locale = i18nConfig.dateFnsLocales.fr
  if (T.isSameDay(start, end)) {
    const date = displayDate ? `${T.format(start, "dd MMMM yyyy", { locale })}, ` : ""
    return `${date}${
      T.isEqual(start, end) ? humanTime(start) : `${humanTime(start, true)} - ${humanTime(end)}`
    }`
  }

  return `${T.format(start, "dd MMMM yyyy", { locale })}, ${humanTime(start)} - ${T.format(
    end,
    "dd MMMM yyyy",
    { locale }
  )} ${humanTime(end)}`
}

/**
 * formatShortDuration
 */
export const formatShortDuration = (...params: Parameters<typeof T.formatDuration>) =>
  pipe(
    T.formatDuration(...params),
    S.split(" "),
    A.mapWithIndex((index, ammountOrUnit) => (index % 2 === 0 ? ammountOrUnit : ammountOrUnit[0]))
  )

/**
 * formatDurationForExcel
 */
export const formatDurationForExcel = (duration: Duration) =>
  `${duration.hours}h${duration.minutes ? `${duration.minutes}` : ""}`
