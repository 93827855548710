import { Image } from "@/components/ui/image"
import { CaretLeft } from "@phosphor-icons/react"
import { Container, ImageOff } from "lucide-react"
import { containerVariants } from "../layout/frontend/container"
import { Button } from "./button"

/**
 * dictionary src/dictionaries/en/components/frontend/layout.json
 */
const dictionary = createContextMapper("components", "frontend", "layout")

/**
 * ImageHeaderContainer
 */

type ImageHeaderContainerProps = {
  children: React.ReactNode
  background: Option<string>
  className?: CX
  backTo?: string
  onBack?: Argless
}

const Root: React.FC<ImageHeaderContainerProps> = props => {
  const { _ } = useDictionary(dictionary())

  return (
    <div
      className={cx(
        "relative w-full flex justify-between overflow-hidden xl:rounded-[2px] md:pb-2 md:min-h-[350px]",
        props.className
      )}
    >
      <div className="absolute inset-0">
        {props.background && S.isNotEmpty(props.background) && (
          <Image src={props.background} className={"absolute inset-0 w-full h-full object-cover"}>
            <ImageOff size={64} />
          </Image>
        )}

        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(6,49,75,0.2), rgba(6,49,75,0.8))`,
          }}
        />
      </div>

      <div className="relative w-full flex flex-col justify-between gap-x-40">
        <div className="relative md:h-0">
          {G.isString(props.backTo) && (
            <Button
              href={props.backTo}
              type="link"
              className="uppercase font-semibold text-sm whitespace-nowrap tracking-wider"
            >
              <CaretLeft className="w-4" weight="bold" />
              {_("back")}
            </Button>
          )}

          {props.onBack && (
            <Button
              onClick={props.onBack}
              className="uppercase font-semibold text-sm whitespace-nowrap tracking-wider"
            >
              <CaretLeft className="w-4" weight="bold" />
              {_("back")}
            </Button>
          )}
        </div>

        {props.children}
      </div>
    </div>
  )
}

/**
 * ImageHeaderHead
 */

const Head: React.FC<{ children: React.ReactNode; className?: CX }> = ({ className, ...props }) => {
  return <Container r="sm" className={cx("flex flex-row justify-end py-9", className)} {...props} />
}

/**
 * ImageHeaderTitle
 */

type ImageHeaderTitleProps = {
  className?: CX
  children: React.ReactNode
  as?: keyof HTMLElementTagNameMap // @deprecated
  level?: number
}

const Title: React.FC<ImageHeaderTitleProps> = ({ as = "h1", level, className, ...props }) => {
  const As = (
    level ? `h${A.includes([1, 2, 3, 4, 5, 6], level) ? level : 1}` : as
  ) as React.ElementType
  return <As {...props} className={cx("text-3xl font-bold max-w-2xl", className)} />
}

/**
 * ImageHeaderContent
 */

const Content: React.FC<{ children: React.ReactNode; className?: CX }> = ({
  className,
  ...props
}) => {
  return (
    <div
      className={cx(
        "flex flex-col gap-5 w-full text-white text-base font-medium sm:font-normal sm:text-xl ",
        className
      )}
      {...props}
    />
  )
}

/**
 * ImageHeaderMain
 */

const Main: React.FC<{ children: React.ReactNode; className?: CX }> = props => {
  return (
    <div
      className={cx(
        containerVariants({ x: "sm" }),
        "flex flex-col items-start py-5 sm:py-[30px] md:py-[55px] lg:flex-row lg:items-end justify-between gap-x-8 gap-y-8",
        props.className
      )}
    >
      {props.children}
    </div>
  )
}

/**
 * ImageHeaderMain
 */

export const ImageHeader = Object.assign(Root, {
  Head,
  Title,
  Content,
  Main,
})
