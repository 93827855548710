import { i18nConfig } from "@/config/i18n"
import { Duration, formatDuration } from "date-fns"
import { tryOrNull } from "./to"

/**
 * Humanize file size formated
 */
export const humanFileSize = (sizeInBytes: number) => {
  let size = sizeInBytes
  let i = -1
  do {
    size /= 1024
    i++
  } while (size > 1024 && i < units.length)
  return `${Math.max(size, 0.1).toFixed(1)} ${units[i]}`
}
const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

/**
 * humanDuration
 */
export const humanDuration = (
  duration: Duration,
  locale: string | Locale = i18nConfig.dateFnsLocales.fr
) => {
  const currentLocale = G.isString(locale)
    ? D.get(i18nConfig.dateFnsLocales, locale as keyof typeof i18nConfig.dateFnsLocales) ??
      i18nConfig.dateFnsLocales.fr
    : locale
  return (
    tryOrNull(() => T.formatDuration(duration, { locale: currentLocale })) ??
    formatDuration(duration, { locale: currentLocale })
  )
}
