import { Card, Fields, Menu, Row } from "@/components/collection"
import { useBookingValues } from "@/components/kulturrallye/hooks"
import { Badge } from "@/components/ui/badge"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocale, useDictionary } from "@/dictionaries/hooks"
import { formatShortDuration } from "@/fns/Date"
import { useSelectItem } from "@/hooks/useSelect"
import { useEventsByWorkshopId } from "@/store/workshops/hooks"
import { Workshop } from "@/store/workshops/localizers"
import {
  Clock,
  Eye,
  EyeOff,
  Globe,
  Presentation,
  Ticket,
  TicketPercent,
  TicketSlash,
  UsersIcon,
} from "lucide-react"
import { Context, ContextMenu } from "./Context"

/**
 * dictionary src/dictionaries/fr/pages/dashboard/kultureralley/workshops.json
 * dictionary src/dictionaries/de/pages/dashboard/kultureralley/workshops.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "workshops")

/**
 * Item
 */
export const Item: React.FC<{ workshop: Workshop }> = props => {
  const { workshop } = props
  const { view } = React.useContext(Context)
  const { selectItemProps } = useSelectItem(Context, workshop.id, () =>
    navigate(`/dashboard/kultureralley/${workshop.placeId}/${workshop.id}`)
  )
  return (
    <Menu type="context-menu" menu={<ContextMenu {...props} />} asChild>
      <div className="grid" {...selectItemProps}>
        {view === "grid" ? <ItemCard {...props} /> : <ItemRow {...props} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCard
 */
const ItemCard: React.FC<{ workshop: Workshop }> = ({ workshop }) => {
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, workshop.id)
  const stripedDescription = React.useMemo(
    () => workshop.description.replace(/(<([^>]+)>)/gi, ""),
    [workshop.description]
  )
  const locale = useDateFnsLocale()
  const {
    totalSlots,
    totalBooking,
    bookingPercent,
    bookingPercentColor,
    bookingPercentMedian,
    bookingPercentMedianColor,
  } = useBookingValues(useEventsByWorkshopId(workshop.id))
  return (
    <Card selected={isSelected}>
      <Card.Header>
        <Card.Title className="line-clamp-1">{workshop.name}</Card.Title>
        <Card.Description className="line-clamp-3">{stripedDescription}</Card.Description>
        <Card.Menu menu={<ContextMenu {...{ workshop }} />} />
      </Card.Header>
      <Card.Content>
        <Fields divider>
          <Fields.Item
            name={_("state")}
            icon={<Eye aria-hidden />}
            value={_(`menu.state-${workshop.published ? "published" : "draft"}`)}
            stretch
          />
          <Fields.Item
            name={_("languages")}
            icon={<Globe aria-hidden />}
            value={pipe(workshop.languages, A.map(S.toUpperCase), A.join(", "))}
            stretch
          />
          <Fields.Item
            name={_("duration")}
            icon={<Clock aria-hidden />}
            value={formatShortDuration(workshop.duration, { locale })}
            stretch
          />
          <Fields.Item
            name={_("max-attendees")}
            icon={<UsersIcon aria-hidden />}
            value={`${workshop.maxAttendees}`}
            stretch
          />
        </Fields>
        <h3 className="mt-4 mb-2 text-base font-medium min-h-[1rem] leading-none tracking-tight line-clamp-1">
          {_("reservations")}
        </h3>
        <Fields divider>
          <Fields.Item
            name={_("reservations-slots")}
            icon={<Ticket aria-hidden />}
            value={`${totalBooking} / ${totalSlots}`}
            stretch
          />
          <Fields.Item
            name={_("reservations-percent")}
            icon={<TicketPercent aria-hidden />}
            value={<span style={{ color: bookingPercentColor }}>{`${bookingPercent}%`}</span>}
            stretch
          />
          <Fields.Item
            name={_("reservations-median")}
            icon={<TicketSlash aria-hidden />}
            value={
              <span style={{ color: bookingPercentMedianColor }}>{`${bookingPercentMedian}%`}</span>
            }
            stretch
          />
        </Fields>
      </Card.Content>
    </Card>
  )
}

/**
 * ItemRow
 */
const ItemRow: React.FC<{ workshop: Workshop }> = ({ workshop }) => {
  const { _ } = useDictionary(dictionary())
  const locale = useDateFnsLocale()
  const { isSelected } = useSelectItem(Context, workshop.id)
  const { totalSlots, totalBooking, bookingPercent, bookingPercentColor } = useBookingValues(
    useEventsByWorkshopId(workshop.id)
  )
  return (
    <Row selected={isSelected}>
      <Row.Image>
        <Presentation size={16} />
      </Row.Image>
      <Row.Header>
        <Row.Title>{workshop.name}</Row.Title>
        <Row.Description className="flex items-center gap-3 [&>span]:inline-flex [&>span]:items-center [&>span]:gap-1">
          <span>
            <Globe aria-label={_("languages")} />
            {pipe(workshop.languages, A.join(", "), S.toUpperCase)}
          </span>
          <span>
            <Clock aria-label={_("duration")} />
            {T.formatDuration(workshop.duration, { locale })}
          </span>
          <span>
            <UsersIcon aria-label={_("max-attendees")} />
            {_("max-attendee-people", { count: workshop.maxAttendees })}
          </span>
        </Row.Description>
      </Row.Header>
      <Row.Content className="flex flex-wrap gap-2">
        <Badge variant="secondary" aria-label={_("booking")}>
          <Ticket aria-hidden />
          {`${totalBooking} / ${totalSlots}`}
        </Badge>
        <Badge
          variant="secondary"
          style={{ backgroundColor: bookingPercentColor, color: "white" }}
          aria-label={_("booking-percent")}
        >
          <TicketPercent aria-hidden />
          {`${bookingPercent}%`}
        </Badge>
        <Row.Icon tooltip={_(`menu.state-${workshop.published ? "published" : "draft"}`)}>
          {workshop.published ? <Eye aria-hidden /> : <EyeOff aria-hidden />}
        </Row.Icon>
      </Row.Content>
      <Row.Menu menu={<ContextMenu {...{ workshop }} />} />
    </Row>
  )
}
