import { i18nConfig } from "@/config/i18n"
import { type Locale } from "date-fns"
import { matchSorter } from "match-sorter"

/**
 * useSearchable
 */
export const useSearchable = <T extends Record<string, unknown>>(
  searchFields: (item: T) => (string | Date)[],
  locale: Locale = i18nConfig.dateFnsLocales.fr
) => {
  const [search, setSearch] = React.useState("")

  const searchIn = React.useCallback(
    (items: T[]) => _searchIn(items, search, searchFields, locale),
    [search, searchFields, locale]
  )

  return [{ search, setSearch }, searchIn] as const
}

/**
 * useMatchable
 */
export const useMatchable = <T extends Record<string, unknown>>(
  keys: (string | ((item: T) => string))[]
) => {
  const [search, setSearch] = React.useState("")

  const searchIn = (items: T[]) => matchSorter(items, normString(search), { keys })

  return [{ search, setSearch }, searchIn] as const
}

/**
 * matcher
 */

/**
 * searchIn
 */

// Internal search function
const _searchIn = <T extends Record<string, unknown>>(
  items: T[],
  search: string,
  searchFields: (item: T) => (string | Date)[],
  locale: Locale = i18nConfig.dateFnsLocales.fr
) =>
  pipe(
    // Map each item to an object that includes the item itself and a score
    items,
    A.map(item => ({
      item,
      score: A.reduce(
        // Split the search term into individual terms
        S.split(search, " "),
        0,
        (score, term) =>
          A.reduce(
            // Iterate over the fields to search within
            searchFields(item),
            0,
            // Add 1 to the score for each match in the field
            (score, field) => Number(searchCompare(term, field, locale)) + score
          ) + score
      ),
    })),
    // Remove items with null score
    A.filter(({ score }) => N.gt(score, 0)),
    // Sort the results by score in descending order
    A.sort((a, b) => b.score - a.score),
    // Extract the items from the sorted results
    A.map(D.prop("item"))
  )

// Pipable function for search
export const searchIn =
  <T extends Record<string, unknown>>(
    search: string,
    searchFields: (item: T) => (string | Date)[],
    locale: Locale = i18nConfig.dateFnsLocales.fr
  ) =>
  (items: T[]) =>
    _searchIn(items, search, searchFields, locale)

/**
 * helpers
 */
export const normString = (term: string) =>
  term
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[.,/"#!$%^&*;:{}=\-_`~()]/g, "")
const normSearch = (search: string) => normString(search.replace(/\s+/g, " "))
const searchCompare = (
  search: string,
  searchIn: string | Date,
  locale: Locale = i18nConfig.dateFnsLocales.fr
) =>
  normSearch(
    G.isDate(searchIn) ? T.format(searchIn, "PPPP p HH'h'mm ddMMyy ddMMyyyy", { locale }) : searchIn
  ).includes(normSearch(search))
