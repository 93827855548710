import { match } from "ts-pattern"

/**
 * Wrapper
 */
type WrapperProps = {
  className?: string
  fixeHeight?: boolean
  children: React.ReactNode
  style?: React.CSSProperties
  margin?: "stretch" | "normal" | "none"
  noMarginBottom?: boolean
  noMarginTop?: boolean
  id?: string
}
export const Wrapper: React.FC<WrapperProps> = ({
  className,
  margin = "none",
  noMarginTop = false,
  noMarginBottom = false,
  fixeHeight,
  children,
  ...props
}) => {
  const mt = match(margin)
    .with("stretch", () => "mt-5 md:mt-[30px]")
    .with("normal", () => "mt-5 md:mt-[30px] lg:mt-[70px]")
    .otherwise(() => "mt-0")
  const mb = match(margin)
    .with("stretch", () => "mb-5 md:mb-[30px]")
    .with("normal", () => "mb-5 md:mb-[30px] lg:mb-[70px]")
    .otherwise(() => "mb-0")
  return (
    <div
      className={cx(
        "flex flex-col justify-center w-full",
        fixeHeight && "h-[832px]",
        noMarginTop || mt,
        noMarginBottom || mb,
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
