import { useTheme } from "../layout/dashboard/theme/useTheme"

/**
 * CardSpotlight
 */
type SpotlightProps = {
  opacity: number
  position: {
    x: number
    y: number
  }
}
const Spotlight: React.FC<SpotlightProps> = ({ opacity, position }) => {
  const { theme } = useTheme()
  const gradientColor = theme === "dark" ? "rgba(255,255,255,.06)" : "rgba(58,37,141,.08)"
  return (
    <div
      className="pointer-events-none absolute -inset-px opacity-0 transition-all duration-300"
      aria-hidden
      style={{
        opacity,
        background: `radial-gradient(circle at ${position.x}px ${position.y}px, ${gradientColor}, transparent 40%)`,
      }}
    />
  )
}

/**
 * exports
 */
export { Spotlight, type SpotlightProps }
