import { Form, FormInput, FormTextarea, useForm } from "@/components/form"
import { Button } from "@/components/frontend/button"
import { Prose } from "@/components/frontend/prose"
import { HN } from "@/components/ui/hn"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { useTranslation } from "@/store/languages/hooks"
import { container } from "../../frontend/container"
import { ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/fr/components/cms.json
 * dictionary src/dictionaries/de/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const { _ } = useDictionary(dictionary("form"))
  const t = useTranslation()
  const { titleLevel } = item.props
  const { title, secondary } = t(item).props

  const form = useForm({
    values: useMemoOnce(() => ({ name: "", email: "", subject: "", message: "" })),
    onSubmit: F.ignore,
  })

  return (
    <div className={cx("relative flex flex-col py-8 font-plus", container({ x: "sm" }))}>
      <div
        className={cx(
          "flex flex-col lg:flex-row gap-8 w-full pt-8 md:pb-8 lg:py-[70px]",
          "border rounded-[2px] border-mercury"
        )}
      >
        <div className="flex flex-col flex-1 ml-[30px] md:ml-[51px] px-[30px] md:px-[51px] py-4 md:py-6 gap-3 md:gap-6 border-l-4 border-tomato text-base">
          <Form form={form} className="grid gap-6">
            {oneIsNotEmpty(title) && (
              <HN level={titleLevel} className="text-2xl font-bold">
                {title}
              </HN>
            )}
            {oneIsNotEmpty(stripeHtml(secondary)) && (
              <Prose
                dangerouslySetInnerHTML={{
                  __html: secondary,
                }}
                className="text-sm text-gray -my-4"
              />
            )}
            <div className="grid lg:grid-cols-2 gap-6">
              <FormInput label={_("name-label")} placeholder={_("name-placeholder")} name="name" />
              <FormInput
                label={_("email-label")}
                placeholder={_("email-placeholder")}
                name="email"
              />
            </div>
            <FormInput
              label={_("subject-label")}
              placeholder={_("subject-placeholder")}
              name="email"
            />
            <FormTextarea
              label={_("message-label")}
              placeholder={_("message-placeholder")}
              name="message"
              rows={6}
            />
            <div className="flex justify-end">
              <Button type="submit" variant="dark">
                {_("submit")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
