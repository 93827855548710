import { Loader } from "@/components/collection"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { usePromise } from "@/hooks/usePromise"
import { SelectProvider, useSelect } from "@/hooks/useSelect"
import { getArticles } from "@/store/articles/actions"
import Collection from "./Collection"
import { Context, ContextProvider } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/articles.json
 */
const dictionary = createContextMapper("pages", "dashboard", "articles")

/**
 * Page: ArticlesIndex
 */
const PagesIndex: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const select = useSelect(Context)

  return (
    <PageWrapper ref={select.ref}>
      <SelectProvider {...select.props} />
      <PageHeader breadcrumbs={[[_("breadcrumbs"), "/dashboard/articles"]]} />
      <Collection />
    </PageWrapper>
  )
}
export default () => {
  const [, inProgress] = usePromise(getArticles)
  if (inProgress) return <Loader />
  return (
    <ContextProvider>
      <PagesIndex />
    </ContextProvider>
  )
}
