import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { useInterval } from "@/hooks/useInterval"
import { useResponsive } from "@/hooks/useResponsive"
import { service } from "@/services/trackings/service"
import millify from "millify"
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts"
import { StatRecord, StatsProps } from "../types"
import { useStatsTheme } from "../useStatsTheme"

const reloadTime = 30000
type Props = { strokeWidth?: number; color?: string; hideAxis?: boolean; hideGrid?: boolean }
/**
 * StatsVisitByDays
 */
export const StatsVisitByDays: React.FC<StatsProps & Props> = ({
  trackingId,
  interval,
  setIsLoading = () => true,
  live = false,
  hideAxis = false,
  hideGrid = false,
  color,
  strokeWidth,
}) => {
  const format = useDateFnsLocaleFormat()
  const { colors } = useStatsTheme()

  const [data, setData] = React.useState<StatRecord[]>([])
  const updateData = async () => {
    setIsLoading(true)
    const params = interval.from
      ? { from: T.formatISO(interval.from), to: T.formatISO(interval.to) }
      : { to: T.formatISO(interval.to) }
    const { error, data } = G.isNullable(trackingId)
      ? await service.byDay(params)
      : await service.trackings.byDay(trackingId, params)
    setIsLoading(false)
    if (error) return
    const start =
      interval.from ??
      pipe(
        D.keys(data.stats),
        A.map(T.parseISO),
        A.sortBy(d => d),
        A.head
      ) ??
      new Date()
    const days = T.eachDayOfInterval({ start, end: interval.to })
    const populated = A.map<Date, StatRecord>(days, date => {
      const key = T.formatISO(date, { representation: "date" })
      return { name: key, value: data.stats[key] ?? 0 }
    })
    setData(populated)
  }
  React.useEffect(() => {
    updateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval.from, interval.to])
  useInterval(updateData, live ? reloadTime : null)

  const { max } = useResponsive()

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        {!hideGrid && <CartesianGrid strokeDasharray="3 3" stroke="hsl(var(--muted))" />}
        {!hideAxis && (
          <XAxis
            dataKey="name"
            stroke="hsl(var(--muted-foreground))"
            tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
            height={max("sm") ? 15 : max("md") ? 25 : max("lg") ? 35 : 40}
            tickFormatter={(value: string) => format(T.parseISO(value), "P")}
          />
        )}
        {!hideAxis && (
          <YAxis
            dataKey="value"
            stroke="hsl(var(--muted-foreground))"
            width={max("sm") ? 15 : max("md") ? 25 : max("lg") ? 35 : 40}
            tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
            tickFormatter={(value: number) => millify(value)}
          />
        )}
        <Tooltip
          content={<StatsVisitByDaysTooltip color={color} />}
          cursor={{ stroke: "hsl(var(--muted))" }}
        />
        <Line
          dataKey="value"
          strokeWidth={strokeWidth ?? 1}
          stroke={color ?? colors[1]}
          dot={false}
          activeDot={{ r: 4, stroke: "transparent" }}
          strokeLinecap="round"
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

/**
 * StatsVisitByDaysTooltip
 */
const StatsVisitByDaysTooltip: React.FC<TooltipProps<number, string> & { color?: string }> = ({
  color,
  active,
  payload,
}) => {
  const format = useDateFnsLocaleFormat()
  const { colors } = useStatsTheme()
  if (!(active && payload && payload.length)) return null
  const {
    value,
    payload: { name },
  } = A.getUnsafe(payload, 0)
  return (
    <div className="rounded-md border bg-popover px-3 py-1.5 text-[0.75em] text-popover-foreground shadow-md">
      <b style={{ color: color ?? colors[1] }}>{format(T.parseISO(name), "PPP")}: </b>{" "}
      <span>{`${value}`}</span>
    </div>
  )
}
