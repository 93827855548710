import { ApiPage } from "@/services/pages/schemas"
import { localizeContent } from "../contents/localizers"
import { localizeSeo } from "../seos/localizers"

/**
 * localize
 */
export const localizePage = ({ seo, content, createdAt, updatedAt, ...parsed }: ApiPage) => ({
  ...D.selectKeys(parsed, ["id", "state", "trackingId", "lock"]),
  seo: localizeSeo(seo),
  content: localizeContent(content),
  createdAt: T.parseISO(createdAt),
  updatedAt: T.parseISO(updatedAt),
})
export type Page = ReturnType<typeof localizePage>
