import { FormGroup, FormLabel, useFieldContext } from "@/components/form"
import { Badge } from "@/components/ui/badge"
import * as SwitchPrimitives from "@radix-ui/react-switch"

/**
 * FormSwitch
 */
type Props = SelectInputProps & {
  label?: string
  name?: string
  info?: string | false
  className?: ClassName
  badge?: React.ReactNode
}
export const FormSwitch: React.FC<Props> = ({ label, name, badge, disabled, ...props }) => {
  return (
    <FormGroup name={name} className={"flex-row items-center"}>
      <FormLabel className="grow text-sm">{label}</FormLabel>
      {disabled && <Badge variant="outline">{badge}</Badge>}
      <SwitchInput {...props} disabled={disabled} />
    </FormGroup>
  )
}

type SelectInputProps = {
  placeholder?: string
  className?: ClassName
  disabled?: boolean
}
const SwitchInput: React.FC<SelectInputProps> = ({ disabled }) => {
  const { id, name, value, setFieldValue, disabled: ctxDisabled } = useFieldContext<boolean>()
  return (
    <SwitchPrimitives.Root
      id={id}
      name={name}
      checked={value}
      onCheckedChange={setFieldValue}
      disabled={disabled || ctxDisabled}
      className={cx(
        "peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-orient data-[state=unchecked]:bg-input",
        "h-5 w-9"
      )}
    >
      <SwitchPrimitives.Thumb
        className={cx(
          "pointer-events-none block rounded-full bg-background shadow-lg ring-0 transition-transform  data-[state=unchecked]:translate-x-0",
          "h-4 w-4 data-[state=checked]:translate-x-4"
        )}
      />
    </SwitchPrimitives.Root>
  )
}
