import { Empty, Grid, LimitMore, Selection, Toolbar } from "@/components/collection"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useFilteredPrograms } from "@/store/programs/hooks"
import { Archive, FilterX, PackageOpen } from "lucide-react"
import { usePageContext } from "../programs/Context"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/programs.json
 */
const dictionary = createContextMapper("pages", "dashboard", "programs")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { programs, filtered, matchable, sortable, filterable, limitable, limit } =
    useFilteredPrograms()
  const {
    view,
    setView,
    create,
    selected,
    setSelected,
    confirmDeleteSelection,
    confirmArchiveSelection,
    confirmUnarchiveSelection,
  } = usePageContext()

  return (
    <section className="flex flex-col gap-4">
      <Selection
        selected={selected}
        unselect={() => setSelected([])}
        deleteSelection={confirmDeleteSelection}
      >
        {filterable.isActive("archived") && (
          <Selection.Button onClick={confirmUnarchiveSelection}>
            <PackageOpen strokeWidth={1} aria-hidden size={16} />
            {_("archive")}
          </Selection.Button>
        )}
        {!filterable.isActive("archived") && (
          <Selection.Button onClick={confirmArchiveSelection}>
            <Archive aria-hidden size={16} />
            {_("archive")}
          </Selection.Button>
        )}
      </Selection>
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Toolbar.Button onClick={create}>{_("create")}</Toolbar.Button>
        <Filters {...filterable} />
        <Toolbar.Sort {...sortable} dictionary={Toolbar.Sort.dictionary(dictionary("sort"))} />
        <Toolbar.View {...{ view, setView }} />
      </Toolbar>
      <Empty
        items={programs}
        results={filtered}
        create={create}
        clear={() => {
          filterable.reset()
          matchable.setSearch("")
          setSelected([])
        }}
      />
      <Grid view={view}>
        {A.map(limit(filtered), item => (
          <Item key={item.id} program={item} />
        ))}
      </Grid>
      <LimitMore {...limitable} />
    </section>
  )
}

/**
 * Filters
 */
const Filters: React.FC<ReturnType<typeof useFilteredPrograms>["filterable"]> = ({
  setTrue,
  setFalse,
  isActive,
  reset,
}) => {
  const { _ } = useDictionary(dictionary("filters"))
  const { setSelected } = usePageContext()
  return (
    <Toolbar.Filters onClick={() => setSelected([])}>
      <Toolbar.Filters.Item onClick={() => setFalse("archived")} active={!isActive("archived")}>
        <PackageOpen aria-hidden />
        {_("unarchived")}
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item onClick={() => setTrue("archived")} active={isActive("archived")}>
        <Archive aria-hidden />
        {_("archived")}
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Separator />
      <Toolbar.Filters.Item onClick={reset}>
        <FilterX aria-hidden />
        {_("reset")}
      </Toolbar.Filters.Item>
    </Toolbar.Filters>
  )
}
