import { Loader } from "@/components/collection"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { usePromise } from "@/hooks/usePromise"
import { SelectProvider, useSelect } from "@/hooks/useSelect"
import { getProjects } from "@/store/projects/actions"
import { Collection } from "./Collection"
import { Context, ContextProvider } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/projects.json
 */
const dictionary = createContextMapper("pages", "dashboard", "projects")

/**
 * page: ProjectsIndex
 */
const ProjectsIndex: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const select = useSelect(Context)
  const [, inProgress] = usePromise(getProjects)
  return inProgress ? (
    <Loader />
  ) : (
    <PageWrapper ref={select.ref}>
      <SelectProvider {...select.props} />
      <PageHeader breadcrumbs={[[_("breadcrumbs"), "/dashboard/projects"]]} />
      <Collection />
    </PageWrapper>
  )
}
export default () => (
  <ContextProvider>
    <ProjectsIndex />
  </ContextProvider>
)
