import { Empty, Grid, LimitMore, Selection, Toolbar } from "@/components/collection"
import { useFilteredProjects } from "@/store/projects/hooks"
import { Project } from "@/store/projects/localizers"
import { CheckCircle, ClockCountdown, XCircle } from "@phosphor-icons/react"
import { CircleCheck, CircleX, FilterX } from "lucide-react"
import { usePageContext } from "./Context"
import Item from "./Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/projects.json
 */
const dictionary = createContextMapper("pages", "dashboard", "projects")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { projects, filtered, matchable, sortable, filterable, limitable, limit } =
    useFilteredProjects()
  const { view, setView, selected, setSelected, ...ctx } = usePageContext()
  return (
    <section className="flex flex-col gap-4">
      <Selection
        selected={selected}
        unselect={() => setSelected([])}
        deleteSelection={ctx.confirmDeleteSelection}
      >
        {!filterable.isActive("accepted") && (
          <Selection.Button onClick={ctx.confirmAcceptSelection}>
            <CheckCircle size={18} aria-hidden />
            {_("selection.accepted")}
          </Selection.Button>
        )}
        {!filterable.isActive("refused") && (
          <Selection.Button onClick={ctx.confirmRefuseSelection}>
            <XCircle size={18} aria-hidden />
            {_("selection.refused")}
          </Selection.Button>
        )}
      </Selection>
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Filters {...filterable} />
        <Toolbar.Sort {...sortable} dictionary={Toolbar.Sort.dictionary(dictionary("sort"))} />
        <Toolbar.View {...{ view, setView }} />
      </Toolbar>
      <Empty
        items={projects}
        results={filtered}
        clear={() => {
          filterable.clear()
          matchable.setSearch("")
          setSelected([])
        }}
      />
      <Grid view={view}>
        {A.map(limit(filtered), item => (
          <Item key={item.id} project={item} />
        ))}
      </Grid>
      <LimitMore {...limitable} />
    </section>
  )
}

/**
 * Filters
 */
const Filters: React.FC<ReturnType<typeof useFilteredProjects>["filterable"]> = ({
  setTrue,
  unset,
  isActive,
  reset,
}) => {
  const { _ } = useDictionary(dictionary("filters"))
  const { setSelected } = usePageContext()
  const toggleStatus = (status: Project["status"]) =>
    A.forEach(["accepted", "pending", "refused"], c =>
      c === status ? (isActive(c) ? unset(c) : setTrue(c)) : unset(c)
    )
  return (
    <Toolbar.Filters onClick={() => setSelected([])}>
      <Toolbar.Filters.Item onClick={() => toggleStatus("accepted")} active={isActive("accepted")}>
        <CircleCheck aria-hidden />
        {_("status-accepted")}
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item onClick={() => toggleStatus("pending")} active={isActive("pending")}>
        <ClockCountdown aria-hidden weight={isActive("pending") ? "bold" : "regular"} />
        {_("status-pending")}
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item onClick={() => toggleStatus("refused")} active={isActive("refused")}>
        <CircleX aria-hidden />
        {_("status-refused")}
      </Toolbar.Filters.Item>

      <Toolbar.Filters.Separator />
      <Toolbar.Filters.Item onClick={reset}>
        <FilterX aria-hidden />
        {_("reset")}
      </Toolbar.Filters.Item>
    </Toolbar.Filters>
  )
}
