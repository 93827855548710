import globalConfig from "@/config/global"
import { usePromise } from "@/hooks/usePromise"
import { useAuthStore } from "."
import { initAuthStore } from "./actions"

/**
 * useInitAuthStore
 */
export const useInitAuthStore = () => {
  const done = useAuthStore(D.getUnsafe("initDone"))
  const [response, loading] = usePromise(() => initAuthStore())
  return [loading, done, response] as const
}

/**
 * Hooks
 */
export const useMe = () => {
  const me = useAuthStore(D.prop("user"))
  if (G.isNullable(me)) throw new Error("never")
  return me
}
export const useMaybeMe = () => {
  const me = useAuthStore(D.prop("user"))
  if (G.isNullable(me)) return null
  return me
}
export const useIsAdmin = () => {
  const me = useAuthStore(D.prop("user"))
  return G.isNotNullable(me) ? me.role === "admin" || me.role === "superadmin" : false
}
export const useIsSuperadmin = () => {
  const me = useAuthStore(D.prop("user"))
  return G.isNotNullable(me) ? me.role === "superadmin" : false
}
export const useIsMember = () => {
  const isDevMode = globalConfig.environment === "development"
  const me = useAuthStore(D.prop("user"))
  return G.isNotNullable(me) ? isDevMode || me.role === "member" : false
}
export const useAuth = () => {
  const authState = useAuthStore(state => state)
  return { ...authState, authenticated: G.isObject(authState.user) }
}
