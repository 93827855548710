import { Check } from "@phosphor-icons/react"
import * as CheckboxPrim from "@radix-ui/react-checkbox"
import { VariantProps, cva } from "class-variance-authority"
import { focusOutlineClasses } from "./button"

/**
 * Checkbox context
 */

const CheckboxGroupIdContext = React.createContext<Option<string>>(null)

/**
 * CheckboxGroup
 */

type CheckboxGroupProps = { children: React.ReactNode; id: string }

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ children, id }) => {
  return <CheckboxGroupIdContext.Provider value={id}>{children}</CheckboxGroupIdContext.Provider>
}

/**
 * CheckboxTrigger
 */

type CheckboxTriggerProps = Extend<
  CheckboxPrim.CheckboxProps,
  VariantProps<typeof checkbox>,
  { value: boolean }
>

const CheckboxTrigger: React.FC<CheckboxTriggerProps> = ({ value, size, ...props }) => {
  const groupId = React.useContext(CheckboxGroupIdContext)

  return (
    <CheckboxPrim.Root
      {...props}
      className={cx(checkbox({ size }), props.className)}
      checked={value}
      id={groupId ? groupId : undefined}
    >
      <CheckboxPrim.Indicator>
        <Check weight="bold" />
      </CheckboxPrim.Indicator>
    </CheckboxPrim.Root>
  )
}

/**
 * CheckboxLabel
 */

type CheckboxLabelProps = Extend<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  { children: React.ReactNode }
>

const CheckboxLabel: React.FC<CheckboxLabelProps> = ({ children, ...props }) => {
  const groupId = React.useContext(CheckboxGroupIdContext)

  return (
    <label
      {...props}
      className={cx("cursor-pointer select-none", props.className)}
      htmlFor={groupId ? groupId : undefined}
    >
      {children}
    </label>
  )
}

/**
 * Checkbox
 */

export const Checkbox = Object.assign(CheckboxGroup, {
  Trigger: CheckboxTrigger,
  Label: CheckboxLabel,
})

/**
 * Styles
 */

export const checkbox = cva(
  [
    focusOutlineClasses,
    'flex justify-center items-center border-[2px] border-mercury [&_svg]:w-3 [&_svg]:h-3 data-[state="checked"]:border-bluewhale focus-visible:border-bluewhale text-bluewhale rounded-[2px]',
  ],
  {
    variants: {
      size: {
        sm: "w-5 aspect-square",
      },
    },
    defaultVariants: {
      size: "sm",
    },
  }
)
