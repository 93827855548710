import { apiContent } from "@/components/cms/items/schemas"
import { apiSeo } from "@/services/seos/schemas"
import { apiUser } from "@/services/users/schemas"
import { z } from "zod"

/**
 * ApiArticleCategoryTranslation
 */
const apiArticleCategoryTranslation = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string(),
  languageId: z.string(),
})
export type ApiArticleCategoryTranslation = z.infer<typeof apiArticleCategoryTranslation>

/**
 * ApiArticleCategory
 */
export const apiArticleCategory = z.object({
  id: z.string().uuid(),
  order: z.number(),
  translations: apiArticleCategoryTranslation.array(),
})
export type ApiArticleCategory = z.infer<typeof apiArticleCategory>

/**
 * ApiArticle
 */
export const articleTypes = ["news", "hero", "offers"] as const
export const apiArticleType = z.union([z.literal("news"), z.literal("hero"), z.literal("offers")])
export type ApiArticleType = z.infer<typeof apiArticleType>
export const apiArticleState = z.union([z.literal("draft"), z.literal("published")])
export type ApiArticleState = z.infer<typeof apiArticleState>
export const apiArticle = z.object({
  id: z.string().uuid(),
  type: apiArticleType,
  state: apiArticleState,
  trackingId: z.string().uuid(),
  seoId: z.string(),
  seo: apiSeo,
  contentId: z.string(),
  content: apiContent,
  categoryId: z.string().uuid().nullable(),
  category: apiArticleCategory.nullable(),
  authorId: z.string().uuid().nullable(),
  author: apiUser.nullable(),
  publishedAt: z.string(),
  publishedFrom: z.string().nullable(),
  publishedTo: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiArticle = z.infer<typeof apiArticle>
