import { FormFieldWrapper, FormFieldWrapperProps, useFieldContext } from "@/components/form"
import { ToggleGroup } from "@/components/ui/toggle-group"
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group"

/**
 * FormToggle
 */
type Props = ToggleInputProps & FormFieldWrapperProps
export const FormSelectTemplate: React.FC<Props> = ({
  label,
  labelAside,
  translatable,
  name,
  info,
  ...props
}) => (
  <FormFieldWrapper {...{ label, labelAside, translatable, name, info }}>
    <ToggleInput {...props} />
  </FormFieldWrapper>
)

type ToggleInputProps = Omit<
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root>,
  "type" | "onValueChange" | "value" | "defaultValue"
> & {
  options: React.FC[]
}
const ToggleInput: React.FC<ToggleInputProps> = ({ className, disabled, options, ...props }) => {
  const { value, setFieldValue, disabled: ctxDisabled } = useFieldContext<number>()
  return (
    <ToggleGroup
      type="single"
      className={cx("flex justify-start flex-wrap gap-2", className)}
      onValueChange={stringValue => setFieldValue(+stringValue)}
      value={`${value}`}
      disabled={ctxDisabled || disabled}
      {...props}
    >
      {A.mapWithIndex(options, (index, Option) => (
        <ToggleGroupPrimitive.Item
          value={`${index}`}
          key={index}
          className={cx(
            "w-28 aspect-square rounded-md bg-card border transition-all text-left",
            value === index ? "border-primary" : "border-input"
          )}
        >
          <Option />
        </ToggleGroupPrimitive.Item>
      ))}
    </ToggleGroup>
  )
}
