import { Menu, Row } from "@/components/collection"
import { Badge } from "@/components/ui/badge"
import { Link, linkCx } from "@/components/ui/link"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { useSelectItem } from "@/hooks/useSelect"
import { useUser } from "@/store/users/hooks"
import { useEvent, useWorkshop } from "@/store/workshops/hooks"
import { WorkshopReservation } from "@/store/workshops/localizers"
import { CalendarClock, SchoolIcon, TicketCheck, TicketX } from "lucide-react"
import { Context, ContextMenu } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/reservations.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "reservations")

type Props = { reservation: WorkshopReservation }

/**
 * Item
 */
export const Item: React.FC<Props> = props => {
  const { reservation } = props
  const { view, details } = React.useContext(Context)
  const { selectItemProps } = useSelectItem(Context, reservation.id, () => details(reservation))
  return (
    <Menu type="context-menu" menu={<ContextMenu {...props} />} asChild>
      <div className="grid" {...selectItemProps}>
        {view === "grid" ? <ItemCard {...props} /> : <ItemRow {...props} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCard
 */
const ItemCard: React.FC<Props> = () => {
  return <></>
}

/**
 * ItemRow
 */
const ItemRow: React.FC<Props> = props => {
  const { reservation } = props
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, reservation.id)
  const workshop = useWorkshop(reservation.workshopId)
  const event = useEvent(reservation.eventId)
  const user = useUser(reservation.userId)
  const format = useDateFnsLocaleFormat()

  const { contact } = reservation
  return (
    <Row selected={isSelected}>
      <Row.Image className={cx(reservation.canceled && "bg-destructive")}>
        {reservation.canceled ? <TicketX size={16} /> : <TicketCheck size={16} />}
      </Row.Image>
      <Row.Header>
        <Row.Title className="flex items-center gap-2">
          {workshop?.name}
          {G.isNotNullable(event) && (
            <span className="inline-flex items-center gap-1 text-xs text-muted-foreground">
              <CalendarClock aria-hidden size={12} /> {format(event.datetime, "PPp")}
            </span>
          )}
        </Row.Title>
        <Row.Description>
          {_("created")}{" "}
          {user && (
            <>
              {_("created-by")}{" "}
              <Link href={`/dashboard/users/${user.id}`} className={linkCx}>
                {`${user.profile.firstname} ${user.profile.lastname}`}
              </Link>
            </>
          )}{" "}
          {_("created-at")} {format(reservation.createdAt, "PPPPp")}
        </Row.Description>
      </Row.Header>
      <Row.Content className="flex flex-wrap gap-2">
        {G.isNotNullable(contact) && (
          <Badge variant="secondary">
            <SchoolIcon aria-hidden />
            {contact.schoolClass} {contact.schoolName}
          </Badge>
        )}
      </Row.Content>
      <Row.Menu menu={<ContextMenu {...props} />} />
    </Row>
  )
}
