import { OptionDateInterval } from "@/components/frontend/datepicker"
import { Evolver, useEvolveState } from "@/hooks/useEvolveState"

/**
 * KulturrallyeContext
 */

const Context = React.createContext<ContextType>({
  reservation: {},
  setReservation: F.ignore,
  filters: {
    interval: { start: null, end: null },
    setInterval: F.ignore,
  },
})
export const useKulturrallyeContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [reservation, setReservation] = React.useState<ContextReservation>({
    place: undefined,
    workshop: undefined,
    eventId: undefined,
    eventDate: undefined,
  })

  const [interval, setInterval] = useEvolveState<OptionDateInterval>(
    { start: null, end: null },
    "required-kulturrallye-interval",
    sessionStorage
  )
  return (
    <Context.Provider
      value={{
        reservation,
        setReservation,
        filters: {
          interval,
          setInterval,
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}

/**
 * types
 */
type ContextReservation = {
  place?: string
  workshop?: string
  eventId?: string
  eventDate?: Date
}
type ContextType = {
  reservation: ContextReservation
  setReservation: React.Dispatch<React.SetStateAction<ContextReservation>>
  filters: {
    interval: OptionDateInterval
    setInterval: Evolver<OptionDateInterval>
  }
}
