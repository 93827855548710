import { useForm, validator } from "@/components/form"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { Project } from "@/store/frontend/localizers"
import { dictionary } from "."

/**
 * useDefinitionForm
 */
export const useDefinitionForm = (project: Option<Project> = null) => {
  const { min } = validator
  const { _ } = useDictionary(dictionary("form"))

  return useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() =>
      project
        ? {
            ...D.selectKeys(project, basicDefinitionValues),
          }
        : {
            skills: [],
            objective: "",
            materials: [],
            preparation: "",
            follow: "",
            values: "",
          }
    ),
    validate: validator({
      skills: [min(4, _("skills-min"))],
    }),
    onSubmit: F.ignore,
  })
}
export const basicDefinitionValues = [
  "skills",
  "objective",
  "materials",
  "preparation",
  "follow",
  "values",
] as const
