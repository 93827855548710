// constants
export const calendarMonthDays = 6 * 7
export const nowDate = new Date()

/**
 * Formats
 */

export const dateKeyFormat = "yyyy-MM-dd"
export const formatDateKey = (date: Date | number) => T.format(date, dateKeyFormat)
export const parseDateKey = (key: string) => T.parse(key, dateKeyFormat, new Date())

/**
 * dateToCalendarMonth:
 * get surrounding calendar month days for any date
 */

export const dateToCalendarMonth = (date: Date, weekStartsOn: 0 | 1 = 1) => {
  const monthStart = T.startOfMonth(date)
  const weekStart = T.startOfWeek(monthStart, { weekStartsOn })

  const calendarMonthEnd = T.add(weekStart, { days: calendarMonthDays - 1 })

  return T.eachDayOfInterval({
    start: weekStart,
    end: calendarMonthEnd,
  })
}

/**
 * dateToWeek:
 * get surrounding week days for any date
 */

export const dateToWeek = (date: Date, weekStartsOn: 0 | 1 = 1) =>
  T.eachDayOfInterval({
    start: T.startOfWeek(date, { weekStartsOn }),
    end: T.endOfWeek(date, { weekStartsOn }),
  })

/**
 * dateToYearMonths:
 */

export const dateToYearMonths = (date: Date) =>
  T.eachMonthOfInterval({
    start: T.startOfYear(date),
    end: T.endOfYear(date),
  })

/**
 * useCalendarDays
 */

export const useCalendarDays = (
  initialSelectedDate: Date = nowDate,
  onChange?: (date: Date) => void
) => {
  const mountNow = React.useRef<Date>(new Date()) // store default now as component mount time

  const [now, refreshNow] = React.useReducer(() => new Date(), mountNow.current)

  const [selectedDate, setSelectedDate] = React.useState<Date>(initialSelectedDate) // user selected date
  const [openedDate, setOpenedDate] = React.useState<Date>(selectedDate) // user selected date

  // get surrounding calendar month days for openedDate
  const openDays = React.useMemo(
    () => (T.isValid(openedDate) ? dateToCalendarMonth(openedDate) : []),
    [openedDate]
  )

  const openedWeekDays = React.useMemo(
    () => (T.isValid(openedDate) ? dateToWeek(openedDate) : []),
    [openedDate]
  )

  const openedYearMonths = React.useMemo(
    () => (T.isValid(openedDate) ? dateToYearMonths(openedDate) : []),
    [openedDate]
  )

  // update open date with selected date
  const setDates = React.useCallback(
    (nextDate: Date) => {
      onChange && onChange(nextDate)
      setSelectedDate(nextDate)
      setOpenedDate(nextDate)
    },
    [onChange]
  )

  // memo open helpers callbacks
  const openHelpers = React.useMemo(() => {
    const open = (opts: Duration) => setOpenedDate(T.add(openedDate, opts))
    return {
      openPrevMonth: () => open({ months: -1 }),
      openNextMonth: () => open({ months: 1 }),
      openPrevWeek: () => open({ weeks: -1 }),
      openNextWeek: () => open({ weeks: 1 }),
      open,
    }
  }, [openedDate])

  // memo select helpers callbacks
  const selectHelpers = React.useMemo(
    () => ({
      select: (duration = {}) => setDates(T.add(selectedDate, duration)),
    }),
    [selectedDate, setDates]
  )

  // open interval and keys
  const interval = React.useMemo(() => {
    const start = A.head(openDays) ?? new Date()
    const end = A.last(openDays) ?? new Date()

    const interval = { start, end }
    const startKey = formatDateKey(interval.start)
    const endKey = formatDateKey(interval.end)

    return { ...interval, startKey, endKey, key: `${startKey}.${endKey}` }
  }, [openDays])

  return {
    // state
    now,
    interval,
    openDays,
    openedWeekDays,
    openedYearMonths,
    selectedDate,
    selectedDateKey: formatDateKey(selectedDate),
    openedDate,
    // actions
    refreshNow,
    setOpenedDate,
    setSelectedDate: setDates,
    selectNow: () => setDates(now),
    ...openHelpers,
    ...selectHelpers,
  }
}
