import {
  Form,
  FormAssertive,
  FormHeader,
  FormImage,
  FormInput,
  FormSection,
  FormSubmit,
  useForm,
} from "@/components/form"
import { Dialog } from "@/components/ui/dialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { useMe } from "@/store/auth/hooks"
import { Me } from "@/store/auth/localizers"
import { updateProfile } from "@/store/users/actions"
import { match } from "ts-pattern"
import { Button } from "../ui/button"
import { UseDialogFormProps, UseDialogProps } from "../ui/hooks/useDialog"

/**
 * dictionary src/dictionaries/en/components/dialogs/profile.json
 */
const dictionary = createContextMapper("components", "dialogs", "profile")

/**
 * ProfileDialog
 */
export const ProfileDialog: React.FC<UseDialogProps<void>> = props => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-2xl">
      <ProfileForm {...props} />
    </Dialog>
  )
}

/**
 * ProfileForm
 */
const ProfileForm: React.FC<UseDialogFormProps<void>> = ({ onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const me = useMe() as Me

  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(me.profile, baseFields),
      image: { url: me.profile.image, file: null, delete: false },
    })),
    onSubmit: async ({ values }) => {
      const payload = {
        ...D.selectKeys(values, baseFields),
        image: D.selectKeys(values.image, ["file", "delete"]),
      }
      return match(await updateProfile(me.id, payload))
        .with({ error: false }, () => {
          toast.success(_("success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormHeader>
        <FormHeader.Title>{_("section-personal-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-personal-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection className="@xl/dialog:grid grid-cols-3">
        <FormSection className="col-span-2">
          <FormInput
            label={_form("firstname-label")}
            name="firstname"
            placeholder={_form("firstname-placeholder")}
            auto="given-name"
          />
          <FormInput
            label={_form("lastname-label")}
            name="lastname"
            placeholder={_form("lastname-placeholder")}
            auto="family-name"
          />
          <FormInput
            label={_form("email-label")}
            name="email"
            placeholder={_form("email-placeholder")}
            auto="email"
          />
        </FormSection>
        <div>
          <FormImage label={_("image-label")} name="image" ratio="aspect-square" />
        </div>
      </FormSection>

      <FormHeader>
        <FormHeader.Title>{_("section-employment-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-employment-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection className="@xl/dialog:grid grid-cols-2">
        <FormInput
          label={_form("company-label")}
          name="company"
          placeholder={_form("company-placeholder")}
          auto="organization"
        />
        <FormInput
          label={_form("position-label")}
          name="position"
          placeholder={_form("position-placeholder")}
          auto="organization-title"
        />
      </FormSection>

      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
/**
 * constants
 */
const baseFields = ["firstname", "lastname", "position", "company", "email"] as const
