import { Form, FormAssertive, FormInput, FormTextarea, useForm, validator } from "@/components/form"
import { Button } from "@/components/frontend/button"
import { Prose } from "@/components/frontend/prose"
import { HN } from "@/components/ui/hn"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { service } from "@/services/frontend/service"
import { useTranslation } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { ItemMappingExport } from "."
import { Container } from "../container"
import { Wrapper } from "../wrapper"

/**
 * dictionary src/dictionaries/fr/components/cms.json
 * dictionary src/dictionaries/de/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", "contact-form")
type RenderFC = ItemMappingExport<"contact-form">["Render"]

export const Render: RenderFC = ({ item }) => {
  const { _, language } = useDictionary(dictionary("form"))

  const t = useTranslation()
  const { titleLevel } = item.props
  const { title, secondary } = t(item).props
  const [isSent, setIsSent] = React.useState(false)
  const { isEmail, min } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({ name: "", email: "", subject: "", message: "" })),
    validate: validator({
      name: [min(1, _("name-invalid"))],
      email: [isEmail(_("email-invalid"))],
      subject: [min(1, _("subject-invalid"))],
      message: [min(1, _("message-invalid"))],
    }),
    onSubmit: async ({ values }) =>
      match(await service.sendMail(item.id, { ...values, language }))
        .with({ error: true }, () => _("error"))
        .otherwise(() => setIsSent(true)),
  })
  return (
    <Wrapper margin="normal">
      <Container x="sm">
        <div
          className={cx(
            "flex flex-col lg:flex-row gap-8 w-full py-[30px] md:py-[51px]",
            "border rounded-[2px] border-mercury"
          )}
        >
          <div
            className={cx(
              "flex flex-col flex-1 ml-[30px] md:ml-[51px] px-[30px] md:px-[51px] py-4 md:py-6 gap-3 md:gap-6 border-l-4 text-base transition-colors",
              isSent ? "border-orient" : "border-tomato"
            )}
          >
            {oneIsNotEmpty(title) && (
              <HN level={titleLevel} className="text-2xl font-bold">
                {title}
              </HN>
            )}
            {isSent ? (
              <div
                className="text-sm text-gray"
                dangerouslySetInnerHTML={{
                  __html: _("success").replace(
                    "{{email}}",
                    `<b class="text-orient">${form.values.email}</b>`
                  ),
                }}
              />
            ) : (
              <Form form={form} className="flex flex-col gap-3 md:gap-6">
                <FormAssertive />
                {oneIsNotEmpty(stripeHtml(secondary)) && (
                  <Prose
                    dangerouslySetInnerHTML={{
                      __html: secondary,
                    }}
                    className="text-sm text-gray -my-4 max-w-xl"
                  />
                )}
                <div className="grid lg:grid-cols-2 gap-3 md:gap-6">
                  <FormInput
                    label={_("name-label")}
                    placeholder={_("name-placeholder")}
                    name="name"
                    maxLength={255}
                  />
                  <FormInput
                    label={_("email-label")}
                    placeholder={_("email-placeholder")}
                    name="email"
                    maxLength={255}
                  />
                </div>
                <FormInput
                  label={_("subject-label")}
                  placeholder={_("subject-placeholder")}
                  name="subject"
                  maxLength={255}
                />
                <FormTextarea
                  label={_("message-label")}
                  placeholder={_("message-placeholder")}
                  name="message"
                  rows={6}
                />
                <div className="flex justify-end">
                  <Button type="submit" variant="dark">
                    {_("submit")}
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}
