import { z } from "zod"
import { apiExtraField, apiMultipleFile } from "../commons/schemas"
import { apiUser } from "../users/schemas"

/**
 * ApiProjectRole
 */
export const apiProjectStatus = z.union([
  z.literal("accepted"),
  z.literal("refused"),
  z.literal("pending"),
])
export type ApiProjectStatus = z.infer<typeof apiProjectStatus>
/**
 * ApiProjectLanguage
 */
export const projectLanguages = ["fr", "de", "en"] as const
export const apiProjectLanguage = z.union([z.literal("fr"), z.literal("de"), z.literal("en")])
export type ApiProjectLanguage = z.infer<typeof apiProjectLanguage>

/**
 * ApiProjectOrientation
 */
export const projectOrientations = ["individual", "projects"] as const
export const apiProjectOrientation = z.union([z.literal("individual"), z.literal("projects")])
export type ApiProjectOrientation = z.infer<typeof apiProjectOrientation>

/**
 * ApiProjectCategory
 */
export const projectCategories = ["dance", "music", "theater"] as const
export const apiProjectCategory = z.union([
  z.literal("dance"),
  z.literal("music"),
  z.literal("theater"),
])
export type ApiProjectCategory = z.infer<typeof apiProjectCategory>

/**
 * ApiProject
 */
export const apiProject = z.object({
  id: z.string().uuid(),
  name: z.string(),
  language: z.string(),
  category: apiProjectCategory,
  orientation: apiProjectOrientation,
  image: apiMultipleFile,
  partners: apiExtraField.array(),
  classes: apiExtraField.array(),
  description: z.string(),
  skills: apiExtraField.array(),
  objective: z.string(),
  materials: z.string().array(),
  preparation: z.string(),
  follow: z.string(),
  values: z.string(),
  documents: apiMultipleFile.array(),
  images: apiMultipleFile.array(),
  status: apiProjectStatus,
  validated: z.boolean(),
  validatedBy: apiUser.nullable(),
  validatedAt: z.string().nullable(),
  createdBy: apiUser.nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  trackingId: z.string().uuid(),
})
export type ApiProject = z.infer<typeof apiProject>
