import { Empty, Grid, LimitMore, Selection, Toolbar } from "@/components/collection"
import { PageContent } from "@/components/layout/dashboard"
import { Badge } from "@/components/ui/badge"
import { ApiUserStatus } from "@/services/users/schemas"
import { useIsSuperadmin } from "@/store/auth/hooks"
import { useFilteredUsers } from "@/store/users/hooks"
import { Gear, GraduationCap } from "@phosphor-icons/react"
import { AlertTriangle, FilterX, History, UserCheck, UserX } from "lucide-react"
import { usePageContext } from "./Context"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/users.json
 */
const dictionary = createContextMapper("pages", "dashboard", "users")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { filtered, users, sortable, matchable, filterable, limitable, limit } = useFilteredUsers()
  const { view, setView, selected, setSelected, confirmDeleteSelection, ...ctx } = usePageContext()
  const isSuperadmin = useIsSuperadmin()
  return (
    <PageContent>
      <Selection
        selected={selected}
        unselect={() => setSelected([])}
        deleteSelection={confirmDeleteSelection}
      />
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Toolbar.Button
          variant={"outline"}
          onClick={ctx.confirmDeleteUnuseAccounts}
          disabled={A.isEmpty(ctx.unuseAccounts)}
        >
          {_("delete-unuse-account")}{" "}
          {A.isNotEmpty(ctx.unuseAccounts) && (
            <Badge variant="destructive" stretch>
              {ctx.unuseAccounts.length}
            </Badge>
          )}
        </Toolbar.Button>
        {isSuperadmin && <Toolbar.Button onClick={ctx.create}>{_("create")}</Toolbar.Button>}
        <Filters {...filterable} />
        <Toolbar.Sort {...sortable} dictionary={Toolbar.Sort.dictionary(dictionary("sort"))} />
        <Toolbar.View {...{ view, setView }} />
      </Toolbar>
      <Empty
        results={filtered}
        items={users}
        create={isSuperadmin ? ctx.create : undefined}
        clear={() => {
          filterable.clear()
          matchable.setSearch("")
          setSelected([])
        }}
      />
      <Grid {...{ view }}>
        {A.map(limit(filtered), item => (
          <Item key={item.id} user={item} />
        ))}
      </Grid>
      <LimitMore {...limitable} />
    </PageContent>
  )
}

/**
 * Filters
 */
type Props = ReturnType<typeof useFilteredUsers>["filterable"]
const Filters: React.FC<Props> = ({ isActive, isInactive, setTrue, setFalse, clearOne, clear }) => {
  const { _ } = useDictionary(dictionary("filters"))
  const { setSelected } = usePageContext()
  const toggleStatus = (status: ApiUserStatus) =>
    A.forEach(["active", "pending", "suspended", "deleted"], c =>
      c === status ? (isActive(c) ? clearOne(c) : setTrue(c)) : clearOne(c)
    )
  return (
    <Toolbar.Filters onClick={() => setSelected([])}>
      <Toolbar.Filters.Button onClick={() => toggleStatus("active")} active={isActive("active")}>
        <UserCheck aria-hidden strokeWidth={isActive("active") ? 2 : 1.4} />
        {_("status-active")}
      </Toolbar.Filters.Button>
      <Toolbar.Filters.Button onClick={() => toggleStatus("pending")} active={isActive("pending")}>
        <History aria-hidden strokeWidth={isActive("pending") ? 2 : 1.4} />
        {_("status-pending")}
      </Toolbar.Filters.Button>
      <Toolbar.Filters.Button
        onClick={() => toggleStatus("suspended")}
        active={isActive("suspended")}
      >
        <AlertTriangle aria-hidden strokeWidth={isActive("suspended") ? 2 : 1.4} />
        {_("status-suspended")}
      </Toolbar.Filters.Button>
      <Toolbar.Filters.Button onClick={() => toggleStatus("deleted")} active={isActive("deleted")}>
        <UserX aria-hidden strokeWidth={isActive("deleted") ? 2 : 1.4} />
        {_("status-deleted")}
      </Toolbar.Filters.Button>

      <Toolbar.Filters.Separator />

      <Toolbar.Filters.Button
        onClick={() => (isActive("admin") ? clearOne("admin") : setTrue("admin"))}
        active={isActive("admin")}
      >
        <Gear aria-hidden strokeWidth={isActive("admin") ? 2 : 1.4} />
        {_("role-admin")}
      </Toolbar.Filters.Button>
      <Toolbar.Filters.Button
        onClick={() => (isInactive("admin") ? clearOne("admin") : setFalse("admin"))}
        active={isInactive("admin")}
      >
        <GraduationCap aria-hidden strokeWidth={isInactive("admin") ? 2 : 1.4} />
        {_("role-member")}
      </Toolbar.Filters.Button>

      <Toolbar.Filters.Separator />

      <Toolbar.Filters.Button onClick={clear}>
        <FilterX aria-hidden strokeWidth={1.4} />
        {_("clear")}
      </Toolbar.Filters.Button>
    </Toolbar.Filters>
  )
}
