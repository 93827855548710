import { decorateStore } from "@/fns/decorateStore"
import { create } from "zustand"
import { Place } from "./localizers"

/**
 * types
 */
export type PlacesStoreState = {
  places: ById<Place>
}

/**
 * initialState
 */
const initialState: PlacesStoreState = {
  places: {},
}

/**
 * Store
 */
export const placesStore = decorateStore(initialState, create, {})
export const usePlacesStore = placesStore.use
