import { Form, useFormContext } from "."
import { Button, ButtonProps } from "@/components/ui/button"
import { Loader2 } from "lucide-react"

/**
 * FormSubmit
 */
type Props = ButtonProps
export const FormSubmit: React.FC<Props> = ({ children, ...props }) => {
  const form = useFormContext()

  return (
    <Form.Submit asChild>
      <Button type="submit" {...props} className="gap-2">
        {form.isSubmitting && <Loader2 className="h-4 w-4 animate-spin" />}
        {children}
      </Button>
    </Form.Submit>
  )
}
