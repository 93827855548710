import { ApiContent, ApiContentItemAny, ContentItemMapping } from "@/components/cms/items/schemas"
import { byId } from "@/fns/byId"

/**
 * localizeContent
 */
export const localizeContent = (parsed: ApiContent) => {
  return {
    id: parsed.id,
    items: byId(parsed.items, localizeContentItem),
  }
}
export type Content = ReturnType<typeof localizeContent>

/**
 * localizeContentItem
 */
export const localizeContentItem = <T extends keyof ContentItemMapping>(
  parsed: ApiContentItemAny
) => {
  const itemBase: ContentItemBase = {
    id: parsed.id,
    state: parsed.state,
    order: parsed.order,
    createdAt: T.parseISO(parsed.createdAt),
    updatedAt: T.parseISO(parsed.updatedAt),
  }
  const itemSpecific = {
    type: parsed.type,
    props: parsed.props,
    translations: parsed.translations.reduce(
      (translations, translation) => ({ ...translations, [translation.languageId]: translation }),
      {}
    ),
  } as ContentItemMapping[T]
  return D.merge(itemBase, itemSpecific)
}

export type ContentItemBase = {
  id: string
  state: "draft" | "published"
  order: number
  createdAt: Date
  updatedAt: Date
}
export type ContentItem = ReturnType<typeof localizeContentItem>
