import { isFile, isSynteticFile } from "@/components/form"
import { AuthErrorCode, ErrorCode, NoContent, api } from "@/services/api"
import { ApiPlace, apiPlace } from "@/services/places/schemas"
import { z } from "zod"
import { apiExtraField, synteticFile } from "../commons/schemas"
import { apiProjectCategory, apiProjectOrientation } from "../projects/schemas"
import {
  ApiArticle,
  ApiArticleCategory,
  ApiPage,
  ApiProject,
  ApiWorkshop,
  ApiWorkshopReservation,
  apiArticle,
  apiArticleCategory,
  apiPage,
  apiProject,
  apiWorkshop,
  apiWorkshopReservation,
} from "./schemas"

/**
 * payload
 */
const createNewsletterPayload = z.object({
  email: z.string(),
})
const sendMailPayload = z.object({
  name: z.string(),
  email: z.string(),
  subject: z.string(),
  message: z.string(),
  language: z.string(),
})
const reservationPayload = z.object({
  students: z.number(),
  language: z.string(),
  message: z.string(),
  contact: z.object({
    schoolName: z.string(),
    schoolClass: z.string(),
    schoolStreet: z.string(),
    schoolZip: z.string(),
    schoolCity: z.string(),
    teacherName: z.string(),
    teacherEmail: z.string(),
    teacherPhone: z.string(),
  }),
})
const createProjectPayload = z.object({
  name: z.string(), // max 100
  language: z.string(), // max 20
  category: apiProjectCategory,
  orientation: apiProjectOrientation,
  partners: apiExtraField.array(),
  classes: apiExtraField.array(),
  description: z.string(), // maxlength 255
  skills: apiExtraField.array(),
  objective: z.string(), // maxlength 4294967295
  materials: z.string().array(),
  preparation: z.string(), // maxlength 4294967295
  follow: z.string(), // maxlength 4294967295
  values: z.string(), // maxlength 4294967295
  image: z.object({ file: z.any() }),
  documents: z.any().array(),
  images: z.any().array(),
})
const projectsIndexParams = z.object({
  category: apiProjectCategory.optional(),
  orientation: apiProjectOrientation.optional(),
  limit: z.number().optional(),
})
export type ProjectsIndexParams = z.infer<typeof projectsIndexParams>

const updateProjectPayload = z.object({
  name: z.string().optional(), // max 100
  language: z.string().optional(), // max 20
  category: apiProjectCategory.optional(),
  orientation: apiProjectOrientation.optional(),
  partners: apiExtraField.array().optional(),
  classes: apiExtraField.array().optional(),
  description: z.string().optional(), // maxlength 255
  skills: apiExtraField.array().optional(),
  objective: z.string().optional(), // maxlength 4294967295
  materials: z.string().array().optional(),
  preparation: z.string().optional(), // maxlength 4294967295
  follow: z.string().optional(), // maxlength 4294967295
  values: z.string().optional(), // maxlength 4294967295
  image: z
    .object({
      file: z.any(),
      delete: z.boolean(),
    })
    .optional(),
  documents: z.union([z.any(), synteticFile]).array().optional(),
  images: z.union([z.any(), synteticFile]).array().optional(),
})

const articlesIndexParams = z.object({
  omit: z.string().optional(),
  category: apiProjectCategory.optional(),
  orientation: apiProjectOrientation.optional(),
  limit: z.number().optional(),
})
export type ArticlesIndexParams = z.infer<typeof articlesIndexParams>

export type Payload = {
  sendMail: z.infer<typeof sendMailPayload>
  newsletters: {
    create: z.infer<typeof createNewsletterPayload>
  }
  workshops: {
    reservation: z.infer<typeof reservationPayload>
  }
  projects: {
    create: z.infer<typeof createProjectPayload>
    update: z.infer<typeof updateProjectPayload>
  }
}

/**
 * service
 */
export const service = {
  newsletters: {
    register: async (payload: Payload["newsletters"]["create"]) => {
      type RSuccess = NoContent
      type RError = AuthErrorCode<
        | "VALIDATION_FAILURE"
        | "LIST_NOT_FOUND"
        | "EMAIL_ALREADY_EXISTS"
        | "UNKNOWN_ERROR"
        | "INVALID_CREDENTIALS"
      >
      const { success, data } = await api.post<RSuccess, RError>(`newsletters`, {
        data: createNewsletterPayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: null, error: false } as const
    },
  },
  sendMail: async (itemId: string, payload: Payload["sendMail"]) => {
    type RSuccess = NoContent
    type RError = ErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.post<RSuccess, RError>(
      `frontend/content/items/${itemId}/sendMail`,
      {
        data: sendMailPayload.parse(payload),
      }
    )
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: null, error: false } as const
  },
  pages: {
    read: async (pageId: string) => {
      type RSuccess = { page: ApiPage }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`frontend/pages/${pageId}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { page: apiPage.parse(data.page) }, error: false } as const
    },
  },
  articles: {
    index: async (types: ApiArticle["type"][], params?: ArticlesIndexParams) => {
      type RSuccess = { articles: ApiArticle[] }
      type RError = AuthErrorCode
      const { success, data } = await api.get<RSuccess, RError>(
        `frontend/articles/${A.join(types, "-")}`,
        { params }
      )
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { articles: A.map(data.articles, apiArticle.parse) }, error: false } as const
    },
    read: async (articleId: string) => {
      type RSuccess = { article: ApiArticle }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`frontend/articles/${articleId}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { article: apiArticle.parse(data.article) }, error: false } as const
    },
    categories: {
      read: async (categoryId: string, params?: ArticlesIndexParams) => {
        type RSuccess = { category: ApiArticleCategory; articles: ApiArticle[] }
        type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
        const { success, data } = await api.get<RSuccess, RError>(
          `frontend/article-categories/${categoryId}`,
          { params }
        )
        if (!success) return { data: null, error: true, code: data.code } as const
        return {
          data: {
            category: apiArticleCategory.parse(data.category),
            articles: A.map(data.articles, apiArticle.parse),
          },
          error: false,
        } as const
      },
    },
  },
  projects: {
    index: async (params: ProjectsIndexParams = {}) => {
      type RSuccess = { projects: ApiProject[] }
      type RError = AuthErrorCode
      const { success, data } = await api.get<RSuccess, RError>("frontend/projects", { params })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { projects: A.map(data.projects, apiProject.parse) }, error: false } as const
    },
    create: async (payload: Payload["projects"]["create"]) => {
      type RSuccess = { project: ApiProject }
      type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_ALLOWED">
      const { image, documents, images, ...rest } = createProjectPayload.parse(payload)
      const response = await api.post<RSuccess, RError>("projects", {
        data: rest,
      })
      if (!response.success) return { data: null, error: true, code: response.data.code } as const
      // uppload attachments
      const { success, data } = await api.put<RSuccess, RError>(
        `projects/${response.data.project.id}`,
        {
          form: {
            documents: A.isNotEmpty(documents) ? documents : undefined,
            images: A.isNotEmpty(images) ? images : undefined,
            image: image.file,
          },
        }
      )
      if (!success)
        return {
          data: { project: apiProject.parse(response.data.project) },
          error: true,
          code: "RELATED_FILES_UPLOAD_FAILURE",
        } as const
      return { data: { project: apiProject.parse(data.project) }, error: false } as const
    },
    read: async (id: string) => {
      type RSuccess = { project: ApiProject }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`frontend/projects/${id}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { project: apiProject.parse(data.project) }, error: false } as const
    },
    update: async (id: string, payload: Payload["projects"]["update"]) => {
      type RSuccess = { project: ApiProject }
      type RError = AuthErrorCode<
        | "VALIDATION_FAILURE"
        | "RESOURCE_NOT_ALLOWED"
        | "RELATED_FILES_UPLOAD_FAILURE"
        | "RESOURCE_NOT_FOUND"
      >
      const { documents, images, image, ...rest } = updateProjectPayload.parse(payload)

      const documentsSync = documents
        ? A.filterMap(documents, file => (isSynteticFile(file) && !file.delete ? file.id : O.None))
        : undefined
      const imagesSync = images
        ? A.filterMap(images, file => (isSynteticFile(file) && !file.delete ? file.id : O.None))
        : undefined

      const { success, data } = await api.put<RSuccess, RError>(`projects/${id}`, {
        data: {
          ...rest,
          image: image?.delete ? null : undefined,
          documentsSync,
          imagesSync,
        },
      })
      if (!success) return { data: null, error: true, code: data.code } as const

      const imageFiles = A.filterMap(images ?? [], file => (isFile(file) ? file : O.None))
      const documentFiles = A.filterMap(documents ?? [], file => (isFile(file) ? file : O.None))
      const form = {
        images: A.isNotEmpty(imageFiles) ? imageFiles : undefined,
        documents: A.isNotEmpty(documentFiles) ? documentFiles : undefined,
        image: image?.file,
      }
      if (A.some(D.values(form), G.isNotNullable)) {
        const { success, data } = await api.put<RSuccess, RError>(`projects/${id}`, { form })
        if (!success)
          return { data: null, error: true, code: "RELATED_FILES_UPLOAD_FAILURE" } as const
        return { data: { project: apiProject.parse(data.project) }, error: false } as const
      }

      return { data: { project: apiProject.parse(data.project) }, error: false } as const
    },
  },
  places: {
    index: async () => {
      type RSuccess = { places: ApiPlace[] }
      type RError = AuthErrorCode
      const { success, data } = await api.get<RSuccess, RError>("frontend/places")
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { places: A.map(data.places, apiPlace.parse) }, error: false } as const
    },
    read: async (id: string) => {
      type RSuccess = { place: ApiPlace }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND" | "UNAUTHORIZED_ACCESS">
      const { success, data } = await api.get<RSuccess, RError>(`frontend/places/${id}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { place: apiPlace.parse(data.place) }, error: false } as const
    },
    workshops: async (placeId: string) => {
      type RSuccess = { workshops: ApiWorkshop[] }
      type RError = AuthErrorCode
      const { success, data } = await api.get<RSuccess, RError>(
        `frontend/places/${placeId}/workshops`
      )
      if (!success) return { data: null, error: true, code: data.code } as const
      return {
        data: { workshops: A.map(data.workshops, apiWorkshop.parse) },
        error: false,
      } as const
    },
  },
  workshops: {
    index: async (
      payload: {
        from?: string
        to?: string
        date?: string
      } = {}
    ) => {
      type RSuccess = { workshops: ApiWorkshop[] }
      type RError = AuthErrorCode
      const { success, data } = await api.get<RSuccess, RError>("frontend/workshops", {
        params: payload,
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return {
        data: { workshops: A.map(data.workshops, apiWorkshop.parse) },
        error: false,
      } as const
    },
    read: async (id: string) => {
      type RSuccess = { workshop: ApiWorkshop }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`frontend/workshops/${id}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { workshop: apiWorkshop.parse(data.workshop) }, error: false } as const
    },
    reservation: async (
      workshopId: string,
      eventId: string,
      payload: Payload["workshops"]["reservation"]
    ) => {
      type RSuccess = { reservation: ApiWorkshopReservation }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND" | "NO_SLOT_AVAILABLE">
      const { success, data } = await api.post<RSuccess, RError>(
        `frontend/workshops/${workshopId}/events/${eventId}/reservations`,
        { data: reservationPayload.parse(payload) }
      )
      if (!success) return { data: null, error: true, code: data.code } as const
      return {
        data: { reservation: apiWorkshopReservation.parse(data.reservation) },
        error: false,
      } as const
    },
  },
}
