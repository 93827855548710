import {
  Form,
  FormAssertive,
  FormInput,
  FormKeywords,
  FormSubmit,
  FormTiptap,
  durationToTime,
  timeToDuration,
  useForm,
  validator,
} from "@/components/form"
import {} from "@/components/form/input"
import { Prose } from "@/components/frontend/prose"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import type { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { resetAllStoresAndReload } from "@/store"
import { createWorkshop } from "@/store/workshops/actions"
import { match } from "ts-pattern"
import { usePageContext } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/workshops.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "workshops")

/**
 * CreateDialog
 */
export const CreateDialog: React.FC<UseDialogProps> = ({ ...props }) => {
  const { _ } = useDictionary(dictionary("create-dialog"))
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="max-w-2xl">
      <DialogForm {...props} />
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps> = ({ onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const { place } = usePageContext()
  const { min } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      name: "",
      description: "",
      languages: [] as string[],
      duration: durationToTime({ hours: 1, minutes: 0 }),
      maxAttendees: "10",
    },
    validate: validator({
      name: [min(1, _form("name-required"))],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return _errors("VALIDATION_FAILURE")
      const payload = {
        ...D.selectKeys(values, ["name", "description", "languages"]),
        placeId: place.id,
        maxAttendees: +values.maxAttendees,
        duration: timeToDuration(values.duration),
      }
      return match(await createWorkshop(payload))
        .with({ error: false }, ({ id }) => {
          toast.success(_("create-dialog.success"))
          onOpenChange(false)
          navigate(`/dashboard/kultureralley/${place.id}/${id}`)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })
  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormInput label={_("form.name-label")} name="name" placeholder={_form("name-placeholder")} />
      <FormTiptap label={_("form.description-label")} name="description" prose={Prose.className} />
      <FormKeywords
        label={_("form.languages-label")}
        name="languages"
        placeholder={_("form.languages-placeholder")}
      />
      <FormInput type="time" label={_("form.duration-label")} name="duration" />
      <FormInput type="number" label={_("form.max-attendees-label")} name="maxAttendees" />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("create")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
