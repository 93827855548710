import { CounterHighlight } from "@/components/trackings/components/counter-highlight"
import { Card } from "@/components/ui/card"
import { Link } from "@/components/ui/link"
import { useDictionary } from "@/dictionaries/hooks"
import { useLiveState } from "@/hooks/useLiveState"
import { getProjects } from "@/store/projects/actions"
import { useProjects } from "@/store/projects/hooks"
import { getUsers } from "@/store/users/actions"
import { useUsers } from "@/store/users/hooks"

/**
 * dictionary src/dictionaries/en/pages/dashboard/index.json
 */
const dictionary = createContextMapper("pages", "dashboard", "index")

export const Today: React.FC = () => {
  const { _ } = useDictionary(dictionary("daily-overview"))

  useLiveState(async () => {
    await getProjects()
    await getUsers()
  }, 120000)

  const users = useUsers()
  const usersToValidate = React.useMemo(
    () => users.filter(user => user.status === "pending"),
    [users]
  )

  const projects = useProjects()
  const projectsToValidate = React.useMemo(
    () => projects.filter(project => project.status === "pending"),
    [projects]
  )
  const counterCx = "aspect-[21/9] sm:aspect-video hover:border-primary hover:text-primary"
  return (
    <Card.Spotlight className="col-span-1 @4xl/collection:col-span-2 @6xl/collection:col-span-3">
      <Card.Header>
        <Card.Title className="line-clamp-1">{_("title")}</Card.Title>
        <Card.Description>{_("secondary")}</Card.Description>
      </Card.Header>
      <Card.Content>
        <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-6">
          <Link href="/dashboard/users">
            <CounterHighlight
              counter={usersToValidate.length}
              description={_("users")}
              className={counterCx}
            />
          </Link>
          <Link href="/dashboard/projects">
            <CounterHighlight
              counter={projectsToValidate.length}
              description={_("projects")}
              className={counterCx}
            />
          </Link>
        </div>
      </Card.Content>
    </Card.Spotlight>
  )
}
