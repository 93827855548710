/**
 * without:
 * returns a list rejecting the inclusion of a second list
 */
export const without = <T>(list: T[], withoutList: T[]) =>
  A.reject<T>(list, v => A.includes(withoutList, v))

/**
 * toggle:
 * returns a list toggling the presence of a specified item
 */
export const toggle = <I>(list: I[], item: I) =>
  A.includes(list, item) ? A.reject(list, F.equals(item)) : A.append(list, item)

/**
 * stress:
 * returns a list bringing all instances of item to the start
 */
export const stress = <X>(x: any, xs: X[]) => {
  return A.reduce<X, X[]>(xs, [], (acc, xx) => {
    return xx === x ? [xx, ...acc] : [...acc, xx]
  })
}

/**
 * relax:
 * returns a list bringing all instances of item to the end
 */
export const relax = <X>(x: any, xs: X[]) => {
  return A.reduce<X, X[]>(xs, [], (acc, xx) => {
    return xx === x ? [...acc, xx] : [xx, ...acc]
  })
}

/**
 * stressBy:
 * returns a list bringing all instances of item to the start
 */
export const stressBy = <X>(xs: X[], fn: (x: X) => boolean) => {
  return A.reduce<X, X[]>(xs, [], (acc, xx) => {
    return fn(xx) ? [xx, ...acc] : [...acc, xx]
  })
}

/**
 * makeCompare
 */
export const makeCompare =
  <X>(xs: X[]) =>
  (compare: (a: X, b: X) => number, descending = true) => {
    return A.sort(xs, (a, b) => (descending ? compare(a, b) : compare(b, a)))
  }

/**
 * mergeNonNullables
 */
export const mergeNonNullables = <T>(...args: Array<Array<T | null | undefined>>) =>
  pipe(args, A.map(A.filter(G.isNotNullable)), A.concatMany) as T[]
