import { ApiPlace } from "@/services/places/schemas"

/**
 * localizePlace
 */
export const localizePlace = (parsed: ApiPlace) => ({
  ...D.selectKeys(parsed, [
    "id",
    "name",
    "published",
    "description",
    "address",
    "website",
    "phones",
    "emails",
    "map",
    "coordinate",
  ]),
  image: G.isNullable(parsed.image) ? null : D.getUnsafe(parsed.image, "id"),
  cover: G.isNullable(parsed.cover) ? null : D.getUnsafe(parsed.cover, "id"),
})
export type Place = ReturnType<typeof localizePlace>
