/**
 * tos
 * try or return error sync
 */
export const tos = <R, E, A extends any[]>(fn: (...args: A) => R, ...args: A): [null, R] | [E, undefined] => {
  try {
    return [null, fn(...args)]
  } catch (error) {
    return [error as E, undefined]
  }
}

/**
 * tof
 * try or false
 */
export const tof = <R, A extends any[]>(fn: (...args: A) => R, ...args: A): R | false => {
  try {
    return fn(...args)
  } catch (error) {
    return false
  }
}

/**
 * tryOrNull
 */
export const tryOrNull = <R, A extends any[]>(fn: (...args: A) => R, ...args: A): R | null => {
  try {
    return fn(...args)
  } catch (error) {
    return null
  }
}

/**
 * to
 * try or return error async
 */
export const to = async <R, E>(promise: Promise<R>): Promise<[E, undefined] | [null, R]> =>
  promise.then<[null, R]>((result: R) => [null, result]).catch<[E, undefined]>((error: E) => [error, undefined])
