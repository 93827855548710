import { useTheme } from "@/components/layout/dashboard/theme/useTheme"

export const useStatsTheme = () => {
  const { isDark } = useTheme()
  const colors = isDark
    ? [
        "#7400B8",
        "#6930C3",
        "#5E60CE",
        "#5390D9",
        "#4EA8DE",
        "#48BFE3",
        "#56CFE1",
        "#64DFDF",
        "#64DFDF",
        "#80FFDB",
      ]
    : [
        "#7400B8",
        "#6930C3",
        "#5E60CE",
        "#5390D9",
        "#4EA8DE",
        "#48BFE3",
        "#56CFE1",
        "#64DFDF",
        "#64DFDF",
        "#80FFDB",
      ]
  return { colors }
}
