import { useCmsContext } from "@/components/cms/Context"
import {
  Form,
  FormAssertive,
  FormInput,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { useForm } from "use-a11y-form"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")
type Payload = FormPayload<ItemType>

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary("content", "items", itemType))
  const { _: __ } = useDictionary(dictionary("content", "form"))
  const _f = useFormDictionary()
  const _e = useErrorsDictionary()

  const { updateContentItem } = useCmsContext().actions
  const languages = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      titleLevel: `${item.props.titleLevel}`,
      translations: D.map(languages, language => ({
        languageId: language.id,
        title: translate(item, language)?.props.title ?? "",
        secondary: translate(item, language)?.props.secondary ?? "",
        label: translate(item, language)?.props.label ?? "",
        button: translate(item, language)?.props.button ?? "",
      })),
    })),
    onSubmit: async ({ values }) => {
      values.translations
      const payload: Payload = {
        props: {
          titleLevel: +values.titleLevel,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: [],
      }

      return match(await updateContentItem(item.id, payload))
        .with({ error: false }, () => {
          toast.success(__("success"))
          close()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _e)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_e(code)))
        )
    },
  })

  const titleLevelOptions = useTitleLevelOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />

      <FormTranslationTabs context={false}>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormTranslationContext language={language}>
              <FormInput
                name="title"
                label={_("title-label")}
                placeholder={_("title-placeholder")}
                translatable
              />
            </FormTranslationContext>
            <FormSelect
              options={titleLevelOptions}
              name="titleLevel"
              label={__("title-level-label")}
            />
            <FormTranslationContext language={language}>
              <FormTiptap
                name="secondary"
                label={__("secondary-label")}
                placeholder={__("secondary-placeholder")}
                translatable
              />
              <FormInput
                name="label"
                label={_("label-label")}
                placeholder={_("label-placeholder")}
                translatable
              />
              <FormInput
                name="button"
                label={_("button-label")}
                placeholder={_("button-placeholder")}
                translatable
              />
            </FormTranslationContext>
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_f("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_f("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
