import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, TooltipProps } from "recharts"
import { useStatsTheme } from "../useStatsTheme"
import { StatRecord } from "../types"

/**
 * StatsPieChart
 */
export const StatsPieChart: React.FC<{ stats: StatRecord[] }> = ({ stats }) => {
  const { colors } = useStatsTheme()
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={stats}
          cx="50%"
          cy="50%"
          innerRadius="75%"
          paddingAngle={5}
          outerRadius="100%"
          dataKey="value"
        >
          {A.mapWithIndex(stats, index => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
              strokeWidth={1}
              stroke="hsl(var(--muted))"
            />
          ))}
        </Pie>
        <Tooltip content={<StatsPieChartTooltip />} />
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          wrapperStyle={{ fontSize: "1em" }}
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

/**
 * StatsPieChartTooltip
 */
const StatsPieChartTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  if (!(active && payload && payload.length)) return null
  const {
    name,
    value,
    payload: { fill },
  } = A.getUnsafe(payload, 0)
  return (
    <div className="rounded-md border bg-popover px-3 py-1.5 text-[0.75em] text-popover-foreground shadow-md">
      <b style={{ color: fill }}>{name}: </b> <span>{`${value}`}</span>
    </div>
  )
}
