import { Button } from "@/components/ui/button"
import { SrOnly } from "@/components/ui/sr-only"
import { LayoutGrid, TableProperties } from "lucide-react"
import { useToolbarContext } from "./root"

/**
 * ToolbarView
 * dictionary: src/dictionaries/en/components/Layout.json
 */
type Props = {
  view: "grid" | "list"
  setView: React.Dispatch<Props["view"]>
}
export const ToolbarView: React.FC<Props> = ({ view, setView }) => {
  const { _ } = useDictionary("components.layout.toolbar")
  const { size } = useToolbarContext()
  return view === "grid" ? (
    <Button
      aria-label={_("view-display-list")}
      variant="secondary"
      size={size}
      icon
      onClick={() => setView("list")}
    >
      <TableProperties aria-hidden />
      <SrOnly>{_("view-grid")}</SrOnly>
    </Button>
  ) : (
    <Button
      aria-label={_("view-display-grid")}
      variant="secondary"
      size={size}
      icon
      onClick={() => setView("grid")}
    >
      <LayoutGrid aria-hidden className="rotate-180" />
      <SrOnly>{_("view-list")}</SrOnly>
    </Button>
  )
}
