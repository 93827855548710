import { z } from "zod"
import { apiExtraField } from "../commons/schemas"
import { apiMediasFile } from "../medias/schemas"

/**
 * MapName
 */
export const mapName = ["north", "south", "east", "west", "center"] as const
export const apiMapName = z.union([
  z.literal("north"),
  z.literal("south"),
  z.literal("east"),
  z.literal("west"),
  z.literal("center"),
])
export type ApiMapName = z.infer<typeof apiMapName>

/**
 * ApiPlace
 */
export const apiPlace = z.object({
  id: z.string().uuid(),
  name: z.string(),
  published: z.boolean(),
  description: z.string(),
  address: z.string(),
  website: z.string(),
  phones: apiExtraField.array(),
  emails: apiExtraField.array(),
  image: apiMediasFile.nullable(),
  cover: apiMediasFile.nullable(),
  map: apiMapName,
  coordinate: z.tuple([z.number(), z.number()]),
})
export type ApiPlace = z.infer<typeof apiPlace>
