import { decorateStore } from "@/fns/decorateStore"
import { create } from "zustand"
import { Article, ArticleCategory } from "./localizers"

/**
 * types
 */
export type ArticlesStoreState = {
  articles: ById<Article>
  categories: ById<ArticleCategory>
}

/**
 * initialState
 */
const initialState: ArticlesStoreState = {
  categories: {},
  articles: {},
}

/**
 * store
 */
export const articlesStore = decorateStore(initialState, create, {})
export const useArticlesStore = articlesStore.use
