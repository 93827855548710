import { Fields, Loader, Menu, Section } from "@/components/collection"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Image } from "@/components/ui/image"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { hasTruthyKey } from "@/fns/helpers"
import { usePromise } from "@/hooks/usePromise"
import { getUserThumbnail } from "@/store/auth/helpers"
import { useIsAdmin, useIsSuperadmin, useMe } from "@/store/auth/hooks"
import { getUser } from "@/store/users/actions"
import { useUser } from "@/store/users/hooks"
import { User } from "@/store/users/localizers"
import {
  CalendarClock,
  CalendarPlus,
  Mail,
  MailSearch,
  Settings,
  Trash,
  UserCog,
  UserIcon,
  UserSquare,
} from "lucide-react"
import { Redirect } from "wouter"
import { ContextProvider, usePageContext } from "../Context"
import { getFullname } from "../Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/users.json
 */
const dictionary = createContextMapper("pages", "dashboard", "users")

/**
 * Page: DashboardUsersUser
 */
type PageProps = { id: string }
const DashboardUsersUser: React.FC<PageProps> = ({ id }) => {
  const { _ } = useDictionary(dictionary())
  const user = useUser(id)
  const isAdmin = useIsAdmin()

  const [result, inProgress] = usePromise(() => getUser(id))
  if (inProgress) return <Loader breadcrumbs={[[_("breadcrumbs"), "/dashboard/users"]]} />
  if (!result || result.error || G.isNullable(user)) return <Redirect to="/dashboard/users" />

  const fullname = getFullname(user, _("fullname-placeholder"))
  return (
    <PageWrapper>
      <PageHeader
        breadcrumbs={[
          [_("breadcrumbs"), "/dashboard/users"],
          [fullname, `/dashboard/users/${id}`],
        ]}
      />
      <Menu type="context-menu" menu={<ContextMenu user={user} />} disabled={!isAdmin}>
        <div className="inline">
          <SectionHeader user={user} />
          <SectionAccount user={user} />
          <SectionPersonal user={user} />
        </div>
      </Menu>
    </PageWrapper>
  )
}

export default (props: PageProps) => (
  <ContextProvider>
    <DashboardUsersUser {...props} />
  </ContextProvider>
)

/**
 * SectionHeader
 */
type Props = { user: User }
const SectionHeader: React.FC<Props> = ({ user }) => {
  const { _ } = useDictionary(dictionary())
  const me = useMe()
  const isMe = me.id === user.id
  const fullname = getFullname(user, _("fullname-placeholder"))
  const { position, company } = user.profile
  return (
    <Section className="mt-16">
      <div className="relative flex justify-center h-12">
        <div
          className={cx(
            "mx-auto absolute -top-20 w-32 h-32 shadow-sm rounded-full transition-all hover:scale-110"
          )}
        >
          <Image
            src={getUserThumbnail(user)}
            alt={_(`profile-image-alt`, { fullname })}
            className="w-full h-full rounded-full aspect-square object-cover text-muted-foreground/10 border-4 border-card"
          >
            <UserIcon size={64} strokeWidth={1} />
          </Image>
        </div>
      </div>
      <div className="flex flex-col space-x-2">
        <h1 className="font-bold text-center text-3xl">
          {fullname}
          {isMe && <span className="text-sm pl-2 text-foreground/75">({_("is-me")})</span>}
        </h1>
        {hasTruthyKey(user.profile, ["position", "company"]) && (
          <p className="text-center text-foreground/50 font-medium">
            {position} {company}
          </p>
        )}
      </div>
    </Section>
  )
}

/**
 * SectionAccount
 */
const SectionAccount: React.FC<Props> = ({ user }) => {
  const { _ } = useDictionary(dictionary())
  const format = useDateFnsLocaleFormat()
  const { role, status, createdAt, updatedAt } = user
  return (
    <Section>
      <Section.Header>
        <Section.HeaderTitle>{_("section-account-title")}</Section.HeaderTitle>
        <Section.HeaderDescription>{_("section-account-description")}</Section.HeaderDescription>
        <Section.Menu menu={<ContextMenu user={user} />} />
      </Section.Header>
      <Fields divider>
        <Fields.Item name={_("role")} icon={<Settings aria-hidden />} value={_(`role-${role}`)} />
        <Fields.Item
          name={_("status")}
          icon={<UserCog aria-hidden />}
          value={_(`status-${status}`)}
        />
        <Fields.Item
          name={_("created-at")}
          icon={<CalendarPlus aria-hidden />}
          value={format(createdAt, "PPpp")}
        />
        <Fields.Item
          name={_("updated-at")}
          icon={<CalendarClock aria-hidden />}
          value={format(updatedAt, "PPpp")}
        />
      </Fields>
    </Section>
  )
}

/**
 * SectionPersonal
 */
const SectionPersonal: React.FC<Props> = ({ user }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Section>
      <Section.Header>
        <Section.HeaderTitle>{_("section-personal-title")}</Section.HeaderTitle>
        <Section.HeaderDescription>{_("section-personal-description")}</Section.HeaderDescription>
        <Section.Menu menu={<ContextMenu user={user} />} />
      </Section.Header>
      <Fields>
        <Fields.Item
          name={_("email")}
          icon={<Mail aria-hidden />}
          value={
            <a href={`mailto:${user.email}`} className="underline">
              {user.email}
            </a>
          }
        />
        {user.profile.email && (
          <Fields.Item
            name={_("email-visible")}
            icon={<MailSearch aria-hidden />}
            value={
              <a href={`mailto:${user.profile.email}`} className="underline">
                {user.profile.email}
              </a>
            }
          />
        )}
      </Fields>
    </Section>
  )
}

/**
 * ContextMenu
 */
export const ContextMenu: React.FC<Props> = ({ user }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { confirmDelete, updateAccount, updateProfile } = usePageContext()

  const { id } = user
  const isAdmin = useIsAdmin()

  const isSuperadmin = useIsSuperadmin()
  const canManage = isSuperadmin || (!isSuperadmin && user.role !== "superadmin")

  return (
    <>
      {canManage && (
        <Menu.Item onClick={() => updateAccount(user)}>
          <UserCog aria-hidden />
          {_("edit-account")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => updateProfile(user)}>
        <UserSquare aria-hidden />
        {_("edit-profile")}
      </Menu.Item>
      {canManage && (
        <Menu.Item onClick={() => confirmDelete(id)}>
          <Trash aria-hidden />
          {_("delete")}
        </Menu.Item>
      )}
    </>
  )
}
