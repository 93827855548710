import { CookieDeclaration } from "@/components/cookies/lib/context/types"

export const cookieDeclarations = {
  domain: window.location.hostname,
  path: "/",
  expiresAfterDays: 182, // 6 months
  categories: ["necessary", "preferences", "analytics", "marketing"],
  apps: [
    {
      name: "authentication",
      categories: ["necessary"],
      cookies: [],
      session: ["required-*"],
      local: ["required-*"],
      default: true,
      required: true,
    },
    {
      name: "interface",
      categories: ["preferences"],
      session: ["filters-*", "sort-*", "toggle-*", "state-*", "theme-*"],
      local: ["filters-*", "sort-*", "toggle-*", "state-*", "theme-*"],
      default: true,
      required: true,
    },
  ],
} as const satisfies CookieDeclaration
