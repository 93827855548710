import { Image } from "@/components/ui/image"
import { useTranslation } from "@/store/languages/hooks"
import { useMediasFile } from "@/store/medias/hooks"
import { MediasFile } from "@/store/medias/localizers"
import { match } from "ts-pattern"
import { container } from "../../frontend/container"
import { Header } from "../../frontend/header"
import { ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel, template } = item.props
  const { title, secondary } = t(item).props
  const image = useMediasFile(item.props.image)

  if (G.isNullable(image)) return null
  return (
    <div className={cx("flex flex-col py-8 gap-8 font-plus", container({ x: "sm" }))}>
      <Header {...{ title, secondary, titleLevel }} />
      {match(template)
        .with(0, () => <Template1 {...{ image }} />)
        .with(1, () => <Template2 {...{ image }} />)
        .with(2, () => <Template3 {...{ image }} />)
        .with(3, () => <Template4 {...{ image }} />)
        .otherwise(() => null)}
    </div>
  )
}

/**
 * templates
 */
type TemplateProps = { image: MediasFile }
const Template1: React.FC<TemplateProps> = ({ image }) => {
  const t = useTranslation()
  return <Image src={image.url} alt={t(image).alt} className="w-full h-auto" />
}
const Template2: React.FC<TemplateProps> = ({ image }) => {
  const t = useTranslation()
  return <Image src={image.url} alt={t(image).alt} className="w-full aspect-video object-cover" />
}
const Template3: React.FC<TemplateProps> = ({ image }) => {
  const t = useTranslation()
  return <Image src={image.url} alt={t(image).alt} className="w-full aspect-[3/2] object-cover" />
}
const Template4: React.FC<TemplateProps> = ({ image }) => {
  const t = useTranslation()
  const { name, alt, caption } = t(image)
  return (
    <div className="relative w-full aspect-video object-cover">
      <Image src={image.url} alt={alt} className="w-full h-auto" />
      <div className="absolute bottom-0 inset-x-0 w-full flex flex-col p-4 gap-2 bg-black/80 text-white">
        <div className="text-base leading-tight font-bold">{name}</div>
        <div className="text-sm  max-w-xl">{caption}</div>
      </div>
    </div>
  )
}
