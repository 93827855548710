import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { Button } from "@/components/ui/button"
import { SrOnly } from "@/components/ui/sr-only"
import * as Primitive from "@radix-ui/react-collapsible"
import { ChevronUp } from "lucide-react"

/**
 * CardRoot
 */
const CardRoot = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div"> & {
    id: string
  }
>(({ className, children, id, ...props }, ref) => {
  const [state, setState] = usePersistedState(
    true,
    `toggle-cms-card-${id}`,
    "interface",
    sessionStorage
  )
  return (
    <Primitive.Root
      ref={ref}
      className={cx(
        "group/card @container/card grid w-full max-w-screen-2xl mx-auto bg-card rounded-md shadow-md border border-border/50",
        className
      )}
      open={state}
      onOpenChange={setState}
      {...props}
    >
      {children}
    </Primitive.Root>
  )
})

/**
 * CardHeader
 */
const CardHeader = React.forwardRef<React.ElementRef<"div">, React.ComponentPropsWithoutRef<"div">>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cx(
          "flex justify-between items-center w-full px-4 @lg/card:px-8 py-4 gap-8",
          className
        )}
        {...props}
        ref={ref}
      >
        {children}
      </div>
    )
  }
)

/**
 * CardHeaderTitle
 */
const CardHeaderTitle = React.forwardRef<
  React.ElementRef<"h2">,
  React.ComponentPropsWithoutRef<"h2">
>(({ className, children, ...props }, ref) => {
  return (
    <h2
      className={cx(
        "flex items-center gap-2 text-base font-normal leading-none tracking-tight",
        className
      )}
      {...props}
      ref={ref}
    >
      {children}
    </h2>
  )
})

/**
 * CardHeaderAside
 */
const CardHeaderAside = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div">
>(({ className, children }, ref) => {
  const { _ } = useDictionary(contextMapper("components", "cms"))
  return (
    <div className={cx("flex items-center gap-2", className)} ref={ref}>
      {children}
      <Primitive.CollapsibleTrigger asChild>
        <Button variant="ghost" size="xxs" icon>
          <ChevronUp
            aria-hidden
            className="group-data-[state=closed]/card:-rotate-180 transition-transform shrink-0"
          />
          <SrOnly>{_("accordion-toggle")}</SrOnly>
        </Button>
      </Primitive.CollapsibleTrigger>
    </div>
  )
})

/**
 * CardContent
 */
const CardContent = React.forwardRef<
  React.ElementRef<typeof Primitive.CollapsibleContent>,
  React.ComponentPropsWithoutRef<typeof Primitive.CollapsibleContent>
>(({ className, children, ...props }, ref) => {
  //
  return (
    <Primitive.CollapsibleContent
      ref={ref}
      className={cx(
        "data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down",
        className
      )}
      {...props}
    >
      {children}
    </Primitive.CollapsibleContent>
  )
})

export const Card = Object.assign(CardRoot, {
  Header: Object.assign(CardHeader, {
    Title: CardHeaderTitle,
    Aside: CardHeaderAside,
  }),
  Content: CardContent,
})
