/**
 * layout context
 */
const LayoutContext = React.createContext<LayoutContext>({
  isFrontend: false,
  isDashboard: false,
})
export const useBaseLayout = () => React.useContext(LayoutContext)
export const BaseLayoutProvider: React.FC<React.PropsWithChildren<Partial<LayoutContext>>> = ({
  children,
  isDashboard = false,
  isFrontend = false,
}) => (
  <LayoutContext.Provider
    value={{
      isDashboard,
      isFrontend,
    }}
  >
    {children}
  </LayoutContext.Provider>
)

type LayoutContext = {
  isFrontend: boolean
  isDashboard: boolean
}
