import { Button } from "@/components/frontend/button"
import { Prose } from "@/components/frontend/prose"
import { Dialog } from "@/components/ui/dialog"
import type { UseDialogProps } from "@/components/ui/hooks/useDialog"
import { dictionary } from "."

/**
 * ConfirmDialog
 */
export const ConfirmDialog: React.FC<
  UseDialogProps<void> & {
    backToWorkshop: () => void
  }
> = ({ backToWorkshop, onOpenChange, ...props }) => {
  const { _ } = useDictionary(dictionary("reservation", "confirm"))
  return (
    <Dialog
      {...props}
      onOpenChange={() => {
        onOpenChange(false)
        backToWorkshop()
      }}
      title={_("title")}
      className="max-w-2xl"
    >
      <Prose>
        <p dangerouslySetInnerHTML={{ __html: _("paragraph-1") }} />
        <p dangerouslySetInnerHTML={{ __html: _("paragraph-2") }} />
      </Prose>
      <div className="flex justify-between pt-4">
        <Button onClick={backToWorkshop} variant="outline">
          {_("back-to-workshop")}
        </Button>
      </div>
    </Dialog>
  )
}
