import { ApiProgram } from "@/services/programs/schemas"

/**
 * localizeProgram
 */
export const localizeProgram = (parsed: ApiProgram) => ({
  ...D.selectKeys(parsed, [
    "id",
    "name",
    "language",
    "code",
    "class",
    "programs",
    "programsVisits",
    "programsTrackingId",
    "books",
    "booksVisits",
    "booksTrackingId",
    "pearltrees",
    "pearltreesVisits",
    "pearltreesTrackingId",
    "archived",
  ]),
  createdAt: T.parseISO(parsed.createdAt),
  updatedAt: T.parseISO(parsed.updatedAt),
})
export type Program = ReturnType<typeof localizeProgram>
