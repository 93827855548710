import { useCmsContext } from "@/components/cms/Context"
import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSection,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import cmsConfig from "@/config/cms"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { usePromise } from "@/hooks/usePromise"
import { resetAllStoresAndReload } from "@/store"
import { getArticleCategories, getArticles } from "@/store/articles/actions"
import { useCategoryOptions } from "@/store/articles/hooks"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { useForm } from "use-a11y-form"
import { FormSelectArticles } from "../../form"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"

/**
 * dictionary src/dictionaries/fr/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")
type Payload = FormPayload<ItemType>

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _: __ } = useDictionary(dictionary("content", "form"))
  const _f = useFormDictionary()
  const _e = useErrorsDictionary()

  const { updateContentItem } = useCmsContext().actions
  const languages = useLanguagesById()
  usePromise(getArticleCategories)
  usePromise(getArticles)
  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(item.props, ["articles"]),
      titleLevel: `${item.props.titleLevel}`,
      category: G.isNull(item.props.category) ? "null" : item.props.category,
      translations: D.map(languages, language => ({
        languageId: language.id,
        title: translate(item, language)?.props.title ?? "",
        secondary: translate(item, language)?.props.secondary ?? "",
      })),
    })),
    onSubmit: async ({ values }) => {
      values.translations
      const payload: Payload = {
        props: {
          ...D.selectKeys(values, ["articles"]),
          titleLevel: +values.titleLevel,
          category: values.category === "null" ? null : values.category,
          omit: item.props.omit,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: [],
      }

      return match(await updateContentItem(item.id, payload))
        .with({ error: false }, () => {
          toast.success(__("success"))
          close()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _e)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_e(code)))
        )
    },
  })
  const categoryOptions = useCategoryOptions(__("select-articles.category-placeholder"))
  const titleLevelOptions = useTitleLevelOptions()
  return (
    <Form form={form}>
      <FormAssertive />
      <FormTranslationTabs context={false}>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormTranslationContext language={language}>
              <FormInput
                name="title"
                label={__("title-label")}
                placeholder={__("title-placeholder")}
                translatable
              />
            </FormTranslationContext>
            <FormSelect
              options={titleLevelOptions}
              name="titleLevel"
              label={__("title-level-label")}
            />
            <FormTranslationContext language={language}>
              <FormTiptap
                name="secondary"
                label={__("secondary-label")}
                placeholder={__("secondary-placeholder")}
                translatable
              />
            </FormTranslationContext>
            <FormSection>
              <FormHeader>
                <FormHeader.Title>{__("select-offers.title")}</FormHeader.Title>
                <FormHeader.Description>{__("select-offers.description")}</FormHeader.Description>
              </FormHeader>
              <FormSelect
                name="category"
                label={__("select-offers.category-label")}
                options={categoryOptions}
              />
              <FormSelectArticles
                name="articles"
                label={__("select-offers.offers-label")}
                type="offers"
                labelAside={
                  <span className="text-muted-foreground text-xs px-2">
                    {form.values.articles.length} / {cmsConfig.relatedArticles}
                  </span>
                }
                placeholder={__("select-offers.offers-placeholder")}
                max={cmsConfig.relatedArticles}
              />
            </FormSection>
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_f("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_f("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
