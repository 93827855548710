import { cva, type VariantProps } from "class-variance-authority"
import { Tooltip } from "./tooltip"

/**
 * variants
 */
const badgeVariants = cva(
  "inline-flex justify-center items-center rounded-md border [&>svg]:size-3 text-xs text-center font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        success: "border-transparent bg-green-700 text-white hover:bg-green-600/80",
        outline: "text-foreground",
      },
      stretch: {
        false: "",
        true: "",
      },
      icon: {
        false: "",
        true: "size-6 px-0 py-0 shrink-0",
      },
    },
    compoundVariants: [
      { icon: false, stretch: false, class: "px-4 py-0.5" },
      { icon: false, stretch: true, class: "px-2 py-0.5" },
      { icon: false, class: "[&>svg]:mr-2 min-h-6" },
    ],
    defaultVariants: {
      variant: "default",
      stretch: false,
      icon: false,
    },
  }
)

/**
 * Badge
 */
type BadgeProps = React.ComponentPropsWithoutRef<"div"> &
  VariantProps<typeof badgeVariants> & {
    side?: React.ComponentProps<typeof Tooltip>["side"]
  }
const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, stretch, icon, side, ...props }, ref) => {
    if (G.isNotNullable(props["aria-label"]))
      return (
        <Tooltip content={props["aria-label"]} side={side}>
          <span
            ref={ref}
            className={cx(badgeVariants({ variant, stretch, icon }), className)}
            {...props}
          />
        </Tooltip>
      )
    return (
      <span
        ref={ref}
        className={cx(badgeVariants({ variant, stretch, icon }), className)}
        {...props}
      />
    )
  }
)

/**
 * exports
 */
export { Badge, badgeVariants, type BadgeProps }
