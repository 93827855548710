import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { Limitable } from "@/hooks/useLimitable"
import { useInView } from "react-intersection-observer"
import { Button } from "../ui/button"

/**
 * dictionary src/dictionaries/en/components/layout.json
 */
const dictionary = createContextMapper("components", "layout")

/**
 * LimitMore
 */
export const LimitMore: React.FC<Limitable> = ({ reached, takeMore }) => {
  const { _ } = useDictionary(dictionary())
  const { ref: showMoreRef, inView } = useInView({})
  React.useEffect(() => {
    if (inView) takeMore()
  }, [inView, takeMore])
  return reached ? (
    <div className="flex justify-center">
      <Button variant="outline" onClick={takeMore} ref={showMoreRef}>
        {_("more")}
      </Button>
    </div>
  ) : null
}
