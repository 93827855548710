import { type ContentItemMapping, type ItemMapping } from "./schemas"

/**
 * unions exports
 */
import * as articles from "./articles"
import * as cards from "./cards"
import * as contactDetails from "./contact-details"
import * as contactForm from "./contact-form"
import * as gallery from "./gallery"
import * as heading from "./heading"
import * as kulturRallye from "./kultur-rallye"
import * as news from "./news"
import * as newsletter from "./newsletter"
import * as offers from "./offers"
import * as picture from "./picture"
import * as projects from "./projects"
import * as richtext from "./richtext"
import * as video from "./video"

const Items: ItemMapping<keyof ContentItemMapping> = {
  articles,
  cards,
  "contact-details": contactDetails,
  "contact-form": contactForm,
  gallery,
  heading,
  "kultur-rallye": kulturRallye,
  news,
  newsletter,
  offers,
  picture,
  projects,
  richtext,
  video,
}
export default Items
