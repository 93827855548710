import { Empty, Grid, LimitMore, Selection, Toolbar } from "@/components/collection"
import { PageContent } from "@/components/layout/dashboard"
import { useFilteredArticleCategories } from "@/store/articles/hooks"
import { usePageContext } from "./Context"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/articles.json
 */
const dictionary = createContextMapper("pages", "dashboard", "articles", "categories")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { categories, filtered, filterable, matchable, sortable, limitable, limit } =
    useFilteredArticleCategories()
  const { view, setView, create, selected, setSelected, confirmDeleteSelection } = usePageContext()
  return (
    <PageContent>
      <Selection
        selected={selected}
        unselect={() => setSelected([])}
        deleteSelection={confirmDeleteSelection}
      />
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Toolbar.Button onClick={create}>{_("create")}</Toolbar.Button>
        <Toolbar.Sort {...sortable} dictionary={Toolbar.Sort.dictionary(dictionary("sort"))} />
        <Toolbar.View {...{ view, setView }} />
      </Toolbar>
      <Empty
        items={categories}
        results={filtered}
        create={create}
        clear={() => {
          matchable.setSearch("")
          filterable.clear()
          setSelected([])
        }}
      />
      <Grid {...{ view }}>
        {A.map(limit(filtered), item => (
          <Item key={item.id} category={item} />
        ))}
      </Grid>
      <LimitMore {...limitable} />
    </PageContent>
  )
}
export default Collection
