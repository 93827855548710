import { Empty, Grid, Pagination, Selection, Toolbar } from "@/components/collection"
import { PageContent } from "@/components/layout/dashboard"
import { useFilteredWorkshopEvents } from "@/store/workshops/hooks"
import { FilterX, Ticket, TicketCheck } from "lucide-react"
import { usePageContext } from "./Context"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/workshops.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "events")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { workshop, view, selected, setSelected, ...ctx } = usePageContext()
  const { events, filtered, matchable, sortable, filterable, paginated, paginable } =
    useFilteredWorkshopEvents(workshop.id)
  return (
    <PageContent>
      <Selection
        selected={selected}
        deleteSelection={ctx.confirmDeleteSelection}
        unselect={() => setSelected([])}
      />
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Toolbar.Button onClick={ctx.create}>{_("create")}</Toolbar.Button>
        <Filters {...filterable} />
        <Toolbar.Sort {...sortable} dictionary={dictionary("sort")} />
      </Toolbar>
      <Empty
        items={events}
        results={filtered}
        create={ctx.create}
        clear={() => {
          matchable.setSearch("")
          setSelected([])
        }}
      />
      <Grid view={view} className="divide-card">
        {A.map(paginated, item => (
          <Item key={item.id} event={item} />
        ))}
      </Grid>
      <Pagination {...paginable} />
    </PageContent>
  )
}

/**
 * Filters
 */
const Filters: React.FC<ReturnType<typeof useFilteredWorkshopEvents>["filterable"]> = ({
  isActive,
  toggleActive,
  isInactive,
  toggleInactive,
  reset,
}) => {
  const { _ } = useDictionary(dictionary("filters"))
  const { setSelected } = usePageContext()

  return (
    <Toolbar.Filters onClick={() => setSelected([])}>
      <Toolbar.Filters.Item onClick={() => toggleActive("full")} active={isActive("full")}>
        <TicketCheck aria-hidden />
        {_("full")}
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item onClick={() => toggleInactive("full")} active={isInactive("full")}>
        <Ticket aria-hidden />
        {_("not-full")}
      </Toolbar.Filters.Item>

      <Toolbar.Filters.Separator />
      <Toolbar.Filters.Item onClick={reset}>
        <FilterX aria-hidden />
        {_("reset")}
      </Toolbar.Filters.Item>
    </Toolbar.Filters>
  )
}
