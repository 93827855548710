import { Form } from "."
import { useBaseLayout } from "../layout/context"

/**
 * FormLabel
 */
type Props = React.ComponentProps<"label"> & { required?: boolean }
export const FormLabel: React.FC<Props> = ({ className, children, required = false, ...props }) => {
  const { isDashboard } = useBaseLayout()
  return G.isNotNullable(children) ? (
    <Form.Label
      className={cx(
        "leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
        isDashboard ? "text-sm font-medium" : "font-medium text-base text-slate-700",
        className
      )}
      {...props}
    >
      {children}
      {required && <span className="text-red-500">{"*"}</span>}
    </Form.Label>
  ) : null
}
