import { usePromise } from "@/hooks/usePromise"
import { normString } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"
import { matchSorter } from "match-sorter"
import { useMemo } from "react"
import { useMediasStore } from "."
import { useTranslation } from "../languages/hooks"
import { getMediasFolder, getMediasRoot } from "./actions"
import { getFoldersPath, isFile } from "./helpers"
import { MediasFile, MediasFolder } from "./localizers"

export const useFreshFolder = (folderId: string | null) => {
  const [result, inProgress] = usePromise(async () => {
    if (G.isNullable(folderId)) return await getMediasRoot()
    else return await getMediasFolder(folderId)
  }, [folderId])
  return [result, inProgress] as const
}
export const useMediasFoldersById = () => {
  return useMediasStore(D.prop("folders"))
}
export const useMediasFolders = () => {
  return useMediasStore(D.prop("folders"))
}
export const useMediasFolder = (id: string) => {
  return useMediasStore(flow(D.prop("folders"), D.get(id)))
}
export const useMediasFilesById = () => {
  return useMediasStore(D.prop("files"))
}
export const useMediasFile = (id: Option<string>) => {
  return useMediasStore(
    flow(D.prop("files"), files => (G.isNotNullable(id) ? D.get(files, id) : null))
  )
}
export const useMediasFiles = (ids: string[]) => {
  return useMediasStore(
    flow(
      D.prop("files"),
      D.selectKeys(ids),
      D.values,
      A.sortBy(({ id }) => ids.indexOf(id) * -1)
    )
  )
}
export const useFolderPath = (folderId: string | null) => {
  const folders = useMediasFoldersById()
  return useMemo(() => getFoldersPath(folderId, folders), [folderId, folders])
}
export const useMediasInFolder = (currentId: string | null, hiddenIds: string[] = []) => {
  const { files, folders } = useMediasStore()
  return React.useMemo(
    () => ({
      files: A.filter(
        D.values(files),
        ({ id, folderId }) => folderId === currentId && !hiddenIds.includes(id)
      ),
      folders: A.filter(
        D.values(folders),
        ({ id, parentId }) => parentId === currentId && !hiddenIds.includes(id)
      ),
    }),
    [currentId, files, folders, hiddenIds]
  )
}

/**
 * collection
 */
export const useFilteredMedias = (folderId: string | null, hiddenIds: string[] = []) => {
  const t = useTranslation()
  const { files, folders } = useMediasInFolder(folderId, hiddenIds)
  const [search, setSearch] = React.useState("")

  const [sortable, sortBy] = useSortable<MediasFile | MediasFolder>(
    "medias",
    {
      name: item => (isFile(item) ? t(item).name : item.name),
      size: item => (isFile(item) ? item.size : item.name),
      createdAt: ({ createdAt }) => createdAt,
      updatedAt: ({ updatedAt }) => updatedAt,
    },
    "name"
  )

  const matchable = { search, setSearch }
  const filteredFolders = React.useMemo(
    () =>
      pipe(
        folders,
        folders => matchSorter(folders, normString(search), { keys: ["name"] }),
        sortBy
      ) as MediasFolder[],
    [folders, search, sortBy]
  )
  const filteredFiles = React.useMemo(
    () =>
      pipe(
        files,
        files => matchSorter(files, normString(search), { keys: [item => t(item).name] }),
        sortBy
      ) as MediasFile[],
    [files, search, sortBy, t]
  )
  const clearAllFilters = () => setSearch("")
  return { sortable, matchable, clearAllFilters, files, folders, filteredFolders, filteredFiles }
}
