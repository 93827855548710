import { Empty, Grid, LimitMore, Selection, Toolbar } from "@/components/collection"
import { PageContent } from "@/components/layout/dashboard"
import { useFilteredPlaces } from "@/store/places/hooks"
import { Eye, EyeOff, FilterX } from "lucide-react"
import { usePageContext } from "./Context"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/places.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "places")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { places, filtered, matchable, sortable, filterable, limitable, limit } =
    useFilteredPlaces()
  const { view, setView, selected, setSelected, ...ctx } = usePageContext()

  return (
    <PageContent>
      <Selection
        selected={selected}
        deleteSelection={ctx.confirmDeleteSelection}
        publishSelection={ctx.confirmPublishSelection}
        unpublishSelection={ctx.confirmUnpublishSelection}
        unselect={() => setSelected([])}
      />
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Toolbar.Button onClick={ctx.create}>{_("create")}</Toolbar.Button>
        <Filters {...filterable} />
        <Toolbar.Sort {...sortable} dictionary={dictionary("sort")} />
        <Toolbar.View {...{ view, setView }} />
      </Toolbar>
      <Empty
        items={places}
        results={filtered}
        create={ctx.create}
        clear={() => {
          filterable.clear()
          matchable.setSearch("")
          setSelected([])
        }}
      />
      <Grid view={view}>
        {A.map(limit(filtered), place => (
          <Item key={place.id} place={place} />
        ))}
      </Grid>
      <LimitMore {...limitable} />
    </PageContent>
  )
}

/**
 * Filters
 */
const Filters: React.FC<ReturnType<typeof useFilteredPlaces>["filterable"]> = ({
  isActive,
  toggleActive,
  toggleInactive,
  isInactive,
  reset,
}) => {
  const { _ } = useDictionary(dictionary("filters"))
  const { setSelected } = usePageContext()

  return (
    <Toolbar.Filters onClick={() => setSelected([])}>
      <Toolbar.Filters.Item
        onClick={() => toggleActive("published")}
        active={isActive("published")}
      >
        <Eye aria-hidden />
        {_("published")}
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item
        onClick={() => toggleInactive("published")}
        active={isInactive("published")}
      >
        <EyeOff aria-hidden />
        {_("unpublished")}
      </Toolbar.Filters.Item>

      <Toolbar.Filters.Separator />
      <Toolbar.Filters.Item onClick={reset}>
        <FilterX aria-hidden />
        {_("reset")}
      </Toolbar.Filters.Item>
    </Toolbar.Filters>
  )
}
