import { Button } from "@/components/ui/button"
import { DropdownMenu } from "@/components/ui/dropdown-menu"

import { RangeName } from "../types"
import { Tooltip } from "@/components/ui/tooltip"

/**
 * dictionary src/dictionaries/en/components/trackings.json
 */
const dictionary = createContextMapper("components", "trackings", "range")

/**
 * RangeSelector
 */
export const RangeSelector: React.FC<{
  range: RangeName
  setRange: (range: RangeName) => void
}> = ({ range, setRange }) => {
  const { _ } = useDictionary(dictionary())
  const list: RangeName[] = ["7days", "1month", "3months", "6months", "12months", "all"]
  return (
    <div className="flex gap-4 items-center">
      <DropdownMenu>
        <Tooltip content={_("tooltip")} side="bottom">
          <DropdownMenu.Trigger asChild>
            <Button variant="outline" size="xs">
              {_(range)}
            </Button>
          </DropdownMenu.Trigger>
        </Tooltip>
        <DropdownMenu.Content align="end" side="bottom" className="w-56">
          {A.map(list, item => (
            <DropdownMenu.Item onClick={() => setRange(item)} active={range === item} key={item}>
              {_(item)}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu>
    </div>
  )
}
