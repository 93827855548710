import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { formatDurationForExcel } from "@/fns/Date"
import { useFilterable } from "@/hooks/useFilterable"
import { usePaginable } from "@/hooks/usePaginable"
import { useMatchable } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"
import { download, generateCsv, mkConfig } from "export-to-csv"
import { v4 as uuid } from "uuid"
import { useWorkshopsStore } from "."
import { usePlacesById } from "../places/hooks"
import { Place } from "../places/localizers"
import { getFullname } from "../users/helpers"
import { useUsersById } from "../users/hooks"
import { Workshop, WorkshopEvent, WorkshopReservation } from "./localizers"

/**
 * workshops hooks
 */
export const useWorkshopsById = () => useWorkshopsStore(D.getUnsafe("workshops"))
export const useWorkshops = () => {
  const byIds = useWorkshopsById()
  return React.useMemo(() => D.values(byIds), [byIds])
}
export const useWorkshop = (id: Option<string>) =>
  useWorkshopsStore(flow(D.getUnsafe("workshops"), D.get(id ?? "")))

export const useWorkshopsByPlace = (id: Option<string>) =>
  useWorkshopsStore(
    flow(
      D.getUnsafe("workshops"),
      D.values,
      A.filter(workshop => workshop.placeId === id)
    )
  )
/**
 * workshops collection
 */
export const useFilteredWorkshops = (placeId: Option<string> = null, page = 1, byPage = 10) => {
  const workshops = useWorkshops()

  const [filterable, filterBy] = useFilterable<Workshop>(
    "workshops",
    {
      archived: ({ archived }) => archived,
      published: ({ published }) => published,
    },
    { archived: false }
  )

  const [matchable, matchIn] = useMatchable<Workshop>(["name"])

  const [sortable, sortBy] = useSortable<Workshop>(
    "workshops",
    {
      name: ({ name }) => name,
    },
    "name"
  )

  const filtered = React.useMemo(
    () =>
      pipe(
        workshops,
        A.filter(workshops => (G.isNotNullable(placeId) ? placeId === workshops.placeId : true)),
        filterBy,
        S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn
      ),
    [workshops, placeId, filterBy, matchable.search, matchIn, sortBy]
  )
  const [paginable, paginated] = usePaginable(filtered, page, byPage)

  return { workshops, filtered, filterable, matchable, sortable, paginable, paginated }
}

/**
 * reservations hooks
 */
export const useReservationsById = () => useWorkshopsStore(D.getUnsafe("reservations"))
export const useReservations = () => {
  const byIds = useReservationsById()
  return React.useMemo(() => D.values(byIds), [byIds])
}
export const useOldReservations = () => {
  return useWorkshopsStore(({ reservations }) =>
    A.filterMap(D.values(reservations), ({ id, archived, createdAt }) => {
      if (archived) return O.None
      return T.isBefore(createdAt, T.subMonths(new Date(), 6)) ? id : O.None
    })
  )
}
export const useReservationsByPlaceId = () => {
  const workshopsById = useWorkshopsById()
  const reservations = useReservations()
  return React.useMemo(() => {
    return A.reduce(
      reservations,
      {} as Record<string, WorkshopReservation[]>,
      (byPlaceId, reservation) => {
        const workshop = D.get(workshopsById, reservation.workshopId)
        if (G.isNullable(workshop)) return byPlaceId
        return D.set(byPlaceId, workshop.placeId, [
          ...(D.get(byPlaceId, workshop.placeId) ?? []),
          reservation,
        ])
      }
    )
  }, [workshopsById, reservations])
}
export const useReservationsByPlace = (placeId: string) => {
  const workshops = useWorkshops()
  const reservations = useReservations()
  return React.useMemo(() => {
    const placeWorkshops = A.filterMap(workshops, workshop =>
      workshop.placeId === placeId ? workshop.id : O.None
    )
    return A.filter(reservations, ({ workshopId }) => A.includes(placeWorkshops, workshopId))
  }, [placeId, workshops, reservations])
}
export const useReservationsByWorkshop = (workshopId: string) => {
  const reservations = useReservations()
  return React.useMemo(
    () => A.filter(reservations, reservation => reservation.workshopId === workshopId),
    [reservations, workshopId]
  )
}

/**
 * reservations collection
 */
export const useFilteredReservations = (placeId: string, page = 1, byPage = 10) => {
  const reservations = useReservationsByPlace(placeId)
  const workshopsByIds = useWorkshopsById()
  const usersById = useUsersById()

  const [filterable, filterBy] = useFilterable<WorkshopReservation>(
    `${placeId}-reservations`,
    {
      archived: ({ archived }) => archived,
      canceled: ({ canceled }) => canceled,
    },
    { archived: false }
  )

  const [matchable, matchIn] = useMatchable<WorkshopReservation>([
    "language",
    ({ workshopId }) => D.get(workshopsByIds, workshopId)?.name ?? "",
    ({ userId }) => (D.get(usersById, userId) ? getFullname(D.getUnsafe(usersById, userId)) : ""),
  ])

  const [sortable, sortBy] = useSortable<WorkshopReservation>(
    `${placeId}-reservations`,
    {
      createdAt: ({ createdAt }) => createdAt,
      name: ({ workshopId }) => D.get(workshopsByIds, workshopId)?.name ?? "",
      students: ({ students }) => students,
    },
    "createdAt"
  )

  const filtered = React.useMemo(
    () => pipe(reservations, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [reservations, filterBy, matchable.search, matchIn, sortBy]
  )

  const [paginable, paginated] = usePaginable(filtered, page, byPage)

  return {
    reservations,
    filtered,
    paginated,
    sortable,
    matchable,
    filterable,
    paginable,
  }
}

/**
 * events hooks
 */
export const useEventsById = () => useWorkshopsStore(D.getUnsafe("events"))
export const useEvents = () => {
  const byIds = useEventsById()
  return React.useMemo(() => D.values(byIds), [byIds])
}
export const useEvent = (id: Option<string>) =>
  useWorkshopsStore(flow(D.getUnsafe("events"), D.get(id ?? "")))
export const useEventsByWorkshopId = (workshopId: string) => {
  const events = useEvents()
  return React.useMemo(
    () => A.filter(events, event => event.workshopId === workshopId),
    [events, workshopId]
  )
}
export const useEventsByPlace = (placeId: string) => {
  return useWorkshopsStore(({ events, workshops }) =>
    pipe(
      events,
      D.values,
      A.filter(event => D.get(workshops, event.workshopId)?.placeId === placeId)
    )
  )
}

/**
 * events collection
 */
export const useFilteredWorkshopEvents = (workshopId: string, page = 1, byPage = 10) => {
  const format = useDateFnsLocaleFormat()
  const events = useEventsByWorkshopId(workshopId)

  const [filterable, filterBy] = useFilterable<WorkshopEvent>(
    `${workshopId}-events`,
    {
      full: ({ reservationsSlot, reservationsDone }) => reservationsSlot <= reservationsDone,
    },
    {}
  )

  const [matchable, matchIn] = useMatchable<WorkshopEvent>([
    ({ datetime }) => format(datetime, "P"),
    ({ datetime }) => format(datetime, "PP"),
    ({ datetime }) => format(datetime, "PPP"),
    ({ datetime }) => format(datetime, "PPPP"),
    ({ datetime }) => format(datetime, "p"),
  ])

  const [sortable, sortBy] = useSortable<WorkshopEvent>(
    `${workshopId}-events`,
    {
      datetime: ({ datetime }) => datetime,
      booking: ({ reservationsSlot, reservationsDone }) =>
        Math.floor((100 / reservationsSlot) * reservationsDone),
    },
    "datetime"
  )

  const filtered = React.useMemo(
    () => pipe(events, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [events, filterBy, matchable.search, matchIn, sortBy]
  )

  const [paginable, paginated] = usePaginable(filtered, page, byPage)

  return {
    events,
    filtered,
    filterable,
    sortable,
    matchable,
    paginable,
    paginated,
  }
}

/**
 * exports to excel
 */
export const useExportReservations = (name: string, reservations?: WorkshopReservation[]) => {
  const allReservations = useReservations()
  const workshops = useWorkshopsById()
  const places2 = usePlacesById()
  const events = useEventsById()
  return React.useCallback(() => {
    const formatedReservations = A.map(
      reservations ?? allReservations,
      (reservation: WorkshopReservation) =>
        formatReservation(reservation, events, workshops, places2)
    )
    const csvConfig = mkConfig({
      useKeysAsHeaders: true,
      filename: `vdp-${name}-${uuid()}.csv`,
    })
    const csvData = generateCsv(csvConfig)(formatedReservations)
    download(csvConfig)(csvData)
  }, [reservations, allReservations, name, events, workshops, places2])
}

const formatReservation = (
  reservation: WorkshopReservation,
  events: ById<WorkshopEvent>,
  workshops: ById<Workshop>,
  places: ById<Place>
) => {
  const workshop = workshops[reservation.workshopId] as Workshop
  const place = places[workshop.placeId] as Place
  const event = events[reservation.eventId] as WorkshopEvent
  return {
    id: reservation.id,
    workshopName: workshop.name,
    workshoptDate: event.datetime.toISOString(),
    workshopDuration: formatDurationForExcel(workshop.duration),
    placeName: place.name,
    placeRegion: place.map as string,
    canceled: reservation.canceled ? "true" : "false",
    language: reservation.language,
    students: S.make(reservation.students),
    schoolName: reservation.contact?.schoolName ?? "",
    schoolCity: reservation.contact?.schoolCity ?? "",
    schoolClass: reservation.contact?.schoolClass ?? "",
  }
}
