import { isFile, isSynteticFile } from "@/components/form"
import { AuthErrorCode, NoContent, api } from "@/services/api"
import { z } from "zod"
import { apiExtraField, synteticFile } from "../commons/schemas"
import {
  ApiProject,
  apiProject,
  apiProjectCategory,
  apiProjectOrientation,
  apiProjectStatus,
} from "./schemas"

/**
 * schemas payload
 */
const createPayload = z.object({
  name: z.string(), // max 100
  language: z.string(), // max 20
  category: apiProjectCategory,
  orientation: apiProjectOrientation,
  partners: apiExtraField.array(),
  classes: apiExtraField.array(),
  description: z.string(), // maxlength 255
  skills: apiExtraField.array(),
  objective: z.string(), // maxlength 4294967295
  materials: z.string().array(),
  preparation: z.string(), // maxlength 4294967295
  follow: z.string(), // maxlength 4294967295
  values: z.string(), // maxlength 4294967295
  image: z.object({ file: z.any() }),
  documents: z.any().array(),
  images: z.any().array(),
})

const updatePayload = z.object({
  status: apiProjectStatus.optional(),
  name: z.string().optional(), // max 100
  language: z.string().optional(), // max 20
  category: apiProjectCategory.optional(),
  orientation: apiProjectOrientation.optional(),
  partners: apiExtraField.array().optional(),
  classes: apiExtraField.array().optional(),
  description: z.string().optional(), // maxlength 255
  skills: apiExtraField.array().optional(),
  objective: z.string().optional(), // maxlength 4294967295
  materials: z.string().array().optional(),
  preparation: z.string().optional(), // maxlength 4294967295
  follow: z.string().optional(), // maxlength 4294967295
  values: z.string().optional(), // maxlength 4294967295
  image: z
    .object({
      file: z.any(),
      delete: z.boolean(),
    })
    .optional(),
  documents: z.union([z.any(), synteticFile]).array().optional(),
  images: z.union([z.any(), synteticFile]).array().optional(),
})
export type Payload = {
  create: z.infer<typeof createPayload>
  update: z.infer<typeof updatePayload>
}

/**
 * service
 */
export const service = {
  index: async () => {
    type RSuccess = { projects: ApiProject[] }
    type RError = AuthErrorCode
    const { success, data } = await api.get<RSuccess, RError>("projects")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { projects: A.map(data.projects, apiProject.parse) }, error: false } as const
  },
  create: async (payload: Payload["create"]) => {
    type RSuccess = { project: ApiProject }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { image, documents, images, ...rest } = createPayload.parse(payload)
    const response = await api.post<RSuccess, RError>("projects", {
      data: rest,
    })
    if (!response.success) return { data: null, error: true, code: response.data.code } as const
    // uppload attachments
    const { success, data } = await api.put<RSuccess, RError>(
      `projects/${response.data.project.id}`,
      {
        form: {
          documents: A.isNotEmpty(documents) ? documents : undefined,
          images: A.isNotEmpty(images) ? images : undefined,
          image: image.file,
        },
      }
    )
    if (!success)
      return {
        data: { project: apiProject.parse(response.data.project) },
        error: true,
        code: data.code,
      } as const
    return { data: { project: apiProject.parse(data.project) }, error: false } as const
  },
  read: async (id: string) => {
    type RSuccess = { project: ApiProject }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`projects/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { project: apiProject.parse(data.project) }, error: false } as const
  },
  update: async (id: string, payload: Payload["update"]) => {
    type RSuccess = { project: ApiProject }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { documents, images, image, ...rest } = updatePayload.parse(payload)

    const documentsSync = documents
      ? A.filterMap(documents, file => (isSynteticFile(file) && !file.delete ? file.id : O.None))
      : undefined
    const imagesSync = images
      ? A.filterMap(images, file => (isSynteticFile(file) && !file.delete ? file.id : O.None))
      : undefined

    const { success, data } = await api.put<RSuccess, RError>(`projects/${id}`, {
      data: {
        ...rest,
        image: image?.delete ? null : undefined,
        documentsSync,
        imagesSync,
      },
    })
    if (!success) return { data: null, error: true, code: data.code } as const

    const imageFiles = A.filterMap(images ?? [], file => (isFile(file) ? file : O.None))
    const documentFiles = A.filterMap(documents ?? [], file => (isFile(file) ? file : O.None))
    const form = {
      images: A.isNotEmpty(imageFiles) ? imageFiles : undefined,
      documents: A.isNotEmpty(documentFiles) ? documentFiles : undefined,
      image: image?.file,
    }
    if (A.some(D.values(form), G.isNotNullable)) {
      const { success, data } = await api.put<RSuccess, RError>(`projects/${id}`, { form })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { project: apiProject.parse(data.project) }, error: false } as const
    }

    return { data: { project: apiProject.parse(data.project) }, error: false } as const
  },
  delete: async (id: string) => {
    type RSuccess = NoContent
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.delete<RSuccess, RError>(`programs/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: {}, error: false } as const
  },
  latest: async () => {
    type RSuccess = { projects: ApiProject[] }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`projects/lastest`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { projects: A.map(data.projects, apiProject.parse) }, error: false } as const
  },
}
