import { Container } from "../layout/frontend/container"
import { Wrapper } from "../layout/frontend/wrapper"

/**
 * FooterPicture
 */
export const FooterPicture: React.FC<{ image: React.ReactNode; className?: ClassName }> = ({
  image,
  className,
}) => {
  return (
    <Wrapper className="pointer-events-none" aria-hidden>
      <Container
        x="sm"
        className={cx(
          "flex items-center my-5",
          "[&>svg]:fill-tomato [&>svg]:w-full [&>svg]:max-w-xs sm:[&>svg]:max-w-sm md:[&>svg]:max-w-md lg:[&>svg]:max-w-lg xl:[&>svg]:max-w-xl md:[&>svg]:-ml-5",
          className
        )}
      >
        {image}
      </Container>
    </Wrapper>
  )
}
