import { Dialog } from "@/components/ui/dialog"
import { UseDialogProps } from "@/components/ui/hooks/useDialog"
import { ContentItem } from "@/store/contents/localizers"
import { ItemFormDispatcher } from "../components/content"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")

/**
 * UpdateSeoDialogs
 */
export const UpdateItemDialog: React.FC<UseDialogProps<ContentItem>> = ({
  open,
  onOpenChange,
  item,
}) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={_("update-item-dialog.title", {
        name: item ? _(`content.items.${item.type}.title`) : "",
      })}
      description={item ? _(`content.items.${item.type}.description`) : undefined}
      className="sm:max-w-xl"
    >
      {item !== false && <ItemFormDispatcher item={item} close={() => onOpenChange(false)} />}
    </Dialog>
  )
}
