import {
  FormFieldWrapper,
  FormFieldWrapperProps,
  inputIconVariants,
  inputVariants,
  useFieldContext,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Image } from "@/components/ui/image"
import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { searchCompare } from "@/fns/search"
import { useProjectsById } from "@/store/projects/hooks"
import { Project } from "@/store/projects/localizers"
import { Combobox } from "@headlessui/react"
import { ChevronDown, ImageIcon, X } from "lucide-react"

/**
 * FormSelectArticles
 */
type Props = SelectInputProps & FormFieldWrapperProps
export const FormSelectProjects: React.FC<Props> = ({
  label,
  labelAside,
  translatable,
  name,
  info,
  disabled,
  ...props
}) => (
  <FormFieldWrapper {...{ label, labelAside, translatable, name, info }}>
    <FormSelectProjectsInput {...props} disabled={disabled} />
  </FormFieldWrapper>
)

type SelectInputProps = {
  placeholder?: string
  className?: ClassName
  disabled?: boolean
  max?: number
}
const FormSelectProjectsInput: React.FC<SelectInputProps> = ({
  className,
  placeholder,
  max = Infinity,
  ...props
}) => {
  const { value, setFieldValue, disabled } = useFieldContext<string[]>()
  const projects = useProjectsById()
  const options = React.useMemo(
    () =>
      A.filterMap(D.values(projects), project =>
        project.status === "accepted"
          ? {
              label: project.name,
              value: project.id,
            }
          : O.None
      ),
    [projects]
  )
  const [inputValue, setInputValue] = React.useState("")
  const filtered = React.useMemo(
    () =>
      S.isEmpty(inputValue)
        ? A.reject(options, option => A.includes(value, option.value))
        : A.filter(
            options,
            option => A.includes(value, option.value) && searchCompare(inputValue, option.label)
          ),
    [value, inputValue, options]
  )
  const inputRef = React.useRef<HTMLInputElement>(null)
  return (
    <div className="flex flex-col">
      <Combobox
        value={value}
        onChange={setFieldValue}
        multiple
        disabled={disabled || props.disabled || value.length >= max}
      >
        {({ open }) => (
          <>
            <div className="relative">
              <Combobox.Input
                onChange={({ target }) => setInputValue(target.value)}
                placeholder={placeholder}
                className={inputVariants({ icon: "right", size: "default", className })}
                ref={inputRef}
              />
              <Combobox.Button
                className={inputIconVariants({ side: "right", size: "default", className: "" })}
              >
                <ChevronDown
                  size={16}
                  className={cx("opacity-50 transition-transform", open && "rotate-180")}
                />
              </Combobox.Button>
              {open && value.length < max && A.isNotEmpty(filtered) && (
                <Combobox.Options
                  className={cx(
                    "absolute top-full inset-x-0 z-50 w-full max-h-48 p-1 overflow-hidden",
                    "rounded-md border border-input shadow-md bg-popover text-popover-foreground",
                    "slide-in-from-top-2",
                    open ? "animate-in fade-in-0 zoom-in-95" : "animate-out fade-out-0 zoom-out-95"
                  )}
                >
                  {A.map(filtered, option => (
                    <Combobox.Option key={option.value} value={option.value}>
                      {({ active }) => (
                        <FormSelectProjectsItem
                          project={D.getUnsafe(projects, option.value)}
                          active={active}
                        />
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
            {A.isNotEmpty(value) && (
              <div className="flex flex-col gap-2 pt-2">
                {A.filterMap(value, id => {
                  const project = D.get(projects, id)
                  if (G.isNullable(project)) return O.None
                  return (
                    <FormSelectProjectsItem
                      project={project}
                      key={project.id}
                      remove={() => {
                        setFieldValue(A.reject(value, id => id === project.id))
                        inputRef.current?.focus()
                      }}
                    />
                  )
                })}
              </div>
            )}
          </>
        )}
      </Combobox>
    </div>
  )
}

const FormSelectProjectsItem = React.forwardRef<
  HTMLDivElement,
  { project: Project; active?: boolean; remove?: () => void }
>(({ project, active, remove }, ref) => {
  const { _ } = useDictionary(contextMapper("pages", "dashboard", "projects"))
  const format = useDateFnsLocaleFormat()
  return (
    <div
      className={cx(
        "flex items-center w-full  py-4 gap-4",
        active ? "bg-accent" : "bg-background",
        G.isNotNullable(remove) ? "pl-2" : "px-2"
      )}
      ref={ref}
    >
      <Image
        src={project.image?.url}
        alt={project.name}
        className="size-8 shrink-0 rounded-full object-cover"
      >
        <ImageIcon size={12} className="text-foreground/50" aria-hidden />
      </Image>
      <div className="flex flex-col grow gap-1">
        <h3 className="text-base font-medium leading-none tracking-tight line-clamp-1">
          {project.name}
        </h3>
        <p className="text-xs font-light text-muted-foreground line-clamp-1">
          {_("created-at")} {format(project.createdAt, "PP")}
        </p>
      </div>
      {G.isNotNullable(remove) && (
        <Button variant="ghost" size="xs" className="m-1" icon onClick={remove}>
          <X aria-hidden />
        </Button>
      )}
    </div>
  )
})
