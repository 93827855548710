import { useForm, validator } from "@/components/form"
import { useDictionary } from "@/dictionaries/hooks"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import {
  projectCategories,
  projectLanguages,
  projectOrientations,
} from "@/services/projects/schemas"
import { Project } from "@/store/frontend/localizers"
import { dictionary } from "."

/**
 * useInformationForm
 */
export const useInformationForm = (project: Option<Project> = null) => {
  const { _, language } = useDictionary(dictionary("form"))
  const { min } = validator
  return useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() =>
      project
        ? {
            ...D.selectKeys(project, basicInformationValues),
            image: { url: project.image?.url ?? "", file: null, delete: false },
          }
        : {
            name: "",
            language: A.find([...projectLanguages], F.equals(language)) ?? projectLanguages[0],
            category: projectCategories[0],
            orientation: projectOrientations[0],
            partners: [{ name: _("partner-name-default"), value: _("partner-value-default") }],
            classes: [{ name: _("class-name-default"), value: _("class-value-default") }],
            description: "",
            image: { url: null, file: null, delete: false },
          }
    ),
    validate: validator({
      name: [min(1, _("name-required"))],
      image: [(field: any) => (G.isNullable(field.file) ? _("image-required") : undefined)],
      description: [min(1, _("description-required"))],
    }),
    onSubmit: F.ignore,
  })
}
export const basicInformationValues = [
  "name",
  "language",
  "category",
  "orientation",
  "partners",
  "classes",
  "description",
] as const
