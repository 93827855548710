import { toggleVariants } from "@/components/ui/toggle"
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group"
import { VariantProps } from "class-variance-authority"
import {
  FormFieldWrapper,
  FormFieldWrapperProps,
  extractInputProps,
  extractWrapperProps,
  useFieldContext,
} from "."
import { ToggleGroup, ToggleGroupItem } from "../ui/toggle-group"

/**
 * FormToggle
 */
type Props = ToggleInputProps & FormFieldWrapperProps
export const FormToggle: React.FC<Props> = ({ options, ...props }) => (
  <FormFieldWrapper {...extractWrapperProps(props)}>
    <ToggleInput {...extractInputProps(props)} options={options} />
  </FormFieldWrapper>
)

type ToggleInputProps = Omit<
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root>,
  "type" | "onValueChange" | "value" | "defaultValue"
> &
  VariantProps<typeof toggleVariants> & {
    options: FormToggleOption[]
  }
const ToggleInput: React.FC<ToggleInputProps> = ({ className, disabled, options, ...props }) => {
  const { value, setFieldValue, disabled: ctxDisabled } = useFieldContext<string[]>()
  return (
    <ToggleGroup
      type="multiple"
      className={className}
      onValueChange={setFieldValue}
      value={value}
      disabled={ctxDisabled || disabled}
      {...props}
    >
      {A.mapWithIndex(options, (index, { label, ...props }) => (
        <ToggleGroupItem {...props} key={index}>
          {label}
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  )
}

/**
 * types
 */
export type FormToggleOption = React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> & {
  label: React.ReactNode
}
