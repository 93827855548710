import {
  Form,
  FormAssertive,
  FormInput,
  FormSubmit,
  FormSwitch,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { useIsSuperadmin } from "@/store/auth/hooks"
import { updateMediasFolder } from "@/store/medias/actions"
import { MediasFolder } from "@/store/medias/localizers"
import { match } from "ts-pattern"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * EditFolderDialog
 */
export const EditFolderDialog: React.FC<UseDialogProps<MediasFolder>> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary("edit-folder-dialog"))
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<MediasFolder>> = ({ item: folder, onOpenChange }) => {
  const { _ } = useDictionary(dictionary("edit-folder-dialog"))
  const _error = useErrorsDictionary()
  const _form = useFormDictionary()

  const { min } = validator
  const isSuperadmin = useIsSuperadmin()

  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => D.selectKeys(folder, ["name", "lock"])),
    validate: validator({
      name: [min(1, _("name-required"))],
    }),
    onSubmit: async ({ values: { name, lock } }, event) => {
      event?.stopPropagation()
      if (!form.isValid) return _error("VALIDATION_FAILURE")
      const payload = { name, lock: isSuperadmin ? lock : undefined }
      return match(await updateMediasFolder(folder.id, payload))
        .with({ error: false }, () => {
          toast.success(_("success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _error)
            .with("RESOURCE_NOT_FOUND", code => {
              onOpenChange(false)
              toast.error(_error(code))
            })
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_error(code)))
        )
    },
  })

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormInput label={_("name-label")} name="name" placeholder={_("name-placeholder")} />
      {isSuperadmin && <FormSwitch label={_("lock-label")} name="lock" />}
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
