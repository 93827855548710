import { Button } from "@/components/frontend/button"
import { Prose } from "@/components/frontend/prose"
import { HN } from "@/components/ui/hn"
import { Image } from "@/components/ui/image"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { useTranslation } from "@/store/languages/hooks"
import { getPreview } from "@/store/medias/helpers"
import { ImageOff } from "lucide-react"
import { Header, ItemMappingExport, getMediasFile } from "."
import { Container } from "../container"
import { Wrapper } from "../wrapper"

type RenderFC = ItemMappingExport<"cards">["Render"]

export const Render: RenderFC = props => {
  const { item } = props
  const t = useTranslation()
  const { titleLevel, cards } = item.props
  const { title, secondary } = t(item).props

  return (
    <Wrapper margin="normal">
      <Container x="sm" className="flex flex-col gap-4">
        <Header {...{ title, secondary, titleLevel }} />
        <div
          className={cx(
            "grid gap-8",
            A.includes([1, 2, 4], cards.length) ? "grid-cols-2" : "grid-cols-3"
          )}
        >
          {A.map(cards, id => (
            <Template1 {...props} id={id} key={id} />
          ))}
        </div>
      </Container>
    </Wrapper>
  )
}

/**
 * templates
 */
type TemplateProps = React.ComponentProps<ItemMappingExport<"cards">["Render"]> & { id: string }
const Template1: React.FC<TemplateProps> = ({ item, id, files }) => {
  const t = useTranslation()
  const { titleLevel } = item.props
  const props = D.get(item.props.cardsProps, id)
  const translation = D.get(t(item).props.cards, id)

  const image = getMediasFile(files, props?.image)

  if (G.isNullable(props) || G.isNullable(translation)) return null
  const { title, secondary, content, linkText, linkUrl } = translation

  return (
    <article className="relative flex flex-col items-stretch rounded-md bg-white text-gray-800 shadow-md">
      <div className="relative flex aspect-video rounded-t-md overflow-hidden">
        <Image
          src={getPreview(image)}
          alt={image ? t(image).alt : title}
          className="absolute inset-0 size-full object-cover rounded-t-md text-muted-foreground"
        >
          <ImageOff size={64} strokeWidth={1} />
        </Image>
        <div
          className="absolute inset-0 size-full bg-gradient-to-b from-[#002c41]/20 from-5% to-[#002c41]/80 to-80%"
          aria-hidden
        />
        {oneIsNotEmpty(title, secondary) && (
          <div className="relative flex flex-col justify-stretch size-full p-4">
            <div className="grow">
              {oneIsNotEmpty(secondary) && (
                <p className="inline-flex min-h-[1rem] mx-auto px-4 py-2 rounded-full backdrop-blur-sm bg-frontend-bluewhale/40 uppercase text-white text-xs leading-none font-semibold">
                  {secondary}
                </p>
              )}
            </div>
            {oneIsNotEmpty(title) && (
              <HN level={titleLevel + 1} className="text-white text-xl font-bold">
                {title}
              </HN>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-stretch items-stretch gap-4 p-4">
        {oneIsNotEmpty(stripeHtml(content)) && (
          <Prose
            className="max-w-xl -my-4"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        )}
        {S.isNotEmpty(linkText) && S.isNotEmpty(linkUrl) && (
          <div className="fle justify-start">
            <Button href={linkUrl} variant="dark" size="sm" className="text-sm">
              {linkText}
            </Button>
          </div>
        )}
      </div>
    </article>
  )
}
