import { type ILocale } from "locale-codes"

/**
 * byLanguage
 */
export const byLanguage = <L extends { languageId: string }, R = void>(
  items: L[],
  localize?: (item: L) => R
): { [key: string]: R extends void ? L : R } => {
  return items.reduce((acc, item) => {
    return { ...acc, [item.languageId]: localize ? localize(item) : item }
  }, {})
}

/**
 * compareCodeToLocale
 * returns true if the code matches the locale
 */
export const compareCodeToLocale = (code: string, locale: ILocale) => {
  const splited = S.split(locale.tag, "-")
  const localeCode = N.gte(splited.length, 2) ? A.getUnsafe(splited, 0).toUpperCase() : undefined
  if (G.isUndefined(localeCode)) return false
  return localeCode === code.toUpperCase()
}
