import { BaseLayoutProvider } from "../context"
import { LayoutContextProvider } from "./context"
import { Footer } from "./footer"
import { Header } from "./header"
import { ProgressBar } from "./progress-bar"

/**
 * LayoutFrontend
 */
const LayoutFrontend: React.FC<React.PropsWithChildren> = ({ children }) => {
  //
  return (
    <BaseLayoutProvider isFrontend>
      <LayoutContextProvider>
        <ProgressBar />
        <div className="flex flex-col min-h-screen font-plus bg-white">
          <Header />
          <main className="relative grow animate-appear-opacity [&>:first-child]:mt-5">
            {children}
          </main>
          <Footer />
        </div>
      </LayoutContextProvider>
    </BaseLayoutProvider>
  )
}
export default LayoutFrontend
