import { StatsByDays } from "@/components/kulturrallye/Stats"
import { CounterHighlight } from "@/components/trackings/components/counter-highlight"
import { Card } from "@/components/ui/card"
import { useLiveState } from "@/hooks/useLiveState"
import { getWorkshops } from "@/store/workshops/actions"
import { useReservations } from "@/store/workshops/hooks"

/**
 * dictionary src/dictionaries/en/pages/dashboard/index.json
 */
const dictionary = createContextMapper("pages", "dashboard", "index")

/**
 * ReservationsStats
 */
export const ReservationsStats: React.FC = () => {
  const { _ } = useDictionary(dictionary("reservations-stats"))
  useLiveState(getWorkshops)
  const reservations = useReservations()
  const interval = React.useMemo(() => {
    const now = new Date()
    return { from: T.sub(now, { days: 7 }), to: now }
  }, [])

  const today = React.useMemo(() => {
    return A.filter(reservations, reservation => T.isSameDay(reservation.createdAt, new Date()))
      .length
  }, [reservations])

  const last7Days = React.useMemo(() => {
    return A.filter(reservations, reservation =>
      T.isAfter(reservation.createdAt, T.sub(new Date(), { days: 7 }))
    ).length
  }, [reservations])

  const lastMonth = React.useMemo(() => {
    return A.filter(reservations, reservation =>
      T.isAfter(reservation.createdAt, T.sub(new Date(), { months: 1 }))
    ).length
  }, [reservations])

  return (
    <Card.Spotlight className="grid grid-rows-[auto_1fr]">
      <Card.Header>
        <Card.Title>{_("title")}</Card.Title>
        <Card.Description>{_("secondary")}</Card.Description>
      </Card.Header>
      <Card.Content className="flex flex-col justify-end gap-6">
        <div className="w-full aspect-video">
          <StatsByDays
            hideAxis
            hideGrid
            strokeWidth={3}
            color="hsl(var(--primary))"
            interval={interval}
            reservations={reservations}
          />
        </div>
        <div className="grid grid-cols-3 gap-6">
          <CounterHighlight counter={today} description={_("today")} color="hsl(var(--primary))" />
          <CounterHighlight
            counter={last7Days}
            description={_("last-7-days")}
            color="hsl(var(--primary))"
          />
          <CounterHighlight
            counter={lastMonth}
            description={_("last-month")}
            color="hsl(var(--primary))"
          />
        </div>
      </Card.Content>
    </Card.Spotlight>
  )
}
