import { api, AuthErrorCode } from "@/services/api"
import { z } from "zod"
import { ApiProgram, apiProgram } from "./schemas"

/**
 * schemas payload
 */
const createPayload = z.object({
  name: z.string(),
  language: z.string(),
  code: z.string(),
  class: z.string(),
  programs: z.string().optional(),
  books: z.string().optional(),
  pearltrees: z.string().optional(),
  archived: z.boolean().optional(),
})
const updatePayload = z.object({
  name: z.string().optional(),
  language: z.string().optional(),
  code: z.string().optional(),
  class: z.string().optional(),
  programs: z.string().optional(),
  books: z.string().optional(),
  pearltrees: z.string().optional(),
  archived: z.boolean().optional(),
})
export type Payload = {
  create: z.infer<typeof createPayload>
  update: z.infer<typeof updatePayload>
}

/**
 * service
 */
export const service = {
  index: async () => {
    type RSuccess = { programs: ApiProgram[] }
    type RError = AuthErrorCode
    const { success, data } = await api.get<RSuccess, RError>("programs")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { programs: A.map(data.programs, apiProgram.parse) }, error: false } as const
  },
  create: async (payload: Payload["create"]) => {
    type RSuccess = { program: ApiProgram }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.post<RSuccess, RError>("programs", {
      data: createPayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { program: apiProgram.parse(data.program) }, error: false } as const
  },
  read: async (id: string) => {
    type RSuccess = { program: ApiProgram }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`programs/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { program: apiProgram.parse(data.program) }, error: false } as const
  },
  update: async (id: string, payload: Payload["update"]) => {
    type RSuccess = { program: ApiProgram }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.put<RSuccess, RError>(`programs/${id}`, {
      data: updatePayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { program: apiProgram.parse(data.program) }, error: false } as const
  },
  delete: async (id: string) => {
    type RSuccess = { program: ApiProgram }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.delete<RSuccess, RError>(`programs/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: {}, error: false } as const
  },
}
