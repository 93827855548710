import {
  Form,
  FormAssertive,
  FormInput,
  FormSubmit,
  FormTranslationTabs,
  useForm,
} from "@/components/form"
import { FormKeywords } from "@/components/form/keywords"
import { FormTextarea } from "@/components/form/textarea"
import { FormMediasImage } from "@/components/medias/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { useCmsContext } from "../Context"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")

/**
 * UpdateSeoDialog
 */
export const UpdateSeoDialog: React.FC<UseDialogProps<void>> = props => {
  const { _ } = useDictionary(dictionary("update-seo-dialog"))
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-xl">
      {props.open && <DialogForm {...props} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<void>> = ({ onOpenChange }) => {
  const { _ } = useDictionary(dictionary("update-seo-dialog"))
  const {
    id,
    seo,
    actions: { updateSeo },
  } = useCmsContext()
  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      translations: D.map(languagesById, language => ({
        languageId: language.id,
        title: translate(seo, language)?.title ?? "",
        description: translate(seo, language)?.description ?? "",
        keywords: translate(seo, language)?.keywords ?? [],
        imageId: translate(seo, language)?.image ?? null,
      })),
    })),
    onSubmit: async ({ values }) => {
      const payload = { translations: D.values(values.translations) }
      return match(await updateSeo(payload))
        .with({ error: false }, () => {
          toast.success(_("success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _)
            .with("RESOURCE_NOT_FOUND", code => {
              onOpenChange(false)
              toast.error(_(code))
            })
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_(code)))
        )
    },
  })
  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormInput label={_("title-label")} name="title" placeholder={_("title-placeholder")} />
            <FormTextarea
              label={_("description-label")}
              name="description"
              placeholder={_("description-placeholder")}
            />
            <FormKeywords
              label={_("keywords-label")}
              name="keywords"
              placeholder={_("keywords-placeholder")}
            />
            <FormMediasImage label={_("image-label")} name="imageId" contextKey={id} />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
