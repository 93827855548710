import { Button } from "@/components/frontend/button"
import { Prose } from "@/components/frontend/prose"
import { Dialog } from "@/components/ui/dialog"
import type { UseDialogProps } from "@/components/ui/hooks/useDialog"
import { dictionary } from "."

/**
 * ErrorDialog
 */
export const ErrorDialog: React.FC<
  UseDialogProps<"not-found" | "no-slot-available" | "admin" | "unknown"> & {
    backToWorkshop: () => void
    backToReservation: () => void
  }
> = ({ item, backToReservation, backToWorkshop, ...props }) => {
  const { _ } = useDictionary(dictionary("reservation", "error"))
  return (
    <Dialog {...props} title={_("title")} className="max-w-2xl">
      {item !== false && (
        <Prose>
          <p>{_(`${item}-paragraph-1`)}</p>
          <p>{_(`${item}-paragraph-2`)}</p>
        </Prose>
      )}
      <div className="flex justify-between pt-4">
        <Button
          onClick={() => {
            props.onOpenChange(false)
            backToReservation()
          }}
          variant="outline"
        >
          {_("back-to-reservation")}
        </Button>
        <Button onClick={backToWorkshop} variant="outline">
          {_("back-to-workshop")}
        </Button>
      </div>
    </Dialog>
  )
}
