import { z } from "zod"

/**
 * ApiLanguage
 */
export const apiLanguage = z.object({
  id: z.string().uuid(),
  name: z.string(),
  code: z.string(),
  locale: z.string(),
  status: z.enum(["active", "deleted"]),
})
export type ApiLanguage = z.infer<typeof apiLanguage>
