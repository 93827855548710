import { AccountDialog } from "@/components/dialogs/account"
import { ProfileDialog } from "@/components/dialogs/profile"
import { Avatar } from "@/components/ui/avatar"
import { DropdownMenu } from "@/components/ui/dropdown-menu"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { i18nConfig } from "@/config/i18n"
import { service } from "@/services/auth/service"

import { signOut } from "@/store/auth/actions"
import { getUserThumbnail } from "@/store/auth/helpers"
import { useMe } from "@/store/auth/hooks"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { Globe, LogOut, User, UserCog, UserSquare } from "lucide-react"

/**
 * UserDropdown
 */
type Props = {
  expanded?: boolean
  beforeClick?: () => true
  align?: DropdownMenuPrimitive.MenuContentProps["align"]
  side?: DropdownMenuPrimitive.MenuContentProps["side"]
}
export const UserDropdown: React.FC<Props> = ({
  expanded,
  beforeClick = () => true,
  align = "end",
  side = "right",
}) => {
  const me = useMe()
  const {
    _,
    language: current,
    changeLanguage,
  } = useDictionary(contextMapper("components", "user-dropdown"))
  const languages = [...i18nConfig.locales]
  const { setItem: updateAccount, ...accountProps } = useDialog<void>()
  const { setItem: updateProfile, ...profileProps } = useDialog<void>()

  const fullName = S.trim(`${me?.profile.firstname ?? ""}`)
  const change = (code: string) => {
    changeLanguage(code)
    service.language(code)
  }
  return (
    <>
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <button className="group/button flex items-center gap-4 text-sm outline-none">
            <span className="inline-flex items-center justify-center rounded-full bg-card [&_svg]:w-4 [&_svg]:h-4 ring-offset-background transition-colors group-focus-visible/button:outline-none group-focus-visible/button:ring-2 group-focus-visible/button:ring-ring group-focus-visible/button:ring-offset-2">
              <Avatar>
                <Avatar.Image
                  src={getUserThumbnail(me)}
                  alt={me?.profile.firstname}
                  className="object-cover"
                />
                <Avatar.Fallback>
                  <User aria-hidden />
                </Avatar.Fallback>
              </Avatar>
              <span className="sr-only">{_("button")}</span>
            </span>
            {false && expanded && S.isNotEmpty(fullName) && (
              <span className="text-sm font-light whitespace-nowrap">{fullName}</span>
            )}
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align={align} side={side} className="w-56">
          <DropdownMenu.Item onClick={() => beforeClick() && updateAccount()}>
            <UserCog aria-hidden />
            {_("account")}
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => beforeClick() && updateProfile()}>
            <UserSquare aria-hidden />
            {_("profile")}
          </DropdownMenu.Item>
          <DropdownMenu.Sub>
            <DropdownMenu.Sub.Trigger>
              <Globe aria-hidden />
              {_("languages")}
            </DropdownMenu.Sub.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenu.Sub.Content>
                {A.map(languages, language => (
                  <DropdownMenu.CheckboxItem
                    key={language}
                    checked={language === current}
                    onCheckedChange={() => beforeClick() && change(language)}
                  >
                    {_(`languages-${language}`)}
                  </DropdownMenu.CheckboxItem>
                ))}
              </DropdownMenu.Sub.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>
          <DropdownMenu.Item onClick={signOut}>
            <LogOut aria-hidden />
            {_("sign-out")}
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>

      <AccountDialog {...accountProps} />
      <ProfileDialog {...profileProps} />
    </>
  )
}
