import { FormSelectOption } from "@/components/form/select"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "form")

export const useTitleLevelOptions = (): FormSelectOption[] => {
  const { _ } = useDictionary(dictionary())
  const titleLevelOption = React.useMemo(
    () =>
      A.map(A.range(1, 6), level => ({
        label: _("title-level-option", { level }),
        value: `${level}`,
      })),
    [_]
  )
  return titleLevelOption
}
