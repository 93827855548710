/**
 * useConfirm
 */
const useConfirm = <T, V = T>({ onConfirm, onAsyncConfirm, ...params }: UseConfirmParams<T, V>) => {
  const [open, setOpen] = React.useState<T | false>(false)
  return {
    open,
    confirm: (value: T) => setOpen(value),
    onCancel: () => setOpen(false),
    onConfirm: G.isNotNullable(onConfirm)
      ? (value: V) => {
          setOpen(false)
          onConfirm(value)
        }
      : undefined,
    onAsyncConfirm: G.isNotNullable(onAsyncConfirm)
      ? (value: V) => {
          setOpen(false)
          return onAsyncConfirm(value)
        }
      : undefined,
    ...params,
  }
}

/**
 * useConfirm
 * translation:
 *   {
 *     "confirm": {
 *       "title": "Are you sure?",
 *       "description": "", // optional skip if unset
 *       "cancel": "Cancel", // optional default on components.ui.confirm
 *       "confirm": "Confirm", // optional default on components.ui.confirm
 *       "success": "Success message", // onAsyncConfirm
 *       "error": "Error message", // onAsyncConfirm
 *       "progress": "Progress message" // onAsyncConfirm
 *       "error": "Error message {{name}}", // onAsyncConfirm with list
 *       "progress": "Progress message {counter} / {total}" // onAsyncConfirm with list
 *     }
 *   }
 */
type UseConfirmParams<T = true, V = T> = {
  onConfirm?: (value: V) => void
  onAsyncConfirm?: (value: V) => Promise<{ error: boolean }>
  confirmKey?: string
  list?: V[]
  displayName?: (value: V) => string
  dictionary?: string
}
export { useConfirm, type UseConfirmParams }
