import { Button } from "@/components/ui/button"
import { SrOnly } from "@/components/ui/sr-only"
import { isImageExtension, isVideoExtension, typeFromExtension } from "@/fns/File"
import { useWindowSize } from "@/hooks/useWindowSize"
import { useTranslation } from "@/store/languages/hooks"
import { MediasFile } from "@/store/medias/localizers"
import {
  ChevronLeft,
  ChevronRight,
  DownloadIcon,
  FullscreenIcon,
  Minimize,
  Play,
  Square,
  X,
  ZoomIn,
  ZoomOut,
} from "lucide-react"
import { Document, DocumentProps, Page, PageProps, pdfjs } from "react-pdf"
import LightboxComponent, {
  LightboxExternalProps,
  PdfSlide,
  Slide,
  SlideImage,
  SlideVideo,
} from "yet-another-react-lightbox"
import Download from "yet-another-react-lightbox/plugins/download"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Slideshow from "yet-another-react-lightbox/plugins/slideshow"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import Video from "yet-another-react-lightbox/plugins/video"
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import "./lightbox-thumbnails.css"
import "./lightbox.css"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * workers
 */
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString()

/**
 * Pdf
 */
const PdfComponent: React.FC<{ slide: PdfSlide }> = ({ slide }) => {
  const { _ } = useDictionary(dictionary("lightbox"))
  const [numberOfPages, setNumberOfPages] = React.useState<number>(0)
  const [currentPage, setCurrentPage] = React.useState<number>(1)

  const onLoadSuccess: DocumentProps["onLoadSuccess"] = ({ numPages }: { numPages: number }) => {
    setNumberOfPages(numPages)
    setCurrentPage(1)
  }
  const changePage = (offset: number) => {
    setCurrentPage(prevPageNumber => {
      const nextPageNumber = prevPageNumber + offset
      if (nextPageNumber < 1 || nextPageNumber > numberOfPages) return prevPageNumber
      return nextPageNumber
    })
  }
  const previous = () => changePage(-1)
  const next = () => changePage(1)

  const windowSize = useWindowSize()
  const [pdfSize, setPdfSize] = React.useState({ width: 0, height: 0 })
  const onRenderSuccess: PageProps["onRenderSuccess"] = ({ originalHeight, originalWidth }) =>
    setPdfSize({ width: originalWidth, height: originalHeight })
  const pageSize = React.useMemo(() => {
    const mx = 128
    const my = 96 + 120
    const availableWidth = window.innerWidth - mx
    const availableHeight = window.innerHeight - my
    const widthScale = availableWidth / pdfSize.width
    const heightScale = availableHeight / pdfSize.height
    const scale = Math.min(widthScale, heightScale)
    const scaledWidth = pdfSize.width * scale
    const scaledHeight = pdfSize.height * scale
    return { width: scaledWidth, height: scaledHeight }
  }, [windowSize, pdfSize])

  return (
    <div className="w-full h-full place-items-center">
      <Document
        file={slide.src}
        onLoadSuccess={onLoadSuccess}
        className={"grid justify-center items-center w-full h-full"}
      >
        <Page
          pageNumber={currentPage}
          {...pageSize}
          onRenderSuccess={onRenderSuccess}
          renderMode="canvas"
          height={Math.min(window.innerWidth, window.innerHeight) - 128}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex justify-center items-center p-1 gap-2 bg-card/90 rounded-md text-md">
        <Button variant="ghost" size="xs" icon onClick={previous}>
          <ChevronLeft aria-hidden />
          <SrOnly>{_("previous-page")}</SrOnly>
        </Button>
        <p>{_("pages", { current: currentPage, total: numberOfPages })}</p>
        <Button variant="ghost" size="xs" icon onClick={next}>
          <ChevronRight aria-hidden />
          <SrOnly>{_("next-page")}</SrOnly>
        </Button>
      </div>
    </div>
  )
}
const PdfThumbnail: React.FC<{ slide: PdfSlide }> = ({ slide }) => {
  const [pdfSize, setPdfSize] = React.useState({ width: 0, height: 0 })
  const onRenderSuccess: PageProps["onRenderSuccess"] = ({ originalHeight, originalWidth }) =>
    setPdfSize({ width: originalWidth, height: originalHeight })
  const pageSize = React.useMemo(() => {
    const maxWidth = 120
    const maxHeight = 80
    const widthScale = maxWidth / pdfSize.width
    const heightScale = maxHeight / pdfSize.height
    const scale = Math.min(widthScale, heightScale)
    const scaledWidth = pdfSize.width * scale
    const scaledHeight = pdfSize.height * scale
    return { width: scaledWidth, height: scaledHeight }
  }, [pdfSize])
  return (
    <div className="w-full h-full place-items-center">
      <Document file={slide.src} className={"grid justify-center items-center w-full h-full"}>
        <Page
          pageNumber={1}
          {...pageSize}
          onRenderSuccess={onRenderSuccess}
          renderMode="canvas"
          height={Math.min(window.innerWidth, window.innerHeight) - 128}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
    </div>
  )
}
const isPdfSlide = (slide: Slide): slide is PdfSlide => {
  return slide.type === "pdf"
}

/**
 * useLightbox
 */
export const useLightbox = () => {
  const [open, setOpen] = React.useState(false)
  const [interactive, setInteractive] = React.useState(false)
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const displaySlide = (index: number) => {
    setCurrentIndex(index)
    setOpen(true)
    setInteractive(true)
  }
  const openLightbox = React.useCallback(() => {
    setOpen(true)
    setInteractive(true)
  }, [])

  const renderLightbox = React.useCallback(
    ({
      slides,
      ...props
    }: Extend<Omit<LightboxExternalProps, "open" | "close">, { slides: LightboxSlide[] }>) =>
      interactive ? (
        <Lightbox
          open={open}
          index={currentIndex}
          slides={slides}
          close={() => setOpen(false)}
          {...props}
        />
      ) : null,
    [open, interactive, currentIndex]
  )

  return { openLightbox, displaySlide, renderLightbox }
}

/**
 * Lightbox
 */
export const Lightbox: React.FC<LightboxExternalProps> = ({
  styles = {},
  plugins = [],
  carousel = {},
  render = {},
  ...props
}) => {
  const { _ } = useDictionary(dictionary("lightbox"))
  return (
    <LightboxComponent
      {...props}
      render={{
        iconZoomIn: () => <ZoomIn size={16} aria-label={_("zoom-in")} />,
        iconZoomOut: () => <ZoomOut size={16} aria-label={_("zoom-in")} />,
        iconClose: () => <X size={16} aria-label={_("close")} />,
        iconSlideshowPlay: () => <Play size={16} aria-label={_("play")} />,
        iconSlideshowPause: () => <Square size={16} aria-label={_("stop")} />,
        iconDownload: () => <DownloadIcon size={16} aria-label={_("download")} />,
        iconPrev: () => <ChevronLeft size={32} strokeWidth={1} aria-label={_("previous")} />,
        iconNext: () => <ChevronRight size={32} strokeWidth={1} aria-label={_("next")} />,
        iconEnterFullscreen: () => <FullscreenIcon size={16} aria-label={_("enter-fullscreen")} />,
        iconExitFullscreen: () => <Minimize size={16} aria-label={_("exit-fullscreen")} />,
        thumbnail: ({ slide }) => (isPdfSlide(slide) ? <PdfThumbnail slide={slide} /> : undefined),
        slide: ({ slide }) => (isPdfSlide(slide) ? <PdfComponent slide={slide} /> : undefined),
        ...render,
      }}
      plugins={[Zoom, Fullscreen, Slideshow, Video, Thumbnails, Download, ...plugins]}
      styles={{
        root: {
          // "--yarl__color_backdrop": "hsl(var(--background) / 0.8)",
          // "--yarl__color_button": "hsl(var(--secondary-foreground))",
          "--yarl__thumbnails_thumbnail_border": "hsl(var(--input)) 1px solid",
        },
        ...styles,
      }}
      controller={{
        closeOnBackdropClick: true,
      }}
      carousel={{
        padding: "64px",
        ...carousel,
      }}
    />
  )
}

/**
 * useFilesToSlides
 */
export const useLightboxFiles = (files: MediasFile[]) => {
  const { openLightbox, displaySlide, renderLightbox } = useLightbox()
  const t = useTranslation()

  /**
   * slides
   */
  const slides: LightboxSlide[] = React.useMemo(
    () =>
      A.filterMap(files, ({ id, extension, url: src, ...file }) => {
        const slideProps = {
          id,
          downloadUrl: src,
          downloadFilename: t(file).name,
        }
        if (isImageExtension(extension))
          return O.Some({ ...slideProps, type: "image", src, alt: t(file).alt })
        if (isVideoExtension(extension))
          return O.Some({
            ...slideProps,
            type: "video",
            sources: [{ src, type: typeFromExtension(extension) }],
          })
        if (extension === "pdf") return O.Some({ ...slideProps, type: "pdf", src })
        return O.None
      }),
    [files]
  )

  /**
   * lightbox
   */
  const lightbox = React.useCallback(
    (id: string) => {
      displaySlide(A.getIndexBy(slides, slide => slide.id === id) ?? 0)
    },
    [slides]
  )

  return { openLightbox, displaySlide, renderLightbox: () => renderLightbox({ slides }), lightbox }
}

/**
 * helpers
 */
export const isLightboxExtension = (extension: string) =>
  isVideoExtension(extension) || isImageExtension(extension) || extension === "pdf"

/**
 * types
 */
export type LightboxSlideImage = Extend<SlideImage, { id: string }>
export type LightboxSlideVideo = Extend<SlideVideo, { id: string }>
export type LightboxSlideVideoPdf = Extend<PdfSlide, { id: string }>
export type LightboxSlide = LightboxSlideVideo | LightboxSlideImage | LightboxSlideVideoPdf
