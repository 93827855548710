/// <reference types="vite-plugin-svgr/client" />
import Dance from "@/assets/icons/dance.svg?react"
import { HN } from "@/components/ui/hn"
import { Image } from "@/components/ui/image"
import { Link } from "@/components/ui/link"
import { SrOnly } from "@/components/ui/sr-only"
import cmsConfig from "@/config/cms"
import { usePromise } from "@/hooks/usePromise"
import { useTranslation } from "@/store/languages/hooks"
import { useProjectsStore } from "@/store/projects"
import { getProjects } from "@/store/projects/actions"
import { Project } from "@/store/projects/localizers"
import { MaskHappy, MusicNotes } from "@phosphor-icons/react"
import { container } from "../../frontend/container"
import { Header } from "../../frontend/header"
import { ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  usePromise(getProjects)
  const projects = useProjectsStore(({ projects }) => {
    const projectList = D.values(projects)
    if (
      A.isEmpty(item.props.projects) &&
      G.isNullable(item.props.category) &&
      G.isNullable(item.props.orientation)
    )
      return pipe(
        projectList,
        A.sortBy(D.getUnsafe("createdAt")),
        A.take(cmsConfig.relatedProjects)
      )
    const projectsSelected = pipe(
      projectList,
      A.filter(project => A.includes(item.props.projects, project.id)),
      A.sortBy(D.getUnsafe("createdAt")),
      A.take(cmsConfig.relatedProjects)
    )
    if (G.isNullable(item.props.category) && G.isNullable(item.props.orientation))
      return projectsSelected
    const articlesFromCategoryAndOrientation = pipe(
      projectList,
      A.filter(
        project =>
          !A.includes(item.props.projects, project.id) &&
          (project.category ? project.category === item.props.category : true) &&
          (project.orientation ? project.orientation === item.props.orientation : true)
      ),
      A.sortBy(D.getUnsafe("createdAt")),
      A.take(cmsConfig.relatedProjects - projectsSelected.length)
    )
    return [...projectsSelected, ...articlesFromCategoryAndOrientation]
  })

  const { titleLevel } = item.props
  const { title, secondary } = t(item).props
  if (A.isEmpty(projects)) return null
  return (
    <div className={cx("flex flex-col py-8 gap-8 font-plus", container({ x: "sm" }))}>
      <Header {...{ title, secondary, titleLevel }} />
      <div className="grid grid-cols-3 gap-8">
        {A.map(projects, project => (
          <ProjectRender project={project} titleLevel={titleLevel} key={project.id} />
        ))}
      </div>
    </div>
  )
}
const ProjectRender: React.FC<{ project: Project; titleLevel: number }> = ({
  project,
  titleLevel,
}) => {
  // dictionary src/dictionaries/fr/pages/projects.json
  const { _ } = useDictionary(contextMapper("pages", "projects"))
  const { id, name, description, category, image } = project
  return (
    <article className={cx("relative flex items-end text-white w-full h-64 p-5")}>
      <div className={"absolute inset-0"}>
        {G.isNotNullable(image) && S.isNotEmpty(image.url) && (
          <Image
            src={image.url}
            className={"absolute inset-0 w-full h-full object-cover"}
            aria-hidden
          />
        )}
        <div
          className={"absolute inset-0 bg-gradient-to-b from-orient/20 to-orient/80"}
          aria-hidden
        />
      </div>
      <div className="absolute right-0 top-0 flex justify-center items-center pl-2 bg-tomato rounded-bl-[40px] w-14 h-14 [&>svg]:h-[28px]">
        {category === "dance" && <Dance aria-hidden />}
        {category === "music" && <MusicNotes aria-hidden />}
        {category === "theater" && <MaskHappy aria-hidden />}
        <SrOnly>{_(`category-${category}`)}</SrOnly>
      </div>
      <div className={"relative flex flex-col gap-2.5"}>
        <HN level={titleLevel + 1} className={cx("font-bold")}>
          {name}
        </HN>
        <p className={cx("text-xs line-clamp-2")}>{description}</p>
      </div>
      <Link className="absolute inset-0 w-full h-full opacity-0" href={`/projects/${id}`}>
        {_("project-link-aria", { name })}
      </Link>
    </article>
  )
}
