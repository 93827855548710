import { Menu } from "@/components/collection"
import { Button } from "@/components/ui/button"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { SrOnly } from "@/components/ui/sr-only"
import { ContentItem } from "@/store/contents/localizers"
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { ChevronRightSquare, Edit, Eye, EyeOff, MoreHorizontal, Plus } from "lucide-react"
import { Fragment } from "react"
import { ItemContextMenu, useCmsContext } from "../Context"
import { CreateItemDialog } from "../dialogs/create-item"
import Items from "../items"
import { Card } from "./card"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")

/**
 * ContentItems
 */
export const ContentItems: React.FC = () => {
  const {
    content,
    actions: { reorderContentItems },
  } = useCmsContext()

  const items = React.useMemo(
    () => pipe(content.items, D.values, A.sortBy(D.prop("order"))),
    [content.items]
  )
  const { setItem: createItem, ...createItemProps } = useDialog<number>()

  // drag and drop reordering
  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      setActiveId(null)
      const { active, over } = event
      if (active.id !== over?.id) {
        const list = A.map(items, D.prop("id"))
        const oldIndex = list.indexOf(active.id as string)
        const newIndex = list.indexOf(over!.id as string)
        reorderContentItems({ items: arrayMove(list, oldIndex, newIndex) })
      }
    },
    [items, reorderContentItems]
  )
  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    setActiveId(active.id as string)
  }
  const mouseSensor = useSensor(MouseSensor)
  const touchSensor = useSensor(TouchSensor)
  const sensors = useSensors(mouseSensor, touchSensor)

  // keyboard accessibility reordering
  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>, id: string) => {
      const keyCode = e.key
      if (!A.includes(["ArrowUp", "ArrowDown"], keyCode)) return
      e.preventDefault()
      const list = A.map(items, D.prop("id"))
      const oldIndex = list.indexOf(id as string)
      switch (keyCode) {
        case "ArrowUp": {
          const newIndex = oldIndex - 1
          if (newIndex < 0) return
          reorderContentItems({ items: arrayMove(list, oldIndex, newIndex) })
          break
        }
        case "ArrowDown": {
          const newIndex = oldIndex + 1
          if (newIndex >= list.length) return
          reorderContentItems({ items: arrayMove(list, oldIndex, newIndex) })
          break
        }
      }
    },
    [items, reorderContentItems]
  )
  const [activeId, setActiveId] = React.useState<string | null>(null)
  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <div className="flex flex-col items-center w-full">
          <ButtonAddItem index={-1} onClick={() => createItem(-1)} isSorting={G.isNull(activeId)} />
          {A.mapWithIndex(items, (index, item) => (
            <Fragment key={item.id}>
              <Item item={item} onKeyDown={onKeyDown} />
              <ButtonAddItem
                index={index}
                onClick={() => createItem(index)}
                isSorting={G.isNull(activeId)}
              />
            </Fragment>
          ))}
        </div>
        <CreateItemDialog {...createItemProps} />
        <DragOverlay adjustScale={false}>
          {activeId ? (
            <Item item={D.getUnsafe(content.items, activeId)} onKeyDown={onKeyDown} isOverlay />
          ) : null}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  )
}

/**
 * ButtonAddItem
 */
type ButtonAddItemProps = {
  onClick: () => void
  index: number
  isSorting: boolean
}
const ButtonAddItem: React.FC<ButtonAddItemProps> = ({ index, onClick, isSorting }) => {
  const { _ } = useDictionary(dictionary("content"))
  const {
    content: { items },
  } = useCmsContext()

  const isAlone = D.isEmpty(items)
  const isLast = index === D.keys(items).length - 1
  const isFirst = index === -1
  const suffix = isAlone ? "first" : isLast ? "after" : isFirst ? "before" : "between"

  return (
    <div
      className={cx(
        "flex justify-center items-center w-full cursor-auto",
        "hover:opacity-100 focus-within:opacity-100 ",
        "transition-all delay-300 focus-within:delay-0 duration-150",
        isSorting && "opacity-0",
        isAlone ? "h-16 opacity-100" : "h-8 hover:h-16 focus-within:h-16 opacity-0"
      )}
    >
      <Button onClick={onClick} variant="secondary" size="sm">
        <Plus size={12} aria-hidden />
        {_(`create-item-${suffix}`)}
      </Button>
    </div>
  )
}

/**
 * Item
 */
type ItemProps = {
  item: ContentItem
  onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>, id: string) => void
  isOverlay?: boolean
}
const Item: React.FC<ItemProps> = ({ item, onKeyDown, isOverlay = false }) => {
  const { _ } = useDictionary(dictionary("content"))
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
  })
  const style = { transform: CSS.Transform.toString(transform), transition }
  const {
    dialogs: { updateItem, toggleState },
  } = useCmsContext()

  return (
    <Card
      ref={setNodeRef}
      style={style}
      id={item.id}
      className={cx(
        "relative transition-all",
        isDragging ? "opacity-0" : "opacity-100 z-10",
        isOverlay && "opacity-75 z-20"
      )}
    >
      <Menu menu={<ItemContextMenu item={item} />} type="context-menu" align="start" side="left">
        <button
          className="absolute cursor-default inset-0 w-full h-full rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
          {...listeners}
          {...attributes}
          onKeyDown={e => onKeyDown(e, item.id)}
          type="button"
        >
          <SrOnly>{_("drag-item")}</SrOnly>
        </button>
        <Card.Header>
          <Card.Header.Title>
            <ChevronRightSquare size={16} aria-hidden />
            {_(`items.${item.type}.title`)}
          </Card.Header.Title>
          <Card.Header.Aside className="relative">
            {item.state === "draft" ? (
              <Button variant="secondary" size="xxs" icon onClick={() => toggleState(item)}>
                <EyeOff aria-label={_(`state-draft`)} />
              </Button>
            ) : (
              <Button size="xxs" icon onClick={() => toggleState(item)}>
                <Eye aria-label={_(`state-published`)} />
              </Button>
            )}
            <Button variant="secondary" size="xxs" icon onClick={() => updateItem(item)}>
              <Edit aria-label={_(`menu.edit`)} />
            </Button>
            <Menu
              menu={<ItemContextMenu item={item} />}
              type="dropdown-menu"
              align="start"
              side="left"
            >
              <Button variant="ghost" size="xxs" icon>
                <MoreHorizontal aria-hidden />
                <SrOnly>{_("more")}</SrOnly>
              </Button>
            </Menu>
          </Card.Header.Aside>
        </Card.Header>
        <Card.Content>
          <ItemRenderDispatcher item={item} />
        </Card.Content>
      </Menu>
    </Card>
  )
}

/**
 * ItemDispatcher
 */
export const ItemRenderDispatcher: React.FC<{ item: ContentItem }> = ({ item }) => {
  const Component = Items[item.type].ItemRender
  // @ts-expect-error
  return <Component item={item} />
}
export const ItemFormDispatcher: React.FC<{ item: ContentItem; close: () => void }> = ({
  item,
  close,
}) => {
  const Component = Items[item.type].ItemForm
  // @ts-expect-error
  return <Component item={item} close={close} />
}
