import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { Tooltip } from "@/components/ui/tooltip"
import { useDocumentVisibility } from "@/hooks/useDocumentVisibility"
import { useInterval } from "@/hooks/useInterval"
import { Radio } from "lucide-react"
import { DisplayBySelector } from "../components/display-by-selector"
import { RangeSelector } from "../components/range-selector"
import { StatsBrowser } from "../components/stats-browser"
import { StatsDevice } from "../components/stats-device"
import { StatsOs } from "../components/stats-os"
import { StatsSelector } from "../components/stats-selector"
import { StatsVisitByDays } from "../components/stats-visit-by-days"
import { StatsVisitByMonths } from "../components/stats-visit-by-months"
import { StatsVisitByWeeks } from "../components/stats-visit-by-weeks"
import { StatsVisitByYears } from "../components/stats-visit-by-years"
import { DisplayByName, RangeName, StatsName } from "../types"

/**
 * dictionary src/dictionaries/en/components/trackings.json
 */
const dictionary = createContextMapper("components", "trackings")

/**
 * StatsDialog
 */
type Props = {
  open: boolean
  onOpenChange: (state: boolean) => void
  item: string | false
}
export const StatsDialog: React.FC<Props> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary("stats-dialog"))
  return (
    <Dialog
      open={props.open}
      onOpenChange={props.onOpenChange}
      title={_("title")}
      // description={_("secondary")}
      className="sm:max-w-3xl"
    >
      {item !== false && <Stats item={item} {...props} />}
    </Dialog>
  )
}

/**
 * Stats
 */
const Stats: React.FC<Props & { item: string }> = ({ item }) => {
  const { _ } = useDictionary(dictionary("stats-dialog"))

  const [isLoading, setIsLoading] = React.useState(false)
  const [range, setRange] = React.useState<RangeName>("7days")
  const [displayBy, setDisplayBy] = React.useState<DisplayByName>("days")
  const [stats, setStats] = React.useState<StatsName>("visit")
  const interval = React.useMemo(() => {
    const to = new Date()
    if (range === "7days") return { from: T.sub(to, { days: 7 }), to }
    if (range === "1month") return { from: T.sub(to, { months: 1 }), to }
    if (range === "3months") return { from: T.sub(to, { months: 3 }), to }
    if (range === "6months") return { from: T.sub(to, { months: 6 }), to }
    if (range === "12months") return { from: T.sub(to, { years: 1 }), to }
    if (range === "all") return { from: undefined, to }
    return exhaustive(range)
  }, [range])

  const [live, setLive] = React.useState(false)
  const isVisible = useDocumentVisibility()
  useInterval(() => setLive(false), live ? 1000 * 60 * 15 : null)

  const props = { trackingId: item, isLoading, setIsLoading, interval, live: live && isVisible }
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex justify-end items-center w-full gap-2">
        <RangeSelector range={range} setRange={setRange} />
        {stats === "visit" && (
          <DisplayBySelector displayBy={displayBy} setDisplayBy={setDisplayBy} />
        )}
        <StatsSelector stats={stats} setStats={setStats} />
        <Tooltip content={_("live-tooltip")} side="bottom">
          <Button
            variant={live ? "default" : "outline"}
            icon
            size="xs"
            onClick={() => setLive(l => !l)}
          >
            <Radio size={16} />
          </Button>
        </Tooltip>
      </div>
      <div className="w-full aspect-video text-[12px] @md/dialog:text-[12px] @lg/dialog:text-base">
        {stats === "os" && <StatsOs {...props} />}
        {stats === "browser" && <StatsBrowser {...props} />}
        {stats === "device" && <StatsDevice {...props} />}
        {stats === "visit" && (
          <>
            {displayBy === "days" && <StatsVisitByDays {...props} />}
            {displayBy === "weeks" && <StatsVisitByWeeks {...props} />}
            {displayBy === "months" && <StatsVisitByMonths {...props} />}
            {displayBy === "years" && <StatsVisitByYears {...props} />}
          </>
        )}
      </div>
    </div>
  )
}
