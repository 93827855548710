import { apiContent } from "@/components/cms/items/schemas"
import { apiSeo } from "@/services/seos/schemas"
import { z } from "zod"
import { apiExtraField, apiMultipleFile } from "../commons/schemas"
import { apiMediasFile } from "../medias/schemas"
import { apiProjectCategory, apiProjectOrientation, apiProjectStatus } from "../projects/schemas"
import { apiUserProfile } from "../users/schemas"

/**
 * ApiUser
 */
export const apiUser = z.object({
  id: z.string().uuid(),
  profile: apiUserProfile,
})
export type ApiUser = z.infer<typeof apiUser>

/**
 * apiPage
 */
export const apiPageState = z.union([z.literal("draft"), z.literal("published")])
export type ApiPageState = z.infer<typeof apiPageState>
export const apiPage = z.object({
  id: z.string().uuid(),
  lock: z.boolean(),
  state: apiPageState,
  trackingId: z.string().uuid(),
  seoId: z.string(),
  seo: apiSeo,
  contentId: z.string().uuid(),
  content: apiContent,
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiPage = z.infer<typeof apiPage>

/**
 * ApiArticleCategoryTranslation
 */
const apiArticleCategoryTranslation = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string(),
  languageId: z.string(),
})
export type ApiArticleCategoryTranslation = z.infer<typeof apiArticleCategoryTranslation>

/**
 * ApiArticleCategory
 */
export const apiArticleCategory = z.object({
  id: z.string().uuid(),
  order: z.number(),
  translations: apiArticleCategoryTranslation.array(),
})
export type ApiArticleCategory = z.infer<typeof apiArticleCategory>

/**
 * ApiArticle
 */
export const articleTypes = ["news", "hero", "offers"] as const
export const apiArticleType = z.union([z.literal("news"), z.literal("hero"), z.literal("offers")])
export type ApiArticleType = z.infer<typeof apiArticleType>
export const apiArticleState = z.union([z.literal("draft"), z.literal("published")])
export type ApiArticleState = z.infer<typeof apiArticleState>
export const apiArticle = z.object({
  id: z.string().uuid(),
  type: apiArticleType,
  state: apiArticleState,
  trackingId: z.string().uuid(),
  seoId: z.string(),
  seo: apiSeo,
  contentId: z.string(),
  content: apiContent,
  categoryId: z.string().uuid().nullable(),
  category: apiArticleCategory.nullable(),
  authorId: z.string().uuid().nullable(),
  author: apiUser.nullable(),
  publishedAt: z.string(),
  publishedFrom: z.string().nullable(),
  publishedTo: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiArticle = z.infer<typeof apiArticle>

/**
 * ApiProject
 */
export const apiProject = z.object({
  id: z.string().uuid(),
  name: z.string(),
  language: z.string(),
  category: apiProjectCategory,
  orientation: apiProjectOrientation,
  image: apiMultipleFile,
  partners: apiExtraField.array(),
  classes: apiExtraField.array(),
  description: z.string(),
  skills: apiExtraField.array(),
  objective: z.string(),
  materials: z.string().array(),
  preparation: z.string(),
  follow: z.string(),
  values: z.string(),
  documents: apiMultipleFile.array(),
  images: apiMultipleFile.array(),
  status: apiProjectStatus,
  validated: z.boolean(),
  validatedBy: apiUser.nullable(),
  validatedAt: z.string().nullable(),
  createdBy: apiUser.nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  trackingId: z.string().uuid(),
})
export type ApiProject = z.infer<typeof apiProject>
/**
 * MapName
 */
export const mapName = ["north", "south", "east", "west", "center"] as const
export const apiMapName = z.union([
  z.literal("north"),
  z.literal("south"),
  z.literal("east"),
  z.literal("west"),
  z.literal("center"),
])
export type ApiMapName = z.infer<typeof apiMapName>

/**
 * ApiPlace
 */
export const apiPlace = z.object({
  id: z.string().uuid(),
  name: z.string(),
  published: z.boolean(),
  description: z.string(),
  address: z.string(),
  website: z.string(),
  phones: apiExtraField.array(),
  emails: apiExtraField.array(),
  image: apiMediasFile.nullable(),
  cover: apiMediasFile.nullable(),
  map: apiMapName,
  coordinate: z.tuple([z.number(), z.number()]),
})
export type ApiPlace = z.infer<typeof apiPlace>

/**
 * ApiContact
 */
export const apiContact = z.object({
  schoolName: z.string(),
  schoolClass: z.string(),
  schoolStreet: z.string(),
  schoolZip: z.string(),
  schoolCity: z.string(),
  teacherName: z.string(),
  teacherEmail: z.string(),
  teacherPhone: z.string(),
})
export type ApiContact = z.infer<typeof apiContact>

/**
 * ApiWorkshopReservation
 */
export const apiWorkshopReservation = z.object({
  id: z.string().uuid(),
  canceled: z.boolean(),
  archived: z.boolean(),
  message: z.string(),
  contact: z.union([apiContact, z.object({})]),
  language: z.string(),
  students: z.number(),
  userId: z.string(),
  eventId: z.string().uuid(),
  workshopId: z.string().uuid(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiWorkshopReservation = z.infer<typeof apiWorkshopReservation>

/**
 * ApiWorkshopEvent
 */
export const apiWorkshopEvent = z.object({
  id: z.string().uuid(),
  reservationsSlot: z.number(),
  datetime: z.string(),
  // reservations: apiWorkshopReservation.array(),
  reservationsDone: z.number(),
  workshopId: z.string().uuid(),
})
export type ApiWorkshopEvent = z.infer<typeof apiWorkshopEvent>

/**
 * ApiWorkshopDuration
 */
export const apiWorkshopDuration = z.object({
  hours: z.number(),
  minutes: z.number(),
})
export type ApiWorkshopDuration = z.infer<typeof apiWorkshopDuration>

/**
 * ApiWorkshop
 */
export const apiWorkshop = z.object({
  id: z.string().uuid(),
  name: z.string(),
  published: z.boolean(),
  archived: z.boolean(),
  description: z.string(),
  languages: z.string().array(),
  duration: apiWorkshopDuration,
  maxAttendees: z.number(),
  placeId: z.string(),
  events: apiWorkshopEvent.array(),
  trackingId: z.string().uuid(),
})
export type ApiWorkshop = z.infer<typeof apiWorkshop>
