import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { useBaseLayout } from "../layout/context"
import { Tooltip } from "./tooltip"

/**
 * buttons states classes
 */
const buttonFocus =
  "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 transition-colors"
const buttonDisabled = "disabled:pointer-events-none disabled:opacity-50"

/**
 * variants
 */
const buttonVariants = cva(
  cx("inline-flex items-center justify-center rounded-md font-medium", buttonFocus, buttonDisabled),
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        success: "bg-green-700 text-white hover:bg-green-700/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        transparent: "bg-background/50 hover:bg-background/75 text-secondary-foreground",
        ghost: "border border-transparent hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 text-sm",
        link: "h-[1rem] px-1 py-1 text-inherit",
        lg: "h-11 text-base",
        sm: "h-9 text-sm",
        xs: "h-8 text-xs",
        xxs: "h-6 text-xs",
      },
      icon: {
        false: "gap-0",
        true: "gap-2",
      },
    },
    compoundVariants: [
      { size: "default", icon: false, class: "px-4 py-2 gap-2" },
      { size: "default", icon: true, class: "w-10 [&>svg]:w-4 [&>svg]:h-4 shrink-0" },
      { size: "lg", icon: false, class: "px-8 py-2 gap-4" },
      { size: "lg", icon: true, class: "w-11 [&>svg]:w-4 [&>svg]:h-4 shrink-0" },
      { size: "sm", icon: false, class: "px-3 py-2 gap-1.5" },
      { size: "sm", icon: true, class: "w-9 [&>svg]:w-4 [&>svg]:h-4 shrink-0" },
      { size: "xs", icon: false, class: "px-2 gap-1" },
      { size: "xs", icon: true, class: "w-8 [&>svg]:w-3 [&>svg]:h-3 shrink-0" },
      { size: "xxs", icon: false, class: "px-2 gap-1" },
      { size: "xxs", icon: true, class: "w-6 [&>svg]:w-3 [&>svg]:h-3 shrink-0" },
    ],
    defaultVariants: {
      variant: "default",
      size: "default",
      icon: false,
    },
  }
)
const buttonVariantsFE = cva(
  cx(
    "inline-flex items-center justify-center rounded-[2px] font-medium",
    buttonFocus,
    buttonDisabled
  ),
  {
    variants: {
      variant: {
        default: "bg-orient text-white hover:bg-orient/90",
        success: "bg-green-700 text-white hover:bg-green-700/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        transparent: "bg-background/50 hover:bg-background/75 text-secondary-foreground",
        ghost: "border border-transparent hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 text-sm",
        link: "h-[1rem] px-1 py-1 text-inherit",
        lg: "h-11 text-base",
        sm: "h-9 text-sm",
        xs: "h-8 text-xs",
        xxs: "h-6 text-xs",
      },
      icon: {
        false: "gap-0",
        true: "gap-2",
      },
    },
    compoundVariants: [
      { size: "default", icon: false, class: "px-4 py-2 gap-2" },
      { size: "default", icon: true, class: "w-10 [&>svg]:w-4 [&>svg]:h-4 shrink-0" },
      { size: "lg", icon: false, class: "px-8 py-2 gap-4" },
      { size: "lg", icon: true, class: "w-11 [&>svg]:w-4 [&>svg]:h-4 shrink-0" },
      { size: "sm", icon: false, class: "px-3 py-2 gap-1.5" },
      { size: "sm", icon: true, class: "w-9 [&>svg]:w-4 [&>svg]:h-4 shrink-0" },
      { size: "xs", icon: false, class: "px-2 gap-1" },
      { size: "xs", icon: true, class: "w-8 [&>svg]:w-3 [&>svg]:h-3 shrink-0" },
      { size: "xxs", icon: false, class: "px-2 gap-1" },
      { size: "xxs", icon: true, class: "w-6 [&>svg]:w-3 [&>svg]:h-3 shrink-0" },
    ],
    defaultVariants: {
      variant: "default",
      size: "default",
      icon: false,
    },
  }
)

/**
 * others variants
 */
const buttonFormField = cx(
  "flex justify-center items-center w-full border border-input rounded-md bg-muted text-muted-foreground",
  buttonFocus,
  buttonDisabled
)

/**
 * Button
 */
interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  pending?: boolean
  side?: React.ComponentProps<typeof Tooltip>["side"]
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, icon, asChild = false, type = "button", side, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    const { isDashboard } = useBaseLayout()
    const variants = isDashboard ? buttonVariants : buttonVariantsFE
    if (G.isNotNullable(props["aria-label"]))
      return (
        <Tooltip content={props["aria-label"]} side={side}>
          <Comp
            className={cx(variants({ variant, size, icon, className }))}
            type={type}
            ref={ref}
            {...props}
          >
            {props.children}
          </Comp>
        </Tooltip>
      )
    return (
      <Comp
        className={cx(variants({ variant, size, icon, className }))}
        type={type}
        ref={ref}
        {...props}
      >
        {props.children}
      </Comp>
    )
  }
)

/**
 * exports
 */

export { Button, buttonDisabled, buttonFocus, buttonFormField, buttonVariants, type ButtonProps }
