import globalConfig from "@/config/global"
import * as Auth from "@/services/auth/service"
import { match } from "ts-pattern"
import { v4 as uuid } from "uuid"
import { authStore } from "."
import { resetAllStores, resetAllStoresAndReload } from ".."
import { localizeMe } from "./localizers"

/**
 * getSid
 */
export const getSid = () => {
  const sid = localStorage.getItem(`required-${globalConfig.sessionKey}-sid`) ?? uuid()
  localStorage.setItem(`required-${globalConfig.sessionKey}-sid`, sid)
  return sid
}

/**
 * initStore
 */
export const initAuthStore = async () => {
  const sid = getSid()

  return match(await Auth.service.session())
    .with({ error: false }, ({ data }) => {
      authStore.set({
        sid,
        initDone: true,
        initError: false,
        authenticated: data.session === true,
        user: data.session === true ? localizeMe(data.user) : null,
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => {
      authStore.set({ sid, initDone: true, initError: true })
      return { error, code } as const
    })
}

/**
 * signIn
 */
export const signIn = async (payload: Auth.Payload["signIn"]) =>
  match(await Auth.service.signIn(payload))
    .with({ error: false }, ({ data }) => {
      authStore.evolve({ authenticated: true, user: localizeMe(data.user) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * signOut
 */
export const signOut = async () =>
  match(await Auth.service.signOut())
    .with({ error: false }, () => {
      resetAllStoresAndReload()
      return { error: false } as const
    })
    .otherwise(({ error, code }) => {
      resetAllStoresAndReload()
      return { error, code } as const
    })

/**
 * updateSession
 */
export const updateSession = async () =>
  match(await Auth.service.session())
    .with({ error: false }, ({ data }) => {
      if (data.session) authStore.evolve({ authenticated: true, user: localizeMe(data.user) })
      else {
        authStore.evolve({ authenticated: false, user: null })
        resetAllStores()
      }
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))
