import { Form } from "."

/**
 * FormGroupError
 */
type Props = React.ComponentProps<"p">
export const FormGroupError: React.FC<Props> = ({ className, ...props }) => {
  return <Form.Error className={cx("text-red-600 font-light text-xs", className)} {...props} />
}
export const FormError: React.FC<Props> = ({ className, ...props }) => {
  return <p className={cx("text-red-600 font-light text-xs", className)} {...props} />
}
