import { buttonVariants } from "@/components/frontend/button"
import { MobilePopover } from "@/components/frontend/mobile-popover"
import { HN } from "@/components/ui/hn"
import { useResponsive } from "@/hooks/useResponsive"
import { projectCategories, projectOrientations } from "@/services/projects/schemas"
import { Project } from "@/store/frontend/localizers"
import { CaretDown } from "@phosphor-icons/react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { Check } from "lucide-react"
import { dictionary } from "."

/**
 * Page: Filters
 */
export const Filters: React.FC<{
  filters: FiltersType
  setFilters: (filters: FiltersType) => void
}> = ({ filters, setFilters }) => {
  const { _ } = useDictionary(dictionary("filters"))
  const [open, setOpen] = React.useState(false)
  const media = useResponsive()

  return media.min("lg") ? (
    <div className="sticky top-1 pb-10">
      <FiltersRender {...{ filters, setFilters }} close={() => setOpen(false)} />
    </div>
  ) : (
    <>
      <MobilePopover open={open} onOpenChange={state => setOpen(state)} modal={false}>
        <MobilePopover.Trigger
          className={cx(buttonVariants({ variant: "dark" }), "relative w-full z-50")}
        >
          {_("title")}
          <CaretDown className={cx("h-5 transition-transform", open ? "rotate-180" : "rotate-0")} />
        </MobilePopover.Trigger>
        <MobilePopover.Content className="p-4">
          <FiltersRender {...{ filters, setFilters }} close={() => setOpen(false)} />
        </MobilePopover.Content>
      </MobilePopover>
    </>
  )
}

const FiltersRender: React.FC<{
  filters: FiltersType
  setFilters: (filters: FiltersType) => void
  close: () => void
}> = ({ filters, setFilters }) => {
  const { _ } = useDictionary(dictionary("filters"))
  const clear = () => setFilters(initialFilters)

  return (
    <div className="divide-y divide-mercury">
      <div className="flex justify-between pb-5">
        <HN level={2} className="text-lg font-bold">
          {_("title")}
        </HN>
        <button
          className="text-xs font-bold outline-none hover:text-orient hover:underline focus:text-orient focus:underline transition-colors"
          onClick={clear}
        >
          {_("clear")}
        </button>
      </div>
      <div className="flex flex-col py-5 sm:py-[30px] gap-5">
        <HN level={3} className="font-semibold">
          {_("orientations")}
        </HN>
        {projectOrientations.map(orientation => (
          <Checkbox
            key={orientation}
            id={`orientations-${orientation}`}
            onCheckedChange={() =>
              setFilters({
                ...filters,
                orientations: A.includes(filters.orientations, orientation)
                  ? A.reject(filters.orientations, F.equals(orientation))
                  : A.append(filters.orientations, orientation),
              })
            }
            checked={A.includes(filters.orientations, orientation)}
          >
            {_(`orientations-${orientation}`)}
          </Checkbox>
        ))}
      </div>
      <div className="flex flex-col py-5 sm:py-[30px] gap-5">
        <HN level={3} className="font-semibold">
          {_("categories")}
        </HN>
        {projectCategories.map(category => (
          <Checkbox
            key={category}
            id={`categories-${category}`}
            onCheckedChange={() =>
              setFilters({
                ...filters,
                categories: A.includes(filters.categories, category)
                  ? A.reject(filters.categories, F.equals(category))
                  : A.append(filters.categories, category),
              })
            }
            checked={A.includes(filters.categories, category)}
          >
            {_(`categories-${category}`)}
          </Checkbox>
        ))}
      </div>
    </div>
  )
}

/**
 * Checkbox
 */
type CheckboxProps = CheckboxPrimitive.CheckboxProps & {
  children?: React.ReactNode
}
const Checkbox: React.FC<CheckboxProps> = ({ className, children, ...props }) => {
  return (
    <label
      htmlFor={props.id}
      className={cx(
        "flex items-center text-sm font-medium transition-all",
        props.checked ? "text-bluewhale" : "text-gray"
      )}
    >
      <CheckboxPrimitive.Root
        className={cx(
          "peer h-5 w-5 shrink-0 mr-2.5 rounded-[2px] border-2 border-mercury",
          "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-orient focus-visible:ring-offset-2",
          "data-[state=checked]:border-bluewhale data-[state=checked]:text-bluewhale",
          "disabled:cursor-not-allowed disabled:opacity-50",
          "transition-all",
          className
        )}
        {...props}
      >
        <CheckboxPrimitive.Indicator
          className={cx("flex items-center justify-center text-current")}
        >
          <Check size={14} strokeWidth={3} />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      {children}
    </label>
  )
}

/**
 * types & initial
 */
export type FiltersType = {
  orientations: Project["orientation"][]
  categories: Project["category"][]
}
export const initialFilters: FiltersType = {
  orientations: [],
  categories: [],
}
