import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSection,
  FormSelect,
  FormSubmit,
  formatDateToFormInput,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { usePromise } from "@/hooks/usePromise"
import { resetAllStoresAndReload } from "@/store"
import { getArticleCategories, updateArticle } from "@/store/articles/actions"
import { useCategoryOptions, useStateOptions, useTypeOptions } from "@/store/articles/hooks"
import { Article } from "@/store/articles/localizers"
import { getUsers } from "@/store/users/actions"
import { useAuthorOptions } from "@/store/users/hooks"
import { match } from "ts-pattern"
import { formatDatetimeToFormInput } from "../form/input"
import { UseDialogFormProps, UseDialogProps } from "../ui/hooks/useDialog"

/**
 * dictionary src/dictionaries/en/components/dialogs/article-create-dialog.json
 */
const dictionary = createContextMapper("components", "dialogs", "article-update-dialog")

/**
 * ArticleUpdateDialog
 */
export const ArticleUpdateDialog: React.FC<
  UseDialogProps<Article> & { types: Article["type"][] }
> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary())

  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}
const DialogForm: React.FC<UseDialogFormProps<Article> & { types: Article["type"][] }> = ({
  onOpenChange,
  item: article,
  types,
}) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  usePromise(getArticleCategories)
  usePromise(getUsers)

  const { min, isSetAndBeforeIso, isSetAndAfterIso } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      type: article.type,
      state: article.state,
      categoryId: G.isNull(article.category) ? "null" : article.category,
      authorId: G.isNull(article.author) ? "null" : article.author,
      publishedAt: formatDateToFormInput(article.publishedAt),
      publishedFrom: formatDatetimeToFormInput(article.publishedFrom),
      publishedTo: formatDatetimeToFormInput(article.publishedTo),
    })),
    validate: validator({
      publishedAt: [min(1, _form("published-at-required"))],
      publishedFrom: [isSetAndBeforeIso("publishedTo", _form("published-from-invalid"))],
      publishedTo: [isSetAndAfterIso("publishedFrom", _form("published-to-invalid"))],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return _errors("VALIDATION_FAILURE")
      const payload = {
        type: values.type,
        state: values.state,
        categoryId: values.categoryId === "null" ? null : values.categoryId,
        authorId: values.authorId === "null" ? null : values.authorId,
        publishedAt: values.publishedAt,
        publishedFrom: values.publishedFrom || null,
        publishedTo: values.publishedTo || null,
      }
      return match(await updateArticle(article.id, payload))
        .with({ error: false }, () => {
          onOpenChange(false)
          toast.success(_("success"))
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("RESOURCE_NOT_FOUND", code => {
              onOpenChange(false)
              toast.error(_errors(code))
            })
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })

  const typeOptions = useTypeOptions(types)
  const stateOptions = useStateOptions()
  const categoryOptions = useCategoryOptions(_form("category-select-placeholder"))
  const authorOptions = useAuthorOptions(_form("author-select-placeholder"))

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormHeader>
        <FormHeader.Title>{_("section-informations-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-informations-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        {types.length > 1 && (
          <FormSelect label={_("type-label")} name="type" options={typeOptions} />
        )}
        <FormSelect
          label={_form("category-select-label")}
          name="categoryId"
          options={categoryOptions}
        />
        <FormSelect label={_form("author-select-label")} name="authorId" options={authorOptions} />
        <FormInput label={_form("published-at-label")} name="publishedAt" type="date" />
      </FormSection>
      <FormHeader>
        <FormHeader.Title>{_("section-publication-title")}</FormHeader.Title>
        <FormHeader.Description>{_("section-publication-description")}</FormHeader.Description>
      </FormHeader>
      <FormSection>
        <FormSelect label={_form("state-label")} name="state" options={stateOptions} />
        <div className="grid grid-cols-2 gap-4">
          <FormInput
            label={_form("published-from-label")}
            name="publishedFrom"
            type="datetime-local"
          />
          <FormInput label={_form("published-to-label")} name="publishedTo" type="datetime-local" />
        </div>
      </FormSection>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
