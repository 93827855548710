import { Empty, Grid, LimitMore, Selection, Toolbar } from "@/components/collection"
import {} from "@/components/collection/toolbar"
import { PageContent } from "@/components/layout/dashboard"
import { useFilteredPages } from "@/store/pages/hooks"
import { usePageContext } from "./Context"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/pages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "pages")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { pages, filtered, sortable, matchable, filterable, limitable, limit } = useFilteredPages()
  const {
    view,
    setView,
    create,
    selected,
    setSelected,
    confirmDeleteSelection,
    confirmPublishSelection,
    confirmUnpublishSelection,
  } = usePageContext()
  return (
    <PageContent>
      <Selection
        selected={selected}
        unselect={() => setSelected([])}
        deleteSelection={confirmDeleteSelection}
        publishSelection={confirmPublishSelection}
        unpublishSelection={confirmUnpublishSelection}
      />
      <Toolbar>
        <Toolbar.Search {...matchable} />
        <Toolbar.Button onClick={create}>{_("create")}</Toolbar.Button>
        <Toolbar.Sort {...sortable} dictionary={Toolbar.Sort.dictionary(dictionary("sort"))} />
        <Toolbar.View {...{ view, setView }} />
      </Toolbar>
      <Empty
        items={pages}
        results={filtered}
        create={create}
        clear={() => {
          matchable.setSearch("")
          filterable.clear()
          setSelected([])
        }}
      />
      <Grid {...{ view }}>
        {A.map(limit(filtered), item => (
          <Item key={item.id} page={item} />
        ))}
      </Grid>
      <LimitMore {...limitable} />
    </PageContent>
  )
}
export default Collection
