import { Button } from "@/components/frontend/button"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { oneIsNotEmpty } from "@/fns/String"
import { service } from "@/services/frontend/service"
import { useIsAdmin } from "@/store/auth/hooks"
import { Envelope, Globe, Phone, Student } from "@phosphor-icons/react"
import { match } from "ts-pattern"
import { FormContactProps, FormReservationProps, StepsProps, StoreProps, dictionary } from "."
import { ConfirmDialog } from "./ConfirmDialog"
import { ErrorDialog } from "./ErrorDialog"
import { StepperContent } from "./Stepper"

/**
 * StepSummary
 */
type Values = {
  values: FormReservationProps["form"]["values"] & FormContactProps["form"]["values"]
  refreshStore: () => void
}
export const StepSummary: React.FC<StoreProps & Values & StepsProps> = ({
  workshops,
  values,
  setStep,
  refreshStore,
}) => {
  const { _ } = useDictionary(dictionary("reservation", "summary"))
  const { _: __ } = useDictionary(dictionary("reservation"))
  const isAdmin = useIsAdmin()
  const workshop = React.useMemo(
    () => A.find(workshops, workshop => workshop.id === values.workshop),
    [values.workshop, workshops]
  )

  // send reservation
  const onSubmit = async () => {
    if (isAdmin) return error("admin")
    const payload = {
      ...D.selectKeys(values, ["students", "language", "message"]),
      contact: D.selectKeys(values, [
        "schoolName",
        "schoolStreet",
        "schoolZip",
        "schoolCity",
        "schoolClass",
        "teacherName",
        "teacherEmail",
        "teacherPhone",
      ]),
    }

    match(await service.workshops.reservation(values.workshop, values.event, payload))
      .with({ error: false }, () => confirm())
      .otherwise(({ code }) => {
        refreshStore()
        error(
          match(code)
            .with("RESOURCE_NOT_FOUND", () => "not-found" as const)
            .with("NO_SLOT_AVAILABLE", () => "no-slot-available" as const)
            .otherwise(() => "unknown" as const)
        )
      })
  }

  // dialogs
  const { setItem: confirm, ...confirmProps } = useDialog<void>({
    afterClose: () => navigate("/kulturrallye"),
  })
  const { setItem: error, ...errorProps } = useDialog<
    "not-found" | "no-slot-available" | "admin" | "unknown"
  >()
  const backToWorkshop = () => navigate(`/kulturrallye/places/${workshop?.placeId}/${workshop?.id}`)
  const backToReservation = () => setStep("reservation")
  return (
    <>
      <StepperContent value="summary" title={_("title")}>
        {(workshop ||
          N.gt(values.students, 0) ||
          oneIsNotEmpty(values.language, values.message)) && (
          <div className="flex flex-col gap-2">
            {workshop && <h3 className="font-bold uppercase">{workshop.name}</h3>}
            <ul className="flex flex-wrap gap-x-8 gap-y-2">
              {N.gt(values.students, 0) && (
                <li className="flex items-center gap-2.5">
                  <Student className="text-tomato h-5" aria-hidden />
                  {_("students")}
                  {values.students}
                </li>
              )}
              {oneIsNotEmpty(values.language) && (
                <li className="flex items-center gap-2.5">
                  <Globe className="text-tomato h-5" aria-hidden />
                  {_("language")}
                  {__(`reservation.language-${S.toLowerCase(values.language) as "en"}`, {
                    defaultValue: values.language,
                  })}
                </li>
              )}
            </ul>
            {oneIsNotEmpty(values.message) && (
              <>
                <h4 className="font-bold pt-2">{_("message")}</h4>
                <p>{values.message}</p>
              </>
            )}
          </div>
        )}

        {oneIsNotEmpty(
          values.schoolName,
          values.schoolStreet,
          values.schoolZip,
          values.schoolCity,
          values.schoolClass
        ) && (
          <div className="flex flex-col gap-2">
            <h3 className="font-bold uppercase">{_("school")}</h3>
            <ul className="flex flex-col gap-y-2">
              {oneIsNotEmpty(values.schoolName) && (
                <li className="font-medium">{values.schoolName}</li>
              )}
              {oneIsNotEmpty(values.schoolStreet, values.schoolZip, values.schoolCity) && (
                <li>
                  {oneIsNotEmpty(values.schoolStreet) && (
                    <span className="block">{values.schoolStreet}</span>
                  )}
                  {oneIsNotEmpty(values.schoolZip, values.schoolCity) && (
                    <span className="block">
                      {values.schoolZip} {values.schoolCity}
                    </span>
                  )}
                </li>
              )}
              {oneIsNotEmpty(values.schoolClass) && (
                <li>
                  <span className="font-medium">{_("school-class")}</span>
                  {values.schoolClass}
                </li>
              )}
            </ul>
          </div>
        )}

        {oneIsNotEmpty(values.teacherName, values.teacherEmail, values.teacherPhone) && (
          <div className="flex flex-col gap-2">
            <h3 className="font-bold uppercase">{_("contact")}</h3>
            <ul className="flex flex-wrap gap-x-8 gap-y-2">
              {oneIsNotEmpty(values.teacherName) && (
                <li className="flex items-center gap-2.5 min-w-full">{values.teacherName}</li>
              )}
              {oneIsNotEmpty(values.teacherEmail) && (
                <li className="flex items-center gap-2.5">
                  <Envelope className="text-tomato h-5" aria-hidden />
                  {values.teacherEmail}
                </li>
              )}
              {oneIsNotEmpty(values.teacherPhone) && (
                <li className="flex items-center gap-2.5">
                  <Phone className="text-tomato h-5" aria-hidden />
                  {values.teacherPhone}
                </li>
              )}
            </ul>
          </div>
        )}
        <div className="flex justify-between">
          <Button onClick={() => setStep("contact")} variant="outline">
            {_("previous")}
          </Button>
          <Button onClick={onSubmit}>{_("next")}</Button>
        </div>
      </StepperContent>
      <ConfirmDialog {...confirmProps} backToWorkshop={backToWorkshop} />
      <ErrorDialog
        {...errorProps}
        backToReservation={backToReservation}
        backToWorkshop={backToWorkshop}
      />
    </>
  )
}
