import Layout from "@/components/layout/dashboard"
import Index from "@/pages/dashboard"
import ArticlesIndex from "@/pages/dashboard/articles"
import ArticlesArticle from "@/pages/dashboard/articles/article"
import ArticlesCategories from "@/pages/dashboard/articles/categories"
import DashboardKultureralley from "@/pages/dashboard/kulturrallye"
import DashboardKultureralleyPlace from "@/pages/dashboard/kulturrallye/place"
import DashboardKultureralleyPlaceWorkshop from "@/pages/dashboard/kulturrallye/place/workshop"
import DashboardKultureralleyReservations from "@/pages/dashboard/kulturrallye/reservations"
import LanguagesIndex from "@/pages/dashboard/languages"
import MediasIndex from "@/pages/dashboard/medias"
import OffersIndex from "@/pages/dashboard/offers"
import OffersOffer from "@/pages/dashboard/offers/offer"
import PagesIndex from "@/pages/dashboard/pages"
import PagesPage from "@/pages/dashboard/pages/page"
import ProgramsIndex from "@/pages/dashboard/programs"
import ProgramsProgram from "@/pages/dashboard/programs/program"
import ProjectsIndex from "@/pages/dashboard/projects"
import ProjectsProject from "@/pages/dashboard/projects/project"
import UsersIndex from "@/pages/dashboard/users"
import UsersUser from "@/pages/dashboard/users/user"
import { Redirect, Route, Switch } from "wouter"

/**
 * DashboardRoutes
 */
const DashboardRoutes: React.FC = () => {
  const isAuth = true
  if (!isAuth) return <Redirect to="/" />
  return (
    <Layout>
      <Switch>
        {/* medias */}
        <Route path="/dashboard/medias/:folderId*">
          {params => <MediasIndex id={params.folderId} />}
        </Route>
        {/* users */}
        <Route path="/dashboard/users">
          <UsersIndex />
        </Route>
        <Route path="/dashboard/users/:id">{params => <UsersUser {...params} />}</Route>
        {/* languages */}
        <Route path="/dashboard/languages">
          <LanguagesIndex />
        </Route>
        {/* pages */}
        <Route path="/dashboard/pages">
          <PagesIndex />
        </Route>
        <Route path="/dashboard/pages/:id">{params => <PagesPage {...params} />}</Route>
        {/* articles */}
        <Route path="/dashboard/articles">
          <ArticlesIndex />
        </Route>
        <Route path="/dashboard/articles/categories">
          <ArticlesCategories />
        </Route>
        <Route path="/dashboard/articles/:id">{params => <ArticlesArticle {...params} />}</Route>
        {/* offers */}
        <Route path="/dashboard/offers">
          <OffersIndex />
        </Route>
        <Route path="/dashboard/offers/:id">{params => <OffersOffer {...params} />}</Route>
        {/* projects */}
        <Route path="/dashboard/projects">
          <ProjectsIndex />
        </Route>
        <Route path="/dashboard/projects/:id">{params => <ProjectsProject {...params} />}</Route>
        {/* kultureralley */}
        <Route path="/dashboard/kultureralley">
          <DashboardKultureralley />
        </Route>
        <Route path="/dashboard/kultureralley/reservations">
          <DashboardKultureralleyReservations />
        </Route>
        <Route path="/dashboard/kultureralley/:id">
          {params => <DashboardKultureralleyPlace {...params} />}
        </Route>
        <Route path="/dashboard/kultureralley/:placeId/:id">
          {params => <DashboardKultureralleyPlaceWorkshop {...params} />}
        </Route>
        {/* programs */}
        <Route path="/dashboard/programs">
          <ProgramsIndex />
        </Route>
        <Route path="/dashboard/programs/:id">{params => <ProgramsProgram {...params} />}</Route>
        {/* home */}
        <Route path="/dashboard">
          <Index />
        </Route>
        <Redirect to="/dashboard" />
      </Switch>
    </Layout>
  )
}
export default DashboardRoutes
