/**
 * sort
 */

export const sort = <X>(xs: X[], compare: (a: X, b: X) => boolean | number) => {
  const list = Array.from(xs)

  // sort cloned list
  list.sort((a, b) => {
    const result = compare(a, b)
    if (typeof result === "boolean") return result ? 1 : -1
    return result
  })

  return list
}

/**
 * sortByProp
 */

export const sortByProp: SortByPropOverload = (
  sortings: Sortings<object> | SortingsList<object>,
  xs?: object[],
) => {
  if (!Array.isArray(xs)) return (xs: object[]) => sortByProp(sortings, xs)

  const sortingsList = Array.isArray(sortings) ? sortings : [sortings]

  return sortingsList.reduceRight((list, sortings) => {
    const sortingKeys = Object.keys(sortings)

    return sortingKeys.reduce((list, key) => {
      const compare = sortings[key]
      if (compare) return sort(list, (a: any, b: any) => compare(a[key], b[key]))
      return list
    }, list)
  }, xs)
}

type SortByPropOverload = {
  <X extends object>(sortings: Sortings<X> | SortingsList<X>, xs: X[]): X[]
  <X extends object>(sortings: Sortings<X> | SortingsList<X>): (xs: X[]) => X[]
}

type Sortings<X> = {
  [Property in keyof X]?: (a: X[Property], b: X[Property]) => boolean | number
}

type SortingsList<X> = Sortings<X>[]
