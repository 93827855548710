import { PageRender } from "@/components/layout/frontend/page"

/**
 * Page: Accessibility
 */
const Accessibility: React.FC = () => {
  return (
    <>
      <PageRender pageKey="accessibility" />
    </>
  )
}
export default Accessibility
