import { byId } from "@/fns/byId"
import * as Programs from "@/services/programs/service"
import { match } from "ts-pattern"
import { programsStore } from "."
import { localizeProgram } from "./localizers"

/**
 * getPrograms
 */
export const getPrograms = async () =>
  match(await Programs.service.index())
    .with({ error: false }, ({ data }) => {
      programsStore.evolve({ programs: byId(data.programs, localizeProgram) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getProgram
 */
export const getProgram = async (id: string) =>
  match(await Programs.service.read(id))
    .with({ error: false }, ({ data }) => {
      programsStore.evolve({ programs: D.set(data.program.id, localizeProgram(data.program)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createProgram
 */
export const createProgram = async (payload: Programs.Payload["create"]) =>
  match(await Programs.service.create(payload))
    .with({ error: false }, ({ data }) => {
      programsStore.evolve({ programs: D.set(data.program.id, localizeProgram(data.program)) })
      return { error: false, id: data.program.id } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateProgram
 */
export const updateProgram = async (id: string, payload: Programs.Payload["update"]) =>
  match(await Programs.service.update(id, payload))
    .with({ error: false }, ({ data }) => {
      programsStore.evolve({ programs: D.set(data.program.id, localizeProgram(data.program)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteProgram
 */
export const deleteProgram = async (id: string) =>
  match(await Programs.service.delete(id))
    .with({ error: false }, () => {
      programsStore.evolve({ programs: D.deleteKey(id) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))
