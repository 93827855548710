/**
 * Note
 */

export const Note: React.FC<React.ComponentProps<"div">> = props => {
  return (
    <div className="text-sm py-10 text-gray w-full flex flex-col gap-4 text-center justify-center items-center [&_svg]:w-7 [&_svg]:h-auto">
      {props.children}
    </div>
  )
}
