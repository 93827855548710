import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Button } from "@/components/ui/button"
import { AlertTriangle, Bug } from "lucide-react"
import { Breadcrumb } from "../layout/dashboard/breadcrumbs"

/**
 * Error
 */
export const Error: React.FC<{ breadcrumbs: Breadcrumb[]; title: string; description: string }> = ({
  breadcrumbs,
  title,
  description,
}) => {
  const { _ } = useDictionary("components.layout.boundary-error")
  const refresh = () => location.reload()
  const home = () => navigate("/")
  return (
    <PageWrapper>
      <PageHeader
        breadcrumbs={[
          ...breadcrumbs,
          [<AlertTriangle size={16} aria-label={title} key="alert-triangle" />, ""],
        ]}
      />
      <div className="flex flex-col items-center max-w-sm mx-auto text-center gap-2">
        <Bug aria-hidden className="text-muted-foreground my-8" size={48} strokeWidth={1} />
        <h2 className="text-2xl font-semibold">{title}</h2>
        <p className="text-sm text-muted-foreground">{description}</p>
        <div className="flex gap-2 pt-4">
          <Button onClick={refresh}>{_("button-refresh")}</Button>
          <Button onClick={home} variant="secondary">
            {_("button-home")}
          </Button>
        </div>
      </div>
    </PageWrapper>
  )
}
