import { byId } from "@/fns/byId"
import * as Contents from "@/services/contents/service"
import * as Pages from "@/services/pages/service"
import * as Seos from "@/services/seos/service"
import { match } from "ts-pattern"
import { pagesStore } from "."
import { extractFilesFromContent, extractFilesFromContentItem } from "../contents/helpers"
import { localizeContent, localizeContentItem } from "../contents/localizers"
import { mediasStore } from "../medias"
import { localizeMediaFile } from "../medias/localizers"
import { extractFilesFromSeo } from "../seos/helpers"
import { localizeSeo } from "../seos/localizers"
import { extractFilesFromPage, extractFilesFromPages } from "./helpers"
import { localizePage } from "./localizers"

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * pages
 */

/**
 * getPages
 */
export const getPages = async () =>
  match(await Pages.service.index())
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromPages(data.pages), localizeMediaFile)),
      })
      pagesStore.evolve({ pages: byId(data.pages, localizePage) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createPage
 */
export const createPage = async (payload: Pages.Payload["create"]) =>
  match(await Pages.service.create(payload))
    .with({ error: false }, ({ data }) => {
      pagesStore.evolve({ pages: D.set(data.page.id, localizePage(data.page)) })
      return { error: false, id: data.page.id } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getPage
 */
export const getPage = async (id: string) =>
  match(await Pages.service.read(id))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromPage(data.page), localizeMediaFile)),
      })
      pagesStore.evolve({ pages: D.set(data.page.id, localizePage(data.page)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updatePage
 */
export const updatePage = async (id: string, payload: Pages.Payload["create"]) =>
  match(await Pages.service.update(id, payload))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromPage(data.page), localizeMediaFile)),
      })
      pagesStore.evolve({ pages: D.set(data.page.id, localizePage(data.page)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deletePage
 */
export const deletePage = async (id: string) =>
  match(await Pages.service.delete(id))
    .with({ error: false }, () => {
      pagesStore.evolve({ pages: D.deleteKey(id) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * seos
 */

/**
 * updatePageSeo
 */
export const updatePageSeo = async (id: string, payload: Seos.Payload["update"]) =>
  match(await Seos.service.update("pages", id, payload))
    .with({ error: false }, async ({ data }) => {
      if (G.isNullable(D.get(pagesStore.current.pages, id))) return await getPage(id)
      mediasStore.evolve({ files: D.merge(byId(extractFilesFromSeo(data.seo), localizeMediaFile)) })
      pagesStore.evolve({
        pages: {
          [id]: {
            seo: localizeSeo(data.seo),
          },
        },
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/** ****** ****** ****** ****** ****** ****** ****** ****** ****** ******
 * contents
 */

/**
 * updatePageContent
 */
export const updatePageContent = async (id: string, payload: Contents.Payload["update"]) =>
  match(await Contents.service.update("pages", id, payload))
    .with({ error: false }, async ({ data }) => {
      if (G.isNullable(D.get(pagesStore.current.pages, id))) return await getPage(id)
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromContent(data.content), localizeMediaFile)),
      })
      pagesStore.evolve({
        pages: {
          [id]: {
            content: localizeContent(data.content),
          },
        },
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createPageContentItem
 */
export const createPageContentItem = async (
  id: string,
  payload: Contents.Payload["items"]["create"]
) =>
  match(await Contents.service.items.create("pages", id, payload))
    .with({ error: false }, async ({ data }) => {
      if (G.isNullable(D.get(pagesStore.current.pages, id))) return await getPage(id)
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromContentItem(data.item), localizeMediaFile)),
      })
      pagesStore.evolve({
        pages: {
          [id]: {
            content: {
              items: flow(
                D.set(data.item.id, localizeContentItem(data.item)),
                D.map(item =>
                  D.set(item, "order", A.getIndexBy(data.sortedIds, id => id === item.id) ?? 0)
                )
              ),
            },
          },
        },
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updatePageContentItem
 */
export const updatePageContentItem = async (
  id: string,
  itemId: string,
  payload: Contents.Payload["items"]["update"]
) =>
  match(await Contents.service.items.update("pages", id, itemId, payload))
    .with({ error: false }, async ({ data }) => {
      if (G.isNullable(D.get(pagesStore.current.pages, id))) return await getPage(id)
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromContentItem(data.item), localizeMediaFile)),
      })
      pagesStore.evolve({
        pages: {
          [id]: {
            content: {
              items: D.set(data.item.id, localizeContentItem(data.item)),
            },
          },
        },
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deletePageContentItem
 */
export const deletePageContentItem = async (id: string, itemId: string) =>
  match(await Contents.service.items.delete("pages", id, itemId))
    .with({ error: false }, async () => {
      if (G.isNullable(D.get(pagesStore.current.pages, id))) return await getPage(id)
      pagesStore.evolve({
        pages: {
          [id]: {
            content: {
              items: D.deleteKey(itemId),
            },
          },
        },
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * reorderPageContentItems
 */
export const reorderPageContentItems = async (
  id: string,
  payload: Contents.Payload["items"]["reorder"]
) => {
  // get page from store if it's not there get it from api
  if (G.isNullable(D.get(pagesStore.current.pages, id))) {
    const response = await getPage(id)
    if (response.error) return { error: true, code: response.code } as const
  }

  // optimist update with new order before sending request
  pagesStore.evolve({
    pages: {
      [id]: {
        content: {
          items: flow(
            D.map(item =>
              D.set(item, "order", A.getIndexBy(payload.items, id => id === item.id) ?? 0)
            )
          ),
        },
      },
    },
  })

  return match(await Contents.service.items.reorder("pages", id, payload))
    .with({ error: false }, async () => ({ error: false } as const))
    .otherwise(({ error, code }) => ({ error, code } as const))
}

/**
 * cmsActions
 */
export const cmsActions = {
  updateSeo: updatePageSeo,
  updateContent: updatePageContent,
  createContentItem: createPageContentItem,
  updateContentItem: updatePageContentItem,
  deleteContentItem: deletePageContentItem,
  reorderContentItems: reorderPageContentItems,
}
