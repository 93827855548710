import { api, AuthErrorCode } from "@/services/api"
import { z } from "zod"
import { ApiSeo, apiSeo, apiSeoSocialMeta } from "./schemas"

/**
 * schemas
 */
const updatePayload = z.object({
  translations: z.array(
    z.object({
      languageId: z.string().uuid(),
      title: z.string(),
      description: z.string(),
      keywords: z.array(z.string()),
      socials: apiSeoSocialMeta.array().optional(),
      imageId: z.string().uuid().nullable(),
    })
  ),
})
export type Payload = {
  update: z.infer<typeof updatePayload>
}

/**
 * service
 */
export const service = {
  update: async (model: string, modelId: string, payload: Payload["update"]) => {
    type RSuccess = { seo: ApiSeo }
    type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
    const { success, data } = await api.put<RSuccess, RError>(`${model}/${modelId}/seo`, {
      data: updatePayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { seo: apiSeo.parse(data.seo) }, error: false } as const
  },
}
