import { useCmsContext } from "@/components/cms/Context"
import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSection,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
} from "@/components/form"
import { FormMediasImages } from "@/components/medias/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { ImageOff } from "lucide-react"
import { match } from "ts-pattern"
import { useForm } from "use-a11y-form"
import { FormSelectTemplate } from "../../form"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)
type Payload = FormPayload<ItemType>

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary())
  const { _: __ } = useDictionary(contextMapper("components", "cms", "content", "form"))
  const _f = useFormDictionary()
  const _e = useErrorsDictionary()

  const {
    id,
    actions: { updateContentItem },
  } = useCmsContext()
  const languages = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(item.props, ["template", "images"]),
      titleLevel: `${item.props.titleLevel}`,
      translations: D.map(languages, language => ({
        languageId: language.id,
        title: translate(item, language)?.props.title ?? "",
        secondary: translate(item, language)?.props.secondary ?? "",
      })),
    })),
    onSubmit: async ({ values }) => {
      values.translations
      const payload: Payload = {
        props: {
          ...D.selectKeys(values, ["template", "images"]),
          titleLevel: +values.titleLevel,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: values.images,
      }

      return match(await updateContentItem(item.id, payload))
        .with({ error: false }, () => {
          toast.success(__("success"))
          close()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _e)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_e(code)))
        )
    },
  })

  const titleLevelOptions = useTitleLevelOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />

      <FormTranslationTabs context={false}>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormSection>
              <FormHeader>
                <FormHeader.Title>{_("section-header-title")}</FormHeader.Title>
                <FormHeader.Description>{_("section-header-description")}</FormHeader.Description>
              </FormHeader>
              <FormTranslationContext language={language}>
                <FormInput
                  name="title"
                  label={__("title-label")}
                  placeholder={__("title-placeholder")}
                  translatable
                />
              </FormTranslationContext>
              <FormSelect
                options={titleLevelOptions}
                name="titleLevel"
                label={__("title-level-label")}
              />
              <FormTranslationContext language={language}>
                <FormTiptap
                  name="secondary"
                  label={__("secondary-label")}
                  placeholder={__("secondary-placeholder")}
                  translatable
                />
              </FormTranslationContext>
            </FormSection>

            <FormSection>
              <FormHeader>
                <FormHeader.Title>{_("section-images-title")}</FormHeader.Title>
                <FormHeader.Description>{_("section-images-description")}</FormHeader.Description>
              </FormHeader>
              <FormMediasImages name="images" label={_("images-label")} contextKey={id} />
            </FormSection>

            <FormSection>
              <FormHeader>
                <FormHeader.Title>{_("section-template-title")}</FormHeader.Title>
                <FormHeader.Description>{_("section-template-description")}</FormHeader.Description>
              </FormHeader>
              <FormSelectTemplate
                name="template"
                options={[Template1, Template2, Template3, Template4]}
                label={_("template-label")}
              />
            </FormSection>
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_f("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_f("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * templates
 */
const Template1: React.FC = () => (
  <div className="grid grid-cols-3 gap-[3px] p-2 [&_svg]:stroke-[1] [&_svg]:size-2 [&_div]:flex [&>div]:justify-start [&>div]:flex-col [&>div]:gap-[3px] [&>div>div]:justify-center [&>div>div]:items-center [&>div>div]:rounded-sm [&>div>div]:bg-muted [&>div>div]:text-muted-foreground">
    <div>
      <div className="aspect-[3/2]">
        <ImageOff />
      </div>
      <div className="aspect-square">
        <ImageOff />
      </div>
      <div className="aspect-[3/2]">
        <ImageOff />
      </div>
    </div>
    <div>
      <div className="aspect-[4/5]">
        <ImageOff />
      </div>
      <div className="aspect-square">
        <ImageOff />
      </div>
    </div>
    <div>
      <div className="aspect-[3/2]">
        <ImageOff />
      </div>
      <div className="aspect-square">
        <ImageOff />
      </div>
      <div className="aspect-[3/2]">
        <ImageOff />
      </div>
    </div>
  </div>
)
const Template2: React.FC = () => (
  <div className="flex flex-col justify-center h-full gap-[3px] p-2 [&_svg]:stroke-[1] [&_svg]:size-2 [&>div]:flex [&>div]:flex-wrap [&>div]:gap-[3px] [&>div>div]:flex [&>div>div]:justify-center [&>div>div]:items-center [&>div>div]:rounded-sm [&>div>div]:bg-muted [&>div>div]:text-muted-foreground">
    <div className="[&>div]:h-7">
      <div className="aspect-[3/2]">
        <ImageOff />
      </div>
      <div className="aspect-square">
        <ImageOff />
      </div>
      <div className="grow">
        <ImageOff />
      </div>
    </div>
    <div className="[&>div]:h-6">
      <div className="aspect-[3/2]">
        <ImageOff />
      </div>
      <div className="aspect-square">
        <ImageOff />
      </div>
      <div className="grow">
        <ImageOff />
      </div>
    </div>
    <div className="[&>div]:h-5">
      <div className="aspect-[4/5]">
        <ImageOff />
      </div>
      <div className="aspect-video">
        <ImageOff />
      </div>
      <div className="aspect-[4/5]">
        <ImageOff />
      </div>
      <div className="grow">
        <ImageOff />
      </div>
    </div>
  </div>
)
const Template3: React.FC = () => (
  <div className="grid grid-cols-3 gap-[3px] h-4/5 m-2 [&_svg]:stroke-[1] [&_svg]:size-2 [&>div]:grid [&>div]:h-full [&>div]:gap-[3px] [&>div>div]:flex [&>div>div]:justify-center [&>div>div]:items-center [&>div>div]:rounded-sm [&>div>div]:bg-muted [&>div>div]:text-muted-foreground">
    <div className="grid-rows-[3fr_5fr_4fr]">
      <div>
        <ImageOff />
      </div>
      <div>
        <ImageOff />
      </div>
      <div>
        <ImageOff />
      </div>
    </div>
    <div className="grid-rows-[2fr_5fr_2fr_3fr]">
      <div>
        <ImageOff />
      </div>
      <div>
        <ImageOff />
      </div>
      <div>
        <ImageOff />
      </div>
      <div>
        <ImageOff />
      </div>
    </div>
    <div className="grid-rows-[4fr_4fr_4fr]">
      <div>
        <ImageOff />
      </div>
      <div>
        <ImageOff />
      </div>
      <div>
        <ImageOff />
      </div>
    </div>
  </div>
)
const Template4: React.FC = () => (
  <div className="grid grid-cols-3 grid-rows-3 gap-[3px] h-4/5 m-2 [&_svg]:stroke-[1] [&_svg]:size-2 [&>div]:grid [&>div]:h-full [&>div]:gap-[3px] [&>div>div]:flex [&>div]:justify-center [&>div]:items-center [&>div]:rounded-sm [&>div]:bg-muted [&>div]:text-muted-foreground">
    <div>
      <ImageOff />
    </div>
    <div>
      <ImageOff />
    </div>
    <div>
      <ImageOff />
    </div>
    <div>
      <ImageOff />
    </div>
    <div>
      <ImageOff />
    </div>
    <div>
      <ImageOff />
    </div>
    <div>
      <ImageOff />
    </div>
  </div>
)
