import { HN } from "@/components/ui/hn"
import { Image } from "@/components/ui/image"
import { Link, LinkExternal } from "@/components/ui/link"
import { SrOnly } from "@/components/ui/sr-only"
import { oneIsNotEmpty } from "@/fns/String"
import { usePromise } from "@/hooks/usePromise"
import { useArticlesStore } from "@/store/articles"
import { getArticleCategories, getArticles } from "@/store/articles/actions"
import { useArticleCategory } from "@/store/articles/hooks"
import { Article } from "@/store/articles/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { getPreview } from "@/store/medias/helpers"
import { useMediasFile } from "@/store/medias/hooks"
import { ChevronRight } from "lucide-react"
import { match } from "ts-pattern"
import { frontendButtonVariants } from "../../frontend/button"
import { container } from "../../frontend/container"
import { ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  usePromise(getArticles)
  usePromise(getArticleCategories)
  const articles = useArticlesStore(
    flow(
      D.getUnsafe("articles"),
      D.values,
      A.filter(article => article.type === "hero"),
      A.sortBy(({ publishedAt }) => publishedAt),
      A.take(3)
    )
  )

  const { titleLevel } = item.props
  const { title, linkText, linkUrl } = t(item).props
  if (A.isEmpty(articles)) return null
  return (
    <div className={cx("flex flex-col py-8 gap-8 font-plus", container({ x: "sm" }))}>
      {oneIsNotEmpty(title) && (
        <div className="flex justify-between gap-4">
          {oneIsNotEmpty(title) && (
            <HN
              level={titleLevel}
              className="text-xl lg:text-[30px] leading-[48px] font-extrabold text-frontend-tomato max-w-xl"
            >
              {title}
            </HN>
          )}
          {oneIsNotEmpty(linkUrl) && (
            <LinkExternal
              className={frontendButtonVariants({ variant: "secondary" })}
              href={linkUrl}
            >
              {linkText}
              <ChevronRight size={16} />
            </LinkExternal>
          )}
        </div>
      )}
      <div className="flex flex-col lg:grid grid-rows-2 grid-cols-2 gap-4">
        {A.mapWithIndex(
          articles,
          (index, article) =>
            match(index)
              .with(0, () => <Template1 {...{ article }} key={article.id} />)
              .with(1, () => <Template2 {...{ article }} key={article.id} />)
              .with(2, () => <Template3 {...{ article }} key={article.id} />)
              .otherwise(() => null) // never with take(3)
        )}
      </div>
    </div>
  )
}

/**
 * templates
 */
type TemplateProps = { article: Article }
const Template1: React.FC<TemplateProps> = ({ article }) => {
  const t = useTranslation()
  const { title, image } = t(article.seo)
  const file = useMediasFile(image)
  const category = useArticleCategory(article.category)

  return (
    <div className="relative flex items-end row-span-2 aspect-square lg:aspect-auto">
      {G.isNotNullable(file) && (
        <Image
          src={getPreview(file)}
          alt={t(file).alt}
          className="absolute inset-0 w-full h-full object-cover"
        />
      )}
      <div className="absolute inset-0 bg-gradient-to-b from-frontend-orient/20 to-frontend-orient/80" />
      <div className="relative flex flex-col p-8 gap-3.5 text-white font-bold">
        {G.isNotNullable(category) && (
          <p className="uppercase text-xs lg:text-base">{t(category).name}</p>
        )}
        <h4 className="lg:text-3xl font-bold">{title}</h4>
      </div>
      <Link href={`/dashboard/articles/${article.id}`} className="absolute inset-0 size-full">
        <SrOnly>{title}</SrOnly>
      </Link>
    </div>
  )
}
const Template2: React.FC<TemplateProps> = ({ article }) => {
  const t = useTranslation()
  const { title } = t(article.seo)
  const category = useArticleCategory(article.category)

  return (
    <div className="relative flex items-end bg-frontend-bluewhale lg:min-h-[16rem]">
      <div className="flex flex-col p-8 gap-3.5 text-white font-bold">
        {G.isNotNullable(category) && (
          <p className="text-frontend-solitude uppercase text-xs lg:text-base">
            {t(category).name}
          </p>
        )}
        <h4 className="lg:text-3xl font-bold">{title}</h4>
      </div>
      <Link href={`/dashboard/articles/${article.id}`} className="absolute inset-0 size-full">
        <SrOnly>{title}</SrOnly>
      </Link>
    </div>
  )
}
const Template3: React.FC<TemplateProps> = ({ article }) => {
  const t = useTranslation()
  const { title, image } = t(article.seo)
  const category = useArticleCategory(article.category)
  const file = useMediasFile(image)

  return (
    <div className="relative flex flex-col-reverse lg:grid grid-cols-3 lg:min-h-[16rem] bg-frontend-solitude">
      <div className="flex flex-col justify-center col-span-2 p-8 gap-3.5">
        {G.isNotNullable(category) && (
          <p className="text-frontend-orient uppercase text-xs md:text-base font-bold">
            {t(category).name}
          </p>
        )}
        <h4 className="text-frontend-bluewhale md:text-[20px] font-extrabold">{title}</h4>
      </div>
      <div>
        {G.isNotNullable(file) && (
          <Image
            src={getPreview(file)}
            alt={t(file).alt}
            className="w-full lg:h-full aspect-[16/5] lg:aspect-auto object-cover"
          />
        )}
      </div>
      <Link href={`/dashboard/articles/${article.id}`} className="absolute inset-0 size-full">
        <SrOnly>{title}</SrOnly>
      </Link>
    </div>
  )
}
