/// <reference types="vite-plugin-svgr/client" />
import { Prose } from "@/components/frontend/prose"
import { Image } from "@/components/ui/image"
import { useLightboxFiles } from "@/components/ui/lightbox"
import { SrOnly } from "@/components/ui/sr-only"
import { truncateText } from "@/fns/String"
import { prependProtocol } from "@/fns/path"
import { Project } from "@/store/frontend/localizers"
import { ArrowSquareOut, DownloadSimple, Student } from "@phosphor-icons/react"
import saveAs from "file-saver"
import { dictionary, htmlIsNotEmpty } from "."

/**
 * Content
 */
export const Content: React.FC<{ project: Project }> = ({ project }) => {
  const { _ } = useDictionary(dictionary())
  const { renderLightbox, lightbox } = useLightboxFiles(D.values(project.images))
  const { language } = project
  return (
    <div className="flex flex-col w-full pb-10">
      <Section
        className="flex-row flex-wrap gap-2.5"
        on={A.isNotEmpty(project.partners) || A.isNotEmpty(project.classes)}
      >
        <Tag
          icon={<ArrowSquareOut size={20} aria-hidden />}
          label={A.getUnsafe(project.partners, 0).name}
          on={A.isNotEmpty(project.partners)}
          lang={language}
        >
          <a
            href={prependProtocol(A.getUnsafe(project.partners, 0).value)}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {A.getUnsafe(project.partners, 0).value}
          </a>
        </Tag>
        <Tag
          icon={<Student size={20} aria-hidden />}
          label={A.getUnsafe(project.classes, 0).name}
          on={A.isNotEmpty(project.classes)}
          lang={language}
        >
          {A.getUnsafe(project.classes, 0).value}
        </Tag>
      </Section>
      <Section divider id="description">
        <Title>{_("title-description")}</Title>
        <p lang={language}>{project.description}</p>
      </Section>
      <Section divider id="skills" on={A.isNotEmpty(project.skills)}>
        <Title id="skills-title">{_("title-skills")}</Title>
        <Prose lang={language}>
          <ul aria-describedby="skills-title">
            {A.mapWithIndex(project.skills, (index, skill) => (
              <li key={index}>
                <strong>{skill.name}</strong>
                &nbsp;&nbsp;&nbsp; ({skill.value})
              </li>
            ))}
          </ul>
        </Prose>
      </Section>
      <Section divider id="objective" on={htmlIsNotEmpty(project.objective)}>
        <Title>{_("title-objective")}</Title>
        <Prose dangerouslySetInnerHTML={{ __html: project.objective }} lang={language} />
      </Section>
      <Section divider id="materials" on={A.isNotEmpty(project.materials)}>
        <Title id="materials-title">{_("title-materials")}</Title>
        <ul className="flex gap-2.5" aria-describedby="materials-title" lang={language}>
          {A.mapWithIndex(project.materials, (index, material) => (
            <li
              key={index}
              className="flex items-center border border-mercury h-[50px] px-5 text-sm font-light"
            >
              {material}
            </li>
          ))}
        </ul>
      </Section>
      <Section divider id="preparation" on={htmlIsNotEmpty(project.preparation)}>
        <Title>{_("title-preparation")}</Title>
        <Prose dangerouslySetInnerHTML={{ __html: project.preparation }} lang={language} />
      </Section>
      <Section divider id="follow" on={htmlIsNotEmpty(project.follow)}>
        <Title>{_("title-follow")}</Title>
        <Prose dangerouslySetInnerHTML={{ __html: project.follow }} lang={language} />
      </Section>
      <Section divider id="values" on={htmlIsNotEmpty(project.values)}>
        <Title>{_("title-values")}</Title>
        <Prose dangerouslySetInnerHTML={{ __html: project.values }} lang={language} />
      </Section>
      <Section id="documents" on={D.isNotEmpty(project.documents)}>
        <Title id="documents-title">{_("title-documents")}</Title>
        <ul className="flex flex-col gap-3 mt-[15px]">
          {A.mapWithIndex(D.values(project.documents), (index, { url, name }) => (
            <li key={index}>
              <button
                type="button"
                onClick={() => saveAs(url, name)}
                className="flex justify-center items-center gap-2.5 outline-none text-left focus-visible:underline text-emperor underline"
              >
                <DownloadSimple className="h-5 shrink-0 text-primary-500" aria-hidden />
                <SrOnly>{_("form.documents-download")}</SrOnly>
                <span className="line-clamp-1">{truncateText(name, 25)}</span>
              </button>
            </li>
          ))}
        </ul>
      </Section>
      <Section id="images" on={D.isNotEmpty(project.images)}>
        <Title id="documents-title">{_("title-images")}</Title>
        <ul className="mt-4 gap-5 columns-1 sm:columns-2 md:columns-3 xl:columns-4">
          {A.map(D.values(project.images), image => (
            <li className="mb-5 relative" key={image.id}>
              <Image src={image.url} alt={image.name} className="rounded-lg" />
              <button
                className="absolute inset-0 size-full rounded-lg"
                onClick={() => lightbox(image.id)}
              >
                <SrOnly>{_("display-image", { name: image.name })}</SrOnly>
              </button>
            </li>
          ))}
        </ul>
        {renderLightbox()}
      </Section>
    </div>
  )
}

/**
 * components
 */
const Section: React.FC<
  { divider?: true; on?: boolean } & React.HTMLAttributes<HTMLDivElement>
> = ({ divider = false, className, on = true, ...props }) =>
  on ? (
    <div
      className={cx(
        "flex flex-col gap-5 pt-10",
        divider && "border-b border-mercury pb-10",
        className
      )}
      {...props}
    />
  ) : null

const Title: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, ...props }) => (
  <h2 className={cx("text-2xl font-medium", className)} {...props} />
)

type TagProps = {
  icon: React.ReactNode
  label: string
  on?: boolean
} & React.HTMLAttributes<HTMLHeadingElement>
const Tag: React.FC<TagProps> = ({ icon, label, on = true, className, children, ...props }) =>
  on ? (
    <div
      className={cx(
        "flex justify-center grow lg:grow-0 items-center h-[60px] gap-2 border border-mercury px-5 text-sm text-shark [&>svg]:h-5 [&>svg]:text-tomato [&>svg]:shrink-0",
        className
      )}
      {...props}
    >
      {icon}
      <span className="font-bold">{label}:</span>
      {children}
    </div>
  ) : null
