import { useCmsContext } from "@/components/cms/Context"
import {
  Form,
  FormAssertive,
  FormFieldGroup,
  FormHeader,
  FormInput,
  FormReorderableItem,
  FormReorderableList,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
  useForm,
  useFormContext,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { textPlaceholder } from "@/fns/String"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { ContentItemBase } from "@/store/contents/localizers"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { Language } from "@/store/languages/localizers"
import { DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { RectangleHorizontal, Trash } from "lucide-react"
import { match } from "ts-pattern"
import { v4 as uuid } from "uuid"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { ContentItemMapping, FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/fr/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")
type Payload = FormPayload<ItemType>
type Item = ContentItemBase & ContentItemMapping[ItemType]
type Cards = Payload["translations"][number]["props"]["cards"]

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _: __ } = useDictionary(dictionary("content", "form"))
  const _f = useFormDictionary()
  const form = useItemForm(item, close)
  const titleLevelOptions = useTitleLevelOptions()

  return (
    <Form form={form}>
      <FormAssertive />

      <FormTranslationTabs context={false}>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormTranslationContext language={language}>
              <FormInput
                name="title"
                label={__("title-label")}
                placeholder={__("title-placeholder")}
                translatable
              />
            </FormTranslationContext>
            <FormSelect
              options={titleLevelOptions}
              name="titleLevel"
              label={__("title-level-label")}
            />
            <FormTranslationContext language={language}>
              <FormTiptap
                name="secondary"
                label={__("secondary-label")}
                placeholder={__("secondary-placeholder")}
                translatable
              />
            </FormTranslationContext>
            <FormCards language={language} />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_f("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_f("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * useItemForm
 */
const useItemForm = (item: Item, close: () => void) => {
  const { _: __ } = useDictionary(dictionary("content", "form"))
  const _e = useErrorsDictionary()

  const {
    actions: { updateContentItem },
  } = useCmsContext()
  const languages = useLanguagesById()
  return useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(item.props, ["titleLevel", "cards"]),
      titleLevel: `${item.props.titleLevel}`,
      translations: D.map(languages, language => ({
        languageId: language.id,
        title: translate(item, language)?.props.title ?? "",
        secondary: translate(item, language)?.props.secondary ?? "",
        cards:
          translate(item, language)?.props.cards ??
          A.reduce(item.props.cards, {} as Cards, (cards, id) =>
            D.set(cards, id, emptyCardTranslation)
          ),
      })),
    })),
    onSubmit: async ({ values }) => {
      values.translations
      const payload: Payload = {
        props: {
          ...D.selectKeys(values, ["cards"]),
          titleLevel: +values.titleLevel,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: [],
      }
      return match(await updateContentItem(item.id, payload))
        .with({ error: false }, () => {
          toast.success(__("success"))
          close()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _e)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_e(code)))
        )
    },
  })
}
type ItemForm = ReturnType<typeof useItemForm>["values"]
const emptyCardTranslation: Cards[number] = {
  title: "",
  content: "",
}

/**
 * FormCards
 */
type FormCardsProps = {
  language: Language
}
const FormCards: React.FC<FormCardsProps> = ({ language }) => {
  const { _ } = useDictionary(dictionary("content", "items", itemType, "form", "cards"))
  const { _: __ } = useDictionary(dictionary("content", "form"))

  const { values, setValues } = useFormContext<ItemForm>()

  // create a new card
  const createCard = () => {
    const id = uuid()
    setValues({
      cards: [...values.cards, id],
      translations: D.map(values.translations, translation => ({
        ...translation,
        cards: {
          ...translation.cards,
          [id]: emptyCardTranslation,
        },
      })),
    })
  }

  // drag and drop reordering
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (!(over && active.id !== over.id)) return
    const oldIndex = A.getIndexBy(values.cards, id => id === active.id)
    const newIndex = A.getIndexBy(values.cards, id => id === over.id)
    if (G.isNullable(oldIndex) || G.isNullable(newIndex)) return
    setValues({ cards: arrayMove(values.cards, oldIndex, newIndex) })
  }

  return (
    <div className="flex flex-col items-start gap-4">
      <FormHeader>
        <FormHeader.Title>{_("title")}</FormHeader.Title>
        <FormHeader.Description>{_("description")}</FormHeader.Description>
      </FormHeader>
      <FormReorderableList
        onDragEnd={onDragEnd}
        items={values.cards}
        createButton={__("create-card")}
        create={createCard}
      >
        {A.mapWithIndex(values.cards, (index, id) => (
          <FormCard id={id} index={index} language={language} key={id} />
        ))}
      </FormReorderableList>
    </div>
  )
}

/**
 * FormCard
 */
type FormCardProps = {
  id: string
  index: number
  language: Language
}
const FormCard: React.FC<FormCardProps> = ({ id, index, language }) => {
  const { _ } = useDictionary(dictionary("content", "items", itemType, "form", "cards"))
  const { _: __ } = useDictionary(dictionary("content", "form"))

  const { values, setValues } = useFormContext<ItemForm>()
  const title = textPlaceholder(
    values.translations[language.id]?.cards[id]?.title,
    __("card-title")
  )

  // delete a card
  const deleteCard = () => {
    setValues({
      cards: A.reject(values.cards, card => card === id),
      translations: D.map(values.translations, translation => ({
        ...translation,
        cards: D.deleteKey(translation.cards, id),
      })),
    })
  }

  // keyboard accessibility reordering
  const onKeyDown = (keyCode: "ArrowUp" | "ArrowDown") =>
    match(keyCode)
      .with("ArrowUp", () => {
        const newIndex = index - 1
        if (newIndex < 0) return
        setValues({
          cards: arrayMove(values.cards, index, newIndex),
        })
      })
      .with("ArrowDown", () => {
        const newIndex = index + 1
        if (newIndex >= values.cards.length) return
        setValues({
          cards: arrayMove(values.cards, index, newIndex),
        })
      })

  return (
    <FormReorderableItem
      id={id}
      title={
        <>
          <RectangleHorizontal size={16} aria-hidden />
          {title}
        </>
      }
      titleLevel={4}
      actions={
        <Button
          variant="secondary"
          size="xxs"
          icon
          onClick={deleteCard}
          aria-label={__("delete-card")}
        >
          <Trash aria-hidden />
        </Button>
      }
      onKeyDown={onKeyDown}
    >
      <div className="relative flex flex-col gap-4">
        <FormTranslationContext language={language}>
          <FormFieldGroup name="cards">
            <FormFieldGroup name={id}>
              <FormInput
                name="title"
                label={_("title-label")}
                placeholder={_("title-placeholder")}
                translatable
              />
              <FormTiptap
                name="content"
                label={_("content-label")}
                translatable
                format={["bold", "italic", "link", "strike", "underline"]}
                className="prose-p:my-0"
              />
            </FormFieldGroup>
          </FormFieldGroup>
        </FormTranslationContext>
      </div>
    </FormReorderableItem>
  )
}
