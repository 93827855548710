/**
 * pages
 */
export const routerPages: Record<RouterPageKey, RouterPage> = {
  index: { id: "08ffb23a-c078-4e52-8839-82a35bb7749d", path: "/" },
  news: { id: "3defd536-aa6f-41db-b6ae-6fb268584701", path: "/news" },
  contact: { id: "1a6ccabf-6bc0-4304-96f4-3f8995c26391", path: "/contact" },
  kulturrallye: {
    id: "a5812b75-7ac7-4025-8d0a-44e4ff750a66",
    path: "/kulturrallye",
    restrict: "member",
  },
  offers: { id: "a174031d-81d0-4694-97ff-93c98b088c2e", path: "/offers" },
  vpDays: { id: "1d5e5140-1267-40c7-8a43-94d2c094161e", path: "/vp-days" },
  programs: {
    id: "9a2e897f-9bdc-4d77-b7de-7d5d36d96188",
    path: "/programs",
    restrict: "member",
  },
  projects: {
    id: "2440fab0-18a3-4237-8dd0-d008bdf399be",
    path: "/projects",
    restrict: "member",
  },
  legalNotice: { id: "8217c456-f396-4629-b90f-e03d02d209fb", path: "/legal-notice" },
  dataProtection: { id: "2f96c97a-e93b-409b-a332-5014fb745b38", path: "/data-protection" },
  accessibility: { id: "5a47364a-58e5-4cbf-af0a-f555c8ce63ab", path: "/accessibility" },
}

export type RouterPageKey =
  | "index"
  | "news"
  | "contact"
  | "kulturrallye"
  | "offers"
  | "vpDays"
  | "programs"
  | "projects"
  | "legalNotice"
  | "dataProtection"
  | "accessibility"
export type RouterPage = {
  id: string
  path: string
  restrict?: "member" | "admin"
}
