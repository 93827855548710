import * as PopoverPrimitive from "@radix-ui/react-popover"

/**
 * PopoverRoot
 */
const Root: React.FC<React.ComponentProps<typeof PopoverPrimitive.Root>> = props => {
  return (
    <>
      {props.open && <div className="fixed z-50 inset-0 size-full bg-black/70" aria-hidden />}
      <PopoverPrimitive.Root {...props} />
    </>
  )
}

/**
 * PopoverTrigger
 */
const Trigger = React.forwardRef<HTMLButtonElement, PopoverPrimitive.PopoverTriggerProps>(
  (props, ref) => <PopoverPrimitive.Trigger ref={ref} {...props} />
)

/**
 * PopoverContent
 */
const Content = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 10, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cx(
        "z-50 w-[--radix-popover-trigger-width] p-5 bg-white text-shark",
        "border border-mercury outline-none",
        "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        className
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
))

export const MobilePopover = Object.assign(Root, { Content, Trigger })
