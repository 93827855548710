import * as SwitchPrimitives from "@radix-ui/react-switch"
import { useBaseLayout } from "../layout/context"

/**
 * Switch
 */
const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    size?: "default" | "sm"
  }
>(({ className, size = "default", ...props }, ref) => {
  const { isDashboard } = useBaseLayout()

  return (
    <SwitchPrimitives.Root
      className={cx(
        "peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50  data-[state=unchecked]:bg-input",
        size === "default" ? "h-6 w-11" : "h-5 w-9",
        isDashboard ? "data-[state=checked]:bg-primary" : "data-[state=checked]:bg-orient",
        className
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cx(
          "pointer-events-none block rounded-full bg-background shadow-lg ring-0 transition-transform  data-[state=unchecked]:translate-x-0",
          size === "default"
            ? "h-5 w-5 data-[state=checked]:translate-x-5"
            : "h-4 w-4 data-[state=checked]:translate-x-4"
        )}
      />
    </SwitchPrimitives.Root>
  )
})

export { Switch }
