import { cva, type VariantProps } from "class-variance-authority"
import { Link, LinkProps } from "../ui/link"

/**
 * schemes
 */

export const focusOutlineClasses = cx(
  "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary-500 focus-visible:ring-offset-2 focus-visible:shadow-[0_0px_4px_0px] focus-visible:shadow-matisse"
)

export const buttonVariants = cva(
  cx(
    focusOutlineClasses,
    "focus-visible:border-[1px] focus-visible:border-orient",
    "inline-flex justify-center items-center max-h-16",
    "text-base font-normal",
    "disabled:pointer-events-none disabled:opacity-50 disabled:bg-cloud",
    "transition-colors"
  ),
  {
    variants: {
      variant: {
        default:
          "bg-aquahaze text-shark hover:bg-solitude active:bg-orient focus-visible:bg-bg-aquahaze",
        dark: "bg-orient text-white hover:bg-bluewhale focus-visible:bg-bluewhale active:bg-bluewhale disabled:text-black",
        secondary:
          "bg-white text-shark hover:bg-aquahaze focus-visible:bg-aquahaze active:bg-aquahaze",
        outline:
          "border border-mercury text-shark hover:border-orient focus-visible:bg-aquahaze active:bg-orient active:border-orient text-orient",
        outlineWhite:
          "border border-mercury text-white hover:border-white/30 focus-visible:bg-aquahaze active:bg-orient active:border-orient",
        outlineDark:
          "border border-orient text-shark hover:border-mercury focus-visible:bg-aquahaze active:bg-orient active:border-orient active:text-white",
        thirdy: "p-none gap-x-2.5 text-shark hover:text-matisse active:text-bluewhale font-bold",
        thirdyDark: "p-none gap-x-2.5 text-white hover:text-solitude active:text-matisse font-bold",
        tab: 'bg-white text-shark hover:bg-aquahaze focus-visible:bg-aquahaze active:bg-aquahaze data-[state="active"]:bg-aquahaze',
      },
      size: {
        default: "h-[50px] px-5 gap-x-4",
        sm: "h-[50px] px-5 gap-x-4",
        icon: "h-[50px] w-[50px]",
        iconSm: "h-[40px] w-[40px]",
        other: "",
      },
      case: {
        uppercase: "uppercase text-sm font-semibold tracking-wider",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

/**
 * Button
 */

type ButtonProps = (ButtonAttributes | LinkAttributes) & { replace?: boolean }

export const Button = React.forwardRef<HTMLButtonElement | HTMLLinkElement, ButtonProps>(
  ({ type, className, variant, size, ["case"]: caseProp, ...props }, ref) => {
    return type === "link" ? (
      <Link
        ref={ref as React.Ref<HTMLAnchorElement>}
        className={cx(buttonVariants({ variant, className, size, case: caseProp }))}
        {...(props as LinkProps)}
      />
    ) : (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        type={type ?? "button"}
        className={cx(buttonVariants({ variant, className, size, case: caseProp }))}
        {...(props as ButtonAttributes)}
      />
    )
  }
)

/**
 * ButtonIcon
 */
export const ButtonIcon = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  (ButtonAttributes | LinkAttributes) & { aria?: string }
>(({ type, className, variant, aria, children, ...props }, ref) => {
  return type === "link" ? (
    <Link
      className={cx(buttonVariants({ variant, className }))}
      {...(props as LinkProps)}
      ref={ref as React.ForwardedRef<HTMLAnchorElement>}
    >
      {children}
      {G.isNotNullable(aria) && <span className="sr-only">{aria}</span>}
    </Link>
  ) : (
    <button
      type={type ?? "button"}
      className={cx(buttonVariants({ variant, className }))}
      {...(props as ButtonAttributes)}
      ref={ref as React.ForwardedRef<HTMLButtonElement>}
    >
      {children}
      {G.isNotNullable(aria) && <span className="sr-only">{aria}</span>}
    </button>
  )
})

/**
 * types
 */

export type ButtonAttributes = Extend<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  { type?: "button" | "submit" }
> &
  VariantProps<typeof buttonVariants>
export type LinkAttributes = Extend<LinkProps, { type: "link" }> &
  VariantProps<typeof buttonVariants>
