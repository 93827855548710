import { z } from "zod"
import { apiSingleImage } from "../commons/schemas"

/**
 * ApiUserProfile
 */
export const apiUserProfile = z.object({
  firstname: z.string(),
  lastname: z.string(),
  position: z.string(),
  company: z.string(),
  email: z.string(),
  image: apiSingleImage,
})
export type ApiUserProfile = z.infer<typeof apiUserProfile>

/**
 * ApiUserRole
 */
export const apiUserRole = z.union([
  z.literal("member"),
  z.literal("admin"),
  z.literal("superadmin"),
])
export type ApiUserRole = z.infer<typeof apiUserRole>

/**
 * ApiUserStatus
 */
export const apiUserStatus = z.union([
  z.literal("pending"),
  z.literal("active"),
  z.literal("deleted"),
  z.literal("suspended"),
])
export type ApiUserStatus = z.infer<typeof apiUserStatus>

/**
 * ApiUser
 */
export const apiUser = z.object({
  id: z.string().uuid(),
  email: z.string(),
  iam: z.string(),
  isLoggedIn: z.boolean(),
  role: apiUserRole,
  status: apiUserStatus,
  profile: apiUserProfile,
  deletedAt: z.string().nullable(),
  lastLoginAt: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiUser = z.infer<typeof apiUser>
