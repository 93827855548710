import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { Tooltip } from "@/components/ui/tooltip"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { ChevronDown } from "lucide-react"
import { SidebarContext } from "./context"

/**
 * AccordionProvider
 */
type AccordionProviderProps = {
  children: React.ReactNode
}
const AccordionProvider: React.FC<AccordionProviderProps> = ({ children }) => {
  const [collaps, setCollaps] = usePersistedState(
    "",
    "toggle-sidebar-collaps",
    "interface",
    sessionStorage
  )
  return (
    <AccordionPrimitive.Root type="single" value={collaps} onValueChange={setCollaps} collapsible>
      {children}
    </AccordionPrimitive.Root>
  )
}

/**
 * SidebarAccordion
 */
type AccordionWrapperProps = {
  value: string
  className?: ClassName
  children: React.ReactNode
}
const AccordionWrapper: React.FC<AccordionWrapperProps> = ({
  value,
  className,
  children,
  ...props
}) => {
  const { isDesktop, ...sidebar } = React.useContext(SidebarContext)
  const expanded = isDesktop ? sidebar.state : true
  return expanded ? (
    <AccordionPrimitive.Item value={value} className={cx("", className)} {...props}>
      {children}
    </AccordionPrimitive.Item>
  ) : (
    <DropdownMenuPrimitive.Root {...props}>
      <div className={cx("", className)}>{children}</div>
    </DropdownMenuPrimitive.Root>
  )
}

/**
 * SidebarAccordionTrigger
 */
type AccordionTriggerProps = {
  className?: ClassName
  icon: React.ReactNode
  children: React.ReactNode
}
const AccordionTrigger: React.FC<AccordionTriggerProps> = ({
  className,
  icon,
  children,
  ...props
}) => {
  const { isDesktop, ...sidebar } = React.useContext(SidebarContext)
  const expanded = isDesktop ? sidebar.state : true

  return expanded ? (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        className={cx(
          "flex flex-1 items-center justify-between h-12 rounded-md [&[data-state=open]>span:last-child>svg]:rotate-180",
          "hover:ring-1 hover:ring-foreground/10 hover:ring-offset-0",
          "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0",
          className
        )}
        {...props}
      >
        <span
          className="flex justify-center items-center w-12 h-12 [&>svg]:w-4 [&>svg]:h-4 [&>svg]:shrink-0"
          aria-hidden
        >
          {icon}
        </span>
        <span className="flex grow line-clamp-1 text-left font-light transition-all duration-300 animate-in fade-in-0">
          {children}
        </span>
        <span className="flex justify-center items-center w-10 h-10 shrink-0">
          <ChevronDown className="size-4 transition-transform duration-300" />
        </span>
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  ) : (
    <Tooltip content={children} side="left" align="center">
      <DropdownMenuPrimitive.Trigger
        className={cx(
          "flex justify-center items-center w-12 h-12 rounded-md",
          "hover:ring-1 hover:ring-foreground/10 hover:ring-offset-0",
          "transition-all duration-300 animate-in fade-in-0",
          className
        )}
        {...props}
      >
        <span className="[&>svg]:w-4 [&>svg]:h-4 [&>svg]:shrink-0" aria-hidden>
          {icon}
        </span>
      </DropdownMenuPrimitive.Trigger>
    </Tooltip>
  )
}

/**
 * SidebarAccordionContent
 */
type AccordionContentProps = {
  className?: ClassName
  children: React.ReactNode
}
const AccordionContent: React.FC<AccordionContentProps> = ({ className, children, ...props }) => {
  const { isDesktop, ...sidebar } = React.useContext(SidebarContext)
  const expanded = isDesktop ? sidebar.state : true
  return expanded ? (
    <AccordionPrimitive.Content
      className={cx(
        "overflow-hidden",
        "text-popover-foreground font-light",
        "data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
      )}
      {...props}
    >
      <div className="relative flex flex-col ml-5 p-1">
        <div
          className="absolute inset-y-0 -left-1 w-[1px] h-10/12 bg-gradient-to-b from-muted/10 via-foreground/10 to-muted/10"
          aria-hidden
        />
        {children}
      </div>
    </AccordionPrimitive.Content>
  ) : (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        sideOffset={2}
        align="start"
        side="right"
        className={cx(
          "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1",
          "text-popover-foreground",
          "data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95",
          "data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95",
          "data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          className
        )}
        {...props}
      >
        {children}
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  )
}

/**
 * SidebarAccordionItem
 */
type AccordionItemProps = {
  onClick?: () => void
  className?: ClassName
  icon: React.ReactNode
  active?: boolean
  children: React.ReactNode
}
const AccordionItem: React.FC<AccordionItemProps> = ({
  onClick,
  className,
  icon,
  active = false,
  children,
  ...props
}) => {
  const { isDesktop, isMobile, ...sidebar } = React.useContext(SidebarContext)
  const expanded = isDesktop ? sidebar.state : true
  const click: React.MouseEventHandler<HTMLElement> = () => {
    if (isMobile) sidebar.close()
    onClick && onClick()
  }
  return expanded ? (
    <button
      className={cx(
        "flex items-center py-1.5 px-2 rounded-sm outline-none",
        "text-left text-sm",
        "hover:ring-1 hover:ring-foreground/10 hover:ring-offset-0",
        "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0",
        "data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        "select-none transition-all duration-300 animate-in fade-in-0",
        active && "text-primary",
        className
      )}
      onClick={click}
      {...props}
    >
      <span
        className="flex justify-center items-center shrink-0 w-6 mr-3 [&>svg]:w-3 [&>svg]:h-3 [&>svg]:shrink-0"
        aria-hidden
      >
        {icon}
      </span>
      <span className={cx("flex grow whitespace-nowrap")}>{children}</span>
    </button>
  ) : (
    <DropdownMenuPrimitive.Item
      className={cx(
        "flex items-center px-2 py-1.5 rounded-sm outline-none cursor-default select-none",
        "text-sm",
        "focus:bg-accent",
        "transition-colors",
        "data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        "[&>svg]:mr-2 [&>svg]:h-4 [&>svg]:w-4",
        active && "text-primary",
        className
      )}
      onClick={click}
      {...props}
    >
      <span
        className="flex justify-center items-center w-6 mr-3 [&>svg]:w-3 [&>svg]:h-3 [&>svg]:shrink-0"
        aria-hidden
      >
        {icon}
      </span>
      {children}
    </DropdownMenuPrimitive.Item>
  )
}

export const Accordion = Object.assign(AccordionWrapper, {
  Provider: AccordionProvider,
  Trigger: AccordionTrigger,
  Content: AccordionContent,
  Item: AccordionItem,
})
