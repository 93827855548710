import cmsConfig from "@/config/cms"
import { useData } from "@/hooks/usePromise"
import { Item } from "@/pages/projects/Item"
import { ProjectsIndexParams, service } from "@/services/frontend/service"
import { Project, localizeProject } from "@/store/frontend/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { Header, ItemMappingExport } from "."
import { Container } from "../container"
import { Wrapper } from "../wrapper"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", "projects")

type RenderFC = ItemMappingExport<"projects">["Render"]

export const Render: RenderFC = props => {
  const { item } = props
  const t = useTranslation()
  const [projects] = useData(initialProjects, () =>
    loadProjects(item.props.projects, item.props.category, item.props.orientation)
  )

  const { titleLevel } = item.props
  const { title, secondary } = t(item).props
  if (A.isEmpty(projects)) return null
  return (
    <Wrapper margin="normal">
      <Container x="sm" className="flex flex-col gap-4">
        <Header {...{ title, secondary, titleLevel }} />
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {A.map(projects, project => (
            <Item project={project} titleLevel={titleLevel} key={project.id} />
          ))}
        </div>
      </Container>
    </Wrapper>
  )
}

/**
 * loadArticles
 */
const loadProjects = async (
  projectIds: string[],
  category: Option<string> = null,
  orientation: Option<string> = null
) => {
  if (A.isEmpty(projectIds) && G.isNullable(category) && G.isNullable(orientation))
    return match(await service.projects.index({ limit: cmsConfig.relatedProjects }))
      .with({ error: false }, ({ data }) =>
        pipe(
          data.projects,
          A.map(localizeProject),
          A.sortBy(D.getUnsafe("createdAt")),
          A.take(cmsConfig.relatedArticles)
        )
      )
      .otherwise(() => initialProjects)

  const projectsSelected = pipe(
    await Promise.all(
      A.map(projectIds, async id =>
        match(await service.projects.read(id))
          .with({ error: false }, ({ data }) => localizeProject(data.project))
          .otherwise(() => O.None)
      )
    ),
    A.filter(G.isNotNullable),
    A.sortBy(D.getUnsafe("createdAt")),
    A.take(cmsConfig.relatedArticles)
  )

  const limit = cmsConfig.relatedArticles - projectsSelected.length
  if ((G.isNullable(category) && G.isNullable(orientation)) || limit <= 0) return projectsSelected

  const projectsFromCategoryAndOrientation = match(
    await service.projects.index({
      limit,
      ...D.filter({ category, orientation }, G.isNotNullable),
    } as ProjectsIndexParams)
  )
    .with({ error: false }, ({ data }) =>
      pipe(data.projects, A.map(localizeProject), A.sortBy(D.getUnsafe("createdAt")), A.take(limit))
    )
    .otherwise(() => initialProjects)

  return [...projectsSelected, ...projectsFromCategoryAndOrientation]
}
const initialProjects: Project[] = []
