import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { resetAllStoresAndReload } from "@/store"
import { createPage } from "@/store/pages/actions"
import { match } from "ts-pattern"
import { UseDialogProps } from "../ui/hooks/useDialog"

/**
 * dictionary src/dictionaries/en/components/dialogs/page-create-dialog.json
 */
const dictionary = createContextMapper("components", "dialogs", "page-create-dialog")

/**
 * PageCreateDialog
 */
export const PageCreateDialog: React.FC<UseDialogProps<void>> = props => {
  const { _ } = useDictionary(dictionary())
  const submit = async () => {
    props.onOpenChange(false)
    match(await createPage({}))
      .with({ error: false }, ({ id }) => {
        navigate(`/dashboard/pages/${id}`)
        toast.success(_("success"))
      })
      .otherwise(({ code }) =>
        match(code)
          .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
          .otherwise(code => void toast.error(_(code)))
      )
  }
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-xl">
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_("cancel")}</Button>
        </Dialog.Close>
        <Button onClick={submit}>{_("submit")}</Button>
      </Dialog.Footer>
    </Dialog>
  )
}
