import { Menu, useMenu } from "@/components/collection"
import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { Confirm } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { useSelectItem } from "@/hooks/useSelect"
import { deleteProgram, updateProgram } from "@/store/programs/actions"
import { useProgramsById } from "@/store/programs/hooks"
import { Program } from "@/store/programs/localizers"
import {
  Archive,
  NotePencil,
  PlusSquare,
  SelectionPlus,
  SelectionSlash,
  TrashSimple,
} from "@phosphor-icons/react"
import { Library, PackageOpen } from "lucide-react"
import { CreateDialog } from "./CreateDialog"
import { EditDialog } from "./EditDialog"

/**
 * dictionary src/dictionaries/en/pages/dashboard/programs.json
 */
const dictionary = createContextMapper("pages", "dashboard", "programs")

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { _ } = useDictionary(dictionary())
  const programs = useProgramsById()

  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    "grid",
    "state-programs-view",
    "interface",
    sessionStorage
  )

  // dialog
  const { setItem: create, ...createProps } = useDialog<void>()
  const { setItem: edit, ...editProps } = useDialog<string>()

  // confirms
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deleteProgram,
    dictionary: Confirm.dictionary(dictionary("delete-confirm")),
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName: id => D.get(programs, id)?.name ?? _("unknow"),
    onAsyncConfirm: deleteProgram,
    dictionary: Confirm.dictionary(dictionary("delete-selection-confirm")),
  })
  const { confirm: confirmArchiveSelection, ...archiveSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName: id => D.get(programs, id)?.name ?? _("unknow"),
    onAsyncConfirm: id => updateProgram(id, { archived: true }),
    dictionary: Confirm.dictionary(dictionary("archive-selection-confirm")),
  })
  const { confirm: confirmUnarchiveSelection, ...unarchiveSelectionProps } = useConfirm<
    void,
    string
  >({
    list: selected,
    displayName: id => D.get(programs, id)?.name ?? _("unknow"),
    onAsyncConfirm: id => updateProgram(id, { archived: false }),
    dictionary: Confirm.dictionary(dictionary("unarchive-selection-confirm")),
  })

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        view,
        setView,
        create,
        edit,
        confirmDelete,
        confirmDeleteSelection,
        confirmArchiveSelection,
        confirmUnarchiveSelection,
      }}
    >
      {children}
      <CreateDialog {...createProps} />
      <EditDialog {...editProps} />
      <Confirm {...deleteSelectionProps} />
      <Confirm {...deleteProps} />
      <Confirm {...archiveSelectionProps} />
      <Confirm {...unarchiveSelectionProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
export const ContextMenu: React.FC<{
  program: Program
}> = ({ program }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const ctx = usePageContext()
  const { type } = useMenu()
  const { isSelected, toggleSelection } = useSelectItem(Context, program.id)

  const toggleArchived = async () => {
    const response = await updateProgram(program.id, { archived: !program.archived })
    if (!response.error)
      toast.success(_(program.archived ? "unarchive-success" : "archive-success"))
    else toast.error(_(program.archived ? "unarchive-error" : "archive-error"))
  }
  return (
    <>
      <Menu.Item onClick={toggleSelection}>
        {isSelected ? <SelectionSlash aria-hidden /> : <SelectionPlus aria-hidden />}
        {_(isSelected ? "unselect" : "select")}
      </Menu.Item>
      <Menu.Item onClick={() => navigate(`/dashboard/programs/${program.id}`)}>
        <Library />
        {_("display")}
      </Menu.Item>
      <Menu.Item onClick={() => ctx.edit(program.id)}>
        <NotePencil />
        {_("edit")}
      </Menu.Item>
      <Menu.Item onClick={toggleArchived}>
        {program.archived ? <PackageOpen strokeWidth={1} aria-hidden /> : <Archive aria-hidden />}
        {_(program.archived ? "unarchive" : "archive")}
      </Menu.Item>
      <Menu.Item onClick={() => ctx.confirmDelete(program.id)}>
        <TrashSimple />
        {_("delete")}
      </Menu.Item>
      {type === "context-menu" && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={() => ctx.create()}>
            <PlusSquare aria-hidden />
            {_("create")}
          </Menu.Item>
          {isSelected && (
            <>
              <Menu.Separator />
              {program.archived ? (
                <Menu.Item onClick={() => ctx.confirmUnarchiveSelection()}>
                  <PackageOpen strokeWidth={1} aria-hidden />
                  {_("unarchive-selection")}
                </Menu.Item>
              ) : (
                <Menu.Item onClick={() => ctx.confirmArchiveSelection()}>
                  <Archive aria-hidden />
                  {_("archive-selection")}
                </Menu.Item>
              )}
              <Menu.Item onClick={() => ctx.confirmDeleteSelection()}>
                <TrashSimple aria-hidden />
                {_("delete-selection")}
              </Menu.Item>
            </>
          )}
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  create: () => void
  edit: (id: string) => void
  confirmDelete: (id: string) => void
  confirmDeleteSelection: () => void
  confirmArchiveSelection: () => void
  confirmUnarchiveSelection: () => void
}
