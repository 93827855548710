import { Wrapper } from "@/components/layout/frontend/wrapper"
import { useData } from "@/hooks/usePromise"
import { service } from "@/services/frontend/service"
import {
  Place,
  Workshop,
  WorkshopEvent,
  localizePlace,
  localizeWorkshop,
} from "@/store/frontend/localizers"
import { match } from "ts-pattern"
import { EventRender } from "./EventRender"
import { StepContact } from "./StepContact"
import { StepReservation } from "./StepReservation"
import { StepSummary } from "./StepSummary"
import { Stepper } from "./Stepper"
import { useConcactForm } from "./useContactForm"
import { useReservationForm } from "./useReservationForm"
import { useStepper } from "./useStepper"

/**
 * dictionary src/dictionaries/fr/pages/kulturrallye.json
 * dictionary src/dictionaries/de/pages/kulturrallye.json
 */
export const dictionary = createContextMapper("pages", "kulturrallye")

type Steps = "reservation" | "contact" | "summary"

/**
 * kulturrallye/reservation
 */
const KulturrallyeReservation: React.FC<{ eventId: string }> = ({ eventId }) => {
  const [data, onProgress] = useData(initialData, loadData)
  if (onProgress) return null

  return (
    <Wrapper className="pb-5 sm:pb-8">
      {!onProgress && <Steps {...data} eventId={eventId} />}
    </Wrapper>
  )
}
export default KulturrallyeReservation

/**
 * Steps
 */
const Steps: React.FC<StoreProps> = props => {
  const { _ } = useDictionary(dictionary("reservation"))
  const [storeProps, setStoreProps] = React.useState(() => props)
  const formReservation = useReservationForm(storeProps)
  const formContact = useConcactForm()
  const stepsProps = useStepper(formReservation.isValid, formContact.isValid)
  const refreshStore = async () => {
    const data = await loadData()
    setStoreProps(state => ({ ...state, ...data }))
  }
  return (
    <Stepper {...stepsProps} aria-label={_("stepper-label")}>
      <div className="flex flex-col-reverse lg:grid grid-cols-2 gap-5">
        <StepReservation {...storeProps} {...stepsProps} {...{ form: formReservation }} />
        <StepContact {...storeProps} {...stepsProps} {...{ form: formContact }} />
        <StepSummary
          {...storeProps}
          {...stepsProps}
          refreshStore={refreshStore}
          values={{ ...formContact.values, ...formReservation.values }}
        />
        <EventRender {...storeProps} {...{ form: formReservation }} />
      </div>
    </Stepper>
  )
}
/**
 * loader
 */
const initialPlaces: Place[] = []
const initialWorkshops: Workshop[] = []
const initialEvents: WorkshopEvent[] = []
const initialData = { places: initialPlaces, workshops: initialWorkshops, events: initialEvents }
const loadPlaces = async () =>
  match(await service.places.index())
    .with({ error: false }, ({ data }) => A.map(data.places, localizePlace))
    .otherwise(() => initialPlaces)
const loadWorkshops = async () =>
  match(
    await service.workshops.index({
      from: T.formatISO(new Date()),
      to: T.formatISO(T.add(new Date(), { years: 5 })),
    })
  )
    .with({ error: false }, ({ data }) => {
      const workshops = A.map(data.workshops, localizeWorkshop)
      return {
        workshops,
        events: A.reduce(workshops, initialEvents, (events, workshop) =>
          A.concat(events, D.values(workshop.events))
        ),
      }
    })
    .otherwise(() => ({
      workshops: initialWorkshops,
      events: initialEvents,
    }))
const loadData = async () => {
  const [places, { workshops, events }] = await Promise.all([loadPlaces(), loadWorkshops()])
  return { places, workshops, events }
}
/**
 * types
 */
export type StoreProps = {
  places: Place[]
  workshops: Workshop[]
  events: WorkshopEvent[]
  eventId: string
}

export type StepsProps = ReturnType<typeof useStepper>
export type FormContactProps = {
  form: ReturnType<typeof useConcactForm>
}
export type FormReservationProps = {
  form: ReturnType<typeof useReservationForm>
}
