import { useCmsContext } from "@/components/cms/Context"
import {
  Form,
  FormAssertive,
  FormFieldGroup,
  FormHeader,
  FormInput,
  FormReorderableItem,
  FormReorderableList,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
} from "@/components/form"
import { FormMediasImage } from "@/components/medias/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { textPlaceholder } from "@/fns/String"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { RectangleHorizontal, Trash } from "lucide-react"
import { match } from "ts-pattern"
import { useFieldGroupContext, useForm, useFormContext } from "use-a11y-form"
import { v4 as uuid } from "uuid"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")
type Payload = FormPayload<ItemType>
type Contacts = Payload["translations"][number]["props"]["contacts"]

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary("content", "items", itemType))
  const { _: __ } = useDictionary(dictionary("content", "form"))
  const _f = useFormDictionary()
  const _e = useErrorsDictionary()

  const { updateContentItem } = useCmsContext().actions
  const languages = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(item.props, ["image", "imageSmall", "contacts"]),
      titleLevel: `${item.props.titleLevel}`,
      translations: D.map(languages, language => ({
        languageId: language.id,
        title: translate(item, language)?.props.title ?? "",
        secondary: translate(item, language)?.props.secondary ?? "",
        mapTitle: translate(item, language)?.props.mapTitle ?? "",
        mapSecondary: translate(item, language)?.props.mapSecondary ?? "",
        content: translate(item, language)?.props.content ?? "",
        contactTitle: translate(item, language)?.props.contactTitle ?? "",
        contacts:
          translate(item, language)?.props.contacts ??
          A.reduce(item.props.contacts, {} as Contacts, (contacts, contact) =>
            D.set(contacts, contact, emptyCard)
          ),
      })),
    })),
    onSubmit: async ({ values }) => {
      values.translations
      const payload: Payload = {
        props: {
          ...D.selectKeys(values, ["image", "imageSmall", "contacts"]),
          titleLevel: +values.titleLevel,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: [
          ...(values.image ? [values.image] : []),
          ...(values.imageSmall ? [values.imageSmall] : []),
        ],
      }

      return match(await updateContentItem(item.id, payload))
        .with({ error: false }, () => {
          toast.success(__("success"))
          close()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _e)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_e(code)))
        )
    },
  })

  const titleLevelOptions = useTitleLevelOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />

      <FormTranslationTabs context={false}>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormHeader>
              <FormHeader.Title>{_("header.title")}</FormHeader.Title>
              <FormHeader.Description>{_("header.description")}</FormHeader.Description>
            </FormHeader>
            <FormMediasImage name="image" label={_("header.image-label")} ratio="aspect-[32/9]" />

            <FormTranslationContext language={language}>
              <FormInput
                name="title"
                label={_("header.title-label")}
                placeholder={_("header.title-placeholder")}
                translatable
              />
            </FormTranslationContext>
            <FormSelect
              options={titleLevelOptions}
              name="titleLevel"
              label={__("title-level-label")}
            />
            <FormTranslationContext language={language}>
              <FormInput
                name="secondary"
                label={_("header.secondary-label")}
                placeholder={_("header.secondary-placeholder")}
                translatable
              />
            </FormTranslationContext>
            <FormMediasImage
              name="imageSmall"
              label={_("header.image-small-label")}
              ratio="aspect-square"
            />

            <FormTranslationContext language={language}>
              <FormHeader>
                <FormHeader.Title>{_("map.title")}</FormHeader.Title>
                <FormHeader.Description>{_("map.description")}</FormHeader.Description>
              </FormHeader>
              <FormInput
                name="mapTitle"
                label={_("map.title-label")}
                placeholder={_("map.title-placeholder")}
                translatable
              />
              <FormTiptap name="mapSecondary" label={_("map.secondary-label")} translatable />
              <FormTiptap name="content" label={_("content-label")} translatable />
            </FormTranslationContext>

            <FormTranslationContext language={language}>
              <FormHeader>
                <FormHeader.Title>{_("cards.title")}</FormHeader.Title>
                <FormHeader.Description>{_("cards.description")}</FormHeader.Description>
              </FormHeader>
              <FormInput
                name="contactTitle"
                label={_("cards.title-label")}
                placeholder={_("cards.title-placeholder")}
                translatable
              />
              <FormCards />
            </FormTranslationContext>
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_f("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_f("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * FormCards
 */
const FormCards: React.FC = () => {
  const { _ } = useDictionary(dictionary("content", "items", itemType, "cards"))

  const { values, setValues } = useFormContext<FormCardsContext>()

  // create a new card
  const createCard = () => {
    const cardId = uuid()
    setValues({
      contacts: [...values.contacts, cardId],
      translations: D.map(values.translations, translation => ({
        ...translation,
        contacts: {
          ...translation.contacts,
          [cardId]: emptyCard,
        },
      })),
    })
  }

  // drag and drop reordering
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (active.id !== over?.id) {
      const oldIndex = values.contacts.indexOf(active.id as string)
      const newIndex = values.contacts.indexOf(over!.id as string)
      setValues({
        contacts: arrayMove(values.contacts, oldIndex, newIndex),
      })
    }
  }

  return (
    <FormFieldGroup name="contacts">
      <div className="flex flex-col items-start gap-4">
        <FormReorderableList
          onDragEnd={onDragEnd}
          items={values.contacts}
          createButton={_("create-card")}
          create={createCard}
        >
          {A.map(values.contacts, id => (
            <FormCard id={id} key={id} />
          ))}
        </FormReorderableList>
      </div>
    </FormFieldGroup>
  )
}

/**
 * FormCard
 */
type FormCardProps = {
  id: string
}
const FormCard: React.FC<FormCardProps> = ({ id }) => {
  const { _ } = useDictionary(dictionary("content", "items", itemType, "cards"))
  const { values, setValues } = useFormContext<FormCardsContext>()
  const { name } = D.getUnsafe(
    useFieldGroupContext<FormCardsContext["translations"][string]["contacts"]>().values,
    id
  )

  // delete a card
  const deleteCard = () => {
    setValues({
      contacts: A.reject(values.contacts, card => card === id),
      translations: D.map(values.translations, translation => ({
        ...translation,
        contacts: D.deleteKey(translation.contacts, id),
      })),
    })
  }

  // keyboard accessibility reordering
  const onKeyDown = (keyCode: "ArrowUp" | "ArrowDown") => {
    const oldIndex = values.contacts.indexOf(id)
    switch (keyCode) {
      case "ArrowUp": {
        const newIndex = oldIndex - 1
        if (newIndex < 0) return
        setValues({
          contacts: arrayMove(values.contacts, oldIndex, newIndex),
        })
        break
      }
      case "ArrowDown": {
        const newIndex = oldIndex + 1
        if (newIndex >= values.contacts.length) return
        setValues({
          contacts: arrayMove(values.contacts, oldIndex, newIndex),
        })
        break
      }
    }
  }

  return (
    <FormReorderableItem
      id={id}
      title={
        <>
          <RectangleHorizontal size={16} aria-hidden />
          {textPlaceholder(name, _("card-title"))}
        </>
      }
      titleLevel={4}
      actions={
        <Button
          variant="secondary"
          size="xxs"
          icon
          onClick={deleteCard}
          aria-label={_("delete-card")}
        >
          <Trash aria-hidden />
        </Button>
      }
      onKeyDown={onKeyDown}
    >
      <FormFieldGroup name={id}>
        <div className="relative flex flex-col gap-4">
          <FormInput
            label={_("name-label")}
            name="name"
            placeholder={_("name-placeholder")}
            translatable
          />
          <FormInput
            label={_("company-label")}
            name="company"
            placeholder={_("company-placeholder")}
            translatable
          />
          <FormInput
            label={_("region-label")}
            name="region"
            placeholder={_("region-placeholder")}
            translatable
          />
          <FormInput
            label={_("email-label")}
            name="email"
            placeholder={_("email-placeholder")}
            translatable
          />
        </div>
      </FormFieldGroup>
    </FormReorderableItem>
  )
}

/**
 * emptyCard
 */
const emptyCard: Contacts[number] = {
  name: "",
  company: "",
  region: "",
  email: "",
}

/**
 * types
 */
type FormCardsContext = {
  contacts: string[]
  translations: Record<
    string,
    {
      languageId: string
      contacts: Record<string, Cards[string]>
    }
  >
}
type Cards = FormPayload<ItemType>["translations"][number]["props"]["contacts"]
