import { getMediasFile } from "@/components/layout/frontend/items"
import { HN } from "@/components/ui/hn"
import { Image } from "@/components/ui/image"
import { Link } from "@/components/ui/link"
import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { Article } from "@/store/frontend/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { getPreview } from "@/store/medias/helpers"
import { ImageOff } from "lucide-react"
import { dictionary } from "."

/**
 * Item
 */
export const Item: React.FC<{ article: Article }> = ({ article }) => {
  const { _ } = useDictionary(dictionary())
  const format = useDateFnsLocaleFormat()
  const t = useTranslation()

  const { publishedAt } = article
  const { title, description, image } = t(article.seo)
  const imageFile = getMediasFile(article.files, image)

  return (
    <article className="relative flex flex-col items-stretch rounded-[2px] bg-white text-gray-800 shadow-md">
      <div className="relative flex aspect-video rounded-t-[2px] overflow-hidden">
        <Image
          src={getPreview(imageFile)}
          alt={imageFile ? t(imageFile).alt : title}
          className="absolute inset-0 size-full object-cover rounded-t-[2px] text-muted-foreground"
        >
          <ImageOff size={64} strokeWidth={1} />
        </Image>
        <div
          className="absolute inset-0 size-full bg-gradient-to-b from-orient/20 from-5% to-orient/80 to-80%"
          aria-hidden
        />
        <div className="relative flex flex-col justify-stretch size-full p-4">
          <div className="grow">
            <ItemCategory article={article} />
          </div>
          <HN level={2} className="text-white text-xl font-bold">
            {t(article.seo).title}
          </HN>
        </div>
      </div>
      <div className="flex flex-col justify-stretch items-stretch gap-2 p-4">
        <p className="text-xs text-frontend-gray">
          {_("published-on", { date: format(publishedAt, "PPP") })}
        </p>
        <p className="text-xs leading-relaxed line-clamp-3">{description}</p>
      </div>
      <Link href={`/offers/${article.id}`} className="absolute inset-0 w-full h-full">
        <span className="sr-only">{title}</span>
      </Link>
    </article>
  )
}

const ItemCategory: React.FC<{ article: Article }> = ({ article }) => {
  const t = useTranslation()
  if (G.isNullable(article.category)) return null
  const name = t(article.category).name
  return (
    <p className="inline-flex min-h-[1rem] mx-auto px-4 py-2 rounded-full backdrop-blur-sm bg-frontend-bluewhale/40 uppercase text-white text-xs leading-none font-semibold">
      {name}
    </p>
  )
}
