import { RegionName } from "@/fns/maps"
import { useFilterable } from "@/hooks/useFilterable"
import { useLimitable } from "@/hooks/useLimitable"
import { useMatchable } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"
import { mapName } from "@/services/places/schemas"
import { usePlacesStore } from "."
import { Place } from "./localizers"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/places.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "places")

/**
 * hooks
 */
export const usePlaces = () => {
  const byIds = usePlacesStore(D.getUnsafe("places"))
  const places = React.useMemo(() => D.values(byIds), [byIds])
  return places
}
export const usePlacesById = () => {
  return usePlacesStore(D.getUnsafe("places"))
}
export const useRegionPlaces = (region: RegionName) => {
  const places = usePlaces()

  const filteredPlaces = React.useMemo(() => {
    return A.filter(places, place => place.map === region)
  }, [region, places])

  return filteredPlaces
}
export const usePlace = (id: Option<Place["id"]>) => {
  const place = usePlacesStore(flow(D.getUnsafe("places"), D.get(id ?? "")))
  return place
}

/**
 * collection
 */
export const useFilteredPlaces = (initialLimit = 24, initialByMore = 12) => {
  const places = usePlaces()

  const [filterable, filterBy] = useFilterable<Place>(
    "places",
    {
      published: place => place.published,
    },
    {}
  )

  const [matchable, matchIn] = useMatchable<Place>(["name", "description"])

  const [sortable, sortBy] = useSortable<Place>(
    "places",
    {
      name: place => place.name,
    },
    "name"
  )

  const filtered = React.useMemo(
    () => pipe(places, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [places, filterBy, matchable.search, matchIn, sortBy]
  )

  const [limitable, limit] = useLimitable<Place>(filtered.length, initialLimit, initialByMore)

  return { places, filtered, filterable, matchable, sortable, limitable, limit }
}

/**
 * options
 */
export const usePlacesOptions = () => {
  const byIds = usePlacesById()
  return React.useMemo(
    () =>
      pipe(
        byIds,
        D.values,
        A.map(place => ({
          label: place.name,
          value: place.id,
        }))
      ),
    [byIds]
  )
}
export const useRegionOptions = () => {
  const { _ } = useDictionary(dictionary("form"))
  return React.useMemo(
    () =>
      mapName.map(name => ({
        label: _(`map-${name}`),
        value: name,
      })),
    [_]
  )
}
