import { Prose } from "@/components/frontend/prose"
import { HN } from "@/components/ui/hn"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"

export const Header: React.FC<{
  title: string
  secondary: string
  titleLevel: number
}> = ({ titleLevel, title, secondary }) =>
  oneIsNotEmpty(title, stripeHtml(secondary)) ? (
    <div className="flex flex-col gap-4">
      {oneIsNotEmpty(title) && (
        <HN
          level={titleLevel}
          className="text-xl lg:text-[30px] leading-[48px] font-extrabold text-[#0A5479] dark:text-[#2c9bd3] max-w-xl"
        >
          {title}
        </HN>
      )}
      {oneIsNotEmpty(stripeHtml(secondary)) && (
        <Prose
          className="max-w-xl"
          dangerouslySetInnerHTML={{
            __html: secondary,
          }}
        />
      )}
    </div>
  ) : null
