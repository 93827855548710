import { dictionary } from "."

/**
 * useStepper
 */
export const useStepper = (informationIsValid: boolean, definitionIsValid: boolean) => {
  const { _ } = useDictionary(dictionary("form"))

  const [step, setStep] = React.useState<string>("information")
  const steps = React.useMemo(
    () => [
      {
        value: "information",
        disabled: false,
        name: _(`tab-information`),
      },
      {
        value: "definition",
        disabled: !informationIsValid,
        name: _(`tab-definition`),
      },
      {
        value: "annexe",
        disabled: !(informationIsValid && definitionIsValid),
        name: _(`tab-annexe`),
      },
    ],
    [_, informationIsValid, definitionIsValid]
  )

  return { step, setStep, steps }
}

export type StepsProps = ReturnType<typeof useStepper>
