import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { CalendarBlank, MapPin } from "@phosphor-icons/react"
import { FormReservationProps, StoreProps } from "."

/**
 * EventRender
 */

export const EventRender: React.FC<StoreProps & FormReservationProps> = ({
  events,
  places,
  workshops,
  form,
}) => {
  const format = useDateFnsLocaleFormat()

  const event = React.useMemo(
    () => A.find(events, event => event.id === form.values.event),
    [form.values.event, events]
  )

  const place = React.useMemo(
    () => A.find(places, place => place.id === form.values.place),
    [form.values.place, places]
  )

  const workshop = React.useMemo(
    () => A.find(workshops, workshop => workshop.id === form.values.workshop),
    [form.values.workshop, workshops]
  )

  if (G.isNullable(event) || G.isNullable(workshop) || G.isNullable(place)) return null

  return (
    <div>
      <div className="relative flex flex-col gap-5 bg-aquahaze p-5 sm:p-[30px]">
        <span
          className="absolute top-2.5 right-0 flex items-center h-[50px] px-5 bg-orient text-white text-sm font-black"
          aria-hidden
        >
          {format(event.datetime, "P")}
        </span>
        <h2 className="mr-32 text-lg sm:text-2xl font-semibold">{workshop.name}</h2>
        <ul className="flex flex-col gap-3">
          <li className="flex gap-2.5 text-sm">
            <CalendarBlank className="text-tomato h-5" aria-hidden />
            {format(event.datetime, "PPPPp")}
          </li>
          <li className="flex gap-2.5 text-sm">
            <MapPin className="text-tomato h-5" aria-hidden />
            {place.name}
          </li>
        </ul>
      </div>
    </div>
  )
}
