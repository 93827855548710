/**
 * YoutubeIFrame
 */
type Props = {
  videoId: string
  title: string
  className?: ClassName
  options?: Partial<YoutubeOptions>
}
export const YoutubeIFrame: React.FC<Props> = props => {
  const { videoId, title, className } = props
  const options = { ...defaultOptions, ...props.options }
  const autoplay = options.autoplay
  const videoURL = `https://www.youtube-nocookie.com/embed/${videoId}${
    autoplay ? "?autoplay=1" : ""
  }`
  const iframeRef = React.useRef<HTMLIFrameElement>(null)
  const defaultHeight = 495
  const [videoHeight, setVideoHeight] = React.useState<number>(
    iframeRef.current ? iframeRef.current.offsetWidth * 0.5625 : defaultHeight
  )

  const handleChangeVideoWidth = React.useCallback(() => {
    const ratio =
      window.innerWidth > 990
        ? 1.0
        : window.innerWidth > 522
        ? 1.2
        : window.innerWidth > 400
        ? 1.45
        : 1.85
    const height = iframeRef.current ? iframeRef.current.offsetWidth * 0.5625 : defaultHeight
    return setVideoHeight(Math.floor(height * ratio))
  }, [])

  React.useEffect(() => {
    window.addEventListener("resize", handleChangeVideoWidth)
    const ratio =
      window.innerWidth > 990
        ? 1.0
        : window.innerWidth > 522
        ? 1.2
        : window.innerWidth > 400
        ? 1.45
        : 1.85
    const height = iframeRef.current ? iframeRef.current.offsetWidth * 0.5625 : defaultHeight
    setVideoHeight(Math.floor(height * ratio))
    return function cleanup() {
      window.removeEventListener("resize", handleChangeVideoWidth)
    }
  }, [videoHeight, handleChangeVideoWidth])

  const [error, setError] = React.useState(false)
  React.useEffect(() => {
    setError(false)
  }, [videoId])

  return (
    <iframe
      ref={iframeRef}
      title={title}
      className={cx("w-full", className)}
      style={{ height: `${videoHeight}px` }}
      width="100%"
      height={`${videoHeight}px`}
      src={videoURL}
      onError={() => setError(true)}
      {...optionsToProps(options)}
    />
  )
}

/**
 * Default options for the youtube iframe
 */
const defaultOptions: YoutubeOptions = {
  autoplay: true,
  clipboardWrite: true,
  encryptedMedia: true,
  gyroscope: true,
  pictureInPicture: true,
  fullscreen: true,
}

/**
 * Convert the options object to the props for the iframe
 */
const optionsToProps = (options: YoutubeOptions) => {
  let allow = "accelerometer;"

  // Check each option and add to the allow string if true
  if (options.autoplay) allow += " autoplay;"
  if (options.clipboardWrite) allow += " clipboard-write;"
  if (options.encryptedMedia) allow += " encrypted-media;"
  if (options.gyroscope) allow += " gyroscope;"
  if (options.pictureInPicture) allow += " picture-in-picture;"

  // Determine the allowFullScreen attribute based on the fullscreen option
  const allowFullScreen = options.fullscreen

  return { allowFullScreen, allow }
}

/**
 * types
 */
type YoutubeOptions = {
  autoplay: boolean
  clipboardWrite: boolean
  encryptedMedia: boolean
  gyroscope: boolean
  pictureInPicture: boolean
  fullscreen: boolean
}
