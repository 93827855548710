/// <reference types="vite-plugin-svgr/client" />
import ContactPicture from "@/assets/images/contact.svg?react"
import { FooterPicture } from "@/components/frontend/drawing"
import { PageRender } from "@/components/layout/frontend/page"

/**
 * dictionary src/dictionaries/en/pages/contact.json
 * dictionary src/dictionaries/fr/pages/contact.json
 * dictionary src/dictionaries/de/pages/contact.json
 */
export const dictionary = createContextMapper("pages", "contact")

/**
 * Page: Contact
 */
const Contact: React.FC = () => {
  return (
    <>
      <PageRender pageKey="contact" />
      <FooterPicture image={<ContactPicture />} />
    </>
  )
}
export default Contact
