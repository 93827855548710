import { Form, FormAssertive, FormExtraFields, FormKeywords, FormTiptap } from "@/components/form"
import { Button } from "@/components/frontend/button"
import { dictionary } from "."
import { useDefinitionForm } from "./useDefinitionForm"
import { StepsProps } from "./useStepper"

/**
 * FormDefinition
 */
export const FormDefinition: React.FC<
  { form: ReturnType<typeof useDefinitionForm> } & StepsProps
> = ({ form, setStep }) => {
  const { _ } = useDictionary(dictionary("form"))
  return (
    <Form
      form={form}
      className="flex flex-col gap-6"
      onSubmit={() => form.isValid && setStep("annexe")}
    >
      <FormAssertive />
      <FormExtraFields
        label={_("skills-label")}
        name="skills"
        translate={c => _(`skills-${c}`)}
        labelPopover={_("skills-popover")}
        required
      />
      <FormTiptap
        label={_("objective-label")}
        name="objective"
        labelPopover={_("objective-popover")}
      />
      <FormKeywords
        label={_("materials-label")}
        name="materials"
        labelPopover={_("materials-popover")}
      />
      <FormTiptap
        label={_("preparation-label")}
        name="preparation"
        labelPopover={_("preparation-popover")}
      />
      <FormTiptap label={_("follow-label")} name="follow" labelPopover={_("follow-popover")} />
      <FormTiptap label={_("values-label")} name="values" labelPopover={_("values-popover")} />
      <div className="flex justify-between">
        <Button onClick={() => setStep("information")} variant="outline">
          {_("back")}
        </Button>
        <Form.Submit asChild>
          <Button>{_("next")}</Button>
        </Form.Submit>
      </div>
    </Form>
  )
}
