/// <reference types="vite-plugin-svgr/client" />
import ProjectPicture from "@/assets/images/project.svg?react"
import { Pagination } from "@/components/collection"
import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { Button } from "@/components/frontend/button"
import { FooterPicture } from "@/components/frontend/drawing"
import { Container } from "@/components/layout/frontend/container"
import { PageRender } from "@/components/layout/frontend/page"
import { Wrapper } from "@/components/layout/frontend/wrapper"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { usePaginable } from "@/hooks/usePaginable"
import { useData } from "@/hooks/usePromise"
import { useResponsive } from "@/hooks/useResponsive"
import { service } from "@/services/frontend/service"
import { Project, localizeProject } from "@/store/frontend/localizers"
import { match } from "ts-pattern"
import { CreateDialog } from "./CreateDialog"
import { Filters } from "./Filters"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/fr/pages/projects.json
 * dictionary src/dictionaries/de/pages/projects.json
 */
export const dictionary = createContextMapper("pages", "projects")

/**
 * Page: Projects
 */
const Projects: React.FC = () => {
  const { _ } = useDictionary(dictionary("filters"))
  const media = useResponsive()
  const [projects] = useData(initialProjects, loadProjects)

  const [filters, setFilters] = usePersistedState<Filters>(
    initialFilters,
    "filters-frontend-projects",
    "interface",
    sessionStorage
  )

  const filtered = React.useMemo(
    () =>
      A.filter(projects, project => {
        const { orientations, categories } = filters
        return (
          (orientations.length === 0 || A.includes(orientations, project.orientation)) &&
          (categories.length === 0 || A.includes(categories, project.category))
        )
      }),
    [projects, filters]
  )

  const [paginable, paginated] = usePaginable(
    filtered,
    1,
    media.min("xl") ? 12 : media.min("lg") ? 8 : 6
  )

  const { setItem: create, ...createProps } = useDialog<void>()

  return (
    <>
      <PageRender pageKey="projects" />
      <Wrapper>
        <Container
          x="sm"
          className="flex flex-col lg:grid grid-cols-[325px_1fr] py-5 sm:py-[30px] gap-5 sm:gap-[30px]"
        >
          <div className="flex flex-col gap-5 sm:gap-[30px]">
            <Button variant="dark" onClick={() => create()}>
              {_("submit-project")}
            </Button>
            <Filters {...{ filters, setFilters }} />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 sm:gap-[30px]">
            {A.map(paginated, project => (
              <Item key={project.id} project={project} />
            ))}
            <Pagination {...paginable} className="col-span-1 md:col-span-2 xl:col-span-3" />
          </div>
        </Container>
      </Wrapper>
      <CreateDialog {...createProps} />
      <FooterPicture image={<ProjectPicture />} />
    </>
  )
}
export default Projects

/**
 * helpers
 */
const loadProjects = async () =>
  match(await service.projects.index())
    .with({ error: false }, ({ data }) => A.map(data.projects, localizeProject))
    .otherwise(() => initialProjects)
const initialProjects: Project[] = []

/**
 * filters
 */
type Filters = {
  orientations: Project["orientation"][]
  categories: Project["category"][]
}
const initialFilters = {
  orientations: [],
  categories: [],
}
