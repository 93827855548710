import { AlertTriangle, PlaySquare } from "lucide-react"

/**
 * VideoFallback
 */
const VideoFallback = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div"> & {
    ratio: string
  }
>(({ className, ratio, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cx("flex items-center justify-center bg-muted", ratio, className)}
    aria-hidden
    {...props}
  >
    {children ?? (
      <span className="relative" aria-hidden>
        <PlaySquare size={64} strokeWidth={0.5} />
        <AlertTriangle size={24} strokeWidth={1.4} className="absolute top-0 right-0 bg-muted" />
      </span>
    )}
  </div>
))

/**
 * Video
 */
type VideoProps = Extend<
  React.ComponentPropsWithoutRef<"video">,
  {
    children?: React.ReactNode
    src?: Option<string>
    type?: string
    ratio?: string
    wrapperCx?: string
  }
>
type VideoRef = React.ElementRef<"video">
const Video = React.forwardRef<VideoRef, VideoProps>(
  (
    {
      children,
      wrapperCx,
      className,
      src,
      controls = true,
      type = "video/mp4",
      ratio = "aspect-video",
      ...props
    },
    ref
  ) => {
    const [loadingError, setLoadingError] = React.useState(false)
    React.useEffect(() => setLoadingError(false), [src])

    return (
      <div className={cx(wrapperCx, ratio)}>
        {loadingError ? (
          <VideoFallback className={className} ratio={ratio}>
            {children}
          </VideoFallback>
        ) : G.isNotNullable(src) ? (
          <video
            ref={ref}
            {...props}
            controls={controls}
            className={cx("object-cover", ratio, className)}
            onError={() => setLoadingError(true)}
          >
            <source src={src} type={type} />
          </video>
        ) : (
          <VideoFallback className={className} ratio={ratio}>
            {children}
          </VideoFallback>
        )}
      </div>
    )
  }
)
export { type VideoProps, type VideoRef, Video }
