import { useFilterable } from "@/hooks/useFilterable"
import { useLimitable } from "@/hooks/useLimitable"
import { useMatchable } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"
import { useUsersStore } from "."
import { getFullname } from "./helpers"
import { User } from "./localizers"

/**
 * hooks
 */
export const useUsersById = () => useUsersStore(D.prop("users"))
export const useUser = (id: Option<string>) => useUsersStore(({ users }) => D.get(users, id ?? ""))
export const useUsers = () => {
  const byIds = useUsersById()
  const users = React.useMemo(() => D.values(byIds), [byIds])
  return users
}

/**
 * collection
 */
export const useFilteredUsers = (initialLimit = 24, initialByMore = 12) => {
  const users = useUsers()

  const [filterable, filterBy] = useFilterable<User>(
    "users",
    {
      pending: ({ status }) => status === "pending",
      active: ({ status }) => status === "active",
      suspended: ({ status }) => status === "suspended",
      deleted: ({ status }) => status === "deleted",
      admin: ({ role }) => role === "admin" || role === "superadmin",
    },
    { pending: true, admin: true }
  )

  const [matchable, matchIn] = useMatchable<User>([
    "profile.firstname",
    "profile.lastname",
    ({ profile: { firstname, lastname } }) => A.join([lastname, firstname, lastname], " "),
  ])

  const [sortable, sortBy] = useSortable<User>(
    "users",
    {
      firstname: ({ profile }) => profile.firstname,
      lastname: ({ profile }) => profile.lastname,
      company: ({ profile }) => profile.company,
      status: ({ status }) => status,
      createdAt: ({ createdAt }) => createdAt,
    },
    "firstname"
  )

  const filtered = React.useMemo(
    () => pipe(users, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [users, filterBy, matchable.search, matchIn, sortBy]
  )
  const [limitable, limit] = useLimitable<User>(filtered.length, initialLimit, initialByMore)

  return { users, filtered, filterable, matchable, sortable, limitable, limit }
}

/**
 * options
 */
export const useAuthorOptions = (placeholder: string) => {
  const authors = useUsers()
  return React.useMemo(
    () => [
      { label: placeholder, value: "null" },
      ...A.map(
        A.sortBy(authors, author => author.profile.lastname),
        author => ({
          label: getFullname(author, true),
          value: author.id,
        })
      ),
    ],
    [authors, placeholder]
  )
}
export const useRoleOptions = (disableSuperadmin: boolean) => {
  const _ = useFormDictionary()
  return React.useMemo(
    () => [
      {
        label: _("role-member"),
        value: "member",
      },
      {
        label: _("role-admin"),
        value: "admin",
      },
      {
        label: _("role-superadmin"),
        value: "superadmin",
        disabled: disableSuperadmin,
      },
    ],
    [disableSuperadmin, _]
  )
}
export const useStatusOptions = () => {
  const _ = useFormDictionary()
  return React.useMemo(
    () => [
      {
        label: _("user-status-pending"),
        value: "pending",
      },
      {
        label: _("user-status-active"),
        value: "active",
      },
      {
        label: _("user-status-deleted"),
        value: "deleted",
      },
      {
        label: _("user-status-suspended"),
        value: "suspended",
      },
    ],
    [_]
  )
}
