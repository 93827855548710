export const proseStyle: ClassName = cx(
  "prose max-w-none font-museo hyphens-auto text-be-independance",
  "prose-p:text-[14px] prose-p:leading-relaxed prose-p:my-[20px]",
  "prose-a:text-be-orient prose-a:underline prose-a:font-semibold",
  "prose-strong:text-be-orient prose-strong:font-semibold",
  "prose-h1:text-[40px] prose-h1:leading-tight prose-h1:font-bold prose-h1:uppercase prose-h1:my-[30px]",
  "prose-h2:text-[35px] prose-h2:leading-tight prose-h2:font-bold prose-h2:my-[30px]",
  "prose-h3:text-[25px] prose-h3:leading-tight prose-h3:font-semibold prose-h3:my-[30px]",
  "prose-h4:text-[20px] prose-h4:leading-tight prose-h4:font-semibold prose-h4:my-[20px]",
  "prose-h5:text-[18px] prose-h5:leading-tight prose-h5:font-semibold prose-h5:my-[20px]",
  "prose-h6:text-[16px] prose-h6:leading-tight prose-h6:font-semibold prose-h6:my-[20px]",
  "prose-ul:list-image-[url(@/assets/icons/rectangle.svg)] prose-ul:marker:w-[6px] prose-ul:marker:h-[6px] prose-ul:ml-0",
  "prose-li:marker:text-be-orient prose-li:marker:text-[14px] prose-li:marker:font-extrabold",
  "prose-blockquote:border-l-be-orient prose-blockquote:text-[14px] prose-blockquote:text-emperor prose-blockquote:font-normal prose-blockquote:italic"
)
export const relativeProseStyle: ClassName = cx(
  "prose max-w-none font-museo hyphens-auto text-be-independance",
  "prose-p:text-[0.875em] prose-p:leading-relaxed prose-p:my-[1.25em]",
  "prose-a:text-be-orient prose-a:underline prose-a:font-semibold",
  "prose-strong:text-be-orient prose-strong:font-semibold",
  "prose-h1:text-[2.5em] prose-h1:leading-tight prose-h1:font-bold prose-h1:uppercase prose-h1:my-[1.875em]",
  "prose-h2:text-[2.1875em] prose-h2:leading-tight prose-h2:font-bold prose-h2:my-[1.875em]",
  "prose-h3:text-[1.5625em] prose-h3:leading-tight prose-h3:font-semibold prose-h3:my-[1.875em]",
  "prose-h4:text-[1.25em] prose-h4:leading-tight prose-h4:font-semibold prose-h4:my-[1.25em]",
  "prose-h5:text-[1.125em] prose-h5:leading-tight prose-h5:font-semibold prose-h5:my-[1.25em]",
  "prose-h6:text-[1em] prose-h6:leading-tight prose-h6:font-semibold prose-h6:my-[1.25em]",
  "prose-ul:list-image-[url(@/assets/icons/rectangle.svg)] prose-ul:marker:w-[0.375em] prose-ul:marker:h-[0.375em] prose-ul:ml-0",
  "prose-li:marker:text-be-orient prose-li:marker:text-[0.875em] prose-li:marker:font-extrabold",
  "prose-blockquote:border-l-be-orient prose-blockquote:text-[0.875em] prose-blockquote:text-emperor prose-blockquote:font-normal prose-blockquote:italic"
)
