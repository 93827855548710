import { CmsContextProvider } from "@/components/cms/Context"
import { ContentItems } from "@/components/cms/components/content"
import SeoItem from "@/components/cms/components/seo"
import { Fields, Loader, Menu } from "@/components/collection"
import { ArticleUpdateDialog } from "@/components/dialogs/article-update"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { StatsDialog } from "@/components/trackings/dialogs/stats"
import { Button } from "@/components/ui/button"
import { Confirm, confirmSafeDictionary } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { usePromise } from "@/hooks/usePromise"

import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { textPlaceholder } from "@/fns/String"
import { deleteArticle, getArticle, updateArticle } from "@/store/articles/actions"
import { useArticle, useArticleCategory } from "@/store/articles/hooks"
import { Article } from "@/store/articles/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { useUser } from "@/store/users/hooks"
import {
  BarChart3,
  CalendarCheck,
  CalendarDays,
  Eye,
  EyeOff,
  FilePen,
  LayoutGrid,
  Shapes,
  Trash,
} from "lucide-react"
import { Redirect } from "wouter"

/**
 * dictionary src/dictionaries/en/pages/dashboard/articles.json
 */
const dictionary = createContextMapper("pages", "dashboard", "articles")
const types = ["news", "hero"] as Article["type"][]

/**
 * Page: ArticlesArticle
 */
const ArticlesArticle: React.FC<{ article: Article }> = ({ article }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()

  // confirm
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<void>({
    onAsyncConfirm: () => deleteArticle(article.id),
    dictionary: confirmSafeDictionary(dictionary("delete-confirm")),
  })

  //dialogs
  const { setItem: update, ...updateProps } = useDialog<Article>()
  const { setItem: statsDialog, ...statsProps } = useDialog<string>()
  const openStats = () => statsDialog(article.trackingId)

  // action
  const toggleState = () =>
    updateArticle(article.id, { state: article.state === "published" ? "draft" : "published" })

  const format = useDateFnsLocaleFormat()
  const category = useArticleCategory(article.category)
  const author = useUser(article.author)
  const isDraft = article.state === "draft"

  return (
    <CmsContextProvider
      ressource={{ type: "articles", ...D.selectKeys(article, ["id", "seo", "content"]) }}
    >
      <PageWrapper>
        <PageHeader
          breadcrumbs={[
            [_("breadcrumbs"), "/dashboard/articles"],
            [
              S.isEmpty(S.trim(t(article.seo).title ?? "")) ? _("no-title") : t(article.seo).title,
              `/dashboard/articles/${article.id}`,
            ],
          ]}
        />
        <SeoItem
          menu={<ContextMenu {...{ article, confirmDelete, openStats, update }} />}
          action={
            <>
              <Button icon size="xxs" onClick={openStats} aria-label={_(`menu.stats`)}>
                <BarChart3 aria-hidden />
              </Button>
              <Button
                variant={isDraft ? "secondary" : "success"}
                icon
                size="xxs"
                onClick={toggleState}
                aria-label={_(`state-${article.state}`)}
              >
                {isDraft ? <EyeOff aria-hidden /> : <Eye aria-hidden />}
              </Button>
            </>
          }
        >
          <h3 className="pt-4">{_("meta-title")}</h3>
          <Fields>
            {types.length > 1 && (
              <Fields.Item name={_("type")} icon={<Shapes aria-hidden />} stretch>
                {_(`type-${article.type}`)}
              </Fields.Item>
            )}
            {G.isNotNullable(author) && (
              <Fields.Item name={_("author")} icon={<LayoutGrid aria-hidden />} stretch>
                {textPlaceholder(
                  `${author.profile.firstname} ${author.profile.lastname}`,
                  _("no-author-profile")
                )}
              </Fields.Item>
            )}
            {G.isNotNullable(category) && (
              <Fields.Item name={_("category")} icon={<LayoutGrid aria-hidden />} stretch>
                {t(category).name}
              </Fields.Item>
            )}
            <Fields.Item name={_("published-at")} icon={<CalendarDays aria-hidden />} stretch>
              {format(article.publishedAt, "PPP")}
            </Fields.Item>
            {(G.isNotNullable(article.publishedFrom) || G.isNotNullable(article.publishedTo)) && (
              <Fields.Item name={_("published")} icon={<CalendarCheck aria-hidden />} stretch>
                {G.isNotNullable(article.publishedFrom) && (
                  <span>
                    <span className="font-medium">{_("published-from")}:</span>{" "}
                    {format(article.publishedFrom, "PPPp")}
                  </span>
                )}
                {G.isNotNullable(article.publishedTo) && (
                  <span>
                    <span className="font-medium">{_("published-to")}:</span>{" "}
                    {format(article.publishedTo, "PPPp")}
                  </span>
                )}
              </Fields.Item>
            )}
          </Fields>
        </SeoItem>
        <ContentItems />
        <StatsDialog {...statsProps} />
        <Confirm {...deleteProps} />
        <ArticleUpdateDialog {...updateProps} types={types} />
      </PageWrapper>
    </CmsContextProvider>
  )
}

type PageProps = { id: string }
export default ({ id }: PageProps) => {
  const { _ } = useDictionary(dictionary())
  const article = useArticle(id)
  const [result, inProgress] = usePromise(() => getArticle(id))
  if (inProgress) return <Loader breadcrumbs={[[_("breadcrumbs"), "/dashboard/articles"]]} />
  if (!result || result.error || G.isNullable(article) || !A.includes(types, article.type))
    return <Redirect to="/dashboard/articles" />
  return <ArticlesArticle {...{ article }} />
}

/**
 * ContextMenu
 */
type ContextMenuProps = {
  article: Article
  confirmDelete: () => void
  update: (article: Article) => void
  openStats: () => void
}
export const ContextMenu: React.FC<ContextMenuProps> = ({
  article,
  openStats,
  confirmDelete,
  update,
}) => {
  const { _ } = useDictionary(dictionary("menu"))
  const isPublished = article.state === "published"
  const toggleState = () =>
    updateArticle(article.id, { state: article.state === "published" ? "draft" : "published" })
  return (
    <>
      <Menu.Item onClick={openStats}>
        <BarChart3 aria-hidden />
        {_("stats")}
      </Menu.Item>
      <Menu.Item onClick={() => update(article)}>
        <FilePen aria-hidden />
        {_("update")}
      </Menu.Item>
      <Menu.Item onClick={toggleState}>
        {isPublished ? <EyeOff aria-hidden /> : <Eye aria-hidden />}
        {_(isPublished ? "unpublish" : "publish")}
      </Menu.Item>
      <Menu.Item onClick={confirmDelete}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
    </>
  )
}
