import { useCookies } from "@/components/cookies/lib/context/context"
import { Video } from "@/components/ui/video"
import { YoutubeIFrame } from "@/components/ui/youtube"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { useTranslation } from "@/store/languages/hooks"
import { Header, ItemMappingExport, getMediasFile } from "."
import { Container } from "../container"
import { Wrapper } from "../wrapper"

type RenderFC = ItemMappingExport<"video">["Render"]

export const Render: RenderFC = ({ item, files }) => {
  const t = useTranslation()
  const { titleLevel, youtube } = item.props
  const { title, secondary } = t(item).props
  const video = getMediasFile(files, item.props.video)
  const { canUse } = useCookies()

  if (!oneIsNotEmpty(title, stripeHtml(secondary)) && G.isNullable(youtube) && G.isNullable(video))
    return null
  return (
    <Wrapper margin="normal">
      <Container x="sm" className="flex flex-col gap-4">
        <Header {...{ title, secondary, titleLevel }} />
        {G.isNotNullable(youtube) ? (
          <YoutubeIFrame
            videoId={youtube}
            title={title ?? ""}
            className="rounded-md bg-muted"
            options={{ autoplay: false }}
          />
        ) : G.isNotNullable(video) ? (
          <Video
            src={video.url}
            aria-label={t(video).alt}
            wrapperCx="w-full aspect-video rounded-md overflow-hidden bg-muted"
          />
        ) : null}
      </Container>
    </Wrapper>
  )
}
