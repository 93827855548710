import { useDictionary } from "@/dictionaries/hooks"
import { searchCompare } from "@/fns/search"
import { Combobox } from "@headlessui/react"
import * as SelectPrimitive from "@radix-ui/react-select"
import { Check, ChevronDown } from "lucide-react"
import {
  FormFieldWrapper,
  FormFieldWrapperProps,
  inputIconVariants,
  inputVariants,
  useFieldContext,
} from "."
import { SrOnly } from "../ui/sr-only"

/**
 * dictionary src/dictionaries/en/components/form/select-create.json
 */
const dictionary = createContextMapper("components", "form", "select-create")

/**
 * FormSelectCreate
 */
type Props = SelectInputProps & FormFieldWrapperProps
export const FormSelectCreate: React.FC<Props> = ({
  label,
  labelAside,
  translatable,
  name,
  info,
  disabled,
  ...props
}) => (
  <FormFieldWrapper {...{ label, labelAside, translatable, name, info }}>
    <SelectInput {...props} disabled={disabled} />
  </FormFieldWrapper>
)

type SelectInputProps = {
  placeholder?: string
  options: FormSelectCreateOption[]
  className?: ClassName
  disabled?: boolean
}
const SelectInput: React.FC<SelectInputProps> = ({ className, placeholder, options, ...props }) => {
  const { value, setFieldValue, disabled } = useFieldContext<string>()
  const { _ } = useDictionary(dictionary())
  const filtered = React.useMemo(
    () =>
      S.isEmpty(value) ? options : A.filter(options, ({ label }) => searchCompare(value, label)),
    [value, options]
  )
  return (
    <Combobox value={value} onChange={setFieldValue} disabled={disabled || props.disabled}>
      {({ open }) => (
        <div className="relative">
          <Combobox.Input
            onChange={({ target }) => setFieldValue(target.value)}
            placeholder={placeholder}
            className={inputVariants({ icon: "right", size: "default", className })}
          />
          <Combobox.Button
            className={inputIconVariants({ side: "right", size: "default", className: "" })}
          >
            <ChevronDown size={16} className="opacity-50" />
            <SrOnly>{_("open")}</SrOnly>
          </Combobox.Button>
          <Combobox.Options
            className={cx(
              "absolute top-full inset-x-0 z-50 w-full max-h-48 p-1 overflow-hidden",
              "rounded-md border border-input shadow-md bg-popover text-popover-foreground",
              "slide-in-from-top-2",
              open ? "animate-in fade-in-0 zoom-in-95" : "animate-out fade-out-0 zoom-out-95"
            )}
          >
            {A.map(filtered, option => (
              <Combobox.Option
                key={option.value}
                value={option.value}
                as={React.Fragment}
                disabled={option.disabled}
              >
                {({ active, selected }) => (
                  <li
                    className={cx(
                      "relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none",
                      active && "bg-accent text-accent-foreground",
                      option.disabled && "opacity-50 pointer-events-none"
                    )}
                  >
                    <span
                      className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"
                      aria-hidden
                    >
                      {selected && <Check size={16} />}
                    </span>
                    {option.label}
                  </li>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </div>
      )}
    </Combobox>
  )
}

/**
 * types
 */
export type FormSelectCreateOption = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
  label: string
}
