import { dictionary } from "."

/**
 * useStepper
 */
export const useStepper = (reservationIsValid: boolean, contactIsValid: boolean) => {
  const { _ } = useDictionary(dictionary("reservation"))

  const [step, setStep] = React.useState<string>("reservation")
  const steps = React.useMemo(
    () => [
      {
        value: "reservation",
        disabled: false,
        name: _(`step-reservation`),
      },
      {
        value: "contact",
        disabled: !reservationIsValid,
        name: _(`step-contact`),
      },
      {
        value: "summary",
        disabled: !(reservationIsValid && contactIsValid),
        name: _(`step-summary`),
      },
    ],
    [_, reservationIsValid, contactIsValid]
  )

  return { step, setStep, steps }
}
