/**
 * useToggle
 */
export const useToggle = (initiale = false) => {
  const [state, setState] = React.useState(initiale)
  return {
    state,
    set: setState,
    toggle: () => setState(!state),
    open: () => setState(true),
    close: () => setState(false),
    setTrue: () => setState(true),
    setFalse: () => setState(false),
  }
}
