import {
  Form,
  FormAssertive,
  FormInput,
  FormSelect,
  FormSelectCreate,
  FormSubmit,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStores } from "@/store"
import { updateProgram } from "@/store/programs/actions"
import {
  useProgram,
  useProgramClassOptions,
  useProgramLanguageOptions,
} from "@/store/programs/hooks"
import { Program } from "@/store/programs/localizers"

/**
 * dictionary src/dictionaries/en/pages/dashboard/programs.json
 */
const dictionary = createContextMapper("pages", "dashboard", "programs")

/**
 * EditDialog
 */
export const EditDialog: React.FC<UseDialogProps<string>> = ({ item: id, onOpenChange, open }) => {
  const { _ } = useDictionary(dictionary())
  const item = useProgram(id)
  return (
    <Dialog {...{ open, onOpenChange }} title={_("edit-dialog.title")} className="max-w-2xl">
      {G.isNotNullable(item) && <DialogForm {...{ item, onOpenChange }} />}
    </Dialog>
  )
}
const DialogForm: React.FC<UseDialogFormProps<Program>> = ({ item, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const { min, max } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() =>
      D.selectKeys(item, [
        "name",
        "language",
        "code",
        "class",
        "programs",
        "books",
        "pearltrees",
        "archived",
      ])
    ),
    validate: validator({
      name: [min(1, _("form.name-required")), max(100, _("form.name-max"))],
      class: [min(1, _("form.class-required")), max(100, _("form.class-max"))],
      code: [min(1, _("form.code-required")), max(20, _("form.code-max"))],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return _("form.VALIDATION_FAILURE")
      const response = await updateProgram(item.id, values)
      if (!response.error) {
        toast.success(_("edit-dialog.success"))
        onOpenChange(false)
      } else if (response.code === "VALIDATION_FAILURE") return _(`form.VALIDATION_FAILURE`)
      else if (response.code === "FETCH_ERROR") return _(`form.FETCH_ERROR`)
      else resetAllStores()
    },
  })

  const languageOptions = useProgramLanguageOptions()
  const classOptions = useProgramClassOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormInput label={_("form.name-label")} name="name" maxLength={100} />
      <FormInput label={_("form.code-label")} name="code" maxLength={20} />
      <FormSelect label={_("form.language-label")} name="language" options={languageOptions} />
      <FormSelectCreate label={_("form.class-label")} name="class" options={classOptions} />
      <FormInput label={_("form.programs-label")} name="programs" type="url" />
      <FormInput label={_("form.books-label")} name="books" type="url" />
      <FormInput label={_("form.pearltrees-label")} name="pearltrees" type="url" />
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button variant="secondary">{_("form.back")}</Button>
        </Dialog.Close>
        <FormSubmit>{_("edit-dialog.submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
