import { Form, FormAssertive, FormInput, FormSubmit, useForm, validator } from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import globalConfig from "@/config/global"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { useMe } from "@/store/auth/hooks"
import { updateUser } from "@/store/users/actions"
import { match } from "ts-pattern"
import { UseDialogFormProps, UseDialogProps } from "../ui/hooks/useDialog"

/**
 * dictionary src/dictionaries/en/components/dialogs/account.json
 */
const dictionary = createContextMapper("components", "dialogs", "account")

/**
 * AccountDialog
 */
export const AccountDialog: React.FC<UseDialogProps<void>> = props => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="max-w-2xl">
      <AccountForm {...props} />
    </Dialog>
  )
}

/**
 * AccountForm
 */
const AccountForm: React.FC<UseDialogFormProps<void>> = ({ onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const me = useMe()

  const { min, isEmail } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      email: me.email,
      password: "",
      confirm: "",
    })),
    validate: validator({
      email: [min(1, _form("email-required")), isEmail(_form("email-format"))],
      password: [
        field =>
          S.isEmpty(S.trim(field)) ||
          N.gte(S.length(S.trim(field)), 8) ||
          _form("password-length", { count: 8 }),
      ],
      confirm: [(field, { password }) => field === password || _form("password-confirm")],
    }),
    onSubmit: async ({ values: { email, password } }) => {
      if (!form.isValid) return _errors("VALIDATION_FAILURE")
      const payload = {
        email,
        redirect: `${globalConfig.host}/validate-email`,
        password: S.isEmpty(password) ? undefined : S.trim(password),
      }
      return match(await updateUser(me.id, payload))
        .with({ error: false }, () => {
          toast.success(_("success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })

  const showEmailInfo =
    S.trim(S.toLowerCase(me.email)) !== S.trim(S.toLowerCase(form.values.email)) &&
    G.isNullable(form.errors.email)

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormInput
        label={_form("email-label")}
        name="email"
        type="email"
        info={showEmailInfo && _("email-dirty")}
        placeholder={_form("email-placeholder")}
      />
      <FormInput
        label={_form("password-label")}
        name="password"
        type="password"
        placeholder={_form("password-placeholder")}
      />
      <FormInput
        label={_form("confirm-label")}
        name="confirm"
        type="password"
        placeholder={_form("confirm-placeholder")}
      />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
