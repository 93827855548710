import { useDocumentVisibility } from "@/hooks/useDocumentVisibility"
import { useEffectOnce } from "@/hooks/useEffectOnce"
import { useInterval } from "@/hooks/useInterval"

/**
 * useLiveState
 */
export const useLiveState = <F extends () => void>(fn: F, refreshDelay = 30000) => {
  useEffectOnce(() => {
    fn()
  })
  const isVisible = useDocumentVisibility()
  useInterval(fn, isVisible ? refreshDelay : null)
  return isVisible
}
