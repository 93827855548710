import de from "./de.json"
import fr from "./fr.json"
export {fr, de}



interface TranslationObject {
    [key: string]: any;
}
export const findMissingTranslation = (t1: TranslationObject, t2: TranslationObject): string[]  => {
    const missingKeys: string[] = [];

    function compareKeys(o1: TranslationObject, o2: TranslationObject, prefix: string = ''): void {
        for (const key in o1) {
            if (!(key in o2)) {
                missingKeys.push(prefix + key);
            } else if (typeof o1[key] === 'object' && typeof o2[key] === 'object') {
                compareKeys(o1[key], o2[key], `${prefix}${key}.`);
            }
        }
    }

    compareKeys(t1, t2);
    compareKeys(t2, t1);

    return missingKeys;
}

