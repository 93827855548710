import { Fields, Loader, Menu, Section } from "@/components/collection"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { StatsCard } from "@/components/trackings/cards/stats"
import { Badge } from "@/components/ui/badge"
import { LinkExternal, linkCx } from "@/components/ui/link"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { usePromise } from "@/hooks/usePromise"
import { useIsAdmin } from "@/store/auth/hooks"
import { getProgram, updateProgram } from "@/store/programs/actions"
import { useProgram } from "@/store/programs/hooks"
import { Program } from "@/store/programs/localizers"
import {
  Archive,
  Barcode,
  Books,
  FileText,
  Globe,
  GraduationCap,
  NotePencil,
  TrashSimple,
  TreeStructure,
} from "@phosphor-icons/react"
import { CalendarClock, CalendarPlus, LibraryBig, PackageOpen } from "lucide-react"
import millify from "millify"
import { Redirect } from "wouter"
import { ContextProvider, usePageContext } from "../Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/programs.json
 */
const dictionary = createContextMapper("pages", "dashboard", "programs")

/**
 * Page: DashboardProgramsProgram
 */
type PageProps = { id: string }
const DashboardProgramsProgram: React.FC<PageProps> = ({ id }) => {
  const { _ } = useDictionary(dictionary())
  const program = useProgram(id)
  const isAdmin = useIsAdmin()

  const [result, inProgress] = usePromise(() => getProgram(id))
  if (inProgress) return <Loader breadcrumbs={[[_("breadcrumbs"), "/dashboard/programs"]]} />
  if (!result || result.error || G.isNullable(program)) return <Redirect to="/dashboard/programs" />

  return (
    <PageWrapper>
      <PageHeader
        breadcrumbs={[
          [_("breadcrumbs"), "/dashboard/programs"],
          [program.name, `/dashboard/programs/${id}`],
        ]}
      />
      <Menu type="context-menu" menu={<ContextMenu program={program} />} disabled={!isAdmin}>
        <PageContent className="inline @container">
          <SectionHeader program={program} />
          <SectionMeta program={program} />
          <SectionStats program={program} />
        </PageContent>
      </Menu>
    </PageWrapper>
  )
}

export default (props: PageProps) => (
  <ContextProvider>
    <DashboardProgramsProgram {...props} />
  </ContextProvider>
)

/**
 * SectionHeader
 */
type Props = { program: Program }
const SectionHeader: React.FC<Props> = ({ program }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Section className="mt-16">
      <div className="relative flex justify-center h-12">
        <div
          className={cx(
            "mx-auto absolute -top-20 w-32 h-32 shadow-sm rounded-full transition-all hover:scale-110"
          )}
        >
          <div className="flex items-center justify-center size-full rounded-full aspect-square bg-muted text-muted-foreground/10 border-4 border-card">
            <LibraryBig size={64} strokeWidth={1} />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-x-2">
        <h1 className="font-bold text-center text-3xl">{program.name}</h1>
      </div>
    </Section>
  )
}
/**
 * SectionMeta
 */
const SectionMeta: React.FC<Props> = props => {
  const { _ } = useDictionary(dictionary())
  const format = useDateFnsLocaleFormat()
  const { program } = props
  return (
    <Section>
      <Section.Header>
        <Section.HeaderTitle>{_("section-meta-title")}</Section.HeaderTitle>
        <Section.HeaderDescription>{_("section-meta-description")}</Section.HeaderDescription>
        <Section.Menu menu={<ContextMenu {...props} />} />
      </Section.Header>
      <Fields divider>
        <Fields.Item
          name={_("language")}
          icon={<Globe aria-hidden />}
          value={_(`language-${program.language as "en"}`)}
        />
        <Fields.Item name={_("class")} icon={<GraduationCap aria-hidden />} value={program.class} />
        <Fields.Item name={_("code")} icon={<Barcode aria-hidden />} value={program.code} />
        <Fields.Item
          name={_("created-at")}
          icon={<CalendarPlus aria-hidden />}
          value={format(program.createdAt, "PPpp")}
        />
        <Fields.Item
          name={_("updated-at")}
          icon={<CalendarClock aria-hidden />}
          value={format(program.updatedAt, "PPpp")}
        />
      </Fields>
    </Section>
  )
}

/**
 * SectionStats
 */
const SectionStats: React.FC<Props> = ({ program }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 max-w-screen-2xl mx-auto mt-4 gap-4">
      <Section.Card>
        <Section.Header>
          <Section.HeaderTitle>{_("section-programs-title")}</Section.HeaderTitle>
          <Badge variant="outline" className="absolute top-0 right-0 gap-2">
            <FileText aria-label={_("programs")} size={12} />
            {millify(program.programsVisits)}
          </Badge>
          <Section.HeaderDescription>
            <LinkExternal href={program.programs} className={linkCx}>
              {program.programs}
            </LinkExternal>
          </Section.HeaderDescription>
        </Section.Header>
        <StatsCard item={program.programsTrackingId} />
      </Section.Card>

      <Section.Card>
        <Section.Header>
          <Section.HeaderTitle>{_("section-books-title")}</Section.HeaderTitle>
          <Badge variant="outline" className="absolute top-0 right-0 gap-2">
            <Books aria-label={_("books")} size={12} />
            {millify(program.booksVisits)}
          </Badge>
          <Section.HeaderDescription>
            <LinkExternal href={program.books} className={linkCx}>
              {program.books}
            </LinkExternal>
          </Section.HeaderDescription>
        </Section.Header>
        <StatsCard item={program.booksTrackingId} />
      </Section.Card>

      <Section.Card>
        <Section.Header>
          <Section.HeaderTitle>{_("section-pearltrees-title")}</Section.HeaderTitle>
          <Badge variant="outline" className="absolute top-0 right-0 gap-2">
            <TreeStructure aria-label={_("pearltrees")} size={12} />
            {millify(program.pearltreesVisits)}
          </Badge>
          <Section.HeaderDescription>
            <LinkExternal href={program.pearltrees} className={linkCx}>
              {program.pearltrees}
            </LinkExternal>
          </Section.HeaderDescription>
        </Section.Header>
        <StatsCard item={program.pearltreesTrackingId} />
      </Section.Card>
    </div>
  )
}

/**
 * ContextMenu
 */
const ContextMenu: React.FC<Props> = ({ program }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const ctx = usePageContext()
  const toggleArchived = async () => {
    const response = await updateProgram(program.id, { archived: !program.archived })
    if (!response.error)
      toast.success(_(program.archived ? "unarchive-success" : "archive-success"))
    else toast.error(_(program.archived ? "unarchive-error" : "archive-error"))
  }
  return (
    <>
      <Menu.Item onClick={() => ctx.edit(program.id)}>
        <NotePencil />
        {_("edit")}
      </Menu.Item>
      <Menu.Item onClick={toggleArchived}>
        {program.archived ? <PackageOpen strokeWidth={1} aria-hidden /> : <Archive aria-hidden />}
        {_(program.archived ? "unarchive" : "archive")}
      </Menu.Item>
      <Menu.Item onClick={() => ctx.confirmDelete(program.id)}>
        <TrashSimple />
        {_("delete")}
      </Menu.Item>
    </>
  )
}
