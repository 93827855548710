import { byId } from "@/fns/byId"
import * as Languages from "@/services/languages/service"
import { match } from "ts-pattern"
import { languagesStore } from "."
import { localizeLanguage } from "./localizers"

/**
 * initLanguagesStore
 */
export const initLanguagesStore = async () =>
  match(await Languages.service.index())
    .with({ error: false }, ({ data }) => {
      languagesStore.evolve({ languages: byId(data.languages, localizeLanguage), initDone: true })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getLanguages
 */
export const getLanguages = async () =>
  match(await Languages.service.index())
    .with({ error: false }, ({ data }) => {
      languagesStore.evolve({ languages: byId(data.languages, localizeLanguage) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createLanguage
 */
export const createLanguage = async (formData: Languages.Payload["create"]) =>
  match(await Languages.service.create(formData))
    .with({ error: false }, ({ data }) => {
      languagesStore.evolve({ languages: D.set(data.language.id, localizeLanguage(data.language)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getLanguage
 */
export const getLanguage = async (id: Uuid) =>
  match(await Languages.service.read(id))
    .with({ error: false }, ({ data }) => {
      languagesStore.evolve({ languages: D.set(data.language.id, localizeLanguage(data.language)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateLanguage
 */
export const updateLanguage = async (id: Uuid, formData: Languages.Payload["update"]) =>
  match(await Languages.service.update(id, formData))
    .with({ error: false }, ({ data }) => {
      languagesStore.evolve({ languages: D.set(data.language.id, localizeLanguage(data.language)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteLanguage
 */
export const deleteLanguage = async (id: Uuid) =>
  match(await Languages.service.delete(id))
    .with({ error: false }, ({ data }) => {
      if (data.language)
        languagesStore.evolve({
          languages: D.set(data.language.id, localizeLanguage(data.language)),
        })
      else languagesStore.evolve({ languages: D.deleteKey(id) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))
