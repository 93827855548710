import tinygradient from "tinygradient"

/**
 * createGradient
 */
const ratioToStep = flow(N.multiply(100), Math.round, N.clamp(0, 100))

export const createGradient = (...hexes: string[]) => {
  const safeHexes =
    hexes.length === 0
      ? ["#000", "#000"]
      : hexes.length === 1
      ? [hexes[0] as string, hexes[0] as string]
      : hexes

  const colors = tinygradient(...safeHexes)
    .rgb(101)
    .map(g => g.toHexString())

  const toCSS = (deg = 90) => {
    const colorsPart = A.join(
      A.mapWithIndex(hexes, (i, hex) => `${hex} ${Math.round((i / (hexes.length - 1)) * 100)}%`),
      ", "
    )

    return `linear-gradient(${deg}deg, ${colorsPart})`
  }

  const toSlicedGradient = (startRatio: number, endRatio: number) => {
    const startIndex = ratioToStep(startRatio)
    const endIndex = ratioToStep(endRatio)
    const slicedHexes = A.slice(colors, startIndex, endIndex - startIndex)
    return createGradient(...slicedHexes)
  }

  return {
    colors,
    toCSS,
    ratio: (ratio: number) => {
      const step = ratioToStep(ratio)
      return colors[step - 1] ?? (colors[0] as string)
    },
    toSlicedGradient,
  }
}
