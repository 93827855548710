import { Button, focusOutlineClasses } from "@/components/frontend/button"
import { OptionDateInterval } from "@/components/frontend/datepicker"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { tryOrNull } from "@/fns/to"
import { useCalendarDays } from "@/hooks/useCalendar"
import { useResponsive } from "@/hooks/useResponsive"
import { CalendarBlank, CaretLeft, CaretRight, CaretUpDown, X } from "@phosphor-icons/react"
import * as Popover from "@radix-ui/react-popover"
import { AdaptHeight } from "../ui/adapt-height"

/**
 * dictionary src/dictionaries/en/components/frontend/datepicker.json
 */
const dictionary = createContextMapper("components", "frontend", "datepicker")

/**
 * DateIntervalSelect
 */

type DateIntervalSelectProps = {
  interval: OptionDateInterval
  min?: Date
  onChange: (interval: OptionDateInterval) => void
}

export const DateIntervalSelect: React.FC<DateIntervalSelectProps> = props => {
  const { _ } = useDictionary(dictionary())

  const [openSelect, setOpenSelect] = React.useState<Option<"start" | "end">>(null)
  const format = useDateFnsLocaleFormat()
  const nowDate = React.useMemo(() => new Date(), [])

  return (
    <div className="flex items-center gap-4 border-[1px] border-mercury rounded-[2px] w-full px-5 py-4 text-sm text-gray min-h-[65px]">
      <CalendarBlank className="w-5 opacity-50" />

      <div className="flex items-center gap-1.5 flex-1 text-gray">
        <span>{_("from")}</span>
        <DateSelect
          value={props.interval.start}
          open={openSelect === "start"}
          min={nowDate}
          onOpenChange={open => {
            if (open) props.onChange({ start: null, end: null })
            setOpenSelect(open ? "start" : null)
          }}
          onChange={start => {
            props.onChange({ start, end: null })
            setTimeout(() => setOpenSelect("end"), 1)
          }}
        >
          {props.interval.start ? format(props.interval.start, "dd/MM/yyyy") : "--/--/----"}
        </DateSelect>
        <span>{_("to")}</span>
        <DateSelect
          open={openSelect === "end"}
          selectedDate={props.interval.start}
          onOpenChange={(open, modal) => {
            setTimeout(() => setOpenSelect(open ? "end" : null), open || modal ? 0 : 700)
          }}
          value={props.interval.end}
          onChange={end => props.onChange({ ...props.interval, end })}
          min={props.interval.start}
          selectDisabled={date =>
            props.interval.start
              ? T.isBefore(date, props.interval.start) || T.isSameDay(date, props.interval.start)
              : false
          }
          disabled={!props.interval.start}
        >
          {props.interval.end ? format(props.interval.end, "dd/MM/yyyy") : "--/--/----"}
        </DateSelect>
      </div>

      {(props.interval.start || props.interval.end) && (
        <Button
          size="icon"
          className="w-7 h-7 bg-transparent"
          onClick={() => props.onChange({ start: null, end: null })}
        >
          <X className="w-4" />
        </Button>
      )}
    </div>
  )
}

/**
 * DateSelect
 */

type DateSelectProps = Extend<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  {
    min?: Option<Date>
    value: Option<Date>
    selectedDate?: Option<Date>
    onChange: (date: Option<Date>) => void
    selectDisabled?: (date: Date) => boolean
    children: React.ReactNode
    open: boolean
    onOpenChange: (open: boolean, modal: boolean) => void
  }
>

export const DateSelect: React.FC<DateSelectProps> = ({
  min,
  open,
  onOpenChange,
  children,
  onChange,
  disabled,
  ...props
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const media = useResponsive()

  const handleMobilePicker = () => {
    inputRef.current?.showPicker()
  }

  return media.max("sm") ? (
    <span className="group/datepicker">
      <input
        type="date"
        ref={inputRef}
        className="sr-only"
        disabled={disabled}
        min={min ? T.format(T.addDays(min, 1), "yyyy-MM-dd") : ""}
        value={props.value ? T.format(props.value, "yyyy-MM-dd") : ""}
        onChange={e => {
          const date = tryOrNull(() => T.parse(e.target.value, "yyyy-MM-dd", new Date())) ?? null
          onChange(date && T.isValid(date) ? date : null)
        }}
      />
      <span
        aria-hidden
        className={cx(
          "underline uppercase tracking-wider underline-offset-2 group-focus-within/datepicker:text-frontend-primary font-medium",
          disabled && "opacity-30"
        )}
        onClick={handleMobilePicker}
      >
        {children}
      </span>
    </span>
  ) : (
    <Popover.Root open={open} onOpenChange={open => onOpenChange(open, true)}>
      <Popover.Trigger
        disabled={disabled}
        className="underline uppercase tracking-wider underline-offset-2 data-[state='open']:text-frontend-primary font-medium disabled:opacity-50"
      >
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="PopoverContent" sideOffset={5} side="bottom" align="start">
          <div className="w-[300px] animate-appear-opacity duration-150">
            <Calendar {...props} onChange={onChange} close={() => onOpenChange(false, false)} />
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

/**
 * Calendar
 */

type CalendarProps = {
  onChange: (date: Date) => void
  selectedDate?: Option<Date>
  value: Option<Date>
  close: Voided
  selectDisabled?: (date: Date) => boolean
}

export const Calendar: React.FC<CalendarProps> = props => {
  const [monthsView, setMonthsView] = React.useState(false)
  const format = useDateFnsLocaleFormat()
  const calendar = useCalendarDays()
  const { _ } = useDictionary(dictionary())

  React.useEffect(() => {
    const initialDate = props.value ? props.value : props.selectedDate ? props.selectedDate : null
    if (initialDate) calendar.setSelectedDate(initialDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.selectedDate])

  return (
    <div className="relative w-full flex flex-col pt-5 border-[1px] rounded-[3px] shadow-lg border-mercury bg-white">
      <div className="flex justify-between px-4 pb-5">
        <button
          className={cx(calendarButtonClasses, "hover:bg-mercury")}
          onClick={() => (monthsView ? calendar.open({ years: -1 }) : calendar.openPrevMonth())}
        >
          <CaretLeft className="w-4" />
        </button>

        <div
          className={cx(
            focusOutlineClasses,
            "flex items-center gap-2 select-none cursor-pointer text-orient"
          )}
        >
          <span
            onClick={() => setMonthsView(B.inverse)}
            className="font-semibold text-sm underline underline-offset-4 decoration-orient/25 "
          >
            {format(calendar.openedDate, monthsView ? "yyyy" : "MMMM")}
          </span>
          <button
            className={cx(
              focusOutlineClasses,
              "hover:bg-aquahaze text-xs font-bold px-2 py-2 rounded-[2px] flex items-center gap-2"
            )}
            onClick={() => setMonthsView(B.inverse)}
          >
            {T.isSameYear(calendar.now, calendar.openedDate) || monthsView
              ? null
              : format(calendar.openedDate, "yyyy")}
            {monthsView ? <X className="w-4" /> : <CaretUpDown className="w-4" />}
          </button>
        </div>

        <button
          className={cx(calendarButtonClasses, "hover:bg-mercury")}
          onClick={() => (monthsView ? calendar.open({ years: 1 }) : calendar.openNextMonth())}
        >
          <CaretRight className="w-4" />
        </button>
      </div>

      <AdaptHeight>
        <div className="my-2 px-4 pb-3 flex flex-col gap-4">
          {monthsView ? (
            <div className="grid grid-cols-3 grid-rows-4 grid-flow-col">
              {calendar.openedYearMonths.map(date => (
                <button
                  className={cx(
                    focusOutlineClasses,
                    "w-full tracking-wider font-medium text-gray py-2 rounded-[2px] hover:bg-mercury hover:text-black text-sm transition-all"
                  )}
                  key={`month.${+date}`}
                  onClick={() => {
                    calendar.setOpenedDate(date)
                    setMonthsView(false)
                  }}
                >
                  {format(date, "MMM")}
                </button>
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-7 gap-x-1.5 gap-y-2 w-full justify-between">
              {calendar.openDays.map(date => {
                const isToday = T.isSameDay(date, calendar.now)
                const isMonth = T.isSameMonth(date, calendar.now)
                const isSelected =
                  (props.value && T.isSameDay(date, props.value)) ||
                  (props.selectedDate && T.isSameDay(date, props.selectedDate))

                const isWithin =
                  props.selectedDate &&
                  props.value &&
                  T.isWithinInterval(date, { start: props.selectedDate, end: props.value })

                const isDisabled = props.selectDisabled ? props.selectDisabled(date) : false

                return (
                  <button
                    disabled={isDisabled}
                    className={cx(
                      focusOutlineClasses,
                      "relative w-full  aspect-square rounded-[1px] text-sm transition-all",
                      isToday ? "bg-aquahaze" : "",
                      isSelected
                        ? "ring-2 ring-orient/75 text-orient font-medium"
                        : isDisabled
                        ? "bg-transparent text-gray/75"
                        : isMonth
                        ? "text-shark hover:bg-aquahaze hover:text-black"
                        : "text-gray hover:bg-aquahaze hover:text-black",
                      isWithin && "bg-orient/20 text-orient",
                      "hover:bg-orient/20 hover:text-orient"
                    )}
                    key={`date.${+date}`}
                    onClick={() => {
                      props.onChange(date)
                      props.close()
                    }}
                  >
                    {format(date, "d")}
                    {isToday && (
                      <span className="w-1.5 absolute bottom-0 translate-y-[50%] left-[50%] translate-x-[-50%] aspect-square rounded-full bg-orient ring-[2.5px] ring-offset-0 ring-white" />
                    )}
                  </button>
                )
              })}
            </div>
          )}

          {!T.isSameMonth(calendar.now, calendar.openedDate) && (
            <button
              className="w-full flex items-center justify-center gap-4 text-[11px] font-semibold uppercase py-2 px-2 rounded-[2px] tracking-wider text-orient bg-aquahaze"
              onClick={() => {
                calendar.selectNow()
                setMonthsView(false)
              }}
            >
              <span className="w-1.5 aspect-square rounded-full bg-orient" />
              {_("today")}
            </button>
          )}
        </div>
      </AdaptHeight>
    </div>
  )
}

const calendarButtonClasses = cx(
  focusOutlineClasses,
  "w-8 rounded-[2px] aspect-square flex justify-center items-center transition-all"
)
