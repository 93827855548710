import { byId } from "@/fns/byId"
import * as Workshops from "@/services/workshops/service"
import { match } from "ts-pattern"
import { workshopsStore } from "."
import { mediasStore } from "../medias"
import { localizeMediaFile } from "../medias/localizers"
import { placesStore } from "../places"
import { extractFilesFromPlaces } from "../places/helpers"
import { localizePlace } from "../places/localizers"
import {
  extractEventsFromWorkshops,
  extractPlacesFromWorkshops,
  extractReservationFromEvents,
} from "./helpers"
import {
  WorkshopEvent,
  WorkshopReservation,
  localizeWorkshop,
  localizeWorkshopEvent,
  localizeWorkshopReservation,
} from "./localizers"

/**
 * getWorkshops
 */
export const getWorkshops = async () =>
  match(await Workshops.service.index())
    .with({ error: false }, ({ data }) => {
      const events = extractEventsFromWorkshops(data.workshops)
      const places = extractPlacesFromWorkshops(data.workshops)
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromPlaces(places), localizeMediaFile)),
      })
      placesStore.evolve({ places: D.merge(byId(places, localizePlace)) })
      workshopsStore.evolve({
        workshops: byId(data.workshops, localizeWorkshop),
        events: byId(events, localizeWorkshopEvent),
        reservations: byId(extractReservationFromEvents(events), localizeWorkshopReservation),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createWorkshop
 */
export const createWorkshop = async (payload: Workshops.Payload["create"]) =>
  match(await Workshops.service.create(payload))
    .with({ error: false }, ({ data }) => {
      const places = extractPlacesFromWorkshops([data.workshop])
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromPlaces(places), localizeMediaFile)),
      })
      placesStore.evolve({ places: D.merge(byId(places, localizePlace)) })
      workshopsStore.evolve({
        workshops: D.set(data.workshop.id, localizeWorkshop(data.workshop)),
      })
      return { error: false, id: data.workshop.id } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getWorkshop
 */
export const getWorkshop = async (id: string) =>
  match(await Workshops.service.read(id))
    .with({ error: false }, ({ data }) => {
      const events = extractEventsFromWorkshops([data.workshop])
      const places = extractPlacesFromWorkshops([data.workshop])
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromPlaces(places), localizeMediaFile)),
      })
      placesStore.evolve({ places: D.merge(byId(places, localizePlace)) })
      workshopsStore.evolve({
        workshops: D.set(data.workshop.id, localizeWorkshop(data.workshop)),
        events: D.merge(byId(events, localizeWorkshopEvent)),
        reservations: D.merge(
          byId(extractReservationFromEvents(events), localizeWorkshopReservation)
        ),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateWorkshop
 */
export const updateWorkshop = async (id: string, payload: Workshops.Payload["update"]) =>
  match(await Workshops.service.update(id, payload))
    .with({ error: false }, ({ data }) => {
      const events = extractEventsFromWorkshops([data.workshop])
      const places = extractPlacesFromWorkshops([data.workshop])
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromPlaces(places), localizeMediaFile)),
      })
      placesStore.evolve({ places: D.merge(byId(places, localizePlace)) })
      workshopsStore.evolve({
        workshops: D.set(data.workshop.id, localizeWorkshop(data.workshop)),
        events: D.merge(byId(events, localizeWorkshopEvent)),
        reservations: D.merge(
          byId(extractReservationFromEvents(events), localizeWorkshopReservation)
        ),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteWorkshop
 */
export const deleteWorkshop = async (id: string) =>
  match(await Workshops.service.delete(id))
    .with({ error: false }, () => {
      workshopsStore.evolve({
        workshops: D.deleteKey(id),
        events: events =>
          D.reject(events, ({ workshopId }) => workshopId === id) as ById<WorkshopEvent>,
        reservations: reservations =>
          D.reject(
            reservations,
            ({ workshopId }) => workshopId === id
          ) as ById<WorkshopReservation>,
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createWorkshopEvent
 */
export const createWorkshopEvent = async (payload: Workshops.Payload["events"]["create"]) =>
  match(await Workshops.service.events.create(payload))
    .with({ error: false }, ({ data }) => {
      workshopsStore.evolve({
        events: D.set(data.event.id, localizeWorkshopEvent(data.event)),
      })
      return { error: false, id: data.event.id } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateWorkshopEvent
 */
export const updateWorkshopEvent = async (
  eventId: string,
  payload: Workshops.Payload["events"]["update"]
) =>
  match(await Workshops.service.events.update(eventId, payload))
    .with({ error: false }, ({ data }) => {
      workshopsStore.evolve({
        events: D.set(data.event.id, localizeWorkshopEvent(data.event)),
        reservations: D.merge(
          byId(extractReservationFromEvents([data.event]), localizeWorkshopReservation)
        ),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteWorkshopEvent
 */
export const deleteWorkshopEvent = async (id: string) =>
  match(await Workshops.service.events.delete(id))
    .with({ error: false }, () => {
      workshopsStore.evolve({
        events: D.deleteKey(id),
        reservations: reservations =>
          D.reject(reservations, ({ eventId }) => eventId === id) as ById<WorkshopReservation>,
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteWorkshopReservation
 */
export const deleteWorkshopReservation = async (id: string) =>
  match(await Workshops.service.reservations.delete(id))
    .with({ error: false }, () => {
      workshopsStore.evolve({ reservations: D.deleteKey(id) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * archiveWorkshopReservation
 */
export const archiveWorkshopReservation = async (id: string) =>
  match(await Workshops.service.reservations.archive(id))
    .with({ error: false }, ({ data }) => {
      workshopsStore.evolve({
        reservations: D.set(data.reservation.id, localizeWorkshopReservation(data.reservation)),
      })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))
