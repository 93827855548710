import { Card, Menu, Row } from "@/components/collection"
import { Badge } from "@/components/ui/badge"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { createGradient } from "@/fns/colors"
import { useSelectItem } from "@/hooks/useSelect"
import { WorkshopEvent } from "@/store/workshops/localizers"
import { CalendarClock, Ticket, TicketCheck, TicketPercent } from "lucide-react"
import { Context, ContextMenu, usePageContext } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/workshops.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "events")

type Props = { event: WorkshopEvent }

/**
 * Item
 */
export const Item: React.FC<Props> = props => {
  const { event } = props
  const { view, edit } = usePageContext()
  const { selectItemProps } = useSelectItem(Context, event.id, () => edit(event))

  return (
    <Menu type="context-menu" menu={<ContextMenu {...props} />} asChild>
      <div className="grid" {...selectItemProps}>
        {view === "grid" ? <ItemCard {...props} /> : <ItemRow {...props} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCard
 */
const ItemCard: React.FC<Props> = props => {
  const { event } = props
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, event.id)
  const format = useDateFnsLocaleFormat()

  const bookingPercent = React.useMemo(
    () =>
      event.reservationsSlot > 0
        ? Math.floor((100 / event.reservationsSlot) * event.reservationsDone)
        : 100,
    [event.reservationsDone, event.reservationsSlot]
  )
  const bookingPercentColor = React.useMemo(
    () => bookingGradient.ratio(bookingPercent / 100),
    [bookingPercent]
  )

  return (
    <Card selected={isSelected}>
      <Card.Header>
        <Card.Title className="line-clamp-1">{format(event.datetime, "PPPpp")}</Card.Title>
        <Card.Description className="line-clamp-3">
          {_("reservations")} : {event.reservationsDone}/{event.reservationsSlot}
        </Card.Description>
        <Card.Menu menu={<ContextMenu {...props} />} />
      </Card.Header>
      <Card.Content>
        <Badge
          variant="secondary"
          style={
            event.reservationsDone > 0
              ? { backgroundColor: bookingPercentColor, color: "white" }
              : undefined
          }
        >
          <TicketCheck aria-hidden />
          {event.reservationsDone >= event.reservationsSlot
            ? _("booking-full")
            : `${bookingPercent}%`}
        </Badge>
      </Card.Content>
    </Card>
  )
}

/**
 * ItemRow
 */
const ItemRow: React.FC<Props> = props => {
  const { event } = props
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, event.id)
  const format = useDateFnsLocaleFormat()

  const bookingPercent = React.useMemo(
    () =>
      event.reservationsSlot > 0
        ? N.clamp(Math.floor((100 / event.reservationsSlot) * event.reservationsDone), 0, 100)
        : 100,
    [event.reservationsDone, event.reservationsSlot]
  )
  const bookingPercentColor = React.useMemo(
    () => bookingGradient.ratio(bookingPercent / 100),
    [bookingPercent]
  )

  return (
    <Row selected={isSelected} className={cx(status === "deleted" && "opacity-75")}>
      <Row.Image>
        <CalendarClock size={16} />
      </Row.Image>
      <Row.Header>
        <Row.Title>{format(event.datetime, "PPPPp")}</Row.Title>
      </Row.Header>
      <Row.Content className="flex flex-wrap gap-2">
        <Badge variant="secondary">
          <Ticket aria-hidden />
          {`${event.reservationsDone} / ${event.reservationsSlot}`}
        </Badge>
        <Badge
          variant="secondary"
          style={
            event.reservationsDone > 0
              ? { backgroundColor: bookingPercentColor, color: "white" }
              : undefined
          }
        >
          <TicketPercent aria-hidden />
          {event.reservationsDone >= event.reservationsSlot
            ? _("booking-full")
            : `${bookingPercent}%`}
        </Badge>
      </Row.Content>
      <Row.Menu menu={<ContextMenu {...props} />} />
    </Row>
  )
}

/**
 * helpers
 */
const bookingGradient = createGradient("#dc2626", "#d97706", "#16a34a")
