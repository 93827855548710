/// <reference types="vite-plugin-svgr/client" />
import Dance from "@/assets/icons/dance.svg?react"
import { Fields } from "@/components/collection"
import { Card as CollectionCard } from "@/components/collection/card"
import { Card } from "@/components/ui/card"
import { Link } from "@/components/ui/link"
import { useDictionary } from "@/dictionaries/hooks"
import { useInterval } from "@/hooks/useInterval"
import { useLiveState } from "@/hooks/useLiveState"
import { getLastestProjects } from "@/store/projects/actions"
import { Project } from "@/store/projects/localizers"
import { MaskHappy, MusicNotes } from "@phosphor-icons/react"
import { motion } from "framer-motion"
import { Globe, ImageOff, Signpost } from "lucide-react"

/**
 * dictionary src/dictionaries/en/pages/dashboard/projects.json
 */
const dictionary = createContextMapper("pages", "dashboard", "projects")

type Props = {
  offset?: number
  scaleFactor?: number
}
export const LastestProjects: React.FC<Props> = ({ offset, scaleFactor }) => {
  const { _ } = useDictionary(dictionary())

  const CARD_OFFSET = offset || 10
  const SCALE_FACTOR = scaleFactor || 0.06

  const [projects, setProjects] = React.useState<Project[]>([])

  useLiveState(async () => {
    const response = await getLastestProjects()
    if (response.error) return
    setProjects(A.take(response.projects, 4))
  })

  const next = () =>
    setProjects((prevCards: Project[]) => {
      const newArray = [...prevCards] // create a copy of the array
      newArray.unshift(newArray.pop()!) // move the last element to the front
      return newArray
    })

  const previous = () =>
    setProjects((prevCards: Project[]) => {
      const newArray = [...prevCards] // create a copy of the array
      newArray.push(newArray.shift()!) // move the first element to the end
      return newArray
    })

  // todo : find a solution to prevent scrolling on the whole page
  const onWheel: React.WheelEventHandler<HTMLDivElement> = e => {
    return
    if (e.deltaY < 0) previous()
    else next()
  }

  useInterval(next, 10000)

  return (
    <Card.Spotlight>
      <Card.Header>
        <Card.Title className="line-clamp-1">{_("lastest-projects.title")}</Card.Title>
        <Card.Description className="line-clamp-3">
          {_("lastest-projects.secondary")}
        </Card.Description>
      </Card.Header>
      <Card.Content>
        <div className="relative max-w-[400px] min-h-[420px] mx-auto mt-4" onWheel={onWheel}>
          {A.mapWithIndex(projects, (index, project) => (
            <motion.div
              key={project.id}
              className="absolute inset-0 grid size-full"
              style={{
                transformOrigin: "top center",
              }}
              animate={{
                top: index * -CARD_OFFSET,
                scale: 1 - index * SCALE_FACTOR, // decrease scale for cards that are behind
                zIndex: projects.length - index, //  decrease z-index for the cards that are behind
              }}
            >
              <CollectionCard.NoSpotlight
                className={cx("transition-colors", index === 0 && "hover:border-primary")}
              >
                <CollectionCard.Image src={project.image?.url} className="aspect-[20/9]">
                  <ImageOff size={64} strokeWidth={0.8} aria-hidden />
                </CollectionCard.Image>
                <CollectionCard.Header>
                  <CollectionCard.Title className="line-clamp-1">
                    {project.name}
                  </CollectionCard.Title>
                  <CollectionCard.Description className="line-clamp-3">
                    {project.description}
                  </CollectionCard.Description>
                </CollectionCard.Header>
                <CollectionCard.Content>
                  <Fields divider>
                    <Fields.Item
                      name={_("category")}
                      icon={
                        <>
                          {project.category === "dance" && <Dance aria-hidden />}
                          {project.category === "theater" && <MaskHappy aria-hidden />}
                          {project.category === "music" && <MusicNotes aria-hidden />}
                        </>
                      }
                      value={_(`category-${project.category}`)}
                      stretch
                    />
                    <Fields.Item
                      name={_("orientation")}
                      icon={<Signpost size={16} aria-hidden />}
                      value={_(`orientation-${project.orientation}`)}
                      stretch
                    />
                    <Fields.Item
                      name={_("language")}
                      icon={<Globe size={16} aria-hidden />}
                      value={_(`language-${project.language as "en" | "fr" | "de"}`)}
                      stretch
                    />
                  </Fields>
                </CollectionCard.Content>
                {index === 0 && (
                  <Link
                    href={`/dashboard/projects/${project.id}`}
                    className="absolute inset-0 size-full"
                  />
                )}
              </CollectionCard.NoSpotlight>
            </motion.div>
          ))}
        </div>
      </Card.Content>
    </Card.Spotlight>
  )
}
