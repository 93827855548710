import { useLayoutContext } from "@/components/layout/frontend/context"
import globalConfig from "@/config/global"
import { byId } from "@/fns/byId"
import { usePromise } from "@/hooks/usePromise"
import { RouterPageKey, routerPages } from "@/routes/pages"
import { service } from "@/services/frontend/service"
import { localizePage } from "@/store/frontend/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { localizeMediaFile } from "@/store/medias/localizers"
import { extractFilesFromPage } from "@/store/pages/helpers"
import { useTracking } from "@/store/trackings/hooks"
import { useTitle } from "hoofd"
import { match } from "ts-pattern"
import { ItemRenderDispatcher } from "./items"

type PageProps = { pageKey: RouterPageKey; children?: React.ReactNode }
const initialResponse = {
  error: true,
  code: "FETCH_ERROR",
  page: null,
  files: {},
} as const
export const PageRender: React.FC<PageProps> = ({ pageKey, children }) => {
  const pageRoute = D.get(routerPages, pageKey)
  const { loading } = useLayoutContext()
  const t = useTranslation()
  const [{ page, files }, inProgress] = usePromise(
    async () => {
      if (G.isNullable(pageRoute)) return navigate("/not-found") as never
      loading.setTrue()
      const response = await service.pages.read(pageRoute.id)
      loading.setFalse()
      return match(response)
        .with({ error: false }, ({ data }) => {
          return {
            error: false,
            page: localizePage(data.page),
            files: byId(extractFilesFromPage(data.page), localizeMediaFile),
          } as const
        })
        .otherwise(({ code }) => {
          match(code)
            .with("RESOURCE_NOT_FOUND", () => navigate("/not-found"))
            .otherwise(() => navigate(`/server-error?code=${code}`))
          return initialResponse
        })
    },
    [],
    initialResponse
  )

  // SEO & Tracking
  const title = G.isNotNullable(page)
    ? `${globalConfig.siteName} - ${t(page.seo).title}`
    : globalConfig.siteName
  useTitle(title)
  useTracking(page?.trackingId)

  return inProgress ? null : (
    <>
      {G.isNotNullable(page) &&
        A.map(page.content.items, item => (
          <ItemRenderDispatcher item={item} key={item.id} files={files} />
        ))}
      {children}
    </>
  )
}
