import { i18nConfig } from "@/config/i18n"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { service } from "@/services/auth/service"
import { CaretDown } from "@phosphor-icons/react"
import * as Dropdown from "@radix-ui/react-dropdown-menu"

/**
 * dictionary src/dictionaries/en/components/frontend/layout.json
 */
const dictionary = createContextMapper("components", "frontend", "layout")

export const LanguagesSelector: React.FC = () => {
  const { language, changeLanguage } = useDictionary(dictionary("header"))
  const languages = i18nConfig.locales
  const [isExpanded, setIsExpanded] = React.useState(false)

  // languagedetector can assign language as code like en-US
  const current = pipe(language, S.split("-"), A.map(S.trim), A.getUnsafe(0), S.toLowerCase)
  const change = (code: string) => {
    changeLanguage(code)
    service.language(code)
  }
  return (
    <Dropdown.Root onOpenChange={() => setIsExpanded(!isExpanded)} modal={false}>
      <Dropdown.Trigger>
        <span
          className={cx(
            "flex justify-center items-center gap-x-1.5 p-2.5 max-h-9 w-[53px]",
            "font-bold text-xs text-bluewhale hover:text-orient",
            "uppercase",
            "bg-transparent bg-aquahaze hover:bg-solitude focus:bg-solitude-100",
            "transition-all"
          )}
        >
          {current}
          <CaretDown
            className={cx(
              "w-3 text-bluewhale transition-all transform duration-300",
              isExpanded && "rotate-180"
            )}
            weight="bold"
          />
        </span>
      </Dropdown.Trigger>

      <Dropdown.Portal>
        <Dropdown.Content
          className={cx(
            "flex flex-wrap w-[53px] z-[10000] overflow-hidden bg-aquahaze px-1.5",
            "data-[state=open]:animate-in data-[state=closed]:animate-out ",
            "data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 ",
            "data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 ",
            "data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 ",
            "data-[side=top]:slide-in-from-bottom-2 "
          )}
          side="bottom"
          align="end"
        >
          {languages
            .filter(code => code !== current)
            .map(code => (
              <Dropdown.Item
                className={cx(
                  "relative select-none cursor-pointer p-1 mb-2.5",
                  "outline-none bg-aquahaze",
                  "focus:bg-solitude focus:text-slate-900",
                  "data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
                  "text-xs text-emperor uppercase",
                  "transition-colors w-full"
                )}
                key={code}
                onClick={() => change(code)}
              >
                {code}
              </Dropdown.Item>
            ))}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  )
}
