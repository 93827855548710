import { z } from "zod"

/**
 * apiProgram
 */
export const apiProgram = z.object({
  id: z.string().uuid(),
  name: z.string(),
  language: z.string(),
  code: z.string(),
  class: z.string(),
  programs: z.string(),
  programsVisits: z.number(),
  programsTrackingId: z.string().uuid(),
  books: z.string(),
  booksVisits: z.number(),
  booksTrackingId: z.string().uuid(),
  pearltrees: z.string(),
  pearltreesVisits: z.number(),
  pearltreesTrackingId: z.string().uuid(),
  archived: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiProgram = z.infer<typeof apiProgram>
