import { ExtraField } from "@/store/types"

/**
 * Fields
 */
const FieldsRoot = React.forwardRef<
  HTMLUListElement,
  React.ComponentPropsWithoutRef<"ul"> & { divider?: boolean }
>(({ className, divider = false, ...props }, ref) => (
  <ul
    className={cx(
      "@container/fields group/fields flex flex-col text-sm",
      divider && "divide-y",
      className
    )}
    ref={ref}
    {...props}
  />
))

/**
 * Field
 */
type FieldsItemProps = Extend<
  React.ComponentPropsWithoutRef<"li">,
  {
    name: React.ReactNode
    icon?: React.ReactNode
    value?: React.ReactNode
    stretch?: boolean
  }
>
const FieldsItem = React.forwardRef<HTMLLIElement, FieldsItemProps>(
  ({ name, icon, value, stretch = false, className, children, ...props }, ref) => (
    <li
      className={cx(
        "flex flex-col @xs/fields:grid items-start grid-cols-3 gap-x-2",
        stretch ? "py-2" : "py-4",
        className
      )}
      ref={ref}
      {...props}
    >
      <span className="flex items-center font-medium [&>svg]:size-4 [&>svg]:mr-2">
        {icon}
        {name}
      </span>
      <span className="flex flex-col col-span-2">{value ?? children}</span>
    </li>
  )
)

/**
 * FieldsItemExtra
 */
const FieldsItemExtra: React.FC<{
  fields: ExtraField[]
  icon?: React.ReactNode
  wrapper?: (value: string) => React.ReactNode
}> = ({ fields, icon = null, wrapper = value => value }) =>
  A.isNotEmpty(fields) ? (
    <>
      {A.mapWithIndex(fields, (index, { name, value }) => (
        <FieldsItem name={name} value={wrapper(value)} icon={icon} key={index} />
      ))}
    </>
  ) : null

/**
 * exports
 */
export const Fields = Object.assign(FieldsRoot, {
  Item: FieldsItem,
  ItemExtra: FieldsItemExtra,
})
