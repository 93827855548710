import { F, G } from "@mobily/ts-belt"

/**
 * useRefState
 */

export const useRefState = <S>(
  initialState: S | (() => S),
  constraint: (value: S) => S = F.identity
) => {
  const [reactState, setReactState] = React.useState(initialState)
  const initialStateRef = React.useRef(reactState)
  const stateRef = React.useRef(reactState)

  const setState: React.Dispatch<React.SetStateAction<S>> = action => {
    stateRef.current = constraint(G.isFunction(action) ? action(stateRef.current) : action)
    setReactState(stateRef.current)
  }

  const get = () => stateRef.current

  return {
    set: setState,
    reset: () => setState(initialStateRef.current),
    init: () => initialStateRef.current,
    get value(): Readonly<S> {
      return get()
    },
  }
}
