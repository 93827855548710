import { createContextMapper } from "@/dictionaries/helpers"
import { useFilterable } from "@/hooks/useFilterable"
import { useLimitable } from "@/hooks/useLimitable"
import { useMatchable } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"
import {
  projectCategories,
  projectLanguages,
  projectOrientations,
} from "@/services/projects/schemas"
import { useProjectsStore } from "."
import { Project } from "./localizers"

/**
 * dictionary src/dictionaries/en/pages/dashboard/projects.json
 */
const dictionary = createContextMapper("pages", "dashboard", "projects")

/**
 * hooks
 */
export const useProjectsById = () => {
  return useProjectsStore(D.getUnsafe("projects"))
}
export const useProjects = () => {
  return useProjectsStore(flow(D.getUnsafe("projects"), D.values))
}
export const useProject = (id: Option<string>) => {
  return useProjectsStore(flow(D.getUnsafe("projects"), D.get(id ?? "")))
}

/**
 * collection
 */
export const useFilteredProjects = (initialLimit = 24, initialByMore = 12) => {
  const projects = useProjects()
  const { _ } = useDictionary(dictionary())

  const [filterable, filterBy] = useFilterable<Project>(
    "project",
    {
      accepted: ({ status }) => status === "accepted",
      pending: ({ status }) => status === "pending",
      refused: ({ status }) => status === "refused",
    },
    { pending: true }
  )

  const [matchable, matchIn] = useMatchable<Project>([
    "name",
    "description",
    "class",
    ({ category }) => _(`category-${category}`),
    ({ language }) => _(`language-${language as "en"}`),
    ({ status }) => _(`status-${status}`),
  ])

  const [sortable, sortBy] = useSortable<Project>(
    "programs",
    {
      name: project => project.name,
      category: ({ category }) => _(`category-${category}`),
      orientation: ({ orientation }) => _(`orientation-${orientation}`),
      language: project => _(`language-${project.language as "en"}`),
    },
    "name"
  )

  const filtered = React.useMemo(
    () => pipe(projects, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [projects, filterBy, matchIn, matchable.search, sortBy]
  )

  const [limitable, limit] = useLimitable<Project>(filtered.length, initialLimit, initialByMore)

  return {
    projects,
    filtered,
    filterable,
    matchable,
    sortable,
    limitable,
    limit,
  }
}

/**
 * options
 */
export const useProjectLanguageOptions = () => {
  const { _ } = useDictionary(dictionary())
  return React.useMemo(
    () =>
      projectLanguages.map(item => ({
        value: item,
        label: _(`language-${item}`),
      })),
    [_]
  )
}
export const useProjectOrientationOptions = (placeholder: Option<string> = null) => {
  const { _ } = useDictionary(dictionary())
  return React.useMemo(() => {
    const options = projectOrientations.map(item => ({
      value: item,
      label: _(`orientation-${item}`),
    }))
    if (placeholder) return [{ label: placeholder, value: "null" }, ...options]
    return options
  }, [placeholder, _])
}
export const useProjectCategoryOptions = (placeholder: Option<string> = null) => {
  const { _ } = useDictionary(dictionary())
  return React.useMemo(() => {
    const options = projectCategories.map(item => ({
      value: item,
      label: _(`category-${item}`),
    }))
    if (placeholder) return [{ label: placeholder, value: "null" }, ...options]
    return options
  }, [placeholder, _])
}
