import { Card, Fields, Menu, Row } from "@/components/collection"
import { useSelectItem } from "@/hooks/useSelect"

import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { getUserThumbnail } from "@/store/auth/helpers"
import { useMe } from "@/store/auth/hooks"
import { User } from "@/store/users/localizers"
import {
  AlertTriangle,
  CalendarPlus,
  History,
  Settings,
  UserCheck,
  UserCog,
  UserIcon,
  UserX,
} from "lucide-react"
import { Context, ContextMenu } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/users.json
 */
const dictionary = createContextMapper("pages", "dashboard", "users")

/**
 * Item
 */
export const Item: React.FC<{ user: User }> = ({ user }) => {
  const { view } = React.useContext(Context)
  const { selectItemProps } = useSelectItem(Context, user.id, () =>
    navigate(`/dashboard/users/${user.id}`)
  )
  return (
    <Menu type="context-menu" menu={<ContextMenu user={user} />} asChild>
      <div className="grid" {...selectItemProps}>
        {view === "grid" ? <ItemCard {...{ user }} /> : <ItemRow {...{ user }} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCard
 */
const ItemCard: React.FC<{ user: User }> = ({ user }) => {
  const { _ } = useDictionary(dictionary())
  const format = useDateFnsLocaleFormat()
  const { isSelected } = useSelectItem(Context, user.id)
  const me = useMe()
  const isMe = me.id === user.id
  const { role, status, createdAt } = user
  return (
    <Card
      className={cx("overflow-hidden", status === "deleted" && "opacity-75")}
      selected={isSelected}
    >
      <Card.Image src={getUserThumbnail(user)} className="aspect-square">
        <UserIcon size={64} strokeWidth={1} />
      </Card.Image>
      <Card.Header>
        <Card.Title>
          {getFullname(user, _("fullname-placeholder"))}
          {isMe && <span className="text-sm pl-1 text-foreground/75">({_("is-me")})</span>}
          <ItemRole role={role} />
        </Card.Title>
        <Card.Description>
          {S.trim(`${user.profile.position} - ${user.profile.company}`)}
        </Card.Description>
        <Card.Menu menu={<ContextMenu user={user} />} />
      </Card.Header>
      <Card.Content>
        <Fields divider>
          <Fields.Item
            name={_("role")}
            icon={<Settings aria-hidden />}
            value={_(`role-${role}`)}
            stretch
          />
          <Fields.Item
            name={_("status")}
            icon={<UserCog aria-hidden />}
            value={_(`status-${status}`)}
            stretch
          />
          <Fields.Item
            name={_("created-at")}
            icon={<CalendarPlus aria-hidden />}
            value={format(createdAt, "PPPPp")}
            stretch
          />
        </Fields>
      </Card.Content>
    </Card>
  )
}

/**
 * ItemRow
 */
const ItemRow: React.FC<{ user: User }> = ({ user }) => {
  const { isSelected } = useSelectItem(Context, user.id)
  const { _ } = useDictionary(dictionary())
  const me = useMe()
  const isMe = me.id === user.id
  const format = useDateFnsLocaleFormat()
  const fullname = getFullname(user, _("fullname-placeholder"))
  const { status, role, createdAt } = user
  const image = user.profile.thumbnail ?? user.profile.preview ?? user.profile.image
  return (
    <Row selected={isSelected} className={cx(status === "deleted" && "opacity-75")}>
      <Row.Image src={image}>
        <UserIcon size={16} />
      </Row.Image>
      <Row.Header>
        <Row.Title>
          {fullname}
          {isMe && <span className="text-sm pl-1 text-foreground/75">({_("is-me")})</span>}
          <ItemRole role={role} />
        </Row.Title>
        <Row.Description>
          {_("created-at")} {format(createdAt, "PPPPp")}
        </Row.Description>
      </Row.Header>
      <Row.Content>
        <Row.Icon tooltip={_(`status-${status}`)}>
          <ItemStatus status={status} />
        </Row.Icon>
      </Row.Content>
      <Row.Menu menu={<ContextMenu user={user} />} />
    </Row>
  )
}

/**
 * components
 */
const ItemStatus: React.FC<{ status: User["status"] }> = ({ status }) => (
  <>
    {status === "pending" && <History aria-hidden />}
    {status === "active" && <UserCheck aria-hidden />}
    {status === "deleted" && <UserX aria-hidden />}
    {status === "suspended" && <AlertTriangle aria-hidden />}
  </>
)
const ItemRole: React.FC<{ role: User["role"] }> = ({ role }) =>
  role !== "member" ? (
    <span className="inline-flex items-center ml-2 text-[10px] text-muted-foreground/50">
      {role === "superadmin" && "DEV"}
      {role === "admin" && "ADMIN"}
    </span>
  ) : undefined

/**
 * helpers
 */
export const getFullname = (user: Option<User>, placeholder: string) =>
  G.isNotNullable(user)
    ? S.isEmpty(S.trim(`${user.profile.firstname} ${user.profile.lastname}`))
      ? placeholder
      : `${user.profile.firstname} ${user.profile.lastname}`
    : placeholder
