/// <reference types="vite-plugin-svgr/client" />
import NewsPicture from "@/assets/images/news.svg?react"
import { Pagination } from "@/components/collection"
import { FooterPicture } from "@/components/frontend/drawing"
import { Container } from "@/components/layout/frontend/container"
import { PageRender } from "@/components/layout/frontend/page"
import { Wrapper } from "@/components/layout/frontend/wrapper"
import { usePaginable } from "@/hooks/usePaginable"
import { useData } from "@/hooks/usePromise"
import { useResponsive } from "@/hooks/useResponsive"
import { service } from "@/services/frontend/service"
import { Article, localizeArticle } from "@/store/frontend/localizers"
import { match } from "ts-pattern"
import { FilterByCategory } from "./FilterByCategory"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/offers.json
 */
export const dictionary = createContextMapper("pages", "offers")

/**
 * Page: Offers
 */
const Offers: React.FC = () => {
  const media = useResponsive()

  const [articles] = useData(initialArticles, loadOffers)
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(null)
  const filtered = React.useMemo(
    () =>
      G.isNotNullable(selectedCategory)
        ? A.filter(articles, article => article.category?.id === selectedCategory)
        : articles,
    [articles, selectedCategory]
  )

  const [paginable, paginated] = usePaginable(
    filtered,
    1,
    media.min("xl") ? 12 : media.min("lg") ? 8 : 6
  )

  return (
    <>
      <PageRender pageKey="offers" />
      <Wrapper margin="normal">
        <Container x="sm" className="flex flex-col gap-5 sm:gap-[30px]">
          <FilterByCategory
            articles={articles}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 sm:gap-[30px]">
            {A.map(paginated, article => (
              <Item key={article.id} article={article} />
            ))}
          </div>
          <Pagination {...paginable} />
        </Container>
      </Wrapper>
      <FooterPicture image={<NewsPicture />} />
    </>
  )
}
export default Offers

/**
 * loadOffers
 */
const loadOffers = async () =>
  match(await service.articles.index(["offers"]))
    .with({ error: false }, ({ data }) => A.map(data.articles, localizeArticle))
    .otherwise(() => initialArticles)
const initialArticles: Article[] = []
