import { Menu, useMenu } from "@/components/collection"
import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { PageCreateDialog } from "@/components/dialogs/page-create"
import { Confirm, confirmSafeDictionary } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { useSelectItem } from "@/hooks/useSelect"
import { useIsSuperadmin } from "@/store/auth/hooks"
import { useTranslation } from "@/store/languages/hooks"
import { deletePage, updatePage } from "@/store/pages/actions"
import { usePagesById } from "@/store/pages/hooks"
import { Page } from "@/store/pages/localizers"
import { SelectionPlus, SelectionSlash } from "@phosphor-icons/react"
import { Eye, EyeOff, Lock, LockOpen, PlusSquare, Square, Trash } from "lucide-react"

/**
 * dictionary src/dictionaries/en/pages/dashboard/pages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "pages")

/**
 * Context
 * dictionary src/dictionaries/en/pages/dashboard/pages.json
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const pagesById = usePagesById()
  const displayName = (id: string) => {
    const seo = D.get(pagesById, id)?.seo
    const title = seo ? t(seo).title : ""
    return S.isEmpty(S.trim(title)) ? _("no-title") : title
  }

  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    "grid",
    "state-pages-view",
    "interface",
    sessionStorage
  )

  // confirms
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deletePage,
    dictionary: confirmSafeDictionary(dictionary("delete-confirm")),
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName,
    onAsyncConfirm: deletePage,
    dictionary: confirmSafeDictionary(dictionary("delete-selection-confirm")),
  })
  const { confirm: confirmPublishSelection, ...publishSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName,
    onAsyncConfirm: id => updatePage(id, { state: "published" }),
    dictionary: confirmSafeDictionary(dictionary("publish-selection-confirm")),
  })
  const { confirm: confirmUnpublishSelection, ...unpublishSelectionProps } = useConfirm<
    void,
    string
  >({
    list: selected,
    displayName,
    onAsyncConfirm: id => updatePage(id, { state: "draft" }),
    dictionary: confirmSafeDictionary(dictionary("unpublish-selection-confirm")),
  })

  // dialogs
  const { setItem: create, ...createProps } = useDialog<void>()

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        view,
        setView,
        create,
        confirmDelete,
        confirmDeleteSelection,
        confirmPublishSelection,
        confirmUnpublishSelection,
      }}
    >
      {children}
      <PageCreateDialog {...createProps} />
      <Confirm {...deleteProps} />
      <Confirm {...deleteSelectionProps} />
      <Confirm {...publishSelectionProps} />
      <Confirm {...unpublishSelectionProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
type ContextMenuProps = { page: Page }
export const ContextMenu: React.FC<ContextMenuProps> = ({ page }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const {
    confirmPublishSelection,
    confirmUnpublishSelection,
    confirmDelete,
    confirmDeleteSelection,
    create,
  } = usePageContext()
  const { type } = useMenu()
  const { isSelected, toggleSelection } = useSelectItem(Context, page.id)
  const { id } = page
  const isSuperadmin = useIsSuperadmin()
  const isContextMenu = type === "context-menu"
  const isPublished = page.state === "published"

  const canManageState = isSuperadmin ? true : !page.lock
  const toggleState = () => updatePage(page.id, { state: isPublished ? "draft" : "published" })

  const canDelete = isSuperadmin ? true : !page.lock
  const toggleLock = () => updatePage(page.id, { lock: !page.lock })

  return (
    <>
      <Menu.Item onClick={() => navigate(`/dashboard/pages/${page.id}`)}>
        <Square aria-hidden />
        {_("display")}
      </Menu.Item>
      <Menu.Item onClick={toggleSelection}>
        {isSelected ? <SelectionSlash aria-hidden /> : <SelectionPlus aria-hidden />}
        {_(isSelected ? "unselect" : "select")}
      </Menu.Item>
      {canManageState && (
        <Menu.Item onClick={toggleState}>
          {isPublished ? <EyeOff aria-hidden /> : <Eye aria-hidden />}
          {_(isPublished ? "unpublish" : "publish")}
        </Menu.Item>
      )}
      {isSuperadmin && (
        <Menu.Item onClick={toggleLock}>
          {page.lock ? <LockOpen aria-hidden /> : <Lock aria-hidden />}
          {_(page.lock ? "unlock" : "lock")}
        </Menu.Item>
      )}
      {canDelete && (
        <Menu.Item onClick={() => confirmDelete(id)}>
          <Trash aria-hidden />
          {_("delete")}
        </Menu.Item>
      )}
      {isContextMenu && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={create}>
            <PlusSquare aria-hidden />
            {_("create")}
          </Menu.Item>
          {isSelected && (
            <>
              <Menu.Separator />
              {canManageState && (
                <Menu.Item
                  onClick={isPublished ? confirmUnpublishSelection : confirmPublishSelection}
                >
                  {isPublished ? <EyeOff aria-hidden /> : <Eye aria-hidden />}
                  {_(isPublished ? "unpublish-selection" : "publish-selection")}
                </Menu.Item>
              )}
              {canDelete && (
                <Menu.Item onClick={confirmDeleteSelection}>
                  <Trash aria-hidden />
                  {_("delete-selection")}
                </Menu.Item>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  create: () => void
  confirmDelete: (value: string) => void
  confirmDeleteSelection: () => void
  confirmPublishSelection: () => void
  confirmUnpublishSelection: () => void
}
