import { cva } from "class-variance-authority"

export const frontendFocusOutlineClasses = cx(
  "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary-500 focus-visible:ring-offset-2 focus-visible:shadow-[0_0px_4px_0px] focus-visible:shadow-frontend-matisse"
)
export const frontendButtonVariants = cva(
  cx(
    frontendFocusOutlineClasses,
    "focus-visible:border-[1px] focus-visible:border-frontend-orient",
    "inline-flex justify-center items-center max-h-16",
    "text-base font-normal",
    "disabled:pointer-events-none disabled:opacity-50 disabled:bg-cloud",
    "transition-colors"
  ),
  {
    variants: {
      variant: {
        default:
          "bg-frontend-aquahaze text-frontend-shark hover:bg-solitude active:bg-frontend-orient focus-visible:bg-frontend-aquahaze",
        dark: "bg-frontend-orient text-white hover:bg-bluewhale focus-visible:bg-bluewhale active:bg-bluewhale disabled:text-black",
        secondary:
          "bg-white text-frontend-shark hover:bg-frontend-aquahaze focus-visible:bg-frontend-aquahaze active:bg-frontend-aquahaze",
        outline:
          "border border-mercury text-frontend-shark hover:border-frontend-orient focus-visible:bg-frontend-aquahaze active:bg-frontend-orient active:border-frontend-orient text-frontend-orient",
        outlineWhite:
          "border border-mercury text-white hover:border-white/30 focus-visible:bg-frontend-aquahaze active:bg-frontend-orient active:border-frontend-orient",
        outlineDark:
          "border border-frontend-orient text-frontend-shark hover:border-mercury focus-visible:bg-frontend-aquahaze active:bg-frontend-orient active:border-frontend-orient active:text-white",
        thirdy:
          "p-none gap-x-2.5 text-frontend-shark hover:text-frontend-matisse active:text-bluewhale font-bold",
        thirdyDark:
          "p-none gap-x-2.5 text-white hover:text-solitude active:text-frontend-matisse font-bold",
        tab: 'bg-white text-frontend-shark hover:bg-frontend-aquahaze focus-visible:bg-frontend-aquahaze active:bg-frontend-aquahaze data-[state="active"]:bg-frontend-aquahaze',
      },
      size: {
        default: "h-[50px] px-5 gap-x-4",
        sm: "min-h-[40px] px-4 py-2 gap-x-2 leading-tight",
      },
      case: {
        uppercase: "uppercase text-sm font-semibold tracking-wider",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)
