import { z } from "zod"
import { AdminErrorCode, NoContent, api } from "../api"
import {
  ApiWorkshop,
  ApiWorkshopEvent,
  ApiWorkshopReservation,
  apiWorkshop,
  apiWorkshopDuration,
  apiWorkshopEvent,
  apiWorkshopReservation,
} from "./schemas"

/**
 * schemas payload
 */
const createPayload = z.object({
  name: z.string(), // maxLength 255
  published: z.boolean().optional(),
  archived: z.boolean().optional(),
  description: z.string().optional(), // maxLength 1000
  languages: z.string().array().optional(), // maxLength 255
  duration: apiWorkshopDuration.optional(), // Duration
  maxAttendees: z.number().optional(), // unsigned maxLength 10000
  placeId: z.string().uuid(),
})
const updatePayload = z.object({
  name: z.string().optional(), // maxLength 255
  published: z.boolean().optional(),
  archived: z.boolean().optional(),
  description: z.string().optional(),
  languages: z.array(z.string()).optional(), // maxLength 255
  duration: apiWorkshopDuration.optional(), // Duration
  maxAttendees: z.number().optional(), // unsigned maxLength 10000
  placeId: z.string().uuid().optional(),
})
const createEventPayload = z.object({
  workshopId: z.string(),
  reservationsSlot: z.number(),
  datetime: z.string(),
})
const updateEventPayload = z.object({
  reservationsSlot: z.number().optional(),
  datetime: z.string().optional(),
})

export type Payload = {
  create: z.infer<typeof createPayload>
  update: z.infer<typeof updatePayload>
  events: {
    create: z.infer<typeof createEventPayload>
    update: z.infer<typeof updateEventPayload>
  }
}

/**
 * service
 */
export const service = {
  index: async () => {
    type RSuccess = { workshops: ApiWorkshop[] }
    type RError = AdminErrorCode
    const { success, data } = await api.get<RSuccess, RError>("workshops")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { workshops: A.map(data.workshops, apiWorkshop.parse) }, error: false } as const
  },
  create: async (payload: Payload["create"]) => {
    type RSuccess = { workshop: ApiWorkshop }
    type RError = AdminErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.post<RSuccess, RError>("workshops", {
      data: createPayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { workshop: apiWorkshop.parse(data.workshop) }, error: false } as const
  },
  read: async (id: string) => {
    type RSuccess = { workshop: ApiWorkshop }
    type RError = AdminErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`workshops/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { workshop: apiWorkshop.parse(data.workshop) }, error: false } as const
  },
  update: async (id: string, payload: Payload["update"]) => {
    type RSuccess = { workshop: ApiWorkshop }
    type RError = AdminErrorCode<"RESOURCE_NOT_FOUND" | "VALIDATION_FAILURE">
    const { success, data } = await api.put<RSuccess, RError>(`workshops/${id}`, {
      data: updatePayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { workshop: apiWorkshop.parse(data.workshop) }, error: false } as const
  },
  delete: async (id: string) => {
    type RSuccess = NoContent
    type RError = AdminErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.delete<RSuccess, RError>(`workshops/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: {}, error: false } as const
  },
  events: {
    create: async (payload: Payload["events"]["create"]) => {
      type RSuccess = { event: ApiWorkshopEvent }
      type RError = AdminErrorCode<"VALIDATION_FAILURE">
      const { success, data } = await api.post<RSuccess, RError>(`workshops/events`, {
        data: createEventPayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { event: apiWorkshopEvent.parse(data.event) }, error: false } as const
    },
    update: async (id: string, payload: Payload["events"]["update"]) => {
      type RSuccess = { event: ApiWorkshopEvent }
      type RError = AdminErrorCode<"RESOURCE_NOT_FOUND" | "VALIDATION_FAILURE">
      const { success, data } = await api.put<RSuccess, RError>(`workshops/events/${id}`, {
        data: updateEventPayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { event: apiWorkshopEvent.parse(data.event) }, error: false } as const
    },
    delete: async (id: string) => {
      type RSuccess = NoContent
      type RError = AdminErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.delete<RSuccess, RError>(`workshops/events/${id}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: {}, error: false } as const
    },
  },
  reservations: {
    archive: async (id: string) => {
      type RSuccess = { reservation: ApiWorkshopReservation }
      type RError = AdminErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.put<RSuccess, RError>(`workshops/reservations/${id}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return {
        data: { reservation: apiWorkshopReservation.parse(data.reservation) },
        error: false,
      } as const
    },
    delete: async (id: string) => {
      type RSuccess = NoContent
      type RError = AdminErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.delete<RSuccess, RError>(`workshops/reservations/${id}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: {}, error: false } as const
    },
  },
}
