import { Form, FormAssertive, FormFiles, FormImages } from "@/components/form"
import { Button } from "@/components/frontend/button"
import { useDictionary } from "@/dictionaries/hooks"
import { dictionary } from "."
import { useAnnexeForm } from "./useAnnexeForm"
import { StepsProps } from "./useStepper"

/**
 * FormInformation
 */
export const FormAnnexe: React.FC<
  { form: ReturnType<typeof useAnnexeForm>; submit: () => void } & StepsProps
> = ({ form, setStep, submit }) => {
  const { _ } = useDictionary(dictionary("form"))
  return (
    <Form form={form} className="flex flex-col gap-6" onSubmit={() => form.isValid && submit()}>
      <FormAssertive />
      <FormFiles
        label={_("documents-label")}
        name="documents"
        labelPopover={_("documents-popover")}
      />
      <FormImages label={_("images-label")} name="images" labelPopover={_("images-popover")} />
      <div className="flex justify-between">
        <Button onClick={() => setStep("information")} variant="outline">
          {_("back")}
        </Button>
        <Form.Submit asChild>
          <Button variant="dark">{_("submit")}</Button>
        </Form.Submit>
      </div>
    </Form>
  )
}
