import { VariantProps, cva } from "class-variance-authority"

/**
 * Container
 */
export const containerVariants = cva("mx-auto w-full", {
  variants: {
    size: {
      lg: "max-w-[1440px]",
    },
    x: {
      lg: "px-5 sm:px-[30px] md:px-[115px]",
      md: "px-5 sm:px-[30px] md:px-[60px]",
      sm: "px-5 sm:px-[30px] md:px-[55px]",
      head: "xl:px-[55px]",
    },
    l: {
      lg: "pl-5 sm:pl-[30px] lg:pl-[115px]",
      md: "pl-5 sm:pl-[30px] lg:pl-[60px]",
      sm: "pl-5 sm:pl-[30px] lg:pl-[55px]",
    },
    r: {
      lg: "pr-5 sm:pr-[30px] lg:pr-[115px]",
      md: "pr-5 sm:pr-[30px] lg:pr-[60px]",
      sm: "pr-5 sm:pr-[30px] lg:pr-[55px]",
    },
  },
  defaultVariants: {
    size: "lg",
  },
})

/**
 * Container
 */
type ContainerProps = Extend<
  React.HTMLAttributes<HTMLDivElement>,
  VariantProps<typeof containerVariants>
>
export const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ x, l, r, size, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cx(containerVariants({ x, l, r, size }), props.className)}
      />
    )
  }
)
