import "@/components/medias/components//lightbox.css"
import "@/components/medias/components/lightbox-thumbnails.css"
import { isImageExtension, isVideoExtension, typeFromExtension } from "@/fns/File"
import { ApiMultipleFile } from "@/services/commons/schemas"
import {
  ChevronLeft,
  ChevronRight,
  DownloadIcon,
  FullscreenIcon,
  Minimize,
  Play,
  Square,
  X,
  ZoomIn,
  ZoomOut,
} from "lucide-react"
import LightboxComponent, {
  LightboxExternalProps,
  PdfSlide,
  SlideImage,
  SlideVideo,
} from "yet-another-react-lightbox"
import Download from "yet-another-react-lightbox/plugins/download"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Slideshow from "yet-another-react-lightbox/plugins/slideshow"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import Video from "yet-another-react-lightbox/plugins/video"
import Zoom from "yet-another-react-lightbox/plugins/zoom"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * useLightbox
 */
export const useLightbox = () => {
  const [open, setOpen] = React.useState(false)
  const [interactive, setInteractive] = React.useState(false)
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const displaySlide = (index: number) => {
    setCurrentIndex(index)
    setOpen(true)
    setInteractive(true)
  }
  const openLightbox = React.useCallback(() => {
    setOpen(true)
    setInteractive(true)
  }, [])

  const renderLightbox = React.useCallback(
    ({
      slides,
      ...props
    }: Extend<Omit<LightboxExternalProps, "open" | "close">, { slides: LightboxSlide[] }>) =>
      interactive ? (
        <Lightbox
          open={open}
          index={currentIndex}
          slides={slides}
          close={() => setOpen(false)}
          {...props}
        />
      ) : null,
    [open, interactive, currentIndex]
  )

  return { openLightbox, displaySlide, renderLightbox }
}

/**
 * Lightbox
 */
export const Lightbox: React.FC<LightboxExternalProps> = ({
  styles = {},
  plugins = [],
  carousel = {},
  render = {},
  ...props
}) => {
  const { _ } = useDictionary(dictionary("lightbox"))
  return (
    <LightboxComponent
      {...props}
      render={{
        iconZoomIn: () => <ZoomIn size={16} aria-label={_("zoom-in")} />,
        iconZoomOut: () => <ZoomOut size={16} aria-label={_("zoom-in")} />,
        iconClose: () => <X size={16} aria-label={_("close")} />,
        iconSlideshowPlay: () => <Play size={16} aria-label={_("play")} />,
        iconSlideshowPause: () => <Square size={16} aria-label={_("stop")} />,
        iconDownload: () => <DownloadIcon size={16} aria-label={_("download")} />,
        iconPrev: () => <ChevronLeft size={32} strokeWidth={1} aria-label={_("previous")} />,
        iconNext: () => <ChevronRight size={32} strokeWidth={1} aria-label={_("next")} />,
        iconEnterFullscreen: () => <FullscreenIcon size={16} aria-label={_("enter-fullscreen")} />,
        iconExitFullscreen: () => <Minimize size={16} aria-label={_("exit-fullscreen")} />,
        ...render,
      }}
      plugins={[Zoom, Fullscreen, Slideshow, Video, Thumbnails, Download, ...plugins]}
      styles={{
        root: {
          // "--yarl__color_backdrop": "hsl(var(--foreground) / 0.6)",
          // "--yarl__color_button": "hsl(var(--background))",
          "--yarl__thumbnails_thumbnail_border": "hsl(var(--input)) 1px solid",
        },
        ...styles,
      }}
      controller={{
        closeOnBackdropClick: true,
      }}
      carousel={{
        padding: "64px",
        ...carousel,
      }}
    />
  )
}

/**
 * useFilesToSlides
 */
export const useLightboxFiles = (files: ApiMultipleFile[]) => {
  const { openLightbox, displaySlide, renderLightbox } = useLightbox()

  /**
   * slides
   */
  const slides: LightboxSlide[] = React.useMemo(
    () =>
      A.filterMap(files, ({ id, extension, url: src, ...file }) => {
        const slideProps = {
          id,
          downloadUrl: src,
          downloadFilename: file.name,
        }
        if (isImageExtension(extension))
          return O.Some({ ...slideProps, type: "image", src, alt: file.name })
        if (isVideoExtension(extension))
          return O.Some({
            ...slideProps,
            type: "video",
            sources: [{ src, type: typeFromExtension(extension) }],
          })
        if (extension === "pdf") return O.Some({ ...slideProps, type: "pdf", src })
        return O.None
      }),
    [files]
  )

  /**
   * lightbox
   */
  const lightbox = React.useCallback(
    (id: string) => {
      displaySlide(A.getIndexBy(slides, slide => slide.id === id) ?? 0)
    },
    [displaySlide, slides]
  )

  return { openLightbox, displaySlide, renderLightbox: () => renderLightbox({ slides }), lightbox }
}

/**
 * helpers
 */
export const isLightboxExtension = (extension: string) =>
  isVideoExtension(extension) || isImageExtension(extension) || extension === "pdf"

/**
 * types
 */
export type LightboxSlideImage = Extend<SlideImage, { id: string }>
export type LightboxSlideVideo = Extend<SlideVideo, { id: string }>
export type LightboxSlideVideoPdf = Extend<PdfSlide, { id: string }>
export type LightboxSlide = LightboxSlideVideo | LightboxSlideImage | LightboxSlideVideoPdf
