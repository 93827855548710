import { Menu, useMenu } from "@/components/collection"
import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { UserAccountDialog } from "@/components/dialogs/user-account"
import { UserCreateDialog } from "@/components/dialogs/user-create"
import { UserProfileDialog } from "@/components/dialogs/user-profile"
import { Confirm } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { useSelectItem } from "@/hooks/useSelect"
import { useIsSuperadmin } from "@/store/auth/hooks"
import { useUsersStore } from "@/store/users"
import { deleteUser } from "@/store/users/actions"
import { useUsersById } from "@/store/users/hooks"
import { User } from "@/store/users/localizers"
import { SelectionPlus, SelectionSlash } from "@phosphor-icons/react"
import { PlusSquare, Trash, UserCog, UserIcon, UserSquare } from "lucide-react"
import { getFullname } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/users.json
 */
const dictionary = createContextMapper("pages", "dashboard", "users")

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { _ } = useDictionary(dictionary())
  const usersById = useUsersById()
  const unuseAccounts = useUsersStore(
    flow(
      D.getUnsafe("users"),
      D.values,
      A.filterMap(({ id, lastLoginAt, createdAt }) =>
        (
          lastLoginAt === null
            ? T.isBefore(createdAt, T.add(new Date(), { months: 6 }))
            : T.isBefore(lastLoginAt, T.add(new Date(), { months: 6 }))
        )
          ? O.None
          : id
      )
    )
  )

  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    "grid",
    "state-users-view",
    "interface",
    sessionStorage
  )

  // confirms
  const displayName = (id: string) => getFullname(D.get(usersById, id), _("fullname-placeholder"))
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deleteUser,
    dictionary: "pages.dashboard.users.delete-confirm",
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName,
    onAsyncConfirm: deleteUser,
    dictionary: Confirm.dictionary(dictionary("delete-selection-confirm")),
  })
  const { confirm: confirmDeleteUnuseAccounts, ...deleteUnuseAccountsProps } = useConfirm<
    void,
    string
  >({
    list: unuseAccounts,
    displayName,
    onAsyncConfirm: deleteUser,
    dictionary: Confirm.dictionary(dictionary("delete-unused-accounts-confirm")),
  })

  // dialogs
  const { setItem: updateAccount, ...accountProps } = useDialog<User>()
  const { setItem: updateProfile, ...profileProps } = useDialog<User>()
  const { setItem: create, ...createProps } = useDialog<void>()

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        view,
        setView,
        confirmDelete,
        updateAccount,
        updateProfile,
        create,
        confirmDeleteSelection,
        unuseAccounts,
        confirmDeleteUnuseAccounts,
      }}
    >
      {children}
      <Confirm {...deleteProps} />
      <Confirm {...deleteSelectionProps} />
      <Confirm {...deleteUnuseAccountsProps} />
      <UserAccountDialog {...accountProps} />
      <UserProfileDialog {...profileProps} />
      <UserCreateDialog {...createProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
type ContextMenuProps = { user: User }
export const ContextMenu: React.FC<ContextMenuProps> = ({ user }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { confirmDelete, confirmDeleteSelection, updateAccount, updateProfile, create } =
    usePageContext()
  const { type } = useMenu()
  const { isSelected, toggleSelection } = useSelectItem(Context, user.id)

  const { id } = user
  const isSuperadmin = useIsSuperadmin()
  const canManage = isSuperadmin || (!isSuperadmin && user.role !== "superadmin")

  return (
    <>
      <Menu.Item onClick={toggleSelection}>
        {isSelected ? <SelectionSlash aria-hidden /> : <SelectionPlus aria-hidden />}
        {_(isSelected ? "unselect" : "select")}
      </Menu.Item>
      <Menu.Item onClick={() => navigate(`/dashboard/users/${id}`)}>
        <UserIcon aria-hidden />
        {_("display")}
      </Menu.Item>
      {canManage && (
        <Menu.Item onClick={() => updateAccount(user)}>
          <UserCog aria-hidden />
          {_("edit-account")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => updateProfile(user)}>
        <UserSquare aria-hidden />
        {_("edit-profile")}
      </Menu.Item>
      {canManage && (
        <Menu.Item onClick={() => confirmDelete(id)}>
          <Trash aria-hidden />
          {_("delete")}
        </Menu.Item>
      )}
      {type === "context-menu" && isSelected && (
        <>
          {isSuperadmin && (
            <>
              <Menu.Separator />
              <Menu.Item onClick={create}>
                <PlusSquare aria-hidden />
                {_("create")}
              </Menu.Item>
            </>
          )}
          {canManage && (
            <>
              <Menu.Separator />
              <Menu.Item onClick={confirmDeleteSelection}>
                <Trash aria-hidden />
                {_("delete-selection")}
              </Menu.Item>
            </>
          )}
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  confirmDelete: (value: string) => void
  updateAccount: (value: User) => void
  updateProfile: (value: User) => void
  create: () => void
  confirmDeleteSelection: () => void
  unuseAccounts: string[]
  confirmDeleteUnuseAccounts: () => void
}
