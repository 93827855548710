import { type ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"

/**
 * dictionary src/dictionaries/fr/components/cms.json
 * dictionary src/dictionaries/de/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemThumbnail
 */
export const ItemThumbnail: ItemMappingExport<ItemType>["ItemThumbnail"] = () => {
  const { _ } = useDictionary(dictionary())
  return (
    <>
      <p className="text-xs font-bold">{_("title")}</p>
    </>
  )
}
