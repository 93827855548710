import { Prose } from "@/components/frontend/prose"
import { HN } from "@/components/ui/hn"
import { Image } from "@/components/ui/image"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { useTranslation } from "@/store/languages/hooks"
import { getPreview } from "@/store/medias/helpers"
import { useMediasFile } from "@/store/medias/hooks"
import { MediasFile } from "@/store/medias/localizers"
import { ImageOff } from "lucide-react"
import { match } from "ts-pattern"
import { container } from "../../frontend/container"
import { ItemMappingExport } from "../schemas"
import { ItemType } from "./schemas"

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel, template } = item.props
  const { title, secondary } = t(item).props
  const image = useMediasFile(item.props.image)

  const props: TemplateProps = { title, titleLevel, secondary, image }

  if (!oneIsNotEmpty(title, stripeHtml(secondary)) && G.isNullable(image)) return null
  return (
    <div className={cx("flex flex-col py-8 gap-8 font-plus", container({ x: "sm" }))}>
      {match(template)
        .with(0, () => <Template1 {...props} />)
        .with(1, () => <Template2 {...props} />)
        .with(2, () => <Template3 {...props} />)
        .otherwise(() => null)}
    </div>
  )
}

/**
 * template
 */
type TemplateProps = {
  title: string
  titleLevel: number
  secondary: string
  image: Option<MediasFile>
}
const Template1: React.FC<TemplateProps> = ({ title, titleLevel, secondary, image }) => (
  <div className={cx("grid gap-12", G.isNotNullable(image) && "grid-cols-2")}>
    <div className="flex flex-col justify-center gap-6 ">
      <HN
        level={titleLevel}
        className="text-[40px] font-bold leading-tight text-[#0A5479] dark:text-[#2c9bd3]"
      >
        {title}
      </HN>
      {oneIsNotEmpty(stripeHtml(secondary)) && (
        <Prose dangerouslySetInnerHTML={{ __html: secondary }} />
      )}
    </div>
    {G.isNotNullable(image) && (
      <Image
        src={getPreview(image)}
        wrapperCx="flex justify-center items-center"
        className="max-w-full max-h-full rounded-md w-full"
      >
        <div className="flex justify-center items-center w-full aspect-[3/2] bg-muted text-muted-foreground">
          <ImageOff size={64} strokeWidth={1} />
        </div>
      </Image>
    )}
  </div>
)
const Template2: React.FC<TemplateProps> = ({ title, titleLevel, secondary, image }) => (
  <div className={cx("grid gap-12", G.isNotNullable(image) && "grid-cols-2")}>
    {G.isNotNullable(image) && (
      <Image
        src={getPreview(image)}
        wrapperCx="flex justify-center items-center"
        className="max-w-full max-h-full rounded-md w-full"
      >
        <div className="flex justify-center items-center w-full aspect-[3/2] bg-muted text-muted-foreground">
          <ImageOff size={64} strokeWidth={1} />
        </div>
      </Image>
    )}
    <div className="flex flex-col justify-center gap-6 ">
      <HN
        level={titleLevel}
        className="text-[40px] font-bold leading-tight text-[#0A5479] dark:text-[#2c9bd3]"
      >
        {title}
      </HN>
      {oneIsNotEmpty(stripeHtml(secondary)) && (
        <Prose dangerouslySetInnerHTML={{ __html: secondary }} />
      )}
    </div>
  </div>
)
const Template3: React.FC<TemplateProps> = ({ title, titleLevel, secondary, image }) => (
  <div className="relative w-full flex justify-between overflow-hidden xl:rounded-[2px] pb-2 min-h-[300px] text-white">
    <Image className="absolute inset-0 size-full object-cover" src={getPreview(image)} />
    <div
      className="absolute inset-0 size-full bg-gradient-to-b from-[#002c41]/20 from-5% to-[#002c41]/80 to-80%"
      aria-hidden
    />
    <div className="relative w-full flex flex-col justify-end gap-x-40">
      <div className="flex flex-row items-end justify-between w-full px-12 py-9 gap-x-12 gap-y-8">
        <HN level={titleLevel} className="max-w-xl text-3xl font-bold">
          {title}
        </HN>
        {oneIsNotEmpty(stripeHtml(secondary)) && (
          <div className="p-5 border border-white text-sm font-medium uppercase">
            {stripeHtml(secondary)}
          </div>
        )}
      </div>
    </div>
  </div>
)
