import { useCmsContext } from "@/components/cms/Context"
import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSection,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
  FormYoutube,
} from "@/components/form"
import { FormMediasVideo } from "@/components/medias/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { Tabs } from "@/components/ui/tabs"
import { mergeNonNullables } from "@/fns/Array"
import { oneIsNotEmpty } from "@/fns/String"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { useForm } from "use-a11y-form"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")
type Payload = FormPayload<ItemType>

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary("content", "items", itemType))
  const { _: __ } = useDictionary(dictionary("content", "form"))
  const _f = useFormDictionary()
  const _e = useErrorsDictionary()

  const {
    id,
    actions: { updateContentItem },
  } = useCmsContext()
  const languages = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(item.props, ["template", "video"]),
      titleLevel: `${item.props.titleLevel}`,
      youtube: item.props.youtube ?? "",
      translations: D.map(languages, language => ({
        languageId: language.id,
        title: translate(item, language)?.props.title ?? "",
        secondary: translate(item, language)?.props.secondary ?? "",
      })),
    })),
    onSubmit: async ({ values }) => {
      values.translations
      const payload: Payload = {
        props: {
          ...D.selectKeys(values, ["template", "video"]),
          titleLevel: +values.titleLevel,
          youtube: S.isEmpty(S.trim(values.youtube)) ? null : values.youtube,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: mergeNonNullables([values.video]),
      }

      return match(await updateContentItem(item.id, payload))
        .with({ error: false }, () => {
          toast.success(__("success"))
          close()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _e)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_e(code)))
        )
    },
  })

  const titleLevelOptions = useTitleLevelOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />

      <FormTranslationTabs context={false}>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormSection>
              <FormHeader>
                <FormHeader.Title>{_("section-header-title")}</FormHeader.Title>
                <FormHeader.Description>{_("section-header-description")}</FormHeader.Description>
              </FormHeader>
              <FormTranslationContext language={language}>
                <FormInput
                  name="title"
                  label={__("title-label")}
                  placeholder={__("title-placeholder")}
                  translatable
                />
              </FormTranslationContext>
              <FormSelect
                options={titleLevelOptions}
                name="titleLevel"
                label={__("title-level-label")}
              />
              <FormTranslationContext language={language}>
                <FormTiptap
                  name="secondary"
                  label={__("secondary-label")}
                  placeholder={__("secondary-placeholder")}
                  translatable
                />
              </FormTranslationContext>
            </FormSection>
            <Tabs
              defaultValue={oneIsNotEmpty(form.values.youtube) ? "youtube" : "video"}
              className="w-full"
            >
              <Tabs.List className="grid w-full grid-cols-2">
                <Tabs.Trigger value="video">{_("section-video-title")}</Tabs.Trigger>
                <Tabs.Trigger value="youtube">{_("section-youtube-title")}</Tabs.Trigger>
              </Tabs.List>
              <Tabs.Content value="video" className="flex flex-col gap-6 pb-4 px-4 border">
                <FormSection>
                  <FormHeader>
                    <FormHeader.Description>
                      {_("section-video-description")}
                    </FormHeader.Description>
                  </FormHeader>
                  <FormMediasVideo name="video" label={_("video-label")} contextKey={id} />
                </FormSection>
              </Tabs.Content>
              <Tabs.Content value="youtube" className="flex flex-col gap-6 pb-4 px-4 border">
                <FormSection>
                  <FormHeader>
                    <FormHeader.Description>
                      {_("section-youtube-description")}
                    </FormHeader.Description>
                  </FormHeader>
                  <FormYoutube name="youtube" label={_("youtube-label")} />
                </FormSection>
              </Tabs.Content>
            </Tabs>
            {/* <FormSection>
              <FormHeader>
                <FormHeader.Title>{_("section-template-title")}</FormHeader.Title>
                <FormHeader.Description>{_("section-template-description")}</FormHeader.Description>
              </FormHeader>
              <FormSelectTemplate
                name="template"
                options={[Template1]}
                label={_("template-label")}
              />
            </FormSection> */}
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_f("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_f("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * templates
 */
// const Template1: React.FC = () => {
//   const { _ } = useDictionary(dictionary("content", "items", itemType))

//   return (
//     <div className="flex justify-center items-center p-4">
//       <div className="flex justify-center items-center w-full aspect-square rounded-sm bg-muted text-muted-foreground text-sm font-medium"></div>
//     </div>
//   )
// }
