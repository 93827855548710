import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { useMediasStore } from "@/store/medias"
import { updateMediasFile, updateMediasFolder } from "@/store/medias/actions"
import { getChildrenFolderIds } from "@/store/medias/helpers"
import { match } from "ts-pattern"
import { SelectFolder } from "../components/select-folder"
import { useMediaContext } from "../context"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias", "move-media-dialog")

/**
 * MoveDialog
 */
type Props = { type: "file" | "folder" }
export const MoveMediaDialog: React.FC<UseDialogProps<string> & Props> = ({
  item,
  type,
  ...props
}) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog
      {...props}
      title={_(`title-${type}`)}
      description={_(`secondary-${type}`)}
      className="sm:max-w-xl"
    >
      {item !== false && <DialogForm {...props} item={item} type={type} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<string> & Props> = ({
  item: mediaId,
  type,
  onOpenChange,
}) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()

  const { currentFolder } = useMediaContext()

  const parentId =
    useMediasStore(state =>
      type === "folder" ? D.get(state.folders, mediaId) : D.get(state.files, mediaId)
    )?.parentId ?? null

  const [selected, setSelected] = React.useState<string | null>(currentFolder)

  const onSubmit = async (nextParentId?: string) => {
    const folderId = nextParentId ?? selected
    onOpenChange(false)
    const toadId = toast.loading(_("progress"))
    const response = await match(type)
      .with("folder", () => updateMediasFolder(mediaId, { parentId: folderId }))
      .otherwise(() => updateMediasFile(mediaId, { folderId }))
    match(response)
      .with({ error: false }, () => toast.success(_("success"), { id: toadId }))
      .otherwise(() => toast.error(_("error"), { id: toadId }))
  }

  const folders = useMediasStore(state => D.values(state.folders))

  const disabledIds = React.useMemo(
    () => (type === "folder" ? [...getChildrenFolderIds(mediaId, folders), mediaId] : []),
    [type, mediaId, folders]
  )
  const submitDisabled = selected === parentId || disabledIds.includes(selected ?? "")

  return (
    <>
      <SelectFolder current={currentFolder} {...{ selected, setSelected, onSubmit, disabledIds }} />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <Button
          onClick={e => {
            e.stopPropagation()
            onSubmit()
          }}
          disabled={submitDisabled}
        >
          {_("submit")}
        </Button>
      </Dialog.Footer>
    </>
  )
}
