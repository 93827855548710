import { useEventListener } from "@/hooks/useEventListener"
import { useRefState } from "@/hooks/useRefState"
import { service } from "@/services/trackings/service"
import { getSid } from "@/store/auth/actions"
import { match } from "ts-pattern"

export const useClickAndTrack = (trackingId: string) => {
  const [traceId, setTraceId] = React.useState<string | null>(null)
  const onClick = async () => {
    if (G.isNullable(traceId))
      match(await service.trace(trackingId, { sessionId: getSid() })).with(
        { error: false },
        ({ data }) => setTraceId(data.traceId)
      )
  }
  return { onClick, traceId, setTraceId }
}

export const useTracking = (trackingId: Option<string>) => {
  const traceId = useRefState<undefined | string>(undefined)

  const fn = async () =>
    trackingId &&
    match(
      await service.trace(trackingId, {
        sessionId: getSid(),
        traceId: traceId.value,
      })
    ).with({ error: false }, ({ data }) => traceId.set(data.traceId))

  useEventListener("beforeunload", () => {
    fn()
  })

  React.useEffect(() => {
    fn()
    return () => void fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingId])

  return { traceId: traceId.value, setTraceId: traceId.set }
}
