import { z } from "zod"

/**
 * ApiAddress
 */
export const apiAddress = z.object({
  address: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  country: z.string(),
})
export type ApiAddress = z.infer<typeof apiAddress>

/**
 * ApiExtraField
 */
export const apiExtraField = z.object({
  name: z.string(),
  value: z.string(),
})
export type ApiExtraField = z.infer<typeof apiExtraField>

/**
 * ApiSingleFile
 */
export const apiSingleFile = z.object({
  url: z.string(),
  size: z.number(),
  extension: z.string(),
})
export type ApiSingleFile = z.infer<typeof apiSingleFile>

/**
 * ApiSingleImage
 */
export const apiSingleImage = apiSingleFile.extend({
  thumbnailUrl: z.string(),
  previewUrl: z.string(),
  originalUrl: z.string(),
})
export type ApiSingleImage = z.infer<typeof apiSingleImage>

/**
 * ApiMultipleFile
 */
export const apiMultipleFile = apiSingleFile.extend({
  id: z.string().uuid(),
  name: z.string(),
})
export type ApiMultipleFile = z.infer<typeof apiMultipleFile>

/**
 * SynteticFile
 */
export const synteticFile = z.object({
  id: z.string().uuid(),
  delete: z.boolean(),
})
export type SynteticFile = z.infer<typeof synteticFile>

/**
 * metadata
 */
// prettier-ignore
export const metaDataFormat = z.enum([ "avif", "dz", "fits", "gif", "heif", "input", "jpeg", "jpg", "jp2", "jxl", "magick", "openslide", "pdf", "png", "ppm", "raw", "svg", "tiff", "tif", "v", "webp" ])
export type MetaDataFormat = z.infer<typeof metaDataFormat>
export const metaDataColourspace = z.enum(["multiband", "b-w", "bw", "cmyk", "srgb"])
export type MetaDataColourspace = z.infer<typeof metaDataColourspace>
export const metaData = z.object({
  format: metaDataFormat.optional(),
  size: z.number().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  space: metaDataColourspace.optional(),
  channels: z.number().optional(),
  depth: z.string().optional(),
  density: z.number().optional(),
  chromaSubsampling: z.string().optional(),
  isProgressive: z.boolean().optional(),
  hasProfile: z.boolean().optional(),
  hasAlpha: z.boolean().optional(),
})
export type MetaData = z.infer<typeof metaData>
