import { Button, ButtonProps } from "@/components/ui/button"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { BoxSelect, Eye, EyeOff, FolderTree, Trash } from "lucide-react"

/**
 * dictionary src/dictionaries/en/components/layout.json
 */
const dictionary = createContextMapper("components", "layout")

/**
 * Selection
 */
type SelectionProps = {
  selected: string[]
  moveSelection?: () => void
  deleteSelection?: () => void
  publishSelection?: () => void
  unpublishSelection?: () => void
  unselect?: () => void
  children?: React.ReactNode
}
const SelectionRoot: React.FC<SelectionProps> = ({
  selected,
  unselect,
  moveSelection,
  deleteSelection,
  publishSelection,
  unpublishSelection,
  children,
}) => {
  const { _ } = useDictionary(dictionary("selection"))
  return (
    <DialogPrimitive.Root open={A.isNotEmpty(selected)} modal={false}>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Content className="fixed z-50 bottom-4 right-4 flex items-center gap-4 px-8 py-2 border bg-card shadow-lg rounded-sm">
          <div className="text-sm">{selected.length} element(s) selected</div>
          {unselect && (
            <SelectionButton onClick={unselect}>
              <BoxSelect size={16} aria-hidden />
              {_("unselect")}
            </SelectionButton>
          )}
          {publishSelection && (
            <SelectionButton onClick={publishSelection}>
              <Eye size={16} aria-hidden />
              {_("publish")}
            </SelectionButton>
          )}
          {unpublishSelection && (
            <SelectionButton onClick={unpublishSelection}>
              <EyeOff size={16} aria-hidden />
              {_("unpublish")}
            </SelectionButton>
          )}
          {moveSelection && (
            <SelectionButton onClick={moveSelection}>
              <FolderTree size={16} aria-hidden />
              {_("move")}
            </SelectionButton>
          )}
          {deleteSelection && (
            <SelectionButton onClick={deleteSelection}>
              <Trash size={16} aria-hidden />
              {_("delete")}
            </SelectionButton>
          )}
          {children}
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

/**
 * SelectionButton
 */

const SelectionButton: React.FC<ButtonProps> = ({ size = "xs", variant = "ghost", ...props }) => (
  <Button variant={variant} size={size} {...props} />
)

/**
 * exports
 */
const Selection = Object.assign(SelectionRoot, {
  Button: SelectionButton,
})
export { Selection }
