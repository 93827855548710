/// <reference types="vite-plugin-svgr/client" />
import Dance from "@/assets/icons/dance.svg?react"
import { HN } from "@/components/ui/hn"
import { Image } from "@/components/ui/image"
import { Link } from "@/components/ui/link"
import { SrOnly } from "@/components/ui/sr-only"
import { Project } from "@/store/frontend/localizers"
import { MaskHappy, MusicNotes } from "@phosphor-icons/react"
import { dictionary } from "."

/**
 * Item
 */
export const Item: React.FC<{ project: Project; titleLevel?: number }> = ({
  project,
  titleLevel = 2,
}) => {
  const { _ } = useDictionary(dictionary())
  const { id, name, description, category, image } = project
  return (
    <article className={cx("relative flex items-end text-white w-full h-64 p-5")}>
      <div className={"absolute inset-0"}>
        {G.isNotNullable(image) && S.isNotEmpty(image.url) && (
          <Image
            src={image.url}
            className={"absolute inset-0 w-full h-full object-cover"}
            aria-hidden
          />
        )}
        <div
          className={"absolute inset-0 bg-gradient-to-b from-orient/20 to-orient/80"}
          aria-hidden
        />
      </div>
      <div className="absolute right-0 top-0 flex justify-center items-center pl-2 bg-tomato rounded-bl-[40px] w-14 h-14 [&>svg]:h-[28px]">
        {category === "dance" && <Dance aria-hidden />}
        {category === "music" && <MusicNotes aria-hidden />}
        {category === "theater" && <MaskHappy aria-hidden />}
        <SrOnly>{_(`category-${category}`)}</SrOnly>
      </div>
      <div className={"relative flex flex-col gap-2.5"}>
        <HN level={titleLevel} className={cx("font-bold")}>
          {name}
        </HN>
        <p className={cx("text-xs line-clamp-2")}>{description}</p>
      </div>
      <Link className="absolute inset-0 w-full h-full opacity-0" href={`/projects/${id}`}>
        {_("project-link-aria", { name })}
      </Link>
    </article>
  )
}
