import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { normString } from "@/fns/search"
import { useFilterable } from "@/hooks/useFilterable"
import { useLimitable } from "@/hooks/useLimitable"
import { usePromise } from "@/hooks/usePromise"
import { useMatchable } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"
import { usePagesStore } from "."
import { useTranslation } from "../languages/hooks"
import { getPages } from "./actions"
import { Page } from "./localizers"

export const useRefreshPages = () => {
  return usePromise(getPages)
}
export const usePagesById = () => {
  const byIds = usePagesStore(D.prop("pages"))
  return byIds
}
export const usePages = () => {
  const byIds = usePagesById()
  const pages = React.useMemo(() => D.values(byIds), [byIds])
  return pages
}
export const usePage = (id: Option<string>) => {
  const page = usePagesStore(flow(D.prop("pages"), D.prop(id ?? "")))
  return page
}

export const useFilteredPages = (initialLimit = 24, initialByMore = 12) => {
  const pages = usePages()
  const t = useTranslation()
  const format = useDateFnsLocaleFormat()

  const [filterable, filterBy] = useFilterable<Page>("pages", {}, {})

  const [matchable, matchIn] = useMatchable<Page>([
    ({ seo }) => t(seo).title,
    ({ seo }) => t(seo).description,
    ({ createdAt }) => normString(format(createdAt, "Pp")),
    ({ createdAt }) => normString(format(createdAt, "PPPP")),
  ])

  const [sortable, sortBy] = useSortable<Page>(
    "pages",
    {
      title: ({ seo }) => t(seo).title,
      createdAt: ({ createdAt }) => createdAt,
      updatedAt: ({ updatedAt }) => updatedAt,
    },
    "title"
  )

  const filtered = React.useMemo(
    () => pipe(pages, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [pages, filterBy, matchable.search, matchIn, sortBy]
  )

  const [limitable, limit] = useLimitable<Page>(filtered.length, initialLimit, initialByMore)

  return { pages, filtered, filterable, matchable, sortable, limitable, limit }
}
