import { Menu, useMenu } from "@/components/collection"
import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { ArticleCreateDialog } from "@/components/dialogs/article-create"
import { ArticleUpdateDialog } from "@/components/dialogs/article-update"
import { Confirm, confirmSafeDictionary } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { useSelectItem } from "@/hooks/useSelect"
import { deleteArticle, updateArticle } from "@/store/articles/actions"
import { useArticlesById } from "@/store/articles/hooks"
import { Article } from "@/store/articles/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { SelectionPlus, SelectionSlash } from "@phosphor-icons/react"
import { Edit, Eye, EyeOff, PlusSquare, Square, Trash } from "lucide-react"

/**
 * dictionary src/dictionaries/en/pages/dashboard/articles.json
 */
const dictionary = createContextMapper("pages", "dashboard", "articles")

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const articlesById = useArticlesById()
  const displayName = (id: string) => {
    const seo = D.get(articlesById, id)?.seo
    const title = seo ? t(seo).title : ""
    return S.isEmpty(S.trim(title)) ? _("no-title") : title
  }

  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    "grid",
    "state-offers-view",
    "interface",
    sessionStorage
  )

  // confirms
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deleteArticle,
    dictionary: confirmSafeDictionary(dictionary("delete-confirm")),
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName,
    onAsyncConfirm: deleteArticle,
    dictionary: Confirm.dictionary(dictionary("delete-selection-confirm")),
  })
  const { confirm: confirmPublishSelection, ...publishSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName,
    onAsyncConfirm: id => updateArticle(id, { state: "published" }),
    dictionary: Confirm.dictionary(dictionary("publish-selection-confirm")),
  })
  const { confirm: confirmUnpublishSelection, ...unpublishSelectionProps } = useConfirm<
    void,
    string
  >({
    list: selected,
    displayName,
    onAsyncConfirm: id => updateArticle(id, { state: "draft" }),
    dictionary: Confirm.dictionary(dictionary("unpublish-selection-confirm")),
  })

  // dialogs
  const { setItem: create, ...createProps } = useDialog<void>()
  const { setItem: update, ...updateProps } = useDialog<Article>()

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        view,
        setView,
        create,
        update,
        confirmDelete,
        confirmDeleteSelection,
        confirmPublishSelection,
        confirmUnpublishSelection,
      }}
    >
      {children}
      <ArticleCreateDialog {...createProps} types={["offers"]} />
      <ArticleUpdateDialog {...updateProps} types={["offers"]} />
      <Confirm {...deleteProps} />
      <Confirm {...deleteSelectionProps} />
      <Confirm {...publishSelectionProps} />
      <Confirm {...unpublishSelectionProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
type ContextMenuProps = { article: Article }
export const ContextMenu: React.FC<ContextMenuProps> = ({ article }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const {
    confirmPublishSelection,
    confirmUnpublishSelection,
    confirmDelete,
    confirmDeleteSelection,
    create,
    update,
  } = usePageContext()
  const { type } = useMenu()
  const { isSelected, toggleSelection } = useSelectItem(Context, article.id)
  const { id } = article
  const isContextMenu = type === "context-menu"
  const toggleState = () =>
    updateArticle(article.id, { state: article.state === "published" ? "draft" : "published" })

  return (
    <>
      <Menu.Item onClick={toggleSelection}>
        {isSelected ? <SelectionSlash aria-hidden /> : <SelectionPlus aria-hidden />}
        {_(isSelected ? "unselect" : "select")}
      </Menu.Item>
      <Menu.Item onClick={() => navigate(`/dashboard/offers/${article.id}`)}>
        <Square aria-hidden />
        {_("display")}
      </Menu.Item>
      <Menu.Item onClick={() => update(article)}>
        <Edit aria-hidden />
        {_("update")}
      </Menu.Item>
      <Menu.Item onClick={toggleState}>
        {article.state === "draft" && <Eye aria-hidden />}
        {article.state === "published" && <EyeOff aria-hidden />}
        {_(article.state === "published" ? "unpublish" : "publish")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDelete(id)}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
      {isContextMenu && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={create}>
            <PlusSquare aria-hidden />
            {_("create")}
          </Menu.Item>
          {isSelected && (
            <>
              <Menu.Separator />
              <Menu.Item onClick={confirmPublishSelection}>
                <Eye aria-hidden />
                {_("publish-selection")}
              </Menu.Item>
              <Menu.Item onClick={confirmUnpublishSelection}>
                <EyeOff aria-hidden />
                {_("unpublish-selection")}
              </Menu.Item>
              <Menu.Item onClick={confirmDeleteSelection}>
                <Trash aria-hidden />
                {_("delete-selection")}
              </Menu.Item>
            </>
          )}
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  create: () => void
  update: (article: Article) => void
  confirmDelete: (value: string) => void
  confirmDeleteSelection: () => void
  confirmPublishSelection: () => void
  confirmUnpublishSelection: () => void
}
