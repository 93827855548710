import { Empty, Grid, LimitMore, Selection } from "@/components/collection"
import { Toolbar } from "@/components/collection/toolbar"
import { PageContent } from "@/components/layout/dashboard"
import { Button } from "@/components/ui/button"

import { useDictionary } from "@/dictionaries/hooks"
import { useIsSuperadmin } from "@/store/auth/hooks"
import { useFilteredLanguages } from "@/store/languages/hooks"
import { CheckCircle2, XCircle } from "lucide-react"
import { usePageContext } from "./Context"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/dashboard/languages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "languages")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { languages, filtered, filterable, sortable, matchable, limitable, limit } =
    useFilteredLanguages()
  const { view, setView, create, selected, setSelected, confirmDeleteSelection } = usePageContext()
  const isSuperadmin = useIsSuperadmin()
  return (
    <PageContent>
      <Selection
        selected={selected}
        unselect={() => setSelected([])}
        deleteSelection={isSuperadmin ? confirmDeleteSelection : undefined}
      />
      <Toolbar>
        <Toolbar.Search {...matchable} />
        {isSuperadmin && <Button onClick={create}>{_("create")}</Button>}
        <Filters {...filterable} />
        <Toolbar.Sort {...sortable} dictionary={Toolbar.Sort.dictionary(dictionary("sort"))} />
        <Toolbar.View {...{ view, setView }} />
      </Toolbar>
      <Empty
        results={filtered}
        items={languages}
        create={isSuperadmin ? create : undefined}
        clear={() => {
          filterable.clear()
          matchable.setSearch("")
          setSelected([])
        }}
      />
      <Grid {...{ view }}>
        {A.map(limit(filtered), item => (
          <Item key={item.id} language={item} />
        ))}
      </Grid>
      <LimitMore {...limitable} />
    </PageContent>
  )
}

/**
 * Filters
 */
type Props = ReturnType<typeof useFilteredLanguages>["filterable"]
const Filters: React.FC<Props> = ({ isActive, setTrue, isInactive, setFalse }) => {
  const { _ } = useDictionary(dictionary("filters"))
  const { setSelected } = usePageContext()
  return (
    <Toolbar.Filters onClick={() => setSelected([])}>
      <Toolbar.Filters.Button onClick={() => setFalse("deleted")} active={isInactive("deleted")}>
        <CheckCircle2 aria-hidden strokeWidth={isInactive("deleted") ? 2 : 1.4} />
        {_("status-active")}
      </Toolbar.Filters.Button>
      <Toolbar.Filters.Button onClick={() => setTrue("deleted")} active={isActive("deleted")}>
        <XCircle aria-hidden strokeWidth={isActive("deleted") ? 2 : 1.4} />
        {_("status-deleted")}
      </Toolbar.Filters.Button>
    </Toolbar.Filters>
  )
}
