import {
  Form,
  FormAssertive,
  FormExtraFields,
  FormHeader,
  FormInput,
  FormNumber,
  FormSelect,
  FormSubmit,
  FormTextarea,
  FormTiptap,
  useForm,
  validator,
} from "@/components/form"
import { Prose } from "@/components/frontend/prose"
import { FormMap } from "@/components/kulturrallye/FormMap"
import { FormMediasImage } from "@/components/medias/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import type { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { updatePlace } from "@/store/places/actions"
import { useRegionOptions } from "@/store/places/hooks"
import { Place } from "@/store/places/localizers"
import { match } from "ts-pattern"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/places.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "places")

/**
 * EditDialog
 */
export const EditDialog: React.FC<UseDialogProps<Place>> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary("edit-dialog"))
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="max-w-2xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<Place>> = ({ item: place, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const { min } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(place, basicFields),
      coordX: place.coordinate[0],
      coordY: place.coordinate[1],
    })),
    validate: validator({
      name: [min(1, _form("name-required"))],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return _errors("VALIDATION_FAILURE")

      const payload = {
        ...D.selectKeys(values, basicFields),
        coordinate: [values.coordX, values.coordY] as [number, number],
      }
      return match(await updatePlace(place.id, payload))
        .with({ error: false }, () => {
          toast.success(_("edit-dialog.success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })
  const mapOptions = useRegionOptions()
  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormHeader>
        <FormHeader.Title>{_("form.section-info-title")}</FormHeader.Title>
        <FormHeader.Description>{_("form.section-info-description")}</FormHeader.Description>
      </FormHeader>
      <FormInput
        label={_("form.name-label")}
        name="name"
        placeholder={_("form.name-placeholder")}
      />
      <FormTiptap label={_("form.description-label")} name="description" prose={Prose.className} />
      <FormMediasImage label={_("form.cover-label")} name="cover" ratio="aspect-[28/9]" />
      <FormMediasImage label={_("form.image-label")} name="image" fit="object-contain" />

      <FormHeader>
        <FormHeader.Title>{_("form.section-contact-title")}</FormHeader.Title>
        <FormHeader.Description>{_("form.section-contact-description")}</FormHeader.Description>
      </FormHeader>
      <FormTextarea
        label={_("form.address-label")}
        name="address"
        placeholder={_("form.address-placeholder")}
      />
      <FormExtraFields
        label={_form("phones-label")}
        name="phones"
        translate={t => _form(`phones-${t}`)}
        type="tel"
        auto={{ name: "new-phone", value: "tel" }}
      />
      <FormExtraFields
        label={_form("emails-label")}
        name="emails"
        translate={t => _form(`emails-${t}`)}
        type="email"
        auto={{ name: "new-email", value: "email" }}
      />
      <FormInput
        label={_("form.website-label")}
        name="website"
        type="url"
        placeholder={_("form.website-placeholder")}
      />

      <FormHeader>
        <FormHeader.Title>{_("form.section-map-title")}</FormHeader.Title>
        <FormHeader.Description>{_("form.section-map-description")}</FormHeader.Description>
      </FormHeader>
      <div className="grid grid-cols-4 gap-6">
        <div className="col-span-2">
          <FormSelect label={_("form.map-label")} name="map" options={mapOptions} />
        </div>
        <FormNumber label={_("form.coord-x-label")} name="coordX" postfix="°" />
        <FormNumber label={_("form.coord-y-label")} name="coordY" postfix="°" />
      </div>
      <FormMap />

      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * basicFields
 */
const basicFields = [
  "cover",
  "name",
  "description",
  "website",
  "address",
  "phones",
  "emails",
  "image",
  "map",
] as const
