import { Menu, useMenu } from "@/components/collection"
import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { Confirm } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { useSelectItem } from "@/hooks/useSelect"
import { Place } from "@/store/places/localizers"
import { deleteWorkshopEvent } from "@/store/workshops/actions"
import { Workshop, WorkshopEvent } from "@/store/workshops/localizers"
import { SelectionPlus, SelectionSlash } from "@phosphor-icons/react"
import { Pen, PlusSquare, Trash } from "lucide-react"
import { CreateDialog } from "./CreateDialog"
import { EditDialog } from "./EditDialog"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/workshops.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "events")

/**
 * Context events
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<
  React.PropsWithChildren<{ place: Place; workshop: Workshop }>
> = ({ workshop, place, children }) => {
  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    "list",
    "state-events-view",
    "interface",
    sessionStorage
  )

  // confirms
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deleteWorkshopEvent,
    dictionary: Confirm.dictionary(dictionary("delete-confirm")),
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    onAsyncConfirm: deleteWorkshopEvent,
    dictionary: Confirm.dictionary(dictionary("delete-selection-confirm")),
  })

  // dialogs
  const { setItem: create, ...createProps } = useDialog<void>()
  const { setItem: edit, ...editProps } = useDialog<WorkshopEvent>()

  return (
    <Context.Provider
      value={{
        place,
        workshop,
        selected,
        setSelected,
        view,
        setView,
        create,
        edit,
        confirmDelete,
        confirmDeleteSelection,
      }}
    >
      {children}
      <Confirm {...deleteProps} />
      <Confirm {...deleteSelectionProps} />
      <CreateDialog {...createProps} />
      <EditDialog {...editProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
export const ContextMenu: React.FC<{
  event: WorkshopEvent
}> = ({ event }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { confirmDelete, edit, create, confirmDeleteSelection } = usePageContext()
  const { id } = event
  const { isSelected, toggleSelection } = useSelectItem(Context, id)
  const { type } = useMenu()
  const isContextMenu = type === "context-menu"
  return (
    <>
      <Menu.Item onClick={toggleSelection}>
        {isSelected ? <SelectionSlash aria-hidden /> : <SelectionPlus aria-hidden />}
        {_(isSelected ? "unselect" : "select")}
      </Menu.Item>
      <Menu.Item onClick={() => edit(event)}>
        <Pen aria-hidden />
        {_("edit")}
      </Menu.Item>
      <Menu.Item onClick={() => confirmDelete(id)}>
        <Trash aria-hidden />
        {_("delete")}
      </Menu.Item>
      {isContextMenu && (
        <>
          <Menu.Separator />
          <Menu.Item onClick={create}>
            <PlusSquare aria-hidden />
            {_("create")}
          </Menu.Item>
          {isSelected && (
            <>
              <Menu.Separator />
              <Menu.Item onClick={confirmDeleteSelection}>
                <Trash aria-hidden />
                {_("delete-selection")}
              </Menu.Item>
            </>
          )}
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  place: Place
  workshop: Workshop
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  confirmDelete: (value: string) => void
  edit: (value: WorkshopEvent) => void
  create: () => void
  confirmDeleteSelection: () => void
}
