import {
  Form,
  FormAssertive,
  FormInput,
  FormSubmit,
  FormTranslationTabs,
  useForm,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { updateArticleCategory } from "@/store/articles/actions"
import { ArticleCategory } from "@/store/articles/localizers"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { FormTextarea } from "../form/textarea"
import { UseDialogFormProps, UseDialogProps } from "../ui/hooks/useDialog"

/**
 * dictionary src/dictionaries/en/components/dialogs/article-category-update-dialog.json
 */
const dictionary = createContextMapper("components", "dialogs", "article-category-update-dialog")

/**
 * ArticleCategoryUpdateDialog
 */
export const ArticleCategoryUpdateDialog: React.FC<UseDialogProps<ArticleCategory>> = ({
  item,
  ...props
}) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<ArticleCategory>> = ({
  onOpenChange,
  item: category,
}) => {
  const { _ } = useDictionary(dictionary())
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const languagesById = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      translations: D.map(languagesById, language => ({
        languageId: language.id,
        name: translate(category, language)?.name ?? "",
        description: translate(category, language)?.description ?? "",
      })),
    })),
    onSubmit: async ({ values }) => {
      const payload = { translations: D.values(values.translations) }
      return match(await updateArticleCategory(category.id, payload))
        .with({ error: false }, () => {
          toast.success(_("success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("RESOURCE_NOT_FOUND", code => {
              onOpenChange(false)
              toast.error(_errors(code))
            })
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormTranslationTabs>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormInput
              label={_form("category-name-label")}
              name="name"
              placeholder={_form("category-name-placeholder")}
            />
            <FormTextarea
              label={_form("category-description-label")}
              name="description"
              placeholder={_form("category-description-placeholder")}
            />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
