import { useCmsContext } from "@/components/cms/Context"
import {
  Form,
  FormAssertive,
  FormInput,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
} from "@/components/form"
import { FormMediasImage } from "@/components/medias/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { mergeNonNullables } from "@/fns/Array"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { ImageOff } from "lucide-react"
import { match } from "ts-pattern"
import { useForm } from "use-a11y-form"
import { FormSelectTemplate } from "../../form"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")
type Payload = FormPayload<ItemType>

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary("content", "items", itemType))
  const { _: __ } = useDictionary(dictionary("content", "form"))
  const _f = useFormDictionary()
  const _e = useErrorsDictionary()

  const {
    id,
    actions: { updateContentItem },
  } = useCmsContext()
  const languages = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(item.props, ["titleLevel", "template", "image"]),
      titleLevel: `${item.props.titleLevel}`,
      translations: D.map(languages, language => ({
        languageId: language.id,
        title: translate(item, language)?.props.title ?? "",
        secondary: translate(item, language)?.props.secondary ?? "",
        linkText: translate(item, language)?.props.linkText ?? "",
        linkUrl: translate(item, language)?.props.linkUrl ?? "",
      })),
    })),
    onSubmit: async ({ values }) => {
      values.translations
      const payload: Payload = {
        props: {
          ...D.selectKeys(values, ["template", "image"]),
          titleLevel: +values.titleLevel,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: mergeNonNullables([values.image]),
      }
      return match(await updateContentItem(item.id, payload))
        .with({ error: false }, () => {
          toast.success(__("success"))
          close()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _e)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_e(code)))
        )
    },
  })

  const titleLevelOptions = useTitleLevelOptions()

  return (
    <Form form={form}>
      <FormAssertive />

      <FormTranslationTabs context={false}>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormTranslationContext language={language}>
              <FormInput
                name="title"
                label={__("title-label")}
                placeholder={__("title-placeholder")}
                translatable
              />
            </FormTranslationContext>
            <FormSelect
              options={titleLevelOptions}
              name="titleLevel"
              label={__("title-level-label")}
            />
            <FormTranslationContext language={language}>
              <FormTiptap
                name="secondary"
                label={__("secondary-label")}
                placeholder={__("secondary-placeholder")}
                translatable
              />
            </FormTranslationContext>
            <FormMediasImage name="image" label={__("image-label")} contextKey={id} />
            <FormSelectTemplate
              name="template"
              options={[Template1, Template2, Template3]}
              label={__("image-label")}
            />
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_f("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_f("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * templates
 */
const Template1: React.FC = () => (
  <div className="grid grid-cols-2 gap-[6px] p-2">
    <div className="flex flex-col justify-center gap-[3px] ">
      <div className="text-[4px] leading-tight">Lorem, ipsum dolor</div>
      <div className="text-[3px] leading-tight line-clamp-3">
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam, excepturi quidem! Aut
        suscipit praesentium nostrum dolorem vero quod repellat quisquam.
      </div>
      <div className="text-[3px] leading-tight">Lorem ipsum</div>
    </div>
    <div className="flex justify-center items-center aspect-square rounded-sm bg-muted text-muted-foreground">
      <ImageOff size={8} strokeWidth={1} />
    </div>
  </div>
)
const Template2: React.FC = () => (
  <div className="grid grid-cols-2 gap-[6px] p-2">
    <div className="flex justify-center items-center aspect-square rounded-sm bg-muted text-muted-foreground">
      <ImageOff size={8} strokeWidth={1} />
    </div>
    <div className="flex flex-col justify-center gap-[3px] ">
      <div className="text-[4px] leading-tight">Lorem, ipsum dolor</div>
      <div className="text-[3px] leading-tight line-clamp-3">
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam, excepturi quidem! Aut
        suscipit praesentium nostrum dolorem vero quod repellat quisquam.
      </div>
      <div className="text-[3px] leading-tight">Lorem ipsum</div>
    </div>
  </div>
)
const Template3: React.FC = () => (
  <div className="flex items-center p-2">
    <div className="relative flex justify-center items-center w-full h-[32px] bg-muted text-muted-foreground">
      <div className="absolute bottom-[4px] left-[4px] p-[1px] text-[4px] leading-none text-foreground">
        Lorem, ipsum dolor
      </div>
      <div className="absolute bottom-[4px] right-[4px] p-[1px] text-[3px] border-[0.1px] border-foreground leading-none text-foreground">
        Lorem, ipsum
      </div>
      <ImageOff size={8} strokeWidth={1} />
    </div>
  </div>
)
