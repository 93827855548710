import { HN } from "@/components/ui/hn"
import { Image } from "@/components/ui/image"
import { Link } from "@/components/ui/link"
import cmsConfig from "@/config/cms"
import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { useData } from "@/hooks/usePromise"
import { ArticlesIndexParams, service } from "@/services/frontend/service"
import { Article, ArticleCategory, localizeArticle } from "@/store/frontend/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { getPreview } from "@/store/medias/helpers"
import { ImageOff } from "lucide-react"
import { match } from "ts-pattern"
import { Header, ItemMappingExport, getMediasFile } from "."
import { Container } from "../container"
import { Wrapper } from "../wrapper"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", "offers")

type RenderFC = ItemMappingExport<"offers">["Render"]

export const Render: RenderFC = props => {
  const { item } = props
  const t = useTranslation()
  const [articles] = useData(initialArticles, () =>
    loadArticles(item.props.articles, item.props.category, item.props.omit)
  )

  const { titleLevel } = item.props
  const { title, secondary } = t(item).props
  if (A.isEmpty(articles)) return null
  return (
    <Wrapper margin="normal">
      <Container x="sm" className="flex flex-col gap-4">
        <Header {...{ title, secondary, titleLevel }} />
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {A.map(articles, article => (
            <ArticleRender article={article} titleLevel={titleLevel} key={article.id} />
          ))}
        </div>
      </Container>
    </Wrapper>
  )
}

const ArticleRender: React.FC<{ article: Article; titleLevel: number }> = ({
  article,
  titleLevel,
}) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const format = useDateFnsLocaleFormat()
  const { title, image, description } = t(article.seo)
  const { publishedAt, category } = article
  const imageFile = getMediasFile(article.files, image)

  return (
    <article className="relative flex flex-col items-stretch rounded-md bg-white text-gray-800 shadow-md">
      <div className="relative flex aspect-video rounded-t-md overflow-hidden">
        <Image
          src={getPreview(imageFile)}
          alt={imageFile ? t(imageFile).alt : title}
          className="absolute inset-0 size-full object-cover rounded-t-md text-muted-foreground"
        >
          <ImageOff size={64} strokeWidth={1} />
        </Image>
        <div
          className="absolute inset-0 size-full bg-gradient-to-b from-orient/20 from-5% to-orient/80 to-80%"
          aria-hidden
        />
        <div className="relative flex flex-col justify-stretch size-full p-4">
          <div className="grow">
            <CategoryRender category={category} />
          </div>
          <HN level={titleLevel + 1} className="text-white text-xl font-bold">
            {t(article.seo).title}
          </HN>
        </div>
      </div>
      <div className="flex flex-col justify-stretch items-stretch gap-2 p-4">
        <p className="text-xs text-frontend-gray">
          {_("published-on", { date: format(publishedAt, "PPP") })}
        </p>
        <p className="text-xs leading-relaxed line-clamp-3">{description}</p>
      </div>
      <Link href={`/offers/${article.id}`} className="absolute inset-0 size-full" />
    </article>
  )
}
const CategoryRender: React.FC<{ category: Option<ArticleCategory> }> = ({ category }) => {
  const t = useTranslation()
  if (G.isNullable(category)) return null
  const name = t(category).name
  return (
    <p className="inline-flex min-h-[1rem] mx-auto px-4 py-2 rounded-full backdrop-blur-sm bg-frontend-bluewhale/40 uppercase text-white text-xs leading-none font-semibold">
      {name}
    </p>
  )
}

/**
 * loadArticles
 */
const loadArticles = async (
  articleIds: string[],
  category: Option<string> = null,
  omit: Option<string> = null
) => {
  const articlesSelected = pipe(
    await Promise.all(
      A.map(articleIds, async id =>
        match(await service.articles.read(id))
          .with({ error: false }, ({ data }) => localizeArticle(data.article))
          .otherwise(() => O.None)
      )
    ),
    A.filter(G.isNotNullable),
    A.sortBy(D.getUnsafe("publishedAt")),
    A.take(cmsConfig.relatedArticles)
  )
  const limit = cmsConfig.relatedArticles - articlesSelected.length

  if (G.isNullable(category) || limit <= 0) return articlesSelected

  const articlesFromCategory = match(
    await service.articles.categories.read(category, {
      limit,
      ...D.filter({ omit }, G.isNotNullable),
    } as ArticlesIndexParams)
  )
    .with({ error: false }, ({ data }) =>
      pipe(
        data.articles,
        A.map(localizeArticle),
        A.sortBy(D.getUnsafe("publishedAt")),
        A.take(limit)
      )
    )
    .otherwise(() => initialArticles)

  return [...articlesSelected, ...articlesFromCategory]
}
const initialArticles: Article[] = []
