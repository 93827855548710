import { useTranslation } from "react-i18next"
import { Locale, i18nConfig } from "../config/i18n"
import fr from "./fr.json"

/**
 * useDictionary
 */
export const useDictionary = <P extends string, S extends boolean = true>(
  prefix?: S extends true ? CheckDictString<P, DefaultDict> : string
) => {
  const { t, i18n, ...rest } = useTranslation<string, typeof i18nConfig.defaultNamespace>(
    i18nConfig.defaultNamespace
  )
  const { language, languages, changeLanguage } = i18n
  type Dict = typeof prefix extends undefined ? DefaultDict : GetDictValue<P, DefaultDict>
  const _ = React.useCallback(
    <T extends string>(
      path: S extends true ? CheckDictString<T, Dict> : string,
      options?: Parameters<typeof t>[1]
    ): string => {
      const context = G.isString(prefix) ? `${prefix}.${path}` : path
      const translation = t(...([context, options] as Parameters<typeof t>))
      return G.isString(translation) ? translation : ""
    },
    [prefix, t]
  )
  return makeIndexable({ _, language, languages, changeLanguage, t, i18n, ...rest }, [_])
}

/**
 * useErrorsDictionary
 */
export const useErrorsDictionary = () => {
  const { _ } = useDictionary("errors")
  return _
}

/**
 * useFormDictionary
 */
export const useFormDictionary = () => {
  const { _ } = useDictionary("form")
  return _
}

/**
 * useDateFnsLocale
 */
export const useDateFnsLocale = () => {
  const { i18n } = useTranslation<string, typeof i18nConfig.defaultNamespace>(
    i18nConfig.defaultNamespace
  )
  const current = D.get(i18nConfig.dateFnsLocales, i18n.language as Locale)
  if (G.isNotNullable(current)) return current
  return i18nConfig.dateFnsLocales.fr
}

/**
 * useDateFnsLocaleFormat
 */
export const useDateFnsLocaleFormat = (): typeof T.format => {
  const locale = useDateFnsLocale()
  return React.useCallback(
    (date: Date | number, fmt: string, opts) =>
      T.format(date, fmt, {
        locale,
        ...opts,
      }),
    [locale]
  )
}

/**
 * useInitDictionary
 */
export const useInitDictionary = () => {
  // set current language on first load and update it on change
  const { language } = useDictionary()
  React.useEffect(
    () =>
      document?.documentElement.setAttribute(
        "lang",
        pipe(language, S.split("-"), A.map(S.trim), A.getUnsafe(0), S.toLowerCase)
      ),
    [language]
  )
}

/**
 * helpers
 */
export const dictionarySafe = <T extends string>(
  context: CheckDictString<T, DefaultDict>
): string => context
const makeIndexable = <T extends Record<string, any>, U extends any[]>(p1: T, p2: U): T & U => {
  return { ...p1, ...p2 } as T & U
}

/**
 * types
 */
export type TFunction = ReturnType<typeof useDictionary>["_"]

export type CheckDictString<T extends string, O> = T extends `${infer A}.${infer B}`
  ? A extends keyof O
    ? `${A}.${Extract<CheckDictString<B, O[A]>, string>}`
    : never
  : T extends keyof O
  ? T
  : never

export type CheckDictEnString<T extends string, O = DefaultDict> = T extends `${infer A}.${infer B}`
  ? A extends keyof O
    ? `${A}.${Extract<CheckDictString<B, O[A]>, string>}`
    : never
  : T extends keyof O
  ? T
  : never
export type GetDictValue<T extends string, O> = T extends `${infer A}.${infer B}`
  ? A extends keyof O
    ? GetDictValue<B, O[A]>
    : never
  : T extends keyof O
  ? O[T]
  : never

export type DefaultDict = typeof fr
export type HasRequiredProps<
  T extends string,
  Props extends Record<string, unknown>,
  D = GetDictValue<T, DefaultDict>
> = D extends Props ? T : never

// Type pour valider le contexte et les propriétés
export type ValidContextAndProps<
  T extends string,
  Props extends Record<string, unknown>
> = CheckDictString<T, DefaultDict> extends T
  ? HasRequiredProps<T, Props> extends never
    ? never
    : T
  : never
