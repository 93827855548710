import { CounterHighlight } from "@/components/trackings/components/counter-highlight"
import { StatsVisitByDays } from "@/components/trackings/components/stats-visit-by-days"
import { useStatsTheme } from "@/components/trackings/useStatsTheme"
import { Card } from "@/components/ui/card"
import { useLiveState } from "@/hooks/useLiveState"
import { service } from "@/services/trackings/service"

/**
 * dictionary src/dictionaries/en/pages/dashboard/index.json
 */
const dictionary = createContextMapper("pages", "dashboard", "index")

/**
 * VisitsStats
 */
export const VisitsStats: React.FC = () => {
  const { _ } = useDictionary(dictionary("visits-stats"))
  const { colors } = useStatsTheme()
  const [data, setData] = React.useState<{ today: number; last7Days: number; lastMonth: number }>({
    today: 0,
    last7Days: 0,
    lastMonth: 0,
  })
  const isVisible = useLiveState(async () => {
    const { error, data } = await service.stats()
    if (error) return
    setData({
      today: D.get(data.stats, "today") ?? 0,
      last7Days: D.get(data.stats, "last7Days") ?? 0,
      lastMonth: D.get(data.stats, "lastMonth") ?? 0,
    })
  })

  const interval = React.useMemo(() => {
    const now = new Date()
    return { from: T.sub(now, { days: 7 }), to: now }
  }, [])

  return (
    <Card.Spotlight className="grid grid-rows-[auto_1fr]">
      <Card.Header>
        <Card.Title>{_("title")}</Card.Title>
        <Card.Description>{_("secondary")}</Card.Description>
      </Card.Header>
      <Card.Content className="flex flex-col justify-end gap-6">
        <div className="w-full aspect-video">
          <StatsVisitByDays
            hideAxis
            hideGrid
            strokeWidth={3}
            color="hsl(var(--primary))"
            live={isVisible}
            interval={interval}
          />
        </div>
        <div className="grid grid-cols-3 gap-6">
          <CounterHighlight
            counter={data.today}
            description={_("today")}
            color="hsl(var(--primary))"
          />
          <CounterHighlight
            counter={data.last7Days}
            description={_("last-7-days")}
            color="hsl(var(--primary))"
          />
          <CounterHighlight
            counter={data.lastMonth}
            description={_("last-month")}
            color="hsl(var(--primary))"
          />
        </div>
      </Card.Content>
    </Card.Spotlight>
  )
}
