import { z } from "zod"
import { apiMediasFile } from "../medias/schemas"

/**
 * ApiContentItemTranslation
 */
export const apiContentItemTranslation = z.object({
  id: z.string().uuid(),
  languageId: z.string().uuid(),
})

/**
 * ApiContentItemState
 */
export const apiContentItemState = z.enum(["draft", "published"])
export type ApiContentItemState = z.infer<typeof apiContentItemState>

/**
 * ApiContentItem
 */
export const apiContentItemBase = z.object({
  id: z.string(),
  state: apiContentItemState,
  files: apiMediasFile.array(),
  order: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiContentItemBase = z.infer<typeof apiContentItemBase>
