import { type Locale as DateFnsLocale } from "date-fns"
import { de, enGB, fr } from "date-fns/locale"

/**
 * i18nConfig
 */
const locales = ["fr", "de"] as const //"en",
export const i18nConfig: I18nConfig = {
  locales,
  defaultLocale: "fr",
  fallbackLocale: "fr",
  debug: false && process.env.VITE_ENV === "development",
  defaultNamespace: "translation",
  localeCodes: {
    // en: "en-US",
    fr: "fr-FR",
    de: "de-DE",
  },
  dateFnsLocales: { fr, enGB, de }, //en: enGB,
}

/**
 * types
 */
export type Locale = (typeof locales)[number]
interface DateFnsLocales extends Record<Locale, DateFnsLocale> {
  enGB: DateFnsLocale
}
type I18nConfig = {
  locales: typeof locales
  defaultLocale: Locale
  fallbackLocale: Locale
  debug: boolean
  defaultNamespace: "translation"
  localeCodes: Record<Locale, string>
  dateFnsLocales: DateFnsLocales
}
