import { z } from "zod"
import { metaData } from "../commons/schemas"

/**
 * ApiMediasTransform
 */
export const apiMediasTransform = z.object({
  width: z.number(),
  height: z.number(),
  x: z.number(),
  y: z.number(),
  rotate: z.number(),
  cropper: z.object({
    zoom: z.number(),
    crop: z.object({
      x: z.number(),
      y: z.number(),
    }),
    aspect: z.object({
      w: z.number(),
      h: z.number(),
    }),
  }),
})
export type ApiMediasTransform = z.infer<typeof apiMediasTransform>

/**
 * ApiFileTranslation
 */
export const apiMediasFileTranslation = z.object({
  id: z.string().uuid(),
  fileId: z.string().uuid(),
  languageId: z.string().uuid(),
  name: z.string(),
  alt: z.string(),
  caption: z.string(),
})
export type ApiMediasFileTranslation = z.infer<typeof apiMediasFileTranslation>

/**
 * ApiMediaFile
 */
export const apiMediasFile = z.object({
  id: z.string().uuid(),
  folderId: z.string().uuid().nullable(),
  size: z.number(),
  extension: z.string(),
  metaData: metaData,
  clientName: z.string(),
  url: z.string(),
  previewUrl: z.string(),
  thumbnailUrl: z.string(),
  originalUrl: z.string(),
  transform: z.union([apiMediasTransform, z.object({})]),
  createdAt: z.string(),
  updatedAt: z.string(),
  translations: z.array(apiMediasFileTranslation),
})
export type ApiMediasFile = z.infer<typeof apiMediasFile>

/**
 * ApiMediasFolder
 */
export const apiMediasBaseFolder = z.object({
  id: z.string().uuid(),
  parentId: z.string().uuid().nullable(),
  name: z.string(),
  lock: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type ApiMediasBaseFolder = z.infer<typeof apiMediasBaseFolder>
// export type ApiMediasFolder = z.infer<typeof apiMediasBaseFolder> & {
//   folders: ApiMediasFolder[]
// }
export const apiMediasFolder = apiMediasBaseFolder.extend({
  folders: apiMediasBaseFolder.array(),
  files: apiMediasFile.array(),
})
export type ApiMediasFolder = z.infer<typeof apiMediasFolder>
