import { i18nConfig } from "@/config/i18n"
import { useFilterable } from "@/hooks/useFilterable"
import { usePromise } from "@/hooks/usePromise"
import { useMatchable } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"

import { byId } from "@/fns/byId"
import { useLimitable } from "@/hooks/useLimitable"
import { useLanguagesStore } from "."
import { getLanguages, initLanguagesStore } from "./actions"
import { Language } from "./localizers"

/**
 * useInitLanguagesStore
 */
export const useInitLanguagesStore = () => {
  const done = useLanguagesStore(D.prop("initDone"))
  const [response, loading] = usePromise(initLanguagesStore)
  return [loading, done, response] as const
}
export const useRefreshLanguages = () => {
  return usePromise(getLanguages)
}
export const useLanguagesById = () => {
  const byIds = useLanguagesStore(D.prop("languages"))
  return byIds
}

export const useLanguage = (id: string) => {
  return useLanguagesStore(({ languages }) => D.get(languages, id))
}

export const useLanguages = () => {
  const byIds = useLanguagesById()
  const languages = React.useMemo(() => D.values(byIds), [byIds])
  return languages
}
export const useActiveLanguages = () => {
  const byIds = useLanguagesById()
  const languages = React.useMemo(
    () => A.filter(D.values(byIds), language => language.status === "active"),
    [byIds]
  )
  return languages
}
export const useActiveLanguagesById = () => {
  const byIds = useLanguagesById()
  const languages = React.useMemo(
    () => byId(A.filter(D.values(byIds), language => language.status === "active")),
    [byIds]
  )
  return languages
}

/**
 * collection
 */
export const useFilteredLanguages = (initialLimit = 24, initialByMore = 12) => {
  const languages = useLanguages()

  const [filterable, filterBy] = useFilterable<Language>(
    "languages",
    {
      deleted: ({ status }) => status === "deleted",
    },
    { deleted: false }
  )

  const [matchable, matchIn] = useMatchable<Language>(["name", "code", "locale"])

  const [sortable, sortBy] = useSortable<Language>(
    "languages",
    {
      name: D.prop("name"),
      code: D.prop("code"),
      locale: D.prop("locale"),
    },
    "name"
  )

  const filtered = React.useMemo(
    () => pipe(languages, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [languages, filterBy, matchable.search, matchIn, sortBy]
  )

  const [limitable, limit] = useLimitable<Language>(filtered.length, initialLimit, initialByMore)

  return { languages, filtered, filterable, sortable, matchable, limitable, limit }
}

/**
 * useCurrentLanguage
 */
export const useCurrentLanguage = () => {
  const { language: i18nCurrent } = useDictionary()
  const list = useActiveLanguages()
  const language = React.useMemo(() => {
    let current = A.find(list, ({ locale, code }) => A.includes([locale, code], i18nCurrent))
    if (G.isNullable(current))
      current = A.find(list, ({ locale, code }) =>
        A.includes([locale, code], i18nConfig.fallbackLocale)
      )
    if (G.isNullable(current))
      current = A.find(list, ({ locale, code }) =>
        A.includes([locale, code], D.getUnsafe(i18nConfig.localeCodes, i18nConfig.fallbackLocale))
      )
    if (G.isNullable(current)) return undefined
    return current
  }, [list, i18nCurrent])
  return language
}

/**
 * useFallbackLanguage
 */
export const useFallbackLanguage = () => {
  const byIds = useLanguagesById()
  const language = React.useMemo(() => {
    const list = D.values(byIds)
    let current = A.find(list, ({ locale, code }) =>
      A.includes([locale, code], i18nConfig.fallbackLocale)
    )
    if (G.isNullable(current))
      current = A.find(list, ({ locale, code }) =>
        A.includes([locale, code], D.getUnsafe(i18nConfig.localeCodes, i18nConfig.fallbackLocale))
      )
    if (G.isNullable(current)) throw new Error(`No language found for ${i18nConfig.fallbackLocale}`)
    return current
  }, [byIds])
  return language
}

/**
 * useTranslation
 */
export const useTranslation = () => {
  const current = useCurrentLanguage()
  const fallback = useFallbackLanguage()
  const translate = <T extends Translation>(translatable: Translatable<T>) => {
    if (G.isNotNullable(current))
      return (
        D.get(translatable.translations, current.id) ??
        D.get(translatable.translations, fallback.id) ??
        ({} as T)
      )
    if (G.isNotNullable(fallback)) D.get(translatable.translations, fallback.id) ?? ({} as T)
    return {} as T
  }
  return translate
}

/**
 * translate
 */
export const translate = <T extends Translation>(
  translatable: Translatable<T>,
  language: Language
) => {
  return D.get(translatable.translations, language.id)
}

/**
 * translateUnsafe
 */
export const translateUnsafe = <T extends Translation>(
  translatable: Translatable<T>,
  language: Language
) => {
  return D.get(translatable.translations, language.id) ?? ({} as T)
}

/**
 * options
 */
export const useLanguageStatusOptions = () => {
  const _form = useFormDictionary()
  const options = React.useMemo(() => {
    return A.map(["active", "deleted"] as const, status => ({
      label: _form(`status-${status}`),
      value: status,
    }))
  }, [_form])
  return options
}
