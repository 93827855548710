import { ContentItemMapping } from "@/components/cms/items/schemas"
import { ContentItem, ContentItemBase } from "@/store/contents/localizers"
import { MediasFile } from "@/store/medias/localizers"

/**
 * imports
 */
import { Prose } from "@/components/frontend/prose"
import { HN } from "@/components/ui/hn"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import * as articles from "./articles"
import * as cards from "./cards"
import * as contactDetails from "./contact-details"
import * as contactForm from "./contact-form"
import * as gallery from "./gallery"
import * as heading from "./heading"
import * as kulturRallye from "./kultur-rallye"
import * as news from "./news"
import * as newsletter from "./newsletter"
import * as offers from "./offers"
import * as picture from "./picture"
import * as projects from "./projects"
import * as richtext from "./richtext"
import * as video from "./video"

const Items: ItemMapping<keyof ContentItemMapping> = {
  articles,
  cards,
  "contact-details": contactDetails,
  "contact-form": contactForm,
  gallery,
  heading,
  "kultur-rallye": kulturRallye,
  news,
  newsletter,
  offers,
  picture,
  projects,
  richtext,
  video,
}

/**
 * Dispatcher
 */
export const ItemRenderDispatcher: React.FC<{ item: ContentItem; files: ById<MediasFile> }> = ({
  item,
  files,
}) => {
  const Component = Items[item.type].Render
  // @ts-expect-error
  return <Component item={item} files={files} />
}

/**
 * helpers
 */
export const getMediasFile = (files: ById<MediasFile>, id: Option<string>) => D.get(files, id ?? "")
export const getMediasFiles = (files: ById<MediasFile>, ids: string[]) =>
  A.filterMap(ids, id => getMediasFile(files, id) ?? O.None)

/**
 * types
 */
export type ItemMappingExport<T extends keyof ContentItemMapping> = {
  Render: React.FC<{ item: ContentItemBase & ContentItemMapping[T]; files: ById<MediasFile> }>
}
type ItemMapping<T extends keyof ContentItemMapping> = {
  [K in T]: ItemMappingExport<K>
}

/**
 * header
 */
export const Header: React.FC<{ title: string; secondary: string; titleLevel: number }> = ({
  title,
  secondary,
  titleLevel,
}) => {
  return oneIsNotEmpty(title, stripeHtml(secondary)) ? (
    <div className="flex flex-col gap-4">
      {oneIsNotEmpty(title) && (
        <HN
          level={titleLevel}
          className="text-xl lg:text-[30px] leading-[48px] font-extrabold text-orient max-w-3xl"
        >
          {title}
        </HN>
      )}
      {oneIsNotEmpty(stripeHtml(secondary)) && (
        <Prose className="max-w-3xl" dangerouslySetInnerHTML={{ __html: secondary }} />
      )}
    </div>
  ) : null
}
