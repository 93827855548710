import { Loader } from "@/components/collection"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { usePromise } from "@/hooks/usePromise"
import { SelectProvider, useSelect } from "@/hooks/useSelect"
import { getUsers } from "@/store/users/actions"
import { getWorkshops } from "@/store/workshops/actions"
import { Collection } from "./Collection"
import { Context, ContextProvider } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/index.json
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/reservations.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley")

/**
 * DashboardKultureralleyReservations
 */
const DashboardKultureralleyReservations: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const select = useSelect(Context)
  return (
    <PageWrapper ref={select.ref}>
      <PageHeader
        breadcrumbs={[
          [_("index.breadcrumbs"), "/dashboard/kultureralley"],
          [_("reservations.breadcrumbs"), "/dashboard/kultureralley/reservations"],
        ]}
      />
      <SelectProvider {...select.props} />
      <Collection />
    </PageWrapper>
  )
}
export default () => {
  const { _ } = useDictionary(dictionary())
  const [, inProgress] = usePromise(() => Promise.all([getWorkshops(), getUsers()]))
  return inProgress ? (
    <Loader breadcrumbs={[[_("index.breadcrumbs"), "/dashboard/kultureralley"]]} />
  ) : (
    <ContextProvider>
      <DashboardKultureralleyReservations />
    </ContextProvider>
  )
}
