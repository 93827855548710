/**
 * generateGoogleMapsLink
 */
export const generateGoogleMapsLink = (address: string): string => {
  const cleanedAddress = address.replace(/[\r\n]+/g, " ")
  const sanitizedAddress = cleanedAddress.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "")
  const encodedAddress = encodeURIComponent(sanitizedAddress.replace(/\s+/g, "+"))
  const googleMapsLink = `https://www.google.com/maps/place/${encodedAddress}`
  return googleMapsLink
}
