import { Loader } from "@/components/collection"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { usePromise } from "@/hooks/usePromise"
import { SelectProvider, useSelect } from "@/hooks/useSelect"
import { getPlaces } from "@/store/places/actions"
import { getWorkshops } from "@/store/workshops/actions"
import { Collection } from "./Collection"
import { Context, ContextProvider } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/index.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "index")

/**
 * Kultureralley
 */
const DashboardKultureralley: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const select = useSelect(Context)
  return (
    <PageWrapper ref={select.ref}>
      <PageHeader breadcrumbs={[[_("breadcrumbs"), "/dashboard/kultureralley"]]} />
      <SelectProvider {...select.props} />
      <Collection />
    </PageWrapper>
  )
}
export default () => {
  const [, inProgress] = usePromise(() => Promise.all([getPlaces(), getWorkshops()]))
  if (inProgress) return <Loader breadcrumbs={[]} />
  return (
    <ContextProvider>
      <DashboardKultureralley />
    </ContextProvider>
  )
}
