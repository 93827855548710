import { User } from "./localizers"

/**
 * getFullname
 * returns the full name of a user
 */
export const getFullname = (user: User, startWithLastname = false) =>
  startWithLastname
    ? `${user.profile.lastname} ${user.profile.firstname}`
    : `${user.profile.firstname} ${user.profile.lastname}`
