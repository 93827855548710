import { Patch } from "evolve-ts"

const prefixKey = (key: string) => `${key}/persisted-useEvolveState-hydration-0.1`

export const useEvolveState = <S extends object>(
  initState: S | (() => S),
  persistKey?: string,
  storage: Storage = localStorage
): [S, Evolver<S>, { persisted: boolean; clear: VoidFunction }] => {
  const persisted = React.useRef(false)

  const [state, setState] = React.useState((...args) => {
    if (persistKey) {
      const maybePersistedState = storage.getItem(prefixKey(persistKey))

      if (maybePersistedState) {
        try {
          const parsedPersistedState: null | { state: Dehyrated<S>; ts: number } =
            JSON.parse(maybePersistedState)

          if (parsedPersistedState && "state" in parsedPersistedState) {
            persisted.current = true
            return shallowHydrate(parsedPersistedState.state)
          }
        } catch (e) {
          storage.removeItem(prefixKey(persistKey))
        }
      }
    }

    return G.isFunction(initState) ? initState(...args) : initState
  })

  React.useEffect(() => {
    if (persistKey) {
      const dehydratedState = shallowDehydrate(state)
      storage.setItem(
        prefixKey(persistKey),
        JSON.stringify({ state: dehydratedState, ts: +new Date() })
      )
    }
  }, [state, persistKey])

  const evolveState = (patch: Patch<S>) => void setState(evolve(patch))

  const clearLocalStorage = () => {
    if (persistKey) storage.removeItem(prefixKey(persistKey))
  }

  return [state, evolveState, { persisted: persisted.current, clear: clearLocalStorage }]
}

/**
 * shallowDehydrate
 */

const shallowDehydrate = <S extends object>(state: S): Dehyrated<S> => {
  const types = Object.entries(state).reduce((acc, [key, value]) => {
    if (value instanceof Date) return { ...acc, [key]: { type: "date", iso: value.toISOString() } }
    if (value === undefined) return { ...acc, [key]: { type: "nullish" } }
    return acc
  }, {})

  return { value: state, types }
}

/**
 * shallowHydrate
 */

const shallowHydrate = <S extends object>({ value, types }: Dehyrated<S>): S => {
  const state = Object.entries(types).reduce((acc, [key, hydration]) => {
    if (hydration.type === "date") return { ...acc, [key]: new Date(hydration.iso) }
    if (hydration.type === "nullish") return { ...acc, [key]: undefined }
    return acc
  }, value)

  return state
}

/**
 * Types
 */

export type Evolver<S extends object> = (path: Patch<S>) => void

export type Dehyrated<S> = {
  value: S
  types: Record<
    string,
    { type: "date"; iso: string } | { type: "nullish" } | { type: "infinity"; sign: 1 | -1 }
  >
}
