import millify from "millify"
import { useCountUp } from "use-count-up"

/**
 * CounterHighlight
 */
type Props = {
  counter: number
  description: string
  color?: string
  className?: ClassName
}
export const CounterHighlight: React.FC<Props> = ({
  counter: count,
  description,
  color = "hsl(var(--primary))",
  className,
}) => {
  const { value } = useCountUp({
    isCounting: React.useMemo(() => count > 0, [count]),
    end: count,
    duration: N.clamp(Math.round(count * 0.325), 0, 5),
    formatter: v => millify(v, { precision: 0 }),
  })
  return (
    <span
      className={cx(
        "relative z-20 flex flex-col justify-center items-center p-2 aspect-square dark:bg-black/50 bg-white border rounded-md shadow-sm text-muted-foreground transition-all",
        className
      )}
    >
      <span
        className="text-2xl @xs/card:text-3xl @sm/card:text-4xl @md/card:text-5xl @lg/card:text-6xl font-medium"
        style={{ color }}
      >
        {value}
      </span>
      <span className="text-[10px] @xs/card:text-xs @lg/card:text-sm">{description}</span>
    </span>
  )
}
