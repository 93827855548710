import { useLocation } from "wouter"

export const ScrollToTop: React.FC = () => {
  const [pathname] = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
