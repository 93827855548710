import Layout from "@/components/layout/frontend"
import { RouteAuthenticated } from "@/components/routes/authenticated"
import Index from "@/pages"
import Accessibility from "@/pages/accessibility"
import Contact from "@/pages/contact"
import DataProtection from "@/pages/data-protection"
import Kulturrallye from "@/pages/kulturrallye/Routes"
import LegalNotice from "@/pages/legal-notice"
import News from "@/pages/news"
import NewsArticle from "@/pages/news/article"
import NotFound from "@/pages/not-found"
import Offers from "@/pages/offers"
import OffersArticle from "@/pages/offers/article"
import Programs from "@/pages/programs"
import Projects from "@/pages/projects"
import ProjectsProject from "@/pages/projects/project"
import ServerError from "@/pages/server-error"
import VPDays from "@/pages/vp-days"
import { Redirect, Route, Switch } from "wouter"

/**
 * FrontendRoutes
 */
const FrontendRoutes: React.FC = () => {
  return (
    <Layout>
      <Switch>
        {/* index */}
        <Route path="/">
          <Index />
        </Route>
        {/* news */}
        <Route path="/news">
          <News />
        </Route>
        <Route path="/news/:id">{params => <NewsArticle {...params} />}</Route>
        {/* projects */}
        <RouteAuthenticated path="/projects" redirect="/">
          <Projects />
        </RouteAuthenticated>
        <RouteAuthenticated path="/projects/:id" redirect="/">
          {params => <ProjectsProject {...params} />}
        </RouteAuthenticated>
        {/* programs */}
        <RouteAuthenticated path="/programs" redirect="/">
          <Programs />
        </RouteAuthenticated>
        {/* offers */}
        <Route path="/offers">
          <Offers />
        </Route>
        <Route path="/offers/:id">{params => <OffersArticle {...params} />}</Route>
        {/* contact */}
        <Route path="/contact">
          <Contact />
        </Route>
        {/* kulturrallye */}
        <RouteAuthenticated path="/kulturrallye/:rest*" redirect="/">
          <Kulturrallye />
        </RouteAuthenticated>
        {/* vp-days */}
        <Route path="/vp-days">
          <VPDays />
        </Route>
        {/* data-protection */}
        <Route path="/data-protection">
          <DataProtection />
        </Route>
        {/* legal-notice */}
        <Route path="/legal-notice">
          <LegalNotice />
        </Route>
        {/* accessibility */}
        <Route path="/accessibility">
          <Accessibility />
        </Route>
        {/* server-error */}
        <Route path="/server-error">
          <ServerError />
        </Route>
        {/* not-found */}
        <Route path="/not-found">
          <NotFound />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Layout>
  )
}
export default FrontendRoutes
