import { cookieDeclarations } from "@/config/cookies"
import { ConsentDialog } from "./dialogs/consent"
import { ManagePreferences } from "./dialogs/manage-preferences"
import { createCookieProvider } from "./lib"

export const { Provider, useCookies } = createCookieProvider(cookieDeclarations)
export type CookieDeclaration = typeof cookieDeclarations
export type CookieApp = CookieDeclaration["apps"][number]["name"]
export type CookieCategory = CookieDeclaration["categories"][number]
export const CookiesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Provider>
      {children}
      <ConsentDialog />
      <ManagePreferences />
    </Provider>
  )
}
