import { decorateStore } from "@/fns/decorateStore"
import { create } from "zustand"
import { Program } from "./localizers"

/**
 * types
 */
export type ProgramsStoreState = {
  programs: ById<Program>
}

/**
 * initialState
 */
const initialState: ProgramsStoreState = {
  programs: {},
}

/**
 * Store
 */
export const programsStore = decorateStore(initialState, create, {})
export const useProgramsStore = programsStore.use
