import { confirmAlert } from "@/components/ui/confirm"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { service } from "@/services/frontend/service"
import { resetAllStoresAndReload } from "@/store"
import { match } from "ts-pattern"
import { FormAnnexe } from "./FormAnnexe"
import { FormDefinition } from "./FormDefinition"
import { FormInformation } from "./FormInformation"
import { Stepper, StepperContent } from "./Stepper"
import { useAnnexeForm } from "./useAnnexeForm"
import { basicDefinitionValues, useDefinitionForm } from "./useDefinitionForm"
import { basicInformationValues, useInformationForm } from "./useInformationForm"
import { useStepper } from "./useStepper"

/**
 * dictionary src/dictionaries/en/pages/projects.json
 */
const dictionary = createContextMapper("pages", "projects")

export const CreateDialog: React.FC<UseDialogProps<void>> = ({ item, onOpenChange, open }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog
      {...{
        open,
        onOpenChange: async () => {
          if (!(await confirmAlert({ dictionary: dictionary("confirm-abort-creation") }))) return
          onOpenChange(false)
        },
      }}
      title={_("create-dialog.title")}
      className="max-w-5xl"
    >
      {item !== false && <DialogForm {...{ item, onOpenChange }} />}
    </Dialog>
  )
}

const DialogForm: React.FC<UseDialogFormProps<void>> = ({ onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const _errors = useErrorsDictionary()
  const informationForm = useInformationForm()
  const definitionForm = useDefinitionForm()
  const annexeForm = useAnnexeForm()
  const stepperProps = useStepper(informationForm.isValid, definitionForm.isValid)
  const submit = async () => {
    const payload = {
      ...D.selectKeys(informationForm.values, basicInformationValues),
      ...D.selectKeys(definitionForm.values, basicDefinitionValues),
      image: D.selectKeys(informationForm.values.image, ["file", "delete"]),
      documents: annexeForm.values.documents,
      images: annexeForm.values.images,
    }
    return match(await service.projects.create(payload))
      .with({ error: false }, ({ data }) => {
        toast.success(_("create-dialog.success"))
        navigate(`/projects/${data.project.id}`)
        onOpenChange(false)
      })
      .otherwise(({ code, data }) =>
        match(code)
          .with("RELATED_FILES_UPLOAD_FAILURE", () => {
            toast.error(_("create-dialog.upload-error"))
            navigate(`/projects/${data?.project.id}`)
            onOpenChange(false)
          })
          .with("VALIDATION_FAILURE", _errors)
          .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
          .otherwise(code => toast.error(_errors(code)))
      )
  }
  return (
    <Stepper {...stepperProps} aria-label={_("form.tabs")}>
      <StepperContent value="information">
        <FormInformation form={informationForm} {...stepperProps} />
      </StepperContent>
      <StepperContent value="definition">
        <FormDefinition form={definitionForm} {...stepperProps} />
      </StepperContent>
      <StepperContent value="annexe">
        <FormAnnexe form={annexeForm} submit={submit} {...stepperProps} />
      </StepperContent>
    </Stepper>
  )
}
