import { decorateStore } from "@/fns/decorateStore"
import { create } from "zustand"
import { Workshop, WorkshopEvent, WorkshopReservation } from "./localizers"

/**
 * types
 */
export type WorkshopsStoreState = {
  workshops: ById<Workshop>
  events: Record<string, WorkshopEvent>
  reservations: ById<WorkshopReservation>
}

/**
 * initialState
 */
const initialState: WorkshopsStoreState = {
  workshops: {},
  events: {},
  reservations: {},
}

/**
 * Store
 */
export const workshopsStore = decorateStore(initialState, create, {})
export const useWorkshopsStore = workshopsStore.use
