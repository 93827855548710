import { Dialog } from "@/components/ui/dialog"
import Blockquote from "@tiptap/extension-blockquote"
import Bold from "@tiptap/extension-bold"
import BulletList from "@tiptap/extension-bullet-list"
import Document from "@tiptap/extension-document"
import Heading from "@tiptap/extension-heading"
import History from "@tiptap/extension-history"
import Italic from "@tiptap/extension-italic"
import Link from "@tiptap/extension-link"
import ListItem from "@tiptap/extension-list-item"
import OrderedList from "@tiptap/extension-ordered-list"
import Paragraph from "@tiptap/extension-paragraph"
import Strike from "@tiptap/extension-strike"
import Text from "@tiptap/extension-text"
import TextAlign from "@tiptap/extension-text-align"
import Underline from "@tiptap/extension-underline"
import {
  ChainedCommands,
  Editor,
  EditorContent,
  EditorOptions,
  Extensions,
  useEditor,
} from "@tiptap/react"
import {
  AlignLeftIcon,
  BoldIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  Heading4Icon,
  Heading5Icon,
  Heading6Icon,
  HeadingIcon,
  ItalicIcon,
  LinkIcon,
  ListIcon,
  ListOrderedIcon,
  PilcrowIcon,
  RedoIcon,
  StrikethroughIcon,
  TextQuoteIcon,
  UnderlineIcon,
  UndoIcon,
} from "lucide-react"
import {
  Form,
  FormFieldWrapper,
  FormFieldWrapperProps,
  FormInput,
  FormSubmit,
  FormSwitch,
  extractInputProps,
  extractWrapperProps,
  useFieldContext,
  useForm,
} from "."
import { proseStyle } from "../cms/frontend/proseStyle"
import { useBaseLayout } from "../layout/context"
import { Button, ButtonProps } from "../ui/button"
import { ContextMenu } from "../ui/context-menu"
import { DropdownMenu } from "../ui/dropdown-menu"
import { SrOnly } from "../ui/sr-only"
import { Tooltip } from "../ui/tooltip"

/**
 * dictionary src/dictionaries/en/components/form/form-tiptap.json
 */
const dictionary = createContextMapper("components", "form", "form-tiptap")

/**
 * FormTextarea
 */
type Props = FormFieldTiptapProps & FormFieldWrapperProps
export const FormTiptap: React.FC<Props> = ({ ...props }) => (
  <FormFieldWrapper {...extractWrapperProps(props)}>
    <FormFieldTiptap {...extractInputProps(props)} />
  </FormFieldWrapper>
)

type FormFieldTiptapProps = {
  onBlur?: EditorOptions["onBlur"]
  className?: ClassName
  placeholder?: string
  prose?: string
  format?: TiptapFormat[]
  options?: Partial<TiptapFormatOptions>
  autoFocus?: boolean
  setAutoFocus?: (focus: boolean) => void
}

/**
 * FormFieldTextarea
 */
const FormFieldTiptap: React.FC<FormFieldTiptapProps> = ({
  format,
  onBlur,
  options,
  prose = proseStyle,
  autoFocus,
  className,
}) => {
  const { value, setFieldValue, name, id } = useFieldContext<string>()
  const { isDashboard } = useBaseLayout()
  const editor = useEditor({
    extensions: extensionsTiptap(format, options ?? extensionsTiptapOptions),
    content: value,
    onUpdate: ({ editor }) => setFieldValue(editor.getHTML() ?? ""),
    onBlur: onBlurProps => {
      onBlur && onBlur(onBlurProps)
    },
    editorProps: {
      attributes: {
        id,
        name,
        class: cx(
          "w-full outline-none min-h-52 px-3 py-2 pt-12  max-w-full",
          isDashboard ? "rounded-md" : "rounded-[2px]",
          "box-border border border-input bg-background",
          "ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
          "disabled:cursor-not-allowed disabled:opacity-50",
          "scrollbar scrollbar-w-1 scrollbar-thumb-muted scrollbar-track-background scrollbar-thumb-rounded-full",
          prose,
          className
        ),
      },
    },
  })

  return (
    <FormFieldTiptapContextMenu format={format} editor={editor}>
      <div className="relative grid  min-h-52 ">
        <FormFieldTiptapNavbar format={format} editor={editor} />
        <EditorContent editor={editor} autoFocus={autoFocus} className={cx("grid")} />
      </div>
    </FormFieldTiptapContextMenu>
  )
}

/**
 * FormFieldTiptapContextMenu
 */
type FormFieldTiptapContextMenuProps = {
  editor: Editor | null
  format?: TiptapFormat[]
  children: React.ReactNode
}
const FormFieldTiptapContextMenu: React.FC<FormFieldTiptapContextMenuProps> = ({
  editor,
  format = extensionsTiptapFormat,
  children,
}) => {
  const { _ } = useDictionary(dictionary())
  const [linkDialogOpen, setLinkDialogOpen] = React.useState(false)

  if (!editor) return children
  const onClick = (fn: (cmd: ChainedCommands) => ChainedCommands) => () =>
    editor && fn(editor.chain().focus()).run()

  return (
    <>
      <ContextMenu>
        <ContextMenu.Trigger asChild>{children}</ContextMenu.Trigger>
        <ContextMenu.Content className="[&_svg]:h-4 [&_svg]:w-4">
          {A.includes(format, "history") && (
            <>
              <ContextMenu.Item onClick={onClick(cmd => cmd.undo())}>
                <UndoIcon aria-hidden strokeWidth={2.2} />
                {_("undo")}
              </ContextMenu.Item>
              <ContextMenu.Item onClick={onClick(cmd => cmd.redo())}>
                <RedoIcon aria-hidden strokeWidth={2.2} />
                {_("redo")}
              </ContextMenu.Item>
            </>
          )}
          {A.includes(format, "bold") && (
            <ContextMenu.Item onClick={onClick(cmd => cmd.toggleBold())}>
              <BoldIcon aria-hidden strokeWidth={editor.isActive("bold") ? 3 : 2.2} />
              {_("bold")}
            </ContextMenu.Item>
          )}
          {A.includes(format, "italic") && (
            <ContextMenu.Item onClick={onClick(cmd => cmd.toggleItalic())}>
              <ItalicIcon aria-hidden strokeWidth={editor.isActive("italic") ? 3 : 2.2} />
              {_("italic")}
            </ContextMenu.Item>
          )}
          {A.includes(format, "strike") && (
            <ContextMenu.Item onClick={onClick(cmd => cmd.toggleStrike())}>
              <StrikethroughIcon aria-hidden strokeWidth={editor.isActive("strike") ? 3 : 2.2} />
              {_("strikethrough")}
            </ContextMenu.Item>
          )}
          {A.includes(format, "underline") && (
            <ContextMenu.Item onClick={onClick(cmd => cmd.toggleUnderline())}>
              <UnderlineIcon aria-hidden strokeWidth={editor.isActive("underline") ? 3 : 2.2} />
              {_("underline")}
            </ContextMenu.Item>
          )}
          {A.includes(format, "link") && (
            <ContextMenu.Item
              onClick={() => setLinkDialogOpen(true)}
              disabled={
                !(isTextSelected(editor) || G.isNotNullable(editor.getAttributes("link").href))
              }
            >
              <LinkIcon
                aria-hidden
                strokeWidth={G.isNotNullable(editor.getAttributes("link").href) ? 3 : 2.2}
              />
              {_("link")}
            </ContextMenu.Item>
          )}

          {A.includes(format, "heading") && (
            <ContextMenu.Sub>
              <ContextMenu.Sub.Trigger>
                <HeadingIcon aria-hidden />
                {_("heading")}
              </ContextMenu.Sub.Trigger>
              <ContextMenu.Portal>
                <ContextMenu.Sub.Content>
                  <ContextMenu.Item
                    onClick={onClick(cmd => cmd.toggleHeading({ level: 1 }))}
                    active={editor.isActive("heading", { level: 1 })}
                  >
                    <Heading1Icon
                      aria-hidden
                      strokeWidth={editor.isActive("heading", { level: 1 }) ? 3 : 2.2}
                    />
                    {_("heading1")}
                  </ContextMenu.Item>
                  <ContextMenu.Item
                    onClick={onClick(cmd => cmd.toggleHeading({ level: 2 }))}
                    active={editor.isActive("heading", { level: 2 })}
                  >
                    <Heading2Icon
                      aria-hidden
                      strokeWidth={editor.isActive("heading", { level: 2 }) ? 3 : 2.2}
                    />
                    {_("heading2")}
                  </ContextMenu.Item>
                  <ContextMenu.Item
                    onClick={onClick(cmd => cmd.toggleHeading({ level: 3 }))}
                    active={editor.isActive("heading", { level: 3 })}
                  >
                    <Heading3Icon
                      aria-hidden
                      strokeWidth={editor.isActive("heading", { level: 3 }) ? 3 : 2.2}
                    />
                    {_("heading3")}
                  </ContextMenu.Item>
                  <ContextMenu.Item
                    onClick={onClick(cmd => cmd.toggleHeading({ level: 4 }))}
                    active={editor.isActive("heading", { level: 4 })}
                  >
                    <Heading4Icon
                      aria-hidden
                      strokeWidth={editor.isActive("heading", { level: 4 }) ? 3 : 2.2}
                    />
                    {_("heading4")}
                  </ContextMenu.Item>
                  <ContextMenu.Item
                    onClick={onClick(cmd => cmd.toggleHeading({ level: 5 }))}
                    active={editor.isActive("heading", { level: 5 })}
                  >
                    <Heading5Icon
                      aria-hidden
                      strokeWidth={editor.isActive("heading", { level: 5 }) ? 3 : 2.2}
                    />
                    {_("heading5")}
                  </ContextMenu.Item>
                  <ContextMenu.Item
                    onClick={onClick(cmd => cmd.toggleHeading({ level: 6 }))}
                    active={editor.isActive("heading", { level: 6 })}
                  >
                    <Heading6Icon
                      aria-hidden
                      strokeWidth={editor.isActive("heading", { level: 6 }) ? 3 : 2.2}
                    />
                    {_("heading6")}
                  </ContextMenu.Item>
                </ContextMenu.Sub.Content>
              </ContextMenu.Portal>
            </ContextMenu.Sub>
          )}
          {(A.includes(format, "paragraph") || A.includes(format, "blockquote")) && (
            <ContextMenu.Sub>
              <ContextMenu.Sub.Trigger>
                <AlignLeftIcon aria-hidden />
                {_("block")}
              </ContextMenu.Sub.Trigger>
              <ContextMenu.Portal>
                <ContextMenu.Sub.Content>
                  {A.includes(format, "paragraph") && (
                    <ContextMenu.Item
                      onClick={onClick(cmd => cmd.setParagraph())}
                      active={editor.isActive("paragraph")}
                    >
                      <PilcrowIcon
                        aria-hidden
                        strokeWidth={editor.isActive("paragraph") ? 3 : 2.2}
                      />
                      {_("paragraph")}
                    </ContextMenu.Item>
                  )}
                  <ContextMenu.Item
                    onClick={onClick(cmd => cmd.toggleBlockquote())}
                    active={editor.isActive("blockquote")}
                  >
                    <TextQuoteIcon
                      aria-hidden
                      strokeWidth={editor.isActive("blockquote") ? 3 : 2.2}
                    />
                    {_("blockquote")}
                  </ContextMenu.Item>
                </ContextMenu.Sub.Content>
              </ContextMenu.Portal>
            </ContextMenu.Sub>
          )}
          {A.includes(format, "list") && (
            <ContextMenu.Sub>
              <ContextMenu.Sub.Trigger>
                <ListIcon aria-hidden />
                {_("list")}
              </ContextMenu.Sub.Trigger>
              <ContextMenu.Portal>
                <ContextMenu.Sub.Content>
                  <ContextMenu.Item
                    onClick={onClick(cmd => cmd.toggleBulletList())}
                    active={editor.isActive("bulletList")}
                  >
                    <ListIcon aria-hidden strokeWidth={editor.isActive("bulletList") ? 3 : 2.2} />
                    {_("bullet-list")}
                  </ContextMenu.Item>
                  <ContextMenu.Item
                    onClick={onClick(cmd => cmd.toggleOrderedList())}
                    active={editor.isActive("orderedList")}
                  >
                    <ListOrderedIcon
                      aria-hidden
                      strokeWidth={editor.isActive("orderedList") ? 3 : 2.2}
                    />
                    {_("ordered-list")}
                  </ContextMenu.Item>
                </ContextMenu.Sub.Content>
              </ContextMenu.Portal>
            </ContextMenu.Sub>
          )}
        </ContextMenu.Content>
      </ContextMenu>
      <LinkDialog editor={editor} open={linkDialogOpen} onClose={() => setLinkDialogOpen(false)} />
    </>
  )
}
/**
 * FormFieldTiptapNavbar
 */
type FormFieldTiptapNavbarProps = {
  editor: Editor | null
  format?: TiptapFormat[]
  className?: ClassName
}
const FormFieldTiptapNavbar: React.FC<FormFieldTiptapNavbarProps> = ({
  editor,
  format = extensionsTiptapFormat,
  className,
}) => {
  const { _ } = useDictionary(dictionary())
  const { isDashboard } = useBaseLayout()

  const [linkDialogOpen, setLinkDialogOpen] = React.useState(false)

  if (!editor) return null
  const onClick = (fn: (cmd: ChainedCommands) => ChainedCommands) => () =>
    editor && fn(editor.chain().focus()).run()

  return (
    <nav
      className={cx(
        "@container/input absolute inset-x-0 top-0 z-10 flex border bg-background",
        isDashboard ? "rounded-t-md" : "rounded-t-[2px]",
        className
      )}
    >
      <ul className="flex items-center flex-wrap">
        {A.includes(format, "history") && (
          <>
            <li>
              <TiptapNavButton onClick={onClick(cmd => cmd.undo())} aria={_("undo")}>
                <UndoIcon aria-hidden strokeWidth={2.2} />
              </TiptapNavButton>
            </li>
            <li>
              <TiptapNavButton onClick={onClick(cmd => cmd.redo())} aria={_("redo")}>
                <RedoIcon aria-hidden strokeWidth={2.2} />
              </TiptapNavButton>
            </li>
            <Divider />
          </>
        )}
        {(A.includes(format, "heading") ||
          A.includes(format, "paragraph") ||
          A.includes(format, "blockquote") ||
          A.includes(format, "list")) && (
          <>
            <li>
              <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                  <Button variant="ghost">{_("format")}</Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content onCloseAutoFocus={e => e.preventDefault()}>
                  {A.includes(format, "heading") && (
                    <DropdownMenu.Sub>
                      <DropdownMenu.Sub.Trigger>
                        <HeadingIcon aria-hidden />
                        {_("heading")}
                      </DropdownMenu.Sub.Trigger>
                      <DropdownMenu.Portal>
                        <DropdownMenu.Sub.Content>
                          <DropdownMenu.Item
                            onClick={onClick(cmd => cmd.toggleHeading({ level: 1 }))}
                            active={editor.isActive("heading", { level: 1 })}
                          >
                            <Heading1Icon
                              aria-hidden
                              strokeWidth={editor.isActive("heading", { level: 1 }) ? 3 : 2.2}
                            />
                            {_("heading1")}
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={onClick(cmd => cmd.toggleHeading({ level: 2 }))}
                            active={editor.isActive("heading", { level: 2 })}
                          >
                            <Heading2Icon
                              aria-hidden
                              strokeWidth={editor.isActive("heading", { level: 2 }) ? 3 : 2.2}
                            />
                            {_("heading2")}
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={onClick(cmd => cmd.toggleHeading({ level: 3 }))}
                            active={editor.isActive("heading", { level: 3 })}
                          >
                            <Heading3Icon
                              aria-hidden
                              strokeWidth={editor.isActive("heading", { level: 3 }) ? 3 : 2.2}
                            />
                            {_("heading3")}
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={onClick(cmd => cmd.toggleHeading({ level: 4 }))}
                            active={editor.isActive("heading", { level: 4 })}
                          >
                            <Heading4Icon
                              aria-hidden
                              strokeWidth={editor.isActive("heading", { level: 4 }) ? 3 : 2.2}
                            />
                            {_("heading4")}
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={onClick(cmd => cmd.toggleHeading({ level: 5 }))}
                            active={editor.isActive("heading", { level: 5 })}
                          >
                            <Heading5Icon
                              aria-hidden
                              strokeWidth={editor.isActive("heading", { level: 5 }) ? 3 : 2.2}
                            />
                            {_("heading5")}
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={onClick(cmd => cmd.toggleHeading({ level: 6 }))}
                            active={editor.isActive("heading", { level: 6 })}
                          >
                            <Heading6Icon
                              aria-hidden
                              strokeWidth={editor.isActive("heading", { level: 6 }) ? 3 : 2.2}
                            />
                            {_("heading6")}
                          </DropdownMenu.Item>
                        </DropdownMenu.Sub.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Sub>
                  )}
                  {(A.includes(format, "paragraph") || A.includes(format, "blockquote")) && (
                    <DropdownMenu.Sub>
                      <DropdownMenu.Sub.Trigger>
                        <AlignLeftIcon aria-hidden />
                        {_("block")}
                      </DropdownMenu.Sub.Trigger>
                      <DropdownMenu.Portal>
                        <DropdownMenu.Sub.Content>
                          {A.includes(format, "paragraph") && (
                            <DropdownMenu.Item
                              onClick={onClick(cmd => cmd.setParagraph())}
                              active={editor.isActive("paragraph")}
                            >
                              <PilcrowIcon
                                aria-hidden
                                strokeWidth={editor.isActive("paragraph") ? 3 : 2.2}
                              />
                              {_("paragraph")}
                            </DropdownMenu.Item>
                          )}
                          <DropdownMenu.Item
                            onClick={onClick(cmd => cmd.toggleBlockquote())}
                            active={editor.isActive("blockquote")}
                          >
                            <TextQuoteIcon
                              aria-hidden
                              strokeWidth={editor.isActive("blockquote") ? 3 : 2.2}
                            />
                            {_("blockquote")}
                          </DropdownMenu.Item>
                        </DropdownMenu.Sub.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Sub>
                  )}
                  {A.includes(format, "list") && (
                    <DropdownMenu.Sub>
                      <DropdownMenu.Sub.Trigger>
                        <ListIcon aria-hidden />
                        {_("list")}
                      </DropdownMenu.Sub.Trigger>
                      <DropdownMenu.Portal>
                        <DropdownMenu.Sub.Content>
                          <DropdownMenu.Item
                            onClick={onClick(cmd => cmd.toggleBulletList())}
                            active={editor.isActive("bulletList")}
                          >
                            <ListIcon
                              aria-hidden
                              strokeWidth={editor.isActive("bulletList") ? 3 : 2.2}
                            />
                            {_("bullet-list")}
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            onClick={onClick(cmd => cmd.toggleOrderedList())}
                            active={editor.isActive("orderedList")}
                          >
                            <ListOrderedIcon
                              aria-hidden
                              strokeWidth={editor.isActive("orderedList") ? 3 : 2.2}
                            />
                            {_("ordered-list")}
                          </DropdownMenu.Item>
                        </DropdownMenu.Sub.Content>
                      </DropdownMenu.Portal>
                    </DropdownMenu.Sub>
                  )}
                </DropdownMenu.Content>
              </DropdownMenu>
            </li>
            <Divider />
          </>
        )}
        {A.includes(format, "bold") && (
          <li>
            <TiptapNavButton onClick={onClick(cmd => cmd.toggleBold())} aria={_("bold")}>
              <BoldIcon aria-hidden strokeWidth={editor.isActive("bold") ? 3 : 2.2} />
            </TiptapNavButton>
          </li>
        )}
        {A.includes(format, "italic") && (
          <li>
            <TiptapNavButton onClick={onClick(cmd => cmd.toggleItalic())} aria={_("italic")}>
              <ItalicIcon aria-hidden strokeWidth={editor.isActive("italic") ? 3 : 2.2} />
            </TiptapNavButton>
          </li>
        )}
        {A.includes(format, "strike") && (
          <li>
            <TiptapNavButton onClick={onClick(cmd => cmd.toggleStrike())} aria={_("strikethrough")}>
              <StrikethroughIcon aria-hidden strokeWidth={editor.isActive("strike") ? 3 : 2.2} />
            </TiptapNavButton>
          </li>
        )}
        {A.includes(format, "underline") && (
          <li>
            <TiptapNavButton onClick={onClick(cmd => cmd.toggleUnderline())} aria={_("underline")}>
              <UnderlineIcon aria-hidden strokeWidth={editor.isActive("underline") ? 3 : 2.2} />
            </TiptapNavButton>
          </li>
        )}
        <Divider />
        <li>
          <TiptapNavButton
            onClick={() => setLinkDialogOpen(true)}
            aria={_("link")}
            disabled={
              !(isTextSelected(editor) || G.isNotNullable(editor.getAttributes("link").href))
            }
          >
            <LinkIcon
              aria-hidden
              strokeWidth={G.isNotNullable(editor.getAttributes("link").href) ? 3 : 2.2}
            />
          </TiptapNavButton>
        </li>
      </ul>
      <LinkDialog editor={editor} open={linkDialogOpen} onClose={() => setLinkDialogOpen(false)} />
    </nav>
  )
}

/**
 * LinkDialog
 */
type LinkDialogProps = {
  editor: Editor
  open: boolean
  onClose: () => void
}
const LinkDialog: React.FC<LinkDialogProps> = ({ editor, onClose, open }) => {
  const { _ } = useDictionary(dictionary("link-dialog"))

  return (
    <Dialog open={open} onOpenChange={onClose} title={_("title")} className="max-w-sm">
      {open && <LinkDialogForm editor={editor} onClose={onClose} />}
    </Dialog>
  )
}

type LinkDialogFormProps = {
  editor: Editor
  onClose: () => void
}
const LinkDialogForm: React.FC<LinkDialogFormProps> = ({ editor, onClose }) => {
  const { _ } = useDictionary(dictionary("link-dialog"))
  const isUpdate = G.isNotNullable(editor.getAttributes("link").href)
  const form = useForm({
    values: React.useMemo(
      () => ({
        href: editor.getAttributes("link").href ?? "",
        external: G.isUndefined(editor.getAttributes("link").href)
          ? true
          : editor.getAttributes("link").target === "_blank",
      }),
      [editor]
    ),
    onSubmit: ({ values: { href, external } }) => {
      const command = editor.chain().focus().extendMarkRange("link")
      if (S.isEmpty(href)) command.unsetLink().run()
      else command.setLink({ href, target: external ? "_blank" : "" }).run()
      onClose()
    },
  })
  const deleteLink = () => {
    const command = editor.chain().focus().extendMarkRange("link")
    command.unsetLink().run()
    onClose()
  }

  return (
    <Form form={form} className="flex flex-col gap-6">
      <FormInput name="href" label={_("href-label")} />
      <FormSwitch name="external" label={_("external-label")} />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_("cancel")}</Button>
        </Dialog.Close>
        {isUpdate && (
          <Button variant="destructive" onClick={deleteLink}>
            {_("delete")}
          </Button>
        )}
        <FormSubmit>{_(isUpdate ? "update" : "create")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * Divider
 */
const Divider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  return <span className={cx("hidden @lg/input:flex border-l h-[30px] ", className)} aria-hidden />
}

/**
 * TiptapNavButton
 */
const TiptapNavButton: React.FC<Extend<ButtonProps, { aria: string }>> = ({
  aria,
  children,
  ...props
}) => {
  return (
    <Tooltip side={"bottom"} content={aria}>
      <Button icon variant="ghost" {...props}>
        {children}
        <SrOnly>{aria}</SrOnly>
      </Button>
    </Tooltip>
  )
}

/**
 * helpers
 */
const isTextSelected = (editor: Editor): boolean => {
  if (!editor || !editor.state) return false
  const { from, to } = editor.state.selection
  return from !== to
}
const extensionsTiptapOptions: TiptapFormatOptions = {
  openLinkOnClick: false,
  list: ["unordered", "ordered"],
  heading: [1, 2, 3, 4, 5, 6],
}
const extensionsTiptapFormat: TiptapFormat[] = [
  "bold",
  "italic",
  "underline",
  "strike",
  "link",
  "breakLink",
  "history",
  "paragraph",
  "heading",
  "list",
  "blockquote",
]
const extensionsTiptap = (
  format: TiptapFormat[] = extensionsTiptapFormat,
  options: Partial<TiptapFormatOptions> = {}
) => {
  const o = { ...options, ...extensionsTiptapOptions }
  const extensions: Extensions = [Paragraph, Document, Text]
  if (A.includes(format, "history")) extensions.push(History)
  // inline
  if (A.includes(format, "bold")) extensions.push(Bold)
  if (A.includes(format, "italic")) extensions.push(Italic)
  if (A.includes(format, "strike")) extensions.push(Strike)
  if (A.includes(format, "underline")) extensions.push(Underline)

  if (A.includes(format, "link"))
    extensions.push(
      Link.configure({
        openOnClick: o.openLinkOnClick,
        HTMLAttributes: {
          rel: "noopener noreferrer nofollow",
        },
      })
    )
  // block
  if (A.includes(format, "align"))
    extensions.push(
      TextAlign.configure({
        types: A.includes(format, "heading") ? ["heading", "paragraph"] : ["paragraph"],
      })
    )
  if (A.includes(format, "blockquote")) extensions.push(Blockquote)
  if (A.includes(format, "heading"))
    extensions.push(
      Heading.configure({
        levels: o.heading,
      })
    )
  if (A.includes(format, "list")) {
    extensions.push(ListItem)
    if (A.includes(o.list, "ordered")) extensions.push(OrderedList)
    if (A.includes(o.list, "unordered")) extensions.push(BulletList)
  }

  return extensions
}
// const proseStyle: ClassName = cx(
//   "prose max-w-none font-plus prose-heading hyphens-auto",
//   "prose-p:text-base prose-p:text-emperor prose-p:leading-relaxed prose-p:my-[15px] prose-p",
//   "prose-a:text-orient prose-a:underline",
//   "prose-strong:text-orient",
//   "prose-h3:text-xl",
//   "prose-ul:list-image-[url(@/assets/icons/rectangle.svg)] prose-ul:marker:w-[6px] prose-ul:marker:h-[6px] prose-ul:-ml-4 prose-ul",
//   "prose-li:marker:text-solitude prose-li:marker:text-base prose-li:marker:font-extrabold [.prose :where(li):where(strong)]:text-lg",
//   "prose-blockquote:border-l-tomato prose-blockquote:text-base prose-blockquote:text-emperor prose-blockquote:font-normal prose-blockquote:italic prose-blockquote"
// )

/**
 * types
 */
type TiptapFormat =
  | "bold"
  | "italic"
  | "underline"
  | "strike"
  | "link"
  | "breakLink"
  | "history"
  | "align"
  | "paragraph"
  | "heading"
  | "list"
  | "blockquote"
type TiptapFormatOptions = {
  openLinkOnClick: boolean
  list: ("unordered" | "ordered")[]
  heading: (1 | 2 | 3 | 4 | 5 | 6)[]
}
