import { ApiMediasFile } from "@/services/medias/schemas"
import { ApiPlace } from "@/services/places/schemas"

/**
 * extractFilesFromPlace
 */
export const extractFilesFromPlace = (place: ApiPlace): ApiMediasFile[] => [
  ...(G.isNotNullable(place.image) ? [place.image] : []),
  ...(G.isNotNullable(place.cover) ? [place.cover] : []),
]

/**
 * extractFilesFromPlaces
 */
export const extractFilesFromPlaces = (places: ApiPlace[]): ApiMediasFile[] =>
  A.reduce(places, [] as ApiMediasFile[], (list, place) => [
    ...list,
    ...extractFilesFromPlace(place),
  ])
