import { Button, buttonFocus } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { Popover } from "@/components/ui/popover"
import { SrOnly } from "@/components/ui/sr-only"
import { useLanguages } from "@/store/languages/hooks"
import { InfoIcon } from "lucide-react"
import { itemTypes, useCmsContext } from "../Context"
import Items from "../items"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")

/**
 * UpdateSeoDialogs
 */
export const CreateItemDialog: React.FC<UseDialogProps<number>> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary("create-item-dialog"))
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<number>> = ({ onOpenChange, item: order }) => {
  const { _ } = useDictionary(dictionary())
  const {
    actions: { createContentItem },
    id,
    type,
  } = useCmsContext()
  const languages = useLanguages()

  return (
    <div className="grid grid-cols-4 gap-4">
      {A.filterMap(D.keys(Items), item => {
        const Thumbnail = Items[item].ItemThumbnail
        const create = Items[item].create

        // limit by feature type or by id
        const limitTo = Items[item].limitTo
        if (G.isNotNullable(limitTo) && S.isNotEmpty(limitTo)) {
          if (A.includes(itemTypes, limitTo)) {
            if (limitTo !== type) return O.None
          } else if (limitTo !== id) return O.None
        }
        return (
          <div
            key={item}
            className={cx(
              "relative flex justify-center items-center aspect-square border border-input shadow-sm rounded-md"
            )}
          >
            <button
              className={cx("absolute inset-0 w-full h-full rounded-md", buttonFocus)}
              type="button"
              onClick={() => {
                onOpenChange(false)
                createContentItem(create(order + 1, languages, id))
              }}
            >
              <SrOnly>{_("create-item-dialog.create-item")}</SrOnly>
            </button>
            <Popover>
              <Popover.Trigger asChild>
                <Button variant="ghost" icon size="xs" className="absolute top-1 right-1">
                  <SrOnly>{_("create-item-dialog.info")}</SrOnly>
                  <InfoIcon size={16} aria-hidden />
                </Button>
              </Popover.Trigger>
              <Popover.Content>
                <h3 className="text-lg font-medium">{_(`content.items.${item}.title`)}</h3>
                <p className="text-sm">{_(`content.items.${item}.description`)}</p>
              </Popover.Content>
            </Popover>
            <div className="flex flex-col justify-center items-center w-full h-full p-2 gap-4 pointer-events-none text-center">
              <Thumbnail />
            </div>
          </div>
        )
      })}
    </div>
  )
}
