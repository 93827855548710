import {
  FormFieldWrapper,
  FormFieldWrapperProps,
  extractInputProps,
  extractWrapperProps,
  useFieldContext,
} from "."
import { Select } from "../ui/select"

/**
 * FormSelect
 */
type Props = SelectInputProps & FormFieldWrapperProps
export const FormSelect: React.FC<Props> = ({ options, ...props }) => (
  <FormFieldWrapper {...extractWrapperProps(props)}>
    <SelectInput {...extractInputProps(props)} options={options} />
  </FormFieldWrapper>
)

type SelectInputProps = {
  placeholder?: string
  options: FormSelectOption[]
  required?: boolean
  className?: ClassName
  disabled?: boolean
  onValueChange?: (value: string) => void
}
const SelectInput: React.FC<SelectInputProps> = ({
  className,
  placeholder,
  disabled,
  onValueChange,
  options,
}) => {
  const { value, setFieldValue, disabled: ctxDisabled } = useFieldContext<string>()

  return (
    <Select
      onValueChange={v => {
        setFieldValue(v)
        if (onValueChange) onValueChange(v)
      }}
      value={value}
      defaultValue={value}
      disabled={ctxDisabled || disabled}
    >
      <Select.Trigger className={cx("w-full", className)}>
        <Select.Value placeholder={placeholder} />
      </Select.Trigger>
      <Select.Content>
        {A.mapWithIndex(options, (index, { label, ...option }) => (
          <Select.Item key={index} {...option}>
            {label}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  )
}

/**
 * helpers
 */
export const toSelectOptions = (options: string[], _: (t: string) => string, prefix: string) =>
  A.map(options, o => ({ label: _(`${prefix}-${o}`), value: o }))

/**
 * types
 */
export type FormSelectOption = React.ComponentPropsWithoutRef<typeof Select.Item> & {
  label: React.ReactNode
}
