/// <reference types="vite-plugin-svgr/client" />
import { Button, buttonVariants } from "@/components/frontend/button"
import { MobilePopover } from "@/components/frontend/mobile-popover"
import { useResponsive } from "@/hooks/useResponsive"
import { CaretDown } from "@phosphor-icons/react"
import { dictionary } from "."

/**
 * TableOfContent
 */
type TableOfContentProps = {
  active: Option<string>
  classes: string[]
  setActive: (name: Option<string>) => void
}
export const TableOfContent: React.FC<TableOfContentProps> = ({ active, classes, setActive }) => {
  const { _ } = useDictionary(dictionary())
  const [open, setOpen] = React.useState(false)
  const media = useResponsive()
  React.useEffect(() => {
    if (media.min("lg") && open) setOpen(false)
  }, [media, open])

  return media.min("lg") ? (
    <div className="sticky top-1 pb-10">
      <ul className="flex flex-col gap-2">
        {A.mapWithIndex(classes, (index, item) => (
          <ListItem key={index} onClick={() => setActive(item)} active={active === item}>
            {item}
          </ListItem>
        ))}
      </ul>
    </div>
  ) : (
    <>
      <MobilePopover open={open} onOpenChange={state => setOpen(state)} modal={false}>
        <MobilePopover.Trigger
          className={cx(buttonVariants({ variant: "dark" }), "relative w-full z-50")}
        >
          {_("table-of-content")}
          <CaretDown className={cx("h-5 transition-transform", open ? "rotate-180" : "rotate-0")} />
        </MobilePopover.Trigger>
        <MobilePopover.Content className="p-0">
          <ul className="flex flex-col">
            {A.mapWithIndex(classes, (index, item) => (
              <ListItem
                key={index}
                onClick={() => {
                  setActive(item)
                  setOpen(false)
                }}
                active={active === item}
              >
                {item}
              </ListItem>
            ))}
          </ul>
        </MobilePopover.Content>
      </MobilePopover>
    </>
  )
}

/**
 * ListItem
 */
const ListItem: React.FC<
  {
    onClick: () => void
    on?: boolean
    active: boolean
  } & React.HTMLAttributes<HTMLElement>
> = ({ active, className, onClick, on = true, children, ...props }) => {
  return on ? (
    <li className={className} {...props}>
      <Button
        onClick={onClick}
        variant="secondary"
        className={cx(
          "w-full justify-start",
          active && "bg-bluewhale hover:bg-bluewhale text-white pointer-events-none"
        )}
      >
        {children}
      </Button>
    </li>
  ) : null
}
