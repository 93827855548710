import { z } from "zod"
import { AuthErrorCode, NoContent, api } from "../api"
import { apiExtraField } from "../commons/schemas"
import { ApiPlace, apiMapName, apiPlace } from "./schemas"

/**
 * schemas payload
 */
const createPayload = z.object({
  name: z.string(),
  published: z.boolean().optional(),
  description: z.string().optional(),
  address: z.string().optional(),
  website: z.string().optional(),
  phones: apiExtraField.array().optional(),
  emails: apiExtraField.array().optional(),
  image: z.string().uuid().optional().nullable(),
  cover: z.string().uuid().optional().nullable(),
  map: apiMapName.optional().default("center"),
  coordinate: z.tuple([z.number(), z.number()]).optional().default([0, 0]),
})
const updatePayload = z.object({
  name: z.string().optional(),
  published: z.boolean().optional(),
  description: z.string().optional(),
  address: z.string().optional(),
  website: z.string().optional(),
  phones: apiExtraField.array().optional(),
  emails: apiExtraField.array().optional(),
  image: z.string().uuid().optional().nullable(),
  cover: z.string().uuid().optional().nullable(),
  map: apiMapName.optional(),
  coordinate: z.tuple([z.number(), z.number()]).optional(),
})

export type Payload = {
  create: z.infer<typeof createPayload>
  update: z.infer<typeof updatePayload>
}

/**
 * service
 */
export const service = {
  index: async () => {
    type RSuccess = { places: ApiPlace[] }
    type RError = AuthErrorCode
    const { success, data } = await api.get<RSuccess, RError>("places")
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { places: A.map(data.places, apiPlace.parse) }, error: false } as const
  },
  create: async (payload: Payload["create"]) => {
    type RSuccess = { place: ApiPlace }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.post<RSuccess, RError>("places", {
      data: createPayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { place: apiPlace.parse(data.place) }, error: false } as const
  },
  read: async (id: string) => {
    type RSuccess = { place: ApiPlace }
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.get<RSuccess, RError>(`places/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { place: apiPlace.parse(data.place) }, error: false } as const
  },
  update: async (id: string, payload: Payload["update"]) => {
    type RSuccess = { place: ApiPlace }
    type RError = AuthErrorCode<"VALIDATION_FAILURE">
    const { success, data } = await api.put<RSuccess, RError>(`places/${id}`, {
      data: updatePayload.parse(payload),
    })
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: { place: apiPlace.parse(data.place) }, error: false } as const
  },
  delete: async (id: string) => {
    type RSuccess = NoContent
    type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
    const { success, data } = await api.delete<RSuccess, RError>(`places/${id}`)
    if (!success) return { data: null, error: true, code: data.code } as const
    return { data: {}, error: false } as const
  },
}
