import {
  Form,
  FormAssertive,
  FormExtraFields,
  FormFiles,
  FormImage,
  FormImages,
  FormInput,
  FormKeywords,
  FormSelect,
  FormSubmit,
  FormTextarea,
  FormTiptap,
  initialFiles,
  useForm,
  validator,
} from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { Tabs } from "@/components/ui/tabs"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { updateProject } from "@/store/projects/actions"
import {
  useProjectCategoryOptions,
  useProjectLanguageOptions,
  useProjectOrientationOptions,
} from "@/store/projects/hooks"
import { Project } from "@/store/projects/localizers"
import { match } from "ts-pattern"

/**
 * dictionary src/dictionaries/fr/pages/dashboard/projects.json
 * dictionary src/dictionaries/de/pages/dashboard/projects.json
 */
const dictionary = createContextMapper("pages", "dashboard", "projects")

export const EditDialog: React.FC<UseDialogProps<Project>> = ({ item, onOpenChange, open }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <Dialog {...{ open, onOpenChange }} title={_("edit-dialog.title")} className="max-w-2xl">
      {item !== false && <DialogForm {...{ item, onOpenChange }} />}
    </Dialog>
  )
}

const DialogForm: React.FC<UseDialogFormProps<Project>> = ({ item, onOpenChange }) => {
  const { _ } = useDictionary(dictionary())
  const { _: _errors } = useDictionary("errors")

  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(item, basicFields),
      image: { url: item.image?.url ?? "", file: null, delete: false },
      documents: initialFiles(D.values(item.documents)),
      images: initialFiles(D.values(item.images)),
    })),
    validate: validator({
      //
    }),
    onSubmit: async ({ values }) => {
      const payload = {
        ...D.selectKeys(values, basicFields),
        image: D.selectKeys(values.image, ["file", "delete"]),
        documents: values.documents,
        images: values.images,
      }
      const response = await updateProject(item.id, payload)
      return match(response)
        .with({ error: false }, () => {
          toast.success(_("edit-dialog.success"))
          onOpenChange(false)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .with("FETCH_ERROR", code => toast.error(_errors(code)))
        )
    },
  })

  const languageOptions = useProjectLanguageOptions()
  const categoryOptions = useProjectCategoryOptions()
  const orientationOptions = useProjectOrientationOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <Tabs defaultValue="information" className="w-full">
        <Tabs.List className="max-md:flex max-md:flex-col max-md:items-stretch max-md:gap-2 max-md:h-auto md:grid md:grid-cols-3 w-full">
          <Tabs.Trigger value="information">{_("form.tab-information")}</Tabs.Trigger>
          <Tabs.Trigger value="definition">{_("form.tab-definition")}</Tabs.Trigger>
          <Tabs.Trigger value="annexe">{_("form.tab-annexe")}</Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="information" className="flex flex-col gap-6 p-4 border">
          <FormInput
            label={_("form.name-label")}
            name="name"
            maxLength={100}
            placeholder={_("form.name-placeholder")}
          />
          <FormSelect label={_("language")} name="language" options={languageOptions} />
          <FormSelect label={_("category")} name="category" options={categoryOptions} />
          <FormSelect label={_("orientation")} name="orientation" options={orientationOptions} />
          <FormImage label={_("form.image-label")} name="image" ratio="aspect-[16/5]" />
          <FormExtraFields
            label={_("form.partner-label")}
            name="partners"
            canAdd={false}
            canRemove={false}
          />
          <FormExtraFields
            label={_("form.class-label")}
            name="classes"
            canAdd={false}
            canRemove={false}
          />
          <FormTextarea
            label={_("form.description-label")}
            name="description"
            maxLength={255}
            rows={6}
          />
        </Tabs.Content>
        <Tabs.Content value="definition" className="flex flex-col gap-6 p-4 border">
          <FormExtraFields
            label={_("form.skills-label")}
            name="skills"
            translate={c => _(`form.skills-${c}`)}
          />
          <FormTiptap label={_("form.objective-label")} name="objective" />
          <FormKeywords label={_("form.materials-label")} name="materials" />
          <FormTiptap label={_("form.preparation-label")} name="preparation" />
          <FormTiptap label={_("form.follow-label")} name="follow" />
          <FormTiptap label={_("form.values-label")} name="values" />
        </Tabs.Content>
        <Tabs.Content value="annexe" className="flex flex-col gap-6 p-4 border">
          <FormFiles label={_("form.documents-label")} name="documents" />
          <FormImages label={_("form.images-label")} name="images" />
        </Tabs.Content>
      </Tabs>
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button variant="secondary">{_("form.back")}</Button>
        </Dialog.Close>
        <FormSubmit>{_("edit-dialog.update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

const basicFields = [
  "name",
  "language",
  "category",
  "orientation",
  "partners",
  "classes",
  "description",
  "skills",
  "objective",
  "materials",
  "preparation",
  "follow",
  "values",
] as const
