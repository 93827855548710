/// <reference types="vite-plugin-svgr/client" />
import Dance from "@/assets/icons/dance.svg?react"
import ProjectPicture from "@/assets/images/project.svg?react"
import { Button } from "@/components/frontend/button"
import { FooterPicture } from "@/components/frontend/drawing"
import { ImageHeader } from "@/components/frontend/image-header"
import { Container } from "@/components/layout/frontend/container"
import { useLayoutContext } from "@/components/layout/frontend/context"
import { Wrapper } from "@/components/layout/frontend/wrapper"
import { useDialog } from "@/components/ui/hooks/useDialog"
import globalConfig from "@/config/global"
import { stripeHtml } from "@/fns/String"
import { usePromise } from "@/hooks/usePromise"
import { service } from "@/services/frontend/service"
import { useAuth } from "@/store/auth/hooks"
import { Project, localizeProject } from "@/store/frontend/localizers"
import { useTracking } from "@/store/trackings/hooks"
import {
  CheckCircle,
  ClockCountdown,
  MaskHappy,
  MusicNotes,
  PencilSimpleLine,
} from "@phosphor-icons/react"
import { useTitle } from "hoofd"
import { match } from "ts-pattern"
import { Content } from "./Content"
import { TableOfContents } from "./TableOfContents"
import { UpdateDialog } from "./UpdateDialog"

/**
 * dictionary src/dictionaries/en/pages/projects.json
 */
export const dictionary = createContextMapper("pages", "projects")

/**
 * Page: ProjectsProject
 */
type PageProps = { id: string }
const ProjectsProject: React.FC<PageProps> = ({ id }) => {
  const { loading } = useLayoutContext()
  const [{ project }, inProgress] = usePromise(
    async () => {
      if (G.isNullable(id)) return navigate("/not-found") as never
      loading.setTrue()
      const response = await service.projects.read(id)
      loading.setFalse()
      return match(response)
        .with({ error: false }, ({ data }) => {
          const project = localizeProject(data.project)
          return {
            error: false,
            project,
          } as const
        })
        .otherwise(({ code }) => {
          match(code)
            .with("RESOURCE_NOT_FOUND", () => navigate("/not-found"))
            .otherwise(code => navigate(`/server-error?code=${code}`))
          return initialResponse
        })
    },
    [],
    initialResponse
  )

  // SEO & Tracking
  const title = G.isNotNullable(project)
    ? `${globalConfig.siteName} - ${project.name}`
    : globalConfig.siteName
  useTitle(title)
  useTracking(project?.trackingId)
  return inProgress || G.isNullable(project) ? null : <ProjectRender project={project} />
}
export default ProjectsProject
const initialResponse = {
  error: true,
  code: "FETCH_ERROR",
  project: null,
} as const

/**
 * ProjectRender
 */
const ProjectRender: React.FC<{ project: Project }> = ({ project }) => {
  const { _ } = useDictionary(dictionary())
  const auth = useAuth()

  const isAuthor = React.useMemo(() => {
    if (G.isNullable(auth.user) || G.isNullable(project.createdBy)) return false
    return auth.user.id === project.createdBy.id
  }, [auth.user, project])
  const { setItem: update, ...updateProps } = useDialog<Project>()
  const { name, category, validated } = project
  return (
    <>
      <Wrapper>
        <Container x="sm">
          <ImageHeader background={project.image?.url} backTo="/projects">
            {isAuthor && (
              <Button
                variant="dark"
                className="absolute top-0 right-0"
                onClick={() => update(project)}
              >
                <PencilSimpleLine size={16} aria-hidden />
                {_("edit-project")}
              </Button>
            )}
            <ImageHeader.Main>
              <ImageHeader.Content>
                <div className="flex justify-center items-center bg-tomato rounded-full w-14 h-14 [&>svg]:h-[28px]">
                  {category === "dance" && <Dance />}
                  {category === "music" && <MusicNotes />}
                  {category === "theater" && <MaskHappy />}
                  <span className="sr-only">{_(`category-${category}`)}</span>
                </div>
                <ImageHeader.Title>{name}</ImageHeader.Title>
              </ImageHeader.Content>
            </ImageHeader.Main>
          </ImageHeader>
          {isAuthor && (
            <div className="lg:relative w-full">
              <span
                className={cx(
                  "lg:absolute -top-[60px] right-0 lg:left-auto w-full lg:w-auto",
                  "inline-flex justify-center items-center h-[50px] px-5 gap-x-4",
                  "bg-aquahaze",
                  "[&>svg]:h-5",
                  "text-bluewhale uppercase text-sm font-semibold tracking-wider"
                )}
              >
                {!validated ? (
                  <>
                    <CheckCircle aria-hidden />
                    {_("state-validated")}
                  </>
                ) : (
                  <>
                    <ClockCountdown aria-hidden />
                    {_("state-review")}
                  </>
                )}
              </span>
            </div>
          )}
          <div className="lg:grid lg:items-start grid-cols-[325px_1fr] gap-10">
            <TableOfContents project={project} />
            <Content project={project} />
          </div>
        </Container>
      </Wrapper>
      <UpdateDialog {...updateProps} />
      <FooterPicture image={<ProjectPicture />} />
    </>
  )
}

/**
 * helpers
 */
export const htmlIsNotEmpty = (contentHTML: string) => S.isNotEmpty(stripeHtml(contentHTML))
