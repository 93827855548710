import { ValidContextAndProps, useDictionary } from "@/dictionaries/hooks"
import { Layers, Search } from "lucide-react"
import { Fragment } from "react"
import { Button } from "../ui/button"

/**
 * helpers
 */
type EmptyCollectionDictionary = {
  "no-item-title": string
  "no-item-content": string
  "no-result-title": string
  "no-result-content": string
}
const EmptySafeDictionary = <T extends string>(
  context: ValidContextAndProps<T, EmptyCollectionDictionary>
) => context

/**
 * CollectionEmpty
 */
type EmptyProps = {
  items: unknown[]
  results: unknown[]
  clear?: () => void
  create?: () => void
}
const EmptyRoot: React.FC<EmptyProps> = ({ items, results, clear, create }) => {
  const { _ } = useDictionary(contextMapper("components", "layout", "empty"))

  return A.isEmpty(results) || A.isEmpty(items) ? (
    <div className="flex flex-col items-center justify-center flex-grow w-full max-w-screen-2xl mx-auto px-4 py-8 bg-card text-card-foreground shadow-md">
      <div className="flex flex-col items-center justify-center gap-2">
        {A.isEmpty(items) ? (
          <Layers aria-hidden className="text-muted-foreground mb-4" size={48} strokeWidth={1} />
        ) : (
          <Search aria-hidden className="text-muted-foreground mb-4" size={48} strokeWidth={1} />
        )}
        <h2 className="text-2xl font-semibold">
          {_(A.isEmpty(items) ? "no-item-title" : "no-result-title")}
        </h2>
        <p className="text-sm text-muted-foreground">
          <ReplaceActions
            content={_(
              A.isEmpty(items)
                ? create
                  ? "no-item-content-create"
                  : "no-item-content"
                : clear
                ? "no-result-content-clear"
                : "no-result-content"
            )}
            actions={{ clear: clear ?? F.ignore, create: create ?? F.ignore }}
          />
        </p>
      </div>
    </div>
  ) : null
}

const ReplaceActions: React.FC<{ content: string; actions: Record<string, typeof F.ignore> }> = ({
  content,
  actions,
}) => {
  const parts = React.useMemo(
    () => S.splitByRe(content, /\[([^\]]+)\]\(action:([^)]+)\)/g),
    [content]
  )
  return (
    <>
      {A.mapWithIndex(parts, (index, part) => {
        if (index % 3 === 0) return <Fragment key={index}>{part}</Fragment>
        if (!((index - 1) % 3 === 0)) return <Fragment key={index} />
        const action = D.get(actions, A.get(parts, index + 1) ?? "")
        if (G.isNullable(action)) return <Fragment key={index}>{part}</Fragment>
        return (
          <Button variant="link" size="link" key={index} onClick={action}>
            {part}
          </Button>
        )
      })}
    </>
  )
}

/**
 * exports
 */
export const Empty = Object.assign(EmptyRoot, {
  dictionary: EmptySafeDictionary,
})
