import notFoundImage from "@/assets/images/not-found.png"
import { Button } from "@/components/frontend/button"
import { Container } from "@/components/layout/frontend/container"
import { Wrapper } from "@/components/layout/frontend/wrapper"
import { Image } from "@/components/ui/image"

/**
 * dictionary src/dictionaries/en/pages/not-found.json
 */
const dictionary = createContextMapper("pages", "not-found")

/**
 * Page: NotFound
 */
const NotFound: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  return (
    <Wrapper>
      <Container x="md">
        <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
          <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
            <div className="relative">
              <div className="absolute">
                <div className="">
                  <h1 className="my-2 text-gray-800 font-bold text-2xl">{_("title")}</h1>
                  <p className="my-2 text-gray-800">{_("content")}</p>
                  <Button type="link" href="/" variant="dark" className="sm:w-full lg:w-auto">
                    {_("button")}
                  </Button>
                </div>
              </div>
              <div
                aria-hidden
                className="text-[150px]/[150px] sm:text-[200px]/[200px] xl:text-[300px]/[200px] font-extrabold text-[#f3f6f9]"
              >
                404
              </div>
            </div>
          </div>
          <div>
            <Image src={notFoundImage} />
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}
export default NotFound
