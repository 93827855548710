import { byId } from "@/fns/byId"
import * as Users from "@/services/users/service"
import { match } from "ts-pattern"
import { usersStore } from "."
import { localizeUser } from "./localizers"

/**
 * getUsers
 */
export const getUsers = async () =>
  match(await Users.service.index())
    .with({ error: false }, ({ data }) => {
      usersStore.evolve({ users: byId(data.users, localizeUser) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getUser
 */
export const getUser = async (id: string) =>
  match(await Users.service.read(id))
    .with({ error: false }, ({ data }) => {
      usersStore.evolve({ users: D.set(data.user.id, localizeUser(data.user)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createUser
 */
export const createUser = async (payload: Users.Payload["create"]) =>
  match(await Users.service.create(payload))
    .with({ error: false }, ({ data }) => {
      usersStore.evolve({ users: D.set(data.user.id, localizeUser(data.user)) })
      return { error: false, id: data.user.id } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateUser
 */
export const updateUser = async (id: string, payload: Users.Payload["update"]) =>
  match(await Users.service.update(id, payload))
    .with({ error: false }, ({ data }) => {
      usersStore.evolve({ users: D.set(data.user.id, localizeUser(data.user)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deleteUser
 */
export const deleteUser = async (id: string) =>
  match(await Users.service.delete(id))
    .with({ error: false }, () => {
      usersStore.evolve({ users: D.deleteKey(id) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updateProfile
 */
export const updateProfile = async (id: string, payload: Users.Payload["profile"]["update"]) =>
  match(await Users.service.profile.update(id, payload))
    .with({ error: false }, ({ data }) => {
      usersStore.evolve({ users: D.set(data.user.id, localizeUser(data.user)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getLastestUsers
 */
export const getLastestUsers = async () =>
  match(await Users.service.lastest())
    .with({ error: false }, ({ data }) => {
      const usersById = byId(data.users, localizeUser)
      usersStore.evolve({ users: D.merge(usersById) })
      return { error: false, users: D.values(usersById) } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))
