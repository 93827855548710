import { useAuthStore } from "@/store/auth"
import { DefaultParams, Path, Redirect, Route, RouteProps } from "wouter"

/**
 * RouteAuthenticated
 */
export const RouteAuthenticated = <
  T extends DefaultParams | undefined = undefined,
  RoutePath extends Path = Path
>({
  redirect = "/sign-in",
  children,
  ...props
}: RouteProps<T, RoutePath> & { redirect?: string }) => {
  const { authenticated } = useAuthStore()
  return (
    <>
      {authenticated ? (
        <Route {...props}>{G.isFunction(children) ? params => children(params) : children}</Route>
      ) : (
        <Route {...props}>
          <Redirect to={redirect} />
        </Route>
      )}
    </>
  )
}
