import { buttonVariants } from "@/components/frontend/button"
import * as Tabs from "@radix-ui/react-tabs"
import { StepsProps } from "./useStepper"

/**
 * Stepper
 */
type StepperProps = StepsProps & {
  "aria-label": string
  children: React.ReactNode
}
export const Stepper: React.FC<StepperProps> = ({ steps, setStep, step, children, ...props }) => {
  return (
    <Tabs.Root className="w-full" value={step} onValueChange={setStep}>
      <Tabs.List
        className="grid md:grid-cols-3 items-center md:justify-center border-b border-mercury py-2.5"
        {...props}
      >
        {A.mapWithIndex(steps, (index, { value, disabled, name }) => (
          <Tabs.Trigger
            key={value}
            value={value}
            disabled={disabled}
            className={cx(
              buttonVariants({ variant: "tab", case: "uppercase" }),
              "inline-flex max-md:data-[state=inactive]:hidden items-center justify-center whitespace-nowrap disabled:opacity-80 disabled:bg-transparent"
            )}
          >
            {name}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      {children}
    </Tabs.Root>
  )
}

/**
 * StepperContent
 */
type StepperContentProps = {
  value: string
  children: React.ReactNode
}
export const StepperContent: React.FC<StepperContentProps> = ({ value, children }) => {
  return (
    <Tabs.Content
      className="pt-10 ring-offset-background focus-visible:outline-none data-[state=inactive]:hidden"
      value={value}
    >
      {children}
    </Tabs.Content>
  )
}
