/**
 * Section
 */

export const Section: React.FC<SectionProps> = ({ start, children, ...props }) => {
  const parentSection = React.useContext(SectionContext)

  return (
    <SectionContext.Provider
      value={{ count: typeof start === "number" ? start : parentSection.count + 1 }}
    >
      {props.asProvider ? children : <section {...props}>{children}</section>}
    </SectionContext.Provider>
  )
}

const SectionContext = React.createContext<{ count: number }>({ count: 0 })

/**
 * H
 */

export const H: React.FC<React.ComponentProps<"h1"> & { as?: string }> = props => {
  const section = React.useContext(SectionContext)

  React.useEffect(() => {
    if (section.count === 0) console.warn("Used <H/> outside <Section/>")
    if (section.count > 6) console.warn(`<Section/> nesting limit reached (${section.count})`)
  }, [section.count])

  return React.createElement(
    props.as ? props.as : getHeadingByIndex(section.count ? section.count : 1),
    { ...props }
  )
}

const getHeadingByIndex = (i: number) => {
  if (i <= 1) return "h1"
  if (i === 2) return "h2"
  if (i === 3) return "h3"
  if (i === 4) return "h4"
  if (i === 5) return "h5"
  return "h6"
}

/**
 * Types
 */

type SectionProps =
  | (React.ComponentProps<typeof React.Fragment> & { asProvider: true; start?: Option<number> })
  | (React.ComponentProps<"section"> & {
      asProvider?: false
      children: React.ReactNode
      start?: Option<number>
    })
