import { Card, Fields, Menu, Row } from "@/components/collection"
import { Badge } from "@/components/ui/badge"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { useSelectItem } from "@/hooks/useSelect"
import { Program } from "@/store/programs/localizers"
import {
  Archive,
  Barcode,
  Books,
  FileText,
  Globe,
  GraduationCap,
  TreeStructure,
} from "@phosphor-icons/react"
import { CalendarPlus, PackageOpen } from "lucide-react"
import millify from "millify"
import { Context, ContextMenu, usePageContext } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/programs.json
 */
const dictionary = createContextMapper("pages", "dashboard", "programs")

/**
 * Item
 */
type Props = { program: Program }
export const Item: React.FC<Props> = props => {
  const { program } = props
  const { selectItemProps } = useSelectItem(Context, program.id, () =>
    navigate(`/dashboard/programs/${program.id}`)
  )
  const { view } = usePageContext()
  return (
    <Menu type="context-menu" menu={<ContextMenu {...props} />} asChild>
      <div className="grid" {...selectItemProps}>
        {view === "grid" ? <ItemCard {...props} /> : <ItemRow {...props} />}
      </div>
    </Menu>
  )
}

/**
 * ItemCard
 */
const ItemCard: React.FC<Props> = ({ program }) => {
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, program.id)
  const format = useDateFnsLocaleFormat()
  return (
    <Card selected={isSelected}>
      <Card.Header>
        <Card.Title>{program.name}</Card.Title>
        <Card.Description>{program.class}</Card.Description>
        <Card.Menu menu={<ContextMenu program={program} />} />
      </Card.Header>
      <Card.Content>
        <Fields divider>
          <Fields.Item
            name={_("language")}
            icon={<Globe aria-hidden />}
            value={_(`language-${program.language as "en"}`)}
            stretch
          />
          <Fields.Item
            name={_("code")}
            icon={<Barcode aria-hidden />}
            value={program.code}
            stretch
          />
          <Fields.Item
            name={_("created-at")}
            icon={<CalendarPlus aria-hidden />}
            value={format(program.createdAt, "PPp")}
            stretch
          />
        </Fields>
        <div className="grid grid-cols-[1fr_1fr_1fr_auto] gap-4 mt-2">
          <Badge variant="outline" className="gap-2">
            <FileText aria-label={_("programs")} size={12} />
            {millify(program.programsVisits)}
          </Badge>
          <Badge variant="outline" className="gap-2">
            <Books aria-label={_("books")} size={12} />
            {millify(program.booksVisits)}
          </Badge>
          <Badge variant="outline" className="gap-2">
            <TreeStructure aria-label={_("pearltrees")} size={12} />
            {millify(program.pearltreesVisits)}
          </Badge>
          <Row.Icon tooltip={_(`status-${program.archived ? "archived" : "available"}`)}>
            {program.archived ? <Archive /> : <PackageOpen />}
          </Row.Icon>
        </div>
      </Card.Content>
    </Card>
  )
}

/**
 * ItemRow
 */
const ItemRow: React.FC<Props> = ({ program }) => {
  const { _ } = useDictionary(dictionary())
  const { isSelected } = useSelectItem(Context, program.id)
  const format = useDateFnsLocaleFormat()
  return (
    <Row selected={isSelected}>
      <Row.Image className="uppercase text-xs">{program.language}</Row.Image>
      <Row.Header>
        <Row.Title>{program.name}</Row.Title>
        <Row.Description>
          {_("created-at")} {format(program.createdAt, "PPPPp")}
        </Row.Description>
        <Row.Description className="flex flex-wrap items-center gap-3 [&>span]:inline-flex [&>span]:items-center [&_svg]:size-4 [&_svg]:mr-2">
          <span>
            <GraduationCap aria-hidden /> {_("class")} : {program.class}
          </span>
          <span>
            <Barcode aria-hidden /> {_("code")} : {program.code}
          </span>
        </Row.Description>
      </Row.Header>
      <Row.Content className="flex flex-wrap gap-2">
        <Badge variant="secondary">
          <FileText aria-label={_("programs")} />
          {millify(program.programsVisits)}
        </Badge>
        <Badge variant="secondary">
          <Books aria-label={_("books")} />
          {millify(program.booksVisits)}
        </Badge>
        <Badge variant="secondary">
          <TreeStructure aria-label={_("pearltrees")} />
          {millify(program.pearltreesVisits)}
        </Badge>
        <Row.Icon tooltip={_(`status-${program.archived ? "archived" : "available"}`)}>
          {program.archived ? <Archive /> : <PackageOpen />}
        </Row.Icon>
      </Row.Content>
      <Row.Menu menu={<ContextMenu program={program} />} />
    </Row>
  )
}
