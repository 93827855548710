import { Empty, Grid, LimitMore, Pagination, Selection, Toolbar } from "@/components/collection"
import { PageContent } from "@/components/layout/dashboard"
import { Badge } from "@/components/ui/badge"
import { CardAccordion } from "@/components/ui/card-accordion"
import { formatDurationForExcel } from "@/fns/Date"
import { slugify } from "@/fns/String"
import { useFilteredPlaces } from "@/store/places/hooks"
import { Place } from "@/store/places/localizers"
import { useExportReservations, useFilteredReservations } from "@/store/workshops/hooks"
import { Workshop, WorkshopEvent, WorkshopReservation } from "@/store/workshops/localizers"
import {
  Archive,
  ArchiveIcon,
  Download,
  FilterX,
  MapPinned,
  PackageOpen,
  TicketCheck,
  TicketX,
} from "lucide-react"
import { usePageContext } from "./Context"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/de/pages/dashboard/kultureralley/places.json
 * dictionary src/dictionaries/de/pages/dashboard/kultureralley/reservations.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley")

/**
 * Collection
 */
export const Collection: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const { places, filtered, matchable, sortable, filterable, limitable, limit } =
    useFilteredPlaces()
  const { selected, setSelected, ...ctx } = usePageContext()
  const exportReservations = useExportReservations("all-places")

  return (
    <CardAccordion defaultValue={"meta"} group={`reservations`}>
      <PageContent>
        <Selection
          selected={selected}
          deleteSelection={ctx.confirmDeleteSelection}
          unselect={() => setSelected([])}
        >
          <Selection.Button onClick={ctx.confirmArchiveSelection}>
            <Archive aria-hidden size={16} />
            {_("reservations.archive")}
          </Selection.Button>
        </Selection>
        <Toolbar>
          <Toolbar.Search {...matchable} />
          <Toolbar.Button
            variant={"outline"}
            onClick={ctx.confirmArchiveOlder}
            disabled={A.isEmpty(ctx.oldReservations)}
          >
            {_("reservations.archive-older")}{" "}
            {A.isNotEmpty(ctx.oldReservations) && (
              <Badge variant="destructive" stretch>
                {ctx.oldReservations.length}
              </Badge>
            )}
          </Toolbar.Button>
          <Toolbar.Sort {...sortable} dictionary={dictionary("places", "sort")} />
          <Toolbar.Button
            variant="default"
            icon
            onClick={exportReservations}
            aria-label={_("reservations.export-all")}
          >
            <Download aria-hidden size={16} />
          </Toolbar.Button>
        </Toolbar>
        <Empty
          items={places}
          results={filtered}
          clear={() => {
            filterable.clear()
            matchable.setSearch("")
            setSelected([])
          }}
        />
        <div className="flex flex-col gap-4">
          {A.map(limit(filtered), place => (
            <PlaceItem key={place.id} place={place} />
          ))}
        </div>
        <LimitMore {...limitable} />
      </PageContent>
    </CardAccordion>
  )
}

const PlaceItem: React.FC<{ place: Place }> = ({ place }) => {
  const { _ } = useDictionary(dictionary())
  const { reservations, filtered, matchable, sortable, filterable, paginated, paginable } =
    useFilteredReservations(place.id, 1, 5)
  const { setSelected } = usePageContext()
  const exportReservations = useExportReservations(slugify(place.name), reservations)
  return A.isNotEmpty(reservations) ? (
    <CardAccordion.Item value={place.id}>
      <CardAccordion.Header>
        <CardAccordion.Header.Title>
          <MapPinned size={16} aria-hidden />
          {place.name}
        </CardAccordion.Header.Title>
        <CardAccordion.Header.Aside />
      </CardAccordion.Header>
      <CardAccordion.Content className="flex flex-col gap-4 pt-4 px-8 pb-8">
        <Toolbar>
          <Toolbar.Search {...matchable} />
          <PlaceItemFilters {...filterable} />
          <Toolbar.Sort {...sortable} dictionary={dictionary("reservations", "sort")} />
          <Toolbar.Button
            variant="default"
            icon
            onClick={exportReservations}
            aria-label={_("reservations.export-place")}
          >
            <Download aria-hidden size={16} />
          </Toolbar.Button>
        </Toolbar>
        <Empty
          items={reservations}
          results={filtered}
          clear={() => {
            filterable.clear()
            matchable.setSearch("")
            setSelected([])
          }}
        />
        <Grid view="list" className="divide-card">
          {A.map(paginated, item => (
            <Item key={item.id} reservation={item} />
          ))}
        </Grid>
        <Pagination {...paginable} />
      </CardAccordion.Content>
    </CardAccordion.Item>
  ) : null
}

/**
 * Filters
 */
const PlaceItemFilters: React.FC<ReturnType<typeof useFilteredReservations>["filterable"]> = ({
  isActive,
  isInactive,
  toggle,
  toggleActive,
  toggleInactive,
  reset,
}) => {
  const { _ } = useDictionary(dictionary("reservations", "filters"))
  const { setSelected } = usePageContext()

  return (
    <Toolbar.Filters onClick={() => setSelected([])}>
      <Toolbar.Filters.Item onClick={() => toggle("archived")} active={isInactive("archived")}>
        <PackageOpen aria-hidden />
        {_("unarchived")}
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item onClick={() => toggle("archived")} active={isActive("archived")}>
        <ArchiveIcon aria-hidden />
        {_("archived")}
      </Toolbar.Filters.Item>

      <Toolbar.Filters.Item onClick={() => toggleActive("canceled")} active={isActive("canceled")}>
        <TicketX aria-hidden />
        {_("canceled")}
      </Toolbar.Filters.Item>
      <Toolbar.Filters.Item
        onClick={() => toggleInactive("canceled")}
        active={isInactive("canceled")}
      >
        <TicketCheck aria-hidden />
        {_("confirm")}
      </Toolbar.Filters.Item>

      <Toolbar.Filters.Separator />
      <Toolbar.Filters.Item onClick={reset}>
        <FilterX aria-hidden />
        {_("reset")}
      </Toolbar.Filters.Item>
    </Toolbar.Filters>
  )
}

const formatReservation = (
  reservation: WorkshopReservation,
  events: ById<WorkshopEvent>,
  workshops: ById<Workshop>,
  places: ById<Place>
) => {
  const workshop = workshops[reservation.workshopId] as Workshop
  const place = places[workshop.placeId] as Place
  const event = events[reservation.eventId] as WorkshopEvent
  return {
    id: reservation.id,
    workshopName: workshop.name,
    workshoptDate: event.datetime.toISOString(),
    workshopDuration: formatDurationForExcel(workshop.duration),
    placeName: place.name,
    placeRegion: place.map as string,
    canceled: reservation.canceled ? "true" : "false",
    language: reservation.language,
    students: S.make(reservation.students),
    schoolName: reservation.contact?.schoolName ?? "",
    schoolCity: reservation.contact?.schoolCity ?? "",
    schoolClass: reservation.contact?.schoolClass ?? "",
  }
}
