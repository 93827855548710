import {
  Form,
  FormError,
  FormNumber,
  FormSection,
  FormSelect,
  FormTextarea,
} from "@/components/form"
import { Button } from "@/components/frontend/button"
import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { FormReservationProps, StepsProps, StoreProps, dictionary } from "."
import { StepperContent } from "./Stepper"

/**
 * StepReservation
 */
export const StepReservation: React.FC<StoreProps & FormReservationProps & StepsProps> = ({
  places,
  workshops,
  events,
  form,
  setStep,
}) => {
  const { _ } = useDictionary(dictionary("reservation", "reservation"))
  const format = useDateFnsLocaleFormat()

  const placeOptions = React.useMemo(
    () =>
      pipe(
        places,
        A.map(({ id, name }) => ({ value: id, label: name })),
        A.sortBy(D.getUnsafe("label"))
      ),
    [places]
  )

  const workshopOptions = React.useMemo(
    () =>
      A.filterMap(workshops, ({ id, name, placeId }) =>
        placeId === form.values.place ? { value: id, label: name } : O.None
      ),
    [workshops, form.values.place]
  )

  const eventDateOptions = React.useMemo(
    () =>
      pipe(
        events,
        A.filter(
          event =>
            event.workshopId === form.values.workshop &&
            event.reservationsDone < event.reservationsSlot
        ),
        A.uniqBy(event => T.formatISO(event.datetime, { representation: "date" })),
        A.map(event => ({
          value: T.formatISO(event.datetime, { representation: "date" }),
          label: format(event.datetime, "P"),
        }))
      ),
    [events, form.values.workshop, format]
  )

  const eventOptions = React.useMemo(
    () =>
      A.filterMap(events, event =>
        event.reservationsDone < event.reservationsSlot &&
        T.formatISO(event.datetime, { representation: "date" }) === form.values.eventDate
          ? {
              value: event.id,
              label: format(event.datetime, "p"),
            }
          : O.None
      ),
    [events, form.values.eventDate, format]
  )

  const languageOptions = React.useMemo(
    () =>
      A.map(A.find(workshops, ({ id }) => id === form.values.workshop)?.languages ?? [], l => ({
        value: l,
        label: _(`language-${S.toLowerCase(l) as "en"}`, { defaultValue: l }),
      })),
    [workshops, form.values.workshop, _]
  )
  const workshop = React.useMemo(
    () => A.find(workshops, ({ id }) => id === form.values.workshop),
    [workshops, form.values.workshop]
  )

  return (
    <StepperContent value="reservation" title={_("title")} secondary={_("fields-required")}>
      <Form
        form={form}
        className="flex flex-col gap-6"
        onSubmit={() => form.isValid && setStep("contact")}
      >
        <FormSelect
          label={_("place-label")}
          name="place"
          options={placeOptions}
          placeholder={_("place-placeholder")}
          required
          onValueChange={(placeId: string) => {
            form.setValues({
              place: placeId,
              workshop: "",
              eventDate: "",
              event: "",
            })
          }}
        />
        {A.isNotEmpty(workshopOptions) ? (
          <>
            <FormSelect
              label={_("workshop-label")}
              name="workshop"
              options={workshopOptions}
              placeholder={_("workshop-placeholder")}
              required
              onValueChange={(workshopId: string) => {
                form.setValues({
                  workshop: workshopId,
                  eventDate: "",
                  event: "",
                })
              }}
            />

            {S.isNotEmpty(form.values.workshop) && (
              <>
                {A.isNotEmpty(eventDateOptions) ? (
                  <FormSection className="grid sm:grid-cols-2 gap-6">
                    <FormSelect
                      label={_("date-label")}
                      name="eventDate"
                      options={eventDateOptions}
                      placeholder={_("date-placeholder")}
                      required
                      onValueChange={(date: string) => {
                        form.setValues({
                          eventDate: date,
                          event: "",
                        })
                      }}
                    />
                    {A.isNotEmpty(eventOptions) ? (
                      <FormSelect
                        label={_("time-label")}
                        name="event"
                        options={eventOptions}
                        placeholder={_("time-placeholder")}
                        required
                      />
                    ) : null}
                  </FormSection>
                ) : (
                  <FormError>{_("date-empty")}</FormError>
                )}
              </>
            )}
          </>
        ) : (
          <FormError>{_("workshop-empty")}</FormError>
        )}

        {S.isNotEmpty(form.values.event) && (
          <>
            <FormSection className="grid sm:grid-cols-2 gap-6">
              <FormNumber
                name="students"
                labelAside={
                  <span className="text-xs text-gray">
                    {form.values.students}/{workshop?.maxAttendees ?? 0}
                  </span>
                }
                label={_("students-label")}
                // max={workshop?.maxAttendees}
                min={1}
                required
              />
              {A.isNotEmpty(languageOptions) ? (
                <FormSelect
                  label={_("language-label")}
                  name="language"
                  options={languageOptions}
                  placeholder={_("language-placeholder")}
                  required
                />
              ) : null}
            </FormSection>
            <FormTextarea name="message" label={_("message-label")} />
          </>
        )}
        <div className="flex justify-end">
          <Form.Submit asChild>
            <Button>{_("next")}</Button>
          </Form.Submit>
        </div>
      </Form>
    </StepperContent>
  )
}
