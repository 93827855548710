import { makePathToApi } from "@/fns/File"
import { byId } from "@/fns/byId"
import { ApiMultipleFile } from "@/services/commons/schemas"
import { ApiProject } from "@/services/projects/schemas"
import { localizeUser } from "../users/localizers"

/**
 * localizeProject
 */
export const localizeProject = (parsed: ApiProject) => ({
  ...D.selectKeys(parsed, [
    "id",
    "name",
    "language",
    "category",
    "orientation",
    "partners",
    "classes",
    "description",
    "skills",
    "objective",
    "materials",
    "preparation",
    "follow",
    "values",
    "status",
    "validated",
    "trackingId",
  ]),
  image: S.isNotEmpty(parsed.image.url) ? localizeProjectFile(parsed.image) : null,
  documents: byId(parsed.documents, localizeProjectFile),
  images: byId(parsed.images, localizeProjectFile),
  createdBy: G.isNotNullable(parsed.createdBy) ? localizeUser(parsed.createdBy) : null,
  validatedBy: G.isNotNullable(parsed.validatedBy) ? localizeUser(parsed.validatedBy) : null,
  validatedAt: G.isNotNullable(parsed.validatedAt) ? T.parseISO(parsed.validatedAt) : null,
  createdAt: T.parseISO(parsed.createdAt),
  updatedAt: T.parseISO(parsed.updatedAt),
})
export type Project = ReturnType<typeof localizeProject>

/**
 * localizeProjectFile
 */
export const localizeProjectFile = (parsed: ApiMultipleFile) => ({
  ...D.selectKeys(parsed, ["id", "name", "size", "extension"]),
  url: makePathToApi(parsed.url),
})
export type ProjectFile = ReturnType<typeof localizeProjectFile>
