import * as TooltipPrimitive from "@radix-ui/react-tooltip"

/**
 * TooltipProvider
 */
const TooltipProvider: React.FC<TooltipPrimitive.TooltipProviderProps> = props => (
  <TooltipPrimitive.Provider {...props} />
)

/**
 * TooltipRoot
 */
const TooltipRoot: React.FC<TooltipPrimitive.TooltipProps> = props => (
  <TooltipPrimitive.Root {...props} />
)

/**
 * TooltipTrigger
 */
const TooltipTrigger = React.forwardRef<HTMLButtonElement, TooltipPrimitive.TooltipTriggerProps>(
  (props, ref) => <TooltipPrimitive.Trigger ref={ref} {...props} />
)

/**
 * TooltipContent
 */
const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cx(
      "z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    )}
    {...props}
  />
))

/**
 * TooltipShort
 */
type TooltipContentProps = React.ComponentProps<typeof TooltipPrimitive.Content>
type TooltipProps = {
  className?: ClassName
  side?: TooltipContentProps["side"]
  align?: TooltipContentProps["align"]
  sideOffset?: TooltipContentProps["sideOffset"]
  content: TooltipContentProps["children"]
  children: React.ReactNode
}
const TooltipShort: React.FC<TooltipProps> = ({
  align = "center",
  side = "left",
  sideOffset = 4,
  content,
  className,
  children,
}) => {
  return (
    <TooltipProvider>
      <TooltipRoot>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipPrimitive.Portal>
          <TooltipContent side={side} align={align} sideOffset={sideOffset}>
            <p className={className}>{content}</p>
          </TooltipContent>
        </TooltipPrimitive.Portal>
      </TooltipRoot>
    </TooltipProvider>
  )
}

/**
 * exports
 */
const Tooltip = Object.assign(TooltipShort, {
  Provider: TooltipProvider,
  Root: TooltipRoot,
  Trigger: TooltipTrigger,
  Content: TooltipContent,
})
export { Tooltip, type TooltipProps }
