/// <reference types="vite-plugin-svgr/client" />
import KulturrallyeSVG from "@/assets/images/kulturrallye.svg?react"
import { FooterPicture } from "@/components/frontend/drawing"
import { RegionName } from "@/fns/maps"
import { Place } from "@/store/places/localizers"
import { Workshop } from "@/store/workshops/localizers"
import { Redirect, Route, Switch } from "wouter"
import KulturrallyeIndex from "."
import { ContextProvider } from "./Context"
import KulturrallyePlaces from "./places"
import KulturrallyeRegions from "./regions"
import KulturrallyeReservation from "./reservation"

/**
 * KulturrallyeRoutes
 */
const KulturrallyeRoutes: React.FC = () => {
  return (
    <ContextProvider>
      <Switch>
        <Route path="/kulturrallye/regions/:region/:initialView?">
          {params => (
            <KulturrallyeRegions
              region={params.region as RegionName}
              initialView={params.initialView}
            />
          )}
        </Route>
        <Route path="/kulturrallye/places/:id/:workshopId?">
          {params => (
            <KulturrallyePlaces
              id={params.id ?? ""}
              workshopId={params.workshopId ?? null}
              key={params.id}
            />
          )}
        </Route>
        <Route path="/kulturrallye/reservation/:eventId">
          {params => <KulturrallyeReservation {...params} />}
        </Route>
        <Route path="/kulturrallye">
          <KulturrallyeIndex />
        </Route>
        <Redirect to="/kulturrallye" />
      </Switch>
      <FooterPicture image={<KulturrallyeSVG />} />
    </ContextProvider>
  )
}

export default KulturrallyeRoutes

/**
 * Links
 */
export const regionLink = (region: RegionName) => `/kulturrallye/regions/${region}`
export const placeLink = (place: Place) => `/kulturrallye/places/${place.id}`
export const workshopLink = (workshop: Workshop) =>
  `/kulturrallye/places/${workshop.placeId}/${workshop.id}`
