import { type ItemMappingExport } from "../schemas"
import { itemType, type ItemType } from "./schemas"

/**
 * create
 */
export const create: ItemMappingExport<ItemType>["create"] = (order, languages) => ({
  type: itemType,
  order,
  props: {
    titleLevel: 1,
    cards: [],
  },
  translations: A.map(languages, ({ id: languageId }) => ({
    languageId,
    props: { title: "", secondary: "", cards: {} },
  })),
  files: [],
})
