import { ApiContent, ApiContentItemAny, ContentItemMapping } from "@/components/cms/items/schemas"
import { makePathToApi } from "@/fns/File"
import { byId } from "@/fns/byId"
import { ApiMultipleFile } from "@/services/commons/schemas"
import {
  ApiArticle,
  ApiArticleCategory,
  ApiContact,
  ApiPage,
  ApiPlace,
  ApiProject,
  ApiUser,
  ApiWorkshop,
  ApiWorkshopEvent,
  ApiWorkshopReservation,
} from "@/services/frontend/schemas"
import { extractFilesFromContent } from "../contents/helpers"
import { byLanguage } from "../languages/helpers"
import { getPreview } from "../medias/helpers"
import { localizeMediaFile } from "../medias/localizers"
import { extractFilesFromSeo } from "../seos/helpers"
import { localizeSeo } from "../seos/localizers"
import { localizeUserProfile } from "../users/localizers"

/**
 * localizePage
 */
export const localizePage = ({ seo, content, createdAt, updatedAt, ...parsed }: ApiPage) => ({
  ...D.selectKeys(parsed, ["id", "state", "trackingId", "lock"]),
  seo: localizeSeo(seo),
  content: localizeContent(content),
  createdAt: T.parseISO(createdAt),
  updatedAt: T.parseISO(updatedAt),
})
export type Page = ReturnType<typeof localizePage>

/**
 * localizeArticle
 */
export const localizeArticle = ({
  seo,
  content,
  category,
  author,
  publishedAt,
  publishedFrom,
  publishedTo,
  createdAt,
  updatedAt,
  ...parsed
}: ApiArticle) => ({
  ...D.selectKeys(parsed, ["id", "state", "trackingId", "type"]),
  author: G.isNotNullable(author) ? localizeUser(author) : null,
  seo: localizeSeo(seo),
  content: localizeContent(content),
  category: G.isNotNullable(category) ? localizeArticleCategory(category) : null,
  publishedAt: T.parseISO(publishedAt),
  publishedFrom: publishedFrom ? T.parseISO(publishedFrom) : null,
  publishedTo: publishedTo ? T.parseISO(publishedTo) : null,
  createdAt: T.parseISO(createdAt),
  updatedAt: T.parseISO(updatedAt),
  files: byId(
    [...extractFilesFromSeo(seo), ...extractFilesFromContent(content)],
    localizeMediaFile
  ),
})

export type Article = ReturnType<typeof localizeArticle>

/**
 * localizeArticleCategory
 */
export const localizeArticleCategory = (parsed: ApiArticleCategory) => ({
  ...D.selectKeys(parsed, ["id", "order"]),
  translations: byLanguage(parsed.translations),
})
export type ArticleCategory = ReturnType<typeof localizeArticleCategory>

/**
 * localizeUser
 */
export const localizeUser = ({ profile, ...parsed }: ApiUser) => ({
  ...D.selectKeys(parsed, ["id"]),
  profile: localizeUserProfile(profile),
})
export type User = ReturnType<typeof localizePage>

/**
 * localizeContent
 */
export const localizeContent = (parsed: ApiContent) => {
  return {
    id: parsed.id,
    items: A.sortBy(A.map(parsed.items, localizeContentItem), D.getUnsafe("order")),
  }
}
export type Content = ReturnType<typeof localizeContent>

/**
 * localizeContentItem
 */
export const localizeContentItem = <T extends keyof ContentItemMapping>(
  parsed: ApiContentItemAny
) => {
  const itemBase: ContentItemBase = {
    id: parsed.id,
    state: parsed.state,
    order: parsed.order,
    createdAt: T.parseISO(parsed.createdAt),
    updatedAt: T.parseISO(parsed.updatedAt),
  }
  const itemSpecific = {
    type: parsed.type,
    props: parsed.props,
    translations: parsed.translations.reduce(
      (translations, translation) => ({ ...translations, [translation.languageId]: translation }),
      {}
    ),
  } as ContentItemMapping[T]
  return D.merge(itemBase, itemSpecific)
}

export type ContentItemBase = {
  id: string
  state: "draft" | "published"
  order: number
  createdAt: Date
  updatedAt: Date
}
export type ContentItem = ReturnType<typeof localizeContentItem>

/**
 * localizeWorkshop
 */
export const localizeWorkshop = (parsed: ApiWorkshop) => ({
  ...D.selectKeys(parsed, [
    "id",
    "name",
    "published",
    "archived",
    "description",
    "languages",
    "duration",
    "maxAttendees",
    "placeId",
    "trackingId",
  ]),
  events: byId(parsed.events, event => localizeWorkshopEvent(event, parsed.placeId)),
})
export type Workshop = ReturnType<typeof localizeWorkshop>

/**
 * localizeWorkshopEvent
 */
export const localizeWorkshopEvent = (parsed: ApiWorkshopEvent, placeId: string) => ({
  ...D.selectKeys(parsed, ["id", "reservationsSlot", "reservationsDone", "workshopId"]),
  placeId,
  reservationsAvailable: parsed.reservationsSlot - parsed.reservationsDone,
  datetime: T.parseISO(parsed.datetime),
})
export type WorkshopEvent = ReturnType<typeof localizeWorkshopEvent>

/**
 * localizeWorkshopReservation
 */
export const localizeWorkshopReservation = (parsed: ApiWorkshopReservation) => ({
  ...D.selectKeys(parsed, [
    "id",
    "canceled",
    "archived",
    "message",
    "language",
    "students",
    "userId",
    "eventId",
    "workshopId",
  ]),
  contact: D.isEmpty(parsed.contact) ? null : localizeContact(parsed.contact as ApiContact),
  createdAt: T.parseISO(parsed.createdAt),
  updatedAt: T.parseISO(parsed.updatedAt),
})
export type WorkshopReservation = ReturnType<typeof localizeWorkshopReservation>

/**
 * localizeContact
 */
const localizeContact = (parsed: ApiContact) => ({
  ...D.selectKeys(parsed, [
    "schoolName",
    "schoolClass",
    "schoolStreet",
    "schoolZip",
    "schoolCity",
    "teacherName",
    "teacherEmail",
    "teacherPhone",
  ]),
})

/**
 * localizePlace
 */
export const localizePlace = (parsed: ApiPlace) => ({
  ...D.selectKeys(parsed, [
    "id",
    "name",
    "published",
    "description",
    "address",
    "website",
    "phones",
    "emails",
    "map",
    "coordinate",
  ]),
  image: getPreview(G.isNullable(parsed.image) ? null : localizeMediaFile(parsed.image)) ?? null,
  cover: getPreview(G.isNullable(parsed.cover) ? null : localizeMediaFile(parsed.cover)) ?? null,
})

export type Place = ReturnType<typeof localizePlace>

/**
 * localizeProject
 */
export const localizeProject = (parsed: ApiProject) => ({
  ...D.selectKeys(parsed, [
    "id",
    "name",
    "language",
    "category",
    "orientation",
    "partners",
    "classes",
    "description",
    "skills",
    "objective",
    "materials",
    "preparation",
    "follow",
    "values",
    "status",
    "validated",
    "trackingId",
  ]),
  image: S.isNotEmpty(parsed.image.url) ? localizeProjectFile(parsed.image) : null,
  documents: byId(parsed.documents, localizeProjectFile),
  images: byId(parsed.images, localizeProjectFile),
  createdBy: G.isNotNullable(parsed.createdBy) ? localizeUser(parsed.createdBy) : null,
  validatedBy: G.isNotNullable(parsed.validatedBy) ? localizeUser(parsed.validatedBy) : null,
  validatedAt: G.isNotNullable(parsed.validatedAt) ? T.parseISO(parsed.validatedAt) : null,
  createdAt: T.parseISO(parsed.createdAt),
  updatedAt: T.parseISO(parsed.updatedAt),
})
export type Project = ReturnType<typeof localizeProject>

/**
 * localizeProjectFile
 */
export const localizeProjectFile = (parsed: ApiMultipleFile) => ({
  ...D.selectKeys(parsed, ["id", "name", "size", "extension"]),
  url: makePathToApi(parsed.url),
})
export type ProjectFile = ReturnType<typeof localizeProjectFile>
