import { Menu, useMenu } from "@/components/collection"
import { usePersistedState } from "@/components/cookies/hooks/usePersistedState"
import { StatsDialog } from "@/components/trackings/dialogs/stats"
import { Confirm } from "@/components/ui/confirm"
import { useConfirm } from "@/components/ui/hooks/useConfirm"
import { useDialog } from "@/components/ui/hooks/useDialog"
import { useSelectItem } from "@/hooks/useSelect"
import { deleteProject, updateProject } from "@/store/projects/actions"
import { useProjectsById } from "@/store/projects/hooks"
import { Project } from "@/store/projects/localizers"
import {
  CheckCircle,
  Monitor,
  NotePencil,
  SelectionPlus,
  SelectionSlash,
  TrashSimple,
  XCircle,
} from "@phosphor-icons/react"
import { EditDialog } from "./EditDialog"

/**
 * dictionary src/dictionaries/en/pages/dashboard/projects.json
 */
const dictionary = createContextMapper("pages", "dashboard", "projects")

/**
 * Context
 */
export const Context = React.createContext({} as ContextType)
export const usePageContext = () => React.useContext(Context)
export const ContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { _ } = useDictionary(dictionary())
  const projects = useProjectsById()

  // selection
  const [selected, setSelected] = React.useState<string[]>([])

  // view
  const [view, setView] = usePersistedState<ContextType["view"]>(
    "grid",
    "state-projects-view",
    "interface",
    sessionStorage
  )

  // confirms
  const { confirm: confirmDelete, ...deleteProps } = useConfirm<string>({
    onAsyncConfirm: deleteProject,
    dictionary: Confirm.dictionary(dictionary("delete-confirm")),
  })
  const { confirm: confirmDeleteSelection, ...deleteSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName: id => D.get(projects, id)?.name ?? _("unknow"),
    onAsyncConfirm: deleteProject,
    dictionary: Confirm.dictionary(dictionary("delete-selection-confirm")),
  })
  const { confirm: confirmAcceptSelection, ...acceptSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName: id => D.get(projects, id)?.name ?? _("unknow"),
    onAsyncConfirm: id => updateProject(id, { status: "accepted" }),
    dictionary: Confirm.dictionary(dictionary("status-selection-confirm")),
  })
  const { confirm: confirmRefuseSelection, ...refuseSelectionProps } = useConfirm<void, string>({
    list: selected,
    displayName: id => D.get(projects, id)?.name ?? _("unknow"),
    onAsyncConfirm: id => updateProject(id, { status: "refused" }),
    dictionary: Confirm.dictionary(dictionary("status-selection-confirm")),
  })

  // dialogs
  const { setItem: edit, ...editProps } = useDialog<Project>()
  const { setItem: openStats, ...statsProps } = useDialog<string>()

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        view,
        setView,
        edit,
        confirmDelete,
        confirmDeleteSelection,
        confirmAcceptSelection,
        confirmRefuseSelection,
        openStats,
      }}
    >
      {children}
      <EditDialog {...editProps} />
      <Confirm {...deleteProps} />
      <Confirm {...deleteSelectionProps} />
      <Confirm {...acceptSelectionProps} />
      <Confirm {...refuseSelectionProps} />
      <StatsDialog {...statsProps} />
    </Context.Provider>
  )
}

/**
 * ContextMenu
 */
export const ContextMenu: React.FC<{
  project: Project
}> = ({ project }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { id, status } = project
  const ctx = usePageContext()
  const { type } = useMenu()
  const { isSelected, toggleSelection } = useSelectItem(Context, id)
  const acceptItem = async () => updateProject(id, { status: "accepted" })
  const refuseItem = async () => updateProject(id, { status: "refused" })
  return (
    <>
      <Menu.Item onClick={toggleSelection}>
        {isSelected ? <SelectionSlash aria-hidden /> : <SelectionPlus aria-hidden />}
        {_(isSelected ? "unselect" : "select")}
      </Menu.Item>
      <Menu.Item onClick={() => navigate(`/dashboard/projects/${id}`)}>
        <Monitor aria-hidden />
        {_("display")}
      </Menu.Item>
      <Menu.Item onClick={() => ctx.edit(project)}>
        <NotePencil />
        {_("edit")}
      </Menu.Item>
      {status !== "accepted" && (
        <Menu.Item onClick={acceptItem}>
          <CheckCircle />
          {_("accept")}
        </Menu.Item>
      )}
      {status !== "refused" && (
        <Menu.Item onClick={refuseItem}>
          <XCircle />
          {_("refuse")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => ctx.confirmDelete(id)}>
        <TrashSimple />
        {_("delete")}
      </Menu.Item>
      {type === "context-menu" && isSelected && (
        <>
          <Menu.Separator />
          {status !== "accepted" && (
            <Menu.Item onClick={ctx.confirmAcceptSelection}>
              <CheckCircle />
              {_("accept-selection")}
            </Menu.Item>
          )}
          {status !== "refused" && (
            <Menu.Item onClick={ctx.confirmRefuseSelection}>
              <XCircle />
              {_("refuse-selection")}
            </Menu.Item>
          )}
          <Menu.Item onClick={ctx.confirmDeleteSelection}>
            <TrashSimple />
            {_("delete-selection")}
          </Menu.Item>
        </>
      )}
    </>
  )
}

/**
 * types
 */
type ContextType = {
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  view: "grid" | "list"
  setView: React.Dispatch<ContextType["view"]>
  edit: (item: Project) => void
  confirmDelete: (id: string) => void
  confirmDeleteSelection: () => void
  confirmAcceptSelection: () => void
  confirmRefuseSelection: () => void
  openStats: (trackingId: string) => void
}
