/**
 * globalConfig
 */
const globalConfig = {
  api: process.env.VITE_API_URL ?? "",
  environment: process.env.VITE_ENV ?? "development",
  inDev: process.env.VITE_ENV === "development",
  inProduction: process.env.VITE_ENV === "production",
  host: process.env.VITE_APP_URL ?? "",
  name: process.env.VITE_APP_NAME ?? "",
  login: {
    email: process.env.VITE_APP_LOGIN_EMAIL ?? "",
    password: process.env.VITE_APP_LOGIN_PASSWORD ?? "",
  },
  siteName: "Voie de Préparation",
  maxUploadFile: +(process.env.VITE_MAX_UPLOAD_FILE ?? 32),
  sessionKey: process.env.VITE_APP_SESSION_KEY ?? "session",
  disableKulturrallyeArchive: true,
}
export default globalConfig

// prettier-ignore
export const acceptedFileExtensions = [
  "pdf", "jpg", "jpeg", "png", "svg", "webp", "gif", "tiff", "psd", "bmp",
  "doc", "docx", "ppt", "pptx", "xls", "xlsx", "txt", "rtf", "odt", "ods",
  "odp", "csv", "md", "json", "xml", "html", "css", "js", "jsx", "ts", "tsx",
  "mp3", "wav", "aac", "ogg", "midi", "flac", "mp4", "mov", "wmv", "avi",
  "mkv", "flv", "m4v", "mpg", "mpeg", "3gp", "zip", "rar", "7z", "gz", "bz2",
  "tar", "iso", "dmg", "exe", "msi", "bin", "jar", "apk", "eps", "ai",
  "indd", "raw", "heic", "heif", "webm", "fla", "swf", "qt", "msg", "eml",
  "vcard", "ics", "cr2", "nef", "orf", "sr2", "pdb", "dwg", "dxf", "cbr",
  "cbz", "vob", "m4a", "m4p", "m4b", "f4v", "f4a", "f4b", "f4p", "oga",
  "ogv", "ogx", "opus", "3g2", "3gp2", "3gpp", "3gpp2", "eot", "woff", "woff2", "ttf", "otf"
]
export const acceptImageExtensions = ["jpg", "jpeg", "png", "svg", "webp", "gif", "tiff", "bmp"]
const inDev = globalConfig.environment === "development"
const inProduction = globalConfig.environment === "production"
export { inDev, inProduction }
