import { useCmsContext } from "@/components/cms/Context"
import {
  Form,
  FormAssertive,
  FormHeader,
  FormInput,
  FormSection,
  FormSelect,
  FormSubmit,
  FormTiptap,
  FormTranslationContext,
  FormTranslationTabs,
} from "@/components/form"
import { FormMediasImage } from "@/components/medias/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { resetAllStoresAndReload } from "@/store"
import { translate, useLanguagesById } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { useForm } from "use-a11y-form"
import { FormSelectTemplate } from "../../form"
import { useTitleLevelOptions } from "../../hooks/useTitleLevelOptions"
import { FormPayload, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms")
type Payload = FormPayload<ItemType>

/**
 * ItemForm
 */
export const ItemForm: ItemMappingExport<ItemType>["ItemForm"] = ({ item, close }) => {
  const { _ } = useDictionary(dictionary("content", "items", itemType))
  const { _: __ } = useDictionary(dictionary("content", "form"))
  const _f = useFormDictionary()
  const _e = useErrorsDictionary()

  const {
    id,
    actions: { updateContentItem },
  } = useCmsContext()
  const languages = useLanguagesById()

  const form = useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => ({
      ...D.selectKeys(item.props, ["template", "image"]),
      titleLevel: `${item.props.titleLevel}`,

      translations: D.map(languages, language => ({
        languageId: language.id,
        title: translate(item, language)?.props.title ?? "",
        secondary: translate(item, language)?.props.secondary ?? "",
      })),
    })),
    onSubmit: async ({ values }) => {
      values.translations
      const payload: Payload = {
        props: {
          ...D.selectKeys(values, ["template", "image"]),
          titleLevel: +values.titleLevel,
        },
        translations: pipe(
          values.translations,
          D.values,
          A.map(({ languageId, ...props }) => ({
            languageId,
            props,
          }))
        ),
        files: [],
      }

      return match(await updateContentItem(item.id, payload))
        .with({ error: false }, () => {
          toast.success(__("success"))
          close()
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _e)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_e(code)))
        )
    },
  })

  const titleLevelOptions = useTitleLevelOptions()

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />

      <FormTranslationTabs context={false}>
        {language => (
          <div className="grid gap-6" key={language.id}>
            <FormSection>
              <FormHeader>
                <FormHeader.Title>{_("section-header-title")}</FormHeader.Title>
                <FormHeader.Description>{_("section-header-description")}</FormHeader.Description>
              </FormHeader>
              <FormTranslationContext language={language}>
                <FormInput
                  name="title"
                  label={__("title-label")}
                  placeholder={__("title-placeholder")}
                  translatable
                />
              </FormTranslationContext>
              <FormSelect
                options={titleLevelOptions}
                name="titleLevel"
                label={__("title-level-label")}
              />
              <FormTranslationContext language={language}>
                <FormTiptap
                  name="secondary"
                  label={__("secondary-label")}
                  placeholder={__("secondary-placeholder")}
                  translatable
                />
              </FormTranslationContext>
            </FormSection>

            <FormSection>
              <FormHeader>
                <FormHeader.Title>{_("section-images-title")}</FormHeader.Title>
                <FormHeader.Description>{_("section-images-description")}</FormHeader.Description>
              </FormHeader>
              <FormMediasImage name="image" label={_("image-label")} contextKey={id} />
            </FormSection>

            <FormSection>
              <FormHeader>
                <FormHeader.Title>{_("section-template-title")}</FormHeader.Title>
                <FormHeader.Description>{_("section-template-description")}</FormHeader.Description>
              </FormHeader>
              <FormSelectTemplate
                name="template"
                options={[Template1, Template2, Template3, Template4]}
                label={_("template-label")}
              />
            </FormSection>
          </div>
        )}
      </FormTranslationTabs>
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_f("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_f("update")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}

/**
 * templates
 */
const Template1: React.FC = () => {
  const { _ } = useDictionary(dictionary("content", "items", itemType))

  return (
    <div className="flex justify-center items-center p-4">
      <div className="flex justify-center items-center w-full aspect-square rounded-sm bg-muted text-muted-foreground text-sm font-medium">
        {_("original-size")}
      </div>
    </div>
  )
}
const Template2: React.FC = () => (
  <div className="flex justify-center items-center p-4">
    <div className="flex justify-center items-center w-full aspect-video rounded-sm bg-muted text-muted-foreground text-sm font-medium">
      16/9
    </div>
  </div>
)
const Template3: React.FC = () => (
  <div className="flex justify-center items-center p-4">
    <div className="flex justify-center items-center w-full aspect-[3/2] rounded-sm bg-muted text-muted-foreground text-sm font-medium">
      3/2
    </div>
  </div>
)
const Template4: React.FC = () => {
  const { _ } = useDictionary(dictionary("content", "items", itemType))
  return (
    <div className="flex justify-center items-center p-4">
      <div className="relative flex justify-center items-center w-full aspect-square rounded-sm bg-muted text-muted-foreground text-sm font-medium">
        {_("original-size")}
        <div className="absolute bottom-0 inset-x-0 w-full flex flex-col p-[2px] gap-[2px] bg-background/80 text-foreground">
          <div className="text-[5px] leading-tight font-bold">Lorem, ipsum dolor</div>
          <div className="text-[2px] leading-tight w-2/3">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur itaque facilis illo.
          </div>
        </div>
      </div>
    </div>
  )
}
