import { DisplayBySelector } from "@/components/trackings/components/display-by-selector"
import { RangeSelector } from "@/components/trackings/components/range-selector"
import { parseIsoMonth, parseIsoWeek, parseIsoYear } from "@/components/trackings/helpers"
import { DisplayByName, RangeName, StatRecord } from "@/components/trackings/types"
import { useStatsTheme } from "@/components/trackings/useStatsTheme"
import { useDateFnsLocale, useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { useResponsive } from "@/hooks/useResponsive"
import { WorkshopReservation } from "@/store/workshops/localizers"
import millify from "millify"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts"

/**
 * dictionary src/dictionaries/en/components/trackings.json
 */
const dictionary = createContextMapper("components", "trackings")

/**
 * Stats
 */
export const Stats: React.FC<{ reservations: WorkshopReservation[] }> = ({ reservations }) => {
  const [displayBy, setDisplayBy] = React.useState<DisplayByName>("days")
  const [range, setRange] = React.useState<RangeName>("7days")
  const interval = React.useMemo(() => {
    const to = new Date()
    if (range === "7days") return { from: T.sub(to, { days: 7 }), to }
    if (range === "1month") return { from: T.sub(to, { months: 1 }), to }
    if (range === "3months") return { from: T.sub(to, { months: 3 }), to }
    if (range === "6months") return { from: T.sub(to, { months: 6 }), to }
    if (range === "12months") return { from: T.sub(to, { years: 1 }), to }
    if (range === "all") return { from: undefined, to }
    return exhaustive(range)
  }, [range])
  const props = { reservations, interval }
  return (
    <>
      <div className="flex justify-end items-center w-full gap-2">
        <RangeSelector range={range} setRange={setRange} />
        <DisplayBySelector displayBy={displayBy} setDisplayBy={setDisplayBy} />
      </div>
      <div className="w-full aspect-video text-[12px] @md/dialog:text-[12px] @lg/dialog:text-base">
        {displayBy === "days" && <StatsByDays {...props} />}
        {displayBy === "weeks" && <StatsByWeeks {...props} />}
        {displayBy === "months" && <StatsByMonths {...props} />}
        {displayBy === "years" && <StatsByYears {...props} />}
      </div>
    </>
  )
}

type ChartProps = { reservations: WorkshopReservation[]; interval: { from?: Date; to: Date } }
type Props = { strokeWidth?: number; color?: string; hideAxis?: boolean; hideGrid?: boolean }

/**
 * StatsByDays
 */
export const StatsByDays: React.FC<ChartProps & Props> = ({
  reservations,
  interval,
  hideAxis = false,
  hideGrid = false,
  color,
  strokeWidth,
}) => {
  const format = useDateFnsLocaleFormat()
  const { colors } = useStatsTheme()
  const { max } = useResponsive()

  const data: StatRecord[] = React.useMemo(() => {
    const start =
      interval.from ??
      pipe(
        reservations,
        A.map(D.getUnsafe("createdAt")),
        A.sortBy(d => d),
        A.head
      ) ??
      T.sub(interval.to, { days: 7 })

    const days = T.eachDayOfInterval({ start, end: interval.to })
    const compacted = compactByDay(reservations)
    return A.map<Date, StatRecord>(days, date => {
      const key = T.formatISO(date, { representation: "date" })
      return { name: key, value: compacted[key] ?? 0 }
    })
  }, [reservations, interval.from, interval.to])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        {!hideGrid && <CartesianGrid strokeDasharray="3 3" stroke="hsl(var(--muted))" />}
        {!hideAxis && (
          <XAxis
            dataKey="name"
            stroke="hsl(var(--muted-foreground))"
            tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
            height={max("sm") ? 15 : max("md") ? 25 : max("lg") ? 35 : 40}
            tickFormatter={(value: string) => format(T.parseISO(value), "P")}
          />
        )}
        {!hideAxis && (
          <YAxis
            dataKey="value"
            stroke="hsl(var(--muted-foreground))"
            width={max("sm") ? 15 : max("md") ? 25 : max("lg") ? 35 : 40}
            tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
            tickFormatter={(value: number) => millify(value)}
          />
        )}
        <Tooltip
          content={<StatsByDaysTooltip color={color ?? colors[1]} />}
          cursor={{ stroke: "hsl(var(--muted))" }}
        />
        <Line
          dataKey="value"
          strokeWidth={strokeWidth ?? 1}
          stroke={color ?? colors[1]}
          dot={false}
          activeDot={{ r: 4, stroke: "transparent" }}
          strokeLinecap="round"
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

/**
 * StatsByDaysTooltip
 */
const StatsByDaysTooltip: React.FC<TooltipProps<number, string> & { color?: string }> = ({
  color,
  active,
  payload,
}) => {
  const format = useDateFnsLocaleFormat()
  const { colors } = useStatsTheme()
  if (!(active && payload && payload.length)) return null
  const {
    value,
    payload: { name },
  } = A.getUnsafe(payload, 0)
  return (
    <div className="rounded-md border bg-popover px-3 py-1.5 text-[0.75em] text-popover-foreground shadow-md">
      <b style={{ color: color ?? colors[1] }}>{format(T.parseISO(name), "PPP")}: </b>{" "}
      <span>{`${value}`}</span>
    </div>
  )
}

/**
 * StatsByWeeks
 */
const StatsByWeeks: React.FC<ChartProps> = ({ reservations, interval }) => {
  const { colors } = useStatsTheme()
  const format = useDateFnsLocaleFormat()
  const locale = useDateFnsLocale()

  const data: StatRecord[] = React.useMemo(() => {
    const start =
      interval.from ??
      pipe(
        reservations,
        A.map(D.getUnsafe("createdAt")),
        A.sortBy(d => d),
        A.head
      ) ??
      T.sub(interval.to, { months: 1 })

    const week = T.eachWeekOfInterval({ start, end: interval.to })
    const compacted = compactByWeek(reservations)

    return A.map<Date, StatRecord>(week, date => {
      const key = T.format(date, "yyyy-ww")
      return { name: key, value: compacted[key] ?? 0 }
    })
  }, [interval.from, interval.to, reservations])
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" stroke="hsl(var(--muted))" />
        <XAxis
          dataKey="name"
          stroke="hsl(var(--muted-foreground))"
          tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
          tickFormatter={(value: string) => format(parseIsoWeek(value, locale), "ww")}
        />
        <YAxis
          dataKey="value"
          stroke="hsl(var(--muted-foreground))"
          width={40}
          tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
          tickFormatter={(value: number) => millify(value)}
        />
        <Tooltip content={<StatsByWeeksTooltip />} cursor={{ fill: "hsl(var(--muted))" }} />
        {/* <Legend /> */}
        <Bar dataKey="value" fill={colors[2]} />
      </BarChart>
    </ResponsiveContainer>
  )
}

/**
 * StatsByWeeksTooltip
 */
const StatsByWeeksTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  const { _ } = useDictionary(dictionary("stats-dialog"))
  const { colors } = useStatsTheme()
  if (!(active && payload && payload.length)) return null
  const {
    value,
    payload: { name },
  } = A.getUnsafe(payload, 0)
  const [year, week] = A.map(S.split(name, "-"), Number)
  if (G.isNullable(year) || G.isNullable(week)) throw new Error("Invalid yearWeek")
  return (
    <div className="rounded-md border bg-popover px-3 py-1.5 text-[0.75em] text-popover-foreground shadow-md">
      <b style={{ color: colors[2] }}>{_("tooltip-by-weeks", { week, year })}: </b>{" "}
      <span>{`${value}`}</span>
    </div>
  )
}

/**
 * StatsByMonths
 */
const StatsByMonths: React.FC<ChartProps> = ({ reservations, interval }) => {
  const { colors } = useStatsTheme()
  const format = useDateFnsLocaleFormat()
  const data: StatRecord[] = React.useMemo(() => {
    const start =
      interval.from ??
      pipe(
        reservations,
        A.map(D.getUnsafe("createdAt")),
        A.sortBy(d => d),
        A.head
      ) ??
      T.sub(interval.to, { months: 3 })
    const month = T.eachMonthOfInterval({ start, end: interval.to })
    const compacted = compactByMonth(reservations)
    return A.map<Date, StatRecord>(month, date => {
      const key = T.format(date, "yyyy-MM")
      return { name: key, value: compacted[key] ?? 0 }
    })
  }, [interval.from, interval.to, reservations])
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" stroke="hsl(var(--muted))" />
        <XAxis
          dataKey="name"
          stroke="hsl(var(--muted-foreground))"
          tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
          tickFormatter={(value: string) => format(parseIsoMonth(value), "LLL")}
        />
        <YAxis
          dataKey="value"
          stroke="hsl(var(--muted-foreground))"
          width={40}
          tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
          tickFormatter={(value: number) => millify(value)}
        />
        <Tooltip content={<StatsByMonthsTooltip />} cursor={{ fill: "hsl(var(--muted))" }} />
        <Bar dataKey="value" fill={colors[4]} />
      </BarChart>
    </ResponsiveContainer>
  )
}

/**
 * StatsByMonthsTooltip
 */
const StatsByMonthsTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  const format = useDateFnsLocaleFormat()
  const { colors } = useStatsTheme()
  if (!(active && payload && payload.length)) return null
  const {
    value,
    payload: { name },
  } = A.getUnsafe(payload, 0)
  const date = parseIsoMonth(name)
  return (
    <div className="rounded-md border bg-popover px-3 py-1.5 text-[0.75em] text-popover-foreground shadow-md">
      <b style={{ color: colors[4] }}>{format(date, "LLLL yyyy")}: </b> <span>{`${value}`}</span>
    </div>
  )
}

/**
 * StatsByYears
 */
const StatsByYears: React.FC<ChartProps> = ({ reservations, interval }) => {
  const { colors } = useStatsTheme()
  const format = useDateFnsLocaleFormat()
  const data: StatRecord[] = React.useMemo(() => {
    const start =
      interval.from ??
      pipe(
        reservations,
        A.map(D.getUnsafe("createdAt")),
        A.sortBy(d => d),
        A.head
      ) ??
      T.sub(interval.to, { years: 1 })
    const years = T.eachYearOfInterval({ start, end: interval.to })
    const compacted = compactByYear(reservations)
    return A.map<Date, StatRecord>(years, date => {
      const key = T.format(date, "yyyy")
      return { name: key, value: compacted[key] ?? 0 }
    })
  }, [interval.from, interval.to, reservations])
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" stroke="hsl(var(--muted))" />
        <XAxis
          dataKey="name"
          stroke="hsl(var(--muted-foreground))"
          tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
          tickFormatter={(value: string) => format(parseIsoYear(value), "yyyy")}
        />
        <YAxis
          dataKey="value"
          stroke="hsl(var(--muted-foreground))"
          width={40}
          tick={{ fontSize: "0.75em", fill: "hsl(var(--muted-foreground))" }}
          tickFormatter={(value: number) => millify(value)}
        />
        <Tooltip content={<StatsByYearsTooltip />} cursor={{ fill: "hsl(var(--muted))" }} />
        <Bar dataKey="value" fill={colors[5]} />
      </BarChart>
    </ResponsiveContainer>
  )
}

/**
 * StatsByYearsTooltip
 */
const StatsByYearsTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  const format = useDateFnsLocaleFormat()
  const { colors } = useStatsTheme()
  if (!(active && payload && payload.length)) return null
  const {
    value,
    payload: { name },
  } = A.getUnsafe(payload, 0)
  const date = parseIsoYear(name)
  return (
    <div className="rounded-md border bg-popover px-3 py-1.5 text-[0.75em] text-popover-foreground shadow-md">
      <b style={{ color: colors[5] }}>{format(date, "yyyy")}: </b> <span>{`${value}`}</span>
    </div>
  )
}

/**
 * helpers
 */
const compactByDay = (reservations: WorkshopReservation[]) => {
  const stats = A.reduce(reservations, {} as Record<string, number>, (stats, reservation) => {
    const day = T.format(reservation.createdAt, "yyyy-MM-dd")
    return D.set(stats, day, (stats[day] ?? 0) + 1)
  })
  return stats
}
const compactByWeek = (reservations: WorkshopReservation[]) => {
  const stats = A.reduce(reservations, {} as Record<string, number>, (stats, reservation) => {
    const week = T.format(reservation.createdAt, "yyyy-ww")
    return D.set(stats, week, (stats[week] ?? 0) + 1)
  })
  return stats
}
const compactByMonth = (reservations: WorkshopReservation[]) => {
  const stats = A.reduce(reservations, {} as Record<string, number>, (stats, reservation) => {
    const month = T.format(reservation.createdAt, "yyyy-MM")
    return D.set(stats, month, (stats[month] ?? 0) + 1)
  })
  return stats
}
const compactByYear = (reservations: WorkshopReservation[]) => {
  const stats = A.reduce(reservations, {} as Record<string, number>, (stats, reservation) => {
    const year = T.format(reservation.createdAt, "yyyy")
    return D.set(stats, year, (stats[year] ?? 0) + 1)
  })
  return stats
}
