import { Form, FormAssertive, FormInput, FormSubmit, useForm, validator } from "@/components/form"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import type { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { resetAllStoresAndReload } from "@/store"
import { createPlace } from "@/store/places/actions"
import { match } from "ts-pattern"

/**
 * dictionary src/dictionaries/en/pages/dashboard/kultureralley/places.json
 */
const dictionary = createContextMapper("pages", "dashboard", "kultureralley", "places")

/**
 * CreateDialog
 */
export const CreateDialog: React.FC<UseDialogProps<void>> = ({ ...props }) => {
  const { _ } = useDictionary(dictionary("create-dialog"))
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="max-w-2xl">
      <DialogForm {...props} />
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<void>> = ({ onOpenChange }) => {
  const { _ } = useDictionary(dictionary("create-dialog"))
  const _form = useFormDictionary()
  const _errors = useErrorsDictionary()
  const { min } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: {
      name: "",
    },
    validate: validator({
      name: [min(1, _form("name-required"))],
    }),
    onSubmit: async ({ values }) => {
      if (!form.isValid) return _errors("VALIDATION_FAILURE")
      return match(await createPlace({ name: values.name, map: "center", coordinate: [0, 0] }))
        .with({ error: false }, ({ id }) => {
          toast.success(_("success"))
          onOpenChange(false)
          navigate(`/dashboard/kultureralley/${id}`)
        })
        .otherwise(({ code }) =>
          match(code)
            .with("VALIDATION_FAILURE", _errors)
            .with("INVALID_AUTH_SESSION", resetAllStoresAndReload)
            .otherwise(code => void toast.error(_errors(code)))
        )
    },
  })
  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormInput label={_form("name-label")} name="name" placeholder={_form("name-placeholder")} />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_form("create")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
