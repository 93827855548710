import { Prose } from "@/components/frontend/prose"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { useTranslation } from "@/store/languages/hooks"
import { match } from "ts-pattern"
import { Header, ItemMappingExport } from "."
import { Container } from "../container"
import { Wrapper } from "../wrapper"

type RenderFC = ItemMappingExport<"richtext">["Render"]

export const Render: RenderFC = props => {
  const { files, item } = props
  const t = useTranslation()
  const { titleLevel, template } = item.props
  const { title, secondary, content } = t(item).props
  if (!oneIsNotEmpty(title, stripeHtml(secondary), stripeHtml(content))) return null

  return (
    <Wrapper margin="normal">
      <Container x="sm" className="flex flex-col gap-8">
        <Header {...{ title, secondary, titleLevel }} />
        {match(template)
          .with(0, () => <Template1 {...{ item, files }} />)
          .otherwise(() => null)}
      </Container>
    </Wrapper>
  )
}

/**
 * templates
 */

const Template1: RenderFC = ({ item }) => {
  const t = useTranslation()
  const content = t(item).props.content
  return oneIsNotEmpty(stripeHtml(content)) ? (
    <Prose
      className="max-w-xl -my-4"
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  ) : null
}
