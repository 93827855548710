import { useFormContext } from "@/components/form"
import { Region } from "@/components/frontend/regions"
import { MapPin } from "lucide-react"

/**
 * FormMap
 */
export const FormMap: React.FC = () => {
  type FormContextValues = {
    coordX: string
    coordY: string
    map: "north" | "south" | "east" | "west" | "center"
  }
  const { setValues, values } = useFormContext<FormContextValues>()
  const mapRef = React.useRef<SVGPathElement>(null)
  const [mapHover, setMapHover] = React.useState(false)
  const [coordinate, setCoordinate] = React.useState<[number, number]>([0, 0])
  const onMouseMove: React.MouseEventHandler<SVGPathElement> = e => {
    if (!mapRef.current) return
    const rect = e.currentTarget.getBoundingClientRect()
    const x = ((e.pageX - (window.scrollX + rect.x)) / rect.width) * 100
    const y = ((e.pageY - (window.scrollY + rect.y)) / rect.height) * 100
    const rounded = 100
    setCoordinate([
      N.clamp(Math.ceil(x * rounded) / rounded, 0, 100),
      N.clamp(Math.ceil(y * rounded) / rounded, 0, 100),
    ])
  }
  return (
    <div className="relative flex w-full justify-center">
      {mapHover && (
        <div className="absolute top-0 right-0 flex items-center px-2 py-1 gap-2 border border-input rounded-md bg-muted text-muted-foreground text-xs text-secondary-500">
          <MapPin aria-hidden size={12} />
          {coordinate[0]}° - {coordinate[1]}°
        </div>
      )}
      <div className="relative w-full max-w-96 overflow-hidden">
        <Region
          region={values.map}
          ref={mapRef}
          pathClassName={"hover:cursor-crosshair transition-colors"}
          onClick={() => setValues({ coordX: `${coordinate[0]}`, coordY: `${coordinate[1]}` })}
          onMouseMove={onMouseMove}
          onMouseEnter={() => setMapHover(true)}
          onMouseLeave={() => setMapHover(false)}
        />
        {(+values.coordX > 0 || +values.coordY > 0) && (
          <div
            className={cx(
              "absolute w-2 h-2 rounded-full -translate-x-1/2 -translate-y-1/2 pointer-events-none transition-all duration-500",
              "bg-primary shadow-[0px_0px_5px_0px_hsl(var(--primary)/0.75)]"
            )}
            style={{
              top: `${values.coordY}%`,
              left: `${values.coordX}%`,
            }}
          />
        )}
      </div>
    </div>
  )
}
