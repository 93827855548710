import { z } from "zod"
import { apiMediasFile } from "../medias/schemas"

/**
 * ApiSeoSocialMeta
 */
export const apiSeoSocialMetaType = z.union([z.literal("facebook"), z.literal("twitter"), z.literal("instagram")])
export const apiSeoSocialMeta = z.object({
  type: apiSeoSocialMetaType,
  title: z.string(),
  description: z.string(),
  imageId: z.string().uuid().nullable(),
})
export type ApiSeoSocialMeta = z.infer<typeof apiSeoSocialMeta>

/**
 * ApiSeoTranslation
 */
export const apiSeoTranslation = z.object({
  id: z.string().uuid(),
  title: z.string(),
  description: z.string(),
  keywords: z.array(z.string()),
  socials: apiSeoSocialMeta.array(),
  seoId: z.string().uuid(),
  languageId: z.string().uuid(),
  imageId: z.string().uuid().nullable(),
  image: apiMediasFile.nullable().optional(),
})
export type ApiSeoTranslation = z.infer<typeof apiSeoTranslation>

/**
 * ApiSeo
 */
export const apiSeo = z.object({
  id: z.string().uuid(),
  translations: apiSeoTranslation.array(),
  files: apiMediasFile.array(),
})
export type ApiSeo = z.infer<typeof apiSeo>
