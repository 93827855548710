import { ChevronLeft, ChevronRight } from "lucide-react"
import { ButtonProps, buttonVariants } from "../ui/button"

/**
 * dictionary src/dictionaries/en/components/layout.json
 */
const dictionary = createContextMapper("components", "layout")

// page: number;
// setPage: Dispatch<SetStateAction<number>>;
// byPage: number;
// setByPage: Dispatch<SetStateAction<number>>;
// total: number;
// pages: number;
// next: () => void;
// prev: () => void;

/**
 * Pagination
 */
export const Pagination: React.FC<{
  page: number
  setPage: (page: number) => void
  pages: number
  byPage: number
  before?: number
  after?: number
  className?: ClassName
}> = ({ page, setPage, pages, before = 2, after = 2, className }) => {
  const buttons = React.useMemo(() => Array.from({ length: pages }, (_, i) => i + 1), [pages])
  React.useEffect(() => {
    if (page > pages) setPage(pages)
  }, [pages])
  const { _ } = useDictionary(dictionary("pagination"))
  return pages > 1 ? (
    <ul className={cx("flex w-full justify-center flex-wrap gap-1", className)}>
      <PaginationButton onClick={() => setPage(1)} disabled={page > before + 1}>
        {1}
      </PaginationButton>
      <PaginationButton icon size={"xs"} onClick={() => setPage(page - 1)} disabled={page > 1}>
        <ChevronLeft size={16} aria-label={_("previous")} />
      </PaginationButton>
      {A.map(buttons, number => (
        <PaginationButton
          key={number}
          onClick={() => setPage(number)}
          isActive={page === number}
          disabled={page >= number - before && page <= number + after}
        >
          {number}
        </PaginationButton>
      ))}
      <PaginationButton icon onClick={() => setPage(page + 1)} disabled={pages > page}>
        <ChevronRight aria-label={_("next")} />
      </PaginationButton>
      <PaginationButton onClick={() => setPage(pages)} disabled={page < pages - after}>
        {pages}
      </PaginationButton>
    </ul>
  ) : null
}

/**
 * PaginationButton
 */
interface PaginationButtonProps extends ButtonProps {
  disabled?: boolean
  isActive?: boolean
}
const PaginationButton: React.FC<PaginationButtonProps> = ({
  className,
  disabled: show = false,
  isActive = false,
  size = "xs",
  icon,
  children,
  ...props
}) => {
  return show ? (
    <li>
      <button
        className={cx(
          buttonVariants({
            variant: isActive ? "outline" : "ghost",
            size,
            icon,
          }),
          "min-w-[32px] [&>svg]:size-4 duration-0",
          className
        )}
        {...props}
      >
        {children}
      </button>
    </li>
  ) : undefined
}
