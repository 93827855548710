import { Loader } from "@/components/collection"
import { PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { usePromise } from "@/hooks/usePromise"
import { SelectProvider, useSelect } from "@/hooks/useSelect"
import { getLanguages } from "@/store/languages/actions"
import { Collection } from "./Collection"
import { Context, ContextProvider } from "./Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/languages.json
 */
const dictionary = createContextMapper("pages", "dashboard", "languages")

/**
 * Page: LanguagesIndex
 * dictionary src/dictionaries/en/pages/dashboard/languages.json
 */
const LanguagesIndex: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const select = useSelect(Context)
  const [, inProgress] = usePromise(getLanguages)
  return inProgress ? (
    <Loader />
  ) : (
    <PageWrapper ref={select.ref}>
      <SelectProvider {...select.props} />
      <PageHeader breadcrumbs={[[_("breadcrumbs"), "/dashboard/languages"]]} />
      <Collection />
    </PageWrapper>
  )
}
export default () => (
  <ContextProvider>
    <LanguagesIndex />
  </ContextProvider>
)
