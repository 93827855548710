import { Prose } from "@/components/frontend/prose"
import { HN } from "@/components/ui/hn"
import { Image } from "@/components/ui/image"
import { LinkExternal } from "@/components/ui/link"
import { oneIsNotEmpty, stripeHtml } from "@/fns/String"
import { ContentItemBase } from "@/store/contents/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { getPreview } from "@/store/medias/helpers"
import { useMediasFile } from "@/store/medias/hooks"
import { ImageOff } from "lucide-react"
import { frontendButtonVariants } from "../../frontend/button"
import { container } from "../../frontend/container"
import { Header } from "../../frontend/header"
import { ContentItemMapping, ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/en/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  const { titleLevel, cards } = item.props
  const { title, secondary } = t(item).props

  return (
    <div className={cx("flex flex-col py-8 gap-8 font-plus", container({ x: "sm" }))}>
      <Header {...{ title, secondary, titleLevel }} />
      <div
        className={cx(
          "grid gap-8",
          A.includes([1, 2, 4], cards.length) ? "grid-cols-2" : "grid-cols-3"
        )}
      >
        {A.map(cards, id => (
          <Template1 item={item} id={id} key={id} />
        ))}
      </div>
    </div>
  )
}

/**
 * templates
 */
type TemplateProps = { item: ContentItemBase & ContentItemMapping[ItemType]; id: string }
const Template1: React.FC<TemplateProps> = ({ item, id }) => {
  const t = useTranslation()
  const { titleLevel } = item.props
  const props = D.get(item.props.cardsProps, id)
  const translation = D.get(t(item).props.cards, id)

  const image = useMediasFile(props?.image)

  if (G.isNullable(props) || G.isNullable(translation)) return null
  const { title, secondary, content, linkText, linkUrl } = translation

  return (
    <article className="relative flex flex-col items-stretch rounded-md bg-white text-gray-800 shadow-md">
      <div className="relative flex aspect-video rounded-t-md overflow-hidden">
        <Image
          src={getPreview(image)}
          alt={image ? t(image).alt : title}
          className="absolute inset-0 size-full object-cover rounded-t-md text-muted-foreground"
        >
          <ImageOff size={64} strokeWidth={1} />
        </Image>
        <div
          className="absolute inset-0 size-full bg-gradient-to-b from-[#002c41]/20 from-5% to-[#002c41]/80 to-80%"
          aria-hidden
        />
        {oneIsNotEmpty(title, secondary) && (
          <div className="relative flex flex-col justify-stretch size-full p-4">
            <div className="grow">
              {oneIsNotEmpty(secondary) && (
                <p className="inline-flex min-h-[1rem] mx-auto px-4 py-2 rounded-full backdrop-blur-sm bg-frontend-bluewhale/40 uppercase text-white text-xs leading-none font-semibold">
                  {secondary}
                </p>
              )}
            </div>
            {oneIsNotEmpty(title) && (
              <HN level={titleLevel + 1} className="text-white text-xl font-bold">
                {title}
              </HN>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-stretch items-stretch gap-4 p-4">
        {oneIsNotEmpty(stripeHtml(content)) && (
          <Prose
            className="max-w-xl -my-4"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        )}
        {S.isNotEmpty(linkText) && S.isNotEmpty(linkUrl) && (
          <div className="fle justify-start">
            <LinkExternal
              href={linkUrl}
              className={frontendButtonVariants({
                variant: "dark",
                size: "sm",
                className: "text-sm",
              })}
            >
              {linkText}
            </LinkExternal>
          </div>
        )}
      </div>
    </article>
  )
}
