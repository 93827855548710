import { ApiPlace } from "@/services/places/schemas"
import { ApiWorkshop, ApiWorkshopEvent, ApiWorkshopReservation } from "@/services/workshops/schemas"

/**
 * extractPlacesFromWorkshops
 */
export const extractPlacesFromWorkshops = (workshops: ApiWorkshop[]): ApiPlace[] =>
  A.reduce(workshops, [] as ApiPlace[], (list, workshop) => [...list, workshop.place])

/**
 * extractEventsFromWorkshops
 */
export const extractEventsFromWorkshops = (workshops: ApiWorkshop[]): ApiWorkshopEvent[] =>
  A.reduce(workshops, [] as ApiWorkshopEvent[], (list, workshop) => [...list, ...workshop.events])

/**
 * extractReservationFromEvents
 */
export const extractReservationFromEvents = (
  events: ApiWorkshopEvent[]
): ApiWorkshopReservation[] =>
  A.reduce(events, [] as ApiWorkshopReservation[], (list, event) => [
    ...list,
    ...event.reservations,
  ])
