/// <reference types="vite-plugin-svgr/client" />
import { Card } from "@/components/ui/card"
import { Image } from "@/components/ui/image"
import { Link } from "@/components/ui/link"
import { useDictionary } from "@/dictionaries/hooks"
import { useInterval } from "@/hooks/useInterval"
import { useLiveState } from "@/hooks/useLiveState"
import { useUsersStore } from "@/store/users"
import { getLastestUsers, getUsers } from "@/store/users/actions"
import { User } from "@/store/users/localizers"
import { motion } from "framer-motion"
import { UserIcon } from "lucide-react"
import { getFullname } from "./users/Item"

type Props = {
  offset?: number
  scaleFactor?: number
}
/**
 * dictionary src/dictionaries/en/pages/dashboard/users.json
 */
const dictionary = createContextMapper("pages", "dashboard", "users")

export const LastestUsers: React.FC<Props> = ({ offset, scaleFactor }) => {
  const { _ } = useDictionary(dictionary())
  const totalUsers = useUsersStore(({ users }) => D.values(users).length)
  useLiveState(async () => {
    const [response] = await Promise.all([getLastestUsers(), getUsers()])
    if (response.error) return
    setUsers(response.users)
  })

  const CARD_OFFSET = offset || 10
  const SCALE_FACTOR = scaleFactor || 0.06
  const [users, setUsers] = React.useState<User[]>([])

  useInterval(() => {
    setUsers((prevCards: User[]) => {
      const newArray = [...prevCards] // create a copy of the array
      newArray.unshift(newArray.pop()!) // move the last element to the front
      return newArray
    })
  }, 7000)

  return (
    <div className="grid grid-rows-[1fr_auto] gap-4">
      <Card.Spotlight className="grid grid-rows-[auto_1fr]">
        <Card.Header>
          <Card.Title>{_("lastest-users.title")}</Card.Title>
          <Card.Description className="line-clamp-3">
            {_("lastest-users.secondary")}
          </Card.Description>
        </Card.Header>
        <Card.Content className="flex items-center">
          <div className="relative w-3/4 mx-auto min-h-[70px]">
            {users.map((user, index) => {
              return (
                <motion.div
                  key={user.id}
                  className="absolute inset-0 grid size-full"
                  style={{
                    transformOrigin: "top center",
                  }}
                  animate={{
                    top: index * -CARD_OFFSET,
                    scale: 1 - index * SCALE_FACTOR, // decrease scale for cards that are behind
                    zIndex: users.length - index, //  decrease z-index for the cards that are behind
                  }}
                >
                  <div
                    className={cx(
                      "flex items-center justify-stretch py-4 px-4 min-h-[4rem] gap-4 rounded-lg bg-card text-card-foreground border border-muted shadow-sm transition-colors",
                      index === 0 && "hover:border-primary"
                    )}
                  >
                    <Image
                      src={user.profile.thumbnail ?? user.profile.preview ?? user.profile.image}
                      className="w-8 h-8 rounded-full object-cover"
                      wrapperCx=""
                    >
                      <UserIcon size={16} />
                    </Image>
                    <div className="flex flex-col grow space-y-1">
                      <h3 className="flex text-base font-medium leading-none tracking-tight">
                        {getFullname(user, _("fullname-placeholder"))}
                      </h3>
                      <p className="text-xs font-light text-muted-foreground">
                        {S.trim(`${user.profile.position} ${user.profile.company}`)}
                      </p>
                    </div>
                    {index === 0 && (
                      <Link
                        href={`/dashboard/users/${user.id}`}
                        className="absolute inset-0 size-full"
                      />
                    )}
                  </div>
                </motion.div>
              )
            })}
          </div>
        </Card.Content>
      </Card.Spotlight>

      <Card.Spotlight>
        <Card.Header>
          <Card.Title>{_("total-users.title")}</Card.Title>
          <Card.Description className="line-clamp-3">{_("total-users.secondary")}</Card.Description>
        </Card.Header>
        <Card.Content>
          <div className="relative">
            <div className="grid grid-cols-3 gap-6" aria-hidden>
              {A.map([1, 2, 3], i => (
                <div className="aspect-square" key={i} />
              ))}
            </div>
            <Link href="/dashboard/users">
              <div className="absolute inset-0 z-20 size-full flex flex-col justify-center items-center dark:bg-black/50 bg-white border hover:border-primary rounded-md shadow-sm">
                <span className="text-2xl @xs/card:text-3xl @sm/card:text-4xl @md/card:text-5xl @lg/card:text-6xl font-medium text-primary">
                  {totalUsers}
                </span>
                <span className="text-[10px] @xs/card:text-xs @lg/card:text-sm text-foreground/50">
                  {_("total-users.total")}
                </span>
              </div>
            </Link>
          </div>
        </Card.Content>
      </Card.Spotlight>
    </div>
  )
}
