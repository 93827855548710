import { Button } from "@/components/frontend/button"
import { Project } from "@/store/frontend/localizers"
import { dictionary, htmlIsNotEmpty } from "."

/**
 * TableOfContents
 */
export const TableOfContents: React.FC<{ project: Project }> = ({ project }) => {
  const { _ } = useDictionary(dictionary())
  return (
    <div className="sticky top-0 py-10 hidden lg:block">
      <ul className=" flex flex-col gap-2">
        <ListItem to="description">{_("title-description")}</ListItem>
        <ListItem to="skills" on={A.isNotEmpty(project.skills)}>
          {_("title-skills")}
        </ListItem>
        <ListItem to="objective" on={htmlIsNotEmpty(project.objective)}>
          {_("title-objective")}
        </ListItem>
        <ListItem to="materials" on={A.isNotEmpty(project.materials)}>
          {_("title-materials")}
        </ListItem>
        <ListItem to="preparation" on={htmlIsNotEmpty(project.preparation)}>
          {_("title-preparation")}
        </ListItem>
        <ListItem to="follow" on={htmlIsNotEmpty(project.follow)}>
          {_("title-follow")}
        </ListItem>
        <ListItem to="values" on={htmlIsNotEmpty(project.values)}>
          {_("title-values")}
        </ListItem>
        <ListItem to="documents" on={D.isNotEmpty(project.documents)}>
          {_("title-documents")}
        </ListItem>
        <ListItem to="images" on={D.isNotEmpty(project.images)}>
          {_("title-images")}
        </ListItem>
      </ul>
    </div>
  )
}

/**
 * components
 */
type ListItemProps = { to: string; on?: boolean } & React.HTMLAttributes<HTMLElement>
const ListItem: React.FC<ListItemProps> = ({ className, to, on = true, children, ...props }) => {
  const scrollTo: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault()
    document
      .querySelector(`#${to}`)
      ?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }
  return on ? (
    <li className={className} {...props}>
      <Button
        href={`#${to}`}
        type="link"
        variant="secondary"
        onClick={scrollTo}
        className="w-full justify-start"
      >
        {children}
      </Button>
    </li>
  ) : null
}
