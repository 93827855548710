import { z } from "zod"
import { AdminErrorCode, AuthErrorCode, NoContent, api } from "../api"
import {
  ApiMediasBaseFolder,
  ApiMediasFile,
  ApiMediasFolder,
  apiMediasBaseFolder,
  apiMediasFile,
  apiMediasFolder,
} from "./schemas"

/**
 * schemas
 */
const createFolderPayload = z.object({
  name: z.string(),
  parentId: z.string().uuid().nullable(),
  lock: z.boolean().optional(),
})
const updateFolderPayload = z.object({
  name: z.string().optional(),
  parentId: z.string().uuid().nullable().optional(),
  lock: z.boolean().optional(),
})
const createFilePayload = z.object({
  folderId: z.string().uuid().nullable(),
  file: z.instanceof(File),
})
const updateFilePayload = z.object({
  folderId: z.string().uuid().nullable().optional(),
  file: z.instanceof(File).optional(),
  translations: z
    .array(
      z.object({
        languageId: z.string().uuid(),
        name: z.string(),
        caption: z.string(),
        alt: z.string(),
      })
    )
    .optional(),
})
const cropFilePayload = z.object({
  transform: z.object({
    width: z.number(),
    height: z.number(),
    x: z.number(),
    y: z.number(),
    rotate: z.number(),
    cropper: z.object({
      zoom: z.number(),
      crop: z.object({
        x: z.number(),
        y: z.number(),
      }),
      aspect: z.object({
        w: z.number(),
        h: z.number(),
      }),
    }),
  }),
})
export type Payload = {
  folders: {
    create: z.infer<typeof createFolderPayload>
    update: z.infer<typeof updateFolderPayload>
  }
  files: {
    create: z.infer<typeof createFilePayload>
    update: z.infer<typeof updateFilePayload>
    crop: z.infer<typeof cropFilePayload>
  }
}

/**
 * service
 */
export const service = {
  index: async () => {
    type RSuccess = { folders: ApiMediasFolder[]; files: ApiMediasFile[] }
    type RError = AuthErrorCode
    const { success, data } = await api.get<RSuccess, RError>("medias")
    if (!success) return { data: null, error: true, code: data.code } as const
    return {
      data: {
        folders: A.map(data.folders, apiMediasFolder.parse),
        files: A.map(data.files, apiMediasFile.parse),
      },
      error: false,
    } as const
  },
  folders: {
    /**
     * index return all folders without relation to files or subfolders
     */
    index: async () => {
      type RSuccess = { folders: ApiMediasBaseFolder[] }
      type RError = AuthErrorCode
      const { success, data } = await api.get<RSuccess, RError>("medias/folders")
      if (!success) return { data: null, error: true, code: data.code } as const
      try {
        return { data: { folders: A.map(data.folders, apiMediasBaseFolder.parse) }, error: false } as const
      } catch (error) {
        return { data: null, error: true, code: '"medias/folders" parse error' } as const
      }
    },
    create: async (payload: Payload["folders"]["create"]) => {
      type RSuccess = { folder: ApiMediasFolder }
      type RError = AuthErrorCode<"VALIDATION_FAILURE">
      const endPoint = G.isNotNullable(payload.parentId)
        ? `medias/folders/${payload.parentId}/folders`
        : "medias/folders"
      const { success, data } = await api.post<RSuccess, RError>(endPoint, {
        data: createFolderPayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { folder: apiMediasFolder.parse(data.folder) }, error: false } as const
    },
    read: async (folderId: string) => {
      type RSuccess = { folder: ApiMediasFolder }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`medias/folders/${folderId}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { folder: apiMediasFolder.parse(data.folder) }, error: false } as const
    },
    update: async (folderId: string, payload: Payload["folders"]["update"]) => {
      type RSuccess = { folder: ApiMediasFolder }
      type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
      const { success, data } = await api.put<RSuccess, RError>(`medias/folders/${folderId}`, {
        data: updateFolderPayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { folder: apiMediasFolder.parse(data.folder) }, error: false } as const
    },
    delete: async (folderId: string) => {
      type RSuccess = NoContent
      type RError = AdminErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.delete<RSuccess, RError>(`medias/folders/${folderId}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: null, error: false } as const
    },
  },
  files: {
    create: async (payload: Payload["files"]["create"]) => {
      type RSuccess = { file: ApiMediasFile }
      type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
      const endPoint = G.isNotNullable(payload.folderId) ? `medias/folders/${payload.folderId}/files` : "medias/files"
      const { success, data } = await api.post<RSuccess, RError>(endPoint, {
        form: createFilePayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { file: apiMediasFile.parse(data.file) }, error: false } as const
    },
    read: async (fileId: string) => {
      type RSuccess = { file: ApiMediasFile }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.get<RSuccess, RError>(`medias/files/${fileId}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { file: apiMediasFile.parse(data.file) }, error: false } as const
    },
    update: async (fileId: string, payload: Payload["files"]["update"]) => {
      type RSuccess = { file: ApiMediasFile }
      type RError = AuthErrorCode<"VALIDATION_FAILURE" | "RESOURCE_NOT_FOUND">
      const { folderId, translations, file } = updateFilePayload.parse(payload)
      // send folderId and translations as json data
      if (!G.isUndefined(folderId) || G.isNotNullable(translations)) {
        const { success, data } = await api.put<RSuccess, RError>(`medias/files/${fileId}`, {
          data: { folderId, translations },
        })
        if (!success) return { data: null, error: true, code: data.code } as const
        if (G.isNullable(file)) return { data: { file: apiMediasFile.parse(data.file) }, error: false } as const
      }
      // send file as form data
      if (G.isNotNullable(file)) {
        const { success, data } = await api.put<RSuccess, RError>(`medias/files/${fileId}`, {
          form: { file },
        })
        if (!success) return { data: null, error: true, code: data.code } as const
        return { data: { file: apiMediasFile.parse(data.file) }, error: false } as const
      }
      // in case of nothing to update to don't throw an error and keep return types consistent
      const { success, data } = await api.get<RSuccess, RError>(`medias/files/${fileId}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { file: apiMediasFile.parse(data.file) }, error: false } as const
    },
    delete: async (fileId: string) => {
      type RSuccess = NoContent
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.delete<RSuccess, RError>(`medias/files/${fileId}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: null, error: false } as const
    },
    copy: async (fileId: string) => {
      type RSuccess = { file: ApiMediasFile }
      type RError = AuthErrorCode<"RESOURCE_NOT_FOUND">
      const { success, data } = await api.post<RSuccess, RError>(`medias/files/${fileId}`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { file: apiMediasFile.parse(data.file) }, error: false } as const
    },
    crop: async (fileId: string, payload: Payload["files"]["crop"]) => {
      type RSuccess = { file: ApiMediasFile }
      type RError = AuthErrorCode<
        "VALIDATION_FAILURE" | "FILE_IS_NOT_CROPABLE" | "UNABLE_TO_CROP" | "RESOURCE_NOT_FOUND"
      >
      const { success, data } = await api.put<RSuccess, RError>(`medias/files/${fileId}/crop`, {
        data: cropFilePayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { file: apiMediasFile.parse(data.file) }, error: false } as const
    },
    uncrop: async (fileId: string) => {
      type RSuccess = { file: ApiMediasFile }
      type RError = AuthErrorCode<"FILE_IS_NOT_CROPABLE" | "FILE_IS_NOT_CROP" | "RESOURCE_NOT_FOUND">
      const { success, data } = await api.delete<RSuccess, RError>(`medias/files/${fileId}/crop`)
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { file: apiMediasFile.parse(data.file) }, error: false } as const
    },
    copyAndCrop: async (fileId: string, payload: Payload["files"]["crop"]) => {
      type RSuccess = { file: ApiMediasFile }
      type RError = AuthErrorCode<
        "VALIDATION_FAILURE" | "FILE_IS_NOT_CROPABLE" | "UNABLE_TO_CROP" | "RESOURCE_NOT_FOUND"
      >
      const { success, data } = await api.post<RSuccess, RError>(`medias/files/${fileId}/crop`, {
        data: cropFilePayload.parse(payload),
      })
      if (!success) return { data: null, error: true, code: data.code } as const
      return { data: { file: apiMediasFile.parse(data.file) }, error: false } as const
    },
  },
}
