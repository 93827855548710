import { ApiMe } from "@/services/auth/schemas"
import { localizeUser } from "../users/localizers"

/**
 * localizeMe
 */
export const localizeMe = ({ rememberMeToken, ...parsed }: ApiMe) => ({
  rememberMeToken,
  ...localizeUser(parsed),
})
export type Me = ReturnType<typeof localizeMe>
