import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { normString } from "@/fns/search"
import { useFilterable } from "@/hooks/useFilterable"
import { useLimitable } from "@/hooks/useLimitable"
import { useMatchable } from "@/hooks/useSearchable"
import { useSortable } from "@/hooks/useSortable"
import { useArticlesStore } from "."
import { useTranslation } from "../languages/hooks"
import { Article, ArticleCategory } from "./localizers"

export const useArticlesById = () => {
  const byIds = useArticlesStore(D.prop("articles"))
  return byIds
}
export const useArticleCategoriesById = () => {
  const byIds = useArticlesStore(D.prop("categories"))
  return byIds
}
export const useArticles = () => {
  const byIds = useArticlesById()
  const articles = React.useMemo(() => D.values(byIds), [byIds])
  return articles
}
export const useArticleCategories = () => {
  const byIds = useArticleCategoriesById()
  const categories = React.useMemo(() => D.values(byIds), [byIds])
  return categories
}
export const useArticle = (id: Option<string>) => {
  const article = useArticlesStore(flow(D.prop("articles"), D.get(id ?? "")))
  return article
}
export const useArticleCategory = (id: Option<string>) => {
  const category = useArticlesStore(flow(D.prop("categories"), D.get(id ?? "")))
  return category
}

/**
 * articles collection
 */
export const useFilteredArticles = (
  types: Article["type"][],
  initialLimit = 24,
  initialByMore = 12
) => {
  const articles = useArticles()
  const categoriesById = useArticleCategoriesById()
  const format = useDateFnsLocaleFormat()
  const t = useTranslation()

  const [filterable, filterBy] = useFilterable<Article>("articles", {}, {})

  const [matchable, matchIn] = useMatchable<Article>([
    ({ seo }) => t(seo).title,
    ({ seo }) => t(seo).description,
    ({ category }) =>
      G.isNotNullable(D.get(categoriesById, category ?? ""))
        ? t(D.getUnsafe(categoriesById, category ?? "")).name
        : "",
    ({ createdAt }) => normString(format(createdAt, "Pp")),
    ({ createdAt }) => normString(format(createdAt, "PPPP")),
  ])

  const [sortable, sortBy] = useSortable<Article>(
    "articles",
    {
      title: ({ seo }) => t(seo).title,
      category: ({ category }) =>
        G.isNotNullable(D.get(categoriesById, category ?? ""))
          ? t(D.getUnsafe(categoriesById, category ?? "")).name
          : "",
      createdAt: ({ createdAt }) => createdAt,
      updatedAt: ({ updatedAt }) => updatedAt,
    },
    "title"
  )
  const filtered = React.useMemo(
    () =>
      pipe(
        articles,
        items => A.filter(items, item => types.includes(item.type)),
        filterBy,
        S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn
      ),
    [articles, filterBy, matchable.search, matchIn, sortBy]
  )

  const [limitable, limit] = useLimitable<Article>(filtered.length, initialLimit, initialByMore)

  return { articles, filtered, filterable, matchable, sortable, limitable, limit }
}

/**
 * categories collection
 */
export const useFilteredArticleCategories = (initialLimit = 24, initialByMore = 12) => {
  const categories = useArticleCategories()
  const t = useTranslation()

  const [filterable, filterBy] = useFilterable<ArticleCategory>("article-categories", {}, {})

  const [matchable, matchIn] = useMatchable<ArticleCategory>([category => t(category).name])

  const [sortable, sortBy] = useSortable<ArticleCategory>(
    "article-categories",
    {
      name: category => t(category).name,
    },
    "name"
  )
  const filtered = React.useMemo(
    () => pipe(categories, filterBy, S.isEmpty(S.trim(matchable.search)) ? sortBy : matchIn),
    [categories, filterBy, matchable.search, matchIn, sortBy]
  )

  const [limitable, limit] = useLimitable<ArticleCategory>(
    filtered.length,
    initialLimit,
    initialByMore
  )

  return { categories, filtered, filterable, matchable, sortable, limitable, limit }
}

/**
 * options
 */
export const useCategoryOptions = (placeholder: string) => {
  const categories = useArticleCategories()
  const t = useTranslation()
  return React.useMemo(
    () => [
      { label: placeholder, value: "null" },
      ...A.map(
        A.sortBy(categories, category => t(category).name),
        category => ({
          label: t(category).name,
          value: category.id,
        })
      ),
    ],
    [categories, placeholder, t]
  )
}
export const useStateOptions = () => {
  const _ = useFormDictionary()
  return React.useMemo(
    () => [
      {
        label: _("state-draft"),
        value: "draft",
      },
      {
        label: _("state-published"),
        value: "published",
      },
    ],
    [_]
  )
}
export const useTypeOptions = (types: Article["type"][]) => {
  // dictionary src/dictionaries/en/pages/dashboard/articles.json
  const { _ } = useDictionary(contextMapper("pages", "dashboard", "articles"))
  return React.useMemo(() => types.map(value => ({ label: _(`type-${value}`), value })), [_, types])
}
