/// <reference types="vite-plugin-svgr/client" />
import Dance from "@/assets/icons/dance.svg?react"
import { Fields, Loader, Menu } from "@/components/collection"
import { Prose } from "@/components/frontend/prose"
import { PageContent, PageHeader, PageWrapper } from "@/components/layout/dashboard"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { CardAccordion } from "@/components/ui/card-accordion"
import { Image } from "@/components/ui/image"
import { useLightboxFiles } from "@/components/ui/lightbox"
import { Link, linkCx } from "@/components/ui/link"
import { SrOnly } from "@/components/ui/sr-only"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDateFnsLocaleFormat, useDictionary } from "@/dictionaries/hooks"
import { truncateText } from "@/fns/String"
import { prependProtocol } from "@/fns/path"
import { usePromise } from "@/hooks/usePromise"
import { getProject, updateProject } from "@/store/projects/actions"
import { useProject } from "@/store/projects/hooks"
import { Project } from "@/store/projects/localizers"
import {
  ArrowSquareOut,
  CheckCircle,
  ClockCountdown,
  DownloadSimple,
  Globe,
  MaskHappy,
  MusicNotes,
  NotePencil,
  Signpost,
  Student,
  TrashSimple,
  UserCircleGear,
  UserCirclePlus,
  XCircle,
} from "@phosphor-icons/react"
import saveAs from "file-saver"
import {
  BarChart3,
  CircleCheck,
  CircleX,
  ImageOff,
  MoreVertical,
  Projector,
  Rocket,
} from "lucide-react"
import { Redirect } from "wouter"
import { ContextProvider, usePageContext } from "../Context"

/**
 * dictionary src/dictionaries/en/pages/dashboard/projects.json
 */
const dictionary = createContextMapper("pages", "dashboard", "projects")

/**
 * page: DashboardProjectsProject
 */
type Props = { project: Project }
const DashboardProjectsProject: React.FC<Props> = props => {
  const { project } = props
  const { _ } = useDictionary(dictionary())

  return (
    <PageWrapper>
      <PageHeader
        breadcrumbs={[
          [_("breadcrumbs"), "/dashboard/projects"],
          [project.name, `/dashboard/projects/${project.id}`],
        ]}
      ></PageHeader>
      <PageContent className="@container/card">
        <CardAccordion defaultValue={"meta"} group={`project-${project.id}`}>
          <SectionMeta {...props} />
          <SectionContent {...props} />
        </CardAccordion>
      </PageContent>
    </PageWrapper>
  )
}

type PageProps = { id: string }
export default ({ id }: PageProps) => {
  const { _ } = useDictionary(dictionary())
  const project = useProject(id)
  const [result, inProgress] = usePromise(() => getProject(id))
  if (inProgress) return <Loader breadcrumbs={[[_("breadcrumbs"), "/dashboard/projects"]]} />
  if (!result || result.error || G.isNullable(project)) return <Redirect to="/dashboard/projects" />
  return (
    <ContextProvider>
      <DashboardProjectsProject project={project} />
    </ContextProvider>
  )
}

/**
 * SectionMeta
 */
const SectionMeta: React.FC<Props> = props => {
  const { project } = props
  const { _ } = useDictionary(dictionary())
  const format = useDateFnsLocaleFormat()
  const ctx = usePageContext()
  return (
    <Menu type="context-menu" menu={<ContextMenu {...props} />} asChild>
      <CardAccordion.Item value="meta">
        <div className="relative w-full">
          <Image
            src={project.image?.url}
            className="aspect-[36/9] object-cover w-full rounded-t-md"
          >
            <ImageOff size={64} strokeWidth={0.8} aria-hidden className="text-muted-foreground" />
          </Image>
          <div className="absolute bottom-0 right-0 flex justify-center items-center px-6 py-3 gap-2 rounded-tl-lg bg-secondary/80 text-secondary-forground text-sm [&>svg]:w-4 [&>svg]:h-4">
            {project.category === "dance" && <Dance aria-hidden />}
            {project.category === "theater" && <MaskHappy aria-hidden />}
            {project.category === "music" && <MusicNotes aria-hidden />}
            <span className="line-clamp-1">
              <span className="font-semibold hidden sm:inline">{_("category")} : </span>
              {_(`category-${project.category}`)}
            </span>
          </div>
        </div>
        <CardAccordion.Header>
          <CardAccordion.Header.Title>
            <Rocket size={16} aria-hidden />
            {_("section-meta-title")}
          </CardAccordion.Header.Title>
          <CardAccordion.Header.Aside className="relative">
            <Badge
              variant={
                project.status === "accepted"
                  ? "success"
                  : project.status === "pending"
                  ? "secondary"
                  : "destructive"
              }
            >
              {project.status === "accepted" && <CircleCheck aria-label={_(`status-accepted`)} />}
              {project.status === "refused" && <CircleX aria-label={_(`status-refused`)} />}
              {project.status === "pending" && <ClockCountdown aria-label={_(`status-pending`)} />}
              {_(`status-${project.status}`)}
            </Badge>
            <Button
              variant="secondary"
              icon
              size="xxs"
              onClick={() => ctx.openStats(project.trackingId)}
              aria-label={_("menu.open-stats")}
            >
              <BarChart3 />
            </Button>
            <Menu menu={<ContextMenu {...props} />} type="dropdown-menu" align="start" side="left">
              <Button variant="ghost" size="xs" icon>
                <MoreVertical aria-hidden />
              </Button>
            </Menu>
          </CardAccordion.Header.Aside>
        </CardAccordion.Header>
        <CardAccordion.Content className="flex flex-col gap-4 pt-4 px-8 pb-8">
          <h1 className="text-lg font-medium leading-none tracking-tight">{project.name}</h1>
          <Prose className="max-w-xl">{project.description}</Prose>
          <Fields divider>
            <Fields.Item
              name={_("orientation")}
              icon={<Signpost aria-hidden />}
              value={_(`orientation-${project.orientation}`)}
            />
            <Fields.Item
              name={_("language")}
              icon={<Globe aria-hidden />}
              value={_(`language-${project.language as "fr" | "de" | "en"}`)}
            />
            {G.isNotNullable(project.createdBy) && (
              <Fields.Item
                name={`${_("created")} ${_("created-by")}`}
                icon={<UserCirclePlus aria-hidden />}
                value={
                  <>
                    <Link href={`/dashboard/users/${project.createdBy.id}`} className={linkCx}>
                      {`${project.createdBy.profile.firstname} ${project.createdBy.profile.lastname}`}
                    </Link>
                    <span className="text-xs text-muted-foreground">
                      {`${project.createdBy.profile.company} - ${project.createdBy.profile.position}`}
                    </span>
                    <span className="text-xs">
                      {`${_("created-at")} ${format(project.createdAt, "PPp")}`}
                    </span>
                  </>
                }
              />
            )}
            {G.isNotNullable(project.validatedBy) && (
              <Fields.Item
                name={_("validated-by")}
                icon={<UserCircleGear aria-hidden />}
                value={
                  <>
                    <Link href={`/dashboard/users/${project.validatedBy.id}`} className={linkCx}>
                      {`${project.validatedBy.profile.firstname} ${project.validatedBy.profile.lastname}`}
                    </Link>
                    <span className="text-xs text-muted-foreground">
                      {`${project.validatedBy.profile.company} - ${project.validatedBy.profile.position}`}
                    </span>
                    {G.isNotNullable(project.validatedAt) && (
                      <span className="text-xs">
                        {`${_("created-at")} ${format(project.validatedAt, "PPp")}`}
                      </span>
                    )}
                  </>
                }
              />
            )}
          </Fields>
        </CardAccordion.Content>
      </CardAccordion.Item>
    </Menu>
  )
}

/**
 * SectionContent
 */
const SectionContent: React.FC<{ project: Project }> = ({ project }) => {
  const { _ } = useDictionary(dictionary())
  const { _: _front } = useDictionary(contextMapper("pages", "projects")) // dictionary src/dictionaries/en/pages/projects.json
  const { partners, classes, skills } = project
  const { renderLightbox, lightbox } = useLightboxFiles(D.values(project.images))
  return (
    <CardAccordion.Item value="content">
      <CardAccordion.Header>
        <CardAccordion.Header.Title>
          <Projector size={16} aria-hidden />
          {_("section-content-title")}
        </CardAccordion.Header.Title>
        <CardAccordion.Header.Aside />
      </CardAccordion.Header>
      <CardAccordion.Content className="flex flex-col gap-4 pt-4 px-8 pb-8">
        <div className="flex flex-wrap gap-2.5">
          {A.mapWithIndex(partners, (index, partner) => (
            <Tag
              icon={<ArrowSquareOut aria-hidden size={20} weight="bold" />}
              label={partner.name}
              key={index}
            >
              <a
                href={prependProtocol(partner.value)}
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="underline"
              >
                {partner.value}
              </a>
            </Tag>
          ))}
          {A.mapWithIndex(classes, (index, { name, value }) => (
            <Tag icon={<Student aria-hidden size={20} weight="bold" />} label={name} key={index}>
              {value}
            </Tag>
          ))}
        </div>
        <div className="divide-y divide-border [&>*]:py-8 [&>:last-child]:pb-0">
          <Wrapper show={A.isNotEmpty(skills)}>
            <Title>{_front("title-skills")}</Title>
            <Prose className="max-w-xl">
              <ul>
                {A.mapWithIndex(skills, (index, skill) => (
                  <li key={index}>
                    <strong>{skill.name}</strong>
                    &nbsp;&nbsp;&nbsp; ({skill.value})
                  </li>
                ))}
              </ul>
            </Prose>
          </Wrapper>
          <Wrapper show={htmlIsNotEmpty(project.objective)}>
            <Title>{_front("title-objective")}</Title>
            <Prose className="max-w-xl" dangerouslySetInnerHTML={{ __html: project.objective }} />
          </Wrapper>
          <Wrapper show={A.isNotEmpty(project.materials)}>
            <Title>{_front("title-materials")}</Title>
            <ul className="flex gap-2.5 my-[15px]">
              {A.mapWithIndex(project.materials, (index, material) => (
                <li
                  key={index}
                  className="flex items-center border border-mercury h-[50px] px-5 text-sm font-light"
                >
                  {material}
                </li>
              ))}
            </ul>
          </Wrapper>
          <Wrapper show={htmlIsNotEmpty(project.preparation)}>
            <Title>{_front("title-preparation")}</Title>
            <Prose
              className="max-w-2xl"
              dangerouslySetInnerHTML={{ __html: project.preparation }}
            />
          </Wrapper>
          <Wrapper show={htmlIsNotEmpty(project.follow)}>
            <Title>{_front("title-follow")}</Title>
            <Prose className="max-w-xl" dangerouslySetInnerHTML={{ __html: project.follow }} />
          </Wrapper>
          <Wrapper show={htmlIsNotEmpty(project.values)}>
            <Title>{_front("title-values")}</Title>
            <Prose dangerouslySetInnerHTML={{ __html: project.values }} />
          </Wrapper>
          <Wrapper show={D.isNotEmpty(project.documents)}>
            <Title>{_front("title-documents")}</Title>
            <ul className="flex flex-col gap-3 mt-[15px]">
              {A.mapWithIndex(D.values(project.documents), (index, { url, name }) => (
                <li key={index}>
                  <button
                    type="button"
                    onClick={() => saveAs(url, name)}
                    className="flex justify-center items-center gap-2.5 outline-none text-left focus-visible:underline text-emperor underline"
                  >
                    <DownloadSimple className="h-5 shrink-0 text-primary-500" aria-hidden />
                    <SrOnly>{_front("form.documents-download")}</SrOnly>
                    <span className="line-clamp-1">{truncateText(name, 25)}</span>
                  </button>
                </li>
              ))}
            </ul>
          </Wrapper>
          <Wrapper show={D.isNotEmpty(project.images)}>
            <Title>{_front("title-images")}</Title>
            <ul className="mt-4 columns-1 gap-5 sm:columns-2 sm:gap-8 md:columns-3 lg:columns-4 [&>*]:mb-8 [&>:last-child]:mb-0">
              {A.map(D.values(project.images), image => (
                <li key={image.id}>
                  <span className="relative">
                    <Image src={image.url} alt={image.name} className="rounded-lg" />
                    <button
                      className="absolute inset-0 size-full rounded-lg"
                      onClick={() => lightbox(image.id)}
                    >
                      <SrOnly>{_front("display-image", { name: image.name })}</SrOnly>
                    </button>
                  </span>
                </li>
              ))}
            </ul>
            {renderLightbox()}
          </Wrapper>
        </div>
      </CardAccordion.Content>
    </CardAccordion.Item>
  )
}

/**
 * ContextMenu
 */
const ContextMenu: React.FC<{ project: Project }> = ({ project }) => {
  const { _ } = useDictionary(dictionary("menu"))
  const { id, status } = project
  const ctx = usePageContext()
  const acceptItem = () => updateProject(id, { status: "accepted" })
  const refuseItem = () => updateProject(id, { status: "refused" })
  return (
    <>
      <Menu.Item onClick={() => ctx.edit(project)}>
        <NotePencil />
        {_("edit")}
      </Menu.Item>
      {status !== "accepted" && (
        <Menu.Item onClick={acceptItem}>
          <CheckCircle />
          {_("accept")}
        </Menu.Item>
      )}
      {status !== "refused" && (
        <Menu.Item onClick={refuseItem}>
          <XCircle />
          {_("refuse")}
        </Menu.Item>
      )}
      <Menu.Item onClick={() => ctx.confirmDelete(id)}>
        <TrashSimple />
        {_("delete")}
      </Menu.Item>
    </>
  )
}

/**
 * components
 */
const Tag: React.FC<{
  icon: React.ReactNode
  label: React.ReactNode
  children: React.ReactNode
}> = ({ icon, label, children }) => (
  <div className="flex justify-center grow lg:grow-0 items-center h-[60px] gap-2.5 border border-mercury px-5 text-sm text-shark [&>svg]:h-5 [&>svg]:text-tomato [&>svg]:shrink-0">
    {icon}
    <span>
      <span className="font-bold">{label} : </span>
      {children}
    </span>
  </div>
)

const Title: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, ...props }) => (
  <h2
    className={cx("flex items-center grow text-lg text-secondary-600 font-medium", className)}
    {...props}
  />
)

const Wrapper: React.FC<{ show?: boolean } & React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  show = true,
  ...props
}) => (show ? <div className={cx("flex flex-col gap-2 font-light", className)} {...props} /> : null)

/**
 * helpers
 */
const stripHTML = (contentHTML: string) => contentHTML.replace(/(<([^>]+)>)/gi, "").trim()
const htmlIsNotEmpty = (contentHTML: string) => S.isNotEmpty(stripHTML(contentHTML))
