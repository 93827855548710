import { Button } from "@/components/ui/button"
import { DropdownMenu } from "@/components/ui/dropdown-menu"

import { DisplayByName } from "../types"
import { Tooltip } from "@/components/ui/tooltip"

/**
 * dictionary src/dictionaries/en/components/trackings.json
 */
const dictionary = createContextMapper("components", "trackings", "display-by")

/**
 * DisplayBySelector
 */
export const DisplayBySelector: React.FC<{
  displayBy: DisplayByName
  setDisplayBy: (displayBy: DisplayByName) => void
}> = ({ displayBy, setDisplayBy }) => {
  const { _ } = useDictionary(dictionary())
  const list: DisplayByName[] = ["days", "weeks", "months", "years"]
  return (
    <div className="flex gap-4 items-center">
      <DropdownMenu>
        <Tooltip content={_("tooltip")} side="bottom">
          <DropdownMenu.Trigger asChild>
            <Button variant="outline" size="xs">
              {_(displayBy)}
            </Button>
          </DropdownMenu.Trigger>
        </Tooltip>
        <DropdownMenu.Content align="end" side="bottom" className="w-56">
          {A.map(list, item => (
            <DropdownMenu.Item
              onClick={() => setDisplayBy(item)}
              active={displayBy === item}
              key={item}
            >
              {_(item)}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu>
    </div>
  )
}
