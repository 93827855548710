/// <reference types="vite-plugin-svgr/client" />
import NewsPicture from "@/assets/images/news.svg?react"
import { FooterPicture } from "@/components/frontend/drawing"
import { ImageHeader } from "@/components/frontend/image-header"
import { Container } from "@/components/layout/frontend/container"
import { useLayoutContext } from "@/components/layout/frontend/context"
import { ItemRenderDispatcher, getMediasFile } from "@/components/layout/frontend/items"
import { Wrapper } from "@/components/layout/frontend/wrapper"
import globalConfig from "@/config/global"
import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { usePromise } from "@/hooks/usePromise"
import { service } from "@/services/frontend/service"
import { Article, localizeArticle } from "@/store/frontend/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { getPreview } from "@/store/medias/helpers"
import { useTracking } from "@/store/trackings/hooks"
import { useTitle } from "hoofd"
import { match } from "ts-pattern"

/**
 * Page: NewsArticle
 */
type PageProps = { id: string }

const NewsArticle: React.FC<PageProps> = ({ id }) => {
  const { loading } = useLayoutContext()
  const t = useTranslation()
  const [{ article, files }, inProgress] = usePromise(
    async () => {
      if (G.isNullable(id)) return navigate("/not-found") as never
      loading.setTrue()
      const response = await service.articles.read(id)
      loading.setFalse()
      return match(response)
        .with({ error: false }, ({ data }) => {
          const article = localizeArticle(data.article)
          if (!A.includes(["hero", "news"], article.type)) navigate("/not-found")
          return {
            error: false,
            article,
            files: article.files,
          } as const
        })
        .otherwise(({ code }) => {
          match(code)
            .with("RESOURCE_NOT_FOUND", () => navigate("/not-found"))
            .otherwise(code => navigate(`/server-error?code=${code}`))
          return initialResponse
        })
    },
    [],
    initialResponse
  )

  // SEO & Tracking
  const title = G.isNotNullable(article)
    ? `${globalConfig.siteName} - ${t(article.seo).title}`
    : globalConfig.siteName
  useTitle(title)
  useTracking(article?.trackingId)

  return inProgress || G.isNullable(article) ? null : (
    <>
      <ArticleHeader article={article} />
      {A.map(article.content.items, item => (
        <ItemRenderDispatcher item={item} key={item.id} files={files} />
      ))}
      <FooterPicture image={<NewsPicture />} />
    </>
  )
}

export default NewsArticle

const ArticleHeader: React.FC<{ article: Article }> = ({ article }) => {
  const { _ } = useDictionary(contextMapper("components", "cms", "content", "items", "articles"))
  const format = useDateFnsLocaleFormat()
  const t = useTranslation()

  const { publishedAt } = article
  const { title, description, image } = t(article.seo)
  const imageFile = getMediasFile(article.files, image)
  const categoryName = G.isNotNullable(article.category) ? t(article.category).name : null
  return (
    <Wrapper>
      <Container x="sm">
        <ImageHeader background={getPreview(imageFile)} backTo="/news">
          <ImageHeader.Main>
            <ImageHeader.Content className="gap-2">
              <ImageHeader.Title level={1}>{title}</ImageHeader.Title>
              <p className="text-xs text-white/75">
                {_("published-on", { date: format(publishedAt, "PPP") })}
                {categoryName && ` - ${categoryName}`}
              </p>
              <p className="text-base max-w-lg">{description}</p>
            </ImageHeader.Content>
          </ImageHeader.Main>
        </ImageHeader>
      </Container>
    </Wrapper>
  )
}

const initialResponse = {
  error: true,
  code: "FETCH_ERROR",
  article: null,
  files: {},
} as const
