import { useActiveLocation } from "@/components/ui/hooks/useActiveLocation"
import {
  Folders,
  GalleryVerticalEnd,
  GaugeCircle,
  Globe,
  LandPlot,
  Layers2,
  LayoutGrid,
  LibraryBig,
  MapPinned,
  Megaphone,
  Newspaper,
  // MapPinned,
  // Presentation,
  // Ticket,
  Rocket,
  Settings2,
  SquareArrowOutUpRight,
  Ticket,
  Users2,
} from "lucide-react"
import { ThemeDropdown } from "../theme/dropdown"
import { Accordion } from "./accordion"
import {
  SidebarDivider as Divider,
  SidebarMenu as Menu,
  SidebarButton,
  SidebarWrapper as Wrapper,
} from "./components"
import { SidebarContext } from "./context"

/**
 * dictionary src/dictionaries/en/components/layout.json
 * dictionary src/dictionaries/en/pages/dashboard/index.json
 */
const dictionary = createContextMapper("components", "layout", "sidebar")
const dashboard = createContextMapper("pages", "dashboard")

/**
 * Sidebar
 */
export const Sidebar: React.FC = () => {
  const { _ } = useDictionary()
  const { isDesktop, isMobile, ...sidebar } = React.useContext(SidebarContext)
  const expanded = isDesktop ? sidebar.state : true
  const { isActivePath, isActiveLocation } = useActiveLocation()
  const beforeClick = (): true => {
    if (isMobile) sidebar.close()
    return true
  }
  return (
    <Wrapper>
      <div className="flex flex-col w-full overflow-x-hidden">
        <div
          className="flex items-center w-full h-16 cursor-pointer"
          onClick={() => navigate("/dashboard")}
        >
          <span className="flex justify-center w-16 shrink-0 text-[#3980bf] font-bold text-xl">
            VP
          </span>
          <span
            className={cx(
              "flex flex-col text-xs leading-[14px] text-[#22486b] dark:text-[#3980bf] font-semibold border-[#22486b] dark:border-[#3980bf] border-l-2 pl-2 uppercase whitespace-nowrap transition-opacity duration-300",
              expanded ? "opacity-1" : "opacity-0"
            )}
          >
            Voie de
            <br /> préparation
          </span>
        </div>
        <Divider />
      </div>
      <Menu>
        <SidebarButton
          icon={<GaugeCircle aria-hidden />}
          onClick={() => navigate("/dashboard")}
          active={isActiveLocation("/dashboard")}
        >
          {_(dashboard("index.breadcrumbs"))}
        </SidebarButton>

        <SidebarButton
          icon={<Folders aria-hidden />}
          onClick={() => navigate("/dashboard/medias")}
          active={isActivePath("/dashboard/medias")}
        >
          {_(dashboard("medias.breadcrumbs"))}
        </SidebarButton>

        <Accordion value="section-content-manager">
          <Accordion.Trigger icon={<GalleryVerticalEnd aria-hidden />}>
            {_(dictionary("menu-content-manager"))}
          </Accordion.Trigger>
          <Accordion.Content>
            <Accordion.Item
              icon={<Layers2 aria-hidden />}
              onClick={() => navigate("/dashboard/pages")}
              active={isActivePath("/dashboard/pages")}
            >
              {_(dashboard("pages.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<Newspaper aria-hidden />}
              onClick={() => navigate("/dashboard/articles")}
              active={isActivePath("/dashboard/articles", ["/dashboard/articles/categories"])}
            >
              {_(dashboard("articles.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<Megaphone aria-hidden />}
              onClick={() => navigate("/dashboard/offers")}
              active={isActivePath("/dashboard/offers", ["/dashboard/offers"])}
            >
              {_(dashboard("offers.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<LayoutGrid aria-hidden />}
              onClick={() => navigate("/dashboard/articles/categories")}
              active={isActiveLocation("/dashboard/articles/categories")}
            >
              {_(dashboard("articles.categories.breadcrumbs"))}
            </Accordion.Item>
          </Accordion.Content>
        </Accordion>

        <Accordion value="kultureralley">
          <Accordion.Trigger icon={<LandPlot aria-hidden />}>
            {_(dashboard("kultureralley", "index", "breadcrumbs"))}
          </Accordion.Trigger>
          <Accordion.Content>
            <Accordion.Item
              icon={<MapPinned aria-hidden />}
              onClick={() => navigate("/dashboard/kultureralley")}
              active={isActivePath("/dashboard/kultureralley")}
            >
              {_(dashboard("kultureralley", "places", "breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<Ticket aria-hidden />}
              onClick={() => navigate("/dashboard/kultureralley/reservations")}
              active={isActivePath("/dashboard/kultureralley/reservations")}
            >
              {_(dashboard("kultureralley", "reservations", "breadcrumbs"))}
            </Accordion.Item>
          </Accordion.Content>
        </Accordion>

        <SidebarButton
          icon={<Rocket aria-hidden />}
          onClick={() => navigate("/dashboard/projects")}
        >
          {_(dashboard("projects.breadcrumbs"))}
        </SidebarButton>

        <SidebarButton
          icon={<LibraryBig aria-hidden />}
          onClick={() => navigate("/dashboard/programs")}
        >
          {_(dashboard("programs.breadcrumbs"))}
        </SidebarButton>

        <Accordion value="section-application">
          <Accordion.Trigger icon={<Settings2 aria-hidden />}>
            {_(dictionary("menu-application"))}
          </Accordion.Trigger>
          <Accordion.Content>
            <Accordion.Item
              icon={<Users2 aria-hidden />}
              onClick={() => navigate("/dashboard/users")}
              active={isActivePath("/dashboard/users")}
            >
              {_(dashboard("users.breadcrumbs"))}
            </Accordion.Item>
            <Accordion.Item
              icon={<Globe aria-hidden />}
              onClick={() => navigate("/dashboard/languages")}
              active={isActivePath("/dashboard/languages")}
            >
              {_(dashboard("languages.breadcrumbs"))}
            </Accordion.Item>
          </Accordion.Content>
        </Accordion>
        <SidebarButton icon={<SquareArrowOutUpRight aria-hidden />} onClick={() => navigate("/")}>
          {_(dictionary("website"))}
        </SidebarButton>
      </Menu>
      <div className="flex flex-col w-full overflow-hidden">
        <Divider />
        <div
          className={cx(
            "flex justify-center items-center py-4 gap-4",
            expanded ? "px-4" : "flex-col"
          )}
        >
          <ThemeDropdown beforeClick={beforeClick} />
        </div>
      </div>
    </Wrapper>
  )
}
