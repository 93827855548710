import { Form, FormInput, FormSection } from "@/components/form"
import { Button } from "@/components/frontend/button"
import { FormContactProps, StepsProps, StoreProps, dictionary } from "."
import { StepperContent } from "./Stepper"

/**
 * StepContact
 */
export const StepContact: React.FC<StoreProps & FormContactProps & StepsProps> = ({
  form,
  setStep,
}) => {
  const { _ } = useDictionary(dictionary("reservation", "contact"))
  return (
    <StepperContent value="contact" title={_("title")} secondary={_("fields-required")}>
      <Form
        form={form}
        onSubmit={() => form.isValid && setStep("summary")}
        className="flex flex-col gap-6"
      >
        <FormSection className="grid sm:grid-cols-2">
          <FormInput
            name="schoolName"
            label={_("school-name-label")}
            placeholder={_("school-name-placeholder")}
            required
          />
          <FormInput
            name="schoolClass"
            label={_("school-class-label")}
            placeholder={_("school-class-placeholder")}
            required
          />
        </FormSection>

        <FormInput
          name="schoolStreet"
          label={_("school-street-label")}
          placeholder={_("school-street-placeholder")}
          required
        />
        <FormSection className="grid sm:grid-cols-2">
          <FormInput
            name="schoolZip"
            label={_("school-zip-label")}
            placeholder={_("school-zip-placeholder")}
            required
          />
          <FormInput
            name="schoolCity"
            label={_("school-city-label")}
            placeholder={_("school-city-placeholder")}
            required
          />
        </FormSection>
        <FormInput
          name="teacherName"
          label={_("teacher-name-label")}
          placeholder={_("teacher-name-placeholder")}
          required
        />
        <FormSection className="grid sm:grid-cols-2">
          <FormInput
            name="teacherEmail"
            label={_("teacher-email-label")}
            placeholder={_("teacher-email-placeholder")}
            required
          />
          <FormInput
            name="teacherPhone"
            label={_("teacher-phone-label")}
            placeholder={_("teacher-phone-placeholder")}
            required
          />
        </FormSection>

        <div className="flex justify-between">
          <Button onClick={() => setStep("reservation")} variant="outline">
            {_("previous")}
          </Button>
          <Form.Submit asChild>
            <Button>{_("next")}</Button>
          </Form.Submit>
        </div>
      </Form>
    </StepperContent>
  )
}
