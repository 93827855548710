import { HN } from "@/components/ui/hn"
import { Image } from "@/components/ui/image"
import { Link } from "@/components/ui/link"
import cmsConfig from "@/config/cms"
import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { usePromise } from "@/hooks/usePromise"
import { useArticlesStore } from "@/store/articles"
import { getArticleCategories, getArticles } from "@/store/articles/actions"
import { useArticleCategory } from "@/store/articles/hooks"
import { Article } from "@/store/articles/localizers"
import { useTranslation } from "@/store/languages/hooks"
import { getPreview } from "@/store/medias/helpers"
import { useMediasFile } from "@/store/medias/hooks"
import { ImageOff } from "lucide-react"
import { container } from "../../frontend/container"
import { Header } from "../../frontend/header"
import { ItemMappingExport } from "../schemas"
import { ItemType, itemType } from "./schemas"

/**
 * dictionary src/dictionaries/fr/components/cms.json
 */
const dictionary = createContextMapper("components", "cms", "content", "items", itemType)

/**
 * ItemRender
 */
export const ItemRender: ItemMappingExport<ItemType>["ItemRender"] = ({ item }) => {
  const t = useTranslation()
  usePromise(getArticles)
  usePromise(getArticleCategories)
  const articles = useArticlesStore(({ articles }) => {
    const articleList = pipe(
      articles,
      D.values,
      A.reject(({ id }) => id === item.props.omit)
    )

    const articlesSelected = pipe(
      articleList,
      A.filter(article => A.includes(item.props.articles, article.id)),
      A.sortBy(D.getUnsafe("publishedAt")),
      A.take(cmsConfig.relatedArticles)
    )
    const articlesFromCategory = pipe(
      articleList,
      A.filter(
        article =>
          !A.includes(item.props.articles, article.id) && article.category === item.props.category
      ),
      A.sortBy(D.getUnsafe("publishedAt")),
      A.take(cmsConfig.relatedArticles - articlesSelected.length)
    )
    return [...articlesSelected, ...articlesFromCategory]
  })

  const { titleLevel } = item.props
  const { title, secondary } = t(item).props
  if (A.isEmpty(articles)) return null
  return (
    <div className={cx("flex flex-col py-8 gap-8 font-plus", container({ x: "sm" }))}>
      <Header {...{ title, secondary, titleLevel }} />
      <div className="grid grid-cols-3 gap-8">
        {A.map(articles, article => (
          <ArticleRender article={article} titleLevel={titleLevel} key={article.id} />
        ))}
      </div>
    </div>
  )
}
const ArticleRender: React.FC<{ article: Article; titleLevel: number }> = ({
  article,
  titleLevel,
}) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()
  const format = useDateFnsLocaleFormat()
  const { title, image, description } = t(article.seo)
  const { publishedAt, category } = article
  const imageFile = useMediasFile(image)

  return (
    <article className="relative flex flex-col items-stretch rounded-md bg-white text-gray-800 shadow-md">
      <div className="relative flex aspect-video rounded-t-md overflow-hidden">
        <Image
          src={getPreview(imageFile)}
          alt={imageFile ? t(imageFile).alt : title}
          className="absolute inset-0 size-full object-cover rounded-t-md text-muted-foreground"
        >
          <ImageOff size={64} strokeWidth={1} />
        </Image>
        <div
          className="absolute inset-0 size-full bg-gradient-to-b from-orient/20 from-5% to-orient/80 to-80%"
          aria-hidden
        />
        <div className="relative flex flex-col justify-stretch size-full p-4">
          <div className="grow">
            <CategoryRender id={category} />
          </div>
          <HN level={titleLevel + 1} className="text-white text-xl font-bold">
            {t(article.seo).title}
          </HN>
        </div>
      </div>
      <div className="flex flex-col justify-stretch items-stretch gap-2 p-4">
        <p className="text-xs text-frontend-gray">
          {_("published-on", { date: format(publishedAt, "PPP") })}
        </p>
        <p className="text-xs leading-relaxed line-clamp-3">{description}</p>
      </div>
      <Link href={`/dashboard/offers/${article.id}`} className="absolute inset-0 size-full" />
    </article>
  )
}
const CategoryRender: React.FC<{ id: Option<string> }> = ({ id }) => {
  const t = useTranslation()
  const categories = useArticleCategory(id)
  if (G.isNullable(categories)) return null
  const name = t(categories).name
  return (
    <p className="inline-flex min-h-[1rem] mx-auto px-4 py-2 rounded-full backdrop-blur-sm bg-frontend-bluewhale/40 uppercase text-white text-xs leading-none font-semibold">
      {name}
    </p>
  )
}
