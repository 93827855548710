import { byId } from "@/fns/byId"
import * as Places from "@/services/places/service"
import { match } from "ts-pattern"
import { placesStore } from "."
import { mediasStore } from "../medias"
import { localizeMediaFile } from "../medias/localizers"
import { extractFilesFromPlace, extractFilesFromPlaces } from "./helpers"
import { localizePlace } from "./localizers"

/**
 * getPlaces
 */
export const getPlaces = async () =>
  match(await Places.service.index())
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromPlaces(data.places), localizeMediaFile)),
      })
      placesStore.evolve({ places: byId(data.places, localizePlace) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * createPlace
 */
export const createPlace = async (payload: Places.Payload["create"]) =>
  match(await Places.service.create(payload))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromPlace(data.place), localizeMediaFile)),
      })
      placesStore.evolve({ places: D.set(data.place.id, localizePlace(data.place)) })
      return { error: false, id: data.place.id } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * getPlace
 */
export const getPlace = async (id: string) =>
  match(await Places.service.read(id))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromPlace(data.place), localizeMediaFile)),
      })
      placesStore.evolve({ places: D.set(data.place.id, localizePlace(data.place)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * updatePlace
 */
export const updatePlace = async (id: string, payload: Places.Payload["update"]) =>
  match(await Places.service.update(id, payload))
    .with({ error: false }, ({ data }) => {
      mediasStore.evolve({
        files: D.merge(byId(extractFilesFromPlace(data.place), localizeMediaFile)),
      })
      placesStore.evolve({ places: D.set(data.place.id, localizePlace(data.place)) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))

/**
 * deletePage
 */
export const deletePlace = async (id: string) =>
  match(await Places.service.delete(id))
    .with({ error: false }, () => {
      placesStore.evolve({ places: D.deleteKey(id) })
      return { error: false } as const
    })
    .otherwise(({ error, code }) => ({ error, code } as const))
