import { Form, FormAssertive, FormFiles, FormSubmit, useForm, validator } from "@/components/form"
import { FormFileType, isFile } from "@/components/form/files"
import { Button } from "@/components/ui/button"
import { Dialog } from "@/components/ui/dialog"
import { UseDialogFormProps, UseDialogProps } from "@/components/ui/hooks/useDialog"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { createMediasFile } from "@/store/medias/actions"
import { match } from "ts-pattern"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * UploadFilesDialog
 */
export const UploadFilesDialog: React.FC<UseDialogProps<string | null>> = ({ item, ...props }) => {
  const { _ } = useDictionary(dictionary("upload-files-dialog"))
  return (
    <Dialog {...props} title={_("title")} description={_("secondary")} className="sm:max-w-xl">
      {item !== false && <DialogForm {...props} item={item} />}
    </Dialog>
  )
}

/**
 * DialogForm
 */
const DialogForm: React.FC<UseDialogFormProps<string | null>> = ({
  item: folderId,
  onOpenChange,
}) => {
  const { _ } = useDictionary(dictionary("upload-files-dialog"))
  const _form = useFormDictionary()
  const _error = useErrorsDictionary()

  const { min } = validator
  const form = useForm({
    allowSubmitAttempt: true,
    allowErrorSubmit: true,
    values: useMemoOnce(() => ({
      files: [] as FormFileType[],
    })),
    validate: validator({
      files: [min(1, _("files-required"))],
    }),
    onSubmit: ({ values }, event) => {
      event?.stopPropagation()
      if (!form.isValid) return _error("VALIDATION_FAILURE")

      onOpenChange(false)
      const files = values.files.filter(isFile)
      let counter = 1
      const total = files.length

      const id = toast.promise(
        Promise.all(
          A.map(
            files,
            async file =>
              void match(await createMediasFile({ folderId, file }))
                .with({ error: false }, () =>
                  toast.loading(_("progress", { counter: counter++, total }), { id })
                )
                .otherwise(() => toast.error(_("error"), { description: file.name }))
          )
        ),
        {
          loading: _("progress", { counter, total }),
          success: _("success"),
        }
      )
    },
  })

  return (
    <Form form={form} className="grid gap-6">
      <FormAssertive />
      <FormFiles label={_("name-label")} name="files" />
      <Dialog.Footer className="sm:justify-start">
        <Dialog.Close asChild>
          <Button variant="secondary">{_form("cancel")}</Button>
        </Dialog.Close>
        <FormSubmit>{_("submit")}</FormSubmit>
      </Dialog.Footer>
    </Form>
  )
}
