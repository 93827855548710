import { makeBreakable } from "@/fns/String"
import { humanFileSize } from "@/fns/human"
import { Fields } from "@/components/collection"
import { Badge } from "@/components/ui/badge"
import { Dialog } from "@/components/ui/dialog"

import { useTranslation } from "@/store/languages/hooks"
import { MediasFile } from "@/store/medias/localizers"
import { ImageIcon, Subtitles, TypeIcon, Weight } from "lucide-react"

/**
 * dictionary src/dictionaries/en/components/medias.json
 */
const dictionary = createContextMapper("components", "medias")

/**
 * FileInfoDialog
 */
type FileInfoDialogProps = {
  file: MediasFile | false
  openInfo: boolean
  setOpenInfo: (open: boolean) => void
  onCloseAutoFocus?: () => void
}
export const FileInfoDialog: React.FC<FileInfoDialogProps> = ({
  file,
  openInfo,
  setOpenInfo,
  onCloseAutoFocus,
}) => {
  const { _ } = useDictionary(dictionary())
  const t = useTranslation()

  return (
    <Dialog
      title={_("file-info")}
      open={openInfo}
      onOpenChange={setOpenInfo}
      onCloseAutoFocus={onCloseAutoFocus}
      className="max-w-md"
    >
      {file !== false && (
        <Fields divider>
          <Fields.Item
            name={_("file-name")}
            icon={<TypeIcon aria-hidden />}
            value={makeBreakable(
              t(file).name + (S.isNotEmpty(file.extension) ? `.${file.extension}` : "")
            )}
            stretch
          />
          <Fields.Item
            name={_("file-size")}
            icon={<Weight aria-hidden />}
            value={
              <Badge variant="secondary" className="w-24">
                {humanFileSize(file.size)}
              </Badge>
            }
            stretch
          />
          <Fields.Item
            name={_("file-alt")}
            icon={<ImageIcon aria-hidden />}
            value={t(file).alt}
            stretch
          />
          <Fields.Item
            name={_("file-caption")}
            icon={<Subtitles aria-hidden />}
            value={t(file).caption}
            stretch
          />
        </Fields>
      )}
    </Dialog>
  )
}
