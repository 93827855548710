import { Tooltip } from "@/components/ui/tooltip"
import { Within } from "@/components/ui/within"
import { useDateFnsLocaleFormat } from "@/dictionaries/hooks"
import { CalendarBlank, X } from "@phosphor-icons/react"
import { format, isAfter, isEqual, isValid as isValidDate, parse } from "date-fns"
import { SrOnly } from "../ui/sr-only"
import { ButtonIcon } from "./button"

/**
 * dictionary src/dictionaries/en/components/frontend/datepicker.json
 */
const dictionary = createContextMapper("components", "frontend", "datepicker")

/**
 * DateIntervalPicker
 */

type DateIntervalPickerProps = {
  interval: OptionDateInterval
  onChange: (interval: OptionDateInterval) => void
  disabled?: boolean
}

export const DateIntervalPicker: React.FC<DateIntervalPickerProps> = props => {
  const { _ } = useDictionary(dictionary())
  const [isFocused, setIsFocused] = React.useState(false)

  const invalidInterval =
    // is interval
    props.interval.start &&
    props.interval.end &&
    // is invalid interval
    !isAfter(props.interval.end, props.interval.start)

  const validStartDate = isValidDate(props.interval.start)

  return (
    <Within
      onFocusIn={() => setIsFocused(true)}
      onFocusOut={() => setIsFocused(false)}
      className="w-full"
    >
      <div className="px-5 py-4 border-[1px] border-mercury text-gray text-sm flex flex-col gap-3 w-full">
        <div className="flex items-center gap-4">
          <CalendarBlank className="w-5 shrink-0" />

          <div className="flex items-center gap-1 flex-wrap">
            <span>{_("from")}</span>
            <DatePicker
              onChange={start => props.onChange({ ...props.interval, start })}
              date={props.interval.start}
            />

            <span>{_("to")}</span>
            <Tooltip content={validStartDate ? null : "Choose a valid start date"}>
              <DatePicker
                disabled={!validStartDate}
                onChange={end => props.onChange({ ...props.interval, end })}
                date={props.interval.end}
              />
            </Tooltip>
          </div>

          {(props.interval.start || props.interval.end) && (
            <ButtonIcon
              size="icon"
              className="w-7 h-7 p-0"
              onClick={() => props.onChange({ start: null, end: null })}
            >
              <X className="w-4" aria-hidden />
              <SrOnly>{_("clear-interval")}</SrOnly>
            </ButtonIcon>
          )}
        </div>

        {!isFocused && invalidInterval && (
          <div>
            <span className="text-xs pt-3">{_("invalid-interval")}</span>
          </div>
        )}
      </div>
    </Within>
  )
}

/**
 * DatePicker
 */

type DatePickerProps = Extend<
  React.ButtonHTMLAttributes<HTMLInputElement>,
  {
    date: Option<Date>
    onChange: (date: Option<Date>) => void
  }
>

export const DatePicker = React.forwardRef<HTMLButtonElement, DatePickerProps>(
  ({ date, onChange, ...props }, ref) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const [isFocused, setIsFocused] = React.useState(false)

    React.useEffect(() => {
      if (isFocused && inputRef.current) inputRef.current.focus()
    }, [isFocused])
    const format = useDateFnsLocaleFormat()
    return (
      <>
        <input
          ref={inputRef}
          {...props}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={cx(
            "focus-visible:outline-none focus-visible:ring-[1.5px] focus-visible:ring-frontend-primary focus-visible:ring-offset-0",
            "tabular-nums lining-nums uppercase w-[120px] text-center py-0 text-sm rounded-[1px]",
            isFocused ? "flex" : "hidden",
            props.className
          )}
          onChange={e => onChange(inputValueToDate(e.target.value))}
          value={dateToInputValue(date)}
          type="date"
        />

        <button
          ref={ref}
          disabled={props.disabled}
          onClick={() => setIsFocused(true)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={cx(
            "focus-visible:outline-none focus-visible:ring-[1.5px] focus-visible:ring-frontend-primary focus-visible:ring-offset-0",
            "py-1 underline underline-offset-4 disabled:opacity-40",
            isFocused ? "hidden" : "flex"
          )}
        >
          {date && isValidDate(date) ? format(date, "P") : "--/--/----"}
        </button>
      </>
    )
  }
)

/**
 * Helpers
 */

const dateToInputValue = (date: Option<Date>) =>
  date && isValidDate(date) ? format(date, "yyyy-MM-dd") : ""

const inputValueToDate = (value: string): Option<Date> => {
  const date = parseDate(value)
  return isValidDate(date) ? date : null
}

// parse date return option date
const parseDate = (dateStr: string): Option<Date> => {
  const maybeDate = parse(dateStr, "yyyy-MM-dd", new Date())
  if (isValidDate(maybeDate)) return maybeDate
  return null
}

export const isValidDateInterval = (interval: OptionDateInterval): interval is DateInterval => {
  return !!(
    interval.start &&
    interval.end &&
    (isAfter(interval.end, interval.start) || isEqual(interval.end, interval.start))
  )
}

/**
 * Types
 */

export type OptionDateInterval = { start: Option<Date>; end: Option<Date> }
export type DateInterval = { start: Date; end: Date }
