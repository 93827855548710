import { Button, focusOutlineClasses } from "@/components/frontend/button"
import { LinkExternal } from "@/components/ui/link"
import { placeLink, workshopLink } from "@/pages/kulturrallye/Routes"
import { Place, Workshop } from "@/store/frontend/localizers"
import { Plus } from "@phosphor-icons/react"
import { Pin } from "lucide-react"
import { Link } from "wouter"

/**
 * PlacePreview
 */

export const PlacePreview: React.FC<
  React.ComponentProps<"div"> & { place: Place; workshops: Workshop[] }
> = ({ place, workshops, ...props }) => {
  const { _ } = useDictionary()

  return (
    <div
      {...props}
      className={cx(
        "border-[1px] border-mercury/80 flex flex-col gap-4 bg-white/80 rounded-[2px] overflow-hidden ",
        props.className
      )}
    >
      <div className={cx("px-7 pt-4 flex flex-col gap-2")}>
        <h3 className={cx("text-lg font-bold leading-relaxed")}>{place.name}</h3>
        <LinkExternal
          className={cx("flex items-center gap-2 text-sm hover:underline")}
          href={getGoogleMapsSearchLink(place.address)}
        >
          <Pin className="w-5 text-tomato" aria-hidden />
          {place.address}
        </LinkExternal>
      </div>

      {A.isNotEmpty(workshops) && (
        <div className={cx("px-7 flex flex-wrap gap-2")}>
          {workshops.map((workshop, i) => {
            return <WorkshopPreview key={workshop.id} optionIndex={i} workshop={workshop} />
          })}
        </div>
      )}
      {G.isNotNullable(props.children) && (
        <div className={cx("px-7 flex flex-wrap gap-2")}>{props.children}</div>
      )}

      <Button
        case="uppercase"
        type="link"
        to={placeLink(place)}
        className="bg-aquahaze/70 hover:bg-solitude/70"
      >
        <Plus className="w-4" aria-hidden />
        {_("pages.kulturrallye.regionPage.placePreview.moreLabel")}
      </Button>
    </div>
  )
}

export const WorkshopPreview: React.FC<{ workshop: Workshop; optionIndex?: number }> = props => {
  return (
    <Link key={props.workshop.id} to={workshopLink(props.workshop)}>
      <a
        className={cx(
          focusOutlineClasses,
          "text-sm [&>span]:font-medium py-3 px-4 border-[1px] border-mercury rounded-sm whitespace-nowrap truncate"
        )}
      >
        {G.isNumber(props.optionIndex) && <span>Option {props.optionIndex + 1}:</span>}{" "}
        {props.workshop.name}
      </a>
    </Link>
  )
}

/**
 * Helpers
 */

export const getGoogleMapsSearchLink = (address: string) =>
  `https://www.google.com/maps/search/${encodeURIComponent(address.trim())}`
