/**
 * Grid
 */
type Props = {
  view: "grid" | "list"
} & React.ComponentProps<"div">
export const Grid = React.forwardRef<HTMLDivElement, Props>(
  ({ className, view, ...props }, ref) => {
    return (
      <div className="@container/collection w-full max-w-screen-2xl mx-auto">
        <div
          ref={ref}
          className={cx(
            view === "grid"
              ? "grid grid-cols-1 @4xl/collection:grid-cols-2 @6xl/collection:grid-cols-3 gap-4"
              : "flex flex-col divide-y-4 divide-transparent",
            className
          )}
          {...props}
        />
      </div>
    )
  }
)
