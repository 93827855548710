import { VariantProps } from "class-variance-authority"
import {
  FormFieldWrapper,
  FormFieldWrapperProps,
  extractInputProps,
  extractWrapperProps,
  inputVariants,
  useFieldContext,
} from "."
import { useBaseLayout } from "../layout/context"
import { inputVariantsFE } from "./input"

/**
 * FormTextarea
 */
type Props = FormFieldTextareaProps & VariantProps<typeof inputVariants> & FormFieldWrapperProps
export const FormTextarea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ icon, size, className, ...props }, ref) => {
    const { isDashboard } = useBaseLayout()
    return (
      <FormFieldWrapper {...extractWrapperProps(props)}>
        <FormFieldTextarea
          ref={ref}
          className={cx(
            isDashboard
              ? inputVariants({ icon, size, className })
              : inputVariantsFE({ icon, size, className })
          )}
          {...extractInputProps(props)}
        />
      </FormFieldWrapper>
    )
  }
)

type FormFieldTextareaProps = Omit<
  React.ComponentPropsWithRef<"textarea">,
  "name" | "id" | "onChange" | "value" | "disabled" | "children"
>

/**
 * FormFieldTextarea
 */
const FormFieldTextarea = React.forwardRef<HTMLTextAreaElement, FormFieldTextareaProps>(
  ({ rows = 3, maxLength, className, ...props }, ref) => {
    const { value, setFieldValue, disabled, name, id } = useFieldContext<string>()
    const { isDashboard } = useBaseLayout()
    return (
      <div className="grid">
        <textarea
          ref={ref}
          id={id}
          name={name}
          disabled={disabled}
          onChange={({ target }) => setFieldValue(target.value)}
          value={value}
          rows={rows}
          className={cx(
            className,
            "resize-none h-auto",
            "scrollbar scrollbar-w-1 scrollbar-thumb-muted scrollbar-track-background scrollbar-thumb-rounded-full",
            isDashboard ? "" : "py-4"
          )}
          {...props}
        />
        {G.isNotNullable(maxLength) && (
          <div className="relative" aria-hidden>
            <div className="absolute bottom-2 right-2 text-xs text-muted-foreground">
              {value.length} / {maxLength}
            </div>
          </div>
        )}
      </div>
    )
  }
)
