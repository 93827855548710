import {
  ApiContact,
  ApiWorkshop,
  ApiWorkshopEvent,
  ApiWorkshopReservation,
} from "@/services/workshops/schemas"

/**
 * localizeWorkshop
 */
export const localizeWorkshop = (parsed: ApiWorkshop) => ({
  ...D.selectKeys(parsed, [
    "id",
    "name",
    "published",
    "archived",
    "description",
    "languages",
    "duration",
    "maxAttendees",
    "placeId",
    "trackingId",
  ]),
})
export type Workshop = ReturnType<typeof localizeWorkshop>

/**
 * localizeWorkshopEvent
 */
export const localizeWorkshopEvent = (parsed: ApiWorkshopEvent) => ({
  ...D.selectKeys(parsed, ["id", "reservationsSlot", "reservationsDone", "workshopId"]),
  reservationsAvailable: parsed.reservationsSlot - parsed.reservationsDone,
  datetime: T.parseISO(parsed.datetime),
})
export type WorkshopEvent = ReturnType<typeof localizeWorkshopEvent>

/**
 * localizeWorkshopReservation
 */
export const localizeWorkshopReservation = (parsed: ApiWorkshopReservation) => ({
  ...D.selectKeys(parsed, [
    "id",
    "canceled",
    "archived",
    "message",
    "language",
    "students",
    "userId",
    "eventId",
    "workshopId",
  ]),
  contact: D.isEmpty(parsed.contact) ? null : localizeContact(parsed.contact as ApiContact),
  createdAt: T.parseISO(parsed.createdAt),
  updatedAt: T.parseISO(parsed.updatedAt),
})
export type WorkshopReservation = ReturnType<typeof localizeWorkshopReservation>

/**
 * localizeContact
 */
const localizeContact = (parsed: ApiContact) => ({
  ...D.selectKeys(parsed, [
    "schoolName",
    "schoolClass",
    "schoolStreet",
    "schoolZip",
    "schoolCity",
    "teacherName",
    "teacherEmail",
    "teacherPhone",
  ]),
})
