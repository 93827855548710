import { VariantProps } from "class-variance-authority"
import { mergeRefs } from "react-merge-refs"
import {
  FormFieldWrapper,
  FormFieldWrapperProps,
  extractInputProps,
  extractWrapperProps,
  inputVariants,
  inputVariantsFE,
  useFieldContext,
} from "."
import { useBaseLayout } from "../layout/context"

/**
 * FormNumber
 */
type InputProps = Omit<React.ComponentProps<typeof InputNumber>, "type">
type Props = InputProps & VariantProps<typeof inputVariants> & FormFieldWrapperProps

export const FormNumber = React.forwardRef<HTMLInputElement, Props>(
  ({ icon, size, className, ...props }, ref) => {
    const { isDashboard } = useBaseLayout()
    const variants = isDashboard ? inputVariants : inputVariantsFE
    return (
      <FormFieldWrapper {...extractWrapperProps(props)}>
        <InputNumber
          ref={ref}
          className={cx(variants({ icon, size, className }))}
          {...extractInputProps(props)}
        />
      </FormFieldWrapper>
    )
  }
)

/**
 * InputNumber
 */
type InputNumberProps = Extend<
  Omit<React.ComponentPropsWithoutRef<"input">, "size">,
  {
    min?: number
    max?: number
    step?: number
    modifierStep?: number
    decimalPrecision?: number
    blurOnEnter?: boolean
    respectFocus?: boolean
    unsigned?: boolean
    adjustValue?: (value: number) => number
    postfix?: string
    deferChanges?: boolean
    placeholder?: string
  }
>
const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  (
    {
      min = Number.MIN_SAFE_INTEGER,
      max = Number.MAX_SAFE_INTEGER,
      postfix = "",
      step = 1,
      modifierStep = 10,
      decimalPrecision = 2,
      respectFocus = true,
      adjustValue = F.identity,
      unsigned = false,
      deferChanges = false,
      disabled,
      ...props
    },
    ref
  ) => {
    const {
      value = 0,
      setFieldValue: onValueChange,
      disabled: ctxDisabled,
    } = useFieldContext<number>()

    const minValue = unsigned ? 0 : min

    const focused = useRefStateWithDirty(false)

    const makeValue = (value: Option<number>) => {
      return S.make(value)
    }

    const [inputValue, setInputValue] = React.useState<string>(() => makeValue(value))

    const validValue = useRefStateWithDirty(value)

    React.useEffect(() => {
      if (respectFocus ? focused.value : false) return
      validValue.set(value)
      setInputValue(makeValue(value))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, respectFocus, focused.value])

    const roundToPrecision = (n: number) => round(n, decimalPrecision)
    const makeValid = flow(
      adjustValue,
      roundToPrecision,
      N.clamp(roundToPrecision(minValue), roundToPrecision(max))
    )

    const confirmInputValue = (value = validValue.value) => {
      if (isFiniteNumber(value)) {
        onValueChange(value)
        setInputValue(makeValue(value))
      }
    }

    const setValidValueMaybe = (value: number, updateInputValue = false) => {
      if (Number.isFinite(value)) {
        const validNumber = makeValid(value)
        validValue.set(validNumber)

        if (!deferChanges) {
          onValueChange(validNumber)
        }

        if (updateInputValue) {
          confirmInputValue(validNumber)
        }
      }
    }

    const inputRef = React.useRef<HTMLInputElement>(null)

    const handleValueChange = (value: string) => {
      setInputValue(value)
      setValidValueMaybe(+value)
    }

    return (
      <input
        {...props}
        type="text"
        disabled={ctxDisabled || disabled}
        style={{ ...props.style, touchAction: "none" }}
        ref={mergeRefs([ref, inputRef])}
        value={focused.value ? inputValue : `${inputValue}${postfix}`}
        spellCheck={false}
        autoComplete="off"
        onFocus={e => {
          focused.set(true)
          if (props.onFocus) props.onFocus(e)
        }}
        onBlur={e => {
          focused.set(false)
          confirmInputValue()
          if (props.onBlur) props.onBlur(e)
        }}
        onKeyDown={e => {
          const currentStep = e.shiftKey ? modifierStep : step

          if (e.code === "ArrowUp") {
            e.preventDefault()
            setValidValueMaybe((validValue.value ?? 0) + currentStep, true)
          }

          if (e.code === "ArrowDown") {
            e.preventDefault()
            setValidValueMaybe((validValue.value ?? 0) - currentStep, true)
          }

          if (e.code === "Enter") {
            e.preventDefault()
            ;(e.target as HTMLInputElement).blur()
            confirmInputValue()
          }

          if (e.code === "Escape") {
            e.preventDefault()
            validValue.set(value)
            setInputValue(S.make(value))

            setTimeout(() => {
              // eslint-disable-next-line no-extra-semi
              ;(e.target as HTMLInputElement).blur()
            }, 0)
          }

          if (props.onKeyDown) props.onKeyDown(e)
        }}
        onChange={e => {
          handleValueChange(e.target.value)
          if (props.onChange) props.onChange(e)
        }}
      />
    )
  }
)

/**
 * helpers
 */
const isFiniteNumber = (n: unknown): n is number => {
  return Number.isFinite(n)
}
const round = (n: number, precision: number = 0) => {
  if (precision === 0) return Math.round(n)
  const factor = 10 ** precision
  return Math.round(n * factor) / factor
}

/**
 * hooks
 */
const useRefState = <S,>(initialState: S | (() => S)) => {
  const [reactState, setReactState] = React.useState(initialState)
  const initialStateRef = React.useRef(reactState)
  const stateRef = React.useRef(reactState)

  const setState: React.Dispatch<React.SetStateAction<S>> = action => {
    stateRef.current = G.isFunction(action) ? action(stateRef.current) : action
    setReactState(stateRef.current)
  }

  const get = () => stateRef.current

  return {
    set: setState,
    reset: () => setState(initialStateRef.current),
    init: () => initialStateRef.current,
    get value(): Readonly<S> {
      return get()
    },
  }
}

const useRefStateWithDirty = <S,>(initialState: S | (() => S)) => {
  const state = useRefState(initialState)
  const dirty = useRefState(false)

  return {
    ...state,
    get isDirty() {
      return dirty.value
    },
    get value() {
      return state.value
    },
    set: (value: React.SetStateAction<S>) => {
      state.set(value)
      dirty.set(true)
    },
    reset: () => {
      state.reset()
      dirty.set(false)
    },
  }
}
